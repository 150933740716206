const removeFalsyProps = (obj) => {
  const newObj = Object.entries(obj).reduce((result, [key, value]) => {
    if (!value) {
      return result;
    }
    return {
      ...result,
      [key]: value,
    };
  }, {});

  return isEmpty(newObj) ? null : newObj;
};

const removeNullProps = (obj) =>
  Object.entries(obj).reduce((result, [key, value]) => {
    if (value === null) {
      return result;
    }
    return {
      ...result,
      [key]: value,
    };
  }, {});

const isEmpty = (obj) => !Object.keys(obj).length;

const getObjectKeyByPartName = (obj, partName) =>
  Object.keys(obj).find((key) => key.startsWith(partName));

const isFalsy = (obj = {}) => {
  return Object.keys(obj)?.every((key) => !obj[key]);
};

const isObject = (obj) => {
  return Object.prototype.toString.call(obj) === '[object Object]';
};

export {
  removeFalsyProps,
  removeNullProps,
  isEmpty,
  getObjectKeyByPartName,
  isFalsy,
  isObject,
};
