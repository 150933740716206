import { useState, useEffect } from 'react';

const useToggleDropdown = (
  buttonRef,
  dropdownRef,
  closeOnClickInside = false,
  onCloseDropdown
) => {
  const [showDropdown, setShowDropdown] = useState(false);

  function closeDropdown() {
    setTimeout(() => {
      setShowDropdown(false);
      if (onCloseDropdown) onCloseDropdown();
    }, 150);
  }

  function handleClickOutside(event) {
    if (showDropdown && closeOnClickInside) {
      closeDropdown();
    } else if (showDropdown) {
      if (
        !dropdownRef?.current?.contains(event.target) &&
        !buttonRef?.current?.contains(event.target)
      ) {
        closeDropdown();
      }
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return { showDropdown, setShowDropdown };
};

export { useToggleDropdown };
