import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { IconButton } from '@mui/material';
import { Row, Col, IconRoundBox } from 'modules/Core/Common';
import * as S from './WidgetCardHeader.styles';
import { SettingsIcon } from '@edusynch/edusynch-svg-icons';

export const WidgetCardHeader = ({
  size = 'small',
  Icon,
  title,
  subtitle,
  showSettingsButton,
  showFiltersButton = false,
  onOpenSettings,
  onOpenFilters,
  hasFiltersActive = false,
  borderBottom = false,
  children,
}) => {
  const theme = useTheme();

  return (
    <Row>
      <Col xs={12} md={size === 'small' ? 12 : 6}>
        <S.Header size={size} borderBottom={borderBottom}>
          {Icon && (
            <S.Icon>
              <IconRoundBox small withBg bg={theme.config.colors.primary_100}>
                <Icon width={16} height={16} />
              </IconRoundBox>
            </S.Icon>
          )}
          <div>
            {subtitle && <h6>{subtitle}</h6>}
            <p>{title}</p>
          </div>
          {showSettingsButton && size === 'small' && (
            <IconButton
              aria-label="settings"
              id="settings"
              onClick={onOpenSettings}
              sx={{ marginLeft: 'auto', alignSelf: 'flex-start' }}
            >
              <SettingsIcon />
            </IconButton>
          )}
          {showFiltersButton && size === 'small' && (
            <IconButton
              aria-label="users-filter"
              id="users-filter"
              onClick={onOpenFilters}
              sx={
                hasFiltersActive
                  ? {
                      position: 'relative',
                      marginLeft: 'auto',

                      '::before': {
                        content: '""',
                        position: 'absolute',
                        width: '0.8rem',
                        height: '0.8rem',
                        borderRadius: '50%',
                        top: '0.8rem',
                        right: '0.8rem',
                        bgcolor: theme.config.colors.green,
                      },
                    }
                  : {
                      marginLeft: 'auto',
                    }
              }
            >
              <Icon name="filter" />
            </IconButton>
          )}
        </S.Header>
      </Col>
      {children}
    </Row>
  );
};

WidgetCardHeader.propTypes = {
  size: PropTypes.oneOf(['small', 'large']),
  Icon: PropTypes.func,
  title: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  subtitle: PropTypes.string,
  showSettingsButton: PropTypes.bool,
  showFiltersButton: PropTypes.bool,
  onOpenSettings: PropTypes.func,
  onOpenFilters: PropTypes.func,
  hasFiltersActive: PropTypes.bool,
  borderBottom: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
