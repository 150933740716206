import styled, { css } from 'styled-components';

const PENDING_STATUS = [
  'pending',
  'to do',
  'todo',
  'no license',
  'pending evaluation',
];
const WAITING_STATUS = ['wait'];
const ERROR_STATUS = ['incorrect'];
const SKIPPED_STATUS = ['skipped'];
const SUCCESS_STATUS = ['success', 'published', 'evaluated', 'completed'];

const getStyleByProperty = (props) => {
  const value = props?.value?.toLowerCase();

  if (PENDING_STATUS.includes(value)) {
    return css`
      color: ${props.theme.config.colors.text_base};
      background-color: rgba(93, 102, 112, 0.05);
    `;
  } else if (WAITING_STATUS.includes(value)) {
    return css`
      color: ${props.theme.config.colors.warning};
      background-color: ${props.theme.config.colors.warning_bg};
    `;
  } else if (SUCCESS_STATUS.includes(value)) {
    return css`
      color: ${props.theme.config.colors.success};
      background-color: ${props.theme.config.colors.success_bg};
    `;
  } else if (SKIPPED_STATUS.includes(value)) {
    return css`
      color: ${props.theme.config.colors.orange};
      background-color: ${props.theme.config.colors.orange_100};
    `;
  } else if (ERROR_STATUS.includes(value)) {
    return css`
      color: ${props.theme.config.colors.tertiary};
      background-color: ${props.theme.config.colors.tertiary_100};
    `;
  }

  return;
};

const StatusElement = styled.span`
  ${(props) => props.theme.config.typograph.xsmall}
  ${(props) => getStyleByProperty(props)}
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 3.2rem;
  user-select: none;
  max-width: max-content;
  padding: 0 1.6rem;
  white-space: nowrap;
`;

export { StatusElement };
