import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Routes } from 'modules/Core/Routes';
import { Selectors, Async } from 'modules/Auth/AuthSlice';
import { ResponseDataHandleServerError, Loading } from 'modules/Core/Common';
import MaintenanceContainer from 'modules/Maintenance/Maintenance/Maintenance.container';
import { IS_MAINTENANCE } from 'modules/Maintenance/Utils/Maintenance.utils';

const AppContainer = ({ whitelabel, outcome }) => {
  const session = useSelector(Selectors.selectAuthData);
  const sessionLoaded = useSelector(Selectors.sessionLoaded);
  const { loading } = useSelector(Selectors.authLoading);
  const serverError = useSelector(Selectors.getServerError);
  const TokenExpired = useSelector(Selectors.getExpiredToken);
  const dispatch = useDispatch();

  useEffect(() => {
    if (session?.user && !TokenExpired) {
      dispatch(Async.checkPermissions());
    }
  }, [sessionLoaded]);

  useEffect(() => {
    dispatch(Async.hydrateSession());
  }, []);

  if (loading) {
    <Loading ousideContainer={true} />;
  }

  if (IS_MAINTENANCE) {
    return <MaintenanceContainer />;
  }

  if (!sessionLoaded || serverError) {
    return <ResponseDataHandleServerError showBackToHomeBtn={false} />;
  }

  return (
    <Routes outcome={outcome} user={session.user} whitelabel={whitelabel} />
  );
};

AppContainer.propTypes = {
  whitelabel: PropTypes.object,
  outcome: PropTypes.any,
};

export default AppContainer;
