import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

const Skeleton = ({
  type,
  isUser = false,
  roundLarge,
  roundExtraLarge,
  secondary,
  ...props
}) => (
  <S.PlaceholderContainer
    className="skeleton-container"
    isUser={isUser}
    {...props}
  >
    <S.Placeholder
      type={type}
      roundLarge={roundLarge}
      roundExtraLarge={roundExtraLarge}
      className="skeleton-element"
      secondary={secondary}
    />
    {isUser && <S.Placeholder type="string" />}
  </S.PlaceholderContainer>
);

Skeleton.propTypes = {
  type: PropTypes.string,
  isUser: PropTypes.bool,
  roundLarge: PropTypes.bool,
  roundExtraLarge: PropTypes.bool,
  secondary: PropTypes.bool,
};

export { Skeleton };
