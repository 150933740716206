import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'modules/Core/Common';

import { ButtonDetails, TooltipDetails } from './ShowDetailsButton.styles';

const ShowDetailsButton = ({ data, label, noDataText, ...props }) => {
  return (
    <Tooltip
      placement="bottom"
      overlay={
        <TooltipDetails>
          {data && data.length ? (
            data.map((text, index) => <p key={index}>{text}</p>)
          ) : (
            <p>{noDataText}</p>
          )}
        </TooltipDetails>
      }
      overlayInnerStyle={{
        backgroundColor: '#0075EA',
        borderRadius: '4px',
        padding: '16px',
      }}
    >
      <ButtonDetails {...props}>{label}</ButtonDetails>
    </Tooltip>
  );
};

ShowDetailsButton.defaultProps = {
  label: 'Show Details',
  noDataText: 'No Details',
};

ShowDetailsButton.propTypes = {
  data: PropTypes.array,
  label: PropTypes.string,
  noDataText: PropTypes.string,
};

export default ShowDetailsButton;
