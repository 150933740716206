import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import passwordGenerator from 'generate-password-browser';
import { EyeIcon, EyeNotIcon, KeyIcon } from '@edusynch/edusynch-svg-icons';

import * as S from './styles';

const Password = ({
  className,
  dataTest,
  PrependIcon,
  suggestPassword,
  onSuggestedPasswordGenerated,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = useCallback(() => setShowPassword(!showPassword));

  const handleSuggestPassword = useCallback(() => {
    const suggestedPassword = passwordGenerator.generate({
      length: 8,
      numbers: true,
    });

    onSuggestedPasswordGenerated(suggestedPassword);
    setShowPassword(true);
  }, []);

  return (
    <S.Container
      className={className}
      PrependIcon={PrependIcon}
      suggestPassword={suggestPassword}
    >
      {PrependIcon && <PrependIcon />}
      <S.InputField
        type={showPassword ? 'text' : 'password'}
        data-testid={dataTest}
        data-id="password"
        PrependIcon={PrependIcon}
        suggestPassword={suggestPassword}
        {...rest}
      />
      {showPassword ? (
        <EyeIcon onClick={togglePassword} color="#ACABB7" />
      ) : (
        <EyeNotIcon onClick={togglePassword} color="#ACABB7" />
      )}
      {suggestPassword && <KeyIcon onClick={handleSuggestPassword} />}
    </S.Container>
  );
};

Password.propTypes = {
  className: PropTypes.string,
  dataTest: PropTypes.string,
  PrependIcon: PropTypes.string,
  suggestPassword: PropTypes.bool,
  onSuggestedPasswordGenerated: PropTypes.func,
};

export { Password };
