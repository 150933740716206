import styled from 'styled-components';
import { device } from 'modules/Utils';

export const ContentFilter = styled.div`
  h6 {
    ${(props) => props.theme.config.typograph.xsmall};
    color: ${(props) => props.theme.config.colors.gray_400};
    margin-bottom: 0.8rem;
  }

  div {
    display: flex;
    align-items: center;

    p {
      ${(props) => props.theme.config.typograph.small};
      color: ${(props) => props.theme.config.colors.gray_400};
      font-weight: bold;
      margin-left: 0.8rem;
    }
  }
`;

export const ContentAction = styled.div`
  margin-left: auto;
`;

export const ScrollableBox = styled.div`
  height: 100%;
  max-height: 180px;
  overflow-y: auto;

  scrollbar-color: ${(props) => props.theme.config.colors.gray_200};

  &::-webkit-scrollbar {
    width: 0.25rem;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.config.colors.gray_100};
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.config.colors.gray_300};
    border-radius: 0.5rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.config.colors.gray_500};
  }

  @media ${device.mobile} {
    max-height: 140px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  @media ${device.tablet} {
    flex-direction: row-reverse;
  }
  @media ${device.mobile} {
    flex-direction: row-reverse;
  }
`;
