import React from 'react';
import PropTypes from 'prop-types';
import Cropper from 'cropperjs';

import 'cropperjs/dist/cropper.min.css';

import {
  Button,
  Icon,
  Tooltip,
  Toast,
  Loader,
  ConfirmDialog,
} from 'modules/Core/Common';
import { useConfirmDialog } from 'modules/Core/Hooks';
import i18n from 'i18next';

import * as S from './ImageCropper.styles';

const IMAGES_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif',
  'image/x-icon',
];

export const ImageCropper = ({
  imageURL,
  imageAlt,
  fileName,
  fileType,
  uploadButtonTitle,
  onCropStart,
  onCropCancel,
  onCropComplete,
  onRemoveImage,
  loading,
  imageContainerStyles,
  cropContainerStyles,
  readOnly,
  custom,
  profileInfo,
}) => {
  const [isCropping, setIsCropping] = React.useState(false);
  const [imagePreviewAsBlob, setImagePreviewAsBlob] = React.useState(null);
  const [imageAsFile, setImageAsFile] = React.useState(null);
  const [cropper, setCropper] = React.useState();

  const fileInput = React.useRef(null);
  const imageRef = React.useRef(null);

  const handleChooseImage = React.useCallback(
    (event) => {
      event.preventDefault();

      if (loading || readOnly) return;

      if (imageURL && !isCropping) return handleEditImage();

      fileInput.current.click();
    },
    [fileInput, imageURL, loading, isCropping]
  );

  const handleEditImage = React.useCallback(() => {
    fetch(imageURL)
      .then((response) => response.blob())
      .then((blob) => {
        const imageFile = new File([blob], fileName, {
          lastModified: Date.now(),
          type: fileType,
        });
        setImagePreviewAsBlob(URL.createObjectURL(blob));
        setImageAsFile(imageFile);
        setIsCropping(true);
      })
      .catch(() => Toast(i18n.t('errors.error-downloading-image'), 'error'));
  });

  const handleChangeImage = React.useCallback(
    (event) => {
      if (!event.target.files.length) return null;
      if (imagePreviewAsBlob) URL.revokeObjectURL(imagePreviewAsBlob);

      if (!IMAGES_TYPES.includes(event.target.files[0].type)) {
        Toast(i18n.t('errors.error-invalid-type-file'), 'error');
      } else {
        setIsCropping(true);
        setImageAsFile(event.target.files[0]);
        setImagePreviewAsBlob(URL.createObjectURL(event.target.files[0]));
      }
    },
    [imagePreviewAsBlob]
  );

  const startCropping = () => {
    if (onCropStart) onCropStart();

    if (cropper) cropper.destroy();

    setCropper(
      new Cropper(imageRef.current, {
        viewMode: 2,
        zoomOnWheel: false,
        movable: false,
      })
    );
  };

  const handleCropCancel = React.useCallback(
    (event) => {
      event.preventDefault();

      if (onCropCancel) onCropCancel();

      fileInput.current.value = null;

      setImageAsFile(null);
      URL.revokeObjectURL(imagePreviewAsBlob);
      setImagePreviewAsBlob(null);
      setIsCropping(false);
    },
    [imagePreviewAsBlob]
  );

  const handleCropSave = React.useCallback((event) => {
    event.preventDefault();

    cropper.getCroppedCanvas().toBlob(
      (blob) => {
        const imageFile = new File([blob], imageAsFile.name, {
          lastModified: Date.now(),
          type: imageAsFile.type,
        });

        onCropComplete(imageFile);
        fileInput.current.value = null;
        URL.revokeObjectURL(imagePreviewAsBlob);
        setImagePreviewAsBlob(null);
        setImageAsFile(null);
        setIsCropping(false);
      },
      'image/jpeg',
      1
    );
  });

  const {
    isConfirmDialogModalOpen,
    setIsConfirmDialogModalOpen,
    handleConfirmDialog,
  } = useConfirmDialog({
    handleConfirm: onRemoveImage,
  });

  return (
    <S.Container>
      {isCropping ? (
        <S.Crop style={cropContainerStyles}>
          <S.CropContainer>
            <img
              ref={imageRef}
              src={imagePreviewAsBlob}
              alt="source"
              onLoad={startCropping}
            />
          </S.CropContainer>
          <S.CropControls>
            <S.CropRotate>
              <Button type="button" onClick={() => cropper.rotate(-90)}>
                <Icon name="rotate-left" />
              </Button>
              <Button type="button" onClick={() => cropper.zoom(-0.1)}>
                <Icon name="zoom-out" />
              </Button>
              <Button type="button" onClick={() => cropper.zoom(0.1)}>
                <Icon name="zoom-in" />
              </Button>
              <Button type="button" onClick={() => cropper.rotate(90)}>
                <Icon name="rotate-right" />
              </Button>
            </S.CropRotate>
            <Tooltip
              placement="top"
              overlay="Change image"
              overlayInnerStyle={{
                backgroundColor: '#0075EA',
                borderRadius: '4px',
                padding: '8px 16px',
                fontSize: '1.6rem',
              }}
            >
              <Button
                type="button"
                data-action="upload"
                onClick={handleChooseImage}
              >
                <Icon name="upload" />
              </Button>
            </Tooltip>
          </S.CropControls>
          <S.CropActions>
            <Button type="button" transparent large onClick={handleCropCancel}>
              {i18n.t('commons.buttons.cancel-button')}
            </Button>
            <Button type="button" large onClick={handleCropSave}>
              {i18n.t('commons.buttons.save-button')}
            </Button>
          </S.CropActions>
        </S.Crop>
      ) : (
        <>
          {imageURL && !loading ? (
            <S.ImageContainer
              custom={custom}
              onClick={handleChooseImage}
              style={imageContainerStyles}
            >
              <img src={imageURL} alt={imageAlt} />
            </S.ImageContainer>
          ) : (
            <S.Placeholder
              onClick={handleChooseImage}
              style={imageContainerStyles}
              profileInfo={profileInfo}
            >
              {loading ? (
                <Loader width="24px" borderWidth="2px" text="Please wait..." />
              ) : (
                <>
                  <img
                    src="https://dwqueqczc80tt.cloudfront.net/public/placeholder_img/img-placeholder.png"
                    alt="placeholder"
                  />
                  <p>{i18n.t('commons.image-upload.drag-image')}</p>
                </>
              )}
            </S.Placeholder>
          )}
          {!readOnly && (
            <S.Actions $loading={loading}>
              <S.ActionsContainer>
                <S.ButtonsContainer hasImage={imageURL}>
                  {imageURL && (
                    <Button
                      type="button"
                      transparent
                      large
                      onClick={() => setIsConfirmDialogModalOpen(true)}
                      hasLoading
                      loading={loading}
                      disabled={loading}
                      data-type="remove"
                    >
                      <Icon
                        name="delete"
                        fontSize="1.2rem"
                        color="white"
                        left
                      />
                      {i18n.t('commons.buttons.remove-button')}
                    </Button>
                  )}
                  <Button
                    type="button"
                    large
                    onClick={handleChooseImage}
                    hasLoading
                    loading={loading}
                    disabled={loading}
                  >
                    {imageURL ? (
                      <Icon name="edit" fontSize="1.2rem" left />
                    ) : (
                      <Icon name="upload" fontSize="1.2rem" left />
                    )}
                    {imageURL ? 'Edit' : uploadButtonTitle}
                  </Button>
                </S.ButtonsContainer>
                {profileInfo && (
                  <span>{i18n.t('commons.avatar-profile.limit-image')}</span>
                )}
              </S.ActionsContainer>
            </S.Actions>
          )}
        </>
      )}
      <S.FileInput
        ref={fileInput}
        type="file"
        accept="image/*"
        onChange={handleChangeImage}
      />
      <ConfirmDialog
        isOpen={isConfirmDialogModalOpen}
        onRequestClose={() => setIsConfirmDialogModalOpen(false)}
        title={i18n.t('commons.avatar-profile.title-confirm')}
        confirmButtonText={i18n.t('commons.buttons.remove-button')}
        cancelButtonText={i18n.t('commons.buttons.cancel-button')}
        loading={loading}
        isCancelHighlighted
        onConfirm={handleConfirmDialog}
      />
    </S.Container>
  );
};

ImageCropper.defaultProps = {
  imageAlt: 'source',
  fileName: `crop${Date.now()}`,
  fileType: 'image/jpeg',
  uploadButtonTitle: 'Select Image',
};

ImageCropper.propTypes = {
  imageURL: PropTypes.string,
  imageAlt: PropTypes.string,
  fileName: PropTypes.string,
  fileType: PropTypes.string,
  uploadButtonTitle: PropTypes.string,
  onCropStart: PropTypes.func,
  onCropCancel: PropTypes.func,
  onCropComplete: PropTypes.func,
  onRemoveImage: PropTypes.func,
  loading: PropTypes.bool,
  imageContainerStyles: PropTypes.object,
  cropContainerStyles: PropTypes.object,
  readOnly: PropTypes.bool,
  custom: PropTypes.bool,
  profileInfo: PropTypes.bool,
};
