import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import {
  Modal,
  Toast,
  Button,
  Icon,
  Title,
  SearchDropdown,
  MUIDateTimePicker,
} from 'modules/Core/Common';
import { UserAvatar } from 'modules/Users/Common';
import { useDate } from 'modules/Core/Hooks/useDate';
import { fetchEvaluators } from 'modules/Users/Common/UserAssociations/UserAssociationsService';
import * as S from './ChangeEvaluatorModal.styles';

const EvaluatorAvatar = ({ id, name, avatar }) => {
  return (
    <UserAvatar id={id} name={name} avatar={avatar?.thumb?.url}>
      <UserAvatar.Photo />
      <UserAvatar.Name />
    </UserAvatar>
  );
};

EvaluatorAvatar.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  avatar: PropTypes.object,
};

const notEqualToZeroOrThirty = (timeValue, clockType) =>
  clockType === 'minutes' && timeValue !== 0 && timeValue !== 30;

export const ChangeEvaluatorModal = ({
  isOpen,
  isAssignToAnotherEvaluator,
  onRequestClose,
  evaluators,
  evaluatorId,
  scheduleDate,
  shouldFetchEvaluators,
  loading,
  onSubmit,
  onSubmitCallback,
  returnObject,
  hasSchedule,
  canRandom,
}) => {
  const [fetchedEvaluators, setFetchedEvaluators] = useState([]);
  const [loadingEvaluators, setLoadingEvaluators] = useState(false);
  const [selectedEvaluator, setSelectedEvaluator] = useState(evaluatorId);
  const [date, setDate] = React.useState(new Date());
  const { date: minDate } = useDate();

  const evaluatorsList = useMemo(() => {
    if (shouldFetchEvaluators) {
      return fetchedEvaluators;
    }

    return evaluators;
  }, [evaluators, fetchedEvaluators]);

  const evaluatorsWithAvatar = useMemo(() => {
    return evaluatorsList?.map((evaluator) => ({
      ...evaluator,
      customOption: <EvaluatorAvatar {...evaluator} />,
      additionalInfo: evaluator?.evaluator_associations?.map(
        (item) => item?.evaluatable_name
      ),
    }));
  }, [evaluatorsList]);

  useEffect(() => {
    function loadEvaluators() {
      setLoadingEvaluators(true);

      fetchEvaluators()
        .then((response) =>
          setFetchedEvaluators(response?.data?.content?.users || [])
        )
        .catch(() =>
          Toast(
            i18n.t(
              'errors.error-sorry-an-error-occurred-during.load-evaluator'
            ),
            'error'
          )
        )
        .finally(() => setLoadingEvaluators(false));
    }

    if (shouldFetchEvaluators) loadEvaluators();
  }, []);

  const getEvaluatorById = React.useCallback(
    (id) => {
      return evaluatorsList?.find((evaluator) => evaluator.id === id);
    },
    [evaluatorsList]
  );

  useEffect(() => {
    setSelectedEvaluator(evaluatorId);

    if (hasSchedule) {
      setDate(scheduleDate || minDate.getTime());
    }

    return () => setSelectedEvaluator(null);
  }, [evaluatorId]);

  const handleSubmit = React.useCallback(() => {
    const evaluator = getEvaluatorById(selectedEvaluator);

    if (returnObject) return onSubmit({ ...evaluator });

    const callback = () => {
      if (typeof onSubmitCallback !== 'function') return null;
      return onSubmitCallback(evaluator);
    };

    if (hasSchedule) onSubmit(evaluator?.id, date, callback);
    else onSubmit(evaluator?.id, null, callback);
  }, [onSubmit, onSubmitCallback, selectedEvaluator, date]);

  return (
    <Modal
      width="418px"
      isOpen={isOpen}
      setIsOpen={onRequestClose}
      overflow="unset"
    >
      <S.FormBody isCenter={isAssignToAnotherEvaluator}>
        <Title tag="h2" size="quartenary">
          {!isAssignToAnotherEvaluator
            ? i18n.t('commons.change-evaluator.change-evaluator')
            : i18n.t('commons.change-evaluator.assign-to-another-evaluator')}
        </Title>
        {!isAssignToAnotherEvaluator && (
          <S.EvaluatorTitle>Evaluator</S.EvaluatorTitle>
        )}
        <SearchDropdown
          placeholder={
            !isAssignToAnotherEvaluator
              ? i18n.t('commons.change-evaluator.evaluator')
              : i18n.t('commons.change-evaluator.change-evaluator')
          }
          options={evaluatorsWithAvatar}
          value={selectedEvaluator ? [selectedEvaluator] : []}
          loading={loadingEvaluators}
          onChangeSelect={(id) => setSelectedEvaluator(id)}
          onChangeUnselect={() => setSelectedEvaluator(null)}
        />
        {hasSchedule && (
          <S.ScheduleDate>
            {!isAssignToAnotherEvaluator && (
              <S.ScheduleTitle>
                {i18n.t('commons.change-evaluator.schedule')}
              </S.ScheduleTitle>
            )}
            <MUIDateTimePicker
              date={date}
              onChange={setDate}
              minDate={minDate}
              inputProps={{ readOnly: true }}
              endIcon
              shouldDisableTime={notEqualToZeroOrThirty}
            />
          </S.ScheduleDate>
        )}

        <S.Footer hasRandom={canRandom && !loadingEvaluators}>
          {canRandom && !loadingEvaluators && evaluatorsWithAvatar?.length && (
            <S.Random
              onClick={() => {
                const newEvaluator =
                  evaluatorsWithAvatar[
                    Math.floor(Math.random() * evaluatorsWithAvatar?.length)
                  ];
                setSelectedEvaluator(newEvaluator?.id);
              }}
            >
              <Icon name="change" />
              <span>{i18n.t('commons.change-evaluator.random')}</span>
            </S.Random>
          )}

          <Button
            large
            type="submit"
            data-test="submit"
            hasLoading
            loading={loading}
            disabled={loading}
            onClick={handleSubmit}
          >
            {i18n.t('commons.change-evaluator.save-change')}
          </Button>
        </S.Footer>

        <S.ButtonClose type="button" onClick={onRequestClose}>
          <Icon fontSize="16px" name="close" />
        </S.ButtonClose>
      </S.FormBody>
    </Modal>
  );
};

ChangeEvaluatorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isAssignToAnotherEvaluator: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
  evaluators: PropTypes.array,
  evaluatorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  scheduleDate: PropTypes.string,
  shouldFetchEvaluators: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitCallback: PropTypes.func,
  returnObject: PropTypes.bool,
  hasSchedule: PropTypes.bool,
  canRandom: PropTypes.bool,
};
