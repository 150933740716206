import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppContainer } from 'modules/Core';
import { ThemeProvider } from 'styled-components';
import { whitelabelConfig } from 'config/whitelabel';
import GlobalStyles from 'assets/styles/createGlobalStyle';
import Head from './Head';
import ErrorBoundary from './config/ErrorBoundary';
import { InternetStatusIndicator } from 'modules/Core/Common';
import { Selectors } from 'modules/Auth/AuthSlice';
import {
  defineAbilityForCurrentUser,
  AbilityContext,
} from 'services/Ability.service';

import './assets/scss/app.scss';

const Root = () => {
  const [whitelabel, setWhitelabel] = useState(null);
  const [ability, setAbility] = useState(null);
  const permissions = useSelector(Selectors.getPermissions);
  const [outcome, setOutcome] = useState(null);

  useEffect(() => {
    const ready = (event) => {
      setOutcome(event);
      event.preventDefault();
    };

    window.addEventListener('beforeinstallprompt', ready);

    return () => {
      window.removeEventListener('beforeinstallprompt', ready);
    };
  }, []);

  useEffect(() => {
    const ability = defineAbilityForCurrentUser(permissions);
    setAbility(ability);
  }, [permissions]);

  useEffect(() => {
    const loadWhitelabel = async () => {
      const name = process.env.REACT_APP_WHITELABEL || 'edusynch';
      const config = await whitelabelConfig(name);
      setWhitelabel({ name, config });
    };

    loadWhitelabel();
  }, [setWhitelabel]);

  if (!whitelabel) {
    // TODO: show loading
    return null;
  }

  return (
    <AbilityContext.Provider value={ability}>
      <ThemeProvider theme={whitelabel}>
        <Head />
        <GlobalStyles />
        <ErrorBoundary>
          <>
            <InternetStatusIndicator />
            <AppContainer
              outcome={outcome}
              whitelabel={{ name: whitelabel.config.info.name }}
            />
          </>
        </ErrorBoundary>
      </ThemeProvider>
    </AbilityContext.Provider>
  );
};

export default hot(module)(Sentry.withProfiler(Root));
