import styled from 'styled-components';
import { Form as Formik } from 'formik';
import { Title } from 'modules/Core/Common';

export const FormGroupSwitch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-bottom: 2.4rem;
`;

export const Form = styled(Formik)`
  position: relative;

  input {
    width: 100%;
    height: 4.8rem;
    border: 1px solid ${(props) => props.theme.config.colors.gray_300};
  }

  select {
    border: 1px solid ${(props) => props.theme.config.colors.gray_300};
  }
`;

export const FooterButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${(props) => props.theme.config.colors.gray_200};
  margin: 0 -3.2rem;
  padding: 2.4rem 3.2rem;

  > button {
    &.btn-cancel {
      background-color: transparent;
      color: ${(props) => props.theme.config.colors.primary};
      border: 1px solid ${(props) => props.theme.config.colors.primary};
      &:hover {
        background-color: ${(props) => props.theme.config.colors.primary};
        color: ${(props) => props.theme.config.colors.light};
      }
    }
    &:last-child {
      margin-left: 1.6rem;
    }
  }
`;

export const FormSectionTitle = styled(Title)`
  margin: ${(props) => props.theme.config.spacing.spacing_3} 0;
`;

export const EnrollmentLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  label:last-child {
    ${(props) => props.theme.config.typograph.mico};
  }
`;
