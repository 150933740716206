import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Actions, Selectors } from 'modules/Auth/AuthSlice';
import { useSelector, useDispatch } from 'react-redux';
import KJUR from 'jsrsasign';
import { find } from 'lodash';

import { TokenExpiredLogin } from 'modules/Core/Common';
import { getToken } from 'modules/Auth/Services';
import { AppLayout } from 'modules/Core/Layout';
import { Footer } from 'modules/Core/Layout/App/Footer';
import { EnvIdentifier } from 'modules/Core/Layout/App/EnvIdentifier';
import { PrivateRoutes } from './config';
import {
  mergeRoutesWithSubmenuIntoASingleArray,
  checkAllAbilitiesToShowMenu,
} from './PrivateRoute.utils';
import { SettingsProfileContainer } from 'modules/SettingsProfile';
import { WidgetsListContainer } from 'modules/Dashboard/Widgets/WidgetsList';
import { isProduction } from 'modules/Utils/Environment';
import { MaintenanceTopBar } from 'modules/Maintenance/Common/MaintenanceTopBar';
import i18n from 'i18next';

const validateRoutesPermission = (routes) => {
  const route = routes.map((route) => ({
    ...route,
    show_on_submenu: checkAllAbilitiesToShowMenu(route.ability),
  }));

  return route;
};

const checkSubmenuPermissions = (submenus) => {
  const checkedSubmenus = submenus?.map((route) => ({
    ...route,
    routes: validateRoutesPermission(route.routes),
  }));

  return checkedSubmenus;
};

const filterPermissionRoutes = (routes) => {
  const filteredMenu = routes.map((menu) => {
    const childMenuHasAbility = find(
      checkSubmenuPermissions(menu?.submenu?.menus),
      {
        routes: [{ show_on_submenu: true }],
      }
    );

    return {
      ...menu,
      show_on_menu:
        !!childMenuHasAbility || checkAllAbilitiesToShowMenu(menu.ability),
      submenu: {
        ...menu.submenu,
        menus: checkSubmenuPermissions(menu?.submenu?.menus),
      },
    };
  });

  return filteredMenu;
};

const PrivateRoute = (props) => {
  const dispatch = useDispatch();
  const tokenExpired = useSelector(Selectors.getExpiredToken);

  useEffect(() => {
    if (!getToken()) return;

    const tokenDecoded = KJUR.jws.JWS.parse(getToken());
    const tokenDecodedToTimestamp = tokenDecoded.payloadObj.exp * 1000;
    const currentTimestamp = Date.now();

    if (currentTimestamp > tokenDecodedToTimestamp) {
      dispatch(Actions.setTokenExpired(true));
    }
  }, [getToken()]);

  const allowedRoutes = filterPermissionRoutes(PrivateRoutes);
  const routesWithoutSubmenuArray = allowedRoutes.filter(
    (routeConfig) => !!routeConfig.path
  );
  const routesWithSubmenuArray =
    mergeRoutesWithSubmenuIntoASingleArray(allowedRoutes);
  const routesMergedArray = [
    ...routesWithoutSubmenuArray,
    ...routesWithSubmenuArray,
  ];

  const routes = routesMergedArray
    .filter((routes) => routes?.show_on_menu || routes?.show_on_submenu)
    .map((routeConfig, i) => (
      <Route key={`router-${routeConfig.name}-${i}`} {...routeConfig} />
    ));

  if (!tokenExpired && !routes.length) {
    // TODO: show loading
    return null;
  }

  return (
    <>
      {tokenExpired && <TokenExpiredLogin />}
      <MaintenanceTopBar />
      <AppLayout {...props} routes={allowedRoutes}>
        {/*
          {props.outcome && <InstallModal outcome={props.outcome} />}
          Removing Install Modal for now
        */}
        <Switch>
          {routes}

          <Route
            exact
            path="/settings-profile"
            component={SettingsProfileContainer}
          />
          <Route
            exact
            path="/dashboard/widgets"
            component={WidgetsListContainer}
          />
          <Redirect to="/dashboard" />
        </Switch>
        <Footer hasIdentifierEnv={!isProduction}>
          {i18n.t('footer.copyright')}
        </Footer>
        {!isProduction && <EnvIdentifier />}
      </AppLayout>
    </>
  );
};

PrivateRoute.propTypes = {
  user: PropTypes.object,
  outcome: PropTypes.any,
  whitelabel: PropTypes.object,
};

export default React.memo(PrivateRoute);
