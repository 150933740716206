import styled from 'styled-components';

const Badge = styled.span`
  ${(props) => props.theme.config.typograph.mico};
  background-color: ${(props) => props.theme.config.colors.background};
  color: ${(props) => props.theme.config.colors.text_base};
  padding: 0.4rem 0.7rem;
  border-radius: 1.2rem;
  font-weight: 700;
  text-align: center;
`;

export { Badge };
