import { useSelector, useDispatch } from 'react-redux';
import {
  Selectors,
  Actions,
  Async,
} from 'modules/Dashboard/Widgets/WidgetsSlice';

export function useWidgets() {
  const state = useSelector(Selectors.fetchListData);
  const dispatch = useDispatch();

  const setActiveWidgets = (widgets) => {
    dispatch(Actions.setActiveWidgets(widgets));
  };

  const addActiveWidget = (widget) => {
    dispatch(Actions.addActiveWidget(widget));
    setUnsavedChanges(true);
  };

  const removeActiveWidget = (widgetId) => {
    dispatch(Actions.removeActiveWidget(widgetId));
    setUnsavedChanges(true);
  };

  const setUnsavedChanges = (isSaved) => {
    dispatch(Actions.setUnsavedChanges(isSaved));
  };

  const saveWidgetSettings = (settings) => {
    dispatch(Actions.saveWidgetSettings(settings));
    setUnsavedChanges(true);
  };

  const saveWidgets = () => {
    dispatch(Async.saveWidgets());
  };

  return {
    state,
    setActiveWidgets,
    addActiveWidget,
    removeActiveWidget,
    setUnsavedChanges,
    saveWidgetSettings,
    saveWidgets,
  };
}
