export const createAPIUserModel = (apiUser = {}) => {
  return {
    id: apiUser.id || null,
    externalApplicationId: apiUser.external_application?.id || '',
    name: apiUser.name || '',
    email: apiUser.email || '',
    password: '',
    passwordConfirmation: '',
  };
};

export const createAPIUserRequestModel = (apiUser = {}) => {
  const apiUserRequestModel = {
    id: apiUser.id,
    external_application_id:
      apiUser.externalApplicationId ||
      apiUser.external_application_id ||
      apiUser.external_application?.id,
    name: apiUser.name,
    email: apiUser.email,
  };

  if (apiUser.password && apiUser.passwordConfirmation) {
    apiUserRequestModel.password = apiUser.password;
    apiUserRequestModel.password_confirmation = apiUser.passwordConfirmation;
  }

  return apiUserRequestModel;
};
