import React from 'react';
import PropTypes from 'prop-types';
import Placeholder from 'assets/avatar/placeholder.png';
import { DragAndDrop, Icon } from 'modules/Core/Common';
import i18n from 'i18next';

import 'react-image-crop/dist/ReactCrop.css';
import * as S from './styles';

const AvatarInput = ({ size, handleChooseImage }) => {
  return (
    <S.AvatarContainer size={size}>
      <DragAndDrop>
        <S.Avatar size={size}>
          <div className="editImageIcon">
            <Icon name="edit" onClick={handleChooseImage} />
          </div>
          <S.AvatarPlaceholder src={Placeholder} />
          <S.AvatarText>
            {i18n.t('commons.avatar-profile.add-image')}
            <br /> {i18n.t('commons.avatar-profile.your-profile')}
          </S.AvatarText>
        </S.Avatar>
      </DragAndDrop>
    </S.AvatarContainer>
  );
};

AvatarInput.propTypes = {
  handleChooseImage: PropTypes.func.isRequired,
  size: PropTypes.string,
};

export default AvatarInput;
