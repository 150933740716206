import styled from 'styled-components';
import { MenuItem } from 'modules/Core/Common';
import { device } from 'modules/Utils/Devices';
import { ZIndexes } from 'modules/Utils/ZIndexes';

const DropdownContainer = styled.div`
  margin-left: ${(props) => props.theme.config.spacing.spacing_3};

  .header-dropdown__menu {
    cursor: pointer;
  }

  .rc-dropdown .rc-menu {
    border: 0;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    width: max-content;
  }

  .edusynch-dropdown {
    top: 55px !important;

    &::before {
      right: 10px;
      top: -4px;
      z-index: ${ZIndexes.header};
    }
    &:hover {
      &::before {
        background: ${(props) => props.theme.config.colors.light} !important;
      }
      .rc-dropdown-menu-item-active {
        background-color: ${(props) => props.theme.config.colors.light};
      }
    }
  }

  @media ${device.mobile} {
    margin-left: ${(props) => props.theme.config.spacing.spacing_2};
  }
`;

const MenuItemContainer = styled(MenuItem)`
  &&& {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1.6rem;
    color: ${(props) => props.theme.config.colors.text_base};
  }

  & + li {
    border-top: 1px solid ${(props) => props.theme.config.colors.gray_100};
  }

  svg {
    width: 16px;
    margin-right: ${(props) => props.theme.config.spacing.spacing_2};

    path {
      fill: ${(props) => props.theme.config.colors.gray_300};
    }
  }

  span {
    ${(props) => props.theme.config.typograph.default};
  }
`;

const IconContainer = styled.div`
  cursor: pointer;
  background-color: ${(props) => props.theme.config.colors.gray_100};
  border-radius: 100%;
  width: ${(props) => props.theme.config.spacing.spacing_5};
  height: ${(props) => props.theme.config.spacing.spacing_5};
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    path {
      fill: ${(props) => props.theme.config.colors.gray_400};
    }
  }
`;

export { DropdownContainer, MenuItemContainer, IconContainer };
