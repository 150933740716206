import styled from 'styled-components';
import { device } from 'modules/Utils/Devices';
import { Card, RouterLink } from 'modules/Core/Common';

export const Container = styled.div`
  width: min-content;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: ${(props) => props.theme.config.spacing.layout_4};

  @media ${device.desktopSmall.replace('1199.98', '1325')} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.mobile} {
    width: 100%;
    grid-template-columns: 1fr;
    gap: 1.6rem;
    margin-top: ${(props) => props.theme.config.spacing.layout_3};
  }
`;

export const StyledCard = styled(Card)`
  width: 270px;
  height: 184px;
  margin: 1.5rem;

  @media ${device.mobile} {
    width: 100%;
    height: 77px;
    padding: 1.6rem 2.4rem;
    margin: 0.4rem 0;
  }
`;

export const NavigationCard = styled(RouterLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  span {
    color: ${(props) => props.theme.config.colors.text_base};
    margin-top: ${(props) => props.theme.config.spacing.spacing_4};
    ${(props) => props.theme.config.typograph.default};
  }

  @media ${device.mobile} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    div {
      width: 4.8rem;
      height: 4.8rem;
    }

    span {
      align-self: center;
      margin-top: 0;
      margin-left: 1.6rem;
    }
  }
`;
