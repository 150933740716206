export const createQueryStringFromObject = (obj) => {
  if (obj === null || obj === undefined || typeof obj !== 'object') return obj;

  let queryString = '';

  Object.keys(obj).forEach((key) => {
    if (!queryString) {
      queryString = obj[key] !== 'null' ? `?${key}=${obj[key]}` : '';
    } else {
      queryString += obj[key] !== 'null' ? `&${key}=${obj[key]}` : '';
    }
  });

  return queryString;
};
