import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'modules/Core/Common';

import * as S from './PaginationNextButton.styles';

const PaginationNextButton = ({ onClick, disabled }) => {
  return (
    <S.Button
      onClick={onClick}
      data-test="pagination-next-button"
      disabled={disabled}
    >
      <S.Text>
        <Icon name="arrow-right" />
      </S.Text>
    </S.Button>
  );
};

PaginationNextButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default PaginationNextButton;
