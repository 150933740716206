import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { Line, Bar } from 'react-chartjs-2';
import { IconButton, Box } from '@mui/material';
import i18n from 'i18next';
import { Chart as ChartJS, Tooltip, ArcElement, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

import { Icon } from 'modules/Core/Common';
import {
  WidgetCard,
  WidgetCardHeader,
  WidgetCardSubHeader,
  WidgetCardContent,
  WidgetEmptyData,
  IntervalOptionsMenu,
} from 'modules/Dashboard/Widgets/Common';
import {
  getChartLabelsByWidgetSize,
  formatChartLabels,
  formatChartValues,
  formatChartCompareValues,
  formatChartScaleYStepSize,
  formatChartData,
  formatTimeSpent,
  formatCompareTimeSpent,
  formatChartOptions,
  formatDate,
} from 'modules/Dashboard/Widgets/EProctoring/SessionsCount/SessionsCount.utils';
import { useMediaQuery, MOBILE_SIZE, TABLET_SIZE } from 'modules/Core/Hooks';
import { EProctoringPluginIcon } from '@edusynch/edusynch-svg-icons';
import emptyEventsImg from 'assets/layout/empty-data/widgets-empty-data.svg';

import * as S from './SessionsCount.styles';

const I18N_PREFIX = 'modules.widgets.eproctoring_sessions_count';

export const SessionsCount = ({
  widget,
  intervalOption,
  onChangeIntervalOption,
  data,
  onOpenSettings,
  onOpenFilter,
  hasFiltersActive,
  isMock,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(MOBILE_SIZE);
  const isTablet = useMediaQuery(TABLET_SIZE);

  const widgetSize = isMobile || isTablet ? 'small' : widget?.settings?.size;

  const chartLabels = getChartLabelsByWidgetSize(data, widgetSize);
  const formattedChartLabels = formatChartLabels(
    chartLabels,
    intervalOption,
    widgetSize
  );
  const formattedChartData = formatChartValues(data);
  const formattedChartCompareData = formatChartCompareValues(data);
  const chartScaleYStepSize = formatChartScaleYStepSize(formattedChartData);
  const chartData = formatChartData(
    formattedChartLabels,
    formattedChartData,
    formattedChartCompareData,
    theme.config.colors.primary,
    widgetSize === 'large'
      ? theme.config.colors.orange
      : theme.config.colors.gray,
    i18n.t(`${I18N_PREFIX}.title`),
    widgetSize
  );
  const timeSpentArray = formatTimeSpent(data);
  const compareTimeSpentArray = formatCompareTimeSpent(data);
  const chartOptions = formatChartOptions(
    chartScaleYStepSize,
    timeSpentArray,
    compareTimeSpentArray,
    i18n.t(`${I18N_PREFIX}.sessions`),
    i18n.t(`${I18N_PREFIX}.time-spent`),
    widgetSize,
    theme.config.colors.gray
  );

  const hasData =
    !!data?.sessions?.session_count || !!data?.compare?.session_count;

  const shouldShowPeriod = !isMobile && widgetSize === 'large';

  return (
    <WidgetCard size={widgetSize}>
      <WidgetCardHeader
        size={widgetSize}
        Icon={EProctoringPluginIcon}
        title={i18n.t(`${I18N_PREFIX}.title`)}
        subtitle={i18n.t(`${I18N_PREFIX}.subtitle`)}
        showSettingsButton={isMock && widgetSize === 'small'}
        showFiltersButton
        onOpenSettings={onOpenSettings}
        onOpenFilters={onOpenFilter}
        hasFiltersActive={hasFiltersActive}
        borderBottom={widgetSize === 'small'}
      >
        <WidgetCardSubHeader size={widgetSize}>
          <S.ContentAction>
            <h6>
              {i18n.t('common-words.last')} {intervalOption}{' '}
              {intervalOption === '12'
                ? i18n.t('common-words.months')
                : i18n.t('common-words.days')}
            </h6>
            {isMock && widgetSize === 'large' && (
              <IconButton
                aria-label="settings"
                id="settings"
                onClick={onOpenSettings}
              >
                <Icon name="settings" />
              </IconButton>
            )}
            <IntervalOptionsMenu
              defaultOption={intervalOption}
              onChangeOption={onChangeIntervalOption}
            />
            {widgetSize === 'large' && (
              <IconButton
                aria-label="users-filter"
                id="users-filter"
                onClick={onOpenFilter}
                sx={
                  hasFiltersActive
                    ? {
                        position: 'relative',
                        '::before': {
                          content: '""',
                          position: 'absolute',
                          width: '0.8rem',
                          height: '0.8rem',
                          borderRadius: '50%',
                          top: '0.8rem',
                          right: '0.8rem',
                          bgcolor: theme.config.colors.green,
                        },
                      }
                    : undefined
                }
              >
                <Icon name="filter" />
              </IconButton>
            )}
          </S.ContentAction>
        </WidgetCardSubHeader>
      </WidgetCardHeader>
      <WidgetCardContent size={widgetSize} borderTop>
        {hasData ? (
          <>
            {widgetSize === 'large' ? (
              <Line data={chartData} options={chartOptions} />
            ) : (
              <Box
                sx={{
                  height: 'inherit',
                  border: `1px solid ${theme.config.colors.gray_100}`,
                  bgcolor: theme.config.colors.gray_50,
                  padding: '0.8rem 1.6rem',

                  '& canvas': {
                    width: '100%',
                    height: '180px !important',
                  },
                }}
              >
                <Bar data={chartData} options={chartOptions} />
                <S.Legend>
                  <span>{i18n.t('common-words.previous')}</span>
                  <span>{i18n.t('common-words.current')}</span>
                </S.Legend>
              </Box>
            )}
          </>
        ) : (
          <WidgetEmptyData
            imageURL={emptyEventsImg}
            title={i18n.t(`${I18N_PREFIX}.empty_data.title`)}
            subtitle={i18n.t(`${I18N_PREFIX}.empty_data.subtitle`)}
          />
        )}
      </WidgetCardContent>
      {hasData && (
        <S.Footer
          style={{ paddingTop: widgetSize === 'small' ? '1.6rem' : '' }}
        >
          {shouldShowPeriod && (
            <S.Period>
              <p>
                {formatDate(data?.sessions?.start)} {i18n.t('common-words.to')}{' '}
                {formatDate(data?.sessions?.end)}
              </p>
              <p>
                {formatDate(data?.compare?.start)} {i18n.t('common-words.to')}{' '}
                {formatDate(data?.compare?.end)}
              </p>
            </S.Period>
          )}
          <S.OverallSessions fullWidth={widgetSize === 'small'}>
            <span>
              {i18n.t(`${I18N_PREFIX}.overall-sessions`)}{' '}
              <strong>{data?.sessions?.session_count?.toLocaleString()}</strong>
            </span>
          </S.OverallSessions>
        </S.Footer>
      )}
    </WidgetCard>
  );
};

SessionsCount.propTypes = {
  widget: PropTypes.object,
  data: PropTypes.object,
  intervalOption: PropTypes.string,
  onChangeIntervalOption: PropTypes.func,
  onOpenSettings: PropTypes.func,
  onOpenFilter: PropTypes.func,
  hasFiltersActive: PropTypes.bool,
  isMock: PropTypes.bool,
};
