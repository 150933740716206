import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import * as S from './PaginationPageNumber.styles';

const PaginationPageNumber = ({ currentPage, onPageClick, totalPages }) => {
  const [page, setPage] = useState(currentPage);

  const changePage = useCallback((event) => {
    const { value } = event.target;
    if (Number(value) > totalPages || Number(value) < 0) return;
    setPage(event.target.value);
  }, []);

  const onKeyDown = useCallback(
    (event) => {
      if (event.key === i18n.t('commons.tables.pagination.enter')) {
        onPageClick(Number(page));
      }

      if (event.key === i18n.t('commons.tables.pagination.escape')) {
        setPage(currentPage);
      }
    },
    [page]
  );

  return (
    <S.InputBox
      value={page}
      onChange={changePage}
      onKeyDown={onKeyDown}
      type="number"
      min={1}
      max={totalPages}
      data-test="page-input"
    />
  );
};

PaginationPageNumber.defaultProps = {
  active: false,
};

PaginationPageNumber.propTypes = {
  onPageClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
  behavior: PropTypes.string,
  search: PropTypes.string,
  currentPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  customQueryParams: PropTypes.any,
  totalPages: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default PaginationPageNumber;
