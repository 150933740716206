import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as S from './Input.styles';
import { CheckIcon } from '@edusynch/edusynch-svg-icons';

const Input = React.forwardRef((props, ref) => {
  const {
    success,
    border,
    AppendIcon,
    inputRef,
    onAppendIconClick,
    PrependIcon,
    rounded,
    medium,
    customPlaceholder,
    customPlaceholderActive,
    value,
    ...rest
  } = props;

  const hasValue = useMemo(() => {
    return typeof value === 'string' ? !!value : value > 0 || value === 0;
  }, [value]);

  return (
    <S.Container PrependIcon={PrependIcon}>
      {PrependIcon && <PrependIcon color="#acabb7" />}
      <S.Input
        border={border}
        ref={inputRef || ref}
        success={success}
        {...rest}
        autoComplete="off"
        AppendIcon={AppendIcon}
        PrependIcon={PrependIcon}
        rounded={rounded}
        medium={medium}
        value={value}
        data-cy={rest.dataCy}
      />
      {customPlaceholder && customPlaceholderActive && hasValue ? (
        <S.Label hasValue={hasValue}>{customPlaceholderActive}</S.Label>
      ) : customPlaceholder ? (
        <S.Label hasValue={hasValue}>{customPlaceholder}</S.Label>
      ) : null}
      {success && <CheckIcon type="small" />}
      {AppendIcon && (
        <AppendIcon data-type="append" onClick={onAppendIconClick} />
      )}
    </S.Container>
  );
});

Input.propTypes = {
  props: PropTypes.any,
  success: PropTypes.any,
  inputRef: PropTypes.any,
  border: PropTypes.bool,
  AppendIcon: PropTypes.func,
  onAppendIconClick: PropTypes.func,
  PrependIcon: PropTypes.func,
  dataCy: PropTypes.string,
  rounded: PropTypes.bool,
  customPlaceholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customPlaceholderActive: PropTypes.string,
  medium: PropTypes.bool,
};

export default Input;
