import styled, { css } from 'styled-components';

export const Button = styled.button`
  outline: none;
  background: transparent;
  border: none;

  ${(props) =>
    props.isToggled &&
    css`
      svg path {
        fill: ${props.theme.config.colors.primary};
      }
    `}
`;
