import React from 'react';
import PropTypes from 'prop-types';
import { useDroppable } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';

import { SortableWidgetContainerItem } from './SortableWidgetContainerItem.component';
import { WIDGETS_LIST_DROPPABLE_CONTAINER } from '../WidgetsDragAndDrop.utils';

import * as S from './WidgetsList.styles';

export const WidgetsList = ({
  activeWidgets,
  isDragging,
  activeDragItemWidget,
}) => {
  const { setNodeRef } = useDroppable({
    id: WIDGETS_LIST_DROPPABLE_CONTAINER,
  });

  return (
    <S.Container>
      <SortableContext items={activeWidgets}>
        {activeWidgets?.map((widget) => (
          <SortableWidgetContainerItem
            key={widget.id}
            widget={widget}
            isDragging={isDragging}
          />
        ))}
        {isDragging && !activeDragItemWidget && (
          <S.WidgetDropContainer ref={setNodeRef} />
        )}
      </SortableContext>
    </S.Container>
  );
};

WidgetsList.propTypes = {
  activeWidgets: PropTypes.array,
  isDragging: PropTypes.any,
  activeDragItemWidget: PropTypes.any,
};
