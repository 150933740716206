import React from 'react';
import PropTypes from 'prop-types';
import * as S from './ValidationError.styles';

const ValidationError = ({ children, ...props }) => (
  <S.ValidationError {...props}>{children}</S.ValidationError>
);

ValidationError.propTypes = {
  children: PropTypes.string.isRequired,
};

export default ValidationError;
