import styled from 'styled-components/macro';
import { device } from 'modules/Utils/Devices';

const FormGroup = styled.div`
  position: relative;

  & + div:not(.auth-btn-container) {
    margin-top: 2.4rem;
  }

  label {
    @media ${device.tabletAndMobile} {
      color: ${(props) => props.theme.config.colors.light};
    }
  }

  svg {
    pointer-events: none;
    width: 1.4rem;

    path {
      fill: ${(props) => props.theme.config.colors.text_base} !important;
      opacity: 0.5;
    }
  }
`;

const LinkForgot = styled.button`
  border: none;
  background: transparent;
  display: block;
  margin: 0.8rem 0 0 auto;
  color: ${(props) => props.theme.config.colors.text_base};

  @media ${device.tabletAndMobile} {
    & {
      color: ${(props) => props.theme.config.colors.light};
    }
  }
`;

const FullWidth = styled.div`
  width: 100%;

  @media (min-width: 1024px) {
    padding-top: 1rem;
  }

  button {
    width: 100%;
  }
`;

export { LinkForgot, FormGroup, FullWidth };
