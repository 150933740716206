import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  padding: 1.6rem 2.4rem;
  border-radius: 4px;
  overflow: hidden;
  max-width: 40rem;
  border-left: 4px solid red;
`;

const getColorsByType = (type) => {
  switch (type) {
    case 'success':
      return {
        color: '#1BD171',
        bg: 'rgba(27, 209, 113, .1)',
      };
    case 'warn':
      return {
        color: '#FBAB34',
        bg: 'rgba(251, 171, 52, .1)',
      };
    case 'info':
      return {
        color: '#0075EA',
        bg: 'rgba(0, 117, 234, .1)',
      };
    case 'error':
      return {
        color: '#EC3237',
        bg: 'rgba(236, 50, 55, .1)',
      };
    default:
      return '';
  }
};

const IconBlock = styled.div`
  svg {
    width: 1.6rem;
    height: 1.6rem;
    padding: 1.2rem !important;
    overflow: visible;
    border-radius: 50%;
    background: ${(props) => getColorsByType(props.type).bg};
    display: flex;
    align-items: center;
    justify-content: center;

    path {
      fill: ${(props) => getColorsByType(props.type).color};
    }
  }
`;

export { IconBlock, Container };
