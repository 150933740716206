import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Label, SelectAutocomplete } from 'modules/Core/Common';
import { renameObjectKey } from 'modules/Utils/RenameObjectKey';
import i18n from 'i18next';

export const SelectTimezone = ({ id, label, options, ...props }) => {
  const reorderd = useMemo(() => {
    return [...options].sort(
      (a, b) => parseFloat(a.offset) - parseFloat(b.offset)
    );
  }, [options]);

  let optionsData = reorderd.map((timezone) => ({
    ...timezone,
    name: `(${timezone.offset}) ${timezone.name}`,
  }));

  if (optionsData.length) {
    optionsData = renameObjectKey(optionsData, 'tzname', 'id');
  }

  return (
    <>
      <Label htmlFor={id}>{label || i18n.t('common-words.timezone')}</Label>
      <SelectAutocomplete
        id={id}
        placeholder={`Select a ${label || i18n.t('common-words.timezone')}`}
        searchPlaceholder={`Search a ${
          label || i18n.t('common-words.timezone')
        }`}
        options={optionsData}
        nonRedorder
        {...props}
      />
    </>
  );
};

SelectTimezone.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
};
