import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  .MuiSlider-root {
    height: 0.7rem;
  }

  .MuiSlider-rail {
    background-color: ${(props) => props.theme.config.colors.secondary_400};
    opacity: 1;
  }

  .MuiSlider-track {
    background-color: ${(props) => props.theme.config.colors.gray_200};
    border: 0;
    transition: inherit;
  }

  .MuiSlider-thumb {
    display: none;
  }
`;
