import styled from 'styled-components';
// import { device } from 'modules/Utils';

export const Container = styled.div`
  h2 {
    ${(props) => props.theme.config.typograph.mico};
    color: ${(props) => props.theme.config.colors.text_base};
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1.2rem;
  }
`;

export const GroupBoolean = styled.div``;

export const RadioGroup = styled.div`
  margin-left: 2.4rem;
`;
