import styled, { css } from 'styled-components';
import { ZIndexes } from 'modules/Utils/ZIndexes';

export const AudioContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding: 0.8rem 2.4rem 0.9rem 2.4rem !important;
  width: ${(props) => (props.error ? '398px' : '350px')};
  height: 50px;
  background: ${(props) => props.theme.config.colors.primary_200};
  border-radius: 6px;

  ${(props) =>
    props.dark &&
    css`
      background: ${(props) => props.theme.config.colors.primary};
    `}

  ${(props) =>
    props.secondary &&
    css`
      background: ${(props) => props.theme.config.colors.green_200};
    `}
`;

export const ButtonPlayer = styled.div`
  background: ${(props) => props.theme.config.colors.primary_100};
  border-radius: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.4rem;
  cursor: pointer;
  position: relative;
  min-width: 3.2rem;
  height: 3.2rem;
  margin-right: ${(props) => props.theme.config.spacing.spacing_3};

  svg {
    width: 1.4rem;
    height: 1.4rem;

    path {
      fill: ${(props) => props.theme.config.colors.primary};
    }
  }

  ${(props) =>
    props.dark &&
    css`
      background: ${(props) => props.theme.config.colors.gray_100};

      svg path {
        fill: ${(props) => props.theme.config.colors.gray_200};
      }
    `}

  ${(props) =>
    props.secondary &&
    css`
      background: ${(props) => props.theme.config.colors.green_200};

      svg path {
        fill: ${(props) => props.theme.config.colors.green};
      }
    `}
`;

export const Timer = styled.div`
  display: block;
`;

export const CurrentDuration = styled.span`
  ${(props) => props.theme.config.typograph.xsmall};
  color: ${(props) => props.theme.config.colors.primary};
  white-space: nowrap;
  user-select: none;

  ${(props) =>
    props.dark &&
    css`
      color: ${(props) => props.theme.config.colors.gray_200};
    `}

  ${(props) =>
    props.secondary &&
    css`
      color: ${(props) => props.theme.config.colors.green};
    `}
`;

export const TotalDuration = styled.span`
  ${(props) => props.theme.config.typograph.xsmall};
  color: ${(props) => props.theme.config.colors.primary};
  white-space: nowrap;
  user-select: none;

  ${(props) =>
    props.dark &&
    css`
      color: ${(props) => props.theme.config.colors.gray_200};
    `}

  ${(props) =>
    props.secondary &&
    css`
      color: ${(props) => props.theme.config.colors.green};
    `}
`;

export const SeekBar = styled.input`
  -webkit-appearance: none;
  background: ${(props) => props.theme.config.colors.primary};
  width: 110px;
  height: 4px;
  border-color: ${(props) => props.theme.config.spacing.primary};
  border-radius: 16px;
  margin-right: ${(props) => props.theme.config.spacing.spacing_3};
  margin-left: ${(props) => props.theme.config.spacing.spacing_3};
  transition: width 0.1s linear;
  cursor: pointer;
`;

export const SpaceIconsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  svg path {
    fill: ${(props) => props.theme.config.colors.primary};
  }

  ${(props) =>
    props.dark &&
    css`
      svg path {
        fill: ${(props) => props.theme.config.colors.gray_200};
      }
    `}

  ${(props) =>
    props.secondary &&
    css`
      svg path {
        fill: ${(props) => props.theme.config.colors.green};
      }
    `}
`;

export const IconThreeDots = styled.div`
  position: relative;
  cursor: pointer;
  svg circle {
    fill: ${(props) => props.theme.config.colors.primary};
  }
  ${(props) =>
    props.secondary &&
    css`
      svg circle {
        fill: ${(props) => props.theme.config.colors.green};
      }
    `}
  ${(props) =>
    props.dark &&
    css`
      svg circle {
        fill: ${(props) => props.theme.config.colors.gray_200};
      }
    `}
  .AudioDownload {
    z-index: ${ZIndexes.audioDownload};
    position: absolute;
    bottom: -6rem;
    background-color: ${(props) => props.theme.config.colors.light};
    color: ${(props) => props.theme.config.colors.text_base};
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    > a {
      display: flex;
      align-items: center;
      padding: 1.6rem;
      &:hover {
        background-color: ${(props) => props.theme.config.colors.gray_300};
      }
      svg path {
        fill: ${(props) => props.theme.config.colors.text_base};
      }
      > span {
        font-size: 14px;
        line-height: 16px;
        margin-left: 16px;
        ::selection {
          background: transparent;
          color: default;
        }
        ::-moz-selection {
          background: transparent;
          color: default;
        }
      }
    }
  }
`;

export const Volume = styled.div`
  display: block;
  position: relative;

  &:hover {
    input {
      opacity: 1;
      visibility: visible;
      transition: 0.1s ease-in;
    }
  }
`;

export const ToggleVolume = styled.input`
  position: absolute;
  left: 50%;
  bottom: 0;
  left: -23px;
  bottom: 54px;
  max-width: 7rem;
  height: 0.5rem;
  appearance: none;
  transform: rotate(-90deg);
  background: ${(props) => props.theme.config.colors.primary};
  border-radius: 1rem;
  opacity: 0;
  visibility: hidden;
  transition: 0.1s ease-in;
  cursor: pointer;

  &::-webkit-slider-thumb {
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
  }

  ${(props) =>
    props.dark &&
    css`
      background: ${(props) => props.theme.config.colors.green_200};

      &::-webkit-slider-thumb {
        background: ${(props) => props.theme.config.colors.green};
      }
    `}

  ${(props) =>
    props.secondary &&
    css`
      background: ${(props) => props.theme.config.colors.green_200};

      &::-webkit-slider-thumb {
        background: ${(props) => props.theme.config.colors.green};
      }
    `}
`;

export const Error = styled.div`
  border-left: 1px solid ${(props) => props.theme.config.colors.primary_100};
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.4rem;
  padding: 0 1.2rem;

  svg {
    path {
      fill: ${(props) => props.theme.config.colors.tertiary_400};
    }
  }
`;

export const ErrorMessage = styled.p`
  max-width: 19.3rem;
  text-align: center;
  margin: 0 auto;
`;
