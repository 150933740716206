import styled from 'styled-components';
import { device } from 'modules/Utils';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(props) => props.theme.config.spacing.spacing_7};

  > div:first-child {
    margin-bottom: 0;
  }

  button {
    svg {
      width: 1.6rem;
      height: 1.6rem;
      margin-right: 0.4rem;
    }
  }

  @media ${device.mobile} {
    margin-bottom: ${(props) => props.theme.config.spacing.spacing_4};

    button {
      padding: 0 1.6rem;
      min-width: 9.6rem;
      height: 3.2rem;

      &[data-action='save-dashboard'] {
        font-size: 1.6rem;
        position: fixed;
        width: 60%;
        height: 4.8rem;
        z-index: 5;
        left: 50%;
        transform: translate(-50%, -50%);
        bottom: 4rem;
        margin-left: 0;
      }
    }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;

  button {
    & + button {
      margin-left: 1.6rem;
    }
  }
`;
