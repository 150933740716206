import React from 'react';
import PropTypes from 'prop-types';

import { capitalizeWord } from 'modules/Utils';

import * as S from './WaitingStatus.styles';

const getStatusByValue = (value) => {
  if (typeof value === 'string') {
    return capitalizeWord(value);
  }

  return 'N/A';
};

const WaitingStatus = ({ value, ...props }) => {
  return (
    <S.StatusElement value={value} {...props}>
      {getStatusByValue(value)}
    </S.StatusElement>
  );
};

WaitingStatus.propTypes = {
  value: PropTypes.string,
};

export default WaitingStatus;
