import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isValid } from 'date-fns';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Stack from '@mui/material/Stack';
import { MobileTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Icon } from 'modules/Core/Common';
import { useTheme } from 'styled-components';
import { useAuth } from 'modules/Auth/Hooks';
import {
  convertUserTimezoneToUTC,
  convertUTCDateToUserTimezone,
} from 'modules/Utils/Date';
import * as S from './MUITimePicker.styles';

const Field = styled(TextField)`
  input {
    color: ${(props) => props.theme.config.colors.text_base};
  }

  fieldset {
    border: none;
  }
`;

const theme = createTheme({
  typography: {
    fontSize: 20,
  },
});

export const MUITimePicker = ({
  time,
  onChange,
  textFieldProps,
  noIcon,
  noEndIcon,
  startIcon,
  hasBorder,
  ...props
}) => {
  const { userTimezone, isAMPM } = useAuth();
  const defaultTheme = useTheme();
  const bordered = `solid 1px ${defaultTheme.config.colors.gray_300}`;

  let timeValue = userTimezone
    ? convertUTCDateToUserTimezone(time, userTimezone)
    : time;

  const handleChange = (item) => {
    if (isValid(item)) {
      if (userTimezone) {
        const newDateUtc = convertUserTimezoneToUTC(item, userTimezone);
        return onChange(newDateUtc);
      }
      return onChange(item);
    }
  };

  return (
    <S.Container>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack spacing={3}>
          <ThemeProvider theme={theme}>
            {!!textFieldProps?.placeholder && !(time || props.value) && (
              <S.Placeholder>{textFieldProps.placeholder}</S.Placeholder>
            )}
            <MobileTimePicker
              value={timeValue}
              ampm={isAMPM}
              onChange={handleChange}
              InputProps={{
                startAdornment: !noIcon && (
                  <Icon name={startIcon || 'time'} left />
                ),
                endAdornment: !noEndIcon && <Icon name="arrow-down" />,
                sx: {
                  fontSize: 16,
                  background: 'white',
                  height: '4.8rem',
                  border: hasBorder ? bordered : 'none',
                  i: {
                    fontSize: '1.5rem',
                    marginRight: '0.7rem',
                  },
                },
              }}
              renderInput={(params) => (
                <Field {...params} {...textFieldProps} />
              )}
              {...props}
            />
          </ThemeProvider>
        </Stack>
      </LocalizationProvider>
    </S.Container>
  );
};

MUITimePicker.propTypes = {
  time: PropTypes.any,
  onChange: PropTypes.func,
  textFieldProps: PropTypes.object,
  noIcon: PropTypes.bool,
  noEndIcon: PropTypes.bool,
  startIcon: PropTypes.string,
  hasBorder: PropTypes.bool,
  value: PropTypes.any,
};
