import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { RouterLink } from 'modules/Core/Common';
import * as S from './AuthSidebar.style';

const AuthSidebar = ({ theme }) => {
  const [imageSrc, setImage] = useState(null);
  const [imageSrcLogo, setImageLogo] = useState(null);

  useEffect(() => {
    const loadImage = async () => {
      const img = await theme.config.images.authSidebar();
      const logo = await theme.config.images.authLogo();
      setImage(img.default, logo.default);
      setImageLogo(logo.default);
    };
    loadImage();
  }, [theme]);

  if (imageSrc) {
    return (
      <S.Container imgSrc={imageSrc}>
        <S.Content>
          <RouterLink to="/" title={theme.config.info.title}>
            <S.Logo src={imageSrcLogo} />
          </RouterLink>

          <S.Description>
            {theme.config.info.linkDescription}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`mailto:${theme.config.info.email}`}
            >
              <span
                dangerouslySetInnerHTML={{ __html: theme.config.info.subtitle }}
              />
            </a>
          </S.Description>
        </S.Content>
      </S.Container>
    );
  }

  return null;
};

AuthSidebar.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(AuthSidebar);
