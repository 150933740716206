import React from 'react';
import PropTypes from 'prop-types';
import RCDropzone from 'react-dropzone';
import { InfoIcon, IconRoundBox } from 'modules/Core/Common';
import { UploadIcon } from '@edusynch/edusynch-svg-icons';
import i18n from 'i18next';

import * as S from './Dropzone.styles';

export const Dropzone = ({
  accept,
  onDropAccepted,
  isFilesSelected,
  fileInputRef,
  children,
  ...props
}) => {
  const renderDropzoneText = (isDragActive, isDragReject) => {
    return (
      <>
        <InfoIcon
          thin
          before={
            <IconRoundBox withBg bg="rgba(0, 117, 234, .1)">
              <UploadIcon width={24} />
            </IconRoundBox>
          }
        />
        <S.UploadMessage isSelectedFile={isFilesSelected}>
          <p>
            {isDragActive ? (
              <span>
                {isDragReject
                  ? i18n.t('commons.file-upload.file-not-supported')
                  : i18n.t('commons.file-upload.drag-file')}
              </span>
            ) : (
              <>
                <span>{i18n.t('commons.file-upload.click-to-upload')}</span>{' '}
                <br />
                <span>{i18n.t('commons.file-upload.drag-your-file')}</span>
              </>
            )}
          </p>
        </S.UploadMessage>
      </>
    );
  };

  return (
    <RCDropzone
      ref={fileInputRef}
      accept={accept}
      onDropAccepted={onDropAccepted}
      {...props}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <S.DropContainer
          {...getRootProps()}
          isDragActive={isDragActive}
          isDragReject={isDragReject}
          isUploadError={() => {}}
        >
          <input {...getInputProps()} />
          {children ? children : renderDropzoneText(isDragActive, isDragReject)}
        </S.DropContainer>
      )}
    </RCDropzone>
  );
};

Dropzone.propTypes = {
  accept: PropTypes.string,
  onDropAccepted: PropTypes.func.isRequired,
  isFilesSelected: PropTypes.bool.isRequired,
  fileInputRef: PropTypes.any,
  children: PropTypes.node,
};
