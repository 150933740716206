import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button } from 'modules/Core/Common';
import ServerErrorIcon from 'assets/layout/error_500.svg';
import * as S from './ResponseDataHandle.styles';
import i18n from 'i18next';

const ResponseDataHandleServerError = ({
  showBackToHomeBtn = true,
  ...props
}) => {
  const history = useHistory();

  const redirectToDashboard = useCallback(() => history.push('/'));

  return (
    <S.Container {...props}>
      <S.Image src={ServerErrorIcon} />
      <S.Title>{i18n.t('errors.error-server-error-500')}</S.Title>
      {showBackToHomeBtn && (
        <Button medium onClick={redirectToDashboard}>
          {i18n.t('commons.buttons.back-to-home')}
        </Button>
      )}
    </S.Container>
  );
};

ResponseDataHandleServerError.propTypes = {
  showBackToHomeBtn: PropTypes.bool,
};

export default ResponseDataHandleServerError;
