import styled from 'styled-components';

export const InputBox = styled.input`
  ${(props) => props.theme.config.typograph.xsmall};
  color: ${(props) => props.theme.config.colors.primary};
  background: ${(props) => props.theme.config.colors.light};
  border: 0.1rem solid ${(props) => props.theme.config.colors.gray_200};
  margin-left: ${(props) => props.theme.config.spacing.spacing_2};
  margin-right: ${(props) => props.theme.config.spacing.spacing_2};
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 0.6rem;
  padding: 0.8rem 1.2rem;
  text-align: center;
  width: 5rem;
  height: 3.2rem;
  outline: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
