import styled, { css } from 'styled-components';
import { device } from 'modules/Utils/Devices';

const Container = styled.div`
  display: flex;
  align-items: center;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const PageNumberContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  background: ${(props) => props.theme.config.colors.light};
  border-radius: 3.2rem;
  overflow: hidden;

  ${({ align }) =>
    align === 'center' &&
    css`
      margin: 0 auto;
    `}

  & > div {
    margin: 0 ${(props) => props.theme.config.spacing.spacing_1};
  }

  & > button:not[type='button'] {
    margin-left: ${(props) => props.theme.config.spacing.spacing_2};
  }

  @media ${device.mobile} {
    margin: ${(props) => props.theme.config.spacing.spacing_4} auto 0 auto;
  }
`;

export { Container, PageNumberContainer };
