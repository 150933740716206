import React from 'react';
import PropTypes from 'prop-types';
import RCTooltip from 'rc-tooltip';
import { useTheme } from 'styled-components';
import 'rc-tooltip/assets/bootstrap.css';
import './tooltipStyles.css';
import { ChildTooltip } from './Tooltip.styles';

const defaultOverlayInnerStyle = {
  borderRadius: '4px',
  padding: '8px 16px',
  fontSize: '14px',
};

export const Tooltip = ({
  placement = 'bottom',
  overlay,
  overlayInnerStyle = defaultOverlayInnerStyle,
  children,
  disabled = false,
  secondaryTooltip,
  light,
  dark,
  ...props
}) => {
  const theme = useTheme();

  return disabled ? (
    children
  ) : (
    <>
      {secondaryTooltip ? (
        <RCTooltip
          placement={placement}
          overlay={overlay}
          overlayInnerStyle={{
            ...overlayInnerStyle,
            backgroundColor: light
              ? theme.config.colors.light
              : dark
              ? theme.config.colors.primary_700
              : theme.config.colors.primary,
            color: light
              ? theme.config.colors.text_base
              : theme.config.colors.light,
          }}
          {...props}
        >
          <ChildTooltip>{children}</ChildTooltip>
        </RCTooltip>
      ) : (
        <RCTooltip
          overlayClassName={light ? 'light' : ''}
          placement={placement}
          overlay={overlay}
          overlayInnerStyle={{
            ...overlayInnerStyle,
            backgroundColor: light
              ? theme.config.colors.light
              : dark
              ? theme.config.colors.primary_700
              : theme.config.colors.primary,
            color: light
              ? theme.config.colors.text_base
              : theme.config.colors.light,
          }}
          {...props}
        >
          {children}
        </RCTooltip>
      )}
    </>
  );
};

Tooltip.propTypes = {
  placement: PropTypes.string,
  disabled: PropTypes.any,
  overlay: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.any,
  ]),
  overlayInnerStyle: PropTypes.object,
  children: PropTypes.node,
  secondaryTooltip: PropTypes.bool,
  light: PropTypes.bool,
  dark: PropTypes.bool,
};
