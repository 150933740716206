import { useHistory } from 'react-router-dom';

import { useQuery, paramsToObject } from './useQuery';
import { createQueryStringFromObject, removeFalsyProps } from 'modules/Utils';

export const useURLSearchQuery = () => {
  const history = useHistory();

  const rawQueryParams = useQuery();
  const queryParams = useQuery().toString();
  const queryObject = paramsToObject(queryParams);

  const changeParams = (newParams) => {
    let queryString = createQueryStringFromObject(
      removeFalsyProps({
        ...queryObject,
        ...newParams,
      })
    );

    history.push({
      pathname: location.pathname,
      search: queryString,
    });
  };

  const deleteParam = (name = '') => {
    rawQueryParams.delete(name);
  };

  return { queryParams, queryObject, changeParams, deleteParam };
};
