import React from 'react';
import PropTypes from 'prop-types';
import * as S from './Title.styles';

const Title = ({
  tag,
  size,
  color = 'text_base',
  fontWeight = 'bold',
  children,
  ...props
}) => (
  <S.Title
    as={tag}
    size={size}
    color={color}
    fontWeight={fontWeight}
    {...props}
  >
    {children}
  </S.Title>
);

Title.defaultProps = {
  tag: 'h1',
  size: 'primary',
};

Title.propTypes = {
  tag: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  fontWeight: PropTypes.string,
  children: PropTypes.any,
};

export default Title;
