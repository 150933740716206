import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 2.5rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
`;

export const Button = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  outline: none;
  position: relative;

  ${(props) =>
    props.isSendingEmail &&
    css`
      width: 3.2rem;
      height: 3.2rem;
    `};

  > svg {
    width: 1.4rem;
    opacity: 0.5;

    path {
      fill: ${(props) =>
        props.isSendingEmail
          ? props.theme.config.colors.orange_400
          : props.theme.config.colors.text_base};
    }
  }

  & + button {
    margin-left: ${(props) => props.theme.config.spacing.spacing_2};
  }
`;

export const EmailsSent = styled.div`
  border-top: 1px solid ${(props) => props.theme.config.colors.light};
  margin-top: 1rem;
  padding-top: 1rem;
  text-align: center;
`;

export const EmailsSentNumber = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background: ${(props) => props.theme.config.colors.gray_300};
  color: ${(props) => props.theme.config.colors.text_base};
  font-size: 0.9rem;
  font-weight: 700;
  position: absolute;
  left: -12px;
  top: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
