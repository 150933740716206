import { lazy } from 'react';
import { menuBuilder } from 'services/Routes.service';
import { SimulationIcon } from '@edusynch/edusynch-svg-icons';

const BaseList = lazy(() =>
  import(
    'modules/Simulation/SimulationsBase/SimulationsBaseList/SimulationsBaseList.container'
  )
);

const CustomtList = lazy(() =>
  import(
    'modules/Simulation/SimulationsCustom/SimulationsCustomList/SimulationsCustomList.container'
  )
);

const SimulationsMenu = menuBuilder([
  {
    Icon: SimulationIcon,
    name: 'Simulations',
  },
]).addSubmenu([
  {
    name: 'Base List',
    path: '/base-simulations/base-list',
    exact: true,
    component: BaseList,
    ability: 'content_simulations',
  },
  {
    name: 'Custom List',
    path: '/custom-simulations/custom-list',
    exact: true,
    component: CustomtList,
    ability: 'content_simulations',
  },
]);

export default SimulationsMenu;
