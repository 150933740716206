import styled from 'styled-components';

export const InputField = styled.input`
  ${(props) => props.theme.config.typograph.xsmall};
  display: block;
  padding: 0 2rem 0 4rem;
  width: 100%;
  height: 4.8rem;
  border: none;
  background: ${(props) => props.theme.config.colors.light};
  border-radius: 0.6rem;
  outline: none;
  color: ${(props) => props.theme.config.colors.text_base};
  appearance: none;
  position: relative;
  outline: none;

  &::-webkit-calendar-picker-indicator {
    background: transparent;
    color: transparent;
    cursor: pointer;
    height: auto;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    width: auto;
    outline: none;
  }
`;

export const Container = styled.div`
  display: block;
  position: relative;

  svg:not(.ArrowDownIcon) {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    z-index: 1;
    opacity: 0.2;
    margin-right: 0;
    width: 1.2rem;

    path {
      fill: ${(props) => props.theme.config.colors.text_base};
    }
  }

  .ArrowDownIcon {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    width: 1.4rem;

    path {
      fill: ${(props) => props.theme.config.colors.text_base};
      opacity: 0.5;
    }
  }
`;
