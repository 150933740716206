/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { format as formatTz } from 'date-fns-tz';

import { useTheme } from 'styled-components';
import { isBefore, isAfter } from 'date-fns';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import TextField from '@mui/material/TextField';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import i18n from 'i18next';

import { Button, Row, Col, MUIDateTimePicker } from 'modules/Core/Common';
import {
  convertUserTimezoneToUTC,
  convertUTCDateToUserTimezone,
  defaultDateFormat,
  defaultDateTimeFormat,
} from 'modules/Utils/Date';
import { useAuth } from 'modules/Auth/Hooks';
import { CloseIcon } from '@edusynch/edusynch-svg-icons';

import * as S from './RangeDatePicker.styles';

export const RangeDatePicker = ({
  startDate,
  endDate,
  onDateChange,
  onCancel,
  onSave,
  isScrollable,
  isDateTime,
  customTitle,
  unlimitedMaxDate = false,
  customMaxDateInDays = null,
}) => {
  const edusynchTheme = useTheme();
  const { userTimeFormat, userTimezone, isAMPM, userDateFormat } = useAuth();
  const [storedDate, setStoredDate] = useState({
    start_date: new Date(startDate),
    end_date: new Date(endDate),
  });

  const formatDate = (date) => {
    if (!date) return '';

    return userTimezone
      ? formatTz(
          convertUTCDateToUserTimezone(date, userTimezone),
          defaultDateFormat
        )
      : date;
  };

  const formatDateTime = (date) => {
    if (!date) return '';

    return userTimezone
      ? formatTz(
          convertUTCDateToUserTimezone(date, userTimezone),
          defaultDateTimeFormat
        )
      : date;
  };

  const handleStoredDateChange = (key, value) => {
    setStoredDate({
      ...storedDate,
      [key]: new Date(value),
    });
  };

  const isInvalidStartDate = (key, value) => {
    if (key === 'start_date' && new Date(value) > storedDate?.end_date) {
      return true;
    }
    return false;
  };

  const handleChangeDate = (key, newValue, isInput) => {
    const maxDate = handleMaxDate();
    let newDate = new Date(newValue);

    if (userTimezone) {
      const newTzDate = isInput
        ? convertUTCDateToUserTimezone(newDate, userTimezone)
        : newValue;
      newDate = convertUserTimezoneToUTC(newTzDate, userTimezone);
    }

    if (isInvalidStartDate(key, newDate)) {
      handleStoredDateChange(key, storedDate?.end_date);
      return onDateChange({ [key]: storedDate?.end_date });
    }
    if (new Date(newDate) > maxDate) {
      handleStoredDateChange(key, maxDate);
      return onDateChange({ [key]: maxDate });
    }
    handleStoredDateChange(key, newDate);
    return onDateChange({ [key]: newDate });
  };

  const handleSaveChanges = () => {
    if (storedDate.end_date < storedDate.start_date) {
      const newStartDate = new Date(storedDate.end_date);
      newStartDate.setDate(newStartDate.getDate() - 30);
      handleStoredDateChange('start_date', newStartDate);
      onDateChange({ start_date: newStartDate });
    }
    onSave();
  };

  const handleMaxDate = () => {
    let maxDate = new Date();

    if (unlimitedMaxDate) {
      maxDate.setFullYear(maxDate.getFullYear() + 999);
    }
    if (customMaxDateInDays) {
      maxDate.setDate(maxDate.getDate() + customMaxDateInDays);
    }
    return convertUTCDateToUserTimezone(maxDate, userTimezone);
  };

  const muiTheme = createTheme({
    typography: {
      fontSize: 20,
    },
  });

  const RANGE_TITLE = customTitle || 'Date interval';

  return (
    <S.Container>
      <S.Header>
        <h2>{RANGE_TITLE}</h2>
      </S.Header>
      <Button
        onClick={onCancel}
        light
        style={{
          border: 'none',
          padding: '0',
          minWidth: '2.4rem',
          height: '2.4rem',
          color: edusynchTheme.config.colors.gray,
          backgroundColor: 'transparent',
        }}
      >
        <CloseIcon color="#acabb7" />
      </Button>
      <S.DateInputs>
        <MUIDateTimePicker
          date={isDateTime ? formatDateTime(startDate) : formatDate(startDate)}
          onChange={(newValue) =>
            handleChangeDate('start_date', newValue, true)
          }
          onlyTextField
          inputFormat={
            isDateTime ? `${userDateFormat} ${userTimeFormat}` : userDateFormat
          }
          unlimitedMaxDate={unlimitedMaxDate}
          customMaxDateInDays={customMaxDateInDays}
        />
        <span>to</span>
        <MUIDateTimePicker
          date={isDateTime ? formatDateTime(endDate) : formatDate(endDate)}
          onChange={(newValue) => handleChangeDate('end_date', newValue, true)}
          onlyTextField
          inputFormat={
            isDateTime ? `${userDateFormat} ${userTimeFormat}` : userDateFormat
          }
          unlimitedMaxDate={unlimitedMaxDate}
          customMaxDateInDays={customMaxDateInDays}
        />
      </S.DateInputs>
      <S.Content isScrollable={isScrollable}>
        <ThemeProvider theme={muiTheme}>
          <Row style={{ marginTop: '2.4rem', marginLeft: 0, marginRight: 0 }}>
            <Col xs>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                {isDateTime ? (
                  <StaticDateTimePicker
                    displayStaticWrapperAs="mobile"
                    ampm={isAMPM}
                    minDate={convertUTCDateToUserTimezone(
                      new Date('2015-01-01'),
                      userTimezone
                    )}
                    maxDate={handleMaxDate()}
                    value={formatDateTime(startDate)}
                    onChange={(newValue) =>
                      handleChangeDate('start_date', newValue)
                    }
                    renderDay={(date, selectedDates, pickersDayProps) => {
                      const [selectedDate] = selectedDates;

                      const highlightClass =
                        isAfter(date, selectedDate) &&
                        isBefore(date, new Date(endDate));

                      return (
                        <PickersDay
                          {...pickersDayProps}
                          className={highlightClass ? 'highlight' : ''}
                        />
                      );
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                ) : (
                  <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    minDate={convertUTCDateToUserTimezone(
                      new Date('2015-01-01'),
                      userTimezone
                    )}
                    maxDate={handleMaxDate()}
                    value={formatDateTime(startDate)}
                    onChange={(newValue) =>
                      handleChangeDate('start_date', newValue)
                    }
                    renderDay={(date, selectedDates, pickersDayProps) => {
                      const [selectedDate] = selectedDates;

                      const highlightClass =
                        isAfter(date, selectedDate) &&
                        isBefore(
                          date,
                          convertUTCDateToUserTimezone(
                            new Date(endDate),
                            userTimezone
                          )
                        );

                      return (
                        <PickersDay
                          {...pickersDayProps}
                          className={highlightClass ? 'highlight' : ''}
                        />
                      );
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                )}
              </LocalizationProvider>
            </Col>
            <Col xs>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                {isDateTime ? (
                  <StaticDateTimePicker
                    displayStaticWrapperAs="mobile"
                    ampm={isAMPM}
                    minDate={convertUTCDateToUserTimezone(
                      new Date(startDate),
                      userTimezone
                    )}
                    maxDate={handleMaxDate()}
                    value={formatDateTime(endDate)}
                    onChange={(newValue) =>
                      handleChangeDate('end_date', newValue)
                    }
                    renderDay={(date, selectedDates, pickersDayProps) => {
                      const [selectedDate] = selectedDates;

                      const highlightClass =
                        isBefore(date, selectedDate) &&
                        isAfter(
                          date,
                          convertUTCDateToUserTimezone(
                            new Date(startDate),
                            userTimezone
                          )
                        );

                      return (
                        <PickersDay
                          {...pickersDayProps}
                          className={highlightClass ? 'highlight' : ''}
                        />
                      );
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                ) : (
                  <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    minDate={convertUTCDateToUserTimezone(
                      new Date(startDate),
                      userTimezone
                    )}
                    maxDate={handleMaxDate()}
                    value={formatDateTime(endDate)}
                    onChange={(newValue) =>
                      handleChangeDate('end_date', newValue)
                    }
                    renderDay={(date, selectedDates, pickersDayProps) => {
                      const [selectedDate] = selectedDates;

                      const highlightClass =
                        isBefore(date, selectedDate) &&
                        isAfter(
                          date,
                          convertUTCDateToUserTimezone(
                            new Date(startDate),
                            userTimezone
                          )
                        );

                      return (
                        <PickersDay
                          {...pickersDayProps}
                          className={highlightClass ? 'highlight' : ''}
                        />
                      );
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                )}
              </LocalizationProvider>
            </Col>
          </Row>
        </ThemeProvider>
      </S.Content>
      <S.Footer>
        <Button medium transparent onClick={onCancel}>
          {i18n.t('commons.buttons.cancel-button')}
        </Button>
        <Button medium onClick={handleSaveChanges}>
          {i18n.t('commons.buttons.save-button')}
        </Button>
      </S.Footer>
    </S.Container>
  );
};

RangeDatePicker.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onDateChange: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  isScrollable: PropTypes.bool,
  isDateTime: PropTypes.bool,
  customTitle: PropTypes.string,
  unlimitedMaxDate: PropTypes.bool,
  customMaxDateInDays: PropTypes.number,
};
