import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 16px;
    opacity: 0.5;

    path {
      fill: ${(props) => props.theme.config.colors.text_base};
    }
  }

  .skeleton-element[type='round'] {
    width: 4.8rem;
    height: 4.8rem;
    max-width: 4.8rem;
  }

  .skeleton-element[type='string'] {
    display: block;
    width: 220px;
  }

  .skeleton-container {
    & + .skeleton-container {
      margin-top: ${(props) => props.theme.config.spacing.spacing_2};
    }
  }
`;

const Span = styled.span`
  flex: 1;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => props.theme.config.spacing.spacing_3};

  span:first-of-type {
    color: ${(props) => props.theme.config.colors.primary};
  }

  span + span {
    margin-top: 0.2rem;
  }

  span:not(:first-of-type) {
    ${(props) => props.theme.config.typograph.xsmall};
    font-weight: 400;
    display: flex;
    align-items: center;
    position: relative;
    left: -0.4rem;

    svg {
      margin-right: ${(props) => props.theme.config.spacing.spacing_1};
      opacity: 1;

      path {
        fill: ${(props) => props.theme.config.colors.gray_400};
      }
    }
  }
`;

export { Span, Container, InfoContainer };
