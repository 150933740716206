import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import { EXTERNAL_APPLICATIONS_URL } from 'modules/Api/Routes';

let cancelToken;

const initialState = {
  loading: false,
  error: null,
  data: [],
};

const externalApplicationsSlice = createSlice({
  name: 'externalApplications',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    requestExternalApplications: (state) => {
      state.loading = true;
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    /**
     * receive a success externalApplications list response
     */
    receiveExternalApplicationsList: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    clearExternalApplicationsList: (state) => {
      state.loading = false;
      state.data = [];
    },
    /**
     * receive an error response
     */
    receiveExternalApplicationsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

const Actions = externalApplicationsSlice.actions;

const Selectors = {
  fetchListData: (state) => state.externalApplications,
  externalApplicationLoading: ({ externalApplication: { loading } }) => ({
    loading,
  }),
};

const Async = {
  fetchExternalApplicationsList: () => async (dispatch) => {
    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    dispatch(Actions.requestExternalApplications());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: EXTERNAL_APPLICATIONS_URL,
      });
      dispatch(Actions.receiveExternalApplicationsList(response.data.content));
    } catch (e) {
      if (!isCancel(e)) {
        dispatch(Actions.receiveExternalApplicationsError(e.message));
      }
    }
  },
};

const { reducer } = externalApplicationsSlice;

export { reducer, Actions, Async, Selectors };
