import { createGlobalStyle, css } from 'styled-components';
import { normalize } from 'styled-normalize';

const GlobalStyles = createGlobalStyle`
  ${normalize}

  body {
    min-width: 320px;
    ${(props) => {
      // custom styles to run storybook
      if (props.storybook) {
        return css`
          background-color: #e2e8ee;
        `;
      }
    }}
  }
`;

export default GlobalStyles;
