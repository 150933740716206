import styled from 'styled-components';

const Container = styled.div`
  height: 2.5rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  & a {
    cursor: pointer;

    &:not(:last-child) {
      margin-right: ${(props) => props.theme.config.spacing.spacing_3};
    }
  }
`;

export { Container };
