import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { IconButton } from '@mui/material';

import {
  Card,
  Icon,
  Button,
  MUIRadioGroup,
  FormGroup,
  Label,
} from 'modules/Core/Common';

import * as S from './WidgetSettings.styles';

export const WidgetSettings = ({ widget, onSave, onClose, onRemoveWidget }) => {
  const [isRemoving, setIsRemoving] = useState(false);
  const [widgetSize, setWidgetSize] = useState(widget?.settings?.size);

  const theme = useTheme();

  const handleSave = () => {
    onSave({
      widgetId: widget.id,
      size: widgetSize,
    });
    onClose();
  };

  return (
    <Card noPadding style={{ height: '100%' }}>
      {isRemoving ? (
        <S.DangerZone>
          <S.CloseButton>
            <IconButton
              aria-label="close"
              id="close"
              onClick={() => setIsRemoving(false)}
              sx={{ marginLeft: 'auto' }}
            >
              <Icon color="#ACABB7" name="close" />
            </IconButton>
          </S.CloseButton>
          <h2>Are you sure?</h2>
          <p>This action will remove the widget from your dashboard.</p>
          <Button small danger onClick={() => onRemoveWidget(widget.id)}>
            <Icon name="delete" fontSize="1.2rem" left />
            Remove widget
          </Button>
        </S.DangerZone>
      ) : (
        <S.Container>
          <S.Header>
            <div>
              <h6>{widget?.name}</h6>
              <p>Configuration</p>
            </div>
            <IconButton
              aria-label="close"
              id="close"
              onClick={onClose}
              sx={{ marginLeft: 'auto' }}
            >
              <Icon color="#ACABB7" name="close" />
            </IconButton>
          </S.Header>
          <S.Content>
            <FormGroup>
              <Label style={{ color: theme.config.colors.gray }}>
                Widget size
              </Label>
              <MUIRadioGroup
                row
                value={widgetSize}
                data={[
                  { value: 'small', label: 'Small' },
                  { value: 'large', label: 'Large' },
                ]}
                onChange={({ target }) => setWidgetSize(target.value)}
              />
            </FormGroup>
          </S.Content>
          <S.Footer>
            <Button
              small
              light
              style={{
                color: theme.config.colors.tertiary_400,
                paddingLeft: 0,
              }}
              noHoverEffect
              onClick={() => setIsRemoving(true)}
            >
              <Icon
                name="delete"
                left
                fontSize="1.2rem"
                color={theme.config.colors.tertiary_400}
              />
              Remove
            </Button>
            <Button small onClick={handleSave}>
              Save widget
            </Button>
          </S.Footer>
        </S.Container>
      )}
    </Card>
  );
};

WidgetSettings.propTypes = {
  widget: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  onRemoveWidget: PropTypes.func,
};
