import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import passwordValidator from 'password-validator';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Icon, Tooltip } from 'modules/Core/Common';
import { useTheme } from 'styled-components';
import i18n from 'i18next';

import * as S from './PasswordStrength.styles';

const PasswordStrength = ({ containerElement, password, handleClose }) => {
  const theme = useTheme();
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [passwordErrorsList, setPasswordErrorsList] = useState([]);

  const schema = useMemo(() => {
    const s = new passwordValidator();
    return s.is().min(8).has().uppercase(1).has().digits(1);
  }, []);

  const validate = useCallback(
    (value) => {
      const passwordErrors = schema.validate(value, { list: true });
      setPasswordErrorsList(passwordErrors);
    },
    [schema]
  );

  const checkErrorsForModColor = useCallback(
    (type) => {
      const error = passwordErrorsList.find((element) => element === type);
      return error ? theme.config.colors.tertiary : theme.config.colors.green;
    },
    [passwordErrorsList, theme.config.colors]
  );

  const checkErrorsForModIcon = useCallback(
    (type) => {
      const error = passwordErrorsList.find((element) => element === type);
      return error ? 'close' : 'check';
    },
    [passwordErrorsList]
  );

  const onClose = useCallback(() => {
    setTooltipVisible((prev) => !prev);
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    validate(password);
    if (password) setTooltipVisible(true);
  }, [password, validate]);

  const tooltipContent = useMemo(
    () => (
      <S.CheckStrongContainer validate={!passwordErrorsList.length}>
        <S.BarStrong>
          <S.LineStrong validate={!passwordErrorsList.length} />
        </S.BarStrong>
        <ul>
          <li>
            {!passwordErrorsList.length ? (
              <Icon name="check" />
            ) : (
              <Icon name="close" />
            )}
            {!passwordErrorsList.length
              ? i18n.t('commons.strenght-password.strong-password')
              : i18n.t('commons.strenght-password.weak-password')}
          </li>
          <li>
            <Icon
              name={checkErrorsForModIcon('min')}
              color={checkErrorsForModColor('min')}
            />
            {i18n.t('commons.strenght-password.minimum-characters')}
          </li>
          <li>
            <Icon
              name={checkErrorsForModIcon('digits')}
              color={checkErrorsForModColor('digits')}
            />
            {i18n.t('commons.strenght-password.minimum-number')}
          </li>
          <li>
            <Icon
              name={checkErrorsForModIcon('uppercase')}
              color={checkErrorsForModColor('uppercase')}
            />
            {i18n.t('commons.strenght-password.minimum-uppercase-letter')}
          </li>
        </ul>
      </S.CheckStrongContainer>
    ),
    [passwordErrorsList, checkErrorsForModIcon, checkErrorsForModColor]
  );

  return (
    <ClickAwayListener
      onClickAway={() => tooltipVisible && setTooltipVisible(false)}
    >
      <S.Container
        tooltipVisible={tooltipVisible}
        validate={!passwordErrorsList.length}
      >
        {tooltipVisible && (
          <Tooltip
            overlayClassName="tooltip-light"
            placement="bottom"
            getTooltipContainer={() => containerElement || document.body}
            visible={tooltipVisible}
            overlay={tooltipContent}
          >
            {!passwordErrorsList.length ? (
              <Icon onClick={onClose} id="ErrorPassword" name="check" />
            ) : (
              <Icon onClick={onClose} id="ErrorPassword" name="close" />
            )}
          </Tooltip>
        )}
      </S.Container>
    </ClickAwayListener>
  );
};

PasswordStrength.propTypes = {
  password: PropTypes.string,
  handleClose: PropTypes.func,
  containerElement: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
};

export default React.memo(PasswordStrength);
