import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  Async,
  Actions,
  Selectors,
} from 'modules/Users/Evaluators/EvaluatorsSlice';

export const useEvaluators = ({ paginate }) => {
  const state = useSelector(Selectors.fetchListData);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (!state?.loading) fetchEvaluatorsList({ paginate });

    return () => dispatch(Actions.cancelRequests());
  }, []);

  useEffect(() => {
    return () => dispatch(Actions.cleanState());
  }, [location.pathname]);

  const fetchEvaluatorsList = (params) =>
    dispatch(Async.fetchEvaluatorsList(params));

  return { state, fetchEvaluatorsList };
};
