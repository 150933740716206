import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  BASE_TESTS_URL,
  BASE_TEST_BY_ID_URL,
  BASE_TESTS_CREATE_URL,
  BASE_TESTS_UPDATE_URL,
} from 'modules/Api/Routes';
import { formDataFromObj } from 'modules/Api/RequestData';

let cancelToken;

const initialState = {
  loading: false,
  error: null,
  data: {
    page: 1,
    perPage: 10,
    search: '',
    sort: {
      sortType: 'ASC',
      sortBy: 'name',
    },
  },
};

const testsSlice = createSlice({
  name: 'tests',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    requestTests: (state) => {
      state.loading = true;
      state.error = null;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    /**
     * receive a success simulationsBase list response
     */
    receiveTestsList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        tests: action.payload.tests,
        total: action.payload.total_items,
      };
    },
    clearTestsList: (state) => {
      state.loading = false;
      state.data = {
        page: 1,
        perPage: 10,
        search: '',
        sort: {
          sortType: 'ASC',
          sortBy: 'name',
        },
      };
    },
    /**
     * receive an error response
     */
    receiveTestsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changeTestsPage: (state, action) => {
      state.data.page = action.payload;
    },
    changeTestsSearch: (state, action) => {
      state.data.search = action.payload.search;
      state.data.page = 1;
    },
    changeTestsFilters: (state, action) => {
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
      state.data.search = action?.payload.search || '';
    },
    clearTestsFilters: (state) => {
      state.data.search = '';
      state.data.page = 1;
      state.data.perPage = 10;
      state.data.sort.sortType = 'ASC';
      state.data.sort.sortBy = 'name';
    },
    updateTestsObjOnList: (state, action) => {
      const updOverallObj = action.payload;
      const index = state.data.tests.findIndex(
        (tests) => tests.id === updOverallObj.id
      );
      if (index !== -1) state.data.tests.splice(index, 1, updOverallObj);
    },
  },
});

const Actions = testsSlice.actions;

const Selectors = {
  fetchListData: (state) => state.tests,
  testsLoading: ({ tests: { loading } }) => ({ loading }),
};

const Async = {
  fetchTestsList: () => async (dispatch, getState) => {
    const {
      tests: {
        data: {
          page,
          perPage,
          search,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestTests());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: BASE_TESTS_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receiveTestsList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveTestsError(e.message);
      }
    }

    action && dispatch(action);
  },

  getTestsById:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestTests());

      try {
        const response = await request({
          method: 'GET',
          url: `${BASE_TEST_BY_ID_URL}?id=${id}`,
        });

        action = Actions.receiveRequestSuccess();
        onSuccess(response);
      } catch (e) {
        action = Actions.setLoading(false);
        onError(e);
      }

      dispatch(action);
    },

  createBaseTest:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestTests());
      try {
        const baseTestData = formDataFromObj(data);

        const response = await request({
          method: 'POST',
          url: BASE_TESTS_CREATE_URL,
          data: baseTestData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveTestsError());
        onError(e);
      }
    },

  updateBaseTest:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestTests());
      try {
        const baseTestData = formDataFromObj(data);

        const response = await request({
          method: 'PUT',
          url: BASE_TESTS_UPDATE_URL,
          data: baseTestData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveTestsError());
        onError(e);
      }
    },
};

const { reducer } = testsSlice;

export { reducer, Actions, Async, Selectors };
