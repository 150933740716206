import React from 'react';
import { useTheme } from 'styled-components';
import { IconRoundBox, Icon } from 'modules/Core/Common';
import PropTypes from 'prop-types';

export const StatusIcon = ({ status }) => {
  const {
    config: { colors },
  } = useTheme();

  const statuses = {
    error: {
      icon: 'close',
      color: colors?.tertiary_300,
      background: colors?.tertiary_100,
    },
    success: {
      icon: 'check',
      color: colors?.green_400,
      background: colors?.green_100,
    },
    warning: {
      icon: 'warning',
      color: colors?.orange_300,
      background: colors?.orange_100,
    },
  };

  const state = statuses[status];

  return (
    <IconRoundBox withBg bg={state?.background} color={state?.color} small>
      <Icon name={state?.icon} />
    </IconRoundBox>
  );
};

StatusIcon.propTypes = {
  status: PropTypes.string,
};
