import React from 'react';
import PropTypes from 'prop-types';

import { PaginationInfo } from './PaginationInfo';
import { PaginationNextButton } from './PaginationNextButton';
import { PaginationPrevButton } from './PaginationPrevButton';
import { PaginationPageNumberCollection } from './PaginationPageNumberCollection';

import * as S from './Pagination.styles';

const calcTotalPages = (total, perPage) => Math.ceil(total / perPage);

const Pagination = ({
  total,
  perPage,
  page,
  showInfo = true,
  align = 'right',
  onNextPage,
  onToggleClick,
}) => {
  const totalPages = calcTotalPages(total, perPage);

  return (
    <S.Container>
      {totalPages > 1 && (
        <>
          {showInfo && (
            <div>
              <PaginationInfo
                total={total}
                page={page}
                perPage={perPage}
                onToggleClick={onToggleClick}
                totalPages={totalPages}
              />
            </div>
          )}

          <S.PageNumberContainer align={align}>
            <PaginationPrevButton
              disabled={page === 1}
              onClick={() => onNextPage(page - 1)}
            />

            <PaginationPageNumberCollection
              total={total}
              currentPage={page}
              onPageClick={onNextPage}
              totalPages={totalPages}
            />

            <PaginationNextButton
              disabled={page === totalPages}
              onClick={() => onNextPage(page + 1)}
            />
          </S.PageNumberContainer>
        </>
      )}
    </S.Container>
  );
};

Pagination.propTypes = {
  total: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  showInfo: PropTypes.bool,
  align: PropTypes.string,
  onNextPage: PropTypes.func,
  onToggleClick: PropTypes.func,
};

export default Pagination;
