import React from 'react';
import PropTypes from 'prop-types';
import * as S from './Label.styles';

import { Tooltip } from 'modules/Core/Common';

const Label = ({
  children,
  htmlFor,
  showTooltip,
  tooltipPlacement,
  ...props
}) => {
  if (showTooltip) {
    return (
      <Tooltip
        placement={tooltipPlacement}
        overlay={children}
        overlayInnerStyle={{
          backgroundColor: '#0075EA',
          borderRadius: '4px',
          padding: '16px',
        }}
      >
        <S.Label htmlFor={htmlFor} {...props}>
          {children}
        </S.Label>
      </Tooltip>
    );
  }
  return (
    <S.Label htmlFor={htmlFor} {...props}>
      {children}
    </S.Label>
  );
};

Label.defaultProps = {
  showTooltip: false,
  tooltipPlacement: 'bottom',
};

Label.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  htmlFor: PropTypes.string,
  showTooltip: PropTypes.bool,
  tooltipPlacement: PropTypes.string,
};

export default Label;
