import React from 'react';

import { Card } from 'modules/Core/Common';
import i18n from 'i18next';

export const WidgetNotFound = () => {
  return (
    <Card padding="2.4rem">
      <p>{i18n.t('widgets.messages.not-found')}</p>
    </Card>
  );
};
