import styled, { css } from 'styled-components';

const types = {
  hasLicense: css`
    background: ${(props) => props.theme.config.colors.primary_200};
    border: none;
    color: ${(props) => props.theme.config.colors.primary};
  `,
  noLicense: css`
    background: ${(props) => props.theme.config.colors.gray_100};
    border: none;
    color: ${(props) => props.theme.config.colors.text_base};
  `,
  isExpired: css`
    background: ${(props) => props.theme.config.colors.tertiary_100};
    border: none;
    color: ${(props) => props.theme.config.colors.tertiary};
  `,
  isToggleOpen: css`
    background: ${(props) => props.theme.config.colors.primary};
    border: none;
    color: ${(props) => props.theme.config.colors.light};
    width: 84px;
    justify-content: flex-start;
    padding-left: 1.2rem;

    svg {
      width: 1.4rem;
      margin-right: ${(props) => props.theme.config.spacing.spacing_1};
    }
  `,
};

export const ButtonLicenses = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 32px;
  border-radius: 4px;
  outline: none;
  ${(props) => types[props.buttonType]}
`;

export const TooltipLicenses = styled.div`
  ${(props) => props.theme.config.typograph.xsmall};

  p::selection {
    background: ${(props) => props.theme.config.colors.gray_400};
  }
`;
