import React from 'react';

import SettingsProfile from './SettingsProfile.component';
import { AvatarToggleButton } from './AvatarProfile/ButtonToggleContext';

const SettingsProfileContainer = (onSubmit, ...props) => {
  return (
    <AvatarToggleButton>
      <SettingsProfile {...props} onSubmit={onSubmit} />
    </AvatarToggleButton>
  );
};

export default SettingsProfileContainer;
