import React from 'react';
import PropTypes from 'prop-types';
import { Can as CanPermission } from '../services/Ability.service';

export const Can = ({ children, I, a }) => (
  <CanPermission I={I} a={a}>
    {children}
  </CanPermission>
);

Can.propTypes = {
  children: PropTypes.element,
  I: PropTypes.string,
  a: PropTypes.string,
};
