import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Row, Col, ListHeader } from 'modules/Core/Common';
import { DashboardWidgets } from 'modules/Dashboard/DashboardWidgets/DashboardWidgets.component';
import { DashboardSecondIcon } from '@edusynch/edusynch-svg-icons';
import i18n from 'i18next';

const Dashboard = () => {
  return (
    <Grid fluid>
      <Row>
        <Col xs={12}>
          <ListHeader
            title={i18n.t('modules.dashboard.title')}
            IconName={DashboardSecondIcon}
          />
          <DashboardWidgets />
        </Col>
      </Row>
    </Grid>
  );
};

Dashboard.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object,
};

export default Dashboard;
