import React from 'react';
import PropTypes from 'prop-types';
import * as S from './Comments.styles';

const Comments = React.forwardRef((props, ref) => {
  const { inputRef, placeholder, ...rest } = props;

  return (
    <S.TextArea placeholder={placeholder} ref={inputRef || ref} {...rest} />
  );
});

Comments.propTypes = {
  inputRef: PropTypes.any,
  ref: PropTypes.any,
  placeholder: PropTypes.string,
};

export default Comments;
