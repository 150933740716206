import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  USER_SCHOOL_ADMINS_ACTIVATE_URL,
  USER_SCHOOL_ADMINS_URL,
  SETTINGS_REMOVE_AVATAR_URL,
  USER_SCHOOL_ADMINS_CREATE_URL,
  USER_SCHOOL_ADMINS_UPDATE_URL,
  USER_SCHOOL_ADMINS_DELETE_URL,
  USER_SCHOOL_ADMIN_BY_ID_URL,
  LANGUAGES_URL,
  TIMEZONES_URL,
  GROUPS_URL,
  UNITS_BY_GROUP_URL,
  SCHOOLS_BY_UNIT_URL,
} from 'modules/Api/Routes';
import { formDataFromObj } from 'modules/Api/RequestData';
import { defaultRequest } from 'modules/Utils/DefaultRequest';
import { getUser } from 'modules/Auth/Services';

const loggedUser = getUser();
let cancelToken;

const initialState = {
  loading: false,
  error: null,
  data: {
    page: 1,
    perPage: 10,
    search: '',
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
  languages: {
    data: [],
    loading: false,
  },
  timezones: {
    data: [],
    loading: false,
  },
  groups: {
    data: loggedUser?.group ? [loggedUser?.group] : [],
    loading: false,
  },
  units: {
    data: loggedUser?.unit ? [loggedUser?.unit] : [],
    empty: false,
    loading: false,
  },
  schools: {
    data: loggedUser?.school ? [loggedUser?.school] : [],
    empty: false,
    loading: false,
  },
  isDeleting: false,
};

const schoolAdminSlice = createSlice({
  name: 'schoolAdmin',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    requestSchoolAdmin: (state) => {
      state.loading = true;
      state.error = null;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsDeleting: (state, action) => {
      state.isDeleting = action.payload;
    },
    requestLanguages: (state) => {
      state.languages = {
        ...state.languages,
        loading: true,
      };
    },
    requestTimezones: (state) => {
      state.timezones = {
        ...state.timezones,
        loading: true,
      };
    },
    receiveTimezonesList: (state, action) => {
      state.timezones = {
        ...state.timezones,
        loading: false,
        data: action.payload.content,
      };
    },
    receiveTimezonesError: (state, action) => {
      state.timezones = {
        ...state.timezones,
        loading: false,
      };
      state.error = action.payload;
    },
    receiveLanguagesError: (state, action) => {
      state.languages = {
        ...state.languages,
        loading: false,
      };
      state.error = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.lading = false;
    },
    /**
     * receive a success schoolAdmin list response
     */
    receiveSchoolAdminList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        schoolAdmin: action.payload.users,
        total: action.payload.total_items,
      };
    },
    clearSchoolAdminList: (state) => {
      state.loading = false;
      state.data = {
        page: 1,
        perPage: 10,
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
    },
    /**
     * receive a success language list response
     */
    receiveLanguagesList: (state, action) => {
      state.languages = {
        ...state.languages,
        loading: false,
        data: action.payload.content,
      };
    },
    /**
     * receive an error response
     */
    receiveSchoolAdminError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changeSchoolAdminPage: (state, action) => {
      state.data.page = action.payload;
    },
    requestUnits: (state) => {
      state.units = {
        ...state.units,
        loading: true,
      };
    },
    receiveUnitsList: (state, action) => {
      state.units.loading = false;

      if (action.payload.content.length) {
        state.units = {
          ...state.units,
          empty: !action.payload.content.length,
          data: action.payload.content,
        };
        return;
      }

      const loggedUser = getUser();
      state.units = {
        ...state.units,
        empty: !loggedUser?.unit,
        data: loggedUser?.unit ? [loggedUser?.unit] : [],
      };
    },
    receiveUnitsError: (state, action) => {
      state.units = {
        ...state.units,
        loading: false,
      };
      state.error = action.payload;
    },
    requestGroups: (state) => {
      state.groups = {
        ...state.groups,
        loading: true,
      };
    },
    receiveGroupsError: (state, action) => {
      state.groups = {
        ...state.groups,
        loading: false,
      };
      state.error = action.payload;
    },
    receiveGroupsList: (state, action) => {
      state.groups.loading = false;

      if (action.payload.content.groups.length) {
        state.groups = {
          ...state.groups,
          data: action.payload.content.groups,
        };
        return;
      }

      const loggedUser = getUser();
      state.groups = {
        ...state.groups,
        data: loggedUser?.group ? [loggedUser?.group] : [],
      };
    },
    clearFormState: (state) => {
      state.units = {
        data: [],
        empty: false,
        loading: false,
      };
      state.schools = {
        data: [],
        empty: false,
        loading: false,
      };
    },
    clearSchools: (state) => {
      state.schools = {
        data: [],
        empty: false,
        loading: false,
      };
    },
    requestSchool: (state) => {
      state.schools = {
        ...state.schools,
        loading: true,
      };
    },
    receiveSchoolList: (state, action) => {
      state.schools.loading = false;

      if (action.payload.content.schools.length) {
        state.schools = {
          ...state.schools,
          empty: !action.payload?.content?.schools?.length,
          data: action.payload?.content?.schools,
        };
        return;
      }

      const loggedUser = getUser();
      state.schools = {
        ...state.schools,
        empty: !loggedUser?.school,
        data: loggedUser?.school ? [loggedUser?.school] : [],
      };
    },
    receiveSchoolError: (state, action) => {
      state.schools = {
        ...state.schools,
        loading: false,
      };
      state.error = action.payload;
    },
    changeSchoolAdminSearch: (state, action) => {
      state.data.search = action.payload.search;
      state.data.page = 1;
    },
    changeSchoolAdminFilters: (state, action) => {
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
      state.data.search = action?.payload.search || '';
    },
    clearSchoolAdminsFilters: (state) => {
      state.data.search = '';
      state.data.page = 1;
      state.data.perPage = 10;
      state.data.sort.sortType = null;
      state.data.sort.sortBy = null;
    },
  },
});

const Actions = schoolAdminSlice.actions;

const Selectors = {
  fetchListData: (state) => state.schoolAdmin,
  schoolAdminLoading: ({ schoolAdmin: { loading } }) => ({ loading }),
};

const Async = {
  setActivated:
    ({ data: schoolAdmin, field, activated, onSuccess, onError }) =>
    async () => {
      try {
        const data = new FormData();
        data.set('id', schoolAdmin.id);
        data.set(field, activated);

        const response = await request({
          method: 'PUT',
          url: USER_SCHOOL_ADMINS_ACTIVATE_URL,
          data,
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  fetchSchoolAdminList: () => async (dispatch, getState) => {
    const {
      schoolAdmin: {
        data: {
          page,
          perPage,
          search,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestSchoolAdmin());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: USER_SCHOOL_ADMINS_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receiveSchoolAdminList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveSchoolAdminError(e.message);
      }
    }

    action && dispatch(action);
  },

  removeAvatar:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      try {
        const data = formDataFromObj({ id });

        const response = await request({
          method: 'PUT',
          url: SETTINGS_REMOVE_AVATAR_URL,
          data,
        });
        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  createSchoolAdmin:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestSchoolAdmin());
      try {
        if (!data.avatar) delete data.avatar;
        const schoolAdminData = formDataFromObj(data);

        const response = await request({
          method: 'POST',
          url: USER_SCHOOL_ADMINS_CREATE_URL,
          data: schoolAdminData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveSchoolAdminError());
        onError(e);
      }
    },

  getSchoolAdminById:
    ({ id, onSuccess, onError }) =>
    async () => {
      try {
        const response = await request({
          method: 'GET',
          url: `${USER_SCHOOL_ADMIN_BY_ID_URL}?id=${id}`,
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  deleteSchoolAdmin:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.setIsDeleting(true));

      try {
        const response = await request({
          method: 'DELETE',
          url: `${USER_SCHOOL_ADMINS_DELETE_URL}?id=${id}`,
        });

        onSuccess(response);
        dispatch(Actions.setIsDeleting(false));
      } catch (e) {
        onError(e);
        dispatch(Actions.setIsDeleting(false));
      }
    },

  updateSchoolAdmin:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestSchoolAdmin());
      try {
        if (!(data.avatar instanceof File)) delete data.avatar;
        const schoolAdminData = formDataFromObj(data);

        const response = await request({
          method: 'PUT',
          url: USER_SCHOOL_ADMINS_UPDATE_URL,
          data: schoolAdminData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveSchoolAdminError());
        onError(e);
      }
    },

  fetchLanguagesList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      LANGUAGES_URL,
      'GET',
      Actions.requestLanguages,
      Actions.receiveLanguagesList,
      Actions.receiveLanguagesError
    ),

  fetchTimezonesList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      TIMEZONES_URL,
      'GET',
      Actions.requestTimezones,
      Actions.receiveTimezonesList,
      Actions.receiveTimezonesError
    ),

  fetchGroupsList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      `${GROUPS_URL}?page=1&paginates_per=200`,
      'GET',
      Actions.requestGroups,
      Actions.receiveGroupsList,
      Actions.receiveGroupsError
    ),

  fetchUnitsList: (groupId) => async (dispatch) =>
    defaultRequest(
      dispatch,
      `${UNITS_BY_GROUP_URL}?group_id=${groupId}`,
      'GET',
      Actions.requestUnits,
      Actions.receiveUnitsList,
      Actions.receiveUnitsError
    ),

  fetchSchoolList: (unitId) => async (dispatch) =>
    defaultRequest(
      dispatch,
      `${SCHOOLS_BY_UNIT_URL}?unit_id=${unitId}`,
      'GET',
      Actions.requestSchool,
      Actions.receiveSchoolList,
      Actions.receiveSchoolError
    ),
};

const { reducer } = schoolAdminSlice;

export { reducer, Actions, Async, Selectors };
