import React from 'react';
import PropTypes from 'prop-types';
import * as S from './Card.styles';

const Card = ({ children, title, overflow, padding, ...props }) => (
  <S.Container overflow={overflow} padding={padding} {...props}>
    {title && <S.Title>{title}</S.Title>}
    {children}
  </S.Container>
);

Card.defaultProps = {
  title: null,
  noPadding: false,
  height: null,
};

Card.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  children: PropTypes.any,
  noPadding: PropTypes.bool,
  height: PropTypes.string,
  margin: PropTypes.bool,
  overflow: PropTypes.bool,
  padding: PropTypes.string,
};

export default Card;
