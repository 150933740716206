import { createContext } from 'react';
import { defineAbility } from '@casl/ability';
import { createContextualCan } from '@casl/react';

export const defineAbilityForCurrentUser = (permissions) =>
  defineAbility((can) => {
    if (!permissions) return;

    Object.keys(permissions).map((key) => {
      can(permissions[key], key);
    });

    can('index', 'common');
  });

export const AbilityContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);
