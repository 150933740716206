import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import { Button } from 'modules/Core/Common';
import * as S from './ScrollPagination.styles';

const calcTotalPages = (total, perPage) => Math.ceil(total / perPage);

const ScrollPagination = ({ total, perPage, page, onNextPage }) => {
  const totalPages = calcTotalPages(total, perPage);

  return (
    <S.Container>
      {totalPages > 1 && page < totalPages && (
        <Button type="button" transparent onClick={() => onNextPage(page + 1)}>
          {i18n.t('commons.buttons.load-more')}
        </Button>
      )}
    </S.Container>
  );
};

ScrollPagination.propTypes = {
  total: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  showInfo: PropTypes.bool,
  align: PropTypes.string,
  onNextPage: PropTypes.func,
  onToggleClick: PropTypes.func,
};

export default ScrollPagination;
