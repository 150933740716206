import { css } from 'styled-components';

const BaseStyle = css`
  color: ${(props) => props.theme.config.colors.primary};
  text-decoration: none;
  font-size: ${(props) => props.fontSize};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.config.colors.secondary};
  }
`;

export { BaseStyle };
