import { createSlice } from '@reduxjs/toolkit';
import { request } from 'modules/Api/HttpClient';
import {
  EXTERNAL_SOFTWARE_URL,
  VERSIONS_URL,
  EXTERNAL_SOFTWARE_BY_ID_URL,
  EXTERNAL_SOFTWARE_CREATE_URL,
  EXTERNAL_SOFTWARE_UPDATE_URL,
  EXTERNAL_SOFTWARE_DELETE_URL,
} from 'modules/Api/Routes';
import { formDataFromObj } from 'modules/Api/RequestData';
import { createExternalSoftwareRequestModel } from 'modules/Softwares/ExternalSoftwares/ExternalSoftwareUtils';

const initialState = {
  loading: false,
  error: null,
  data: {
    externalSoftwares: [],
    page: 1,
    perPage: 10,
    search: '',
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
  externalSoftwareVersions: {
    externalSoftwareId: '',
    externalSoftwareName: '',
    versions: [],
    total: 0,
    page: 1,
    perPage: 8,
    align: 'center',
    loading: false,
    error: null,
  },
  isDeleting: false,
};

const externalSoftwaresSlice = createSlice({
  name: 'externalSoftwares',
  initialState,
  reducers: {
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    requestExternalSoftware: (state) => {
      state.loading = true;
      state.error = null;
    },
    changeSearch: (state, action) => {
      state.data.search = action.payload;
    },
    changePage: (state, action) => {
      state.data.page = action.payload;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    changeSelectedExternalSoftware: (state, action) => {
      state.externalSoftwareVersions.externalSoftwareId = action.payload?.id;
      state.externalSoftwareVersions.externalSoftwareName =
        action.payload?.name;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsDeleting: (state, action) => {
      state.isDeleting = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    /**
     * receive a success group list response
     */
    receiveExternalSoftwareList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        externalSoftwares: action.payload.external_softwares,
        total: action.payload.total_items,
      };
    },
    clearExternalSoftwaresList: (state) => {
      state.loading = false;
      state.data = {
        externalSoftwares: [],
        page: 1,
        perPage: 10,
        search: '',
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
      state.externalSoftwareVersions = {
        externalSoftwareId: '',
        externalSoftwareName: '',
        versions: [],
        total: 0,
        page: 1,
        perPage: 8,
        search: '',
        showInfo: false,
        align: 'center',
        loading: false,
        error: null,
      };
    },
    /**
     * receive an error response
     */
    receiveExternalSoftwareError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changeExternalSoftwareVersionsPage: (state, action) => {
      state.externalSoftwareVersions.page = action.payload;
    },
    changeExternalSoftwareVersionsPerPage: (state, action) => {
      state.externalSoftwareVersions.perPage = action.payload;
      state.externalSoftwareVersions.page = 1;
    },
    changeExternalSoftwaresFilters: (state, action) => {
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
      state.data.search = action?.payload.search || '';
    },
    updateExternalSoftwareOnList: (state, action) => {
      const updExternalSoftware = action.payload;
      const index = state.data.externalSoftwares.findIndex(
        (externalSoftware) => externalSoftware.id === updExternalSoftware.id
      );
      if (index >= 0)
        state.data.externalSoftwares.splice(index, 1, updExternalSoftware);
    },
    requestExternalSoftwareVersions: (state) => {
      state.externalSoftwareVersions.loading = true;
      state.externalSoftwareVersions.error = null;
    },
    receiveExternalSoftwareVersionsList: (state, action) => {
      state.externalSoftwareVersions = {
        ...state.externalSoftwareVersions,
        versions: action.payload.versions || [],
        total: action.payload.total_items,
        loading: false,
      };
    },
    receiveExternalSoftwareVersionsError: (state, action) => {
      state.externalSoftwareVersions.loading = false;
      state.externalSoftwareVersions.error = action.payload;
    },
    clearExternalSoftwareVersions: (state) => {
      state.externalSoftwareVersions.versions = [];
      state.externalSoftwareVersions.externalSoftwareId = '';
      state.externalSoftwareVersions.externalSoftwareName = '';
      state.externalSoftwareVersions.page = 1;
      state.externalSoftwareVersions.perPage = 8;
      state.externalSoftwareVersions.total = 0;
      state.externalSoftwareVersions.loading = false;
      state.externalSoftwareVersions.error = null;
    },
  },
});

const Actions = externalSoftwaresSlice.actions;

const Selectors = {
  fetchListData: (state) => state.externalSoftwares,
  externalSoftwareLoading: ({ externalSoftwares: { loading } }) => ({
    loading,
  }),
};

const Async = {
  fetchExternalSoftwaresList: () => async (dispatch, getState) => {
    const {
      externalSoftwares: {
        data: {
          page,
          perPage,
          search,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    let action;

    dispatch(Actions.requestExternalSoftware());

    try {
      const response = await request({
        method: 'GET',
        url: EXTERNAL_SOFTWARE_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receiveExternalSoftwareList(response.data.content);
    } catch (e) {
      action = Actions.receiveExternalSoftwareError(e.message);
    }

    dispatch(action);
  },

  fetchExternalSoftwaresListForSelect: () => async (dispatch) => {
    let action;

    dispatch(Actions.requestExternalSoftware());

    try {
      const response = await request({
        method: 'GET',
        url: EXTERNAL_SOFTWARE_URL,
        params: {
          sort: 'ASC',
          sort_by: 'title',
        },
      });

      action = Actions.receiveExternalSoftwareList(response.data.content);
    } catch (e) {
      action = Actions.receiveExternalSoftwareError(e.message);
    }

    dispatch(action);
  },

  fetchExternalSoftwareVersionsList: () => async (dispatch, getState) => {
    const {
      externalSoftwares: {
        externalSoftwareVersions: { page, perPage, externalSoftwareId },
      },
    } = getState();

    if (!externalSoftwareId) return;

    let action;

    dispatch(Actions.requestExternalSoftwareVersions());

    try {
      const response = await request({
        method: 'GET',
        url: VERSIONS_URL,
        params: {
          page,
          paginates_per: perPage,
          external_software_id: externalSoftwareId,
        },
      });

      action = Actions.receiveExternalSoftwareVersionsList(
        response.data.content
      );
    } catch (e) {
      action = Actions.receiveExternalSoftwareVersionsError(e.message);
    }

    dispatch(action);
  },

  getExternalSoftwareById:
    ({ id, onSuccess, onError }) =>
    async () => {
      try {
        const response = await request({
          method: 'GET',
          url: `${EXTERNAL_SOFTWARE_BY_ID_URL}?id=${id}`,
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  createExternalSoftware:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestExternalSoftware());
      try {
        const parsedSoftware = createExternalSoftwareRequestModel(data);
        delete parsedSoftware.id;

        const softwareData = formDataFromObj(parsedSoftware);

        const response = await request({
          method: 'POST',
          url: EXTERNAL_SOFTWARE_CREATE_URL,
          data: softwareData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveExternalSoftwareError());
        onError(e);
      }
    },

  updateExternalSoftware:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestExternalSoftware());
      try {
        const parsedSoftware = createExternalSoftwareRequestModel(data);

        const softwareData = formDataFromObj(parsedSoftware);

        const response = await request({
          method: 'PUT',
          url: EXTERNAL_SOFTWARE_UPDATE_URL,
          data: softwareData,
        });

        const updatedExternalSoftware = response.data.content;
        dispatch(Actions.updateExternalSoftwareOnList(updatedExternalSoftware));
        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveExternalSoftwareError());
        onError(e);
      }
    },

  deleteExternalSoftware:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.setIsDeleting(true));

      try {
        const response = await request({
          method: 'DELETE',
          url: `${EXTERNAL_SOFTWARE_DELETE_URL}?id=${id}`,
        });

        onSuccess(response);
        dispatch(Actions.setIsDeleting(false));
      } catch (e) {
        onError(e);
        dispatch(Actions.setIsDeleting(false));
      }
    },
};

const reducer = externalSoftwaresSlice.reducer;

export { reducer, Actions, Async, Selectors };
