import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Selectors, Async, Actions } from 'modules/Auth/AuthSlice';
import { Label, InputControl, Button } from 'modules/Core/Common';
import * as AuthStyle from 'modules/Auth/Auth.styles';
import * as S from './LoginForm.styles';
import i18n from 'i18next';

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('incorrect email format')
    .required("email can't be blank"),

  password: Yup.string().required("password can't be blank"),
});

const LoginForm = ({ onSubmit, theme }) => {
  const dispatch = useDispatch();
  const tokenExpired = useSelector(Selectors.getExpiredToken);
  const history = useHistory();
  const formRef = useRef(null);

  const handleForgotPassword = React.useCallback(() => {
    if (tokenExpired) {
      dispatch(Async.logout());
      dispatch(Actions.clearTokenExpired());
    }

    history.push('/auth/forgot-password');
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={LoginSchema}
        onSubmit={(data) => onSubmit(data)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <AuthStyle.AuthForm onSubmit={handleSubmit} noValidate ref={formRef}>
            <S.FormGroup>
              <Label auth htmlFor="email">
                {i18n.t('login.inputs.email.label')}
              </Label>

              <InputControl
                inputType="email"
                inputID="email"
                inputName="email"
                iconName="email"
                dataTest="email"
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.email}
                value={values.email}
                touched={touched.email}
                containerElement={formRef?.current}
                required
              />
            </S.FormGroup>
            <S.FormGroup>
              <Label auth htmlFor="password">
                {i18n.t('login.inputs.password.label')}
              </Label>

              <InputControl
                inputType="password"
                inputID="password"
                inputName="password"
                iconName="password"
                dataTest="password"
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.password}
                value={values.password}
                touched={touched.password}
                containerElement={formRef?.current}
                required
              />
            </S.FormGroup>

            <S.LinkForgot
              className="forgot-link"
              type="button"
              data-testid="forgot-link"
              onClick={handleForgotPassword}
            >
              {theme?.config?.info?.forgot_name}
            </S.LinkForgot>

            <AuthStyle.ButtonContainer className="login-button-container">
              <S.FullWidth>
                <Button
                  large
                  type="submit"
                  data-test="submit"
                  data-testid="submit-button"
                >
                  {i18n.t('commons.buttons.sign-in-button')}
                </Button>
              </S.FullWidth>
            </AuthStyle.ButtonContainer>
          </AuthStyle.AuthForm>
        )}
      </Formik>
    </>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

export default LoginForm;
