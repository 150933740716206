import { useSelector } from 'react-redux';
import Intercom, { shutdown } from '@intercom/messenger-js-sdk';
import { Selectors } from 'modules/Auth/AuthSlice';

export const useZendeskChat = () => {
  const session = useSelector(Selectors.selectAuthData);
  const hideZendeskChat = () => shutdown();

  const initZendeskChat = () => {
    const user = session?.user;

    if (user.id) {
      Intercom({
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        user_id: user.id,
        name: user.name,
        email: user.email,
        created_at: user.created_at,
      });
    } else {
      Intercom({
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      });
    }
  };

  return {
    initZendeskChat,
    hideZendeskChat,
  };
};
