import styled from 'styled-components';
import { device } from 'modules/Utils/Devices';

const WhitelabelNameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Span = styled.span`
  ${(props) => props.theme.config.typograph.default};

  @media ${device.tablet} {
    ${(props) => props.theme.config.typograph.xsmall};
  }

  @media ${device.mobile} {
    ${(props) => props.theme.config.typograph.mico};
  }
`;

const Strong = styled.strong`
  ${(props) => props.theme.config.typograph.quartenary};

  @media ${device.tablet} {
    ${(props) => props.theme.config.typograph.default};
  }

  @media ${device.mobile} {
    display: block;
    ${(props) => props.theme.config.typograph.default};
  }
`;

export { WhitelabelNameContainer, Span, Strong };
