import styled, { css } from 'styled-components/macro';
import { shade } from 'polished';
import {
  Button as CustomButton,
  Input as DefaultInput,
} from 'modules/Core/Common';

export const Button = styled(CustomButton)`
  justify-content: center;
`;

export const Label = styled.span`
  padding: 0;
  color: ${(props) => props.theme.config.colors.text_base};
  display: inline-block;
  ${(props) => props.theme.config.typograph.xsmall};
  font-weight: 300;
  ${(props) =>
    props.size
      ? 'margin-left: 6.5rem; margin-top: 2rem; font-weight: bold; margin-bottom: .8rem'
      : null};
`;

export const AvatarInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 25rem;
  border: ${(props) =>
    !props.hasImg ? '2px dashed rgba(4, 117, 234, .2);' : 'none'};
  background: ${(props) => props.theme.config.colors.primary_100};
  border-radius: 6px;
  margin-top: ${(props) => props.theme.config.spacing.spacing_2};
  overflow: hidden;
  position: relative;

  input {
    display: none;
  }

  img {
    /* position: absolute; */
    /* object-fit: ${(props) => (!props.hasImg ? 'none' : 'cover')}; */
    width: ${(props) => (!props.hasImg ? '64.5px' : '380px')};
    opacity: ${(props) => (!props.hasImg ? '.2' : '1')};
    height: ${(props) => (!props.hasImg ? '22rem' : '25rem')};
  }

  button#remove {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    position: absolute;
    background: ${(props) => props.theme.config.colors.light};
    border-radius: 4px;
    top: 8px;
    right: 8px;
    padding: 0;

    svg {
      width: 1.2rem;
    }

    svg path {
      fill: ${(props) => props.theme.config.colors.gray_300};
    }
  }

  button#add {
    position: absolute;
    bottom: ${(props) => props.theme.config.spacing.spacing_5};
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  button#crop_save,
  button#crop_cancel {
    position: absolute;
    bottom: 12.63px;
    width: ${(props) => props.theme.config.spacing.spacing_5};
    height: ${(props) => props.theme.config.spacing.spacing_5};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    svg {
      width: 1.2rem !important;
    }
  }

  button#crop_save {
    background: ${(props) => props.theme.config.colors.green};
    left: 60%;
    -webkit-transform: translateX(-60%);
    transform: translateX(-60%);

    &:hover {
      background: ${shade(0.2, '#1BD171')};
    }
  }

  button#crop_cancel {
    background: ${(props) => props.theme.config.colors.light};
    left: 40%;
    -webkit-transform: translateX(-40%);
    transform: translateX(-40%);

    &:hover {
      background: ${shade(0.2, '#fff')};
    }
  }

  .ReactCrop--disabled > .ReactCrop__crop-selection {
    border: none;
  }
`;

const AvatarContainer = styled.div`
  display: flex;
  ${(props) =>
    props.size ? 'flex-direction: column; align-items: center;' : null};
  margin-top: ${(props) => props.theme.config.spacing.spacing_2};
  margin-bottom: ${(props) => props.theme.config.spacing.spacing_4};
`;

const Input = styled(DefaultInput)`
  display: none;
`;

const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: ${(props) => (props.size ? '29.6rem' : '15rem')};
  height: ${(props) => (props.size ? '29.6rem' : '15rem')};
  padding: 1rem;
  background: ${(props) => props.theme.config.colors.primary_100};
  border-radius: 0.6rem;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  border: ${(props) =>
    !props.hasImg ? '0.2rem dashed rgba(4, 117, 234, .2);' : 'none'};
`;

const AvatarText = styled.span`
  ${(props) => props.theme.config.typograph.mico};
  display: block;
  text-align: center;
  pointer-events: none;
  color: ${(props) => props.theme.config.colors.primary};
`;

const AvatarPlaceholder = styled.img`
  display: block;
  width: 4rem;
  height: 4rem;
  padding: 0.5rem;
  mix-blend-mode: normal;
  pointer-events: none;
  margin-bottom: ${(props) => props.theme.config.spacing.spacing_2};
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-align: center;
  margin-left: ${(props) => props.theme.config.spacing.spacing_4};
  ${(props) => (props.size ? 'margin-left: 0' : null)};
  margin-top: ${(props) => props.theme.config.spacing.spacing_6};

  a,
  button {
    width: 12rem;
    padding: 0 1em;
  }
`;

const LimitText = styled.span`
  ${(props) => props.theme.config.typograph.xsmall};
  color: ${(props) => props.theme.config.colors.text_base};
  margin-top: ${(props) => props.theme.config.spacing.spacing_2};

  ${(props) =>
    props.invalidImageType &&
    css`
      color: ${props.theme.config.colors.tertiary};
    `}
`;

const CropModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  background: ${(props) => props.theme.config.colors.text_base};
  bottom: 0;
  right: 0;
`;

const CropContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 70rem;
  width: 100%;
  margin-top: ${(props) => props.theme.config.spacing.layout_6};

  img {
    max-height: 40rem;
  }
`;

const CropButtonContainer = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  margin-top: ${(props) => props.theme.config.spacing.spacing_4};

  & + button {
    margin-left: ${(props) => props.theme.config.spacing.layout_7};
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const getButtonPropsByType = (props) => {
  switch (props.type) {
    case 'save':
      return `
        background: ${props.theme.config.colors.gray};

        svg path {
          fill: ${props.theme.config.colors.light};
        }
      `;
    case 'cancel':
      return `
        background: ${props.theme.config.colors.light};

        svg path {
          fill: ${props.theme.config.colors.text_base};
        }
      `;
    default:
      return '';
  }
};

const CropButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  margin-right: ${(props) => props.theme.config.spacing.spacing_2};

  ${(props) => getButtonPropsByType(props)}
`;

const CropText = styled.span`
  ${(props) => props.theme.config.typograph.mico};
  color: ${(props) => props.theme.config.colors.light};
`;

const ImagePreviewContainer = styled.div`
  position: relative;
  display: block;
  width: 15rem;
  height: 15rem;
  overflow: hidden;
  border-radius: 0.6rem;

  img {
    max-width: 100%;
  }
`;

const ImagePreviewButton = styled.button`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 0.4rem;
  top: 0.8rem;
  right: 0.8rem;
  border: none;
  background: ${(props) => props.theme.config.colors.orange};

  svg {
    width: 0.8rem;
    height: 0.8rem;

    path {
      fill: ${(props) => props.theme.config.colors.light};
    }
  }
`;

const InvalidType = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 1.6rem;
  height: 1.6rem;
  top: 3.6rem;
  right: 5.4rem;
  background: ${(props) => props.theme.config.colors.tertiary};

  svg {
    width: 1.6rem;
    height: 1.6rem;

    path {
      fill: ${(props) => props.theme.config.colors.light};
    }
  }
`;

export {
  Input,
  Avatar,
  CropText,
  Container,
  LimitText,
  AvatarText,
  CropButton,
  CropContent,
  InvalidType,
  ButtonContainer,
  AvatarContainer,
  AvatarPlaceholder,
  ImagePreviewButton,
  CropModalContainer,
  CropButtonContainer,
  ImagePreviewContainer,
};
