import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import {
  InputControl,
  ValidationError,
  Skeleton,
  Tooltip,
} from 'modules/Core/Common';
import { ReactComponent as EmptyIcon } from 'assets/layout/empty-data/empty-icon.svg';
import { useTheme } from 'styled-components';
import i18n from 'i18next';
import {
  CloseIcon,
  SearchIcon,
  ArrowDownIcon,
  AlertCircleSecondIcon,
} from '@edusynch/edusynch-svg-icons';
import * as S from './styles';

const SingleResult = ({ results = [], selectedOptions }) => {
  const item = results.find((item) => item.id === selectedOptions[0]);

  if (!item) return null;

  return (
    <S.Placeholder title={item.label || item.name}>
      {item?.Icon && <item.Icon type="small" color="#0075EA" />}
      {item?.customOption && !item?.customOptionOnlyInDropdown ? (
        <>{item.customOption}</>
      ) : (
        item.label || item.name
      )}
    </S.Placeholder>
  );
};
SingleResult.propTypes = {
  results: PropTypes.array,
  selectedOptions: PropTypes.array,
};

const MultipleResults = ({ options = [], selectedOptions, handleUnselect }) => {
  if (!selectedOptions?.length || !options?.length) return null;

  const optionToShow = options?.find(
    (option) => option?.id === selectedOptions?.[0]
  );

  return (
    <>
      <S.SelectedOpt isMultiple>
        <S.SelectedLabel
          title={optionToShow?.label || optionToShow?.name || optionToShow}
        >
          {optionToShow?.label || optionToShow?.name || optionToShow}
        </S.SelectedLabel>
        <S.RemoveSelectedOpt
          onClick={() => handleUnselect(optionToShow?.id || optionToShow)}
        >
          <CloseIcon color="#0075EA" width={10} height={10} />
        </S.RemoveSelectedOpt>
      </S.SelectedOpt>
      {selectedOptions?.length > 1 && (
        <S.HiddenSelectedNumber>{`+${
          selectedOptions?.length - 1
        }`}</S.HiddenSelectedNumber>
      )}
    </>
  );
};

MultipleResults.propTypes = {
  options: PropTypes.array,
  selectedOptions: PropTypes.array,
  handleUnselect: PropTypes.func,
  theme: PropTypes.object,
};

const SelectAutocomplete = ({
  'data-cy': dataCy,
  id = 'search',
  options = [],
  optionsGroups = [],
  placeholder = '',
  floatingPlaceholder,
  searchPlaceholder = 'Search',
  hasClearField,
  clearFieldLabel,
  className,
  togglClassName,
  IconName,
  loading,
  onChangeSearch,
  onChangeSelect,
  onChangeUnselect,
  isMultiple,
  showAllOption,
  awaysOpen,
  onlySearch,
  borders,
  filterStyle,
  disabled,
  index,
  indexActive,
  changeIndexActive,
  value = [],
  customToggl,
  customIcon,
  hideSearch,
  tooltipWidth,
  tooltipPlacement = 'bottom',
  containerElement,
  noSort,
  ...rest
}) => {
  const [open, setOpen] = useState(awaysOpen || false);
  const [searchFor, setSearchFor] = useState('');
  const [selectedOptions, setSelectedOptions] = useState(value);
  const [formattedOptions, setFormattedOptions] = useState([]);
  const [optionsToRender, setOptionsToRender] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [visibleFloatingPlaceholder, setVisibleFloatingPlaceholder] = useState(
    value?.[0] && floatingPlaceholder
  );
  const inputRef = useRef(null);
  const mainContainerRef = useRef(null);
  const theme = useTheme();

  const allOption = [
    {
      name: showAllOption?.label || 'All',
      id: showAllOption?.id || '-1',
    },
  ];

  const tooltipDropWidth = useMemo(() => {
    return tooltipWidth || mainContainerRef?.current?.clientWidth;
  }, [mainContainerRef?.current?.clientWidth]);

  const handleToggl = () => {
    !loading && !awaysOpen && setOpen(!open);
    index && changeIndexActive && changeIndexActive(!open ? index : '');
  };

  const handleClickSearch = useCallback(() => {
    if (!inputRef.current) return;

    const { value } = inputRef.current;
    setSearchFor(value);
    onChangeSearch && onChangeSearch(value);
  }, [value]);

  const handleKeyPress = useCallback(
    (event) => {
      if (
        (inputRef.current && event.key) ||
        event.key === i18n.t('commons.buttons.enter-button')
      ) {
        const { value } = inputRef.current;
        setSearchFor(value);
        onChangeSearch && onChangeSearch(value);
      }
    },
    [value]
  );

  const handleChangeSearch = useCallback(() => {
    if (!inputRef.current) return;

    const { value } = inputRef.current;

    setSearchFor(value);
    onChangeSearch && onChangeSearch(value);
  }, [value]);

  const handleClearActive = () => {
    indexActive === index && changeIndexActive(null);
  };

  const handleSelect = (id) => {
    isMultiple
      ? setSelectedOptions([...selectedOptions, id])
      : setSelectedOptions([id]);
    onChangeSelect(id);
    setOpen(false);
    index && changeIndexActive && handleClearActive();
    floatingPlaceholder && setVisibleFloatingPlaceholder(true);
  };

  const handleUnselect = (idToRemove) => {
    const newOptions = selectedOptions.filter((item) => item !== idToRemove);
    setSelectedOptions(newOptions);
    onChangeUnselect && onChangeUnselect(idToRemove);
    setOpen(false);
  };

  const getLabelOrName = (object) =>
    object?.label?.toString()?.toUpperCase() ||
    object?.name?.toString()?.toUpperCase() ||
    '';

  const hasNumber = (arrayToCheck) =>
    arrayToCheck?.find((item) => !isNaN(item));

  useEffect(() => {
    setSearchFor('');
  }, [open]);

  useEffect(() => {
    const sortedOptions = noSort
      ? options
      : [...options].sort((a, b) => {
          const aLabel = getLabelOrName(a);
          const bLabel = getLabelOrName(b);
          return aLabel.localeCompare(bLabel, 'en', {
            numeric: hasNumber([aLabel, bLabel]),
          });
        });

    showAllOption
      ? setFormattedOptions(allOption.concat(sortedOptions))
      : setFormattedOptions(sortedOptions);
  }, [options]);

  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  useEffect(() => {
    const toRender = formattedOptions.filter((item) => {
      const label = item?.label || item?.name || '';
      const email = item?.email || '';
      return (
        (label.toString().toUpperCase().includes(searchFor.toUpperCase()) ||
          email.toString().toUpperCase().includes(searchFor.toUpperCase())) &&
        !selectedOptions.includes(item.id)
      );
    });

    const toRenderOnTop = isMultiple
      ? options
          ?.filter((option) => selectedOptions?.includes(option?.id))
          ?.map((option) => ({
            ...option,
            isMultipleSelected: true,
          }))
      : [];

    setOptionsToRender([...toRenderOnTop, ...toRender]);
    formattedOptions?.length && setNoResults(true);
  }, [searchFor, formattedOptions, selectedOptions, noResults]);

  const RenderGroupedOptions = () => {
    const groupedList = optionsGroups?.map((optionGroup) => {
      return {
        name: optionGroup,
        data: optionsToRender.filter(
          (item) => item?.select_component_tag === optionGroup
        ),
      };
    });
    console.log(groupedList);
    return groupedList?.map((group, groupIndex) => (
      <S.Group key={groupIndex}>
        <S.GroupTitle>{group?.name}</S.GroupTitle>
        {group?.data?.map((option, optionIndex) => (
          <S.GroupOption
            key={optionIndex}
            onClick={() => handleSelect(option.id)}
            data-cy="select-autocomplete-option"
          >
            {option?.customOption ? (
              <>{option?.customOption}</>
            ) : (
              <>
                <S.GroupOptionLabel fullWidth={!option?.additionalInfo?.length}>
                  {option.label || option.name}
                </S.GroupOptionLabel>
                {option?.additionalInfo && (
                  <Tooltip
                    placement="bottom"
                    overlay={
                      <>
                        {Array.isArray(option?.additionalInfo)
                          ? option?.additionalInfo?.map((info, index) => (
                              <div key={index}>{info}</div>
                            ))
                          : option?.additionalInfo}
                        <div></div>
                      </>
                    }
                    overlayInnerStyle={{
                      backgroundColor: theme.config.colors.primary,
                      borderRadius: '4px',
                      padding: '8px 16px',
                    }}
                  >
                    <S.OptionAdditionalInfo>
                      {Array.isArray(option?.additionalInfo)
                        ? option?.additionalInfo[0]
                        : option?.additionalInfo}
                    </S.OptionAdditionalInfo>
                  </Tooltip>
                )}
              </>
            )}
          </S.GroupOption>
        ))}
      </S.Group>
    ));
  };

  if (loading) {
    return (
      <S.SkeletonContainer>
        <Skeleton type="string" />
      </S.SkeletonContainer>
    );
  }

  return (
    <div ref={mainContainerRef}>
      <Tooltip
        visible={open}
        showArrow={false}
        destroyTooltipOnHide
        getTooltipContainer={() => mainContainerRef?.current}
        placement={tooltipPlacement}
        overlay={
          <ClickAwayListener
            onClickAway={() => {
              !awaysOpen && setOpen(false);
              index && changeIndexActive && handleClearActive();
            }}
          >
            <S.SearchBox borders={borders} width={tooltipDropWidth}>
              {!hideSearch && noResults && (
                <S.SearchHead>
                  <SearchIcon
                    onClick={handleClickSearch}
                    color="#E0DEEA"
                    width={16}
                  />
                  <InputControl
                    onKeyDown={handleKeyPress}
                    inputRef={inputRef}
                    inputType="text"
                    inputID={id}
                    inputName="search"
                    placeholder={searchPlaceholder}
                    onChange={handleChangeSearch}
                  />
                </S.SearchHead>
              )}
              <S.OptionsBox
                data-cy="select-autocomplete-options-box"
                hideScroll={optionsToRender.length}
              >
                {hasClearField && selectedOptions.length ? (
                  <S.Option key={index} onClick={() => handleSelect('')}>
                    <S.OptionLabelFlex>
                      <S.OptionLabel>
                        {clearFieldLabel ||
                          i18n.t('commons.search-dropdown.clear-field')}
                      </S.OptionLabel>
                    </S.OptionLabelFlex>
                  </S.Option>
                ) : (
                  ''
                )}
                {(!selectedOptions.length && !optionsToRender.length) ||
                (selectedOptions.length && !optionsToRender.length) ? (
                  <S.Empty>
                    <EmptyIcon />
                    <S.EmptyTitle>
                      {i18n.t('commons.search-dropdown.no-results')}
                    </S.EmptyTitle>
                    <S.EmptyDescription>
                      {i18n.t('commons.search-dropdown.no-matches')}
                    </S.EmptyDescription>
                  </S.Empty>
                ) : optionsGroups?.length ? (
                  <RenderGroupedOptions />
                ) : (
                  optionsToRender.map((item, index) => {
                    const label = item.label || item.name;

                    if (item?.isMultipleSelected) {
                      return (
                        <S.Option key={index}>
                          <S.SelectedLabelOnTop>
                            {label}
                            <S.RemoveSelectedOpt
                              onClick={() => handleUnselect(item?.id || item)}
                            >
                              <CloseIcon color="#0075EA" width={12} />
                            </S.RemoveSelectedOpt>
                          </S.SelectedLabelOnTop>
                        </S.Option>
                      );
                    }

                    return item?.customOption ? (
                      <S.NormalDiv
                        key={index}
                        onClick={() => handleSelect(item.id)}
                        data-cy="select-autocomplete-option"
                      >
                        {item?.customOption}
                      </S.NormalDiv>
                    ) : (
                      <S.Option
                        key={index}
                        onClick={() => handleSelect(item.id)}
                        data-cy="select-autocomplete-option"
                      >
                        <S.OptionLabelFlex
                          fullWidth={!item?.additionalInfo?.length}
                        >
                          {item?.Icon && <item.Icon />}
                          <S.OptionLabel
                            fullWidth={!item?.additionalInfo?.length}
                          >
                            {label}
                          </S.OptionLabel>
                        </S.OptionLabelFlex>
                        {item?.additionalInfo && (
                          <Tooltip
                            placement="bottom"
                            overlay={
                              <>
                                {Array.isArray(item?.additionalInfo)
                                  ? item?.additionalInfo?.map((info, index) => (
                                      <div key={index}>{info}</div>
                                    ))
                                  : item?.additionalInfo}
                                <div></div>
                              </>
                            }
                            overlayInnerStyle={{
                              backgroundColor: theme.config.colors.primary,
                              borderRadius: '4px',
                              padding: '8px 16px',
                            }}
                          >
                            <S.OptionAdditionalInfo>
                              {Array.isArray(item?.additionalInfo)
                                ? item?.additionalInfo[0]
                                : item?.additionalInfo}
                            </S.OptionAdditionalInfo>
                          </Tooltip>
                        )}
                      </S.Option>
                    );
                  })
                )}
              </S.OptionsBox>
            </S.SearchBox>
          </ClickAwayListener>
        }
        overlayClassName="tooltip-light"
        overlayInnerStyle={{
          backgroundColor: 'transparent',
          padding: '0px',
        }}
      >
        <S.Container className={className} {...rest}>
          {!onlySearch && (
            <>
              {floatingPlaceholder && placeholder && (
                <S.FloatingPlaceholder
                  isDisabled={loading || disabled}
                  show={visibleFloatingPlaceholder}
                >
                  <span>{placeholder}</span>
                </S.FloatingPlaceholder>
              )}
              {customToggl ? (
                <S.CustomToggl onClick={handleToggl}>
                  {customToggl}
                </S.CustomToggl>
              ) : (
                <S.ToogleButton
                  onClick={() => {
                    (!selectedOptions.length || !isMultiple) && handleToggl();
                  }}
                  borders={borders}
                  filterStyle={filterStyle}
                  disabled={disabled}
                  className={togglClassName}
                  isMultiple={isMultiple}
                  data-cy={dataCy}
                  {...rest}
                >
                  {IconName && !rest.errors && (
                    <S.Icon>
                      <IconName color="#ACABB7" width={16} />
                    </S.Icon>
                  )}
                  {customIcon && !rest.errors && <S.Icon>{customIcon}</S.Icon>}
                  {rest.errors && (
                    <Tooltip
                      overlayClassName="tooltip-error"
                      placement="bottom"
                      transitionName="rc-tooltip-zoom"
                      getTooltipContainer={() => containerElement}
                      overlay={<ValidationError>{rest.errors}</ValidationError>}
                    >
                      <S.Error>
                        <AlertCircleSecondIcon type="small" color="#EC3237" />
                      </S.Error>
                    </Tooltip>
                  )}
                  {!loading &&
                    (isMultiple ? (
                      <MultipleResults
                        options={formattedOptions}
                        selectedOptions={selectedOptions}
                        theme={theme}
                        handleUnselect={handleUnselect}
                      />
                    ) : (
                      <SingleResult
                        results={formattedOptions}
                        selectedOptions={selectedOptions}
                      />
                    ))}
                  {!loading &&
                    !visibleFloatingPlaceholder &&
                    !selectedOptions.filter((v) => v || v === 0).length && (
                      <S.Placeholder isMultiple={isMultiple}>
                        {placeholder}
                      </S.Placeholder>
                    )}
                  {!awaysOpen && (
                    <S.Toggl onClick={handleToggl}>
                      <ArrowDownIcon color="#5D6670" type="small" />
                    </S.Toggl>
                  )}
                </S.ToogleButton>
              )}
            </>
          )}
        </S.Container>
      </Tooltip>
    </div>
  );
};

SelectAutocomplete.propTypes = {
  'data-cy': PropTypes.string,
  id: PropTypes.string,
  options: PropTypes.array,
  optionsGroups: PropTypes.array,
  placeholder: PropTypes.string,
  floatingPlaceholder: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  className: PropTypes.string,
  togglClassName: PropTypes.string,
  IconName: PropTypes.func,
  loading: PropTypes.bool,
  onChangeSearch: PropTypes.func,
  onChangeSelect: PropTypes.func,
  onChangeUnselect: PropTypes.func,
  isMultiple: PropTypes.bool,
  showAllOption: PropTypes.any,
  awaysOpen: PropTypes.bool,
  onlySearch: PropTypes.bool,
  borders: PropTypes.bool,
  filterStyle: PropTypes.bool,
  disabled: PropTypes.bool,
  index: PropTypes.string,
  indexActive: PropTypes.string,
  changeIndexActive: PropTypes.func,
  value: PropTypes.array,
  customToggl: PropTypes.object,
  customIcon: PropTypes.object,
  hasClearField: PropTypes.bool,
  clearFieldLabel: PropTypes.string,
  noSort: PropTypes.bool,
  hideSearch: PropTypes.bool,
  tooltipWidth: PropTypes.any,
  tooltipPlacement: PropTypes.string,
  containerElement: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
};

export default SelectAutocomplete;
