import styled from 'styled-components';
import { device } from 'modules/Utils/Devices';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 10.8rem;
  background: ${(props) => props.theme.config.colors.light};
  border-radius: 6px;
  padding: 2.6rem ${(props) => props.theme.config.spacing.spacing_4};
  margin-bottom: ${(props) => props.theme.config.spacing.spacing_6};
`;

export const GradingContainer = styled.div`
  text-align: center;
`;

export const Info = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  div {
    & + div {
      border-left: 1px solid ${(props) => props.theme.config.colors.gray_300};
      margin-left: ${(props) => props.theme.config.spacing.layout_5};
      padding-left: ${(props) => props.theme.config.spacing.layout_5};
    }
    span,
    strong {
      display: block;
    }
    a {
      display: inline-block;
      text-align: center;
      margin-top: ${(props) => props.theme.config.spacing.spacing_2};
    }

    strong {
      ${(props) => props.theme.config.typograph.default};
      color: ${(props) => props.theme.config.colors.text_base};
      font-weight: bold;
      margin-top: ${(props) => props.theme.config.spacing.spacing_1};
    }

    span {
      ${(props) => props.theme.config.typograph.default};
      color: ${(props) => props.theme.config.colors.text_base};
    }

    svg {
      display: inline-block;
      width: 1.6rem;
      opacity: 0.5;

      path {
        fill: ${(props) => props.theme.config.colors.text_base};
      }
    }
  }

  @media ${device.tabletAndMobile} {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    div {
      width: inherit;
      padding: ${(props) => props.theme.config.spacing.layout_1} 0;
      &:last-child {
        padding-bottom: 0;
      }
      & + div {
        text-align: left;
        position: relative;
        padding: 1.5rem 0 0;
        margin: 0;
        width: 100%;
        border-left: none;
        border-top: 1px solid ${(props) => props.theme.config.colors.gray_300};
        span {
          width: fit-content;
        }
        svg {
          position: relative;
          margin: 0 auto;
          width: cover;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background: ${(props) => props.theme.config.colors.gray_300};
  border-radius: 6px;
  padding: 2.4rem;
  min-height: 108px;
  margin-bottom: ${(props) => props.theme.config.spacing.spacing_6};
  @media ${device.tabletAndMobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const InfoContainer = styled.div`
  width: 40%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media ${device.tabletAndMobile} {
    width: 100%;
    grid-template-columns: 1fr;
    gap: 32px;
  }
  > div {
    span {
      display: block;
      margin-top: 1rem;
    }
    div:first-child {
      max-width: 5.6rem;
    }
    div:last-child {
      max-width: 15rem;
    }
  }
`;
