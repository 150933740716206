const subtitle = (firstMark, lastMark) =>
  `Enter score between ${firstMark} and ${lastMark}.`;

const getSubtitleForTestType = (testType, category, availableMarks) => {
  switch (testType.toLowerCase()) {
    case 'a2_flyers': {
      const first = availableMarks[0] || 0;
      const last = availableMarks[availableMarks.length - 1] || 5;
      return subtitle(first, last);
    }
    case 'cefr':
      return subtitle('A0', 'C1');
    case 'toefl':
    case 'cambridge':
      if (category?.toLowerCase() === 'speaking') return subtitle('0', '4');
      return subtitle('0', '5');
    case 'ielts':
      return subtitle('0', '9');
    default:
      return 'Score Subtitle';
  }
};

export const getGradesSubtitleByTestCategoryAndType = (
  category,
  testType,
  availableMarks = []
) => {
  if (!category || !testType) return 'Score Subtitle';
  return getSubtitleForTestType(testType, category, availableMarks);
};
