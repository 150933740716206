import React from 'react';
import PropTypes from 'prop-types';
import { CustomSelect } from '../PaginationSelect';
import * as S from './PaginationInfo.styles';
import i18n from 'i18next';

const PaginationInfo = ({ total, perPage, onToggleClick }) => (
  <S.Span data-test="pagination-info-text">
    {i18n.t('commons.tables.pagination.showing')}
    <CustomSelect
      perPage={perPage}
      total={total}
      onToggleClick={onToggleClick}
    />
    {i18n.t('commons.tables.pagination.of')} {total}{' '}
    {i18n.t('commons.tables.pagination.entries')}
  </S.Span>
);

PaginationInfo.propTypes = {
  onToggleClick: PropTypes.func,
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  perPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default PaginationInfo;
