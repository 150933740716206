import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { useDraggable } from '@dnd-kit/core';

import { IconRoundBox, Icon } from 'modules/Core/Common';

import * as S from './WidgetsSidebar.styles';

export const DraggableWidgetItem = ({ widget, ...props }) => {
  const theme = useTheme();

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: widget?.id,
    data: widget,
  });

  const draggableStyle = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  return (
    <S.Widget
      key={widget.id}
      ref={setNodeRef}
      style={draggableStyle}
      {...listeners}
      {...attributes}
      {...props}
    >
      <S.WidgetIcon>
        <IconRoundBox small withBg bg={theme.config.colors.primary_100}>
          <Icon name="user-registered" fontSize="1rem" />
        </IconRoundBox>
        <div>
          <p>{widget.name}</p>
          {!!widget?.test_type && <span>{widget?.test_type}</span>}
        </div>
      </S.WidgetIcon>
      <Icon name="plus" fontSize="1.2rem" />
    </S.Widget>
  );
};

DraggableWidgetItem.propTypes = {
  widget: PropTypes.object,
};
