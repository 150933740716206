import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Async, Actions, Selectors } from 'modules/BaseTests/BaseTestsSlice';

export const useBaseTests = (hideProgressCheck = true) => {
  const state = useSelector(Selectors.fetchListData);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (!state?.loading) fetchBaseTestsList();

    return () => dispatch(Actions.cancelRequests());
  }, []);

  useEffect(() => {
    return () => dispatch(Actions.cleanState());
  }, [location.pathname]);

  const fetchBaseTestsList = () =>
    dispatch(Async.fetchBaseTestsList({ hideProgressCheck }));

  return { state, fetchBaseTestsList };
};
