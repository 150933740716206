import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 16px;
    opacity: ${(props) => (props.hasHover ? '1' : '0.5')};

    path {
      fill: ${(props) => props.theme.config.colors.text_base};
    }
  }
`;

export { Container };
