import axios from 'axios';
import { getToken } from 'modules/Auth/Services';
import store from 'config/store';
import { Actions as AuthActions } from 'modules/Auth/AuthSlice';
import { Toast } from 'modules/Core/Common';
import { IS_MAINTENANCE } from 'modules/Maintenance/Utils/Maintenance.utils';
import ENV from 'config/env';

const client = axios.create({
  baseURL: ENV.apiStudent,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'json',
  },
});

const reqSuccess = async (config) => {
  const authHeaders = {};
  const token = getToken();

  if (token) {
    authHeaders.authorization = `Bearer ${token}`;
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      ...authHeaders,
    },
  };
};

// TODO: Handle if returns token
const responseSuccess = (res) => res;

const responseError = async (error) => {
  if (error?.message?.match(/Network Error/)) {
    const msg = 'Internet connection error';
    store.dispatch(AuthActions.setServerError('Something went wrong'));
    return Promise.reject(new Error(msg));
  }

  // fallback
  if (!error.response) {
    return Promise.reject(error);
  }

  if (error.response?.data?.error_desc?.match(/Token expired/)) {
    store.dispatch(AuthActions.setTokenExpired(true));
    !IS_MAINTENANCE && Toast('Token expired!', 'info');
  }

  if (error.response?.data?.error_desc?.match(/Invalid token/)) {
    store.dispatch(AuthActions.setTokenInvalid());
  }

  if (error.response?.status === 500) {
    const { error_desc: errorDesc } = error.response.data;
    const errorMessage =
      typeof errorDesc === 'string' ? errorDesc : 'Something went wrong';
    return Promise.reject(new Error(errorMessage));
  }

  if (error.response?.status === 502) {
    store.dispatch(AuthActions.setServerError('Something went wrong'));
    return Promise.reject(new Error('Something went wrong'));
  }

  if (error.response?.data?.error_desc?.includes('User not found')) {
    return Promise.reject(
      new Error('Wrong username and/or password. Please try again')
    );
  }

  if (error.response.data?.error_desc) {
    return Promise.reject(new Error(error.response.data.error_desc));
  }

  if (error.response.status === 404) {
    return Promise.reject(new Error('Something went wrong'));
  }

  if (error.response.data) {
    const msg =
      typeof error.response.data === 'string'
        ? error.response.data
        : error.response.data.content;

    return Promise.reject(new Error(msg));
  }

  return Promise.reject(error);
};

client.interceptors.request.use(reqSuccess, undefined);
client.interceptors.response.use(responseSuccess, responseError);

/**
 * @see {@link https://github.com/axios/axios#axiosrequestconfig}
 */
const request = (opts) => client.request(opts);

export { client, request };
