import styled from 'styled-components';

export const Title = styled.span`
  color: ${(props) => props.theme.config.colors.text_base};
`;

export const ToggleButtonsSettings = styled.div`
  font-size: 1.4rem;
  color: ${(props) => props.theme.config.colors.text_base};
  border-bottom: solid 1px ${(props) => props.theme.config.colors.primary_300};
  margin-bottom: 2rem;

  button {
    height: 6rem;
    font-size: 2.4rem;
    line-height: 2.4rem;
    background: transparent;
    color: ${(props) => props.theme.config.colors.text_base};
    width: fit-content;
    border: none;
    border-bottom: 1px solid transparent;
    box-sizing: content-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: all 0.3s;
    margin-bottom: -2px;
    margin-right: 3.8rem;
    outline: none;
    &:hover {
      border: none;
      border-bottom: 4px solid ${(props) => props.theme.config.colors.primary};
      color: ${(props) => props.theme.config.colors.text_base};
      box-sizing: content-box;
      -webkit-appearance: none;
      -moz-appearance: none;
    }
    &.toggle-active {
      border: none;
      border-bottom: 4px solid ${(props) => props.theme.config.colors.primary};
      color: ${(props) => props.theme.config.colors.primary};
      font-weight: bold;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
`;
