import { createSlice } from '@reduxjs/toolkit';
import { defaultRequest } from 'modules/Utils/DefaultRequest';
import { LANGUAGES_URL } from 'modules/Api/Routes';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const languagesSlice = createSlice({
  name: 'languages',
  initialState,
  reducers: {
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    requestLanguages: (state) => {
      state.loading = true;
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.lading = false;
    },
    /**
     * receive a success languages list response
     */
    receiveLanguagesList: (state, action) => {
      state.loading = false;
      state.data = action?.payload?.content;
    },
    clearLanguagesList: (state) => {
      state.loading = false;
      state.data = [];
    },
    /**
     * receive an error response
     */
    receiveLanguagesError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

const Actions = languagesSlice.actions;

const Selectors = {
  fetchListData: (state) => state.languages,
  languageLoading: ({ languages: { loading } }) => ({ loading }),
};

const Async = {
  fetchLanguagesList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      LANGUAGES_URL,
      'GET',
      Actions.requestLanguages,
      Actions.receiveLanguagesList,
      Actions.receiveLanguagesError
    ),
};

const { reducer } = languagesSlice;

export { reducer, Actions, Async, Selectors };
