import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'modules/Core/Common';
import * as S from './SidebarLogo.styles';

const SidebarLogo = ({ logo }) => (
  <S.Container>
    <NavLink to="/dashboard">
      <img src={logo} alt="EduSynch logo" />
    </NavLink>
  </S.Container>
);

SidebarLogo.propTypes = {
  logo: PropTypes.string.isRequired,
};

export default SidebarLogo;
