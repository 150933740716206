import React from 'react';
import PropTypes from 'prop-types';
import ResponseDataHandleEmpty from './ResponseDataHandleEmpty.component';
import ResponseDataHandlePlaceholder from './ResponseDataHandlePlaceholder.component';
import ResponseDataHandleServerError from './ResponseDataHandleServerError.component';

const hasData = (data) => {
  if (data && Object.prototype.hasOwnProperty.call(data, 'total')) {
    return !!data.total;
  }

  return !!data;
};

const ResponseDataHandle = ({ error, loading, data, render, ...props }) => {
  if (error) {
    return <ResponseDataHandleServerError {...props} />;
  }

  if (loading) {
    return <ResponseDataHandlePlaceholder {...props} />;
  }

  if (!hasData(data)) {
    return <ResponseDataHandleEmpty {...props} />;
  }

  return render(data);
};

ResponseDataHandle.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  data: PropTypes.any,
  render: PropTypes.any,
};

export default ResponseDataHandle;
