import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

import { Button, Input, Switch } from 'modules/Core/Common';
import { ReactComponent as EmptyIcon } from 'assets/layout/empty-data/oops-icon.svg';
import { ChevronLeftIcon, SearchIcon } from '@edusynch/edusynch-svg-icons';
import i18n from 'i18next';

import * as S from './Menu.styles';

export const Menu = ({
  options,
  toggleOptions,
  currentFilters,
  onFilterChange,
  onSelect,
  onSearchMouseEnter,
  onSearchMouseLeave,
  onHeaderMouseEnter,
  onHeaderMouseLeave,
}) => {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(null);
  const [search, setSearch] = useState('');
  const theme = useTheme();

  useEffect(() => {
    return () => {
      setSelectedOptionIndex(null);
    };
  }, []);

  const showToggleOptions =
    selectedOptionIndex === null && !!toggleOptions?.length;

  const computedOptions =
    options?.[selectedOptionIndex]?.items
      ?.filter((item) => {
        const currentTypeFilter = currentFilters?.find(
          (filter) => filter?.type === options?.[selectedOptionIndex]?.type
        );
        return item?.id !== currentTypeFilter?.value;
      })
      ?.filter((item) => {
        const label = item.name || item.label;
        return label?.toLowerCase().includes(search.toLowerCase());
      }) || options;

  const groupedTitles = useMemo(() => {
    const allTitles = computedOptions?.map((item) => item?.groupedBy);
    return allTitles.filter(
      (item, index) => item && allTitles.indexOf(item) === index
    );
  }, [computedOptions]);

  const handleSelect = (option, index) => {
    if (onSelect) onSelect();
    if (!option.filterName) return setSelectedOptionIndex(index);

    onFilterChange(option);
    setSelectedOptionIndex(null);
  };

  const currentFilterTitle = options?.[selectedOptionIndex]?.type;

  return (
    <S.Container>
      {selectedOptionIndex !== null && (
        <>
          <S.OptionHeader
            onMouseEnter={() => {
              if (onHeaderMouseEnter) onHeaderMouseEnter();
            }}
            onMouseLeave={() => {
              if (onHeaderMouseLeave) onHeaderMouseLeave();
            }}
          >
            <Button
              onClick={() => setSelectedOptionIndex(null)}
              light
              style={{
                border: 'none',
                padding: '0',
                minWidth: '2.4rem',
                height: '2.4rem',
                color: theme.config.colors.gray,
                backgroundColor: 'transparent',
              }}
            >
              <ChevronLeftIcon />
            </Button>
            <h6>{currentFilterTitle}</h6>
          </S.OptionHeader>
          <S.SearchContainer>
            <Input
              PrependIcon={SearchIcon}
              placeholder="Search"
              value={search}
              onChange={({ target }) => setSearch(target.value)}
              onMouseEnter={() => {
                if (onSearchMouseEnter) onSearchMouseEnter();
              }}
              onMouseLeave={() => {
                if (onSearchMouseLeave) onSearchMouseLeave();
              }}
            />
          </S.SearchContainer>
        </>
      )}
      <S.Options>
        {computedOptions.length ? (
          groupedTitles?.length ? (
            groupedTitles?.map((groupTitle, index) => (
              <S.GroupedOptions key={index}>
                <S.GroupedOptionsTitle>{groupTitle}</S.GroupedOptionsTitle>
                {computedOptions?.map(({ Icon, ...option }, optionIndex) => {
                  if (option?.groupedBy !== groupTitle) return null;
                  return (
                    <S.GroupedOptionsItem
                      key={option.id}
                      onClick={() => handleSelect(option, optionIndex)}
                    >
                      {Icon && <Icon className="left" />}
                      {option.render ? (
                        <>{option.render}</>
                      ) : (
                        option.name || option.label || option.type
                      )}
                    </S.GroupedOptionsItem>
                  );
                })}
              </S.GroupedOptions>
            ))
          ) : (
            <>
              {computedOptions?.map(({ Icon, ...option }, index) => (
                <S.Option
                  key={option.id}
                  onClick={() => handleSelect(option, index)}
                >
                  {Icon && <Icon className="left" />}
                  {option.render ? (
                    <>{option.render}</>
                  ) : (
                    option.name || option.label || option.type
                  )}
                </S.Option>
              ))}
              {showToggleOptions &&
                toggleOptions?.map((toggleOption) => (
                  <S.Option
                    key={toggleOption.id}
                    between
                    onClick={() => {
                      onHeaderMouseEnter();
                      toggleOption.onChange(toggleOption);
                    }}
                  >
                    {toggleOption.name}
                    <Switch
                      active={toggleOption.isActive}
                      onChange={({ target }) =>
                        toggleOption.onChange(target.checked)
                      }
                    />
                  </S.Option>
                ))}
            </>
          )
        ) : (
          <S.Empty>
            <EmptyIcon />
            <S.EmptyDescription>
              <span>{i18n.t('commons.filter.empty.ops')}</span>{' '}
              {i18n.t('commons.filter.empty.message')}
            </S.EmptyDescription>
          </S.Empty>
        )}
      </S.Options>
    </S.Container>
  );
};

Menu.propTypes = {
  options: PropTypes.array,
  toggleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      filterName: PropTypes.string,
      name: PropTypes.string,
      isActive: PropTypes.bool,
      onChange: PropTypes.func,
    })
  ),
  currentFilters: PropTypes.array,
  onFilterChange: PropTypes.func,
  onSelect: PropTypes.func,
  onSearchMouseEnter: PropTypes.func,
  onSearchMouseLeave: PropTypes.func,
  onHeaderMouseEnter: PropTypes.func,
  onHeaderMouseLeave: PropTypes.func,
};
