import styled, { css } from 'styled-components';

export const SelectField = styled.select`
  ${(props) => props.theme.config.typograph.default};
  display: block;
  padding: 0 2.4rem 0 1.6rem;
  width: 100%;
  height: 4.8rem;
  border: 1px solid transparent;
  background: ${(props) => props.theme.config.colors.light};
  border-radius: 0.6rem;
  outline: none;
  color: ${(props) => props.theme.config.colors.text_base};
  appearance: none;
  transition: 0.2s ease-in;
  ${({ prependInnerIcon }) =>
    !!prependInnerIcon &&
    css`
      text-indent: 2.4rem;
    `};

  &:hover,
  &:focus {
    border-color: ${(props) => props.theme.config.colors.primary};

    & + svg {
      path {
        fill: ${(props) => props.theme.config.colors.primary};
      }
    }
  }

  &:disabled {
    color: ${(props) => props.theme.config.colors.text_base};
    background: ${(props) => props.theme.config.colors.gray_200};
    border: 1px solid ${(props) => props.theme.config.colors.gray_300};
    cursor: not-allowed;

    & + svg {
      path {
        fill: ${(props) => props.theme.config.colors.gray_400};
      }
    }
  }

  ${(props) =>
    props.errors &&
    css`
      border-color: ${(props) => props.theme.config.colors.tertiary} !important;

      & + svg {
        path {
          fill: ${(props) => props.theme.config.colors.tertiary} !important;
        }
      }
    `}
`;

export const Container = styled.div`
  display: block;
  position: relative;

  ${({ prependInnerIcon }) =>
    !!prependInnerIcon &&
    css`
      display: flex;

      svg:first-child {
        width: 1.6rem;
        height: 1.6rem;
        position: absolute;
        bottom: 50%;
        transform: translateY(50%);
        left: 1.6rem;
      }
    `};

  svg:not[.edu-icon-arrow-down] {
    background: red;
  }

  .edu-icon-arrow-down {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    width: 1.4rem;

    path {
      fill: ${(props) => props.theme.config.colors.text_base};
      transition: 0.2s ease-in;
    }
  }
`;
