import React, { useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { useTheme } from 'styled-components';
import { IconButton } from '@mui/material';
import { WidgetFiltersLoading } from './WidgetFiltersLoading.component';
import { useDebounce } from 'modules/Core/Hooks';
import { ReactComponent as EmptyIcon } from 'assets/layout/empty-data/oops-icon.svg';

import {
  Card,
  Icon,
  Button,
  Input,
  FormGroup,
  Avatar,
} from 'modules/Core/Common';

import * as S from './WidgetFilters.styles';

const MIN_SEARCH_LENGTH = 3;

export const WidgetFilters = ({
  widget,
  defaultUserId,
  defaultSearch,
  users = [],
  onSave,
  onClose,
  isLoading,
  onSearchChange,
}) => {
  const [selectedUserId, setSelectedUserId] = useState(defaultUserId);
  const [tempSearch, setTempSearch] = useState('');
  const [search, setSearch] = useState(defaultSearch);
  const theme = useTheme();

  useDebounce(
    () => {
      if (
        search === tempSearch ||
        (search.length > 0 && search.length < MIN_SEARCH_LENGTH)
      )
        return;

      setTempSearch(search);
      onSearchChange(search);
    },
    [search],
    500
  );

  const handleUnselectUser = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedUserId(null);
  };

  const handleSave = () => {
    onSave({ userId: selectedUserId });
    onClose();
  };

  return (
    <Card noPadding style={{ height: '100%' }}>
      <S.Container>
        <S.Header>
          <div>
            <h6>{widget?.name}</h6>
            <p>{i18n.t('common-words.filter')}</p>
          </div>
          <IconButton
            aria-label="close"
            id="close"
            onClick={onClose}
            disabled={isLoading}
            sx={{ marginLeft: 'auto' }}
          >
            <Icon name="close" />
          </IconButton>
        </S.Header>
        <S.Content>
          <FormGroup>
            <Input
              id="search-user"
              prependIcon="search"
              medium
              style={{
                width: '100%',
                border: `1px solid ${theme.config.colors.gray_300}`,
              }}
              dataCy="filter.search-input"
              placeholder={i18n.t('modules.widgets.filters.search_user')}
              value={search}
              onChange={({ target }) => setSearch(target.value)}
              disabled={isLoading}
            />
          </FormGroup>
          {isLoading ? (
            <WidgetFiltersLoading />
          ) : !!users && !!users.length ? (
            <S.UsersList>
              {users?.map((user) => {
                if (selectedUserId && user.id !== selectedUserId) return null;

                return (
                  <S.User
                    key={user.id}
                    className={selectedUserId === user.id ? 'active' : ''}
                  >
                    <button
                      className="user"
                      onClick={() => setSelectedUserId(user.id)}
                    >
                      <Avatar src={user.avatar.url} name={user.name} />
                      <span>{user.name}</span>
                    </button>
                    {selectedUserId === user.id && (
                      <IconButton
                        aria-label="remove"
                        id="remove"
                        onClick={handleUnselectUser}
                        disabled={isLoading}
                      >
                        <Icon name="trash" fontSize="1.6rem" />
                      </IconButton>
                    )}
                  </S.User>
                );
              })}
            </S.UsersList>
          ) : (
            <S.Empty>
              {!tempSearch ? (
                <p>Enter at least {MIN_SEARCH_LENGTH} characters to search</p>
              ) : (
                <S.EmptyMessage>
                  <EmptyIcon />
                  <p>
                    <strong>{i18n.t('commons.filter.empty.ops')}</strong>{' '}
                    <span>{i18n.t('commons.filter.empty.message')}</span>
                  </p>
                </S.EmptyMessage>
              )}
            </S.Empty>
          )}
        </S.Content>
        <S.Footer>
          <Button medium onClick={handleSave} disabled={isLoading}>
            {i18n.t('common-words.save')}
          </Button>
        </S.Footer>
      </S.Container>
    </Card>
  );
};

WidgetFilters.propTypes = {
  widget: PropTypes.object,
  defaultUserId: PropTypes.any,
  defaultSearch: PropTypes.string,
  users: PropTypes.array,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  onSearchChange: PropTypes.func,
  isLoading: PropTypes.bool,
};
