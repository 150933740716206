import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import { PaginationPageNumber } from '../PaginationPageNumber';

import * as S from './PaginationPageNumberCollection.styles';

const PaginationPageNumberCollection = ({
  currentPage,
  onPageClick,
  totalPages,
}) => {
  return (
    <S.Container>
      <S.Text>{i18n.t('commons.tables.pagination.page')}</S.Text>
      <PaginationPageNumber
        currentPage={currentPage}
        onPageClick={onPageClick}
        totalPages={totalPages}
      />
      <S.Text data-test="total-pages">
        {i18n.t('commons.tables.pagination.of')} {totalPages}
      </S.Text>
    </S.Container>
  );
};

PaginationPageNumberCollection.propTypes = {
  total: PropTypes.number,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  onPageClick: PropTypes.func,
};

export default PaginationPageNumberCollection;
