import React from 'react';
import PropTypes from 'prop-types';

import * as S from './MUIMenu.styles';

export function MUIMenuItem({ onClick, children }) {
  return <S.MUIMenuItem onClick={onClick}>{children}</S.MUIMenuItem>;
}

MUIMenuItem.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
};
