import {
  formatGeneralOptions,
  shouldRender,
  sessionOptions,
  generalOptions,
  toeflSpeakingOptions,
  toeflWritingOptions,
  ieltsWritingOptions,
  ieltsSpeakingOptions,
  linguaskillSpeakingOptions,
  linguaskillWritingOptions,
} from '../Common/ExportAdvanced/utils';
import {
  PRACTICE_RESULTS_STUDENTS_EXPORT_URL,
  PRACTICE_RESULTS_STUDENTS_EXPORT_DETAILS_URL,
  PRACTICE_RESULTS_INSTITUTIONS_ANALYTICS_EXPORT_URL,
  PRACTICE_RESULTS_INSTITUTIONS_EXPORT_URL,
} from 'modules/Api/Routes';

export const getApiUrlByScreen = (screen) => {
  switch (screen) {
    case 'students-list':
      return PRACTICE_RESULTS_STUDENTS_EXPORT_URL;
    case 'student-details':
      return PRACTICE_RESULTS_STUDENTS_EXPORT_DETAILS_URL;
    case 'institutions-analytics':
      return PRACTICE_RESULTS_INSTITUTIONS_ANALYTICS_EXPORT_URL;
    default:
      return PRACTICE_RESULTS_INSTITUTIONS_EXPORT_URL;
  }
};

export const generateExportAdvancedFilters = (
  exportFilter,
  handleChangeExportFilter
) => {
  if (exportFilter.resultsScreen !== 'students-list') {
    const defaultValue = false;

    const filteredGeneralOptions = formatGeneralOptions(
      generalOptions,
      exportFilter
    );

    const generateFilter = (options, addTag = true) =>
      options?.forEach((option) =>
        handleChangeExportFilter(
          option?.tag,
          addTag ? exportFilter?.[option?.tag] || defaultValue : defaultValue
        )
      );

    exportFilter.resultsScreen === 'student-details' &&
      generateFilter(sessionOptions);

    generateFilter(filteredGeneralOptions);

    generateFilter(
      toeflSpeakingOptions,
      shouldRender('TOEFL', 'SPEAKING', exportFilter)
    );

    generateFilter(
      toeflWritingOptions,
      shouldRender('TOEFL', 'WRITING', exportFilter)
    );

    generateFilter(
      ieltsWritingOptions,
      shouldRender('IELTS', 'WRITING', exportFilter)
    );

    generateFilter(
      ieltsSpeakingOptions,
      shouldRender('IELTS', 'SPEAKING', exportFilter)
    );
  }
};

export const formatAdvancedParams = (exportFilter) => {
  if (exportFilter.invalid_sessions_only || exportFilter.no_sessions_only) {
    return {};
  }

  const mountParams = (arrayOptions) => {
    const params = {};
    arrayOptions?.forEach((option) => {
      if (exportFilter?.[option?.tag] !== undefined) {
        params[option.tag] = exportFilter?.[option?.tag];
      }
    });
    return params;
  };

  const mountIfShouldRender = (test, category, arrayOptions) =>
    shouldRender(test, category, exportFilter) ? mountParams(arrayOptions) : {};

  const filteredGeneralOptions = formatGeneralOptions(
    generalOptions,
    exportFilter
  );

  const sessionParams = mountParams(sessionOptions);
  const generalParams = mountParams(filteredGeneralOptions);
  const toeflSpeakingParams = mountIfShouldRender(
    'TOEFL',
    'SPEAKING',
    toeflSpeakingOptions
  );
  const toeflWritingParams = mountIfShouldRender(
    'TOEFL',
    'WRITING',
    toeflWritingOptions
  );
  const ieltsWritingParams = mountIfShouldRender(
    'IELTS',
    'WRITING',
    ieltsWritingOptions
  );
  const ieltsSpeakingParams = mountIfShouldRender(
    'IELTS',
    'SPEAKING',
    ieltsSpeakingOptions
  );
  const linguaskillGeneralSpeakingParams = mountIfShouldRender(
    'LINGUASKILL_GENERAL',
    'SPEAKING',
    linguaskillSpeakingOptions
  );
  const linguaskillBusinessSpeakingParams = mountIfShouldRender(
    'LINGUASKILL_BUSINESS',
    'SPEAKING',
    linguaskillSpeakingOptions
  );
  const linguaskillGeneralWritingParams = mountIfShouldRender(
    'LINGUASKILL_GENERAL',
    'WRITING',
    linguaskillWritingOptions
  );
  const linguaskillBusinessWritingParams = mountIfShouldRender(
    'LINGUASKILL_BUSINESS',
    'WRITING',
    linguaskillWritingOptions
  );

  return {
    ...sessionParams,
    ...generalParams,
    ...toeflSpeakingParams,
    ...toeflWritingParams,
    ...ieltsWritingParams,
    ...ieltsSpeakingParams,
    ...linguaskillGeneralSpeakingParams,
    ...linguaskillBusinessSpeakingParams,
    ...linguaskillGeneralWritingParams,
    ...linguaskillBusinessWritingParams,
  };
};
