import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactHotLoader from 'react-hot-loader';
import PatchedReactDOM from '@hot-loader/react-dom';
import { Provider } from 'react-redux';
import store from 'config/store';
import Sentry from 'config/Sentry';
import reportWebVitals from './reportWebVitals';
import Translation from './config/translation/i18n';
import i18n from 'i18next';
import { I18nextProvider } from 'react-i18next';
import Root from './Root';
import * as serviceWorker from './serviceWorker';
import { isDevelop as isDevelopFromReactAppEnv } from 'modules/Utils';

if (process.env.NODE_ENV === 'development') {
  ReactHotLoader.patch(React, PatchedReactDOM);
}

if (!isDevelopFromReactAppEnv) {
  Sentry.init();
}

const container = document.getElementById('root');
const root = createRoot(container);

Translation.init(() =>
  root.render(
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <Root />
      </I18nextProvider>
    </Provider>
  )
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
