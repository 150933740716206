import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

import { Modal, RangeDatePicker } from 'modules/Core/Common';

export const RangeDatePickerModal = ({
  isOpen,
  onClose,
  startDate,
  endDate,
  onDateChange,
  onSave,
  isDateTime,
  customTitle,
  unlimitedMaxDate,
  customMaxDateInDays,
}) => {
  const edusynchTheme = useTheme();

  return (
    <Modal
      width="700px"
      isOpen={isOpen}
      setIsOpen={onClose}
      overflow="unset"
      background={edusynchTheme.config.colors.light}
      maxHeight="100vh"
    >
      <RangeDatePicker
        onCancel={onClose}
        startDate={startDate}
        endDate={endDate}
        onDateChange={onDateChange}
        onSave={onSave}
        isScrollable
        isDateTime={isDateTime}
        customTitle={customTitle}
        unlimitedMaxDate={unlimitedMaxDate}
        customMaxDateInDays={customMaxDateInDays}
      />
    </Modal>
  );
};

RangeDatePickerModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onDateChange: PropTypes.func,
  onSave: PropTypes.func,
  isDateTime: PropTypes.bool,
  customTitle: PropTypes.string,
  unlimitedMaxDate: PropTypes.bool,
  customMaxDateInDays: PropTypes.number,
};
