import styled, { css } from 'styled-components';

const Option = styled.span`
  ${(props) => props.theme.config.typograph.xsmall};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: ${(props) => props.theme.config.spacing.spacing_5};
  border-radius: 0.4rem;
  background: ${(props) => props.theme.config.colors.gray_100};
  margin: 0 auto;
  color: ${(props) => props.theme.config.colors.text_base};

  ${(props) =>
    props.active &&
    css`
      width: 5.5rem;
      background: ${(props) => props.theme.config.colors.green_100};
      color: ${(props) => props.theme.config.colors.green};
    `}
`;

export { Option };
