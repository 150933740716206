import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import { UserAvatar } from 'modules/Users/Common';
import { Skeleton } from 'modules/Core/Common';
import * as S from './HeaderInfo.styles';
import i18n from 'i18next';

const HeaderInfoContext = createContext();

const useHeaderInfoContext = () => {
  const context = useContext(HeaderInfoContext);
  if (!context) {
    throw new Error(i18n.t('errors.error-header-info-context'));
  }
  return context;
};

const Content = () => {
  const { id, loading, hasId, avatar, name, email } = useHeaderInfoContext();

  if (loading) {
    return (
      <S.InfoWrapper>
        <S.UserContainer>
          <Skeleton type="round" isUser />
        </S.UserContainer>
        <S.InfoContainer>
          <div className="primary">
            <Skeleton type="string" />
            <Skeleton type="string" />
          </div>
          {hasId && (
            <div className="secondary">
              <Skeleton type="string" />
              <Skeleton type="string" />
            </div>
          )}
        </S.InfoContainer>
      </S.InfoWrapper>
    );
  }

  return (
    <>
      <S.User>
        <UserAvatar size="4.8rem" avatar={avatar} name={name}>
          <UserAvatar.Photo />
          <UserAvatar.Name />
        </UserAvatar>
      </S.User>
      <S.Info>
        <div>
          <h6>Email</h6>
          <span>{email}</span>
        </div>
        {id && (
          <div>
            <h6>ID</h6>
            <span>{id}</span>
          </div>
        )}
      </S.Info>
    </>
  );
};

const HeaderInfo = ({ children, loading, avatar, name, email, id, hasId }) => {
  const values = useMemo(
    () => ({
      loading,
      avatar,
      name,
      email,
      id,
      hasId,
    }),
    [loading, avatar, name, email, id, hasId]
  );

  return (
    <HeaderInfoContext.Provider value={values}>
      <S.Container>{children}</S.Container>
    </HeaderInfoContext.Provider>
  );
};

HeaderInfo.Content = Content;

HeaderInfo.propTypes = {
  loading: PropTypes.bool,
  avatar: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasId: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
};

export default HeaderInfo;
