const LOCAL_HOSTNAMES = ['localhost', '127.0.0.1'];
const DEVELOP_HOSTNAMES = ['cmsdev'];
const STAGE_HOSTNAMES = ['cmsstage'];

export const isLocal = LOCAL_HOSTNAMES.includes(location.hostname);
export const isDevelop =
  process.env.REACT_APP_ENV === 'development' ||
  DEVELOP_HOSTNAMES.includes(location.hostname);
export const isStage =
  process.env.REACT_APP_ENV === 'stage' ||
  STAGE_HOSTNAMES.includes(location.hostname);
export const isProduction = process.env.REACT_APP_ENV === 'production';

export const localEnvLabel = 'Localhost';
export const devEnvLabel = 'Dev';
export const stageEnvLabel = 'Stage';

export const currentEnv = isLocal
  ? localEnvLabel
  : isDevelop
  ? devEnvLabel
  : isStage
  ? stageEnvLabel
  : null;
