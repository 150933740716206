import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  display: flex;
  top: 0;
  justify-content: space-between;
  background: ${(props) => props.theme.config.colors.light};
  z-index: 1;
  height: 6rem;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 6px 6px 0 0;

  h1 {
    ${(props) => props.theme.config.typograph.quartenary};
    margin: ${(props) => props.theme.config.spacing.spacing_4} 0
      ${(props) => props.theme.config.spacing.spacing_4}
      ${(props) => props.theme.config.spacing.spacing_5};
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;

  button {
    & + button {
      margin-left: ${(props) => props.theme.config.spacing.spacing_4};
    }
  }
`;

export const ButtonClose = styled.button`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 1.8rem 2rem;
  width: 60px;
  text-align: center;
  background-color: ${(props) => props.theme.config.colors.gray_100};
  border: none;
  outline: none;
  border-radius: 0 8px 0 0;

  svg path {
    fill: ${(props) => props.theme.config.colors.text_base};
  }
`;
