import styled from 'styled-components';

export const DropContainer = styled.div`
  padding: ${(props) => (!props.bigSize ? '2.6rem' : '4rem')};
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin-bottom: 1.4rem;
  }

  div {
    text-align: center;
    font-size: 1.4rem;
    margin-left: ${(props) => (!props.bigSize ? '2rem' : '0')};
    margin-top: ${(props) => (!props.bigSize ? '0' : '3rem')};

    strong {
      font-weight: normal;
      display: block;
      margin-bottom: 0.4rem;
      color: ${(props) =>
        props.error
          ? props.theme.config.colors.tertiary
          : props.theme.config.colors.primary};
    }

    span {
      display: block;
      color: ${(props) => props.theme.config.colors.gray};
    }
  }
`;

export const DropImageContainer = styled.div`
  width: 111%;
  background-color: ${(props) => props.theme.config.colors.primary_200};
  margin: -1.8rem;
  display: flex;
  justify-content: center;
  padding: ${(props) => (!props.bigSize ? '2rem' : '0')};
  height: ${(props) => (!props.bigSize ? '160px' : '208px')};
  border-radius: 0.6rem;
  position: relative;

  img {
    max-width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const RemoveImage = styled.div`
  background-color: ${(props) => props.theme.config.colors.tertiary};
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 0.2rem;
  position: absolute;
  top: 3.3rem;
  right: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 0.8rem;
    height: 0.8rem;

    path {
      fill: ${(props) => props.theme.config.colors.light};
    }
  }
`;
