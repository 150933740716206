import React from 'react';
import PropTypes from 'prop-types';
import * as S from './NavLink.styles';

const NavLink = ({ children, activateStyle, ...rest }) => (
  <S.SytledLink activeStyle={activateStyle} {...rest}>
    {children}
  </S.SytledLink>
);

NavLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]),
  // styled CSS type
  activateStyle: PropTypes.any,
};

export default NavLink;
