import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  LIVE_INTERVIEWS_LIST_URL,
  LIVE_INTERVIEWS_START_LIVE_TEST_URL,
  LIVE_INTERVIEWS_END_LIVE_TEST_URL,
  LIVE_INTERVIEWS_INSTRUCTIONS_URL,
} from 'modules/Api/Routes';

let cancelToken;

const initialState = {
  loading: false,
  error: null,
  data: {
    page: 1,
    perPage: 10,
    search: '',
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
};

const liveInterviewSlice = createSlice({
  name: 'liveInterview',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    requestLiveInterview: (state) => {
      state.loading = true;
      state.error = null;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    receiveLiveInterviewList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        liveInterview: action.payload,
        total: action.payload?.length,
      };
    },
    clearLiveInterviewList: (state) => {
      state.data = {
        page: 1,
        perPage: 10,
        search: '',
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
    },
    receiveLiveInterviewError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changeLiveInterviewPage: (state, action) => {
      state.data.page = action.payload;
    },
    changeLiveInterviewFilters: (state, action) => {
      state.data.search = action.payload.search;
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
    },
    clearLiveInterviewFilters: (state) => {
      state.data.search = '';
      state.data.page = 1;
      state.data.perPage = 10;
      state.data.sort.sortType = null;
      state.data.sort.sortBy = null;
    },
  },
});

const Actions = liveInterviewSlice.actions;

const Selectors = {
  fetchListData: (state) => state.liveInterview,
  liveInterviewLoading: ({ liveInterview: { loading } }) => ({ loading }),
};

const Async = {
  fetchLiveInterviewList: () => async (dispatch, getState) => {
    const {
      liveInterview: {
        data: {
          page,
          perPage,
          search,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestLiveInterview());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: LIVE_INTERVIEWS_LIST_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receiveLiveInterviewList(response?.data?.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveLiveInterviewError(e.message);
      }
    }

    action && dispatch(action);
  },

  startSession:
    ({ live_code, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestLiveInterview());
      try {
        const response = await request({
          method: 'POST',
          url: LIVE_INTERVIEWS_START_LIVE_TEST_URL,
          data: {
            live_code,
          },
        });

        onSuccess(response.data);
      } catch (e) {
        onError();
      }

      dispatch(Actions.setLoading(false));
    },

  endSession:
    ({ live_code, onSuccess, onError }) =>
    async () => {
      try {
        await request({
          method: 'DELETE',
          url: LIVE_INTERVIEWS_END_LIVE_TEST_URL,
          params: {
            live_code,
          },
        });

        onSuccess();
      } catch (e) {
        onError();
      }
    },

  fetchInstructions:
    ({ live_code, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.setLoading(true));

      try {
        const response = await request({
          method: 'GET',
          url: LIVE_INTERVIEWS_INSTRUCTIONS_URL,
          params: {
            live_code,
          },
        });

        onSuccess(response?.data?.content?.instuctions);
      } catch (e) {
        onError(e.message);
      }

      dispatch(Actions.setLoading(false));
    },
};

const { reducer } = liveInterviewSlice;

export { reducer, Actions, Async, Selectors };
