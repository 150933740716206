import React from 'react';
import PropTypes from 'prop-types';
import * as S from './Switch.styles';

const Switch = ({ active, onChange, dataCy, ...props }) => (
  <S.Label data-cy={dataCy}>
    <input type="checkbox" checked={active} onChange={onChange} {...props} />

    <S.Slider id="slider" checked={active} />
    <S.SliderBall checked={active} />
  </S.Label>
);

Switch.propTypes = {
  active: PropTypes.bool,
  onChange: PropTypes.func,
  dataCy: PropTypes.string,
};

export default Switch;
