import styled, { css } from 'styled-components';
import { device } from 'modules/Utils';

export const Content = styled.div`
  margin-top: 1.6rem;
  height: ${(props) => (props.size === 'small' ? '21.5rem' : '28rem')};

  ${(props) =>
    props.borderTop &&
    css`
      margin-top: ${props.size === 'small' ? '0.8rem' : '1.6rem'};
      padding-top: 1.6rem;
      border-top: 1px solid ${props.theme.config.colors.gray_200};

      @media ${device.mobile} {
        margin-top: 0;
      }
    `};

  @media ${device.tablet} {
    margin-top: 1.6rem;
  }

  @media ${device.mobile} {
    height: 21.5rem;
  }
`;
