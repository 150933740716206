export const getExerciseStatus = (status, scheduleStatus) => {
  switch (scheduleStatus || status) {
    case 0:
      return 'Pending';
    case 1:
    case 'DONE':
      return 'Completed';
    case 'SCHEDULED':
      return 'Scheduled';
    case 'CANCELED':
      return 'Canceled';
    case 'MISSED':
      return 'Missed';
    default:
      return 'N/A';
  }
};
