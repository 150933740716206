import React from 'react';
import PropTypes from 'prop-types';
import { Icon, MUIDateTimePicker } from 'modules/Core/Common';
import * as S from '../ListIntervals.styles';
import { getMinAndMaxBasedOnLastInterval } from 'modules/Users/Evaluators/EvaluatorsUtils';
import { addMinutesUTC } from 'modules/Utils/Date';
import i18n from 'i18next';

const EditInterval = ({
  interval,
  setFieldValue,
  handleCloseEdit,
  handleDeleteInterval,
  index,
}) => {
  const { minDate } = getMinAndMaxBasedOnLastInterval();
  return (
    <S.Tr>
      <S.Td isEdit width="45%">
        <MUIDateTimePicker
          name={`intervals.${index}.start_date`}
          date={interval?.start_date}
          minutesStep={30}
          onChange={(date) => {
            setFieldValue(`intervals.${index}.start_date`, date);
            setFieldValue(
              `intervals.${index}.end_date`,
              addMinutesUTC(date, 30)
            );
          }}
          minDate={minDate}
          inputProps={{ readOnly: true }}
          textFieldProps={{
            placeholder: i18n.t(
              'modules.settings-profile.forms.intervals.columns.start-date'
            ),
          }}
        />
      </S.Td>

      <S.Td isEdit width="45%">
        <MUIDateTimePicker
          name={`intervals.${index}.end_date`}
          date={interval?.end_date}
          minutesStep={30}
          onChange={(date) => {
            setFieldValue(`intervals.${index}.end_date`, date);
            setFieldValue(
              `intervals.${index}.start_date`,
              addMinutesUTC(date, -30)
            );
          }}
          minDate={interval?.start_date}
          inputProps={{ readOnly: true }}
          textFieldProps={{
            placeholder: i18n.t(
              'modules.settings-profile.forms.intervals.columns.end-date'
            ),
          }}
        />
      </S.Td>
      <S.Td isEdit width="10%">
        <S.ActionsContainer isEdit>
          <S.ActionButton onClick={() => handleCloseEdit()}>
            <Icon size="small" className="close-icon" name="close" />
          </S.ActionButton>
          <S.ActionButton onClick={handleDeleteInterval}>
            <Icon name="delete" />
          </S.ActionButton>
        </S.ActionsContainer>
      </S.Td>
    </S.Tr>
  );
};

EditInterval.propTypes = {
  interval: PropTypes.object,
  setFieldValue: PropTypes.func,
  handleCloseEdit: PropTypes.func,
  handleDeleteInterval: PropTypes.func,
  index: PropTypes.number,
  user: PropTypes.object,
};

export default React.memo(EditInterval);
