import styled from 'styled-components';

export const Container = styled.div`
  position: sticky;
  z-index: 9;
  left: 0;
  right: 0;
  top: 0;
  position: sticky;
  background-color: ${(props) => props.theme.config.colors.orange_100};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 8px 24px;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1216px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Message = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 8px;
  }

  span {
    font-size: 12px;
    color: ${(props) => props.theme.config.colors.gray_600};
  }
`;

export const Close = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 12px;

    path {
      fill: ${(props) => props.theme.config.colors.gray_500};
    }
  }
`;
