import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

export const RCTooltip = styled(ReactTooltip)`
  &.edusynch-tooltip {
    color: ${({ fontColor }) => fontColor};
    background-color: ${({ backgroundColor }) => backgroundColor};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 1.4rem;

    /* Prevent background color leak outs */
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
  }
`;
