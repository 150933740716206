import defaultsdeep from 'lodash.defaultsdeep';

export const whitelabelConfig = async (name = 'edusynch') => {
  const map = {
    edusynch: () => import('./edusynch' /* webpackChunkName: "edusynch" */),
    berlitz: () => import('./berlitz' /* webpackChunkName: "berlitz" */),
  };

  const selectedWhitelabel = map[name];
  if (!selectedWhitelabel) {
    throw new Error(`Invalid '${name}' whitelabel`);
  }

  const { factory } = await selectedWhitelabel();
  if (name === 'edusynch') {
    return factory();
  }

  const selectedWhitelabelProps = await factory();
  const { factory: defaultWhitelabelFactort } = await map.edusynch();
  const defaults = defaultWhitelabelFactort({
    colors: selectedWhitelabelProps.colors,
  });

  return defaultsdeep(selectedWhitelabelProps, defaults);
};
