import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const TooltipItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.2rem;
  border-radius: 0.4rem;
  outline: none;
  margin: 0 auto;
  padding: 0.8rem 1.6rem;
  background: ${(props) => props.theme.config.colors.primary_200};
  border: none;
  color: ${(props) => props.theme.config.colors.primary};
  white-space: nowrap;
  font-weight: bold;
  width: fit-content;

  ${({ isMultipleTests }) =>
    isMultipleTests &&
    css`
      max-width: 8.6rem;
    `}
`;

export const Text = styled.div`
  ${(props) => props.theme.config.typograph.xsmall};
  white-space: nowrap;

  p::selection {
    background: ${(props) => props.theme.config.colors.gray_400};
  }
`;
