import styled, { css } from 'styled-components';
import { device } from 'modules/Utils';

export const Header = styled.div`
  display: flex;

  h6 {
    ${(props) => props.theme.config.typograph.xsmall};
    color: ${(props) => props.theme.config.colors.text_base};

    span {
      color: ${(props) => props.theme.config.colors.gray};
    }
  }

  p {
    ${(props) => props.theme.config.typograph.default};
    font-weight: bold;
    color: ${(props) => props.theme.config.colors.primary};
  }

  @media ${device.desktop} {
    ${({ size }) =>
      size === 'small'
        ? css`
            margin-bottom: 2.4rem;
          `
        : ''};
  }

  @media ${device.mobile} {
    margin-bottom: 2.4rem;
  }
`;

export const Icon = styled.div`
  margin-right: 0.8rem;
`;

export const Content = styled.div`
  margin-top: 2.4rem;
  height: ${(props) => (props.size === 'small' ? '21.5rem' : '28rem')};
`;

export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${device.tablet} {
    h6,
    button {
      margin-top: 1.6rem;
      margin-bottom: 0;
    }
  }
`;
