import React from 'react';

import { WidgetByType } from 'modules/Dashboard/Widgets/Common';
import { NavigationCards } from 'modules/Dashboard/NavigationCards';
import { WelcomeMessage } from 'modules/Dashboard/WelcomeMessage';
import { DashboardWidgetsPlaceholder } from './DashboardWidgets.placeholder';
import { useDashboardWidgets } from 'modules/Dashboard/Hooks';

import * as S from './DashboardWidgets.styles';

export const DashboardWidgets = () => {
  const { state, userWidgets } = useDashboardWidgets();

  if (state.loading) return <DashboardWidgetsPlaceholder />;

  if (userWidgets?.widgets?.length > 0) {
    return (
      <S.Container>
        {userWidgets.widgets.map((widget) => (
          <S.WidgetContainer
            key={widget.id}
            widgetSize={widget?.settings?.size}
            style={{ cursor: 'auto' }}
          >
            <WidgetByType widget={widget} />
          </S.WidgetContainer>
        ))}
      </S.Container>
    );
  }

  return (
    <>
      <WelcomeMessage />
      <NavigationCards />
    </>
  );
};
