import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { IS_MAINTENANCE } from 'modules/Maintenance/Utils/Maintenance.utils';
import Maintenance from './Maintenance.component';

const MaintenanceContainer = () => {
  const history = useHistory();

  useEffect(() => {
    if (!IS_MAINTENANCE) {
      history.push('/auth/login');
    }
  }, []);

  return <Maintenance />;
};

export default MaintenanceContainer;
