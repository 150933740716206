import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  svg {
    width: 1.6rem;
    height: 1.6rem;
    opacity: 0.5;

    path {
      fill: ${(props) => props.theme.config.colors.text_base};
    }

    &:first-child {
      position: absolute;
      left: 1.6rem;
    }

    &:last-child {
      position: absolute;
      right: 1.6rem;
      pointer-events: none;
    }
  }
`;

export const DatePicker = styled.input`
  outline: none;
  border: 1px solid ${(props) => props.theme.config.colors.gray_100};
  width: 100%;
  height: 4.8rem;
  border-radius: 5.4rem;
  padding-left: 4rem;
  ${(props) => props.theme.config.typograph.default};
  color: ${(props) => props.theme.config.colors.text_base};

  &::-webkit-calendar-picker-indicator {
    right: -10px;
    position: absolute;
    width: 100%;
    height: 100%;
    color: ${(props) => props.theme.config.colors.gray_100};
    opacity: 0;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${(props) => props.theme.config.colors.text_base};
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${(props) => props.theme.config.colors.text_base};
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${(props) => props.theme.config.colors.text_base};
  }

  ${({ secondary }) =>
    secondary &&
    css`
      border-radius: 0.6rem;
      border: 1px solid ${(props) => props.theme.config.colors.gray_300};
    `};
`;
