import { useState } from 'react';

export const useConfirmDialog = ({ handleConfirm }) => {
  const [isConfirmDialogModalOpen, setIsConfirmDialogModalOpen] =
    useState(false);

  const handleConfirmDialog = () => {
    handleConfirm();
    setIsConfirmDialogModalOpen(false);
  };

  return {
    isConfirmDialogModalOpen,
    setIsConfirmDialogModalOpen,
    handleConfirmDialog,
  };
};
