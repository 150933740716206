import styled, { css } from 'styled-components';
import { Title } from 'modules/Core/Common';

export const TestsListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 35px;
`;

export const TestsListHeaderTitle = styled(Title)`
  margin: 16px 0;
`;

export const TestsListHeaderActions = styled.div`
  display: flex;

  h3 {
    & + h3 {
      margin-left: ${(props) => props.theme.config.spacing.spacing_5};
    }
  }
`;

export const TestsList = styled.ul`
  list-style: none;
`;

export const TestsListItem = styled.li`
  ${(props) => props.theme.config.typograph.xsmall};
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.config.colors.light};
  padding: 16px 24px;
  color: ${(props) => props.theme.config.colors.text_base};
  border-radius: 6px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);

  & + li {
    margin-top: ${(props) => props.theme.config.spacing.spacing_1};
  }
`;

export const TestsListItemActions = styled.div`
  display: flex;
  ${({ hasHideSwitch }) =>
    hasHideSwitch &&
    css`
      flex-basis: 12.4rem;
    `}

  label {
    & + label {
      margin-left: ${(props) => props.theme.config.spacing.spacing_4};
    }
  }
`;

export const SkeletonContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${(props) => (props.marginTop ? '32px' : 0)};

  div {
    &:not(:last-child) {
      padding-bottom: 1rem;
      width: 10rem;
    }

    &:last-child {
      span.skeleton-element {
        height: 4.8rem;
        border-radius: 0.6rem;
      }
    }
  }
`;

export const SkeletonContainerRow = styled.div`
  padding-bottom: 2.4rem;

  div {
    &:not(:last-child) {
      padding-bottom: 1rem;
      width: 10rem;
    }

    &:last-child {
      span.skeleton-element {
        height: 4.8rem;
        border-radius: 0.6rem;
      }
    }
  }
`;
