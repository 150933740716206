import i18next from 'i18next';

const TOKEN_KEY = 'edusynch_token';
const USER_KEY = 'edusynch_user';

const persistSession = (user, token) => {
  localStorage.setItem(USER_KEY, JSON.stringify(user));
  localStorage.setItem(TOKEN_KEY, token);

  i18next.changeLanguage(user?.language);
};

const cleanSession = () => {
  localStorage.removeItem(USER_KEY);
  localStorage.removeItem(TOKEN_KEY);
};

const persistUser = (user) => {
  localStorage.setItem(USER_KEY, JSON.stringify(user));
};

const getSession = () => {
  const token = getToken();

  if (!token) {
    // ensure user data will be removed
    cleanSession();

    return null;
  }

  return {
    token,
    user: getUser(),
  };
};

const getToken = () => _readStorage(TOKEN_KEY);

const getUser = () => _readStorage(USER_KEY, true);

const _readStorage = (key, inObj) => {
  try {
    const data = localStorage.getItem(key);
    if (!data) {
      return null;
    }
    return inObj ? JSON.parse(data) : data;
  } catch (e) {
    return null;
  }
};

export {
  persistSession,
  persistUser,
  getSession,
  getToken,
  getUser,
  cleanSession,
};
