import { createSlice } from '@reduxjs/toolkit';
import { request } from 'modules/Api/HttpClient';
import {
  USER_API_URL,
  USER_API_BY_ID_URL,
  USER_API_CREATE_URL,
  USER_API_UPDATE_URL,
  USER_API_DELETE_URL,
  VERSIONS_CURRENT_URL,
} from 'modules/Api/Routes';
import { formDataFromObj } from 'modules/Api/RequestData';
import { createAPIUserRequestModel } from 'modules/Softwares/APIUsers/APIUserUtils';
import {
  getUTCDefaultStartDate,
  getUTCDefaultEndDate,
  getISOStringFromStringDate,
} from 'modules/Utils/Date';

const initialState = {
  loading: false,
  error: null,
  data: {
    apiUsers: [],
    page: 1,
    perPage: 10,
    startDate: getUTCDefaultStartDate(),
    endDate: getUTCDefaultEndDate(),
    softwareId: '',
    search: '',
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
  selectedAPIUser: {
    data: {},
    loading: false,
    error: null,
  },
  selectedExternalApplication: {
    data: {},
    loading: false,
    error: null,
  },
  isDeleting: false,
};

const apiUsersSlice = createSlice({
  name: 'apiUsers',
  initialState,
  reducers: {
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    requestAPIUser: (state) => {
      state.loading = true;
      state.error = null;
    },
    requestSelectedAPIUser: (state, action) => {
      state.selectedAPIUser.loading = true;
      state.selectedAPIUser.error = null;
      state.selectedAPIUser.data = {
        id: action?.payload || null,
      };
    },
    requestSelectedExternalApplication: (state, action) => {
      state.selectedExternalApplication.loading = true;
      state.selectedExternalApplication.error = null;
      state.selectedExternalApplication.data = action?.payload;
    },
    changePage: (state, action) => {
      state.data.page = action.payload;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsDeleting: (state, action) => {
      state.isDeleting = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    /**
     * receive a success group list response
     */
    receiveAPIUserList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        apiUsers: action.payload.user_apis,
        total: action.payload.total_items,
      };
    },
    receiveSelectedAPIUser: (state, action) => {
      state.selectedAPIUser = {
        ...state.selectedAPIUser,
        data: action.payload,
        loading: false,
        error: null,
      };
    },
    receiveSelectedExternalApplication: (state, action) => {
      state.selectedExternalApplication = {
        ...state.selectedExternalApplication,
        data: {
          ...state.selectedExternalApplication.data,
          version: action.payload,
        },
        loading: false,
        error: null,
      };
    },
    clearAPIUsersList: (state) => {
      state.loading = false;
      state.data = {
        page: 1,
        perPage: 10,
        apiUsers: [],
        softwareId: '',
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
    },
    clearSelectedAPIUser: (state) => {
      state.selectedAPIUser = {
        data: {},
        loading: false,
        error: null,
      };
    },
    clearSelectedExternalApplication: (state) => {
      state.selectedExternalApplication = {
        data: {},
        loading: false,
        error: null,
      };
    },
    /**
     * receive an error response
     */
    receiveAPIUserError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    receiveSelectedAPIUserError: (state, action) => {
      state.selectedAPIUser.loading = false;
      state.selectedAPIUser.error = action.payload;
    },
    receiveSelectedExternalApplicationError: (state, action) => {
      state.selectedExternalApplication.loading = false;
      state.selectedExternalApplication.error = action.payload;
    },
    changeAPIUsersFilters: (state, action) => {
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
      state.data.startDate =
        action?.payload.start_date || getUTCDefaultStartDate();
      state.data.endDate = action?.payload.end_date || getUTCDefaultEndDate();
      state.data.softwareId = action?.payload.software_id || '';
      state.data.search = action?.payload.search || '';
    },
    updateAPIUserOnList: (state, action) => {
      const updAPIUser = action.payload;
      const index = state.data.apiUsers.findIndex(
        (apiUser) => apiUser.id === updAPIUser.id
      );
      if (index >= 0) state.data.apiUsers.splice(index, 1, updAPIUser);
    },
  },
});

const Actions = apiUsersSlice.actions;

const Selectors = {
  fetchListData: (state) => state.apiUsers,
  apiUserLoading: ({ apiUsers: { loading } }) => ({
    loading,
  }),
};

const Async = {
  fetchAPIUsersList: () => async (dispatch, getState) => {
    const {
      apiUsers: {
        data: {
          page,
          perPage,
          startDate,
          endDate,
          softwareId,
          search,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    let action;

    dispatch(Actions.requestAPIUser());

    try {
      const response = await request({
        method: 'GET',
        url: USER_API_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          start_date: getISOStringFromStringDate(startDate),
          end_date: getISOStringFromStringDate(endDate),
          external_software_id: ['', 'all', null].includes(softwareId)
            ? undefined
            : softwareId,
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receiveAPIUserList(response.data.content);
    } catch (e) {
      action = Actions.receiveAPIUserError(e.message);
    }

    dispatch(action);
  },

  getAPIUserById:
    ({ id }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestSelectedAPIUser(id));

      try {
        const response = await request({
          method: 'GET',
          url: `${USER_API_BY_ID_URL}?id=${id}`,
        });

        action = Actions.receiveSelectedAPIUser(response.data.content);
      } catch (e) {
        action = Actions.receiveSelectedAPIUserError(e.message);
      }

      dispatch(action);
    },

  getVersionByExternalApplication:
    ({ data }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestSelectedExternalApplication(data));

      try {
        const response = await request({
          method: 'GET',
          url: `${VERSIONS_CURRENT_URL}?external_software_id=${data?.external_software?.id}`,
        });

        action = Actions.receiveSelectedExternalApplication(
          response?.data?.content
        );
      } catch (e) {
        action = Actions.receiveSelectedExternalApplicationError(e?.message);
      }

      dispatch(action);
    },

  createAPIUser:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestAPIUser());
      try {
        const parsedAPIUser = createAPIUserRequestModel(data);
        delete parsedAPIUser.id;

        const apiUserData = formDataFromObj(parsedAPIUser);

        const response = await request({
          method: 'POST',
          url: USER_API_CREATE_URL,
          data: apiUserData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveAPIUserError());
        onError(e);
      }
    },

  updateAPIUser:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestAPIUser());
      try {
        const parsedAPIUser = createAPIUserRequestModel(data);

        const apiUserData = formDataFromObj(parsedAPIUser);

        const response = await request({
          method: 'PUT',
          url: USER_API_UPDATE_URL,
          data: apiUserData,
        });

        const updatedAPIUser = response.data.content;
        dispatch(Actions.updateAPIUserOnList(updatedAPIUser));
        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveAPIUserError());
        onError(e);
      }
    },

  deleteAPIUser:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.setIsDeleting(true));

      try {
        const response = await request({
          method: 'DELETE',
          url: `${USER_API_DELETE_URL}?id=${id}`,
        });

        onSuccess(response);
        dispatch(Actions.setIsDeleting(false));
      } catch (e) {
        onError(e);
        dispatch(Actions.setIsDeleting(false));
      }
    },
};

const reducer = apiUsersSlice.reducer;

export { reducer, Actions, Async, Selectors };
