import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 16px;
    opacity: 0.5;

    path {
      fill: ${(props) => props.theme.config.colors.text_base};
    }
  }
`;

const Span = styled.span`
  flex: 1;
`;

export { Container, Span };
