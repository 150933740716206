import React from 'react';
import * as S from './LicenseAmount.styles';

export const LicenseAmountSkeleton = () => (
  <S.SkeletonWrapper>
    <S.SkeletonElement type="title" style={{ marginBottom: '2rem' }} />
    <S.SkeletonRow>
      <div>
        <S.SkeletonElement type="subtitle" stye={{ marginBottom: '1.6rem' }} />
        <S.SkeletonElement type="data" />
      </div>
      <div>
        <S.SkeletonElement type="subtitle" stye={{ marginBottom: '1.6rem' }} />
        <S.SkeletonElement type="data" />
      </div>
      <div>
        <S.SkeletonElement type="subtitle" stye={{ marginBottom: '1.6rem' }} />
        <S.SkeletonElement type="data" />
      </div>
      <div>
        <S.SkeletonElement type="subtitle" stye={{ marginBottom: '1.6rem' }} />
        <S.SkeletonElement type="data" />
      </div>
      <div>
        <S.SkeletonElement type="subtitle" stye={{ marginBottom: '1.6rem' }} />
        <S.SkeletonElement type="data" />
      </div>
    </S.SkeletonRow>

    <S.SkeletonElement type="title" style={{ margin: '4rem 0 2rem 0' }} />
    <S.SkeletonRow>
      <div>
        <S.SkeletonElement type="subtitle" stye={{ marginBottom: '1.6rem' }} />
        <S.SkeletonElement type="data" />
      </div>
      <div>
        <S.SkeletonElement type="subtitle" stye={{ marginBottom: '1.6rem' }} />
        <S.SkeletonElement type="data" />
      </div>
      <div>
        <S.SkeletonElement type="subtitle" stye={{ marginBottom: '1.6rem' }} />
        <S.SkeletonElement type="data" />
      </div>
      <div>
        <S.SkeletonElement type="subtitle" stye={{ marginBottom: '1.6rem' }} />
        <S.SkeletonElement type="data" />
      </div>
      <div>
        <S.SkeletonElement type="subtitle" stye={{ marginBottom: '1.6rem' }} />
        <S.SkeletonElement type="data" />
      </div>
    </S.SkeletonRow>
  </S.SkeletonWrapper>
);
