import { createSlice } from '@reduxjs/toolkit';
import { defaultRequest } from 'modules/Utils/DefaultRequest';
import { TIMEZONES_URL } from 'modules/Api/Routes';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const timezonesSlice = createSlice({
  name: 'timezones',
  initialState,
  reducers: {
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    requestTimezones: (state) => {
      state.loading = true;
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.lading = false;
    },
    /**
     * receive a success timezones list response
     */
    receiveTimezonesList: (state, action) => {
      state.loading = false;
      state.data = action?.payload?.content;
    },
    clearTimezonesList: (state) => {
      state.loading = false;
      state.data = [];
    },
    /**
     * receive an error response
     */
    receiveTimezonesError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

const Actions = timezonesSlice.actions;

const Selectors = {
  fetchListData: (state) => state.timezones,
  timezoneLoading: ({ timezones: { loading } }) => ({ loading }),
};

const Async = {
  fetchTimezonesList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      TIMEZONES_URL,
      'GET',
      Actions.requestTimezones,
      Actions.receiveTimezonesList,
      Actions.receiveTimezonesError
    ),
};

const { reducer } = timezonesSlice;

export { reducer, Actions, Async, Selectors };
