import { createSlice } from '@reduxjs/toolkit';
import { request } from 'modules/Api/HttpClient';
import {
  COUNTRY_PERMISSIONS_COUNTRIES_URL,
  PROCTORING_SETTINGS_BY_WHITELABEL_OR_EXTERNAL_APP_ID_URL,
  PROCTORING_SETTINGS_CREATE_URL,
  PROCTORING_SETTINGS_UPDATE_URL,
  PROCTORING_SETTINGS_BY_ID_URL,
  ALLOWED_URLS_BY_ID_URL,
  ALLOWED_URLS_CREATE_URL,
  ALLOWED_URLS_UPDATE_URL,
  ALLOWED_URLS_DELETE_URL,
  WHITELABELS_URL,
  EXTERNAL_APPLICATIONS_URL,
} from 'modules/Api/Routes';
import { defaultRequest } from 'modules/Utils/DefaultRequest';

const ENABLE_SIMULATION = 'enable_simulation';
const ENABLE_PREFIX = 'enable_';
const DISABLE_PREFIX = 'disable_';
const FORCE_PREFIX = 'force_';
const UNUSED_FLAGS = ['enable_leaving_screen_check'];

const checkIfStringHasPrefix = (string) =>
  string.startsWith(ENABLE_PREFIX) ||
  string.startsWith(DISABLE_PREFIX) ||
  string.startsWith(FORCE_PREFIX);

const initialState = {
  loading: false,
  loadingWhitelistUrl: false,
  error: null,
  countries: {
    loading: false,
    data: [],
  },
  origin: {
    selected: {
      id: null,
      type: null,
    },
    whitelabels: {
      loading: false,
      data: [],
    },
    external_applications: {
      loading: false,
      data: [],
    },
  },
  data: {
    allowed_urls: [],
    enable_simulation: false,
    hide_simulation: false,
    enable_photo_of_user: false,
    hide_photo_of_user: false,
    enable_photo_of_id: false,
    hide_photo_of_id: false,
    enable_room_scan: false,
    hide_room_scan: false,
    enable_photo_of_user_during_test: false,
    hide_photo_of_user_during_test: false,
    enable_photo_of_screen_during_test: false,
    hide_photo_of_screen_during_test: false,
    enable_user_video_recording: false,
    hide_user_video_recording: false,
    enable_user_screen_recording: false,
    hide_user_screen_recording: false,
    disable_screenshot: false,
    hide_screenshot: false,
    enable_basic_calculator: false,
    hide_basic_calculator: false,
    enable_scientific_calculator: false,
    hide_scientific_calculator: false,
    disable_copy_paste: false,
    hide_copy_paste: false,
    enable_random_room_scan: false,
    hide_random_room_scan: false,
    enable_multiple_monitors_check: false,
    hide_multiple_monitors_check: false,
    enable_multiple_people_check: false,
    hide_multiple_people_check: false,
    enable_ai_detector: false,
    hide_ai_detector: false,
    enable_mobile_device_detector: false,
    hide_mobile_device_detector: false,
    enable_submissions: false,
    force_incognito_mode: false,
    enable_lockdown_browser: false,
    high_move_focus: 0,
    high_closed_window: 0,
    high_offline: 0,
    high_online: 0,
    high_online_server: 0,
    high_offline_server: 0,
    high_simulation_timeout: 0,
    high_new_tab_window: 0,
    high_multiple_displays_detected: 0,
    high_more_than_one_cam: 0,
    high_multiple_faces_detected: 0,
    high_no_face_detected: 0,
    high_test_paused: 0,
    high_test_resumed: 0,
    high_start_streaming: 0,
    high_stop_streaming: 0,
    high_simulation_expired: 0,
    high_closed_window_or_tab: 0,
    high_roomscan_failed_more_than_twice_and_resumed: 0,
    high_roomscan_locked: 0,
    high_suspended_camera: 0,
    high_gaze_detection: 0,
    medium_gaze_detection: 0,
    medium_move_focus: 0,
    medium_closed_window: 0,
    medium_offline: 0,
    medium_online: 0,
    medium_online_server: 0,
    medium_offline_server: 0,
    medium_simulation_timeout: 0,
    medium_new_tab_window: 0,
    medium_multiple_displays_detected: 0,
    medium_more_than_one_cam: 0,
    medium_multiple_faces_detected: 0,
    medium_no_face_detected: 0,
    medium_test_paused: 0,
    medium_test_resumed: 0,
    medium_start_streaming: 0,
    medium_stop_streaming: 0,
    medium_simulation_expired: 0,
    medium_closed_window_or_tab: 0,
    medium_roomscan_failed_more_than_twice_and_resumed: 0,
    medium_roomscan_locked: 0,
    medium_suspended_camera: 0,
    name: '',
    id: null,
    type: '',
    proctoring_setting_countries_attributes: [],
  },
  origin_countries: [],
};

const proctoringSettingsEditSlice = createSlice({
  name: 'proctoringSettingsEdit',
  initialState,
  reducers: {
    cleanState: (state) => {
      state.loading = false;
      state.data = initialState.data;
      state.origin.selected = initialState.origin.selected;
    },
    changeParam: (state, action) => {
      state.data[action.payload.param] = action.payload.value;

      if (action.payload.param === ENABLE_SIMULATION) {
        Object.keys(state.data).forEach((key) => {
          if (
            key !== ENABLE_SIMULATION &&
            checkIfStringHasPrefix(key) &&
            state.data[key]
          ) {
            state.data[key] = false;
          }
        });
      } else if (checkIfStringHasPrefix(action.payload.param)) {
        state.data[ENABLE_SIMULATION] = false;
      }
    },
    changeOrigin: (state, action) => {
      state.origin.selected.id = action.payload.id;
      state.origin.selected.type = action.payload.type;
    },
    addWhitelistUrl: (state, action) => {
      state.data.allowed_urls = [...state.data.allowed_urls, action.payload];
    },
    changeWhitelistUrl: (state, action) => {
      const providedIndex = action.payload?.index;
      const providedUrl = action.payload?.url;
      state.data.allowed_urls = state.data.allowed_urls?.map((item, index) => {
        if (index !== providedIndex) return item;
        return {
          ...item,
          url: providedUrl,
        };
      });
    },
    removeWhitelistUrl: (state, action) => {
      state.data.allowed_urls = state.data.allowed_urls?.map((item) => {
        if (action.payload !== item?.url) return item;
        return {
          ...item,
          delete: true,
        };
      });
    },
    clearWhitelistUrl: (state) => {
      state.data.allowed_urls = [];
    },
    destroyWhitelistUrl: (state, action) => {
      state.data.allowed_urls = state.data.allowed_urls?.filter(
        (item) => action.payload !== item?.id
      );
    },
    changeCountries: (state, action) => {
      state.data.proctoring_setting_countries_attributes = action.payload;
    },
    requestExternalApps: (state) => {
      state.origin.external_applications.loading = true;
    },
    receiveExternalAppsList: (state, action) => {
      state.origin.external_applications.loading = false;
      state.origin.external_applications.data = action.payload.content;
    },
    receiveExternalAppsError: (state, action) => {
      state.origin.external_applications.loading = false;
      state.error = action.payload;
    },
    requestWhitelabels: (state) => {
      state.origin.whitelabels.loading = true;
    },
    receiveWhitelabelsList: (state, action) => {
      state.origin.whitelabels.loading = false;
      state.origin.whitelabels.data = action.payload.content.whitelabels;
    },
    receiveWhitelabelsError: (state, action) => {
      state.origin.whitelabels.loading = false;
      state.error = action.payload;
    },
    requestCountries: (state) => {
      state.countries.loading = true;
    },
    receiveCountriesList: (state, action) => {
      state.countries.loading = false;

      const countries = action.payload.content?.map((item) => ({
        id: item,
        name: item,
      }));

      state.countries.data = countries;
    },
    receiveCountriesError: (state, action) => {
      state.countries.loading = false;
      state.error = action.payload;
    },
    requestProctoringSetting: (state) => {
      state.loading = true;
    },
    receiveProctoringSetting: (state, action) => {
      state.loading = false;

      const formatEnableSimulation = (flags) => {
        const hasAnotherEnabledFlag = Object.keys(flags).filter((key) => {
          return (
            key !== ENABLE_SIMULATION &&
            !UNUSED_FLAGS.includes(key) &&
            checkIfStringHasPrefix(key) &&
            action.payload[key]
          );
        });

        if (hasAnotherEnabledFlag?.length) return false;
        return action.payload?.enable_simulation;
      };

      if (action.payload?.id) {
        state.origin_countries =
          action.payload?.proctoring_setting_countries_attributes ||
          action.payload?.proctoring_setting_countries ||
          [];
        state.data = {
          id: action.payload?.id,
          name: action.payload?.name,
          has_exams: action.payload?.has_exams,
          allowed_urls: action.payload?.allowed_urls || [],
          enable_simulation: formatEnableSimulation(action.payload),
          hide_simulation: action.payload?.hide_simulation,
          enable_photo_of_user: action.payload?.enable_photo_of_user,
          hide_photo_of_user: action.payload?.hide_photo_of_user,
          enable_photo_of_id: action.payload?.enable_photo_of_id,
          hide_photo_of_id: action.payload?.hide_photo_of_id,
          enable_room_scan: action.payload?.enable_room_scan,
          hide_room_scan: action.payload?.hide_room_scan,
          enable_photo_of_user_during_test:
            action.payload?.enable_photo_of_user_during_test,
          hide_photo_of_user_during_test:
            action.payload?.hide_photo_of_user_during_test,
          enable_photo_of_screen_during_test:
            action.payload?.enable_photo_of_screen_during_test,
          hide_photo_of_screen_during_test:
            action.payload?.hide_photo_of_screen_during_test,
          enable_user_audio_recording:
            action.payload?.enable_user_audio_recording,
          hide_user_audio_recording: action.payload?.hide_user_audio_recording,
          enable_user_video_recording:
            action.payload?.enable_user_video_recording,
          hide_user_video_recording: action.payload?.hide_user_video_recording,
          enable_user_screen_recording:
            action.payload?.enable_user_screen_recording,
          hide_user_screen_recording:
            action.payload?.hide_user_screen_recording,
          disable_screenshot: action.payload?.disable_screenshot,
          hide_screenshot: action.payload?.hide_screenshot,
          enable_basic_calculator: action.payload?.enable_basic_calculator,
          hide_basic_calculator: action.payload?.hide_basic_calculator,
          enable_scientific_calculator:
            action.payload?.enable_scientific_calculator,
          hide_scientific_calculator:
            action.payload?.hide_scientific_calculator,
          disable_copy_paste: action.payload?.disable_copy_paste,
          hide_copy_paste: action.payload?.hide_copy_paste,
          enable_random_room_scan: action.payload?.enable_random_room_scan,
          hide_random_room_scan: action.payload?.hide_random_room_scan,
          enable_multiple_monitors_check:
            action.payload?.enable_multiple_monitors_check,
          hide_multiple_monitors_check:
            action.payload?.hide_multiple_monitors_check,
          enable_multiple_people_check:
            action.payload?.enable_multiple_people_check,
          hide_multiple_people_check:
            action.payload?.hide_multiple_people_check,
          enable_ai_detector: action.payload?.enable_ai_detector,
          hide_ai_detector: action.payload?.hide_ai_detector,
          enable_mobile_device_detector:
            action.payload?.enable_mobile_device_detector,
          hide_mobile_device_detector:
            action.payload?.hide_mobile_device_detector,
          enable_submissions: action.payload?.enable_submissions,
          force_incognito_mode: action.payload?.force_incognito_mode,
          enable_lockdown_browser: action.payload?.enable_lockdown_browser,
          high_move_focus: action.payload?.high_move_focus,
          high_closed_window: action.payload?.high_closed_window,
          high_offline: action.payload?.high_offline,
          high_online: action.payload?.high_online,
          high_online_server: action.payload?.high_online_server,
          high_offline_server: action.payload?.high_offline_server,
          high_simulation_timeout: action.payload?.high_simulation_timeout,
          high_new_tab_window: action.payload?.high_new_tab_window,
          high_multiple_displays_detected:
            action.payload?.high_multiple_displays_detected,
          high_more_than_one_cam: action.payload?.high_more_than_one_cam,
          high_multiple_faces_detected:
            action.payload?.high_multiple_faces_detected,
          high_no_face_detected: action.payload?.high_no_face_detected,
          high_new_tab: action.payload?.high_new_tab,
          high_test_paused: action.payload?.high_test_paused,
          high_test_resumed: action.payload?.high_test_resumed,
          high_start_streaming: action.payload?.high_start_streaming,
          high_stop_streaming: action.payload?.high_stop_streaming,
          high_simulation_expired: action.payload?.high_simulation_expired,
          high_closed_window_or_tab: action.payload?.high_closed_window_or_tab,
          high_roomscan_failed_more_than_twice_and_resumed:
            action.payload?.high_roomscan_failed_more_than_twice_and_resumed,
          high_roomscan_locked: action.payload?.high_roomscan_locked,
          high_suspended_camera: action.payload?.high_suspended_camera,
          high_gaze_detection: action.payload?.high_gaze_detection,
          medium_gaze_detection: action.payload?.medium_gaze_detection,
          medium_move_focus: action.payload?.medium_move_focus,
          medium_closed_window: action.payload?.medium_closed_window,
          medium_offline: action.payload?.medium_offline,
          medium_online: action.payload?.medium_online,
          medium_online_server: action.payload?.medium_online_server,
          medium_offline_server: action.payload?.medium_offline_server,
          medium_simulation_timeout: action.payload?.medium_simulation_timeout,
          medium_new_tab_window: action.payload?.medium_new_tab_window,
          medium_multiple_displays_detected:
            action.payload?.medium_multiple_displays_detected,
          medium_more_than_one_cam: action.payload?.medium_more_than_one_cam,
          medium_multiple_faces_detected:
            action.payload?.medium_multiple_faces_detected,
          medium_no_face_detected: action.payload?.medium_no_face_detected,
          medium_new_tab: action.payload?.medium_new_tab,
          medium_test_paused: action.payload?.medium_test_paused,
          medium_test_resumed: action.payload?.medium_test_resumed,
          medium_start_streaming: action.payload?.medium_start_streaming,
          medium_stop_streaming: action.payload?.medium_stop_streaming,
          medium_simulation_expired: action.payload?.medium_simulation_expired,
          medium_closed_window_or_tab:
            action.payload?.medium_closed_window_or_tab,
          medium_roomscan_failed_more_than_twice_and_resumed:
            action.payload?.medium_roomscan_failed_more_than_twice_and_resumed,
          medium_roomscan_locked: action.payload?.medium_roomscan_locked,
          medium_suspended_camera: action.payload?.medium_suspended_camera,
          proctoring_setting_countries_attributes:
            action.payload.proctoring_setting_countries || [],
        };
      }
    },
    receiveProctoringSettingError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    requestSave: (state) => {
      state.loading = true;
    },
    receiveSave: (state, action) => {
      state.loading = false;

      if (action.payload.id) {
        state.data.id = action.payload.id;
      }

      if (action.payload.has_exams) {
        state.data.has_exams = action.payload.has_exams;
      }

      const countriesToSave =
        action.payload?.proctoring_setting_countries_attributes ||
        action.payload?.proctoring_setting_countries;

      if (countriesToSave) {
        state.data.proctoring_setting_countries_attributes =
          countriesToSave || [];
        state.origin_countries = countriesToSave || [];
      }
    },
    receiveSaveError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    requestWhitelistUrl: (state) => {
      state.loadingWhitelistUrl = true;
    },
    receiveWhitelistFromFetchUrl: (state, action) => {
      state.loadingWhitelistUrl = false;
      state.data.allowed_urls = action.payload;
    },
    receiveWhitelistUrl: (state, action) => {
      state.loadingWhitelistUrl = false;
      state.data.allowed_urls = state.data.allowed_urls?.map((item) => {
        if (action.payload?.url !== item?.url) return item;
        return {
          ...item,
          id: action.payload?.id,
        };
      });
    },
    receiveWhitelistUrlError: (state, action) => {
      state.loadingWhitelistUrl = false;
      state.error = action.payload;
    },
  },
});

const Actions = proctoringSettingsEditSlice.actions;

const Selectors = {
  fetchListData: (state) => state.proctoringSettingsEdit,
  proctoringSettingsLoading: ({ proctoringSettingsEdit: { loading } }) => ({
    loading,
  }),
};

const Async = {
  fetchWhitelabelsList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      WHITELABELS_URL,
      'GET',
      Actions.requestWhitelabels,
      Actions.receiveWhitelabelsList,
      Actions.receiveWhitelabelsError
    ),
  fetchExternalAppsList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      EXTERNAL_APPLICATIONS_URL,
      'GET',
      Actions.requestExternalApps,
      Actions.receiveExternalAppsList,
      Actions.receiveExternalAppsError
    ),
  fetchCountriesList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      COUNTRY_PERMISSIONS_COUNTRIES_URL,
      'GET',
      Actions.requestCountries,
      Actions.receiveCountriesList,
      Actions.receiveCountriesError
    ),
  fetchWhitelistUrls:
    ({ proctoring_setting_id }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestWhitelistUrl());

      try {
        const response = await request({
          method: 'GET',
          url: ALLOWED_URLS_BY_ID_URL,
          params: {
            proctoring_setting_id,
          },
        });

        action = Actions.receiveWhitelistFromFetchUrl(response.data.content);
      } catch (e) {
        dispatch(Actions.receiveWhitelistUrlError(e.message));
      }

      dispatch(action);
    },
  fetchProctoringSettingByOrigin: () => async (dispatch, getState) => {
    const {
      proctoringSettingsEdit: {
        origin: {
          selected: { id, type },
        },
      },
    } = getState();

    let action;

    const isWhitelabelType = type === 'whitelabel';
    const isExternalAppType = type === 'external_application';

    dispatch(Actions.requestProctoringSetting());

    try {
      const response = await request({
        method: 'GET',
        url: PROCTORING_SETTINGS_BY_WHITELABEL_OR_EXTERNAL_APP_ID_URL,
        params: {
          whitelabel_id: isWhitelabelType ? id : null,
          external_application_id: isExternalAppType ? id : null,
        },
      });

      action = Actions.receiveProctoringSetting(response?.data?.content);
    } catch (e) {
      action = Actions.receiveProctoringSettingError(e.message);
    }

    dispatch(action);
  },
  fetchProctoringSettingById:
    ({ id }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestProctoringSetting());

      try {
        const response = await request({
          method: 'GET',
          url: PROCTORING_SETTINGS_BY_ID_URL,
          params: {
            id,
          },
        });

        action = Actions.receiveProctoringSetting(response?.data?.content);
      } catch (e) {
        action = Actions.receiveProctoringSettingError(e.message);
      }

      dispatch(action);
    },
  createProctoringSetting:
    ({ onCreateSuccess }) =>
    async (dispatch, getState) => {
      const {
        proctoringSettingsEdit: {
          data,
          origin: {
            selected: { id, type },
          },
        },
      } = getState();

      const isWhitelabelType = type === 'whitelabel';
      const isExternalAppType = type === 'external_application';

      const dataToSave = { ...data };
      delete dataToSave['allowed_urls'];

      let action;

      dispatch(Actions.requestSave());

      const countries =
        dataToSave?.proctoring_setting_countries_attributes?.map((country) => ({
          name: country?.name,
          level: country?.level,
        }));

      const requestData = {
        ...dataToSave,
        proctoring_setting_countries_attributes: JSON.stringify(countries),
        whitelabel_id: isWhitelabelType ? id : null,
        external_application_id: isExternalAppType ? id : null,
      };

      delete requestData.type;
      delete requestData.has_exams;

      try {
        const response = await request({
          method: 'POST',
          url: PROCTORING_SETTINGS_CREATE_URL,
          data: requestData,
        });

        action = Actions.receiveSave(response.data.content);
        onCreateSuccess(response.data.content);
      } catch (e) {
        action = Actions.receiveSaveError(e.message);
      }

      dispatch(action);
    },
  updateProctoringSetting:
    ({ onUpdateSuccess }) =>
    async (dispatch, getState) => {
      const {
        proctoringSettingsEdit: { data, origin_countries },
      } = getState();

      const dataToSave = { ...data };
      delete dataToSave['allowed_urls'];

      let action;

      dispatch(Actions.requestSave());

      const countries =
        dataToSave?.proctoring_setting_countries_attributes?.map((country) => {
          const formatted = {
            name: country?.name,
            level: country?.level,
          };

          const isOnOriginList = origin_countries?.find(
            (countryOrigin) =>
              country?.name === countryOrigin?.name &&
              country?.level === countryOrigin?.level
          );

          if (isOnOriginList) formatted.id = isOnOriginList?.id;

          return formatted;
        }) || [];

      const countriesToDestroy =
        origin_countries
          ?.filter((countryToDestroy) => {
            const isOnList = countries?.find(
              (country) =>
                countryToDestroy?.name === country?.name &&
                countryToDestroy?.level === country?.level
            );

            return !isOnList;
          })
          ?.map((countryToDestroy) => ({
            name: countryToDestroy?.name,
            level: countryToDestroy?.level,
            id: countryToDestroy?.id,
            _destroy: true,
          })) || [];

      const requestData = {
        ...dataToSave,
        proctoring_setting_countries_attributes: JSON.stringify(
          countries.concat(countriesToDestroy)
        ),
      };

      delete requestData.type;
      delete requestData.has_exams;

      try {
        const response = await request({
          method: 'PUT',
          url: PROCTORING_SETTINGS_UPDATE_URL,
          data: requestData,
        });

        action = Actions.receiveSave(response.data.content);
        onUpdateSuccess(response.data.content);
      } catch (e) {
        action = Actions.receiveSaveError(e.message);
      }

      dispatch(action);
    },
  createWhitelistUrl:
    ({ proctoring_setting_id, url, onCreateUrlSuccess }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestWhitelistUrl());

      try {
        const response = await request({
          method: 'POST',
          url: ALLOWED_URLS_CREATE_URL,
          data: {
            proctoring_setting_id,
            url,
          },
        });

        action = Actions.receiveWhitelistUrl(response.data.content);
        onCreateUrlSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveWhitelistUrlError(e.message));
      }

      dispatch(action);
    },
  updateWhitelistUrl:
    ({ proctoring_setting_id, id, url, onUpdateUrlSuccess }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestWhitelistUrl());

      try {
        const response = await request({
          method: 'PUT',
          url: ALLOWED_URLS_UPDATE_URL,
          data: {
            proctoring_setting_id,
            url,
            id,
          },
        });

        action = Actions.receiveWhitelistUrl(response.data.content);
        onUpdateUrlSuccess(response);
      } catch (e) {
        action = Actions.receiveWhitelistUrlError(e.message);
      }

      dispatch(action);
    },
  deleteWhitelistUrl:
    ({ id, onDeleteSuccess }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestWhitelistUrl());

      try {
        const response = await request({
          method: 'DELETE',
          url: `${ALLOWED_URLS_DELETE_URL}?id=${id}`,
        });

        action = Actions.destroyWhitelistUrl(id);
        onDeleteSuccess(response);
      } catch (e) {
        action = Actions.receiveWhitelistUrlError(e.message);
      }

      dispatch(action);
    },
};

const reducer = proctoringSettingsEditSlice.reducer;

export { reducer, Actions, Async, Selectors };
