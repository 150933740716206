import { useAuth } from 'modules/Auth/Hooks';
import { format, utcToTimezone } from 'modules/Utils/Date';

export const useFormattedDate = ({ date, dateFormat }) => {
  const { userTimezone, userDateFormat } = useAuth();
  const formattedDate = utcToTimezone(date, userTimezone);
  const dateFormatToUse = dateFormat || userDateFormat;

  return {
    formattedDate: format(formattedDate, dateFormatToUse),
  };
};
