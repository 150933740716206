import * as Sentry from '@sentry/react';

const CONFIG = {
  dsn: process.env.REACT_APP_SENTRY_DNS,
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
  ignoreErrors: [
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
  ],
  beforeSend(event) {
    if (
      event !== undefined &&
      event.exception !== undefined &&
      event.exception.values !== undefined &&
      event.exception.values.length == 1
    ) {
      const error = event.exception.values[0];
      if (
        error.type.includes('UnhandledRejection') ||
        error.value.includes('Non-Error promise')
      ) {
        return null;
      }
    }
  },
};

export default {
  init() {
    if (process.env.NODE_ENV !== 'production') {
      return false;
    }
    if (process.env.REACT_APP_SENTRY_RELEASE) {
      CONFIG.release = process.env.REACT_APP_SENTRY_RELEASE;
    }
    return Sentry.init(CONFIG);
  },
};
