import styled from 'styled-components';
import { Form as Formik } from 'formik';

export const Form = styled(Formik)`
  position: relative;

  input {
    width: 100%;
    height: 4.8rem;
  }
`;

export const FormBody = styled.div`
  position: relative;
  max-height: 90vh;
  padding: 3.2rem 2.4rem ${(props) => (props.isCenter ? '4.2rem' : '3.2rem')};

  h2 {
    max-width: 190px;
    text-align: ${(props) => (props.isCenter ? 'center' : 'left')};
    margin: ${(props) =>
      props.isCenter ? '2.5rem auto 3.2rem' : '0 0 3.2rem 0'};
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 4px;
    background: ${(props) => props.theme.config.colors.primary};
    border-radius: 6px 6px 0px 0px;
  }
`;

export const ButtonClose = styled.button`
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
  cursor: pointer;
  text-align: center;
  background-color: transparent;
  border: none;
  outline: none;

  svg {
    path {
      fill: ${(props) => props.theme.config.colors.text_base};
    }
  }
`;

export const EvaluatorTitle = styled.p`
  font-size: 1.6rem;
  font-weight: 400;
  color: ${(props) => props.theme.config.colors.text_base};
  margin-bottom: 0.8rem;
`;

export const ScheduleDate = styled.div`
  margin-top: 1rem;
`;

export const ScheduleTitle = styled.p`
  font-size: 1.6rem;
  font-weight: 400;
  color: ${(props) => props.theme.config.colors.text_base};
  margin-bottom: 0.8rem;
  margin-top: 1rem;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.hasRandom ? 'space-between' : 'center')};
  margin-top: ${(props) => props.theme.config.spacing.spacing_6};
`;

export const Random = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 400;
  color: ${(props) => props.theme.config.colors.primary};
  cursor: pointer;

  svg {
    path {
      fill: ${(props) => props.theme.config.colors.primary};
    }
  }

  span {
    margin-left: 0.4rem;
  }
`;
