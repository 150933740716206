import KJUR from 'jsrsasign';
import ENV from 'config/env';

const ALGORITHM = 'HS256';

/**
 * Generate a jwt token with payload data.
 * @param {Object} payload
 * @returns {String}
 */
const generatePayload = (payload) => {
  const oHeader = { alg: ALGORITHM };
  const sHeader = JSON.stringify(oHeader);
  const sPayload = JSON.stringify(payload);
  const token = KJUR.jws.JWS.sign(ALGORITHM, sHeader, sPayload, {
    b64u: ENV.token.secret,
  });

  return token;
};

export { generatePayload };
