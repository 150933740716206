import { lazy } from 'react';
import { menuBuilder } from 'services/Routes.service';
import { AntifraudIcon } from '@edusynch/edusynch-svg-icons';

const BaseTestsList = lazy(() =>
  import('modules/Antifraud/BaseTests/BaseTestsList/BaseTestsList.container')
);
const BaseTestsLog = lazy(() =>
  import('modules/Antifraud/BaseTests/BaseTestsLog')
);
const CustomTestsList = lazy(() =>
  import(
    'modules/Antifraud/CustomTests/CustomTestsList/CustomTestsList.container'
  )
);
const CustomTestsLog = lazy(() =>
  import('modules/Antifraud/CustomTests/CustomTestsLog')
);
const AntifraudPluginList = lazy(() =>
  import(
    'modules/Antifraud/Plugin/AntifraudPluginList/AntifraudPluginList.container'
  )
);
const AntifraudPluginLog = lazy(() =>
  import('modules/Antifraud/Plugin/AntifraudPluginLog')
);

const AntifraudMenu = menuBuilder([
  {
    Icon: AntifraudIcon,
    name: 'E-Proctoring',
  },
]).addSubmenu([
  {
    type: '',
    name: 'Base Tests',
    path: '/antifraud/base-tests',
    exact: true,
    component: BaseTestsList,
    ability: 'antifraud_base_tests',
  },
  {
    type: '',
    name: 'Base Tests Logs',
    path: '/antifraud/base-tests/log/:id',
    component: BaseTestsLog,
    hidden: true,
    ability: 'antifraud_base_tests',
  },
  {
    type: '',
    name: 'Custom Tests',
    path: '/antifraud/custom-tests',
    exact: true,
    component: CustomTestsList,
    ability: 'antifraud_custom_tests',
  },
  {
    type: '',
    name: 'Custom Tests Logs',
    path: '/antifraud/custom-tests/log/:id',
    component: CustomTestsLog,
    hidden: true,
    ability: 'antifraud_custom_tests',
  },
  {
    type: '',
    name: 'Plugin Sessions',
    path: '/antifraud/plugin',
    exact: true,
    component: AntifraudPluginList,
    ability: 'antifraud_plugin_sessions',
  },
  {
    type: '',
    name: 'Plugin Sessions Logs',
    path: '/antifraud/plugin/log/:id',
    component: AntifraudPluginLog,
    hidden: true,
    ability: 'antifraud_plugin_sessions',
  },
]);

export default AntifraudMenu;
