import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useQuery = () => {
  const searchQuery = new URLSearchParams(useLocation().search);
  return useMemo(() => new URLSearchParams(searchQuery), [searchQuery]);
};

export const paramsToObject = (params) => {
  const urlParams = new URLSearchParams(params);
  const entries = urlParams.entries();

  const result = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};
