import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Text = styled.span`
  ${(props) => props.theme.config.typograph.xsmall}
  color: ${(props) => props.theme.config.colors.text_base};
  display: block;
`;
