import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTheme } from 'styled-components';

import * as S from './MUIRadioGroup.styles';

export const MUICheckbox = ({ checked, label, disabled, onChange }) => {
  const theme = useTheme();

  const customTheme = createTheme({
    typography: {
      fontSize: 17,
    },
  });

  return (
    <ThemeProvider theme={customTheme}>
      <S.Container>
        <FormControlLabel
          control={
            <Checkbox
              checked={!!checked}
              disabled={disabled}
              onChange={onChange}
              sx={{
                paddingTop: 0,
                paddingBottom: 0,
                color: theme.config.colors.primary,
                '&.Mui-checked': {
                  color: theme.config.colors.primary,
                },
              }}
            />
          }
          label={label}
        />
      </S.Container>
    </ThemeProvider>
  );
};

MUICheckbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};
