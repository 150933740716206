import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { ZIndexes } from 'modules/Utils/ZIndexes';
import { Modal, InfoIcon, IconRoundBox, Button } from 'modules/Core/Common';
import i18n from 'i18next';
import * as S from './FirstAccessModal.styles';

export const FirstAccessModal = ({
  isOpen,
  setIsOpen,
  render,
  data,
  modalTitle,
  IconName,
  handleFilter,
}) => {
  const theme = useTheme();

  return (
    <Modal
      width="416"
      maxHeight="569"
      borderTop="4px #0075EA solid"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      offCloseOnOverlayClick
      zIndex={ZIndexes.modalResults}
      hasSolidBg
    >
      <S.Container data-cy="practice-modal-filter">
        <S.ModalHeader>
          <InfoIcon
            thin
            before={
              <IconRoundBox medium withBg bg={theme.config.colors.primary_200}>
                <IconName color={theme.config.colors.secondary} type="medium" />
              </IconRoundBox>
            }
          />
          <S.Title>{modalTitle}</S.Title>
          <S.SubTitle>
            {i18n.t('modules.results.commons.modal-filter.subtitle')}
          </S.SubTitle>
        </S.ModalHeader>
        <S.FiltersContainer>{render(data)}</S.FiltersContainer>
        <S.ButtonContainer>
          <Button
            medium
            type="submit"
            data-test="submit"
            onClick={handleFilter}
          >
            {i18n.t('commons.buttons.enter-button')}
          </Button>
        </S.ButtonContainer>
      </S.Container>
    </Modal>
  );
};

FirstAccessModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired,
  render: PropTypes.any,
  data: PropTypes.any,
  modalTitle: PropTypes.string,
  IconName: PropTypes.func,
};
