import styled, { css } from 'styled-components';

export const Container = styled.div`
  background-color: ${(props) => props.theme.config.colors.light};
  border-radius: 0.6rem;
  box-shadow: 0 0.6rem 1.2rem rgba(49, 49, 49, 0.15);
`;

export const Options = styled.ul`
  border-radius: 0.6rem;
  max-height: 35rem;
  overflow-y: auto;

  scrollbar-color: ${(props) => props.theme.config.colors.gray_200};

  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.config.colors.gray_100};
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.config.colors.gray_300};
    border-radius: 0.8rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.config.colors.gray};
  }
`;

export const GroupedOptions = styled.li`
  padding: 1.6rem 0 0;
`;

export const GroupedOptionsTitle = styled.div`
  ${(props) => props.theme.config.typograph.xsmall};
  color: ${(props) => props.theme.config.colors.gray_400};
  font-size: 1.4rem;
  text-transform: uppercase;
  padding-left: 1.6rem;
  padding-bottom: 0.6rem;
`;

export const GroupedOptionsItem = styled.div`
  display: flex;
  align-items: center;
  ${(props) => props.theme.config.typograph.xsmall};
  color: ${(props) => props.theme.config.colors.text_base};
  font-size: 1.4rem;
  padding: 0.6rem 1.6rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${(props) => props.theme.config.colors.gray_200};
  }

  svg {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.8rem;

    path {
      fill: ${(props) => props.theme.config.colors.primary_400};
    }
  }
`;

export const Option = styled.li`
  display: flex;
  align-items: center;
  ${(props) => props.theme.config.typograph.xsmall};
  color: ${(props) => props.theme.config.colors.text_base};
  padding: 1.4rem;
  cursor: pointer;
  transition: background-color 0.2s;

  ${({ between }) =>
    between &&
    css`
      justify-content: space-between;
    `}

  &:hover {
    background-color: ${(props) => props.theme.config.colors.gray_200};
  }

  & + li {
    border-top: 1px solid ${(props) => props.theme.config.colors.gray_200};
  }

  svg {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.8rem;

    path {
      fill: ${(props) => props.theme.config.colors.primary_400};
    }
  }
`;

export const OptionHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 1.4rem;
  position: relative;
  border-bottom: 1px solid ${(props) => props.theme.config.colors.gray_200};

  button {
    position: absolute;
  }

  svg {
    width: 1.2rem;
    height: 1.2rem;
    justify-self: flex-start;
  }

  h6 {
    text-align: center;
    margin: 0 auto;
    ${(props) => props.theme.config.typograph.xsmall};
    color: ${(props) => props.theme.config.colors.text_base};
    font-weight: bold;
  }
`;

export const SearchContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.config.colors.gray_200};

  input {
    width: 100%;
    border: none;
  }
`;

export const Empty = styled.div`
  text-align: center;
  padding: 1.5rem;

  svg {
    padding-bottom: 1.5rem;
  }

  span {
    display: block;
  }
`;

export const EmptyTitle = styled.span`
  ${(props) => props.theme.config.typograph.default};
  font-size: 24px;
  font-weight: bold;
  color: ${(props) => props.theme.config.colors.secondary};
  margin-bottom: 15px;
`;

export const EmptyDescription = styled.div`
  text-align: left;
  ${(props) => props.theme.config.typograph.mico};
  color: ${(props) => props.theme.config.colors.text_base};

  span {
    text-align: left;
    display: inline;
    ${(props) => props.theme.config.typograph.xsmall};
    color: ${(props) => props.theme.config.colors.secondary};
    font-weight: bold;
  }
`;
