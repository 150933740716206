import { lazy } from 'react';
import { menuBuilder } from 'services/Routes.service';
import { TeachersIcon } from '@edusynch/edusynch-svg-icons';

const GroupsListContainer = lazy(() =>
  import('modules/Institutions/Groups/GroupsList/GroupsList.container')
);
const UnitsListContainer = lazy(() =>
  import('modules/Institutions/Units/UnitsList/UnitsList.container')
);
const SchoolsListContainer = lazy(() =>
  import('modules/Institutions/Schools/SchoolsList/SchoolsList.container')
);

const ClassesListContainer = lazy(() =>
  import('modules/Institutions/Classes/ClassesList/ClassesList.container')
);

const InstitutionsMenu = menuBuilder([
  {
    Icon: TeachersIcon,
    name: 'Institutions',
  },
]).addSubmenu([
  {
    name: 'Group',
    path: '/institutions/groups',
    component: GroupsListContainer,
    ability: 'groups',
  },
  {
    name: 'Unit',
    path: '/institutions/units',
    component: UnitsListContainer,
    ability: 'unit',
  },
  {
    name: 'School',
    path: '/institutions/schools',
    component: SchoolsListContainer,
    ability: 'school',
  },
  {
    name: 'Classes',
    path: '/institutions/classes',
    component: ClassesListContainer,
    ability: 'classes',
  },
]);

export default InstitutionsMenu;
