import styled, { css } from 'styled-components';

const AvatarProfile = styled.div`
  .General {
    display: flex;

    .Image {
      position: relative;
      height: 150px;
      width: 150px;
      border-radius: 4px;
      margin-right: 2.4rem;

      .EditImageIcon,
      .editImageIcon {
        position: absolute;
        right: 4px;
        top: 4px;
        background-color: ${(props) => props.theme.config.colors.primary};
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        > svg {
          width: 1.2rem;
          cursor: pointer;

          path {
            fill: ${(props) => props.theme.config.colors.light};
          }
        }
      }
      .avatarSettingsProfile {
        width: 150px;
        height: 150px;
      }
    }
    .User {
      display: inherit;
      flex-direction: column;
      justify-content: center;
      > h4 {
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: ${(props) => props.theme.config.colors.text_base};
      }
      > span {
        font-size: 1.2rem;
        line-height: 1.4 rem;
        color: ${(props) => props.theme.config.colors.text_base};
        opacity: 0.5;
      }
    }
  }
`;

const ToggleButtonsSettings = styled.div`
  font-size: 1.4rem;
  margin: 3.2rem -2.4rem 0;
  color: ${(props) => props.theme.config.colors.text_base};
  button {
    outline: none;
    height: 6rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: left;
    padding-left: 2.4rem;
    background: transparent;
    color: ${(props) => props.theme.config.colors.text_base};
    min-width: 100%;
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.config.colors.gray_100};
    border-left: 4px solid transparent;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: all 0.3s;
    &:hover {
      border: none;
      border-left: 4px solid ${(props) => props.theme.config.colors.primary};
      background-color: ${(props) => props.theme.config.colors.gray_100};
      color: ${(props) => props.theme.config.colors.text_base};
      border-bottom: 1px solid transparent;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
    }
    &.toggle-active {
      border: none;
      border-left: 4px solid ${(props) => props.theme.config.colors.primary};
      border-bottom: 1px solid transparent;
      background-color: ${(props) => props.theme.config.colors.gray_100};
      color: ${(props) => props.theme.config.colors.text_base};
    }
  }
`;

const getTitleType = (props) => {
  if (props?.secondary) {
    return css`
      margin-bottom: 1.6rem;

      h4 {
        ${props.theme.config.typograph.default};
        font-weight: bold;
      }
    `;
  }

  return css`
    margin-bottom: 0.8rem;
    h4 {
      ${(props) => props.theme.config.typograph.xsmall};
    }
  `;
};

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  ${(props) => getTitleType(props)};

  h4 {
    color: ${(props) => props.theme.config.colors.text_base};
  }

  button {
    background: transparent;
    border: none;
  }
`;

export { ToggleButtonsSettings, AvatarProfile, Title };
