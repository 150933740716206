import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { useTheme } from 'styled-components';

import { ZIndexes } from 'modules/Utils/ZIndexes';

import './styles.css';

const Modal = ({
  children,
  isOpen,
  setIsOpen,
  onCloseModal,
  offCloseOnOverlayClick,
  overflow = 'auto',
  width = '830px',
  maxHeight = '90vh',
  borderTop = '',
  background,
  maxWidth = '100vw',
  hasSolidBg,
  zIndex = ZIndexes.modal,
}) => {
  const [modalStatus, setModalStatus] = useState(isOpen);
  const theme = useTheme();
  const backgroundToSet = background || theme.config.colors.background;

  useEffect(() => {
    setModalStatus(isOpen);
  }, [isOpen]);

  return (
    <ReactModal
      shouldCloseOnOverlayClick={
        offCloseOnOverlayClick ? false : !offCloseOnOverlayClick
      }
      onAfterClose={onCloseModal}
      shouldCloseOnEsc={!offCloseOnOverlayClick}
      onRequestClose={setIsOpen}
      isOpen={modalStatus}
      ariaHideApp={false}
      style={{
        content: {
          overflow,
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          background: backgroundToSet,
          color: theme.config.colors.black,
          borderRadius: '6px',
          width,
          border: 'none',
          borderTop,
          padding: '0px',
          maxWidth: maxWidth,
          maxHeight,
        },
        overlay: {
          backgroundColor: hasSolidBg
            ? theme.config.colors.text_base
            : theme.config.colors.black_opacity_9,
          zIndex,
        },
      }}
    >
      {children}
    </ReactModal>
  );
};

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  isOpen: PropTypes.any.isRequired,
  setIsOpen: PropTypes.func,
  onCloseModal: PropTypes.func,
  overflow: PropTypes.string,
  width: PropTypes.string,
  maxHeight: PropTypes.string,
  zIndex: PropTypes.number,
  borderTop: PropTypes.string,
  background: PropTypes.string,
  hasSolidBg: PropTypes.bool,
  offCloseOnOverlayClick: PropTypes.bool,
  maxWidth: PropTypes.string,
  noPadding: PropTypes.bool,
};

export { Modal };
