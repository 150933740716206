import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import { Tooltip } from 'modules/Core/Common';
import { ButtonDetails, TooltipDetails } from './styles';

const UserButtonDetails = ({ data }) => (
  <Tooltip
    placement="bottom"
    overlay={
      <TooltipDetails>
        {data.association_names && data.association_names.length ? (
          data.association_names.map((text, index) => <p key={index}>{text}</p>)
        ) : (
          <p>No Details</p>
        )}
      </TooltipDetails>
    }
    overlayInnerStyle={{
      backgroundColor: '#0075EA',
      borderRadius: '4px',
      padding: '16px',
    }}
  >
    <ButtonDetails>
      {i18n.t('commons.buttons.show-details-button')}
    </ButtonDetails>
  </Tooltip>
);

UserButtonDetails.propTypes = {
  data: PropTypes.object,
};

export default UserButtonDetails;
