import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { Menu, MenuItem, IconButton } from '@mui/material';
import { Title } from 'modules/Core/Common';
import { CalendarIcon } from '@edusynch/edusynch-svg-icons';
import i18n from 'i18next';

const INTERVAL_OPTIONS = ['7', '30', '12'];

export const IntervalOptionsMenu = ({ defaultOption, onChangeOption }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const theme = useTheme();

  const handleDaysOptionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelectDayOption = (option) => {
    onChangeOption(option);
    setAnchorEl(null);
  };

  return (
    <>
      <Title as="span" size="mico" fontWeight="400">
        {i18n.t('common-words.last')} {defaultOption}{' '}
        {defaultOption === '12'
          ? i18n.t('common-words.months')
          : i18n.t('common-words.days')}
      </Title>
      <IconButton
        aria-label="days-options"
        id="days-options"
        aria-controls={open ? 'days-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleDaysOptionsClick}
      >
        <CalendarIcon color="#acabb7" />
      </IconButton>
      <Menu
        id="days-menu"
        MenuListProps={{
          'aria-labelledby': 'days-options',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        sx={{
          '.MuiMenu-list': {
            padding: 0,
            fontSize: '1.6rem',
          },
        }}
      >
        {INTERVAL_OPTIONS.map((option) => (
          <MenuItem
            key={option}
            selected={defaultOption === option}
            onClick={() => handleSelectDayOption(option)}
            divider
            sx={{
              fontSize: '1.4rem',
              padding: '1.6rem',
              borderBottomColor: theme.config.colors.gray_100,
            }}
          >
            {i18n.t('common-words.last')} {option}{' '}
            {option === '12'
              ? i18n.t('common-words.months')
              : i18n.t('common-words.days')}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

IntervalOptionsMenu.propTypes = {
  defaultOption: PropTypes.string,
  onChangeOption: PropTypes.func.isRequired,
};
