import { lazy } from 'react';
import { menuBuilder } from 'services/Routes.service';
import { SettingsIcon } from '@edusynch/edusynch-svg-icons';

const SettingsGeneralContainer = lazy(() =>
  import('modules/Settings/Settings.container')
);

const ApiLogContainer = lazy(() =>
  import('modules/Settings/ApiLog/ApiLogList/ApiLogList.container')
);

const NotificationsContainer = lazy(() =>
  import(
    'modules/Settings/Notifications/NotificationsList/NotificationsList.container'
  )
);

const AiEvaluatorContainer = lazy(() =>
  import('modules/Settings/AIEvaluator/AIEvaluator.container')
);

const FeatureFlagsContainer = lazy(() =>
  import(
    'modules/Settings/FeatureFlags/FeatureFlagsList/FeatureFlagsList.container'
  )
);

const SettingsMenu = menuBuilder([
  {
    Icon: SettingsIcon,
    name: 'Settings',
  },
]).addSubmenu([
  {
    name: 'General',
    path: '/settings',
    component: SettingsGeneralContainer,
    ability: 'settings_web_mobile',
  },
  {
    name: 'API Log',
    path: '/api-log',
    component: ApiLogContainer,
    ability: 'settings_api_log',
  },
  {
    name: 'Push Notifications',
    path: '/push-notifications',
    component: NotificationsContainer,
    ability: 'settings_push_notifications',
  },
  {
    name: 'AI Evaluator Bot',
    path: '/ai-evaluator',
    component: AiEvaluatorContainer,
    ability: 'settings_web_mobile',
  },
  {
    name: 'Feature Flags',
    path: '/feature-flags',
    component: FeatureFlagsContainer,
    ability: 'settings_feature_flags',
  },
]);

export default SettingsMenu;
