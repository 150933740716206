import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import * as S from './CustomSelect.styles';
import { useToggleDropdown } from 'modules/Core/Hooks';
import { ArrowDownIcon } from '@edusynch/edusynch-svg-icons';
import { Tooltip } from 'modules/Core/Common';
import i18n from 'i18next';

const CustomSelectContext = createContext();

const useCustomSelectContext = () => {
  const context = useContext(CustomSelectContext);
  if (!context) {
    throw new Error(i18n.t('errors.error-custom-select'));
  }
  return context;
};

const ButtonContent = () => {
  const { Icon, selected, selectedCustomDisplay, title, loading } =
    useCustomSelectContext();

  const label =
    (selected || title)?.length > 10
      ? (selected || title).slice(0, 10) + '...'
      : selected || title;

  if (loading) return `${i18n.t('common-words.loading')}...`;

  if (Icon) {
    return (
      <>
        <Icon />
        <span>{label}</span>
      </>
    );
  }

  return selectedCustomDisplay || label;
};

const Option = ({ text, value, customOption }) => {
  const { onToggleClick, setSelected, setShowDropdown } =
    useCustomSelectContext();

  return (
    <S.Item
      onClick={() => {
        setSelected(text);
        onToggleClick(value);
        setShowDropdown(false);
      }}
    >
      {customOption || text}
    </S.Item>
  );
};

const Select = ({
  Icon,
  data,
  title,
  loading,
  disabled = false,
  secondary,
  tertiary,
  quaternary,
  block,
  large,
  selectedCustomDisplay,
  selectedValue,
  onToggleClick,
}) => {
  const dropdownRef = React.useRef();
  const buttonRef = React.useRef();
  const [selected, setSelected] = useState('');

  const { showDropdown, setShowDropdown } = useToggleDropdown(
    buttonRef,
    dropdownRef
  );

  useEffect(() => {
    if (!data?.length && selectedValue) return;
    const currentSelected = data?.find(
      (item) => item.id === Number(selectedValue)
    );
    setSelected(currentSelected?.name || currentSelected?.text);
  }, [data, loading]);

  const titleIsLarge = useMemo(
    () => (selected || title)?.length > 10,
    [selected || title]
  );

  const values = useMemo(
    () => ({
      Icon,
      data,
      title,
      loading,
      disabled,
      selected,
      secondary,
      tertiary,
      quaternary,
      block,
      large,
      selectedCustomDisplay,
      setSelected,
      onToggleClick,
      setShowDropdown,
    }),
    [
      Icon,
      data,
      title,
      loading,
      disabled,
      selected,
      secondary,
      tertiary,
      block,
      large,
      selectedCustomDisplay,
      setSelected,
      onToggleClick,
      setShowDropdown,
    ]
  );

  return (
    <CustomSelectContext.Provider value={values}>
      <S.ButtonContainer quaternary={quaternary} block={block}>
        <Tooltip
          placement="bottom"
          overlay={selected || title}
          disabled={!titleIsLarge || showDropdown}
          overlayInnerStyle={{
            backgroundColor: '#0075EA',
            borderRadius: '4px',
            padding: '16px',
            fontSize: '16px',
          }}
        >
          <S.Button
            disabled={disabled}
            ref={buttonRef}
            secondary={secondary}
            tertiary={tertiary}
            quaternary={quaternary}
            block={block}
            large={large}
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <ButtonContent clasName="content" />
            {(secondary || tertiary || quaternary) && (
              <ArrowDownIcon quaternary={quaternary} />
            )}
          </S.Button>
        </Tooltip>
        {showDropdown && (
          <S.DropdownContainer
            quaternary={quaternary}
            secondary={secondary}
            block={block}
          >
            <S.Dropdown
              quaternary={quaternary}
              secondary={secondary}
              ref={dropdownRef}
            >
              {data?.map((item) => (
                <Option
                  key={item.id}
                  text={item.name || item.text}
                  value={item.id}
                  customOption={item.customOption}
                />
              ))}
            </S.Dropdown>
          </S.DropdownContainer>
        )}
      </S.ButtonContainer>
    </CustomSelectContext.Provider>
  );
};

Select.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  selectedValue: PropTypes.any,
  title: PropTypes.string,
  Icon: PropTypes.func,
  onToggleClick: PropTypes.func,
  loading: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  quaternary: PropTypes.bool,
  block: PropTypes.bool,
  large: PropTypes.bool,
  selectedCustomDisplay: PropTypes.element,
  data: PropTypes.array,
  disabled: PropTypes.bool,
};

Option.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.string,
  customOption: PropTypes.element,
};

export { Select as CustomSelect };
