import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 2.4rem;

  svg {
    width: 3.2rem;
    height: 3.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${(props) => props.theme.config.colors.gray_100};

    path {
      width: 1.2rem;
      fill: ${(props) => props.theme.config.colors.gray};
    }
  }

  .MuiStepper-root {
    width: 100%;
  }

  .MuiStepConnector-root {
    top: 1.6rem;
    left: calc(-50% + 1.5rem);
    right: calc(50% + 1.6rem);
  }

  .MuiStepLabel-label {
    font-size: 1.2rem;
    color: ${(props) => props.theme.config.colors.gray} !important;
    line-height: normal;
    margin-top: 0.4rem !important;
  }

  .Mui-completed {
    .MuiStepLabel-iconContainer {
      svg {
        background-color: ${(props) => props.theme.config.colors.primary};

        path {
          fill: ${(props) => props.theme.config.colors.light};
        }
      }
    }

    .MuiStepConnector-line {
      border-color: ${(props) => props.theme.config.colors.primary};
    }

    .MuiStepLabel-label {
      color: ${(props) => props.theme.config.colors.primary} !important;
    }
  }
`;
