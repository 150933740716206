import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Selectors, Actions } from 'modules/Core/AppSlice';
import { Toast } from 'modules/Core/Common';
import i18n from 'i18next';

const InternetStatusIndicator = () => {
  const dispatch = useDispatch();
  const status = useSelector(Selectors.internetStatus);

  React.useEffect(() => {
    window.addEventListener('online', () => {
      dispatch(Actions.setInternetStatus('online'));
      Toast(
        i18n.t('commons.internet-status.device-connected'),
        'connected',
        'top-center',
        3
      );
      dispatch(Actions.clearReconnectIntervalId());
    });

    window.addEventListener('offline', () => {
      dispatch(Actions.setInternetStatus('offline'));
      Toast(
        i18n.t('commons.internet-status.lost-connection'),
        'offline',
        'top-center',
        3
      );
    });
  }, []);

  React.useEffect(() => {
    if (status === 'offline') {
      dispatch(
        Actions.setReconnectIntervalId(
          window.setInterval(() => {
            Toast(null, 'reconnecting', 'top-center', 4);
          }, 5000)
        )
      );
    }
  }, [status]);

  return null;
};

export default InternetStatusIndicator;
