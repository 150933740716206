import styled from 'styled-components';
import { device } from 'modules/Utils/Devices';
import { ZIndexes } from 'modules/Utils/ZIndexes';

export const Container = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;
`;

export const Dropdown = styled.div`
  position: absolute;
  width: 24rem;
  top: 4.8rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${ZIndexes.overlay + 1};
  background: ${(props) => props.theme.config.colors.light};
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: ${ZIndexes.overlay + 3};
  min-height: fit-content;

  &::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: ${(props) => props.theme.config.colors.light};
    position: absolute;
    top: -4px;
    left: 50%;
    transition: background-color 0.2s ease;
    transform: rotate(45deg);
    z-index: -1;
  }

  @media ${device.tabletAndMobile} {
    top: 70px;
  }
`;

export const Confirm = styled.div`
  padding: 2.4rem;
  text-align: center;

  h4 {
    color: ${(props) => props.theme.config.colors.primary};
    ${(props) => props.theme.config.typograph.default};
    font-weight: bold;
    margin-top: 0.4rem;
  }

  p {
    color: ${(props) => props.theme.config.colors.text_base};
    ${(props) => props.theme.config.typograph.mico};
    margin-top: 0.8rem;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${(props) => props.theme.config.colors.gray_300};
  margin-top: 2.4rem;
  padding-top: 1.6rem;

  button {
    min-width: 7.2rem;
  }
`;
