import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { getUser } from 'modules/Auth/Services';

const SUPORTED_LANGUAGES = ['pt', 'en', 'es'];

const loggedUser = getUser();

const TRANSLATION_CONFIG = {
  fallbackLng:
    loggedUser?.language || process.env.REACT_APP_DEFAULT_LANGUAGE || 'en',
  lng: loggedUser?.language || process.env.REACT_APP_DEFAULT_LANGUAGE || 'en',
  debug: process.env.NODE_ENV === 'development',
  compatibilityJSON: 'v2',
  supportedLngs: SUPORTED_LANGUAGES,
  whitelist: SUPORTED_LANGUAGES,
  ns: ['translation'],
  defaultNS: 'translation',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
};

export default {
  init(callback) {
    i18n
      .use(Backend)
      .use(LanguageDetector)
      .use(initReactI18next)
      .init(TRANSLATION_CONFIG)
      .then(() => callback());
  },
};
