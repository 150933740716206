const DEFAULT_FILTERS_KEYS = [
  'search',
  'test_id',
  'category_id',
  'page',
  'paginates_per',
  'start_date',
  'end_date',
];

const getParsedFiltersByType = (filters, resultsType) => {
  switch (resultsType) {
    case 'group':
      return parseGroupResultsFilters(filters);
    case 'unit':
      return parseUnitResultsFilters(filters);
    case 'school':
      return parseSchoolResultsFilters(filters);
    case 'class':
      return parseClassResultsFilters(filters);
    case 'student':
      return parseStudentResultsFilters(filters);
    default:
      return {};
  }
};

const parseGroupResultsFilters = (filters) => {
  return {
    group_id: filters?.group_id,
    ...getDefaultFiltersValues(filters),
  };
};

const parseUnitResultsFilters = (filters) => {
  return {
    group_id: filters?.group_id,
    unit_id: filters?.unit_id,
    ...getDefaultFiltersValues(filters),
  };
};

const parseSchoolResultsFilters = (filters) => {
  return {
    group_id: filters?.group_id,
    unit_id: filters?.unit_id,
    school_id: filters?.school_id,
    ...getDefaultFiltersValues(filters),
  };
};

const parseClassResultsFilters = (filters) => {
  return {
    group_id: filters?.group_id,
    unit_id: filters?.unit_id,
    school_id: filters?.school_id,
    class_id: filters?.class_id,
    ...getDefaultFiltersValues(filters),
  };
};

const parseStudentResultsFilters = (filters) => {
  return {
    test_id: filters?.test_id,
    ...getDefaultFiltersValues(filters),
  };
};

const getDefaultFiltersValues = (filters) => {
  return DEFAULT_FILTERS_KEYS.reduce((prev, cur) => {
    prev[cur] = filters[cur];
    return prev;
  }, {});
};

export const parseFiltersByType = (filters, resultsType) => {
  if (!filters || !resultsType) return {};

  return getParsedFiltersByType(filters, resultsType);
};
