import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ center }) =>
    !!center &&
    css`
      align-items: center;
      justify-content: center;
      text-align: center;
    `}

  svg {
    path {
      fill: ${(props) => props.theme.config.colors[props.iconColor]};
    }
  }

  p {
    margin-top: ${(props) => props.theme.config.spacing.spacing_5};
    color: ${(props) => props.theme.config.colors.gray};
  }
`;
