import { useEffect, useState } from 'react';

export function useBottomScroll(defaultOffset, maxOffset) {
  const [bottom, setBottom] = useState(defaultOffset);

  const handleScroll = (e) => {
    const target = e.target;

    const windowHeight = target.scrollHeight;
    const scrollY = target.scrollTop + target.offsetHeight;
    const scrollLimit = windowHeight - maxOffset;
    const diff = (scrollLimit - scrollY) * -1;
    const newFixedPosition = diff < 0 ? defaultOffset : defaultOffset + diff;

    setBottom(newFixedPosition);
  };

  useEffect(() => {
    const body = document.querySelector('body');
    body?.addEventListener('scroll', handleScroll);
    return () => {
      body?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return { bottom };
}
