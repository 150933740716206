import styled from 'styled-components';
import { Row } from 'modules/Core/Common';

const TitleRow = styled(Row)`
  margin-bottom: ${(props) => props.theme.config.spacing.spacing_5};
  align-items: ${(props) =>
    props.align && props.align === 'center' ? 'center' : 'stretch'};
`;

export { TitleRow };
