import styled, { css } from 'styled-components/macro';
import { device } from 'modules/Utils/Devices';

const AuthForm = styled.form`
  position: relative;
  width: 100%;
  max-width: 370px;
  margin-top: 0;

  ${(props) =>
    props.forgot &&
    css`
      margin-top: ${(props) => props.theme.config.spacing.spacing_4};
    `}

  input {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: ${(props) => props.theme.config.spacing.spacing_4};

  button {
    width: 100%;
  }

  ${(props) =>
    props.forgot &&
    css`
      margin-top: ${(props) => props.theme.config.spacing.spacing_5};
      justify-content: center;
    `}

  @media ${device.tabletAndMobile} {
    button {
      ${(props) => props.theme.config.typograph.default};
      justify-content: center;
      margin: 0 auto;
    }
  }

  @media ${device.mobile} {
    ${(props) =>
      !props.forgot &&
      css`
        margin-top: 7.2rem;
      `}
  }

  @media screen and (max-height: 570px) {
    position: static;
    transform: none;
    margin: 0 auto;
  }
`;

export { AuthForm, ButtonContainer };
