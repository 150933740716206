import React, { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import {
  DndContext,
  useSensors,
  useSensor,
  PointerSensor,
  DragOverlay,
} from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import { arrayMove } from '@dnd-kit/sortable';

import { Grid, Row, Col, UnsavedChangesModal } from 'modules/Core/Common';
import {
  WidgetsListHeader,
  WidgetsSidebar,
} from 'modules/Dashboard/Widgets/Common';
import { WidgetsList } from './WidgetsList.component';
import { useAuth } from 'modules/Auth/Hooks';
import { useWidgets } from 'modules/Dashboard/Widgets/Hooks';
import { isFalsy } from 'modules/Utils';
import { WIDGETS_LIST_DROPPABLE_CONTAINER } from 'modules/Dashboard/Widgets/WidgetsDragAndDrop.utils';
import { SortableWidgetContainerItem } from './SortableWidgetContainerItem.component';

export const WidgetsListContainer = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [activeDragItemId, setActiveDragItemId] = useState(null);
  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] =
    useState(false);
  const [nextLocation, setNextLocation] = useState();

  const history = useHistory();

  const { userWidgets } = useAuth();
  const {
    state,
    setActiveWidgets,
    addActiveWidget,
    removeActiveWidget,
    setUnsavedChanges,
    saveWidgets,
  } = useWidgets();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  useEffect(() => {
    setActiveWidgets(userWidgets?.widgets);
  }, []);

  const handleDragStart = (event) => {
    const { active } = event;

    setIsDragging(true);
    setActiveDragItemId(active.id);
  };

  const handleDragEnd = (event) => {
    setIsDragging(false);
    setActiveDragItemId(null);

    const { active, over } = event;

    if (typeof active.id === 'number' && typeof over?.id === 'number') {
      if (active.id === over.id) return;

      const activeIndex = state?.data?.activeWidgets?.findIndex(
        (widget) => widget.id === active.id
      );
      const overIndex = state?.data?.activeWidgets?.findIndex(
        (widget) => widget.id === over.id
      );

      setActiveWidgets(
        arrayMove(state?.data?.activeWidgets, activeIndex, overIndex)
      );
      setUnsavedChanges(true);
    }

    if (over?.id === WIDGETS_LIST_DROPPABLE_CONTAINER) {
      const { active } = event;

      if (isFalsy(active.data.current)) return;

      addActiveWidget(active.data.current);
    }
  };

  const activeDragItemWidget = state?.data?.activeWidgets?.find(
    (widget) => widget.id === activeDragItemId
  );

  const blockTransition = (nextRoute) => {
    if (!state?.unsavedChanges) return true;

    const { pathname } = nextRoute;

    setIsUnsavedChangesModalOpen(true);
    setNextLocation(pathname);

    return false;
  };

  const handleConfirmNavigate = () => {
    setUnsavedChanges(false);
    setIsUnsavedChangesModalOpen(false);
    setTimeout(() => history.push(nextLocation), 100);
  };

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      sensors={sensors}
      modifiers={[restrictToWindowEdges]}
    >
      <Grid fluid>
        <WidgetsListHeader
          onAddWidget={() => setIsSidebarOpen(true)}
          loading={state?.loading}
          onSaveDashboard={saveWidgets}
        />
        <Row>
          <Col xs={12}>
            <WidgetsList
              activeWidgets={state?.data?.activeWidgets}
              activeDragItemWidget={activeDragItemWidget}
              isDragging={isDragging}
            />
          </Col>
        </Row>
        <WidgetsSidebar
          isOpen={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          activeWidgets={state?.data?.activeWidgets}
          onAddActiveWidget={addActiveWidget}
          onRemoveActiveWidget={removeActiveWidget}
          isDragging={isDragging}
        />
      </Grid>
      <DragOverlay>
        {activeDragItemId && activeDragItemWidget ? (
          <SortableWidgetContainerItem widget={activeDragItemWidget} />
        ) : null}
      </DragOverlay>
      <Prompt when={state?.unsavedChanges} message={blockTransition} />
      <UnsavedChangesModal
        isOpen={isUnsavedChangesModalOpen}
        onClose={() => setIsUnsavedChangesModalOpen(false)}
        onConfirm={handleConfirmNavigate}
      />
    </DndContext>
  );
};
