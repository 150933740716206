import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { withTheme } from 'styled-components';

const Head = (props) => (
  <Helmet>
    <title>{props.theme.config.info.title}</title>
    <meta property="og:locale" content="en_US" />
    <meta property="og:url" content="https://edusynch.com" />
    <meta
      property="og:title"
      content="EduSynch - Online Testing and Remote Proctoring Made Easy for Institutions"
    />
    <meta property="og:site_name" content="EduSynch" />
    <meta
      property="og:description"
      content="Affordable and Scalable Online Testing and Remote Proctoring Made Easy"
    />
    <meta
      property="og:image"
      content="https://edusynch.com/assets/images/og-image.png"
    />
    <meta property="og:image:type" content="image/png" />
    <meta property="og:image:width" content="600" />
    <meta property="og:image:height" content="315" />
    <meta property="og:type" content="website" />
    <meta property="og:author" content="Dokimi Inc." />
  </Helmet>
);

Head.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(Head);
