import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

const Loader = ({ width, borderWidth, text, ...rest }) => (
  <S.Container {...rest}>
    <S.Loader width={width} borderWidth={borderWidth} />
    <S.Text text={text}>{text}</S.Text>
  </S.Container>
);

Loader.propTypes = {
  width: PropTypes.string,
  borderWidth: PropTypes.string,
  text: PropTypes.string,
};

export { Loader };
