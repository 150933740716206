import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import i18n from 'i18next';

import {
  Modal,
  Button,
  MUIDatePicker,
  MUIRadioGroup,
  FormGroup,
} from 'modules/Core/Common';
import * as S from './ModalExport.styles';
import {
  ArrowDownIcon,
  CloseIcon,
  ReportIcon,
} from '@edusynch/edusynch-svg-icons';

const ModalExport = ({
  maxHeight,
  maxWidth,
  isOpen,
  exportFilter,
  setIsOpen,
  noDates,
  customSection,
  advancedComponent,
  disabledSubmit,
  loading,
  onChange,
  onSubmit,
  modalBodyComponent,
  exportOptionsInnerComponent,
  onCloseModal,
}) => {
  const theme = useTheme();
  const [showAdvanced, setShowAdvanced] = useState(false);

  const handleChange = (param, value) => {
    onChange(param, value);
  };

  return (
    <Modal
      width="500"
      maxHeight={maxHeight}
      background={theme.config.colors.light}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      offCloseOnOverlayClick
      onCloseModal={onCloseModal}
    >
      <S.Container width={maxWidth}>
        <S.ModalHeader>
          <S.Flex>
            <S.IconBox>
              <ReportIcon
                color={theme.config.colors.primary}
                type="large"
                width={26}
              />
            </S.IconBox>
            <S.Title>{i18n.t('common-words.export')}</S.Title>
          </S.Flex>
          <S.Close onClick={setIsOpen}>
            <CloseIcon color={theme.config.colors.text_base} />
          </S.Close>
        </S.ModalHeader>

        <S.ScroolBox>
          {customSection && <S.CustomSection>{customSection}</S.CustomSection>}

          <S.ModalBody>
            {!noDates && (
              <S.Flex spaceBetween>
                <S.DateCard>
                  <S.DateLabel>Start Date</S.DateLabel>
                  <MUIDatePicker
                    placeholder={i18n.t('common-words.date')}
                    inputClick
                    inputTransparent
                    textFieldProps={{
                      placeholder: i18n.t('common-words.date'),
                    }}
                    date={exportFilter?.startDate}
                    onChange={(newValue) =>
                      newValue && handleChange('startDate', newValue)
                    }
                  />
                </S.DateCard>

                <S.DateCard>
                  <S.DateLabel>
                    {i18n.t('commons.modal-export.end-date')}
                  </S.DateLabel>
                  <MUIDatePicker
                    placeholder={i18n.t('common-words.prep-to')}
                    inputClick
                    inputTransparent
                    textFieldProps={{
                      placeholder: i18n.t('common-words.prep-to'),
                    }}
                    date={exportFilter?.endDate}
                    onChange={(newValue) =>
                      newValue && handleChange('endDate', newValue)
                    }
                  />
                </S.DateCard>
              </S.Flex>
            )}

            <S.ExportOptions>
              {exportOptionsInnerComponent && (
                <>{exportOptionsInnerComponent}</>
              )}
              <FormGroup style={{ paddingBottom: '0.8rem' }}>
                <MUIRadioGroup
                  row
                  value={exportFilter?.extension}
                  data={[
                    {
                      value: 'csv',
                      label: i18n.t('commons.modal-export.export-csv'),
                    },
                    {
                      value: 'xls',
                      label: i18n.t('commons.modal-export.export-xls'),
                    },
                  ]}
                  onChange={(e) => handleChange('extension', e.target.value)}
                />
              </FormGroup>
            </S.ExportOptions>

            {modalBodyComponent && <>{modalBodyComponent}</>}
          </S.ModalBody>
        </S.ScroolBox>

        <S.ModalFooter>
          <S.FooterContainer
            spaceBetween
            flexEnd={!advancedComponent}
            column={showAdvanced}
          >
            {advancedComponent && (
              <>
                <S.Toggl
                  showAdvanced={showAdvanced}
                  onClick={() => setShowAdvanced(!showAdvanced)}
                >
                  {i18n.t('commons.modal-export.advanced')}
                  <ArrowDownIcon />
                </S.Toggl>

                {showAdvanced && <S.Advanced>{advancedComponent}</S.Advanced>}
              </>
            )}

            <Button
              large
              className="submit-button"
              type="submit"
              loading={loading}
              hasLoading
              disabled={disabledSubmit}
              onClick={onSubmit}
            >
              {i18n.t('commons.modal-export.generate')}
            </Button>
          </S.FooterContainer>
        </S.ModalFooter>
      </S.Container>
    </Modal>
  );
};

ModalExport.propTypes = {
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func,
  exportFilter: PropTypes.object.isRequired,
  noDates: PropTypes.bool,
  customSection: PropTypes.node,
  advancedComponent: PropTypes.node,
  disabledSubmit: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  modalBodyComponent: PropTypes.node,
  exportOptionsInnerComponent: PropTypes.node,
};

export default ModalExport;
