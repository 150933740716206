import React from 'react';
import PropTypes from 'prop-types';
import * as S from './Tab.styles';
import { ButtonToggleContext } from './ButtonToggleContext';
import cx from 'classnames';

const Tab = ({
  title,
  nameView1,
  nameView2,
  nameView3,
  disabled,
  className,
  customOnClick,
}) => {
  const button = React.useContext(ButtonToggleContext);
  const cssTabClass = cx('toggle-active', className);

  const handleClick = (tab) => {
    button.setToggleButtonTab(tab);
    customOnClick && customOnClick(tab);
  };

  return (
    <>
      <S.Title>{title}</S.Title>
      <S.ToggleButtonsSettings className={className}>
        {nameView1 && (
          <button
            onClick={() => handleClick('tab1')}
            className={button.toggleButtonTab === 'tab1' ? cssTabClass : ''}
            disabled={disabled}
          >
            {nameView1}
          </button>
        )}
        {nameView2 && (
          <button
            onClick={() => handleClick('tab2')}
            className={button.toggleButtonTab === 'tab2' ? cssTabClass : ''}
            disabled={disabled}
          >
            {nameView2}
          </button>
        )}
        {nameView3 && (
          <button
            onClick={() => handleClick('tab3')}
            className={button.toggleButtonTab === 'tab3' ? cssTabClass : ''}
            disabled={disabled}
          >
            {nameView3}
          </button>
        )}
      </S.ToggleButtonsSettings>
    </>
  );
};

Tab.propTypes = {
  title: PropTypes.string,
  nameView1: PropTypes.string,
  nameView2: PropTypes.string,
  nameView3: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  customOnClick: PropTypes.func,
};

export default Tab;
