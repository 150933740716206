import { createSlice } from '@reduxjs/toolkit';
import {
  generateCancelToken,
  cancelRequests,
  isCancel,
  request,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  LIVE_TESTS_URL,
  LIVE_TESTS_CREATE_URL,
  LIVE_TESTS_DELETE_URL,
  LIVE_TESTS_UPDATE_URL,
} from '../../Api/Routes';
import { createLiveTestsModel } from '../LiveTests/Utils/LiveTestsUtils';
let cancelToken;

const initialState = {
  loading: false,
  error: null,
  data: {
    liveTests: null,
    page: 1,
    perPage: 10,
    search: '',
    groupId: null,
    unitId: null,
    schoolId: null,
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
  isDeleting: false,
};

const liveTestsSlice = createSlice({
  name: 'liveTests',
  initialState,
  reducers: {
    clearState: () => ({ ...initialState }),
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    requestLiveTests: (state) => {
      state.loading = true;
      state.error = null;
    },
    receiveLiveTestsList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        liveTests: action.payload.live_tests,
        total: action.payload.total_items,
      };
    },
    clearLiveTestsList: (state) => {
      state.data = {
        page: 1,
        perPage: 10,
        search: '',
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
    },
    receiveLiveTestsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changeLiveTestsPage: (state, action) => {
      state.data.page = action.payload;
    },
    changeLiveTestsFilters: (state, action) => {
      state.data.search = action.payload.search;
      state.data.groupId = action.payload.group_id || null;
      state.data.unitId = action.payload.unit_id || null;
      state.data.schoolId = action.payload.school_id || null;
      state.data.page = Number(action?.payload?.page) || 1;
      state.data.perPage =
        Number(action?.payload?.paginates_per) || state.data.perPage;
    },
    clearLiveTestsFilters: (state) => {
      state.data.search = '';
      state.data.page = 1;
      state.data.groupId = null;
      state.data.unitId = null;
      state.data.schoolId = null;
      state.data.sort.sortType = null;
      state.data.sort.sortBy = null;
    },
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    setIsDeleting: (state, action) => {
      state.isDeleting = action.payload;
    },
  },
});

const Actions = liveTestsSlice.actions;

const Selectors = {
  fetchListData: (state) => state.liveTests,
  liveTestsLoading: ({ liveTests: { loading } }) => ({ loading }),
};

const Async = {
  fetchLiveTestsList: () => async (dispatch, getState) => {
    const {
      liveTests: {
        data: {
          page,
          perPage,
          search,
          groupId,
          unitId,
          schoolId,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;
    dispatch(Actions.requestLiveTests());
    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: LIVE_TESTS_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          sort: sortType,
          sort_by: sortBy,
          group_id: groupId,
          school_id: schoolId,
          unit_id: unitId,
        },
      });

      action = Actions.receiveLiveTestsList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveLiveTestsError(e.message);
      }
    }

    action && dispatch(action);
  },

  createLiveTest:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      try {
        const liveTestData = createLiveTestsModel(data);
        delete liveTestData.id;

        const response = await request({
          method: 'POST',
          url: LIVE_TESTS_CREATE_URL,
          data: liveTestData,
        });
        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);

        dispatch(Async.fetchLiveTestsList());
      } catch (e) {
        dispatch(Actions.receiveLiveTestsError());
        onError(e);
      }
    },

  updateLiveTest:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      try {
        const liveTestData = createLiveTestsModel(data);

        const response = await request({
          method: 'PUT',
          url: LIVE_TESTS_UPDATE_URL,
          data: liveTestData,
        });
        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);

        dispatch(Async.fetchLiveTestsList());
      } catch (e) {
        dispatch(Actions.receiveLiveTestsError());
        onError(e);
      }
    },

  deleteLiveTest:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.setIsDeleting(true));
      try {
        const response = await request({
          method: 'DELETE',
          url: `${LIVE_TESTS_DELETE_URL}/?id=${id}`,
        });
        onSuccess(response);

        dispatch(Async.fetchLiveTestsList());
      } catch (e) {
        onError(e);
      } finally {
        dispatch(Actions.setIsDeleting(false));
      }
    },
};

const { reducer } = liveTestsSlice;

export { reducer, Actions, Async, Selectors };
