import styled, { css } from 'styled-components';

export const CropModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  background: ${(props) => props.theme.config.colors.text_base};
  bottom: 0;
  right: 0;
`;

export const CropContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 70rem;
  width: 100%;
  margin-top: ${(props) => props.theme.config.spacing.layout_6};

  img {
    max-height: 40rem;
  }
`;

export const CropButtonContainer = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  margin-top: ${(props) => props.theme.config.spacing.spacing_4};

  & + button {
    margin-left: ${(props) => props.theme.config.spacing.layout_7};
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

const getButtonPropsByType = (props) => {
  switch (props.type) {
    case 'save':
      return css`
        background: ${props.theme.config.colors.green};

        svg path {
          fill: ${props.theme.config.colors.light};
        }
      `;
    case 'cancel':
      return css`
        background: ${props.theme.config.colors.light};

        svg path {
          fill: ${props.theme.config.colors.text_base};
        }
      `;
    default:
      return '';
  }
};

export const CropButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  margin-right: ${(props) => props.theme.config.spacing.spacing_2};

  ${(props) => getButtonPropsByType(props)}
`;

export const CropText = styled.span`
  ${(props) => props.theme.config.typograph.mico};
  color: ${(props) => props.theme.config.colors.light};
`;
