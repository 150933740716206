import React from 'react';
import PropTypes from 'prop-types';
import {
  ActiveUsersContainer,
  RegisteredUsersContainer,
} from 'modules/Dashboard/Widgets/Users';
import {
  EProctoringEventsAnalyticsContainer,
  SessionsCountContainer,
} from 'modules/Dashboard/Widgets/EProctoring';
import { CompletionRateContainer } from 'modules/Dashboard/Widgets/CompletionRate';
import { WidgetNotFound } from './WidgetNotFound.component';

import {
  ACTIVE_USERS,
  REGISTERED_USERS,
  EPROCTORING_EVENTS_ANALYTICS_BASE_TESTS,
  EPROCTORING_EVENTS_ANALYTICS_CUSTOM_TESTS,
  EPROCTORING_SESSIONS_COUNT,
  EPROCTORING_EVENTS_ANALYTICS_PLUGIN,
  COMPLETION_RATE_BASE_TESTS,
  COMPLETION_RATE_CUSTOM_TESTS,
  COMPLETION_RATE_PLUGIN,
} from './Widgets.types';

export const WidgetByType = ({ widget, ...props }) => {
  const widgetsTypes = {
    [ACTIVE_USERS]: ActiveUsersContainer,
    [REGISTERED_USERS]: RegisteredUsersContainer,
    [EPROCTORING_EVENTS_ANALYTICS_BASE_TESTS]:
      EProctoringEventsAnalyticsContainer,
    [EPROCTORING_EVENTS_ANALYTICS_CUSTOM_TESTS]:
      EProctoringEventsAnalyticsContainer,
    [EPROCTORING_SESSIONS_COUNT]: SessionsCountContainer,
    [EPROCTORING_EVENTS_ANALYTICS_PLUGIN]: EProctoringEventsAnalyticsContainer,
    [COMPLETION_RATE_BASE_TESTS]: CompletionRateContainer,
    [COMPLETION_RATE_CUSTOM_TESTS]: CompletionRateContainer,
    [COMPLETION_RATE_PLUGIN]: CompletionRateContainer,
  };

  const WidgetComponent = widgetsTypes[widget?.type];

  if (!WidgetComponent) {
    return <WidgetNotFound />;
  }

  return <WidgetComponent widget={widget} {...props} />;
};

WidgetByType.propTypes = {
  widget: PropTypes.object,
};
