import { lazy } from 'react';
import { menuBuilder } from 'services/Routes.service';
import { MoneyIcon } from '@edusynch/edusynch-svg-icons';

const PurchasesContainer = lazy(() =>
  import('modules/Purchases/PurchasesList/PurchasesList.container')
);

const SubscriptionsContainer = lazy(() =>
  import('modules/Subscriptions/SubscriptionsList/SubscriptionsList.container')
);

const DiscountsContainer = lazy(() =>
  import('modules/Discounts/DiscountsList/DiscountsList.container')
);

const SubscriptionPurchasesContainer = lazy(() =>
  import(
    'modules/Subscriptions/SubscriptionPurchases/SubscriptionPurchasesList/SubscriptionPurchasesList.container'
  )
);

const CancelFeedbackContainer = lazy(() =>
  import(
    'modules/CancelFeedback/CancelFeedbackList/CancelFeedbackList.container'
  )
);

const PurchasesMenu = menuBuilder([
  {
    Icon: MoneyIcon,
    name: 'Purchases',
  },
]).addSubmenu([
  {
    Icon: MoneyIcon,
    name: 'Purchases',
    path: '/purchases',
    exact: true,
    component: PurchasesContainer,
    ability: 'purchases',
  },
  {
    Icon: MoneyIcon,
    name: 'Subscriptions',
    path: '/subscriptions',
    exact: true,
    component: SubscriptionsContainer,
    ability: 'subscriptions',
  },
  {
    name: 'Subscriptions',
    component: SubscriptionPurchasesContainer,
    path: '/subscriptions/:id',
    hidden: true,
    ability: 'subscriptions',
  },
  {
    Icon: MoneyIcon,
    name: 'Discounts',
    path: '/discounts',
    exact: true,
    component: DiscountsContainer,
    ability: 'discounts',
  },
  {
    name: 'Cancel Feedback',
    path: '/cancel-feedback',
    exact: true,
    component: CancelFeedbackContainer,
    ability: 'subscriptions',
  },
]);

export default PurchasesMenu;
