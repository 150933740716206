import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'modules/Core/Common/Modal';
import StudentLicenseForm from 'modules/Users/Students/StudentLicenseForm';

const ModalStudentLicense = ({
  studentId,
  studentLicense,
  isOpen,
  setIsOpen,
  onSubmit,
}) => (
  <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
    <StudentLicenseForm
      studentId={studentId}
      studentLicense={studentLicense}
      onSubmit={onSubmit}
      btnCloseClick={setIsOpen}
    />
  </Modal>
);

ModalStudentLicense.propTypes = {
  studentLicense: PropTypes.object,
  studentId: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ModalStudentLicense;
