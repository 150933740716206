import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  svg {
    width: 1.6rem;
    z-index: 1;

    &::before {
      transition: 0.2s ease-in;
    }

    &:first-child {
      margin-right: 0.8rem;
    }
  }

  .MuiTextField-root {
    margin-top: 0;
  }

  .MuiOutlinedInput-root {
    height: 48px;

    .MuiOutlinedInput-input {
      ${(props) => props.theme.config.typograph.default};
      color: ${(props) => props.theme.config.colors.gray_400};
      z-index: 1;

      &::placeholder {
        color: transparent;
      }

      &:-ms-input-placeholder {
        color: transparent;
      }

      &::-ms-input-placeholder {
        color: transparent;
      }
    }

    &.Mui-error .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) =>
        props.borders
          ? props.theme.config.colors.gray_300
          : props.theme.config.colors.light};
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) =>
        props.borders
          ? props.theme.config.colors.gray_300
          : props.theme.config.colors.light};
      background-color: ${(props) =>
        props.inputTransparent
          ? 'transparent'
          : props?.disabled
          ? props.theme.config.colors.gray_200
          : props.theme.config.colors.light};
      border-radius: 0.6rem;
    }

    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${(props) =>
          props.borders
            ? props.theme.config.colors.gray_300
            : props.theme.config.colors.light};
      }

      svg {
        &:last-child {
          path {
            fill: ${(props) => props.theme.config.colors.primary};
          }
        }
      }
    }

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${(props) =>
          props.borders
            ? props.theme.config.colors.gray_300
            : props.theme.config.colors.light};
        border-width: 1px;
      }
    }
  }
`;

export const Placeholder = styled.span`
  ${(props) => props.theme.config.typograph.default};
  color: ${(props) => props.theme.config.colors.gray_400};
  position: absolute;
  top: 25%;
  left: 43px;
  pointer-events: none;
  z-index: 1;
`;

export const FloatingPlaceholder = styled.div`
  position: absolute;
  left: 1.6rem;
  top: -0.7rem;
  z-index: 1;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.15s ease-in-out;

  span {
    position: relative;
    font-size: 1.2rem;
    line-height: 14px;
    color: ${(props) => props.theme.config.colors.gray_400};
    z-index: 1;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -0.1rem;
    width: 100%;
    height: 1rem;
    z-index: 0;
    background-color: ${(props) =>
      props?.isDisabled
        ? props.theme.config.colors.gray_200
        : props.theme.config.colors.light};
  }
`;

export const ArrowDown = styled.div`
  cursor: default;
  position: absolute;
  right: 2px;
  z-index: 1;
  height: 24px;
`;
