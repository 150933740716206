export const STATUS_APPROVED = 'approved';
export const STATUS_ACTIVE = 'active';
export const STATUS_PENDING = 'pending';
export const STATUS_INCOMPLETE = 'incomplete';
export const STATUS_INCOMPLETE_EXPIRED = 'incomplete_expired';
export const STATUS_TRIAL = 'trial';
export const STATUS_TRIALING = 'trialing';
export const STATUS_PAST_DUE = 'past_due';
export const STATUS_UNPAID = 'unpaid';
export const STATUS_PAUSED = 'paused';
export const STATUS_CANCELED = 'canceled';

export const PERIOD_MONTHLY = 'Monthly';
export const PERIOD_QUARTERLY = 'Quarterly';
export const PERIOD_SEMIANNUAL = 'Semiannual';

export const subscriptionPeriods = [
  PERIOD_MONTHLY,
  PERIOD_QUARTERLY,
  PERIOD_SEMIANNUAL,
];
export const subscriptionStatus = [
  STATUS_ACTIVE,
  STATUS_TRIAL,
  STATUS_CANCELED,
];

export const getPeriodName = (period) => {
  const periodMap = {
    1: PERIOD_MONTHLY,
    3: PERIOD_QUARTERLY,
    6: PERIOD_SEMIANNUAL,
    default: PERIOD_MONTHLY,
  };

  const finalPeriod = periodMap[period] || periodMap.default;
  return finalPeriod.toLowerCase();
};
