import styled from 'styled-components';
import { device } from 'modules/Utils';

export const Container = styled.div`
  background-color: ${(props) => props.theme.config.colors.primary_100};
  padding: 1.6rem 1.6rem 0.6rem 1.6rem;
  border-radius: 0.6rem;
  margin-bottom: 4rem;

  input:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const Separator = styled.div`
  height: 0.1rem;
  background-color: ${(props) => props.theme.config.colors.gray_300};
  margin: 1.6rem 0;
`;

export const Label = styled.span`
  ${(props) => props.theme.config.typograph.mico};
  color: ${(props) => props.theme.config.colors.gray_400};
  padding-right: 1.6rem;
  line-height: unset;
`;

export const SearchValue = styled.span`
  display: inline-flex;
  align-items: center;
  ${(props) => props.theme.config.typograph.mico};
  line-height: unset;
  background-color: ${(props) => props.theme.config.colors.primary_400};
  color: ${(props) => props.theme.config.colors.light};
  border-radius: 0.4rem;
  padding: 0.8rem;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${(props) => props.theme.config.colors.primary};
  }

  svg:not(.ExclamationIcon) {
    width: 0.8rem;
    height: 0.8rem;
    margin-left: 0.8rem;
    cursor: pointer;
  }
`;

export const Filters = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 0.8rem 0.4rem;
`;

export const FilterValue = styled.span`
  display: inline-flex;
  align-items: center;
  ${(props) => props.theme.config.typograph.mico};
  line-height: unset;
  background-color: ${(props) => props.theme.config.colors.secondary_200};
  color: ${(props) => props.theme.config.colors.light};
  border-radius: 0.4rem;
  padding: 0.8rem;
  transition: background-color 0.2s;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.config.colors.secondary_300};
  }

  span {
    font-weight: bold;
    padding-right: 0.4rem;
  }

  svg:not(.ExclamationIcon) {
    width: 0.8rem;
    height: 0.8rem;
    margin-left: 0.8rem;
    cursor: pointer;
  }
`;

export const FilterValueContent = styled.div`
  position: relative;
`;

export const SelectedFilters = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0 4rem;
  margin-bottom: 1rem;
`;

export const SearchContainer = styled.div``;

export const FiltersContainer = styled.div``;

export const FilterButtonContainer = styled.div`
  position: relative;
  width: 8.4rem;
`;

export const RangeDatePickerContainer = styled.div`
  width: 75rem;
  background-color: ${(props) => props.theme.config.colors.light};
  border-radius: 0.6rem;
  padding: 1.6rem 3.2rem;
  margin: 0 auto;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  right: 0;
  top: 4.8rem;
  z-index: 10;

  @media ${device.mobile} {
    width: 96vw;
    transform: translateX(0);
    left: -2.4rem;
    padding: 1.6rem 0.8rem;
  }
`;

export const SkeletonContainer = styled.div`
  display: inline-flex;

  .skeleton-element {
    background: ${(props) => props.theme.config.colors.primary_200};
  }
`;

export const ButtonSkeletonContainer = styled.div`
  display: inline-flex;
  height: 100%;

  .skeleton-element {
    background: ${(props) => props.theme.config.colors.primary_200};
    height: 100%;
  }
`;
