import styled from 'styled-components/macro';

const AvatarContainer = styled.div`
  display: flex;
  ${(props) =>
    props.size ? 'flex-direction: column; align-items: center;' : null};
  margin-top: ${(props) => props.theme.config.spacing.spacing_2};
  margin-bottom: ${(props) => props.theme.config.spacing.spacing_4};
`;

export const Label = styled.span`
  padding: 0;
  color: ${(props) => props.theme.config.colors.text_base};
  display: inline-block;
  ${(props) => props.theme.config.typograph.xsmall};
  font-weight: 300;
  ${(props) =>
    props.size
      ? 'margin-left: 6.5rem; margin-top: 2rem; font-weight: bold; margin-bottom: .8rem'
      : null};
`;

const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: ${(props) => (props.size ? '29.6rem' : '15rem')};
  height: ${(props) => (props.size ? '29.6rem' : '15rem')};
  padding: 1rem;
  background: ${(props) => props.theme.config.colors.primary_100};
  border-radius: 0.6rem;
  overflow: hidden;
  position: relative;
  border: ${(props) =>
    !props.hasImg
      ? `0.2rem dashed ${props.theme.config.colors.primary_200}`
      : 'none'};

  .editImageIcon {
    cursor: pointer;
    position: absolute;
    right: 4px;
    top: 4px;
    background-color: ${(props) => props.theme.config.colors.primary};
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      width: 1.2rem
      cursor: pointer;

      path {
        fill: ${(props) => props.theme.config.colors.light};
      }
    }
  }
`;

const AvatarText = styled.span`
  ${(props) => props.theme.config.typograph.mico};
  display: block;
  text-align: center;
  pointer-events: none;
  color: ${(props) => props.theme.config.colors.primary};
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.4rem;
`;

const AvatarPlaceholder = styled.img`
  display: block;
  width: 6rem;
  height: 6rem;
  padding: 0.5rem;
  opacity: 0.5;
  mix-blend-mode: normal;
  pointer-events: none;
  margin-bottom: ${(props) => props.theme.config.spacing.spacing_1};
`;

export { Avatar, AvatarText, AvatarContainer, AvatarPlaceholder };
