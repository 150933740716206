import styled, { css } from 'styled-components';

import { Button } from 'modules/Core/Common';

export const Container = styled.div`
  position: relative;
  max-height: 90vh;
  padding: 6rem 4rem;

  h2 {
    text-align: center;
    font-weight: 400;
    margin: 0 auto ${(props) => props.theme.config.spacing.spacing_6} auto;
  }

  button[type='submit'] {
    margin: ${(props) => props.theme.config.spacing.spacing_6} auto 0 auto;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 4px;
    background: ${(props) => props.theme.config.colors.primary};
    border-radius: 6px 6px 0px 0px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DialogButton = styled(Button)`
  background: transparent;
  color: ${(props) => props.theme.config.colors.primary};
  border: 1px solid ${(props) => props.theme.config.colors.primary};
  ${(props) => props.theme.config.typograph.xsmall};
  padding: 1.2rem 1.6rem;
  white-space: nowrap;

  &:hover,
  &:focus,
  &:active {
    color: ${(props) => props.theme.config.colors.light};
  }

  & + button {
    margin-left: ${(props) => props.theme.config.spacing.spacing_3};
  }

  ${({ highlight }) =>
    highlight &&
    css`
      background: ${(props) => props.theme.config.colors.primary};
      color: ${(props) => props.theme.config.colors.light};
    `};
`;

export const ButtonClose = styled.button`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 1.6rem;
  right: 1.6rem;
  cursor: pointer;
  text-align: center;
  background-color: transparent;
  border: none;
  outline: none;

  svg {
    path {
      fill: ${(props) => props.theme.config.colors.text_base};
    }
  }
`;
