import styled from 'styled-components';
import { MenuItem } from '@mui/material';

export const MUIMenuItem = styled(MenuItem)`
  &&& {
    font-size: 1.4rem;

    svg {
      margin-right: 0.8rem;
    }

    &:hover {
      color: ${(props) => props.theme.config.colors.primary};

      svg path {
        fill: ${(props) => props.theme.config.colors.primary};
      }
    }
  }
`;
