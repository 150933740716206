import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { Can } from 'hoc/Ability';
import { Tooltip, ConfirmPopover } from 'modules/Core/Common';
import { Container, Button, EmailsSent, EmailsSentNumber } from './styles';
import {
  EmailIcon,
  EditIcon,
  KeyIcon,
  DeleteIcon,
  SendEmailIcon,
  SendingEmailIcon,
} from '@edusynch/edusynch-svg-icons';
import { STUDENTS } from 'modules/Users/Utils';

const BUTTON_PREFIX = 'commons.buttons.actions-buttons';

const Actions = ({
  id,
  email,
  activation_mail_amount_sent,
  onCopyEmail,
  onEdit,
  onNewPassword,
  onDelete,
  onSendEmail,
  subject,
  isDeleting,
  isSendingEmail,
}) => {
  const isStudents = subject === STUDENTS;

  return (
    <Container>
      {isStudents && email && (
        <Tooltip
          placement="bottom"
          overlay={
            <div>
              <span>
                {isSendingEmail
                  ? i18n.t(`${BUTTON_PREFIX}.sending-email`)
                  : i18n.t(`${BUTTON_PREFIX}.send-email`)}
              </span>
              {!isSendingEmail && activation_mail_amount_sent && (
                <EmailsSent>
                  {i18n.t(`${BUTTON_PREFIX}.emails-sent`)}:{' '}
                  {activation_mail_amount_sent}
                </EmailsSent>
              )}
            </div>
          }
          secondaryTooltip={!isSendingEmail}
          overlayInnerStyle={{
            backgroundColor: '#0075EA',
            borderRadius: '4px',
            padding: '8px 16px',
            fontSize: '14px',
          }}
        >
          <Button
            isSendingEmail={isSendingEmail}
            onClick={() => onSendEmail(email)}
          >
            {!isSendingEmail && activation_mail_amount_sent && (
              <EmailsSentNumber>{activation_mail_amount_sent}</EmailsSentNumber>
            )}
            {isSendingEmail ? <SendingEmailIcon /> : <SendEmailIcon />}
          </Button>
        </Tooltip>
      )}

      {onCopyEmail && email && (
        <Tooltip
          placement="bottom"
          overlay={i18n.t(`${BUTTON_PREFIX}.copy-email`)}
          secondaryTooltip
          overlayInnerStyle={{
            backgroundColor: '#0075EA',
            borderRadius: '4px',
            padding: '8px 16px',
            fontSize: '14px',
          }}
        >
          <Button onClick={() => onCopyEmail(email)}>
            <EmailIcon />
          </Button>
        </Tooltip>
      )}

      <Can I="update" a={subject}>
        <Tooltip
          placement="bottom"
          overlay={i18n.t(`${BUTTON_PREFIX}.edit`)}
          secondaryTooltip
          overlayInnerStyle={{
            backgroundColor: '#0075EA',
            borderRadius: '4px',
            padding: '8px 16px',
            fontSize: '14px',
          }}
        >
          <Button onClick={() => onEdit(id)}>
            <EditIcon data-cy="edit-btn" />
          </Button>
        </Tooltip>
      </Can>

      {onNewPassword && (
        <Tooltip
          placement="bottom"
          overlay={i18n.t(`${BUTTON_PREFIX}.new-password`)}
          secondaryTooltip
          overlayInnerStyle={{
            backgroundColor: '#0075EA',
            borderRadius: '4px',
            padding: '8px 16px',
            fontSize: '14px',
          }}
        >
          <Button onClick={() => onNewPassword(id)}>
            <KeyIcon />
          </Button>
        </Tooltip>
      )}

      <Can I="destroy" a={subject}>
        <Tooltip
          placement="bottom"
          overlay={i18n.t(`${BUTTON_PREFIX}.delete`)}
          secondaryTooltip
          overlayInnerStyle={{
            backgroundColor: '#0075EA',
            borderRadius: '4px',
            padding: '8px 16px',
            fontSize: '14px',
          }}
        >
          <ConfirmPopover
            onConfirm={() => onDelete(id)}
            isLoading={isDeleting}
            subtitle="This action will delete this item."
          >
            <DeleteIcon data-cy="delete-btn" />
          </ConfirmPopover>
        </Tooltip>
      </Can>
    </Container>
  );
};

Actions.propTypes = {
  id: PropTypes.number,
  activation_mail_amount_sent: PropTypes.number,
  onEdit: PropTypes.func,
  onNewPassword: PropTypes.func,
  onDelete: PropTypes.func,
  onSendEmail: PropTypes.func,
  email: PropTypes.string,
  subject: PropTypes.string,
  onCopyEmail: PropTypes.func,
  isDeleting: PropTypes.bool,
  isSendingEmail: PropTypes.bool,
};

export default Actions;
