import { createSlice } from '@reduxjs/toolkit';
import { request } from 'modules/Api/HttpClientStudent';
import { MAINTENANTE_URL } from 'modules/Api/Routes';

const initialState = {
  error: null,
  closedByUser: false,
  scheduledMaintenance: false,
  maintenanceDescription: null,
};

const maintenanceSlice = createSlice({
  name: 'maintenance',
  initialState,
  reducers: {
    cleanState: () => ({ ...initialState }),
    changeClosedByUser: (state) => {
      state.closedByUser = true;
    },
    requestMaintenanceStatus: (state) => {
      state.scheduledMaintenance = false;
      state.maintenanceDescription = null;
      state.closedByUser = false;
    },
    receiveMaintenanceStatus: (state, action) => {
      state.closedByUser = false;
      state.scheduledMaintenance = action.payload.scheduled_maintenance;
      state.maintenanceDescription = action.payload.maintenance_description;
    },
    receiveMaintenanceStatusError: (state, action) => {
      state.error = action.payload;
    },
  },
});

const Actions = maintenanceSlice.actions;

const Selectors = {
  fetchMaintenance: (state) => state.maintenance,
};

const Async = {
  fetchMaintenanceStatus: () => async (dispatch) => {
    let action;

    dispatch(Actions.requestMaintenanceStatus());

    try {
      const response = await request({
        method: 'GET',
        url: MAINTENANTE_URL,
      });

      action = Actions.receiveMaintenanceStatus(response.data);
    } catch (e) {
      action = Actions.receiveMaintenanceStatusError(e.message);
    }

    action && dispatch(action);
  },
};

const { reducer } = maintenanceSlice;

export { reducer, Actions, Async, Selectors };
