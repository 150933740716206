import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { TablePagination } from 'modules/Core/Common';
import { UserAvatar } from 'modules/Users/Common';
import { Actions } from './Actions';
import i18n from 'i18next';

const ColumnAvatar = ({ id, name, email, avatar, onShowDropdown }) => {
  const cardRef = createRef(null);
  return (
    <UserAvatar
      id={id}
      name={name}
      email={email}
      avatar={avatar?.thumb?.url || null}
      onShowDropdown={onShowDropdown}
      cardRef={cardRef}
    >
      <UserAvatar.Photo showCursorPointer />
      <UserAvatar.Name />
      <UserAvatar.Card />
    </UserAvatar>
  );
};

ColumnAvatar.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  email: PropTypes.string,
  avatar: PropTypes.string,
  onShowDropdown: PropTypes.func,
};

const getColumns = (data, additional, onSort, hasName = true) => [
  hasName
    ? {
        title: i18n.t('common-words.name'),
        key: 'name',
        sort: data?.sort?.sortBy === 'name' ? data?.sort?.sortType : 'DESC',
        onSort: () => onSort('name'),
        render: ColumnAvatar,
      }
    : '',

  ...additional,

  {
    title: i18n.t('common-words.actions'),
    key: 'actions',
    sort: false,
    render: Actions,
  },
];

const UserTable = ({
  data,
  type,
  actions,
  toggleId,
  toggleProfileDropdownId,
  onNextPage,
  toggledChildren,
  additionalColumns,
  onToggleClick,
  onSort,
  hasName,
  tdFirstChildStyle,
}) => (
  <TablePagination
    data={data[type]}
    toggleId={toggleId}
    tdFirstChildStyle={tdFirstChildStyle}
    toggleProfileDropdownId={toggleProfileDropdownId}
    additionalData={actions}
    toggledChildren={toggledChildren}
    columns={getColumns(data, additionalColumns, onSort, hasName).filter(
      Boolean
    )}
    pagination={{
      total: data.total,
      perPage: data.perPage,
      page: data.page,
      search: data.search,
      startDate: data?.startDate,
      endDate: data?.endDate,
      onToggleClick: onToggleClick,
      onNextPage,
    }}
  />
);

UserTable.defaultProps = {
  additionalColumns: [],
};

UserTable.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  type: PropTypes.string,
  onNextPage: PropTypes.func,
  additionalColumns: PropTypes.array,
  actions: PropTypes.object,
  toggleId: PropTypes.number,
  toggleProfileDropdownId: PropTypes.number,
  toggledChildren: PropTypes.element,
  onToggleClick: PropTypes.func,
  tdFirstChildStyle: PropTypes.object,
  onSort: PropTypes.func,
  hasName: PropTypes.bool,
};

export default UserTable;
