import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Avatar, Icon, ImageCropper, Tooltip } from 'modules/Core/Common';
import { ButtonToggleContext } from './ButtonToggleContext';
import * as S from './AvatarProfile.styles';
import AvatarInput from './AvatarInput';
import i18n from 'i18next';

const IMAGE_CONTAINER_STYLES = {
  width: 'auto',
  height: '31.5rem',
};

const AvatarProfile = ({
  user,
  loading,
  onSaveImage,
  onRemoveImage,
  customOnClick,
  onToggleEdit,
}) => {
  const button = React.useContext(ButtonToggleContext);
  const [toggleEditImage, setToggleEditImage] = useState(false);
  const [isCropping, setIsCropping] = useState(false);
  const [userTypeEvaluator] = useState(user?.user_type === 'evaluator');

  const handleClick = (tab) => {
    button.setToggleButton(tab);
    customOnClick && customOnClick(tab);
  };

  const handleToggleEdit = () => {
    onToggleEdit();
    setToggleEditImage(true);
  };

  const DEFAULT_AVATAR_URL =
    user?.avatar?.scale?.url === 'avatar/scale_default_avatar.png';
  const AVATAR_URL =
    user?.avatar?.url !== 'avatar/default_avatar.png'
      ? user?.avatar_base64?.url
      : null;

  return (
    <>
      <Card height={isCropping ? '535px' : '507px'} padding="2.4rem">
        {toggleEditImage ? (
          <>
            <S.Title secondary isCropping={isCropping}>
              <h4>Edit Image</h4>
              {!isCropping && (
                <Tooltip
                  placement="top"
                  overlay="Back"
                  overlayInnerStyle={{
                    backgroundColor: '#0075EA',
                    borderRadius: '4px',
                    padding: '8px 16px',
                    fontSize: '1.6rem',
                  }}
                >
                  <button
                    type="button"
                    onClick={() => setToggleEditImage(false)}
                  >
                    <Icon name="chevron-left" />
                  </button>
                </Tooltip>
              )}
            </S.Title>
            <ImageCropper
              imageURL={AVATAR_URL}
              imageAlt="User Avatar"
              fileName={user?.avatar_base64?.filename}
              fileType={user?.avatar_base64?.type}
              uploadButtonTitle={i18n.t('commons.avatar-profile.button-upload')}
              onCropStart={() => setIsCropping(true)}
              onCropCancel={() => {
                setIsCropping(false);
                setToggleEditImage(false);
              }}
              onCropComplete={(file) =>
                onSaveImage(file, () => {
                  setIsCropping(false);
                  setToggleEditImage(false);
                })
              }
              onRemoveImage={() => {
                onRemoveImage(() => {
                  setToggleEditImage(false);
                });
              }}
              loading={loading}
              imageContainerStyles={IMAGE_CONTAINER_STYLES}
              cropContainerStyles={IMAGE_CONTAINER_STYLES}
              profileInfo
            />
          </>
        ) : (
          <>
            <S.Title>
              <h4>{i18n.t('commons.avatar-profile.title')}</h4>
            </S.Title>
            <S.AvatarProfile>
              <div
                className={i18n.t(
                  'modules.settings-profile.profile-nav.avatar.tabs.general'
                )}
              >
                <>
                  <div className="Image">
                    {!DEFAULT_AVATAR_URL ? (
                      <>
                        <div className="editImageIcon">
                          <Icon name="edit" onClick={handleToggleEdit} />
                        </div>
                        <Avatar
                          className="avatarSettingsProfile"
                          borderRadius={'4px'}
                          src={user?.avatar?.scale?.url}
                          name={user?.name}
                          isSquare
                          xlarge
                        />
                      </>
                    ) : (
                      <AvatarInput handleChooseImage={handleToggleEdit} />
                    )}
                  </div>
                  <div className="User">
                    <h4>{user?.name}</h4>
                    <span>{user?.email}</span>
                  </div>
                </>
              </div>
            </S.AvatarProfile>

            <S.ToggleButtonsSettings>
              <button
                onClick={() => handleClick('general')}
                className={
                  button.toggleButton === 'general' ? 'toggle-active' : ''
                }
              >
                {i18n.t(
                  'modules.settings-profile.profile-nav.avatar.tabs.general'
                )}
              </button>
              <button
                onClick={() => handleClick('password')}
                className={
                  button.toggleButton === 'password' ? 'toggle-active' : ''
                }
              >
                {i18n.t(
                  'modules.settings-profile.profile-nav.avatar.tabs.password'
                )}
              </button>
              {userTypeEvaluator && (
                <button
                  onClick={() => handleClick('intervals')}
                  className={
                    button.toggleButton === 'intervals' ? 'toggle-active' : ''
                  }
                >
                  {i18n.t(
                    'modules.settings-profile.profile-nav.avatar.tabs.intervals'
                  )}
                </button>
              )}
            </S.ToggleButtonsSettings>
          </>
        )}
      </Card>
    </>
  );
};

AvatarProfile.propTypes = {
  user: PropTypes.object,
  loading: PropTypes.bool,
  onSaveImage: PropTypes.func,
  onRemoveImage: PropTypes.func,
  setFieldValue: PropTypes.func,
  customOnClick: PropTypes.func,
  onToggleEdit: PropTypes.func,
};

export default AvatarProfile;
