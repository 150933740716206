import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import { Title, Icon } from 'modules/Core/Common';

import * as S from './UTMBar.styles';

export const UTMBar = ({ utms }) => {
  return (
    <S.Container>
      <Title
        tag="p"
        size="quartenary"
        color="primary"
        style={{ fontSize: '2rem' }}
      >
        {i18n.t('common-words.utm')}
      </Title>
      <S.Info>
        {utms?.length ? (
          utms?.map((utm) => (
            <p key={utm?.id}>
              <strong>{utm?.utm_type}: </strong>
              <span>{utm?.value}</span>
            </p>
          ))
        ) : (
          <Icon name="table-empty-data" style={{ margin: 'auto' }} />
        )}
      </S.Info>
    </S.Container>
  );
};

UTMBar.propTypes = {
  utms: PropTypes.array,
};
