import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTheme } from 'styled-components';

import * as S from './MUIRadioGroup.styles';

export const MUIRadioGroup = ({ data, row, value, onChange }) => {
  const theme = useTheme();

  const customTheme = createTheme({
    typography: {
      fontSize: 17,
    },
  });

  return (
    <ThemeProvider theme={customTheme}>
      <RadioGroup value={value} onChange={onChange}>
        <S.Flex row={row}>
          {data?.map((item) => (
            <FormControlLabel
              key={item.value}
              value={item?.value}
              control={
                <Radio
                  sx={{
                    color: theme.config.colors.primary,
                    '&.Mui-checked': {
                      color: theme.config.colors.primary,
                    },
                  }}
                />
              }
              label={item.label}
            />
          ))}
        </S.Flex>
      </RadioGroup>
    </ThemeProvider>
  );
};

MUIRadioGroup.propTypes = {
  data: PropTypes.array,
  row: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
};
