import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Toast, Switch } from 'modules/Core/Common';
import i18n from 'i18next';

const ToggleActivatedContainer = ({
  data,
  field,
  async,
  additionalField = '',
  callback,
}) => {
  const [value, setValue] = useState(data[field]);
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();

  const handleOnChange = async () => {
    const newValue = !value;
    setDisabled(true);
    setValue(newValue);

    const action = async.setActivated({
      data,
      field,
      additionalField,
      activated: newValue,
      onSuccess: () => {
        if (callback) {
          dispatch(callback(Math.random()));
        }
      },
      onError: () => {
        setValue(value);
        Toast(
          i18n.t('errors.error-sorry-an-error-occurred-during.update'),
          'error'
        );
      },
    });

    setDisabled(false);
    dispatch(action);
  };

  return (
    <Switch disabled={disabled} active={value} onChange={handleOnChange} />
  );
};

ToggleActivatedContainer.propTypes = {
  data: PropTypes.object,
  field: PropTypes.string,
  additionalField: PropTypes.string,
  async: PropTypes.object,
  callback: PropTypes.func,
};

export default ToggleActivatedContainer;
