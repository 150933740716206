import styled, { css } from 'styled-components';

export const TooltipDetails = styled.div`
  ${(props) => props.theme.config.typograph.xsmall};

  p::selection {
    background: ${(props) => props.theme.config.colors.gray_400};
  }
`;

export const SelectValueContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  display: block;
  position: relative;
  border: 1px solid transparent;
  border-radius: 6px;
  transition: 0.2s ease-in;

  svg:not[.edu-icon-arrow-down] {
    background: red;
  }

  ${(props) =>
    props.isDisabled &&
    css`
      .multi-select__placeholder {
        color: ${(props) => props.theme.config.colors.gray_100};
      }

      .multi-select__control {
        border: 1px solid ${(props) => props.theme.config.colors.gray_300};
      }

      &:hover,
      &:focus {
        border-color: transparent !important;

        svg {
          path {
            fill: transparent !important;
          }
        }
      }
    `}

  .edu-icon-arrow-down {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    width: 1.4rem;

    path {
      fill: ${(props) => props.theme.config.colors.primary};
      transition: 0.2s ease-in;
    }
  }

  &:hover,
  &:focus {
    border-color: ${(props) => props.theme.config.colors.primary};

    svg {
      path {
        fill: ${(props) => props.theme.config.colors.primary};
      }
    }
  }

  .edu-icon-close {
    width: 0.8rem !important;
  }
`;

const customStyles = {
  control: (_, { isDisabled }) => ({
    height: '4.7rem',
    borderWidth: 0,
    boxShadow: 'none',
    padding: 6,
    borderRadius: 6,
    background: isDisabled ? '#ECEDF3' : '#FFF',
  }),
  valueContainer: () => ({
    padding: 0,
    display: 'flex',
    height: 36,
    alignItems: 'center',
    color: '#0075EA',
    fontSize: 16,
  }),
  multiValue: () => ({
    borderRadius: 2,
    display: 'flex',
    margin: 2,
    minWidth: 0,
    boxSizing: 'border-box',
    position: 'relative',
    width: 'max-content',
  }),
  multiValueLabel: () => ({
    background: 'rgba(0, 117, 234, 0.1)',
    height: 32,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 30,
    borderRadius: 4,
    color: '#0075EA',
    fontSize: 16,
  }),
  multiValueRemove: () => ({
    position: 'absolute',
    cursor: 'pointer',
    right: '3px',
    top: '4.5px',
  }),
  indicatorsContainer: () => ({
    display: 'none',
  }),
  option: (provided) => ({
    ...provided,
    color: '#0075EA',
    fontSize: 16,
  }),
  placeholder: () => ({
    color: 'rgba(93, 102, 112, 0.6)',
    fontSize: '16px',
    marginLeft: '10px',
  }),
};

export default customStyles;
