import { useDispatch, useSelector } from 'react-redux';
import camelCase from 'camelcase';

import { Selectors, Async } from 'modules/Dashboard/Widgets/WidgetsSlice';

export const useEProctoringSessionsCount = ({ widgetType }) => {
  const widgetsState = useSelector(Selectors.fetchListData);

  const dispatch = useDispatch();

  const fetchEProctoringSessionsCount = (intervalOption = '7', userId) => {
    dispatch(
      Async.fetchEProctoringSessionsCount({
        widget_type: widgetType,
        filters: {
          days: +intervalOption,
          student_id: userId,
        },
      })
    );
  };

  const fetchStudentsList = (search = '') => {
    dispatch(
      Async.fetchStudentsList({
        search,
      })
    );
  };

  return {
    fetchEProctoringSessionsCount,
    eproctoringSessionsCount: widgetsState[camelCase(widgetType)],
    fetchStudentsList,
    users: widgetsState.users,
  };
};
