import { isPrimitive } from 'modules/Utils';

export const createCountryPermissionsRequestModel = ({ unitId, countries }) => {
  return {
    unit_id: unitId,
    countries: [
      ...countries.map((country) =>
        isPrimitive(country) ? country : country.country || country.name
      ),
    ],
  };
};
