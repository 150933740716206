export const createExternalSoftwareModel = (externalSoftware = {}) => {
  return {
    id: externalSoftware.id || null,
    title: externalSoftware.title || '',
    url: externalSoftware.url || '',
    description: externalSoftware.description || '',
  };
};

export const createExternalSoftwareRequestModel = (externalSoftware = {}) => {
  return {
    id: externalSoftware.id,
    title: externalSoftware.title,
    url: externalSoftware.url,
    description: externalSoftware.description,
  };
};
