import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { Tooltip } from 'modules/Core/Common';
import { ButtonLicenses, TooltipLicenses } from './styles';
import { EyeNotIcon } from '@edusynch/edusynch-svg-icons';

const UserButtonLicenses = ({ data, toggleId, onClick }) => {
  const [buttonType, setButtonType] = useState(false);
  const NO_LICESNSE_TEXT = i18n.t(
    'modules.users.students.table.table-buttons.no-license'
  );
  const [licensesText, setLicensesText] = useState(NO_LICESNSE_TEXT);
  const [tooltipText, setTooltipText] = useState([]);

  const allLicensesExpired =
    data?.licenses?.length &&
    data?.licenses?.every((license) => license.is_expired);

  useEffect(() => {
    generateLicensesText();
    generateTooltipText();
  }, []);

  useEffect(() => {
    if (toggleId === data.id) {
      setButtonType('isToggleOpen');
    } else if (allLicensesExpired) {
      setButtonType('isExpired');
    } else if (data.licenses?.length) {
      setButtonType('hasLicense');
    } else {
      setButtonType('noLicense');
    }
  }, [toggleId, data.id, data?.licenses]);

  function generateLicensesText() {
    if (allLicensesExpired) {
      setLicensesText('Expired');
      return;
    }

    return data.licenses?.length
      ? setLicensesText(
          `${data.licenses.length} ${
            data.licenses.length > 1 ? 'licenses' : 'license'
          }`
        )
      : setLicensesText(NO_LICESNSE_TEXT);
  }

  function generateTooltipText() {
    const { licenses } = data;

    if (licenses?.length) {
      setTooltipText(
        licenses.map(
          (license) => license?.test?.name || license?.custom_test?.name
        )
      );
    } else {
      setTooltipText([NO_LICESNSE_TEXT]);
    }
  }

  return (
    <Tooltip
      overlayClassName={tooltipText.includes(NO_LICESNSE_TEXT) ? 'hide' : ''}
      placement="bottom"
      overlay={
        <TooltipLicenses>
          {tooltipText.map((text, index) => (
            <p key={index}>{text}</p>
          ))}
        </TooltipLicenses>
      }
      overlayInnerStyle={{
        backgroundColor: '#0075EA',
        borderRadius: '4px',
        padding: '16px',
      }}
    >
      <ButtonLicenses buttonType={buttonType} onClick={() => onClick(data.id)}>
        {toggleId === data.id ? (
          <>
            <EyeNotIcon color="white" />
            {i18n.t('commons.buttons.hide-button')}
          </>
        ) : (
          licensesText
        )}
      </ButtonLicenses>
    </Tooltip>
  );
};

UserButtonLicenses.propTypes = {
  data: PropTypes.object,
  toggleId: PropTypes.number,
  onClick: PropTypes.func,
};

export default UserButtonLicenses;
