const createClassModel = (classObj = {}) => {
  return {
    id: classObj.id || null,
    name: classObj.name || '',
    period: classObj.period || '',
    groupId: classObj.group?.id || '',
    unitId: classObj.unit?.id || '',
    schoolId: classObj.school?.id || '',
    department: classObj.department || '',
    hideAnalysis: classObj.hide_analysis || false,
    showOnSignup: classObj.show_on_signup || false,
    showRank: classObj.show_rank || false,
  };
};

const createClassRequestModel = (classObj = {}) => {
  return {
    id: classObj.id,
    name: classObj.name,
    period: classObj.period,
    group_id: classObj.groupId,
    unit_id: classObj.unitId,
    school_id: classObj.schoolId,
    department: classObj.department,
    hide_analysis: classObj.hideAnalysis,
    show_on_signup: classObj.showOnSignup,
    show_rank: classObj.showRank,
  };
};

export { createClassModel, createClassRequestModel };
