import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, useZendeskChat } from 'modules/Core/Hooks';
import AuthSidebar from './AuthSidebar.component';
import AuthContent from './AuthContent.component';
import * as S from './Auth.styles';

const Auth = ({ children }) => {
  const desktop = useMediaQuery('(min-width: 1024px)');
  const { hideZendeskChat } = useZendeskChat();

  useEffect(() => {
    hideZendeskChat();
  }, []);

  return (
    <S.AuthContainer>
      {desktop && <AuthSidebar />}

      <AuthContent>{children}</AuthContent>
    </S.AuthContainer>
  );
};

Auth.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

export default Auth;
