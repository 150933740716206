import { createSlice } from '@reduxjs/toolkit';
import {
  generateCancelToken,
  cancelRequests,
  isCancel,
  request,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  LIVE_TEST_CODES_URL,
  LIVE_TEST_CODES_CREATE_URL,
} from 'modules/Api/Routes';

let cancelToken;

const initialState = {
  loading: false,
  error: null,
  data: {
    page: 1,
    perPage: 10,
    search: '',
    status: null,
    startDate: null,
    endDate: null,
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
};

const liveCodesSlice = createSlice({
  name: 'liveCodes',
  initialState,
  reducers: {
    clearState: () => ({ ...initialState }),
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    requestLiveCodes: (state) => {
      state.loading = true;
      state.error = null;
    },
    receiveLiveCodesList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        testId: action.payload.live_test.id,
        testName: action.payload.live_test.name,
        liveCodes: action.payload.live_test.live_test_codes,
        total: action.payload.live_test.total_items,
      };
    },
    clearLiveCodesList: (state) => {
      state.data = {
        page: 1,
        perPage: 10,
        search: '',
      };
    },
    changeLiveCodesPage: (state, action) => {
      state.data.page = action.payload;
    },
    changeLiveCodesFilters: (state, action) => {
      state.data.search = action.payload.search || '';
      state.data.status = action.payload.status || null;
      state.data.startDate = action.payload.start_expiration_date || null;
      state.data.endDate = action.payload.end_expiration_date || null;
      state.data.page = Number(action.payload?.page) || 1;
      state.data.perPage =
        Number(action?.payload?.paginates_per) || state.data.perPage;
    },
    clearLiveCodesFilters: (state) => {
      state.data.search = '';
      state.data.status = null;
      state.data.startDate = null;
      state.data.endDate = null;
      state.data.page = 1;
      state.data.perPage = 10;
    },
    clearExpirationDate: (state) => {
      state.data = {
        ...state.data,
        status: null,
        startDate: null,
      };
    },
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    receiveLiveCodesError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

const Actions = liveCodesSlice.actions;

const Selectors = {
  fetchListData: (state) => state.liveCodes,
  liveCodesLoading: ({ liveCodes: { loading } }) => ({ loading }),
};

const Async = {
  fetchLiveCodesList: (id) => async (dispatch, getState) => {
    const {
      liveCodes: {
        data: {
          page,
          perPage,
          search,
          status,
          startDate,
          endDate,
          sort: { sortBy, sortType },
        },
      },
    } = getState();
    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();
    let action;

    dispatch(Actions.requestLiveCodes());
    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: `${LIVE_TEST_CODES_URL}?live_test_id=${id}`,
        params: {
          page,
          paginates_per: perPage,
          search,
          status,
          start_expiration_date: startDate,
          end_expiration_date: endDate,
          sort: sortType,
          sort_by: sortBy,
        },
      });
      action = Actions.receiveLiveCodesList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveLiveCodesError(e.message);
      }
    }

    action && dispatch(action);
  },

  createLiveCodes:
    ({ id, amount, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestLiveCodes());
      let action;
      const data = {
        live_test_id: id,
        amount,
      };

      try {
        await request({
          method: 'POST',
          url: `${LIVE_TEST_CODES_CREATE_URL}`,
          data,
        });
        onSuccess();
        dispatch(Actions.receiveRequestSuccess());
        dispatch(Async.fetchLiveCodesList(id));
      } catch (e) {
        dispatch(Actions.receiveRequestError());
        onError(e);
      }

      action && dispatch(action);
    },
};

const { reducer } = liveCodesSlice;

export { reducer, Actions, Async, Selectors };
