import styled, { css } from 'styled-components';
import { device } from 'modules/Utils/Devices';

export const Container = styled.div`
  margin: 1.6rem auto 0;

  svg {
    width: 1.6rem;
    height: 1.6rem;
    width: 1.6rem;

    path {
      fill: ${(props) => props.theme.config.colors.text_base};
    }
  }

  @media ${device.mobile} {
    width: 100%;
  }
`;

export const ImageContainer = styled.div`
  width: 392px;
  margin: 0 auto;
  height: 223px;
  margin-bottom: 1.6rem;
  border: 2px dashed ${(props) => props.theme.config.colors.gray_300};
  background: ${(props) => props.theme.config.colors.gray_100};
  border-radius: 6px;
  cursor: pointer;

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media ${device.mobile} {
    width: 100%;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3.2rem;

  ${({ $loading }) =>
    $loading &&
    css`
      display: none;
    `};

  button {
    &[data-type='remove'] {
      margin-right: 2.4rem;

      &:hover,
      &:active,
      &:focus {
        svg {
          path {
            fill: ${(props) => props.theme.config.colors.light};
          }
        }
      }

      svg {
        path {
          fill: ${(props) => props.theme.config.colors.primary};
        }
      }
    }

    svg {
      width: 1.2rem;
      height: 1.2rem;
      margin-right: 0.8rem;

      path {
        fill: ${(props) => props.theme.config.colors.light};
      }
    }
  }
`;

export const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 310px;
  max-height: 310px;
  margin: 0 auto;
  height: 223px;
  background: ${(props) => props.theme.config.colors.light};
  border: 2px dashed ${(props) => props.theme.config.colors.primary_200};
  border-radius: 6px;
  cursor: pointer;

  ${($custom) =>
    $custom &&
    css`
      background: ${(props) => props.theme.config.colors.primary_100};
      width: 240px;
    `};

  img {
    margin-bottom: 1.6rem;
    ${(props) => props.profileInfo && 'width: 6rem'};
    ${(props) => props.profileInfo && 'opacity: 0.5'};
  }

  p {
    min-width: 12.4rem;
    text-align: center;
    ${(props) => props.theme.config.typograph.mico};
    color: ${(props) => props.theme.config.colors.primary};
  }

  @media ${device.mobile} {
    width: 100%;
  }
`;

export const InfoText = styled.p`
  ${(props) => props.theme.config.typograph.mico};
  color: ${(props) => props.theme.config.colors.text_base};
  margin-top: -25px;
`;

export const Crop = styled.div`
  width: 600px;
  height: 600px;
  margin: 0 auto;

  @media ${device.mobile} {
    width: 100%;
  }
`;

export const CropContainer = styled.div`
  width: inherit;
  height: inherit;
  margin: inherit;

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media ${device.mobile} {
    width: 100%;
  }
`;

export const CropControls = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  margin: 1.6rem 0 3.2rem;

  button {
    width: 3.2rem;
    height: 3.2rem;
    padding: 0;
    border: none;
    background: ${(props) => props.theme.config.colors.light};
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);

    &:hover,
    &:focus {
      svg {
        path {
          fill: ${(props) => props.theme.config.colors.light};
        }
      }
    }

    &[data-action='upload'] {
      position: absolute;
      right: 0;
    }
  }
`;

export const CropRotate = styled.div`
  display: flex;

  button {
    width: 4.8rem;

    &:first-child {
      border-radius: 3.2rem 0 0 3.2rem;
    }

    &:nth-child(2),
    &:nth-child(3) {
      border-radius: 0;
    }

    &:last-child {
      border-radius: 0 3.2rem 3.2rem 0;
      border-left: 1px solid ${(props) => props.theme.config.colors.gray_200};
    }
  }
`;

export const CropActions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FileInput = styled.input.attrs({
  type: 'file',
})`
  display: none;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  ${(props) =>
    props.hasImage
      ? `justify-content: space-between`
      : `justify-content: center`};
`;

export const ActionsContainer = styled.div`
  display: flex;
  max-width: 30rem;
  flex-wrap: wrap;

  span {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: ${(props) => props.theme.config.spacing.spacing_3};
    color: ${(props) => props.theme.config.colors.text_base};
    position: relative;
  }
`;
