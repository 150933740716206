import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import { ModalHeader } from 'modules/Core/Common/Modal/ModalHeader';

const FormHeader = ({
  title,
  isEdit,
  loading,
  disabled,
  btnPrefix,
  titlePrefix,
  btnCloseClick,
  btnEditPrefix,
  onSubmit,
  customSubmitButtonText,
}) => (
  <ModalHeader
    title={
      isEdit
        ? `${i18n.t(
            'modules.users.teachers.teachers-form.form-header.title.edit'
          )} ${title}`
        : `${
            titlePrefix
              ? titlePrefix
              : i18n.t(
                  'modules.users.teachers.teachers-form.form-header.title.add'
                )
          } ${title}`
    }
    submitButtonText={
      customSubmitButtonText
        ? customSubmitButtonText
        : isEdit
        ? `${
            btnEditPrefix
              ? btnEditPrefix
              : i18n.t(
                  'modules.users.teachers.teachers-form.form-header.buttons.save-changes'
                )
          }`
        : `${
            btnPrefix
              ? btnPrefix
              : i18n.t(
                  'modules.users.teachers.teachers-form.form-header.buttons.add-new'
                )
          } ${title}`
    }
    onSubmit={onSubmit}
    loading={loading}
    btnCloseClick={btnCloseClick}
    disabled={disabled}
  />
);

FormHeader.propTypes = {
  isEdit: PropTypes.any,
  disabled: PropTypes.bool,
  btnPrefix: PropTypes.string,
  titlePrefix: PropTypes.string,
  btnEditPrefix: PropTypes.string,
  onSubmit: PropTypes.func,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  customSubmitButtonText: PropTypes.string,
  btnCloseClick: PropTypes.func.isRequired,
};

export default FormHeader;
