import { request } from 'modules/Api/HttpClient';

const defaultRequest = async (
  dispatch,
  url,
  method,
  startRequest,
  receiveRequest,
  receiveError,
  params
) => {
  let action;

  dispatch(startRequest());

  try {
    const response = await request({
      method,
      url,
      params,
    });

    action = receiveRequest(response.data || []);
  } catch (e) {
    action = receiveError(e.message);
  }

  dispatch(action);
};

export { defaultRequest };
