const renameObjectKey = (object, oldKey, newKey) => {
  const modifiedObject = object.map((obj) => ({
    ...obj,
    [newKey]: obj[oldKey],
  }));

  return modifiedObject;
};

export { renameObjectKey };
