import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import {
  fetchGroups,
  fetchWhitelabels,
  fetchExternalApplications,
  fetchUnitsByGroup,
  fetchSchoolsByUnit,
  fetchClassesBySchool,
  fetchLicensesByGroup,
  fetchLicensesByUnit,
  fetchLicensesBySchool,
} from 'modules/Users/Common/UserAssociations/UserAssociationsService';
import { useAuth } from 'modules/Auth/Hooks';

import {
  Row,
  Col,
  Label,
  FormGroup,
  SelectAutocomplete,
  Toast,
} from 'modules/Core/Common';

/**
 * TODO - Refactor this component
 * overload of logic and an excessive amount of conditionals
 */
const UserAssociations = ({
  student,
  rawStudent,
  onGroupChange,
  onUnitChange,
  onSchoolChange,
  onClassChange,
  onWhitelabelChange,
  onGroupLicenseChange,
  onUnitLicenseChange,
  onSchoolLicenseChange,
  onIntegrationChange,
}) => {
  const [groups, setGroups] = useState({ data: [], loading: true });
  const [groupLicenses, setGroupLicenses] = useState({
    data: [],
    loading: false,
  });
  const [units, setUnits] = useState({ data: [], loading: false });
  const [unitLicenses, setUnitLicenses] = useState({
    data: [],
    loading: false,
  });
  const [schools, setSchools] = useState({ data: [], loading: false });
  const [schoolLicenses, setSchoolLicenses] = useState({
    data: [],
    loading: false,
  });
  const [classes, setClasses] = useState({ data: [], loading: false });
  const [whitelabels, setWhitelabels] = useState({ data: [], loading: false });
  const [externalApplications, setExternalApplications] = useState({
    data: [],
    loading: false,
  });
  const [whitelabelHasChanged, setWhitelabelHasChanged] = useState(false);

  const {
    authData: { user: loggedUser },
    isSuperAdmin,
    isGroupAdmin,
    isUnitAdmin,
    isSchoolAdmin,
  } = useAuth();

  const { group, unit, school, student_classes } = loggedUser;

  const isAdd = useMemo(() => !student?.id, [student?.id]);

  const preSelectWhitelabel = useMemo(
    () =>
      isAdd
        ? student?.whitelabelId && whitelabels?.data?.length < 2
        : student?.whitelabelId,
    [student?.whitelabelId, whitelabels?.data, isAdd]
  );

  useEffect(() => {
    if (student?.id) {
      if (isSuperAdmin || isGroupAdmin) loadGroups(rawStudent?.group); // only super admin and group admin can change group
      loadExternalApplications();

      if (student?.groupId) {
        loadGroupLicenses(student.groupId, rawStudent?.group_license);
        if (!unit) loadUnits(student.groupId, rawStudent?.unit);
      }
      if (student?.unitId) {
        loadUnitLicenses(student.unitId, rawStudent?.unit_license);
        if (!school) loadSchools(student.unitId, rawStudent?.school);
      }
      if (student?.schoolId) {
        loadSchoolLicenses(student.schoolId, rawStudent?.school_license);
        if (!student_classes?.length)
          loadClasses(student.schoolId, rawStudent?.class);
      }
    } else {
      if (isSuperAdmin) {
        loadGroups();
        loadExternalApplications();
      } else {
        loadLoggedUserAssociations();
      }
    }
  }, [student?.id, group, unit, school, student?.schoolId]);

  useEffect(() => {
    !groups.loading &&
      loadWhitelabels({
        group_id: student?.groupId || group?.id,
        unit_id: student?.unitId || unit?.id,
        school_id: student?.schoolId || school?.id,
      });
  }, [groups.loading, student?.id, group, unit, school]);

  function loadGroups(groupData) {
    setGroups({
      data: groupData ? [groupData] : [],
      loading: true,
    });
    return fetchGroups()
      .then((response) =>
        setGroups({
          data: groupData
            ? [groupData, ...response.data.content.groups]
            : response.data.content.groups,
          loading: false,
        })
      )
      .catch(() => {
        Toast(
          i18n.t('errors.error-sorry-an-error-occurred-during.load-licenses'),
          'error'
        );
        setGroups({
          data: [],
          loading: false,
        });
      });
  }

  function loadGroupLicenses(groupId, rawGroupLicense) {
    setGroupLicenses({
      data: rawGroupLicense ? [rawGroupLicense] : [],
      loading: true,
    });
    fetchLicensesByGroup({ groupId })
      .then((response) =>
        setGroupLicenses({
          data: rawGroupLicense
            ? [rawGroupLicense, ...response.data.content.licenses]
            : response.data.content.licenses,
          loading: false,
        })
      )
      .catch(() => {
        Toast(
          i18n.t('errors.error-sorry-an-error-occurred-during.load-licenses'),
          'error'
        );
        setGroupLicenses({
          data: [],
          loading: false,
        });
      });
  }

  function loadUnits(groupId, rawUnits) {
    setUnits({
      data: rawUnits ? [rawUnits] : [],
      loading: true,
    });
    fetchUnitsByGroup({ groupId })
      .then((response) =>
        setUnits({
          data: rawUnits
            ? [rawUnits, ...response.data.content]
            : response.data.content,
          loading: false,
        })
      )
      .catch(() => {
        Toast(
          i18n.t('errors.error-sorry-an-error-occurred-during.load-units'),
          'error'
        );
        setUnits({
          data: [],
          loading: false,
        });
      });
  }

  function loadUnitLicenses(unitId, rawUnitLicense) {
    setUnitLicenses({
      data: rawUnitLicense ? [rawUnitLicense] : [],
      loading: true,
    });
    fetchLicensesByUnit({ unitId })
      .then((response) =>
        setUnitLicenses({
          data: rawUnitLicense
            ? [rawUnitLicense, ...response.data.content.licenses]
            : response.data.content.licenses,
          loading: false,
        })
      )
      .catch(() => {
        Toast(
          i18n.t(
            'errors.error-sorry-an-error-occurred-during.load-units-licenses'
          ),
          'error'
        );
        setUnitLicenses({
          data: [],
          loading: false,
        });
      });
  }

  function loadSchools(unitId, rawSchools) {
    setSchools({
      data: rawSchools ? [rawSchools] : [],
      loading: true,
    });
    fetchSchoolsByUnit({ unitId })
      .then((response) => {
        const data = response?.data?.content?.schools || [];
        return setSchools({
          data: rawSchools ? [rawSchools, ...data] : data,
          loading: false,
        });
      })
      .catch(() => {
        Toast(
          i18n.t('errors.error-sorry-an-error-occurred-during.load-schools'),
          'error'
        );
        setSchools({
          data: [],
          loading: false,
        });
      });
  }

  function loadSchoolLicenses(schoolId, rawSchoolsLicense) {
    setSchoolLicenses({
      data: rawSchoolsLicense ? [rawSchoolsLicense] : [],
      loading: true,
    });
    fetchLicensesBySchool({ schoolId })
      .then((response) =>
        setSchoolLicenses({
          data: rawSchoolsLicense
            ? [rawSchoolsLicense, ...response.data.content.licenses]
            : response.data.content.licenses,
          loading: false,
        })
      )
      .catch(() => {
        Toast(
          i18n.t(
            'errors.error-sorry-an-error-occurred-during.load-schools-licenses'
          ),
          'error'
        );
        setSchoolLicenses({
          data: [],
          loading: false,
        });
      });
  }

  function loadClasses(schoolId, rawClasses) {
    setClasses({
      data: rawClasses ? [rawClasses] : [],
      loading: true,
    });
    fetchClassesBySchool({ schoolId })
      .then((response) =>
        setClasses({
          data: rawClasses
            ? [rawClasses, ...response.data.content]
            : response.data.content,
          loading: false,
        })
      )
      .catch(() => {
        Toast(
          i18n.t('errors.error-sorry-an-error-occurred-during.load-classes'),
          'error'
        );
        setClasses({
          data: [],
          loading: false,
        });
      });
  }

  function loadWhitelabels({ group_id, unit_id, school_id }) {
    setWhitelabels({
      data: [],
      loading: true,
    });
    fetchWhitelabels({
      group_id,
      unit_id,
      school_id,
    })
      .then((response) => {
        const newWhitelabelsArray = response.data.content.whitelabels;
        setWhitelabels({
          data: newWhitelabelsArray,
          loading: false,
        });
        newWhitelabelsArray?.length === 1 &&
          onWhitelabelChange(newWhitelabelsArray?.[0]?.id);
      })
      .catch(() => {
        Toast(
          i18n.t(
            'errors.error-sorry-an-error-occurred-during.load-whitelabels'
          ),
          'error'
        );
        setWhitelabels({
          data: [],
          loading: false,
        });
      });
  }

  function loadExternalApplications() {
    setExternalApplications({
      data: [],
      loading: true,
    });
    fetchExternalApplications()
      .then((response) =>
        setExternalApplications({
          data: response.data.content,
          loading: false,
        })
      )
      .catch(() => {
        Toast(
          i18n.t(
            'errors.error-sorry-an-error-occurred-during.load-external-app'
          ),
          'error'
        );
        setExternalApplications({
          data: [],
          loading: false,
        });
      });
  }

  function loadLoggedUserAssociations() {
    const { group, unit, school, student_classes } = loggedUser;

    if (group) {
      setGroups({
        data: [group],
        loading: false,
      });
      onGroupChange(group.id);
      loadGroupLicenses(group.id);

      if (!unit) loadUnits(group.id);
    }

    if (unit) {
      setUnits({
        data: [unit],
        loading: false,
      });
      onUnitChange(unit.id);
      loadUnitLicenses(unit.id);

      if (!school) loadSchools(unit.id);
    }

    if (school) {
      setSchools({
        data: [school],
        loading: false,
      });
      onSchoolChange(school.id);
      loadSchoolLicenses(school.id);

      if (!student_classes?.length) loadClasses(school.id);
    }

    if (student_classes?.length > 0) {
      setClasses({
        data: [...student_classes],
        loading: false,
      });
      const [firstClass] = student_classes;
      onClassChange(firstClass?.id);
    }
  }

  function formatExternalApplications(data) {
    return (
      data?.map((item) => ({
        id: item?.id,
        label: item?.name || item?.group?.name,
        type: 'External Application',
        filterName: 'external_application_id',
      })) || []
    );
  }

  function handleGroupChange(event) {
    const groupId = event.target.value;
    onGroupChange(groupId);
    onWhitelabelChange('');
    onGroupLicenseChange('');
    onUnitLicenseChange('');
    onSchoolLicenseChange('');
    !whitelabels.loading && loadWhitelabels({ group_id: groupId || null });

    if (!unit) onUnitChange('');
    if (!school) onSchoolChange('');
    if (!student_classes?.length) onClassChange('');

    if (groupId) {
      loadGroupLicenses(groupId);
      if (!unit) loadUnits(groupId);
    }
  }

  function handleGroupLicenseChange(event) {
    const groupLicenseId = event.target.value;
    onGroupLicenseChange(groupLicenseId);

    if (groupLicenseId) {
      onUnitLicenseChange('');
      onSchoolLicenseChange('');
    }
  }

  function handleUnitChange(event) {
    const unitId = event.target.value;
    onUnitChange(unitId);
    onWhitelabelChange('');
    onUnitLicenseChange('');
    onSchoolLicenseChange('');
    if (!unitLicenses.loading && unitId) {
      loadUnitLicenses(unitId);
    }
    !whitelabels.loading &&
      loadWhitelabels({
        group_id: student?.groupId || group?.id,
        unit_id: unitId || null,
      });

    if (!school) onSchoolChange('');
    if (!student_classes?.length) onClassChange('');

    if (unitId) {
      if (!school) loadSchools(unitId);
    }
  }

  function handleUnitLicenseChange(event) {
    const unitLicenseId = event.target.value;
    onUnitLicenseChange(unitLicenseId);

    if (unitLicenseId) {
      onGroupLicenseChange('');
      onSchoolLicenseChange('');
    }
  }

  function handleSchoolChange(event) {
    const schoolId = event.target.value;
    onSchoolChange(schoolId);
    onWhitelabelChange('');
    onSchoolLicenseChange('');
    !whitelabels.loading &&
      loadWhitelabels({
        group_id: student?.groupId || group?.id,
        unit_id: student?.unitId || unit?.id,
        school_id: schoolId || null,
      });

    if (!student_classes?.length) onClassChange('');

    if (schoolId) {
      loadSchoolLicenses(schoolId);
      if (!student_classes?.length) loadClasses(schoolId);
    }
  }

  function handleSchoolLicenseChange(event) {
    const schoolLicenseId = event.target.value;
    onSchoolLicenseChange(schoolLicenseId);

    if (schoolLicenseId) {
      onGroupLicenseChange('');
      onUnitLicenseChange('');
    }
  }

  function handleClassChange(event) {
    const classId = event.target.value;
    onClassChange(classId);
  }

  function handleWhitelabelChange(event) {
    const whitelabelId = event.target.value;
    setWhitelabelHasChanged(!!whitelabelId);
    onWhitelabelChange(whitelabelId);
  }

  function handleIntegrationChange(event) {
    const externalApplicationId = event.target.value;
    onIntegrationChange(externalApplicationId);
  }

  const onlyForGroupAdmins = isSuperAdmin || isGroupAdmin;
  const onlyForUnitAdmins = onlyForGroupAdmins || isUnitAdmin;
  const onlyForSchoolAdmins = onlyForUnitAdmins || isSchoolAdmin;
  const teacherAdmin = !onlyForSchoolAdmins;
  const disableClassSelection = teacherAdmin && classes?.data?.length < 2;
  const hasOneWhitelabel = whitelabels?.data?.length < 2;
  const disableWhitelabelSelection = !onlyForUnitAdmins || hasOneWhitelabel;

  return (
    <Row>
      <Col xs={6}>
        <FormGroup>
          <Label htmlFor="groupId">{i18n.t('common-words.group')}</Label>
          <SelectAutocomplete
            id="group_search_id"
            loading={groups.loading}
            searchPlaceholder="Search"
            placeholder=" "
            options={groups?.data}
            hasClearField
            clearFieldLabel={i18n.t(
              'modules.users.students.student-form.classification.inputs.select-group'
            )}
            disabled={!isSuperAdmin}
            value={student?.groupId ? [parseInt(student?.groupId)] : []}
            onChangeSelect={(id) =>
              handleGroupChange({
                target: {
                  name: 'groupId',
                  value: id,
                },
              })
            }
          />
        </FormGroup>
      </Col>
      {onlyForGroupAdmins ? (
        <Col xs={6}>
          <FormGroup>
            <Label htmlFor="groupLicenses">
              {i18n.t(
                'modules.users.students.student-form.classification.inputs.group-licenses'
              )}
            </Label>
            <SelectAutocomplete
              id="group_license_search_id"
              loading={groupLicenses.loading}
              searchPlaceholder="Search"
              placeholder=" "
              hasClearField
              clearFieldLabel={i18n.t(
                'modules.users.students.student-form.classification.inputs.select-group-licenses'
              )}
              options={groupLicenses?.data}
              value={
                student?.groupLicenseId
                  ? [parseInt(student?.groupLicenseId)]
                  : []
              }
              onChangeSelect={(id) =>
                handleGroupLicenseChange({
                  target: {
                    name: 'groupLicenseId',
                    value: id,
                  },
                })
              }
            />
          </FormGroup>
        </Col>
      ) : null}

      <Col xs={6}>
        <FormGroup>
          <Label htmlFor="unit">{i18n.t('common-words.unit')}</Label>
          <SelectAutocomplete
            id="unit_search_id"
            loading={units.loading}
            searchPlaceholder="Search"
            placeholder=" "
            hasClearField
            clearFieldLabel={i18n.t(
              'modules.users.students.student-form.classification.inputs.select-unit'
            )}
            options={units?.data}
            disabled={!student.groupId || (!isGroupAdmin && !isSuperAdmin)}
            value={student?.unitId ? [parseInt(student?.unitId)] : []}
            onChangeSelect={(id) =>
              handleUnitChange({
                target: {
                  name: 'unit',
                  value: id,
                },
              })
            }
          />
        </FormGroup>
      </Col>
      {onlyForUnitAdmins ? (
        <Col xs={6}>
          <FormGroup>
            <Label htmlFor="unitLicenses">
              {i18n.t(
                'modules.users.students.student-form.classification.inputs.unit-licenses'
              )}
            </Label>
            <SelectAutocomplete
              id="unit_license_search_id"
              loading={unitLicenses.loading}
              searchPlaceholder="Search"
              placeholder=" "
              hasClearField
              clearFieldLabel={i18n.t(
                'modules.users.students.student-form.classification.inputs.select-unit-licenses'
              )}
              options={unitLicenses?.data}
              value={
                student?.unitLicenseId ? [parseInt(student?.unitLicenseId)] : []
              }
              disabled={!student.unitId}
              onChangeSelect={(id) =>
                handleUnitLicenseChange({
                  target: {
                    name: 'unitLicenseId',
                    value: id,
                  },
                })
              }
            />
          </FormGroup>
        </Col>
      ) : null}

      <Col xs={6}>
        <FormGroup>
          <Label htmlFor="school">{i18n.t('common-words.school')}</Label>
          <SelectAutocomplete
            id="school_search_id"
            loading={schools.loading}
            searchPlaceholder="Search"
            placeholder=" "
            hasClearField
            clearFieldLabel={i18n.t(
              'modules.users.students.student-form.classification.inputs.select-school'
            )}
            options={schools?.data}
            disabled={
              !student.unitId ||
              (!isUnitAdmin && !isGroupAdmin && !isSuperAdmin)
            }
            value={student?.schoolId ? [parseInt(student?.schoolId)] : []}
            onChangeSelect={(id) =>
              handleSchoolChange({
                target: {
                  name: 'schoolId',
                  value: id,
                },
              })
            }
          />
        </FormGroup>
      </Col>
      {onlyForSchoolAdmins ? (
        <Col xs={6}>
          <FormGroup>
            <Label htmlFor="schoolLicenses">
              {i18n.t(
                'modules.users.students.student-form.classification.inputs.school-licenses'
              )}
            </Label>
            <SelectAutocomplete
              id="school_license_search_id"
              loading={schoolLicenses.loading}
              searchPlaceholder="Search"
              placeholder=" "
              hasClearField
              clearFieldLabel={i18n.t(
                'modules.users.students.student-form.classification.inputs.select-school-licenses'
              )}
              options={schoolLicenses?.data}
              value={
                student?.schoolLicenseId
                  ? [parseInt(student?.schoolLicenseId)]
                  : []
              }
              disabled={!student.schoolId}
              onChangeSelect={(id) =>
                handleSchoolLicenseChange({
                  target: {
                    name: 'schoolLicenseId',
                    value: id,
                  },
                })
              }
            />
          </FormGroup>
        </Col>
      ) : null}

      <Col xs={6}>
        <FormGroup>
          <Label htmlFor="class">{i18n.t('common-words.class')}</Label>
          <SelectAutocomplete
            id="class_search_id"
            loading={classes.loading}
            searchPlaceholder="Search"
            placeholder=" "
            hasClearField
            clearFieldLabel={i18n.t(
              'modules.users.students.student-form.classification.inputs.select-class'
            )}
            options={classes?.data}
            disabled={!student.schoolId || disableClassSelection}
            value={student?.classId ? [parseInt(student?.classId)] : []}
            onChangeSelect={(id) =>
              handleClassChange({
                target: {
                  name: 'classId',
                  value: id,
                },
              })
            }
          />
        </FormGroup>
      </Col>

      <Col xs={6}>
        <FormGroup>
          <Label htmlFor="whitelabel">
            {i18n.t('common-words.whitelabel')}
          </Label>
          <SelectAutocomplete
            id="whitelabel_search_id"
            loading={whitelabels.loading}
            searchPlaceholder="Search"
            placeholder=" "
            hasClearField
            clearFieldLabel={i18n.t(
              'modules.users.students.student-form.classification.inputs.select-whitelabel'
            )}
            options={whitelabels?.data}
            disabled={disableWhitelabelSelection}
            value={
              preSelectWhitelabel || whitelabelHasChanged
                ? [parseInt(student?.whitelabelId)]
                : []
            }
            onChangeSelect={(id) =>
              handleWhitelabelChange({
                target: {
                  name: 'whitelabel',
                  value: id,
                },
              })
            }
          />
        </FormGroup>
      </Col>

      <Col xs={6}>
        <FormGroup>
          <Label htmlFor="integration">
            {i18n.t('common-words.integration')}
          </Label>
          <SelectAutocomplete
            id="integration_search_id"
            loading={externalApplications.loading}
            searchPlaceholder="Search"
            placeholder=" "
            hasClearField
            clearFieldLabel={i18n.t(
              'modules.users.students.student-form.classification.inputs.select-integration'
            )}
            options={formatExternalApplications(externalApplications?.data)}
            disabled={!isSuperAdmin}
            value={
              student?.externalApplicationId
                ? [parseInt(student?.externalApplicationId)]
                : []
            }
            onChangeSelect={(id) =>
              handleIntegrationChange({
                target: {
                  name: 'externalApplicationId',
                  value: id,
                },
              })
            }
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

UserAssociations.propTypes = {
  student: PropTypes.object,
  rawStudent: PropTypes.object,
  onGroupChange: PropTypes.func,
  onGroupLicenseChange: PropTypes.func,
  onUnitChange: PropTypes.func,
  onUnitLicenseChange: PropTypes.func,
  onSchoolChange: PropTypes.func,
  onSchoolLicenseChange: PropTypes.func,
  onClassChange: PropTypes.func,
  onWhitelabelChange: PropTypes.func,
  onIntegrationChange: PropTypes.func,
};

export default UserAssociations;
