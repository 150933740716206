import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  BASE_TESTS_URL,
  PURCHASES_URL,
  GROUPS_URL,
  WHITELABELS_URL,
  PURCHASE_PLANS_URL,
  PURCHASES_EXPORT_URL,
} from 'modules/Api/Routes';
import { getISOStringFromStringDate } from 'modules/Utils';
import {
  getUTCDefaultStartDate,
  getUTCDefaultEndDate,
} from 'modules/Utils/Date';
import { defaultRequest } from 'modules/Utils/DefaultRequest';

const currentStartDate = getUTCDefaultStartDate();
const currentEndDate = getUTCDefaultEndDate();

let cancelToken;

const initialState = {
  loading: false,
  error: null,
  exportFilter: {
    link: '',
    testId: '',
    groupId: null,
    whitelabelId: null,
    operator: null,
    packageId: null,
    startDate: currentStartDate,
    endDate: currentEndDate,
    extension: 'csv',
    loading: false,
  },
  data: {
    page: 1,
    perPage: 10,
    testId: null,
    groupId: null,
    whitelabelId: null,
    operator: null,
    packageId: null,
    search: '',
    startDate: currentStartDate,
    endDate: currentEndDate,
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
  groups: {
    loading: false,
    data: [],
  },
  tests: {
    loading: false,
    data: [],
  },
  whitelabels: {
    loading: false,
    data: [],
  },
  purchasePlans: {
    loading: false,
    data: [],
  },
};

const purchasesSlice = createSlice({
  name: 'purchases',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    changeExportFilter: (state, action) => {
      state.exportFilter = {
        ...state.exportFilter,
        [action.payload?.param]: action.payload?.value,
      };
    },
    requestPurchases: (state) => {
      state.loading = true;
      state.error = null;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    receivePurchasesList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        purchases: action.payload.purchases,
        total: action.payload.total_items,
      };
    },
    clearPurchasesList: (state) => {
      state.data = {
        page: 1,
        perPage: 10,
        search: '',
        groupId: null,
        testId: null,
        whitelabelId: null,
        operator: null,
        packageId: null,
        startDate: currentStartDate,
        endDate: currentEndDate,
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
    },
    receivePurchasesError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changePurchasesPage: (state, action) => {
      state.data.page = action.payload;
    },
    changePurchasesFilters: (state, action) => {
      state.data.testId = action.payload.test_id || null;
      state.data.groupId = action.payload.group_id || null;
      state.data.whitelabelId = action.payload.whitelabel_id || null;
      state.data.operator = action.payload.operator || null;
      state.data.packageId = action.payload.package_id || null;
      state.data.startDate = action.payload.start_date || currentStartDate;
      state.data.endDate = action.payload.end_date || currentEndDate;
      state.data.search = action.payload.search;
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);

      state.exportFilter.testId = action.payload?.test_id || '';
      state.exportFilter.groupId = action.payload.group_id || null;
      state.exportFilter.whitelabelId = action.payload.whitelabel_id || null;
      state.exportFilter.operator = action.payload.operator || null;
      state.exportFilter.packageId = action.payload.package_id || null;
      state.exportFilter.startDate =
        action.payload.start_date || currentStartDate;
      state.exportFilter.endDate = action.payload.end_date || currentEndDate;
    },
    clearPurchasesFilters: (state) => {
      state.data.testId = null;
      state.data.groupId = null;
      state.data.whitelabelId = null;
      state.data.operator = null;
      state.data.packageId = null;
      state.data.search = '';
      state.data.page = 1;
      state.data.perPage = 10;
      state.data.startDate = currentStartDate;
      state.data.endDate = currentEndDate;
      state.data.sort.sortType = null;
      state.data.sort.sortBy = null;
    },
    requestGroups: (state) => {
      state.groups.loading = true;
    },
    receiveGroupsList: (state, action) => {
      state.groups.loading = false;
      state.groups.data = action.payload.content.groups;
    },
    receiveGroupsError: (state, action) => {
      state.groups.loading = false;
      state.error = action.payload;
    },
    requestTests: (state) => {
      state.tests.loading = true;
    },
    receiveTestsList: (state, action) => {
      state.tests.loading = false;
      state.tests.data = action.payload.content.tests;
    },
    receiveTestsError: (state, action) => {
      state.tests.loading = false;
      state.error = action.payload;
    },
    requestWhitelabels: (state) => {
      state.whitelabels.loading = true;
    },
    receiveWhitelabelsList: (state, action) => {
      state.whitelabels.loading = false;
      state.whitelabels.data = action.payload.content.whitelabels;
    },
    receiveWhitelabelsError: (state, action) => {
      state.whitelabels.loading = false;
      state.error = action.payload;
    },
    requestPuchasePlans: (state) => {
      state.purchasePlans.loading = true;
    },
    receivePuchasePlansList: (state, action) => {
      state.purchasePlans.loading = false;
      state.purchasePlans.data = action.payload.content.purchases;
    },
    receivePuchasePlansError: (state, action) => {
      state.purchasePlans.loading = false;
      state.error = action.payload;
    },
    requestExportLinks: (state) => {
      state.exportFilter.link = '';
      state.exportFilter.loading = true;
    },
    receiveExportLinks: (state, action) => {
      state.exportFilter.loading = false;
      state.exportFilter.link =
        action?.payload?.[`link_download_${state.exportFilter.extension}`];
    },
    receiveExportLinksError: (state, action) => {
      state.exportFilter.loading = false;
      state.error = action.payload;
    },
  },
});

const Actions = purchasesSlice.actions;

const Selectors = {
  fetchListData: (state) => state.purchases,
  purchasesLoading: ({ purchases: { loading } }) => ({ loading }),
};

const Async = {
  fetchGroupsList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      `${GROUPS_URL}?page=1&paginates_per=200`,
      'GET',
      Actions.requestGroups,
      Actions.receiveGroupsList,
      Actions.receiveGroupsError
    ),
  fetchTestsList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      `${BASE_TESTS_URL}?page=1&paginates_per=200&sort=ASC&sort_by=name`,
      'GET',
      Actions.requestTests,
      Actions.receiveTestsList,
      Actions.receiveTestsError
    ),
  fetchWhitelabelsList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      WHITELABELS_URL,
      'GET',
      Actions.requestWhitelabels,
      Actions.receiveWhitelabelsList,
      Actions.receiveWhitelabelsError
    ),
  fetchPurchasePlansList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      PURCHASE_PLANS_URL,
      'GET',
      Actions.requestPuchasePlans,
      Actions.receivePuchasePlansList,
      Actions.receivePuchasePlansError
    ),
  fetchPurchasesList: () => async (dispatch, getState) => {
    const {
      purchases: {
        data: {
          page,
          perPage,
          search,
          testId,
          groupId,
          whitelabelId,
          operator,
          packageId,
          startDate,
          endDate,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestPurchases());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: PURCHASES_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          group_id: groupId,
          test_id: testId,
          whitelabel_id: whitelabelId,
          operator: operator,
          package_bought_id: packageId,
          start_date: getISOStringFromStringDate(startDate || currentStartDate),
          end_date: getISOStringFromStringDate(endDate || currentEndDate),
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receivePurchasesList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receivePurchasesError(e.message);
      }
    }

    action && dispatch(action);
  },

  fetchExportSubmit:
    ({ onSuccess, onError }) =>
    async (dispatch, getState) => {
      const {
        purchases: {
          data: { search },
          exportFilter: {
            testId,
            groupId,
            whitelabelId,
            operator,
            packageId,
            startDate,
            endDate,
            extension,
          },
        },
      } = getState();
      let action;

      dispatch(Actions.requestExportLinks());

      const data = {
        file_type: extension,
        group_id: groupId,
        test_id: testId,
        whitelabel_id: whitelabelId,
        operator: operator,
        package_bought_id: packageId,
        start_date: getISOStringFromStringDate(startDate || currentStartDate),
        end_date: getISOStringFromStringDate(endDate || currentEndDate),
        search,
      };

      try {
        const response = await request({
          method: 'POST',
          url: PURCHASES_EXPORT_URL,
          data,
        });

        action = Actions.receiveExportLinks(response.data.content);
        onSuccess(response.data.content);
      } catch (e) {
        action = Actions.receiveExportLinksError(e.message);
        onError();
      }

      dispatch(action);
    },
};

const { reducer } = purchasesSlice;

export { reducer, Actions, Async, Selectors };
