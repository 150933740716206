import { useContext } from 'react';

import { AbilityContext } from 'services/Ability.service';
import { VIEW } from 'services/Ability.constants';

import {
  LINKS_1,
  LINKS_2,
  LINKS_3,
  LINKS_4,
} from 'modules/Dashboard/NavigationCards/Utils';

export const useDashboardLinks = () => {
  const ability = useContext(AbilityContext);
  const links1 = LINKS_1.find((item) => ability.can(VIEW, item.ability));
  const links2 = LINKS_2.find((item) => ability.can(VIEW, item.ability));
  const links3 = LINKS_3.find((item) => ability.can(VIEW, item.ability));
  const links4 = LINKS_4.find((item) => ability.can(VIEW, item.ability));

  const shortcuts = [links1, links2, links3, links4];

  return { shortcuts };
};
