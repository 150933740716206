import styled, { css } from 'styled-components';
import { device } from 'modules/Utils/Devices';

const Container = styled.div`
  width: 100%;
  max-width: 50rem;
  height: 100vh;
  position: relative;
  ${(props) =>
    props.imgSrc &&
    css`
      background-image: url(${props.imgSrc});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 70% 0%;
    `};

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${(props) =>
      props.theme.config.components.AuthSidebar.alt};
    z-index: 1;
    opacity: 0.9;
  }
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media ${device.tabletAndMobile} {
    position: static;
    height: auto;
  }

  @media ${device.mobile} {
    position: static;
    height: auto;

    &.page {
      margin-top: ${(props) => props.theme.config.spacing.spacing_7};
    }
  }
`;

const Logo = styled.img`
  margin-bottom: ${(props) => props.theme.config.spacing.spacing_5};
  min-width: 31.5rem;
  display: block;

  @media ${device.tabletAndMobile} {
    margin-bottom: ${(props) => props.theme.config.spacing.spacing_4};
  }

  @media ${device.mobile} {
    margin-bottom: ${(props) => props.theme.config.spacing.spacing_3};
    max-width: 200px;
    min-width: auto;
    width: 100%;
  }
`;

const Description = styled.p`
  ${(props) => props.theme.config.typograph.default};
  max-width: 90%;
  color: ${(props) => props.theme.config.components.AuthSidebar.text};
  text-align: center;

  a {
    margin-top: ${(props) => props.theme.config.spacing.spacing_1};
    color: ${(props) => props.theme.config.components.AuthSidebar.link};
    display: block;
    font-weight: 300;
    text-decoration: none;
  }

  strong {
    font-weight: 700;
  }

  @media ${device.tabletAndMobile} {
    line-height: 1.9rem;
  }
`;

export { Container, Content, Logo, Description, Img };
