import React from 'react';
import PropTypes from 'prop-types';
import { DoubleArrowIcon } from '@edusynch/edusynch-svg-icons';
import * as S from './ToggleButton.styles';

export const ToggleButton = ({ IconName, toggledId, id, handleToggle }) => {
  return (
    <S.Button isToggled={toggledId === id} onClick={() => handleToggle(id)}>
      {IconName ? <IconName /> : <DoubleArrowIcon />}
    </S.Button>
  );
};

ToggleButton.propTypes = {
  IconName: PropTypes.func.isRequired,
  toggledId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  id: PropTypes.number.isRequired,
  handleToggle: PropTypes.func.isRequired,
};
