import { lazy } from 'react';
import { menuBuilder } from 'services/Routes.service';
import { SubmissionIcon } from '@edusynch/edusynch-svg-icons';

const BaseTestsList = lazy(() =>
  import('modules/Submissions/Base/BaseTestsList')
);

const BaseTestListAnswers = lazy(() =>
  import('modules/Submissions/Base/BaseTestAnswers/BaseTestAnswers.container')
);

const BaseTestListQuestions = lazy(() =>
  import(
    'modules/Submissions/Base/BaseTestQuestions/BaseTestQuestions.container'
  )
);

const CustomTestList = lazy(() =>
  import('modules/Submissions/Custom/CustomTestList')
);

const CustomTestListAnswers = lazy(() =>
  import(
    'modules/Submissions/Custom/CustomTestAnswers/CustomTestAnswers.container'
  )
);

const CustomTestListQuestions = lazy(() =>
  import(
    'modules/Submissions/Custom/CustomTestQuestions/CustomTestQuestions.container'
  )
);

const InvalidTestsList = lazy(() =>
  import(
    'modules/Submissions/Invalid/InvalidTestsList/InvalidTestsList.container'
  )
);

const InvalidTestsListAnswers = lazy(() =>
  import(
    'modules/Submissions/Invalid/InvalidTestAnswers/InvalidTestAnswers.container'
  )
);

const InvalidTestsListQuestions = lazy(() =>
  import(
    'modules/Submissions/Invalid/InvalidTestQuestions/InvalidTestQuestions.container'
  )
);

const PracticeResultsList = lazy(() =>
  import(
    'modules/Submissions/Practice/PracticeResultsList/PracticeResultsList.container'
  )
);

const PracticeResultsListAnswers = lazy(() =>
  import(
    'modules/Submissions/Practice/PracticeResultsAnswers/PracticeResultsAnswers.container'
  )
);

const PracticeResultsListQuestions = lazy(() =>
  import(
    'modules/Submissions/Practice/PracticeResultsQuestions/PracticeResultsQuestions.container'
  )
);

const SubmissionsMenu = menuBuilder([
  {
    Icon: SubmissionIcon,
    name: 'Submissions',
  },
]).addSubmenu([
  {
    name: 'Base Tests',
    path: '/submissions/base-tests',
    exact: true,
    component: BaseTestsList,
    ability: 'submissions_test_admin_base',
  },
  {
    path: '/submissions/base-tests/:testCategory/:exerciseId/answers',
    exact: true,
    component: BaseTestListAnswers,
    hidden: true,
    ability: 'submissions_test_admin_base',
  },
  {
    path: '/submissions/base-tests/:questionType/:exerciseId/answers/:questionId',
    component: BaseTestListQuestions,
    hidden: true,
    ability: 'submissions_test_admin_base',
  },
  {
    name: 'Custom Tests',
    path: '/submissions/custom-tests',
    exact: true,
    component: CustomTestList,
    ability: 'submissions_test_admin_custom',
  },
  {
    path: '/submissions/custom-tests/:testCategory/:exerciseId/answers',
    exact: true,
    component: CustomTestListAnswers,
    hidden: true,
    ability: 'submissions_test_admin_custom',
  },
  {
    path: '/submissions/custom-tests/:questionType/:exerciseId/answers/:questionId',
    component: CustomTestListQuestions,
    hidden: true,
    ability: 'submissions_test_admin_custom',
  },
  {
    name: 'Invalid Tests',
    path: '/submissions/invalid-tests',
    exact: true,
    component: InvalidTestsList,
    ability: 'submissions_test_admin_invalid',
  },
  {
    path: '/submissions/invalid-tests/:testCategory/:exerciseId/answers',
    exact: true,
    component: InvalidTestsListAnswers,
    hidden: true,
    ability: 'submissions_test_admin_invalid',
  },
  {
    path: '/submissions/invalid-tests/:questionType/:exerciseId/answers/:questionId',
    component: InvalidTestsListQuestions,
    hidden: true,
    ability: 'submissions_test_admin_invalid',
  },
  {
    name: 'Practice',
    path: '/submissions/practice-results',
    exact: true,
    component: PracticeResultsList,
    ability: 'submissions_test_admin_invalid', // TODO colocar ability que vier na api
  },
  {
    path: '/submissions/practice-results/:testCategory/:exerciseId/answers',
    exact: true,
    component: PracticeResultsListAnswers,
    hidden: true,
    ability: 'submissions_test_admin_invalid', // TODO colocar ability que vier na api
  },
  {
    path: '/submissions/practice-results/:questionType/:exerciseId/answers/:questionId',
    component: PracticeResultsListQuestions,
    hidden: true,
    ability: 'submissions_test_admin_invalid', // TODO colocar ability que vier na api
  },
]);

export default SubmissionsMenu;
