import { createSlice } from '@reduxjs/toolkit';
import { request } from 'modules/Api/HttpClient';
import {
  INTEGRATIONS_URL,
  INTEGRATIONS_LIST_URL,
  INTEGRATION_BY_ID_URL,
  INTEGRATIONS_CREATE_URL,
  INTEGRATIONS_UPDATE_URL,
} from 'modules/Api/Routes';
import { formDataFromObj } from 'modules/Api/RequestData';
import { createIntegrationRequestModel } from 'modules/Softwares/Integrations/IntegrationUtils';
import {
  getUTCDefaultStartDate,
  getUTCDefaultEndDate,
  getISOStringFromStringDate,
} from 'modules/Utils/Date';

const currentStartDate = getUTCDefaultStartDate();
const currentEndDate = getUTCDefaultEndDate();

const initialState = {
  loading: false,
  error: null,
  data: {
    integrations: [],
    page: 1,
    perPage: 10,
    startDate: currentStartDate,
    endDate: currentEndDate,
    softwareId: '',
    groupId: '',
    search: '',
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
  selectedIntegration: {
    data: {},
    loading: false,
    error: null,
  },
  isDeleting: false,
  isResetting: false,
};

const integrationsSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    requestIntegration: (state) => {
      state.loading = true;
      state.error = null;
    },
    requestSelectedIntegration: (state, action) => {
      state.selectedIntegration.loading = true;
      state.selectedIntegration.error = null;
      state.selectedIntegration.data = {
        id: action?.payload || null,
      };
    },
    changePage: (state, action) => {
      state.data.page = action.payload;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsDeleting: (state, action) => {
      state.isDeleting = action.payload;
    },
    setIsResetting: (state, action) => {
      state.isResetting = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    /**
     * receive a success group list response
     */
    receiveIntegrationList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        integrations: action.payload.integrations,
        total: action.payload.total_items,
      };
    },
    receiveSelectedIntegration: (state, action) => {
      state.selectedIntegration = {
        ...state.selectedIntegration,
        data: action.payload,
        loading: false,
        error: null,
      };
    },
    clearIntegrationsList: (state) => {
      state.loading = false;
      state.data = {
        page: 1,
        perPage: 10,
        integrations: [],
        startDate: currentStartDate,
        endDate: currentEndDate,
        softwareId: '',
        groupId: '',
        search: '',
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
    },
    clearSelectedIntegration: (state) => {
      state.selectedIntegration = {
        ...state.selectedIntegration,
        data: {
          external_software_id:
            state.selectedIntegration?.data?.external_software_id || null,
        },
        loading: false,
        error: null,
      };
    },
    /**
     * receive an error response
     */
    receiveIntegrationError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    receiveSelectedIntegrationError: (state, action) => {
      state.selectedIntegration.loading = false;
      state.selectedIntegration.error = action.payload;
    },
    changeIntegrationsFilters: (state, action) => {
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
      state.data.startDate = action?.payload.start_date || currentStartDate;
      state.data.endDate = action?.payload.end_date || currentEndDate;
      state.data.groupId = action?.payload.group_id || '';
      state.data.softwareId = action?.payload.software_id || '';
      state.data.search = action?.payload.search || '';
    },
    updateIntegrationOnList: (state, action) => {
      const updIntegration = action.payload;
      const index = state.data.integrations.findIndex(
        (integration) => integration.id === updIntegration.id
      );
      if (index >= 0) state.data.integrations.splice(index, 1, updIntegration);
    },
  },
});

const Actions = integrationsSlice.actions;

const Selectors = {
  fetchListData: (state) => state.integrations,
  integrationLoading: ({ integrations: { loading } }) => ({
    loading,
  }),
};

const Async = {
  fetchIntegrationsList: () => async (dispatch, getState) => {
    const {
      integrations: {
        data: {
          page,
          perPage,
          startDate,
          endDate,
          groupId,
          softwareId,
          search,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    let action;

    dispatch(Actions.requestIntegration());

    try {
      const response = await request({
        method: 'GET',
        url: INTEGRATIONS_LIST_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          start_date: getISOStringFromStringDate(startDate),
          end_date: getISOStringFromStringDate(endDate),
          external_software_id: ['', 'all', null].includes(softwareId)
            ? undefined
            : softwareId,
          group_id: ['', 'all', null].includes(groupId) ? undefined : groupId,
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receiveIntegrationList(response.data.content);
    } catch (e) {
      action = Actions.receiveIntegrationError(e.message);
    }

    dispatch(action);
  },

  fetchIntegrationsListForSelect: () => async (dispatch) => {
    let action;

    dispatch(Actions.requestIntegration());

    try {
      const response = await request({
        method: 'GET',
        url: INTEGRATIONS_LIST_URL,
      });

      action = Actions.receiveIntegrationList(response.data.content);
    } catch (e) {
      action = Actions.receiveIntegrationError(e.message);
    }

    dispatch(action);
  },

  getIntegrationById:
    ({ id }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestSelectedIntegration(id));

      try {
        const response = await request({
          method: 'GET',
          url: `${INTEGRATION_BY_ID_URL}?id=${id}`,
        });

        action = Actions.receiveSelectedIntegration(response.data.content);
      } catch (e) {
        action = Actions.receiveSelectedIntegrationError(e.message);
      }

      dispatch(action);
    },

  createIntegration:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestIntegration());
      try {
        const parsedIntegration = createIntegrationRequestModel(data);
        delete parsedIntegration.id;

        const integrationData = formDataFromObj(parsedIntegration);

        const response = await request({
          method: 'POST',
          url: INTEGRATIONS_CREATE_URL,
          data: integrationData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveIntegrationError());
        onError(e);
      }
    },

  updateIntegration:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestIntegration());
      try {
        const parsedIntegration = createIntegrationRequestModel(data);

        const integrationData = formDataFromObj(parsedIntegration);

        const response = await request({
          method: 'PUT',
          url: INTEGRATIONS_UPDATE_URL,
          data: integrationData,
        });

        const updatedIntegration = response.data.content;
        dispatch(Actions.updateIntegrationOnList(updatedIntegration));
        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveIntegrationError());
        onError(e);
      }
    },

  deleteIntegration:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.setIsDeleting(true));

      try {
        const response = await request({
          method: 'DELETE',
          url: `${INTEGRATIONS_URL}/${id}`,
        });

        onSuccess(response);
        dispatch(Actions.setIsDeleting(false));
      } catch (e) {
        onError(e);
        dispatch(Actions.setIsDeleting(false));
      }
    },

  resetIntegration:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.setIsResetting(true));

      try {
        const response = await request({
          method: 'PUT',
          url: `${INTEGRATIONS_URL}/${id}`,
        });

        onSuccess(response);
        dispatch(Actions.setIsResetting(false));
      } catch (e) {
        onError(e);
        dispatch(Actions.setIsResetting(false));
      }
    },
};

const reducer = integrationsSlice.reducer;

export { reducer, Actions, Async, Selectors };
