import {
  ResultsIcon,
  ContentIcon,
  UsersIcon,
  TeachersIcon,
  PricingIcon,
  LicenseIcon,
  OverallIcon,
  SubmissionIcon,
  WhitelabelIcon,
  AntifraudIcon,
  SettingsIcon,
  EProctoringPluginIcon,
} from '@edusynch/edusynch-svg-icons';

const RoutesShortcuts = [
  {
    name: 'Results',
    Icon: ResultsIcon,
    abilities: [
      'results_practice_student',
      'results_practice_group',
      'results_practice_unit',
      'results_practice_school',
      'results_practice_class',
      'results_test_group',
      'results_test_unit',
      'results_test_school',
      'results_test_class',
      'results_custom_group',
      'results_custom_unit',
      'results_custom_school',
      'results_custom_class',
    ],
  },
  {
    name: 'Content',
    Icon: ContentIcon,
    hidden: true,
  },
  {
    name: 'Users',
    Icon: UsersIcon,
    abilities: [
      'users_super_admin',
      'users_group_admin',
      'users_unit_admin',
      'users_school_admin',
      'users_teachers',
      'students',
      'evaluators',
    ],
  },
  {
    name: 'Institutions',
    Icon: TeachersIcon,
    abilities: ['groups', 'unit', 'school', 'classes'],
  },
  {
    name: 'Pricings',
    Icon: PricingIcon,
    hidden: true,
  },
  {
    name: 'Licenses',
    Icon: LicenseIcon,
    path: 'licenses',
    abilities: ['licenses'],
  },
  {
    name: 'Overall Scoring',
    Icon: OverallIcon,
    path: '/overall-scoring',
    abilities: ['overall_scorings'],
  },
  {
    name: 'Submissions',
    Icon: SubmissionIcon,
    abilities: ['submissions_test_admin_base', 'submissions_test_admin_custom'],
  },
  {
    name: 'Whitelabel',
    Icon: WhitelabelIcon,
    path: '/whitelabel',
    hidden: true,
  },
  {
    name: 'E-Proctoring',
    Icon: AntifraudIcon,
    abilities: [
      'antifrauds',
      'antifraud_base_tests',
      'antifraud_custom_tests',
      'antifraud_plugin_sessions',
    ],
  },
  {
    name: 'Settings',
    Icon: SettingsIcon,
    hidden: true,
    path: '/settings',
  },
  {
    name: 'Proctoring Settings',
    Icon: EProctoringPluginIcon,
    abilities: ['incident_level'],
    path: '/plugin_levels_settings',
  },
];

export default RoutesShortcuts;
