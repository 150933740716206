import styled, { css } from 'styled-components';

const getColor = (props) => {
  if (props.color) {
    return props.color;
  }

  if (props.theme) {
    return (props) => props.theme.config.colors.primary;
  }

  return 'inherit';
};

const getSize = (props) => {
  if (props.small) {
    return props.theme.config.spacing.spacing_5;
  }

  if (props.medium) {
    return '5.6rem';
  }

  if (props.large) {
    return '6.4rem';
  }

  if (props.xlarge) {
    return '8rem';
  }

  return props.theme.config.spacing.spacing_7;
};

const IconRoundBox = styled.div`
  background-color: ${(props) => props.theme.config.colors.light};
  width: ${(props) => getSize(props)};
  height: ${(props) => getSize(props)};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg path {
    fill: ${getColor};
  }

  ${(props) =>
    props.withBg &&
    css`
      background-color: ${(props) =>
        props.bg || props.theme.config.colors.gray_200};
    `}
`;

export { IconRoundBox };
