import styled from 'styled-components/macro';

const TextArea = styled.textarea`
  display: block;
  width: 100%;
  resize: none;
  background: ${(props) => props.theme.config.colors.light};
  border: 1px solid ${(props) => props.theme.config.colors.gray_300};
  border-radius: 6px;
  padding: 12px 10px;
  min-height: 240px;
`;

export { TextArea };
