import styled, { css } from 'styled-components';
import { device } from 'modules/Utils/Devices';

export const DropContainer = styled.div.attrs({
  className: 'dropzone',
})`
  cursor: pointer;
  display: flex;
  align-items: center;
  ${(props) =>
    props.isUploadError &&
    css`
      justify-content: center;
    `};
  padding: ${(props) => props.theme.config.spacing.spacing_3};
  background: ${(props) => props.theme.config.colors.primary_100};
  border: 2px dashed ${(props) => props.theme.config.colors.primary_200};
  border-radius: 0.6rem;
  color: ${(props) => props.theme.config.colors.primary};
  ${({ isDragActive }) => isDragActive && dragActive}
  ${({ isDragReject }) => isDragReject && dragReject}

  svg {
    path {
      fill: ${(props) => props.theme.config.colors.primary};
    }
  }

  p {
    text-align: left;
    ${(props) => props.theme.config.typograph.mico};

    @media ${device.mobile} {
      padding-left: ${(props) => props.theme.config.spacing.spacing_3};
    }
  }
`;

const dragActive = css`
  border-color: ${(props) => props.theme.config.colors.green};
`;

const dragReject = css`
  border-color: ${(props) => props.theme.config.colors.tertiary};
`;

export const UploadMessage = styled.div`
  width: ${(props) => (props.isUploadError ? '160px' : '100%')};

  p {
    ${(props) =>
      props.isSelectedFile
        ? props.theme.config.typograph.xsmall
        : props.theme.config.typograph.mico};

    span {
      &:first-child {
        ${(props) => props.theme.config.typograph.xsmall};
        font-weight: bold;
      }
    }
  }

  progress {
    width: 100%;
    margin-top: ${(props) => props.theme.config.spacing.spacing_2};
  }

  div {
    display: flex;
    justify-content: space-between;

    span {
      &:first-child {
        opacity: 0.5;
      }
      ${(props) => props.theme.config.typograph.mico};
    }
  }
`;
