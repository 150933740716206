import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Async,
  Actions,
  Selectors as MaintenanceSelectors,
} from 'modules/Maintenance/MaintenanceSlice';
import { IS_MAINTENANCE } from 'modules/Maintenance/Utils/Maintenance.utils';

export const useMaintenance = () => {
  const state = useSelector(MaintenanceSelectors.fetchMaintenance);
  const dispatch = useDispatch();

  const hideMaintenanceTopBar = useMemo(() => {
    return (
      IS_MAINTENANCE ||
      !state.scheduledMaintenance ||
      !state.maintenanceDescription ||
      state.closedByUser
    );
  }, [
    state.scheduledMaintenance,
    state.maintenanceDescription,
    state.closedByUser,
  ]);

  useEffect(() => {
    if (!IS_MAINTENANCE && !state.closedByUser) {
      dispatch(Async.fetchMaintenanceStatus());
    }
  }, [state.closedByUser]);

  const handleCloseByUser = () => {
    dispatch(Actions.changeClosedByUser());
  };

  return {
    scheduledMaintenance: state.scheduledMaintenance,
    maintenanceDescription: state.maintenanceDescription,
    closedByUser: state.closedByUser,
    handleCloseByUser,
    hideMaintenanceTopBar,
  };
};
