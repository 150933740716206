import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  OVERALL_SCORINGS_URL,
  OVERALL_SCORING_BY_ID_URL,
  OVERALL_SCORINGS_APROVED_URL,
  OVERALL_SCORINGS_UPDATE_URL,
  OVERALL_SCORINGS_GENERATE_CERTIFICATE_URL,
  OVERALL_SCORINGS_GENERATE_REPORT_URL,
  OVERALL_SCORINGS_CHANGE_EVALUATOR_URL,
  OVERALL_SCORINGS_EXPORT_URL,
} from 'modules/Api/Routes';
import { formDataFromObj } from 'modules/Api/RequestData';
import { getISOStringFromStringDate } from 'modules/Utils';
import {
  getUTCDefaultStartDate,
  getUTCDefaultEndDate,
} from 'modules/Utils/Date';

const currentStartDate = getUTCDefaultStartDate();
const currentEndDate = getUTCDefaultEndDate();

let cancelToken;

const initialState = {
  loading: false,
  error: null,
  exportFilter: {
    evaluator: '',
    startDate: currentStartDate,
    endDate: currentEndDate,
    extension: 'csv',
    link: '',
    loading: false,
  },
  data: {
    page: 1,
    perPage: 10,
    evaluator_id: '',
    search: '',
    startDate: currentStartDate,
    endDate: currentEndDate,
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
};

const overallScoringSlice = createSlice({
  name: 'overallScoring',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    changeExportFilter: (state, action) => {
      state.exportFilter = {
        ...state.exportFilter,
        [action.payload?.param]: action.payload?.value,
      };
    },
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    requestOverallScoring: (state) => {
      state.loading = true;
      state.error = null;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    /**
     * receive a success overallScoring list response
     */
    receiveOverallScoringList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        overallScoring: action.payload.overall_scorings,
        url_export_csv: action.payload.url_export_csv,
        url_export_xls: action.payload.url_export_xls,
        total: action.payload.total_items,
      };
    },
    clearOverallScoringList: (state) => {
      state.data = {
        page: 1,
        perPage: 10,
        search: '',
        evaluator_id: '',
        startDate: currentStartDate,
        endDate: currentEndDate,
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
    },
    /**
     * receive an error response
     */
    receiveOverallScoringError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changeOverallScoringPage: (state, action) => {
      state.data.page = action.payload;
    },
    changeOverallScoringFilters: (state, action) => {
      state.data.evaluator_id = action.payload.evaluator_id || '';
      state.data.startDate = action.payload.start_date || currentStartDate;
      state.data.endDate = action.payload.end_date || currentEndDate;
      state.data.search = action.payload.search;
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
      state.exportFilter.evaluator = action.payload.evaluator_id;
      state.exportFilter.startDate =
        action.payload?.start_date || currentStartDate;
      state.exportFilter.endDate = action.payload?.end_date || currentEndDate;
    },
    clearOverallScoringFilters: (state) => {
      state.data.evaluator_id = '';
      state.data.search = '';
      state.data.page = 1;
      state.data.perPage = 10;
      state.data.startDate = currentStartDate;
      state.data.endDate = currentEndDate;
      state.data.sort.sortType = null;
      state.data.sort.sortBy = null;
      state.exportFilter.evaluator = '';
      state.exportFilter.startDate = currentStartDate;
      state.exportFilter.endDate = currentEndDate;
    },
    updateOverallObjOnList: (state, action) => {
      const updOverallObj = action.payload;
      const index = state.data.overallScoring.findIndex(
        (overallScoring) => overallScoring.id === updOverallObj.id
      );
      if (index !== -1)
        state.data.overallScoring.splice(index, 1, updOverallObj);
    },
    requestExportLinks: (state) => {
      state.exportFilter.link = '';
      state.exportFilter.loading = true;
    },
    receiveExportLinks: (state, action) => {
      state.exportFilter.loading = false;
      state.exportFilter.link =
        action?.payload?.[`link_download_${state.exportFilter.extension}`];
    },
    receiveExportLinksError: (state, action) => {
      state.exportFilter.loading = false;
      state.error = action.payload;
    },
  },
});

const Actions = overallScoringSlice.actions;

const Selectors = {
  fetchListData: (state) => state.overallScoring,
  overallScoringLoading: ({ overallScoring: { loading } }) => ({ loading }),
};

const Async = {
  fetchOverallScoringList: () => async (dispatch, getState) => {
    const {
      overallScoring: {
        data: {
          page,
          perPage,
          search,
          evaluator_id,
          startDate,
          endDate,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestOverallScoring());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: OVERALL_SCORINGS_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          evaluator_id,
          start_date: getISOStringFromStringDate(startDate || currentStartDate),
          end_date: getISOStringFromStringDate(endDate || currentEndDate),
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receiveOverallScoringList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveOverallScoringError(e.message);
      }
    }

    action && dispatch(action);
  },

  getOverallScoringById:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestOverallScoring());

      try {
        const response = await request({
          method: 'GET',
          url: `${OVERALL_SCORING_BY_ID_URL}?id=${id}`,
        });

        action = Actions.receiveRequestSuccess();
        onSuccess(response);
      } catch (e) {
        action = Actions.setLoading(false);
        onError(e);
      }

      dispatch(action);
    },

  setApproved:
    ({ data: studentOverallScoring, approved, onSuccess, onError }) =>
    async () => {
      try {
        const data = new FormData();
        data.set('id', studentOverallScoring.id);
        data.set('approved', approved);

        const response = await request({
          method: 'PUT',
          url: OVERALL_SCORINGS_APROVED_URL,
          data,
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  updateOverallScoring:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestOverallScoring());
      try {
        const overallScoringData = formDataFromObj(data);

        const response = await request({
          method: 'PUT',
          url: OVERALL_SCORINGS_UPDATE_URL,
          data: overallScoringData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.setLoading(false));
        onError(e);
      }
    },

  generateCertificatePDF:
    ({ id, studentId, onSuccess, onError }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestOverallScoring());

      try {
        const response = await request({
          method: 'GET',
          url: `${OVERALL_SCORINGS_GENERATE_CERTIFICATE_URL}/${studentId}/${id}`,
          responseType: 'blob',
        });

        action = Actions.receiveRequestSuccess();
        onSuccess(response);
      } catch (e) {
        action = Actions.setLoading(false);
        onError(e);
      }

      dispatch(action);
    },

  generateELLTReport:
    ({ id, studentId, onSuccess, onError }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestOverallScoring());

      try {
        const response = await request({
          method: 'GET',
          url: `${OVERALL_SCORINGS_GENERATE_REPORT_URL}/${studentId}/${id}`,
          responseType: 'blob',
        });

        action = Actions.receiveRequestSuccess();
        onSuccess(response);
      } catch (e) {
        action = Actions.setLoading(false);
        onError(e);
      }

      dispatch(action);
    },

  changeEvaluator:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestOverallScoring());
      try {
        const parsedOverallScoringObj = {
          id: data.id,
          evaluator_id: data.evaluatorId,
        };

        const overallObjData = formDataFromObj(parsedOverallScoringObj);

        const response = await request({
          method: 'PUT',
          url: OVERALL_SCORINGS_CHANGE_EVALUATOR_URL,
          data: overallObjData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.setLoading(false));
        onError(e);
      }
    },

  fetchExportSubmit:
    ({ onSuccess, onError }) =>
    async (dispatch, getState) => {
      const {
        overallScoring: {
          data: { search },
          exportFilter: { evaluator, startDate, endDate, extension },
        },
      } = getState();
      let action;

      dispatch(Actions.requestExportLinks());

      const data = {
        file_type: extension,
        start_date: getISOStringFromStringDate(startDate),
        end_date: getISOStringFromStringDate(endDate),
        search,
      };

      evaluator && evaluator !== 'all' && (data.evaluator_id = evaluator);

      try {
        const response = await request({
          method: 'POST',
          url: OVERALL_SCORINGS_EXPORT_URL,
          data,
        });

        action = Actions.receiveExportLinks(response.data.content);
        onSuccess(response.data.content);
      } catch (e) {
        action = Actions.receiveExportLinksError(e.message);
        onError();
      }

      dispatch(action);
    },
};

const { reducer } = overallScoringSlice;

export { reducer, Actions, Async, Selectors };
