import { useSelector, useDispatch } from 'react-redux';
import { Selectors, Async } from 'modules/Dashboard/DashboardSlice';
import { useAuth } from 'modules/Auth/Hooks';

export const useDashboardWidgets = () => {
  const state = useSelector(Selectors.fetchListData);
  const dispatch = useDispatch();

  const { userWidgets } = useAuth();

  const fetchDashboardWidgets = () => {
    dispatch(Async.fetchDashboardWidgetsList());
  };

  return {
    fetchDashboardWidgets,
    state,
    userWidgets,
  };
};
