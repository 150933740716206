import styled from 'styled-components';

const Line = styled.div`
  background-color: ${(props) => props.theme.config.colors.gray_300};
  opacity: 0.3;
  width: 150%;
  left: -25%;
  height: 0.1rem;
  position: relative;
`;

export { Line };
