import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import * as S from './PaginationSelect.styles';
import { Icon } from 'modules/Core/Common';
import { useToggleDropdown } from 'modules/Core/Hooks';
import i18n from 'i18next';

const CustomSelectContext = createContext();

const generateData = (value) => {
  if (value >= 50) {
    return [50, 40, 30, 20, 10];
  } else if (value === 10) {
    return [10];
  } else {
    const generateArray = Array.from(Array(value).keys());
    return generateArray
      .filter((item) => item % 10 === 0 && item >= 10)
      .reverse();
  }
};

const useCustomSelectContext = () => {
  const context = useContext(CustomSelectContext);
  if (!context) {
    throw new Error(i18n.t('errors.error-custom-select'));
  }
  return context;
};

const Option = ({ text, value }) => {
  const { onToggleClick, selected, setSelected, setShowDropdown } =
    useCustomSelectContext();

  return (
    <S.Item
      onClick={() => {
        setSelected(text);
        onToggleClick(value);
        setShowDropdown(false);
      }}
    >
      {text} {selected === text && <Icon name="check" />}
    </S.Item>
  );
};

const Select = ({ perPage, total, onToggleClick }) => {
  const dropdownRef = React.useRef();
  const buttonRef = React.useRef();
  const [selected, setSelected] = useState('');

  const { showDropdown, setShowDropdown } = useToggleDropdown(
    buttonRef,
    dropdownRef
  );

  useEffect(() => {
    setSelected(perPage);
    return () => setSelected('');
  }, [perPage]);

  const values = useMemo(
    () => ({
      selected,
      setSelected,
      onToggleClick,
      setShowDropdown,
    }),
    [selected, onToggleClick, setSelected, setShowDropdown]
  );

  const data = generateData(total);

  return (
    <CustomSelectContext.Provider value={values}>
      <S.ButtonContainer isShowDropdown={showDropdown}>
        <S.Button
          ref={buttonRef}
          onClick={() => setShowDropdown(!showDropdown)}
        >
          {selected} <Icon name="arrow-down" />
        </S.Button>
        {showDropdown && (
          <S.DropdownContainer>
            <S.Dropdown ref={dropdownRef}>
              {data.map((item) => (
                <Option key={item} text={item} value={item} />
              ))}
            </S.Dropdown>
          </S.DropdownContainer>
        )}
      </S.ButtonContainer>
    </CustomSelectContext.Provider>
  );
};

Select.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  perPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onToggleClick: PropTypes.func,
  total: PropTypes.any,
};

Option.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.any,
};

export { Select as CustomSelect };
