import React, { useState, useEffect, createRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import * as S from './DragAndDrop.styles';

const DragAndDrop = ({ children, handleDroppedImage }) => {
  const imageElement = createRef();
  const [dragging, setDragging] = useState(false);
  let dragCounter = 0;

  const dragEnter = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setDragging(true);
      dragCounter++;
    },
    [dragging]
  );

  const dragLeave = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setDragging(false);
      dragCounter--;
    },
    [dragging]
  );

  const dragOver = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
    },
    [dragging]
  );

  const dragDrop = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      handleDroppedImage(event);
      setDragging(false);
      dragCounter = 0;
    },
    [dragging, dragCounter]
  );

  useEffect(() => {
    const elementRef = imageElement.current;

    elementRef.addEventListener('dragenter', dragEnter);
    elementRef.addEventListener('dragleave', dragLeave);
    elementRef.addEventListener('dragover', dragOver);
    elementRef.addEventListener('drop', dragDrop);

    return () => {
      elementRef.removeEventListener('dragenter', dragEnter);
      elementRef.removeEventListener('dragleave', dragLeave);
      elementRef.removeEventListener('dragover', dragOver);
      elementRef.removeEventListener('drop', dragDrop);
    };
  }, [dragging, imageElement]);

  return (
    <div ref={imageElement}>
      {dragging ? (
        <S.Avatar>
          <S.AvatarText>
            {i18n.t('commons.image-upload.drop-image')}
          </S.AvatarText>
        </S.Avatar>
      ) : (
        children
      )}
    </div>
  );
};

DragAndDrop.propTypes = {
  children: PropTypes.element,
  handleDroppedImage: PropTypes.func,
};

export { DragAndDrop };
