import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  SETTINGS_FETCH_AI_EVALUATOR_URL,
  SETTINGS_UPDATE_AI_EVALUATOR_URL,
  SETTINGS_DELETE_IMAGE_URL,
} from 'modules/Api/Routes';
import { AI_EVALUATOR_AVATAR } from 'modules/Settings/SettingsUtils';

import { formDataFromObj } from 'modules/Api/RequestData';
import { createAIEvaluatorRequest } from './AIEvaluatorUtils';

const AI_EVALUATOR_INITIAL_STATE = {
  ai_evaluator_name: '',
  ai_evaluator_description: '',
  ai_evaluator_avatar: null,
};

let cancelToken;

const initialState = {
  loading: false,
  update_loading: false,
  error: null,
  data: AI_EVALUATOR_INITIAL_STATE,
};

const AIEvaluatorSlice = createSlice({
  name: 'ai_evaluator',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    changeAIEvaluatorField: (state, action) => {
      state.data[action.payload.key] = action.payload.value;
    },
    requestAIEvaluator: (state) => {
      state.loading = true;
      state.error = null;
    },
    /**
     * receive a success whitelabel list response
     */
    receiveAIEvaluatorData: (state, action) => {
      const { ai_evaluator_avatar } = action.payload;
      state.loading = false;
      state.data = {
        ...state.data,
        ...action.payload,
        ai_evaluator_avatar: ai_evaluator_avatar?.url || null,
      };
    },
    receiveAIEvaluatorDataError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    requestUpdateAIEvaluator: (state) => {
      state.update_loading = true;
    },
    receiveUpdateAIEvaluator: (state, action) => {
      const { ai_evaluator_avatar } = action.payload;
      state.update_loading = false;
      state.data = {
        ...state.data,
        ...action.payload,
        ai_evaluator_avatar: ai_evaluator_avatar?.url || null,
      };
    },
    receiveRemoveAvatar: (state) => {
      state.data.ai_evaluator_avatar = null;
    },
    receiveUpdateAIEvaluatorError: (state, action) => {
      state.update_loading = false;
      state.error = action.payload;
    },
  },
});

const Actions = AIEvaluatorSlice.actions;

const Selectors = {
  initialEvaluatorData: (state) => state.aiEvaluator,
  aiEvaluatorLoading: ({ aiEvaluator: { loading } }) => ({ loading }),
};

const Async = {
  fetchAIEvaluator: () => async (dispatch) => {
    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestAIEvaluator());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: SETTINGS_FETCH_AI_EVALUATOR_URL,
      });

      action = Actions.receiveAIEvaluatorData(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveAIEvaluatorDataError(e.message);
      }
    }

    action && dispatch(action);
  },

  updateAIEvaluator:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      ejectCancelInterceptor();
      cancelToken?.cancel();
      cancelToken = generateCancelToken();
      dispatch(Actions.requestUpdateAIEvaluator());
      try {
        const requestData = createAIEvaluatorRequest(data);
        const sanitizedData = formDataFromObj(requestData);

        const response = await request({
          cancelToken: cancelToken.token,
          method: 'PUT',
          url: SETTINGS_UPDATE_AI_EVALUATOR_URL,
          data: sanitizedData,
        });

        dispatch(Actions.receiveUpdateAIEvaluator(response.data.content));
        onSuccess();
      } catch (e) {
        if (!isCancel(e)) {
          dispatch(Actions.receiveUpdateAIEvaluatorError(e.message));
        }
        dispatch(Actions.setLoading(false));
        onError(e);
      }
    },

  removeAvatar:
    ({ id, onSuccess, onError }) =>
    async () => {
      try {
        const response = await request({
          method: 'PUT',
          url: SETTINGS_DELETE_IMAGE_URL,
          data: {
            id,
            type: AI_EVALUATOR_AVATAR,
          },
        });
        if (onSuccess) onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },
};

const { reducer } = AIEvaluatorSlice;

export { reducer, Actions, Async, Selectors };
