import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip, Icon } from 'modules/Core/Common';

import { TooltipDetails } from './ShowDetailsIcon.styles';

const ShowDetailsIcon = ({ data, iconName, noDataText, ...props }) => {
  return (
    <Tooltip
      placement="bottom"
      overlay={
        <TooltipDetails>
          {data && data.length ? (
            data.map((text, index) => <p key={index}>{text}</p>)
          ) : (
            <p>{noDataText}</p>
          )}
        </TooltipDetails>
      }
      overlayInnerStyle={{
        backgroundColor: '#0075EA',
        borderRadius: '4px',
        padding: '16px',
      }}
    >
      <Icon name={iconName} {...props} />
    </Tooltip>
  );
};

ShowDetailsIcon.defaultProps = {
  iconName: 'Show Details',
  noDataText: 'No Details',
};

ShowDetailsIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  data: PropTypes.array,
  noDataText: PropTypes.string,
};

export default ShowDetailsIcon;
