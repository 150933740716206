import * as Yup from 'yup';

export default Yup.object().shape({
  password: Yup.string().min(6).required(),
  password_confirmation: Yup.string()
    .when('password', {
      is: (val) => val && !!val.length,
      then: Yup.string().required('password confirmation can\t be blank'),
      otherwise: Yup.string(),
    })
    .oneOf([Yup.ref('password'), undefined], 'incorrect confirmation'),
});
