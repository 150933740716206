import { menuBuilder } from 'services/Routes.service';
import { DashboardContainer } from 'modules/Dashboard';
import { ContentIcon } from '@edusynch/edusynch-svg-icons';

const ContentMenu = menuBuilder([
  {
    Icon: ContentIcon,
    name: 'Content',
    hidden: true,
    disabled: true,
  },
]).addSubmenu([
  {
    type: 'Content',
    name: 'Categories',
    path: '/group2',
    component: DashboardContainer,
    disabled: true,
    ability: 'content_categories',
  },
  {
    type: 'Content',
    name: 'Questions',
    path: '/unit2',
    component: DashboardContainer,
    disabled: true,
    ability: 'content_questions',
  },
  {
    type: 'Content',
    name: 'Passages',
    path: '/school2',
    component: DashboardContainer,
    disabled: true,
    ability: 'content_passages',
  },
  {
    type: 'Content',
    name: 'Tests',
    path: '/school2',
    component: DashboardContainer,
    disabled: true,
    ability: 'content_tests',
  },
  {
    type: 'Content',
    name: 'Simulations',
    path: '/school2',
    component: DashboardContainer,
    disabled: true,
    ability: 'content_simulations',
  },
  {
    type: 'Content',
    name: 'Instructions Simulate',
    path: '/school2',
    component: DashboardContainer,
    disabled: true,
    ability: 'content_instructions_simulations',
  },
]);

export default ContentMenu;
