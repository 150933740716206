import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Toast, Loading } from 'modules/Core/Common';
import { Selectors, Async } from 'modules/Auth/AuthSlice';
import { LoginForm } from 'modules/Auth/Login/LoginForm';
import { withTheme } from 'styled-components';
import * as S from './TokenExpiredLogin.styles';
import i18n from 'i18next';

const TokenExpiredLogin = ({ theme }) => {
  const loading = useSelector(Selectors.selectLoading);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    document.querySelector('html').style.overflow = 'hidden';

    return () => {
      document.querySelector('html').style.overflow = 'auto';
    };
  }, []);

  const handleSignIn = async (data) =>
    dispatch(
      Async.signIn(
        data,
        (e) => {
          Toast(e.message, 'error');
        },
        () => history.go(0)
      )
    );

  return (
    <S.Container>
      <S.FormContainer>
        <S.Title>{i18n.t('commons.buttons.sign-in-button')}</S.Title>
        <S.Description>
          {i18n.t('login.continue-using')}
          {` ${theme.name}`}
        </S.Description>
        {loading ? (
          <div>
            <Loading position="relative" />
          </div>
        ) : (
          <LoginForm onSubmit={handleSignIn} theme={theme} />
        )}
      </S.FormContainer>
    </S.Container>
  );
};

TokenExpiredLogin.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(TokenExpiredLogin);
