import React, { lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Auth } from 'modules/Core/Layout';

const Login = lazy(() => import('modules/Auth/Login/Login.container'));
const Forgot = lazy(() =>
  import('modules/Auth/ForgotPassword/ForgotPassword.container')
);
const NewPassword = lazy(() =>
  import('modules/Auth/NewPassword/NewPassword.container')
);

const AuthRoute = () => (
  <Auth>
    <Switch>
      <Route path="/auth/login" component={Login} />
      <Route path="/auth/forgot-password" component={Forgot} />
      <Route path="/auth/new-password" component={NewPassword} />

      <Redirect to="/auth/login" />
    </Switch>
  </Auth>
);

export default AuthRoute;
