import styled, { css } from 'styled-components';

const Container = styled.div`
  background-color: ${(props) => props.theme.config.colors.light};
  border-radius: 0.6rem;
  box-shadow: 0 0.2rem 0.6rem 0 rgba(0, 0, 0, 0.1);
  ${(props) =>
    props.overflow &&
    css`
      overflow: hidden;
    `}
  ${(props) =>
    props.margin &&
    css`
      margin-top: ${(props) => props.theme.config.spacing.spacing_3};
    `}
  ${(props) =>
    !props.noPadding &&
    css`
      padding: 2rem;
    `}
  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${(props) =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
`;

const Title = styled.div`
  margin-bottom: ${(props) => props.theme.config.spacing.spacing_5};
`;

export { Container, Title };
