import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Row, ListHeader, Col } from 'modules/Core/Common';

const HeaderList = ({
  Icon,
  onFilterClick,
  onAddClick,
  children,
  error,
  subject = 'all',
  actionsType = 'default',
  actions,
  defaultSearch,
  showSearchbar = true,
  ...props
}) => (
  <Grid fluid>
    {error && (
      <ListHeader
        {...props}
        IconName={Icon}
        subject={subject}
        showSearchbar={showSearchbar}
      >
        {actions && actions}
      </ListHeader>
    )}
    {!error && (
      <ListHeader
        {...props}
        IconName={Icon}
        onSaveFilterButtonClick={onFilterClick}
        onAddButtonClick={onAddClick}
        actionsType={actionsType}
        defaultSearch={defaultSearch}
        subject={subject}
        showSearchbar={showSearchbar}
      >
        {actions && actions}
      </ListHeader>
    )}

    <Row>
      <Col xs={12} sm={12} md={12} lg={12}>
        {children}
      </Col>
    </Row>
  </Grid>
);

HeaderList.propTypes = {
  error: PropTypes.string,
  data: PropTypes.shape({
    total: PropTypes.number,
    page: PropTypes.number,
    perPage: PropTypes.number,
  }),
  hasFilters: PropTypes.bool,
  onFilterClick: PropTypes.func,
  onAddClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  Icon: PropTypes.func.isRequired,
  children: PropTypes.any,
  isSearch: PropTypes.bool,
  subject: PropTypes.string,
  actionsType: PropTypes.string,
  actions: PropTypes.node,
  defaultSearch: PropTypes.string,
  showSearchbar: PropTypes.bool,
};

export default HeaderList;
