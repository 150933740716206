import styled from 'styled-components';

export const Container = styled.div`
  margin: 4rem 5.8rem;
  width: 300px;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: ${(props) => props.theme.config.colors.text_base};
  margin-bottom: ${(props) => props.theme.config.spacing.spacing_4};

  div {
    display: flex;
    justify-content: center;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-top: ${(props) => props.theme.config.spacing.spacing_3};
`;

export const SubTitle = styled.div`
  margin-top: ${(props) => props.theme.config.spacing.spacing_1};
  font-size: 14px;
  line-height: 16px;
`;

export const FiltersContainer = styled.div`
  margin: ${(props) => props.theme.config.spacing.spacing_3} 0;
`;

export const ButtonContainer = styled.div`
  button {
    width: 100%;
  }
`;
