import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import Intervals from 'modules/SettingsProfile/SidebarGeneralIntervals/Intervals';
import i18n from 'i18next';

import { Button, Loading } from 'modules/Core/Common';
import IntervalsSchema from './IntervalsSchema';

import * as S from './SidebarGeneralIntervals.styles';

const SidebarGeneralIntervals = ({ loading, user, onSave }) => {
  if (loading)
    return (
      <S.Container loading={loading.toString()}>
        <Loading position="relative" />
      </S.Container>
    );

  return (
    <S.Container>
      <Formik
        initialValues={{
          name: user?.name,
          email: user?.email,
          language: user?.language,
          timezone: user?.timezone,
          time_format: user?.time_format,
          intervals: user?.scheduler?.items,
          removedIntervals: [],
        }}
        validationSchema={IntervalsSchema}
        onSubmit={onSave}
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <>
              <Intervals
                handleChange={setFieldValue}
                setFieldValue={setFieldValue}
                values={values}
                user={user}
                isEdit
              />
              <S.SubmitButton>
                <Button
                  medium
                  type="submit"
                  hasLoading={loading}
                  loading={loading}
                  disabled={
                    !values?.intervals?.length &&
                    !values?.removedIntervals?.length >= 1
                  }
                  intervalValues={values}
                  onClick={() => onSave(values)}
                >
                  {i18n.t('commons.buttons.save-settings')}
                </Button>
              </S.SubmitButton>
            </>
          </Form>
        )}
      </Formik>
    </S.Container>
  );
};

SidebarGeneralIntervals.propTypes = {
  values: PropTypes.object,
  data: PropTypes.object,
  onSave: PropTypes.func,
  user: PropTypes.object,
  setFieldValue: PropTypes.func,
  handleChange: PropTypes.func,
  loading: PropTypes.bool,
};

export default SidebarGeneralIntervals;
