import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import * as S from './styles';
import './styles.css';
import 'rc-tooltip/assets/bootstrap.css';
import { Tooltip } from 'modules/Core/Common';
import { PendingIcon, CheckIcon } from '@edusynch/edusynch-svg-icons';

const ListTooltip = ({
  children,
  data,
  text,
  column,
  title,
  nameField,
  typeField,
  textView,
  hasIcon,
}) => {
  const [tooltipList, setTooltipList] = useState(text);
  const [tooltipText, setTooltipText] = useState([]);

  useEffect(() => {
    generatetooltipList();
    generateTooltipText();
  }, []);

  function generatetooltipList() {
    return data?.[column]?.length
      ? setTooltipList(
          `${data?.[column]?.length} ${
            data?.[column]?.length > 1 ? title : pluralize.singular(title || '')
          }`
        )
      : setTooltipList(text);
  }

  function generateTooltipText() {
    return data?.[column]?.length
      ? setTooltipText(data?.[column]?.map((value) => value))
      : setTooltipText([text]);
  }

  function getIconByType(type) {
    if (type?.toLowerCase().includes('pending')) {
      return <PendingIcon />;
    }

    return <CheckIcon />;
  }

  const getTooltipName = (item) => {
    if (item[nameField]) return item[nameField];
    if (item?.name) return item?.name;
    if (item && typeof item !== 'object') return item;
    return '-';
  };

  const getTooltipType = (item) => {
    if (item[typeField]) return item[typeField];
    if (item?.type) return item?.type;
    if (item && typeof item !== 'object') return item;
    return '-';
  };

  function overlayData() {
    if (hasIcon) {
      return (
        <S.Text>
          {tooltipText.map((text, index) => (
            <p key={index}>
              {getIconByType(text?.split(':')[1])}
              {`${text?.split(':')[0]}`}
            </p>
          ))}
        </S.Text>
      );
    }

    if (data?.[column]?.length < 2) {
      return (
        <S.TooltipItem hasItems>
          {getTooltipName(data?.[column]?.[0])}
        </S.TooltipItem>
      );
    }

    return (
      <S.Text>
        {tooltipText.map((raw, index) => {
          const text =
            raw?.evaluatable_type === 'StudentClass'
              ? { ...raw, evaluatable_type: 'Student Class' }
              : raw;

          return (
            <p key={index}>
              {getTooltipName(text) !== '-' && `${getTooltipType(text)}:`}{' '}
              {getTooltipName(text)}
            </p>
          );
        })}
      </S.Text>
    );
  }

  if (!data?.[column]?.length) {
    return (
      <S.TooltipItem hasItems={false}>{textView || tooltipList}</S.TooltipItem>
    );
  }

  if (data?.[column]?.length < 2) {
    if (getTooltipName(data?.[column]?.[0]).length > 20) {
      return (
        <Tooltip
          overlayClassName={tooltipText.includes(`No ${title}`) ? 'hide' : ''}
          overlay={getTooltipName(data?.[column]?.[0])}
        >
          <S.TooltipItem hasItems>
            {getTooltipType(data?.[column]?.[0]) !== '-' &&
              `${getTooltipType(data?.[column]?.[0])}:`}{' '}
            {`${getTooltipName(data?.[column]?.[0])?.slice(0, 20)}...`}
          </S.TooltipItem>
        </Tooltip>
      );
    }

    return (
      <S.TooltipItem hasItems>
        {getTooltipType(data?.[column]?.[0]) !== '-' &&
          `${getTooltipType(data?.[column]?.[0])}:`}{' '}
        {getTooltipName(data?.[column]?.[0])}
      </S.TooltipItem>
    );
  }

  return (
    <Tooltip
      overlayClassName={tooltipText.includes(`No ${title}`) ? 'hide' : ''}
      overlay={overlayData}
    >
      {children || (
        <S.TooltipItem hasItems={data?.[column]?.length}>
          {textView || tooltipList}
        </S.TooltipItem>
      )}
    </Tooltip>
  );
};

ListTooltip.propTypes = {
  children: PropTypes.element,
  data: PropTypes.object,
  text: PropTypes.string,
  column: PropTypes.string,
  title: PropTypes.string,
  nameField: PropTypes.string,
  typeField: PropTypes.string,
  textView: PropTypes.string,
  hasIcon: PropTypes.bool,
};

export default ListTooltip;
