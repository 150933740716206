import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { Line } from 'react-chartjs-2';
import { IconButton } from '@mui/material';

import {
  WidgetCard,
  WidgetCardHeader,
  WidgetCardSubHeader,
  WidgetCardContent,
  IntervalOptionsMenu,
} from 'modules/Dashboard/Widgets/Common';
import {
  getChartLabelsByWidgetSize,
  formatChartLabels,
  formatChartValues,
  formatChartScaleYStepSize,
  formatChartData,
  formatChartOptions,
} from 'modules/Dashboard/Widgets/Users/UserWidgets.utils';
import { useMediaQuery, TABLET_SIZE } from 'modules/Core/Hooks';
import {
  UsersIcon,
  SettingsIcon,
  UserRegisteredIcon,
} from '@edusynch/edusynch-svg-icons';
import i18n from 'i18next';
import * as S from './RegisteredUsers.styles';

export const RegisteredUsers = ({
  widget,
  intervalOption,
  onChangeIntervalOption,
  data,
  onOpenSettings,
  isMock,
}) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(TABLET_SIZE);

  const widgetSize = isTablet ? 'small' : widget?.settings?.size;

  const chartLabels = getChartLabelsByWidgetSize(data, widgetSize);
  const formattedChartLabels = formatChartLabels(chartLabels, intervalOption);
  const formattedChartData = formatChartValues(data);
  const chartScaleYStepSize = formatChartScaleYStepSize(formattedChartData);
  const chartData = formatChartData(
    formattedChartLabels,
    formattedChartData,
    theme.config.colors.primary,
    i18n.t('modules.dashboard.charts.titles.registred-users')
  );
  const chartOptions = formatChartOptions(chartScaleYStepSize);

  return (
    <WidgetCard size={widgetSize}>
      <WidgetCardHeader
        size={widgetSize}
        Icon={UserRegisteredIcon}
        title={data?.total_users?.toLocaleString() || ''}
        subtitle="Registered Users (Overall)"
        showSettingsButton={isMock && widgetSize === 'small'}
        onOpenSettings={onOpenSettings}
      >
        <WidgetCardSubHeader size={widgetSize}>
          <S.ContentFilter>
            <h6>
              Last {intervalOption}{' '}
              {intervalOption === '12' ? 'months' : 'days'}
            </h6>
            <div>
              <UsersIcon color="#ACABB7" />
              <p>{data?.total_by_day}</p>
            </div>
          </S.ContentFilter>
          <S.ContentAction>
            {isMock && widgetSize === 'large' && (
              <IconButton
                aria-label="settings"
                id="settings"
                onClick={onOpenSettings}
              >
                <SettingsIcon color="#ACABB7" />
              </IconButton>
            )}
            <IntervalOptionsMenu
              defaultOption={intervalOption}
              onChangeOption={onChangeIntervalOption}
            />
          </S.ContentAction>
        </WidgetCardSubHeader>
      </WidgetCardHeader>
      <WidgetCardContent size={widgetSize}>
        <Line data={chartData} options={chartOptions} />
      </WidgetCardContent>
    </WidgetCard>
  );
};

RegisteredUsers.propTypes = {
  widget: PropTypes.object,
  data: PropTypes.object,
  intervalOption: PropTypes.string,
  onChangeIntervalOption: PropTypes.func,
  onOpenSettings: PropTypes.func,
  isMock: PropTypes.bool,
};
