export const capitalizeWord = (
  word = '',
  allWords = false,
  separator = '_'
) => {
  if (!word) {
    return '';
  }
  if (separator === '_') {
    word = word.replace('_', ' ');
  }

  if (allWords) {
    return word
      .split(' ')
      .map((w) => `${w[0]?.toUpperCase()}${w?.substr(1)?.toLocaleLowerCase()}`)
      .join(' ');
  }

  return `${word[0]?.toUpperCase()}${word?.substr(1)?.toLocaleLowerCase()}`;
};
