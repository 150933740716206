import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  BASE_TESTS_URL,
  SUBSCRIPTIONS_URL,
  SUBSCRIPTION_BY_ID_URL,
  WHITELABELS_URL,
  SUBSCRIPTIONS_INFORMATION_URL,
} from 'modules/Api/Routes';
import { getISOStringFromStringDate } from 'modules/Utils';
import { defaultRequest } from 'modules/Utils/DefaultRequest';
import {
  STATUS_TRIALING,
  STATUS_TRIAL,
} from 'modules/Subscriptions/SubscriptionsUtils';

let cancelToken;

const initialState = {
  loading: false,
  error: null,
  data: {
    page: 1,
    perPage: 10,
    testId: null,
    whitelabelId: null,
    period: null,
    status: null,
    search: '',
    startDate: null,
    endDate: null,
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
  tests: {
    loading: false,
    data: [],
  },
  whitelabels: {
    loading: false,
    data: [],
  },
  subscriptionPurchases: {
    data: {
      page: 1,
      perPage: 10,
    },
    loading: false,
    error: null,
  },
  subscriptionsInformation: {
    data: null,
    loading: true,
    error: null,
  },
};

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    requestSubscriptions: (state) => {
      state.loading = true;
      state.error = null;
    },
    changeSubscriptionsPage: (state, action) => {
      state.data.page = action.payload;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    requestSubscriptionPurchases: (state) => {
      state.subscriptionPurchases.loading = true;
      state.subscriptionPurchases.error = null;
    },
    requestSubscriptionsInformation: (state) => {
      state.subscriptionsInformation.loading = true;
      state.subscriptionsInformation.error = null;
    },
    changeSubscriptionPurchasesPage: (state, action) => {
      state.subscriptionPurchases.data.page = action.payload;
    },
    changeSubscriptionPurchasesPerPage: (state, action) => {
      state.subscriptionPurchases.data.perPage = action.payload;
      state.subscriptionPurchases.data.page = 1;
    },
    receiveSubscriptionsList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        subscriptions: action.payload.subscriptions,
        total: action.payload.total_items,
      };
    },
    receiveSubscriptionPurchases: (state, action) => {
      state.subscriptionPurchases.loading = false;
      state.subscriptionPurchases.data = {
        ...state.subscriptionPurchases.data,
        info: action.payload.info,
        purchases: action.payload.purchases,
        total: action.payload.total_items,
      };
    },
    receiveSubscriptionsInformation: (state, action) => {
      state.subscriptionsInformation.loading = false;
      state.subscriptionsInformation.data = {
        ...state.subscriptionsInformation.data,
        new: action.payload.new_on_period || action.payload.new || 0,
        active: action.payload.total_active || action.payload.active || 0,
        renewed: action.payload.renewed_on_period || action.payload.active || 0,
        trial: action.payload.total_trial || action.payload.trial || 0,
        canceled:
          action.payload.canceled_on_period || action.payload.canceled || 0,
      };
    },
    clearSubscriptionsList: (state) => {
      state.data = {
        page: 1,
        perPage: 10,
        search: '',
        testId: null,
        whitelabelId: null,
        startDate: null,
        endDate: null,
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
    },
    clearSubscriptionPurchases: (state) => {
      state.subscriptionPurchases = {
        loading: false,
        data: {
          page: 1,
          perPage: 10,
        },
        error: null,
        total: 0,
      };
    },
    receiveSubscriptionsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    receiveSubscriptionPurchasesError: (state, action) => {
      state.subscriptionPurchases.loading = false;
      state.subscriptionPurchases.error = action.payload;
    },
    receiveSubscriptionsInformationError: (state, action) => {
      state.subscriptionsInformation.loading = false;
      state.subscriptionsInformation.error = action.payload;
    },
    changeSubscriptionsFilters: (state, action) => {
      state.data.testId = action.payload.test_id || null;
      state.data.whitelabelId = action.payload.whitelabel_id || null;
      state.data.period = action.payload.period || null;
      state.data.status = action.payload.status || null;
      state.data.startDate = action.payload.start_date || null;
      state.data.endDate = action.payload.end_date || null;
      state.data.search = action.payload.search;
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
    },
    clearSubscriptionsFilters: (state) => {
      state.data.testId = null;
      state.data.whitelabelId = null;
      state.data.period = null;
      state.data.status = null;
      state.data.search = '';
      state.data.page = 1;
      state.data.perPage = 10;
      state.data.startDate = null;
      state.data.endDate = null;
      state.data.sort.sortType = null;
      state.data.sort.sortBy = null;
    },
    requestTests: (state) => {
      state.tests.loading = true;
    },
    receiveTestsList: (state, action) => {
      state.tests.loading = false;
      state.tests.data = action.payload.content.tests;
    },
    receiveTestsError: (state, action) => {
      state.tests.loading = false;
      state.error = action.payload;
    },
    requestWhitelabels: (state) => {
      state.whitelabels.loading = true;
    },
    receiveWhitelabelsList: (state, action) => {
      state.whitelabels.loading = false;
      state.whitelabels.data = action.payload.content.whitelabels;
    },
    receiveWhitelabelsError: (state, action) => {
      state.whitelabels.loading = false;
      state.error = action.payload;
    },
  },
});

const Actions = subscriptionsSlice.actions;

const Selectors = {
  fetchListData: (state) => state.subscriptions,
  subscriptionsLoading: ({ subscriptions: { loading } }) => ({ loading }),
};

const Async = {
  fetchTestsList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      `${BASE_TESTS_URL}?page=1&paginates_per=200&sort=ASC&sort_by=name`,
      'GET',
      Actions.requestTests,
      Actions.receiveTestsList,
      Actions.receiveTestsError
    ),
  fetchWhitelabelsList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      WHITELABELS_URL,
      'GET',
      Actions.requestWhitelabels,
      Actions.receiveWhitelabelsList,
      Actions.receiveWhitelabelsError
    ),
  fetchSubscriptionsList: () => async (dispatch, getState) => {
    const {
      subscriptions: {
        data: {
          page,
          perPage,
          search,
          testId,
          whitelabelId,
          period,
          status,
          startDate,
          endDate,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestSubscriptions());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: SUBSCRIPTIONS_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          test_id: testId,
          whitelabel_id: whitelabelId,
          period: period?.toUpperCase(),
          status: [STATUS_TRIALING, STATUS_TRIAL].includes(status)
            ? STATUS_TRIALING?.toUpperCase()
            : status?.toUpperCase(),
          start_date: startDate ? getISOStringFromStringDate(startDate) : null,
          end_date: endDate ? getISOStringFromStringDate(endDate) : null,
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receiveSubscriptionsList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveSubscriptionsError(e.message);
      }
    }

    action && dispatch(action);
  },

  fetchSubscriptionById:
    ({ id }) =>
    async (dispatch, getState) => {
      const {
        subscriptions: {
          subscriptionPurchases: {
            data: { page, perPage },
          },
        },
      } = getState();

      ejectCancelInterceptor();
      cancelToken?.cancel();
      cancelToken = generateCancelToken();

      let action;

      dispatch(Actions.requestSubscriptionPurchases());

      try {
        const response = await request({
          cancelToken: cancelToken.token,
          method: 'GET',
          url: SUBSCRIPTION_BY_ID_URL,
          params: {
            id,
            page,
            paginates_per: perPage,
          },
        });

        action = Actions.receiveSubscriptionPurchases(response.data.content);
      } catch (e) {
        if (!isCancel(e)) {
          action = Actions.receiveSubscriptionPurchasesError(e.message);
        }
      }

      action && dispatch(action);
    },

  fetchSubscriptionsInformation: (value) => async (dispatch) => {
    let action;
    dispatch(Actions.requestSubscriptionsInformation());

    try {
      const response = await request({
        method: 'GET',
        url: `${SUBSCRIPTIONS_INFORMATION_URL}?period=${value}`,
      });

      action = Actions.receiveSubscriptionsInformation(response.data.content);
    } catch (e) {
      action = Actions.receiveSubscriptionsInformationError(e.message);
    }

    dispatch(action);
  },
};

const { reducer } = subscriptionsSlice;

export { reducer, Actions, Async, Selectors };
