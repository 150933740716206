import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import i18n from 'i18next';

import { Drawer } from '@mui/material';
import { IconButton, IconRoundBox, Icon } from 'modules/Core/Common';
import { DraggableWidgetItem } from './DraggableWidgetItem.component';
import { useMediaQuery, MOBILE_SIZE } from 'modules/Core/Hooks';
import { ReactComponent as EmptyIcon } from 'assets/layout/empty-data/empty-active-widgets.svg';
import MOCKED_WIDGETS_LIST from 'modules/Dashboard/Common/mocked-widgets.json';
import { CloseIcon } from '@edusynch/edusynch-svg-icons';
import * as S from './WidgetsSidebar.styles';

export const WidgetsSidebar = ({
  isOpen,
  onClose,
  activeWidgets,
  onAddActiveWidget,
  onRemoveActiveWidget,
  isDragging,
}) => {
  const activeWidgetsIds = useMemo(() => {
    return activeWidgets?.map((activeWidget) => activeWidget.id);
  }, [activeWidgets?.length]);

  const widgetsList = MOCKED_WIDGETS_LIST?.widgets?.filter(
    (widget) => !activeWidgetsIds?.includes(widget.id)
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(MOBILE_SIZE);

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      BackdropProps={{
        invisible: true,
      }}
      PaperProps={{
        sx: {
          width: isMobile ? '100%' : isDragging ? '5%' : 'auto',
          height: isMobile ? '92vh' : '86vh',
          bottom: 0,
          top: 'unset',
          borderRadius: '0.6rem 0 0 0',
          overflowY: 'unset',
        },
      }}
    >
      <S.Container>
        <S.Header>
          <IconButton IconName={CloseIcon} iconSize={12} onClick={onClose} />
          <h2>{i18n.t('modules.widgets.title')}</h2>
          <p>{i18n.t('modules.widgets.sidebar.drag_a_widget')}</p>
        </S.Header>
        <S.ScrollableContainer isDragging={isDragging}>
          <S.ActiveWidgets>
            <h4>{i18n.t('modules.widgets.sidebar.active_widgets')}</h4>
            {activeWidgets?.length ? (
              <S.WidgetsList>
                {activeWidgets?.map((widget) => (
                  <S.Widget
                    key={widget.id}
                    onClick={() => onRemoveActiveWidget(widget.id)}
                  >
                    <S.WidgetIcon>
                      <IconRoundBox
                        small
                        withBg
                        bg={theme.config.colors.primary_100}
                      >
                        <Icon name="user-registered" fontSize="1rem" />
                      </IconRoundBox>
                      <div>
                        <p>{widget.name}</p>
                        {!!widget?.test_type && (
                          <span>{widget?.test_type}</span>
                        )}
                      </div>
                    </S.WidgetIcon>
                    <Icon name="delete" fontSize="1.2rem" />
                  </S.Widget>
                ))}
              </S.WidgetsList>
            ) : (
              <S.EmptyWidgets>
                <EmptyIcon />
                <h6>{i18n.t('modules.widgets.sidebar.empty.title')}</h6>
                <p>{i18n.t('modules.widgets.sidebar.empty.description')}</p>
              </S.EmptyWidgets>
            )}
          </S.ActiveWidgets>
          <S.MoreWidgets>
            <h4>{i18n.t('modules.widgets.sidebar.more_widgets')}</h4>
            {widgetsList?.length ? (
              <S.WidgetsList>
                {widgetsList.map((widget) => (
                  <DraggableWidgetItem
                    key={widget.id}
                    widget={widget}
                    onClick={() => onAddActiveWidget(widget)}
                  />
                ))}
              </S.WidgetsList>
            ) : (
              <S.EmptyWidgets>
                <p>{i18n.t('modules.widgets.sidebar.empty.empty_list')}</p>
              </S.EmptyWidgets>
            )}
          </S.MoreWidgets>
        </S.ScrollableContainer>
      </S.Container>
    </Drawer>
  );
};

WidgetsSidebar.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  activeWidgets: PropTypes.array,
  onAddActiveWidget: PropTypes.func,
  onRemoveActiveWidget: PropTypes.func,
  isDragging: PropTypes.bool,
};
