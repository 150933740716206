import { groupBy } from 'lodash';

const getPathByName = (name) => {
  if (!name) return '';

  const finalName = `/${name.replaceAll(' ', '_')}`;
  return finalName.toLowerCase();
};

const transformArrayIntoCorrectSubmenuFormat = (submenu) => {
  const groupedSubmenu = groupBy(submenu, 'type');
  const groupedSubmenuValues = Object.values(groupedSubmenu);
  const submenuTitles = [...new Set(submenu.map((item) => item.type))];

  const finalSubmenu = groupedSubmenuValues.reduce(
    (acc, cur, index) => [
      ...acc,
      {
        name: submenuTitles[index] || '',
        routes: cur,
      },
    ],
    []
  );

  return finalSubmenu;
};

const menuBuilder = (menuArray) => {
  const menu = menuArray
    .filter(({ disabled }) => !disabled)
    .reduce(
      (acc, cur) => [
        ...acc,
        {
          name: cur.name || '',
          Icon: cur.Icon,
          component: cur.component,
          exact: cur.exact || false,
          ability: cur.ability || '',
          disabled: cur.disabled || false,
          hidden: cur.hidden || false,
          show_on_menu: cur.show_on_menu ? true : cur.show_on_menu,
          path: cur.path || getPathByName(cur.name),
        },
      ],
      []
    );

  function addSubmenu(submenu = []) {
    if (!submenu.length || !menu.length || menu.length > 1) return menu;

    const { name, Icon, show_on_menu, ability } = menu[0]; // eslint-disable-line

    const mergedMenuWithSubmenu = [
      {
        name,
        Icon,
        ability,
        show_on_menu,
        submenu: {
          name,
          menus: transformArrayIntoCorrectSubmenuFormat(submenu),
        },
      },
    ];

    return mergedMenuWithSubmenu;
  }

  return { addSubmenu };
};

const mergeMenuArrays = (menuList) => {
  const mergedMenuList = menuList.reduce((acc, cur) => acc.concat(cur), []);
  return mergedMenuList;
};

export { menuBuilder, mergeMenuArrays };
