import React from 'react';
import i18n from 'i18next';
import MaintenanceImage from 'assets/layout/maintenance.svg';
import './Maintenance.styles.css';

const Maintenance = () => {
  return (
    <div className="maintenance-page">
      <div>
        <div className="clouds" />
        <div className="content">
          <h1 className="title">{i18n.t('modules.maintenance.title')}</h1>
          <p className="description">
            {i18n.t('modules.maintenance.description')}
          </p>
          <img src={MaintenanceImage} alt="maintenance" />
        </div>
        <div
          className="footer"
          dangerouslySetInnerHTML={{
            __html: i18n.t('modules.maintenance.footer'),
          }}
        />
      </div>
    </div>
  );
};

export default Maintenance;
