import { useContext } from 'react';

import { AbilityContext } from 'services/Ability.service';
import {
  VIEW,
  UPDATE,
  MANAGE,
  DESTROY,
  CREATE,
} from 'services/Ability.constants';
import { isPrimitive } from 'modules/Utils';

const mergeRoutesWithSubmenuIntoASingleArray = (routes) => {
  const submenus = routes
    .filter((routeConfig) => !routeConfig.path)
    .flatMap(({ submenu }) => submenu.menus)
    .flatMap((item) => item.routes);

  return submenus;
};

const checkAllAbilitiesToShowMenu = (route) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const ability = useContext(AbilityContext);

  if (isPrimitive(route)) {
    return (
      ability.can(VIEW, route) ||
      ability.can(UPDATE, route) ||
      ability.can(MANAGE, route) ||
      ability.can(DESTROY, route) ||
      ability.can(CREATE, route)
    );
  }

  return route?.some((_route) => {
    return (
      ability.can(VIEW, _route) ||
      ability.can(UPDATE, _route) ||
      ability.can(MANAGE, _route) ||
      ability.can(DESTROY, _route) ||
      ability.can(CREATE, _route)
    );
  });
};

export { mergeRoutesWithSubmenuIntoASingleArray, checkAllAbilitiesToShowMenu };
