import * as Yup from 'yup';
import i18n from 'i18next';

const REQUIRED_PREFIX = 'modules.institutions.class.class-form.requireds';

export const fieldNamesMap = {
  reading_amount: 'readingAmount',
  listening_amount: 'listeningAmount',
  writing_amount: 'writingAmount',
  speaking_amount: 'speakingAmount',
  full_test_amount: 'fullTestAmount',
  reading_practice_amount: 'readingPracticeAmount',
  listening_practice_amount: 'listeningPracticeAmount',
  writing_practice_amount: 'writingPracticeAmount',
  speaking_practice_amount: 'speakingPracticeAmount',
  cefr_amount: 'cefrAmount',
  mcer_amount: 'mcerAmount',
  reading_writing_amount: 'readingWritingAmount',
  reading_writing_practice_amount: 'readingWritingPracticeAmount',
  math_amount: 'mathAmount',
  math_practice_amount: 'mathPracticeAmount',
};

export const fieldNameToKey = (fieldName) => {
  if (!fieldName) {
    throw new Error('fieldName is required');
  }
  return fieldNamesMap[fieldName];
};

export default Yup.object().shape({
  name: Yup.string().required(i18n.t(`${REQUIRED_PREFIX}.name`)),
  amount: Yup.number(),
  testId: Yup.string(),
  customTestId: Yup.string(),
  groupId: Yup.string(),
  unitId: Yup.string(),
  schoolId: Yup.string(),
  classId: Yup.string(),
  enableApplyPhotos: Yup.boolean(),
  expirationDate: Yup.string(),
  licenseType: Yup.string(),
  readingAmount: Yup.number(),
  listeningAmount: Yup.number(),
  writingAmount: Yup.number(),
  speakingAmount: Yup.number(),
  fullTestAmount: Yup.number(),
  readingPracticeAmount: Yup.number(),
  listeningPracticeAmount: Yup.number(),
  writingPracticeAmount: Yup.number(),
  speakingPracticeAmount: Yup.number(),
  cefrAmount: Yup.number(),
  mcerAmount: Yup.number(),
  readingWritingAmount: Yup.number(),
  readingWritingPracticeAmount: Yup.number(),
  mathAmount: Yup.number(),
  mathPracticeAmount: Yup.number(),
  photoStudent: Yup.boolean(),
  photoUploadId: Yup.boolean(),
  photoUploadType: Yup.string(),
  takePictureNextId: Yup.boolean(),
  screenShare: Yup.boolean(),
  roomScan360: Yup.boolean(),
  roomScanRandom: Yup.boolean(),
  enableUserScreenRecording: Yup.boolean(),
  enableUserVideoRecording: Yup.boolean(),
  enableUserAudioRecording: Yup.boolean(),
});
