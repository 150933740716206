import styled from 'styled-components';

const ValidationError = styled.span`
  ${(props) => props.theme.config.typograph.mico};
  font-size: 1.6rem;
  color: ${(props) => props.theme.config.colors.light};
  padding: 1.6rem;
  &::first-letter {
    text-transform: capitalize;
  }
`;

export { ValidationError };
