import styled from 'styled-components';

const Container = styled.div`
  margin: 2rem 0 3rem;
  display: flex;
  justify-content: center;
  transition: all 0.2s;
`;

export { Container };
