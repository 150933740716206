/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { isValid } from 'date-fns';

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  LocalizationProvider,
  MobileDateTimePicker,
  DesktopDateTimePicker,
} from '@mui/x-date-pickers';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  convertUserTimezoneToUTC,
  convertUTCDateToUserTimezone,
} from 'modules/Utils/Date';
import { useAuth } from 'modules/Auth/Hooks';
import { CalendarIcon, ChangeIcon } from '@edusynch/edusynch-svg-icons';

const theme = createTheme({
  typography: {
    fontSize: 20,
  },
});

export const MUIDateTimePicker = ({
  date,
  onChange,
  endIcon,
  placeholder,
  minDate,
  onlyTextField,
  inputFormat,
  unlimitedMaxDate,
  customMaxDateInDays,
  ...props
}) => {
  const { userTimeFormat, userTimezone, userDateFormat, isAMPM } = useAuth();

  const format = inputFormat || `${userDateFormat} ${userTimeFormat}`;

  let dateValue = userTimezone
    ? convertUTCDateToUserTimezone(date, userTimezone)
    : date;

  const minDateToUserTimezone = convertUTCDateToUserTimezone(
    minDate,
    userTimezone
  );

  const styledTheme = useTheme();

  const pickers = {
    mobile: MobileDateTimePicker,
    desktop: DesktopDateTimePicker,
  };

  const PickerType = pickers[onlyTextField ? 'desktop' : 'mobile'];

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <ThemeProvider theme={theme}>
          <PickerType
            value={dateValue}
            inputFormat={format}
            ampm={isAMPM}
            minDate={minDateToUserTimezone}
            onChange={(item) => {
              if (isValid(item)) {
                if (userTimezone) {
                  const newDateUtc = convertUserTimezoneToUTC(
                    item,
                    userTimezone
                  );
                  return onChange(newDateUtc);
                }
                return onChange(item);
              }
            }}
            InputProps={{
              startAdornment: <CalendarIcon left />,
              endAdornment: endIcon ? <ChangeIcon /> : null,
              sx: {
                fontSize: 15,
                background: 'white',
                height: '4.8rem',
                color: `${styledTheme.config.colors.text_base} !important`,
              },
            }}
            renderInput={(params) => (
              <TextField placeholder={placeholder} {...params} />
            )}
            {...props}
          />
        </ThemeProvider>
      </Stack>
    </LocalizationProvider>
  );
};

MUIDateTimePicker.propTypes = {
  date: PropTypes.any,
  inputFormat: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  endIcon: PropTypes.bool,
  minDate: PropTypes.any,
  onlyTextField: PropTypes.bool,
  unlimitedMaxDate: PropTypes.bool,
  customMaxDateInDays: PropTypes.number,
};
