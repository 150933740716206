import React from 'react';
import PropTypes from 'prop-types';
import { BasicLink, NavLink, RouterLink } from '..';

const getLinkCmp = ({ onClick, nav }) => {
  if (onClick) {
    return BasicLink;
  }
  if (nav) {
    return NavLink;
  }
  return RouterLink;
};

const Link = (props) => {
  const LinkCmp = getLinkCmp(props);
  return <LinkCmp {...props}>{props.children}</LinkCmp>;
};

Link.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

export default Link;
