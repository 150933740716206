import {
  addMinutes,
  format as fnsFormat,
  parseISO,
  isValid,
  roundToNearestMinutes,
  addMonths,
} from 'date-fns';

import {
  utcToZonedTime,
  zonedTimeToUtc,
  toDate,
  format as formatTz,
} from 'date-fns-tz';

export const formats = Object.freeze({
  ETC_TIMEZONE: 'Etc/UTC',
  FORMAT_TO_ISO: "yyyy-MM-dd'T'HH:mm:ss'.000Z'",
});

export const durationToHuman = (duration) => {
  const fnDuration = new Date(duration * 1000);
  const fnformatted = format(
    addMinutes(fnDuration, fnDuration.getTimezoneOffset()),
    'HH:mm:ss'
  );

  return fnformatted;
};

export const format = (date, format, parse) => {
  if (
    !date ||
    !format ||
    (parse && !isValid(parseISO(date))) ||
    (typeof date === 'object' && !isValid(date))
  )
    return '';

  if (parse) return fnsFormat(parseISO(date), format);

  return fnsFormat(date, format);
};

export const utcToTimezone = (date, timezone) => utcToZonedTime(date, timezone);

export const getISOStringFromStringDate = (date = '') => {
  return new Date(date).toISOString();
};

const getDefaultDates = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const today = new Date();

  const utcToday = zonedTimeToUtc(today, timeZone);

  const startDate = format(addMonths(utcToday, -1), "yyyy-MM-dd'T'00:00:00'Z'");
  const endDate = format(utcToday, "yyyy-MM-dd'T'00:00:00'Z'");

  return { startDate, endDate };
};

export const getDefaultStartDate = () => {
  const { startDate } = getDefaultDates();
  return startDate.substring(0, 10);
};

export const getDefaultEndDate = () => {
  const { endDate } = getDefaultDates();
  return endDate.substring(0, 10);
};

export const convertUTCToTimezone = (date, timezone) =>
  toDate(date, { timeZone: timezone });

export const convertUTCDateToUserTimezone = (date, timezone) => {
  const parsed = convertUTCToTimezone(date, timezone);
  return utcToZonedTime(parsed, timezone);
};

export const convertUserTimezoneToUTC = (date, timezone) => {
  const formatDefault = 'yyyy-MM-dd HH:mm:ss';
  const newItem = format(date, formatDefault).split(' ');
  const formatedDate = `${newItem[0]}T${newItem[1]}`;
  const utcDate = zonedTimeToUtc(formatedDate, timezone);
  return utcDate.toISOString();
};

export const addMinutesUTC = (date, minutes) => {
  if (!date) return '';
  const parse = addMinutes(
    toDate(date, { timeZone: formats.ETC_TIMEZONE }),
    minutes
  );
  const response = formatTz(
    utcToZonedTime(parse, formats.ETC_TIMEZONE),
    formats.FORMAT_TO_ISO
  );
  return response;
};

export const roundTimeUTC = (date, minutes = 30) => {
  if (!date) return '';
  const parse = roundToNearestMinutes(
    toDate(date, { timeZone: formats.ETC_TIMEZONE }),
    {
      nearestTo: minutes,
      roundingMethod: 'ceil',
    }
  );
  const response = formatTz(
    utcToZonedTime(parse, formats.ETC_TIMEZONE),
    formats.FORMAT_TO_ISO
  );
  return response;
};

export const formatDatetUTCToTimezone = (
  date,
  userDateFormat,
  userTimeFormat,
  timeZone
) => {
  if (!date) return '';
  let format = '';
  format += userDateFormat ? `${userDateFormat}` : '';
  format += userDateFormat && userTimeFormat ? ' ' : '';
  format += userTimeFormat ? ` ${userTimeFormat}` : '';
  const dateInTimezone = utcToTimezone(date, timeZone);
  return formatTz(dateInTimezone, format);
};

export const getUTCDefaultStartDate = () => {
  const { startDate } = getDefaultDates();
  return startDate.substring(0, 10);
};

export const getUTCCustomStartDate = (days) => {
  const currentDate = new Date();
  days && currentDate.setDate(currentDate.getDate() - days);
  return formatToSimpleDate(currentDate);
};

export const getUTCDefaultEndDate = () => {
  const { endDate } = getDefaultDates();
  return endDate.substring(0, 10);
};

export const getUTCLast7Days = () => {
  const current_date = new Date();
  const end_date = formatToSimpleDate(current_date);

  current_date.setDate(current_date.getDate() - 7);
  const start_date = formatToSimpleDate(current_date);

  return { start_date, end_date };
};

export const getUTCLast30Days = () => {
  const current_date = new Date();
  const end_date = formatToSimpleDate(current_date);

  current_date.setMonth(current_date.getMonth() - 1);
  const start_date = formatToSimpleDate(current_date);

  return { start_date, end_date };
};

export const getUTCLastYear = () => {
  const current_date = new Date();
  const end_date = formatToSimpleDate(current_date);

  current_date.setMonth(current_date.getMonth() - 12);
  const start_date = formatToSimpleDate(current_date);

  return { start_date, end_date };
};

export const formatToSimpleDate = (date) => {
  if (!date) return null;
  return fnsFormat(new Date(date), defaultDateFormat);
};

export const defaultDateFormat = 'yyyy-MM-dd';
export const defaultDateTimeFormat = `yyyy-MM-dd HH:mm:ss`;
