export const secondsToMinutes = (time) => {
  var minutes = Math.floor(time / 60);
  var seconds = Math.floor(time % 60);

  if (time === Infinity) {
    minutes = '--';
    seconds = '--';
  }

  return `${('0' + minutes).slice(-2)}:${('0' + seconds).slice(-2)}`;
};
