import styled, { css } from 'styled-components';
import { NavLink } from 'modules/Core/Common';
import { device } from 'modules/Utils/Devices';

const styledActivateStyle = css`
  background-color: ${(props) => props.theme.config.colors.primary};

  svg path {
    fill: ${(props) => props.theme.config.colors.light};
  }
`;

const SidebarLink = styled.div`
  display: flex;
  align-items: center;
  border-radius: 1.2rem;
  padding: 0.5rem 1.3rem;
  color: white;
  height: 5rem;
  pointer-events: none;
  background: ${(props) => props.theme.config.colors.secondary_600};

  @media ${device.mobile} {
    margin-right: ${(props) => props.theme.config.spacing.spacing_3};
  }
`;

const MenuItem = styled(NavLink)`
  ${(props) => props.theme.config.typograph.default};
  display: block;
  position: relative;
  color: ${(props) => props.theme.config.colors.light};
  cursor: pointer;
  border-radius: 1.2rem;

  &.active,
  &:hover {
    @media ${device.mobile} {
      color: ${(props) => props.theme.config.colors.light};
    }

    @media ${device.tabletMin} {
      > div {
        ${styledActivateStyle}
      }
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
    `}

  @media ${device.mobile} {
    display: flex;
    align-items: center;

    a,
    button {
      margin-right: ${(props) => props.theme.config.spacing.spacing_3};
    }
  }
`;

export { SidebarLink, MenuItem };
