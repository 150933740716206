import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'modules/Core/Common';
import * as S from './Button.styles';

const Button = ({
  children,
  loading,
  loadingText = 'Please wait...',
  hasLoading,
  text,
  dataCy,
  ...props
}) => {
  if (hasLoading && loading) {
    return (
      <S.Button {...props} data-cy={dataCy}>
        <Loader width="24px" borderWidth="2px" text={loadingText} />
      </S.Button>
    );
  }

  return (
    <S.Button data-cy={dataCy} {...props}>
      {children || text}
    </S.Button>
  );
};

Button.propTypes = {
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  hasLoading: PropTypes.bool,
  text: PropTypes.string,
  dataCy: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.node,
  ]),
};

export default Button;
export { S };
