import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  GROUPS_URL,
  GROUP_BY_ID_URL,
  GROUPS_CREATE_URL,
  GROUPS_UPDATE_URL,
  GROUPS_DELETE_URL,
  STUDENTS_BY_GROUP_URL,
} from 'modules/Api/Routes';
import { formDataFromObj } from 'modules/Api/RequestData';
import { createGroupRequestModel } from 'modules/Institutions/Groups/GroupUtils';

let cancelToken;

const initialState = {
  loading: false,
  error: null,
  data: {
    groups: [],
    page: 1,
    perPage: 10,
    search: '',
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
  groupStudents: {
    groupId: '',
    name: '',
    students: [],
    total: 0,
    page: 1,
    perPage: 8,
    search: '',
    showInfo: false,
    align: 'center',
    loading: false,
    error: null,
  },
  isDeleting: false,
};

const groupsSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    requestGroup: (state) => {
      state.loading = true;
      state.error = null;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsDeleting: (state, action) => {
      state.isDeleting = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    /**
     * receive a success group list response
     */
    receiveGroupList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        groups: action.payload.groups,
        total: action.payload.total_items,
      };
    },
    clearGroupsList: (state) => {
      state.loading = false;
      state.data = {
        page: 1,
        perPage: 10,
        search: '',
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
      state.groupStudents = {
        groupId: '',
        name: '',
        students: [],
        total: 0,
        page: 1,
        perPage: 8,
        search: '',
        showInfo: false,
        align: 'center',
        loading: false,
        error: null,
      };
    },
    /**
     * receive an error response
     */
    receiveGroupError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changeGroupsPage: (state, action) => {
      state.data.page = action.payload;
    },
    changeGroupSearch: (state, action) => {
      state.data.search = action.payload.search;
      state.data.page = 1;
    },
    changeGroupFormSearch: (state, action) => {
      state.groupStudents.search = action.payload.search;
      state.groupStudents.page = 1;
    },
    changeGroupFormPage: (state, action) => {
      state.groupStudents.page = action.payload;
    },
    changeGroupFilters: (state, action) => {
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
      state.data.search = action?.payload.search || '';
    },
    updateGroupOnList: (state, action) => {
      const updGroup = action.payload;
      const index = state.data.groups.findIndex(
        (group) => group.id === updGroup.id
      );
      if (index !== -1) state.data.groups.splice(index, 1, updGroup);
    },
    requestStudents: (state) => {
      state.groupStudents.loading = true;
      state.groupStudents.error = null;
    },
    receiveStudentsList: (state, action) => {
      state.groupStudents = {
        ...state.groupStudents,
        groupId: action.payload.id || state.groupStudents.groupId,
        name: action.payload.name || state.groupStudents.name,
        students: action.payload.students,
        total: action.payload.total_items,
        loading: false,
      };
    },
    receiveStudentsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    clearStudents: (state) => {
      state.groupStudents.search = '';
      state.groupStudents.page = 1;
    },
  },
});

const Actions = groupsSlice.actions;

const Selectors = {
  fetchListData: (state) => state.group,
  groupLoading: ({ group: { loading } }) => ({ loading }),
};

const Async = {
  fetchGroupsList: () => async (dispatch, getState) => {
    const {
      group: {
        data: {
          page,
          perPage,
          search,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestGroup());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: GROUPS_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receiveGroupList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveGroupError(e.message);
      }
    }

    action && dispatch(action);
  },

  fetchAllGroupsList: () => async (dispatch) => {
    dispatch(Actions.requestGroup());

    try {
      const response = await request({
        method: 'GET',
        url: GROUPS_URL,
      });

      dispatch(Actions.receiveGroupList(response.data.content));
    } catch (e) {
      dispatch(Actions.receiveGroupError(e.message));
    }
  },

  fetchGroupsListForSelect: () => async (dispatch) => {
    let action;

    dispatch(Actions.requestGroup());

    try {
      const response = await request({
        method: 'GET',
        url: GROUPS_URL,
      });

      action = Actions.receiveGroupList(response.data.content);
    } catch (e) {
      action = Actions.receiveGroupError(e.message);
    }

    dispatch(action);
  },

  fetchGroupStudents:
    ({ id, name } = {}) =>
    async (dispatch, getState) => {
      const {
        group: {
          groupStudents: { page, perPage, search, groupId, name: groupName },
        },
      } = getState();

      let action;

      dispatch(Actions.requestStudents());

      try {
        const response = await request({
          method: 'GET',
          url: STUDENTS_BY_GROUP_URL,
          params: {
            page,
            paginates_per: perPage,
            group_id: id || groupId,
            search,
          },
        });

        const data = {
          ...response.data.content,
          id: id || groupId,
          name: name || groupName,
        };

        action = Actions.receiveStudentsList(data);
      } catch (e) {
        action = Actions.receiveStudentsError(e.message);
      }

      dispatch(action);
    },

  getGroupById:
    ({ id, onSuccess, onError }) =>
    async () => {
      try {
        const response = await request({
          method: 'GET',
          url: `${GROUP_BY_ID_URL}?id=${id}`,
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  createGroup:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestGroup());
      try {
        const parsedGroup = createGroupRequestModel(data);
        delete parsedGroup.id;

        const groupData = formDataFromObj(parsedGroup);

        const response = await request({
          method: 'POST',
          url: GROUPS_CREATE_URL,
          data: groupData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveGroupError());
        onError(e);
      }
    },

  updateGroup:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestGroup());
      try {
        const parsedGroup = createGroupRequestModel(data);

        const groupData = formDataFromObj(parsedGroup);

        const response = await request({
          method: 'PUT',
          url: GROUPS_UPDATE_URL,
          data: groupData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveGroupError());
        onError(e);
      }
    },

  deleteGroup:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.setIsDeleting(true));

      try {
        const response = await request({
          method: 'DELETE',
          url: `${GROUPS_DELETE_URL}?id=${id}`,
        });

        onSuccess(response);
        dispatch(Actions.setIsDeleting(false));
      } catch (e) {
        onError(e);
        dispatch(Actions.setIsDeleting(false));
      }
    },
};

const reducer = groupsSlice.reducer;

export { reducer, Actions, Async, Selectors };
