import React from 'react';

import { IconRoundBox, Icon } from 'modules/Core/Common';
import * as S from './NavigationCards.styles';
import { useDashboardLinks } from 'modules/Dashboard/Hooks';
import { useTheme } from 'styled-components';

const NavigationCards = () => {
  const { shortcuts } = useDashboardLinks();
  const theme = useTheme();

  return (
    <S.Container data-cy="dashboard-navigation-container">
      {shortcuts?.map((item, index) => {
        if (!item) return null;

        return (
          <S.StyledCard key={`dashboard-link-${index}`}>
            <S.NavigationCard to={item.url}>
              <IconRoundBox large withBg bg={theme.config.colors.primary_100}>
                <Icon
                  name={item.icon}
                  fontSize="2.4rem"
                  color={theme.config.colors.primary}
                />
              </IconRoundBox>
              <span>{item.name}</span>
            </S.NavigationCard>
          </S.StyledCard>
        );
      })}
    </S.Container>
  );
};

export default NavigationCards;
