import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import ErrorImg from 'assets/layout/img-error-boundary.svg';
import * as S from './ErrorBoundary.styles';
import { Button } from 'modules/Core/Common';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });

    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <>
          <S.Container>
            <S.ErrorContent>
              <img src={ErrorImg} alt="Error img" />
              <p>
                Something went wrong with the application. Go back to home for
                more <strong>EduSynch.</strong>
              </p>
              <S.LinkStyled href="/dashboard">
                <Button>Back to Home</Button>
              </S.LinkStyled>
            </S.ErrorContent>
          </S.Container>
        </>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ErrorBoundary;
