import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  CLASSES_URL,
  CLASS_BY_ID_URL,
  CLASSES_CREATE_URL,
  CLASSES_UPDATE_URL,
  CLASSES_DELETE_URL,
  STUDENTS_BY_CLASS_URL,
} from 'modules/Api/Routes';
import { formDataFromObj } from 'modules/Api/RequestData';
import { createClassRequestModel } from 'modules/Institutions/Classes/ClassUtils';

let cancelToken;

const initialState = {
  loading: false,
  error: null,
  data: {
    page: 1,
    perPage: 10,
    search: '',
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
  classStudents: {
    classId: '',
    name: '',
    students: [],
    total: 0,
    page: 1,
    perPage: 8,
    search: '',
    showInfo: false,
    align: 'center',
    loading: false,
  },
  isDeleting: false,
};

const classesSlice = createSlice({
  name: 'class',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    requestClass: (state) => {
      state.loading = true;
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsDeleting: (state, action) => {
      state.isDeleting = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    /**
     * receive a success class list response
     */
    receiveClassList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        classes: action.payload.classes,
        total: action.payload.total_items,
      };
    },
    clearClassesList: (state) => {
      state.loading = false;
      state.data = {
        page: 1,
        perPage: 10,
        search: '',
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
      state.classStudents = {
        classId: '',
        name: '',
        students: [],
        total: 0,
        page: 1,
        perPage: 8,
        search: '',
        showInfo: false,
        align: 'center',
        loading: false,
      };
    },
    /**
     * receive an error response
     */
    receiveClassError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changeClassesPage: (state, action) => {
      state.data.page = action.payload;
    },
    changeClassFilters: (state, action) => {
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
      state.data.search = action?.payload.search || '';
    },
    updateClassOnList: (state, action) => {
      const updClass = action.payload;
      const index = state.data.classes.findIndex(
        (studentClass) => studentClass.id === updClass.id
      );
      if (index !== -1) state.data.classes.splice(index, 1, updClass);
    },
    changeClassesSearch: (state, action) => {
      state.data.search = action.payload.search;
      state.data.page = 1;
    },
    changeClassesFormSearch: (state, action) => {
      state.classStudents.search = action.payload.search;
      state.classStudents.page = 1;
    },
    changeClassesFormPage: (state, action) => {
      state.classStudents.page = action.payload;
    },
    requestStudents: (state) => {
      state.classStudents.loading = true;
      state.classStudents.error = null;
    },
    receiveStudentsList: (state, action) => {
      state.classStudents = {
        ...state.classStudents,
        classId: action.payload.id || state.classStudents.classId,
        name: action.payload.name || state.classStudents.name,
        students: action.payload.students,
        total: action.payload.total_items,
        loading: false,
      };
    },
    receiveStudentsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    clearStudents: (state) => {
      state.classStudents.search = '';
      state.classStudents.page = 1;
    },
  },
});

const Actions = classesSlice.actions;

const Selectors = {
  fetchListData: (state) => state.class,
  classLoading: ({ class: { loading } }) => ({ loading }),
};

const Async = {
  fetchClassesList: () => async (dispatch, getState) => {
    const {
      class: {
        data: {
          page,
          perPage,
          search,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestClass());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: CLASSES_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receiveClassList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveClassError(e.message);
      }
    }

    action && dispatch(action);
  },

  fetchClassStudents:
    ({ id, name } = {}) =>
    async (dispatch, getState) => {
      const {
        class: {
          classStudents: { page, perPage, search, classId, name: className },
        },
      } = getState();

      let action;

      dispatch(Actions.requestStudents());

      try {
        const response = await request({
          method: 'GET',
          url: STUDENTS_BY_CLASS_URL,
          params: {
            page,
            paginates_per: perPage,
            class_id: id || classId,
            search,
          },
        });

        const data = {
          ...response.data.content,
          id: id || classId,
          name: name || className,
        };

        action = Actions.receiveStudentsList(data);
      } catch (e) {
        action = Actions.receiveStudentsError(e.message);
      }

      dispatch(action);
    },

  getClassById:
    ({ id, onSuccess, onError }) =>
    async () => {
      try {
        const response = await request({
          method: 'GET',
          url: `${CLASS_BY_ID_URL}?id=${id}`,
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  createClass:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestClass());
      try {
        const parsedClass = createClassRequestModel(data);
        delete parsedClass.id;

        const classData = formDataFromObj(parsedClass);

        const response = await request({
          method: 'POST',
          url: CLASSES_CREATE_URL,
          data: classData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveClassError());
        onError(e);
      }
    },

  updateClass:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestClass());
      try {
        const parsedClass = createClassRequestModel(data);

        const classData = formDataFromObj(parsedClass);

        const response = await request({
          method: 'PUT',
          url: CLASSES_UPDATE_URL,
          data: classData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveClassError());
        onError(e);
      }
    },

  deleteClass:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.setIsDeleting(true));

      try {
        const response = await request({
          method: 'DELETE',
          url: `${CLASSES_DELETE_URL}?id=${id}`,
        });

        onSuccess(response);
        dispatch(Actions.setIsDeleting(false));
      } catch (e) {
        onError(e);
        dispatch(Actions.setIsDeleting(false));
      }
    },
};

const reducer = classesSlice.reducer;

export { reducer, Actions, Async, Selectors };
