import { lazy } from 'react';
import { menuBuilder } from 'services/Routes.service';
import { WhitelabelIcon } from '@edusynch/edusynch-svg-icons';

const WhitelabelListContainer = lazy(() =>
  import('modules/Whitelabel/WhitelabelList/WhitelabelList.container')
);

const WhitelabelEditContainer = lazy(() =>
  import('modules/Whitelabel/WhitelabelEdit/WhitelabelEdit.container')
);

const WhitelabelMenu = menuBuilder([
  {
    name: 'Whitelabel',
    Icon: WhitelabelIcon,
    component: WhitelabelListContainer,
    ability: 'whitelabels',
    exact: true,
  },
  {
    Icon: WhitelabelIcon,
    name: 'Whitelabel',
    component: WhitelabelEditContainer,
    ability: 'whitelabels',
    path: '/whitelabel/edit',
    hidden: true,
  },
]).addSubmenu();

export default WhitelabelMenu;
