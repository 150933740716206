import styled, { css } from 'styled-components';
import { device } from 'modules/Utils/Devices';

const styledActivateStyle = css`
  background-color: ${(props) => props.theme.config.colors.primary};

  svg path {
    fill: ${(props) => props.theme.config.colors.light};
  }
`;

const MenuItem = styled.button`
  ${(props) => props.theme.config.typograph.default};
  display: block;
  position: relative;
  color: ${(props) => props.theme.config.colors.light};
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  border-radius: 1.2rem;
  width: 100%;

  &.active {
    div {
      ${styledActivateStyle}
    }
  }

  @media ${device.tabletMin} {
    &:hover {
      div {
        ${styledActivateStyle}
      }
    }
  }

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  @media ${device.mobile} {
    display: flex;
    align-items: center;
    overflow: hidden;

    a,
    button {
      margin-right: ${(props) => props.theme.config.spacing.spacing_3};
    }
  }
`;

const OpenArrow = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: ${(props) => props.theme.config.spacing.spacing_5};
  height: ${(props) => props.theme.config.spacing.spacing_5};
  right: 0;
  background: ${(props) => props.theme.config.colors.secondary_600};
  mix-blend-mode: normal;
  border-radius: 50%;
  pointer-events: none;

  svg {
    width: 1.2rem;

    path {
      fill: ${(props) => props.theme.config.colors.primary};
    }
  }
`;

const SidebarLinkUnClickable = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1.3rem;
  color: white;
  height: 5rem;
  border-radius: 1.2rem;
  pointer-events: none;
  border: none;
  background-color: transparent;
  outline: none;
  background: ${(props) => props.theme.config.colors.secondary_600};

  @media ${device.mobile} {
    margin-right: ${(props) => props.theme.config.spacing.spacing_3};
  }

  svg {
    pointer-events: none;
  }
`;

export { MenuItem, OpenArrow, SidebarLinkUnClickable };
