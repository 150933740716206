import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().required(),
  language: Yup.string().required(),
  timezone: Yup.string().required(),
  time_format: Yup.string().required(),
  date_format: Yup.string().required(),
  intervals: Yup.array(),
});
