import styled, { css } from 'styled-components';
import { device } from 'modules/Utils';

export const Header = styled.div`
  display: flex;

  ${(props) =>
    props.borderBottom &&
    css`
      margin-bottom: 0.8rem;
      padding-bottom: 1.6rem;
      border-bottom: 1px solid ${props.theme.config.colors.gray_200};
    `};

  h6 {
    ${(props) => props.theme.config.typograph.xsmall};
    color: ${(props) => props.theme.config.colors.text_base};

    span {
      color: ${(props) => props.theme.config.colors.gray};
    }
  }

  p {
    ${(props) => props.theme.config.typograph.default};
    font-size: 1.6rem;
    font-weight: bold;
    color: ${(props) => props.theme.config.colors.primary};
  }

  @media ${device.desktop} {
    ${({ size, borderBottom }) =>
      !borderBottom && size === 'small'
        ? css`
            margin-bottom: 2.4rem;
          `
        : ''};
  }

  @media ${device.tablet} {
    margin-bottom: 0rem;
  }

  @media ${device.mobile} {
    margin-bottom: ${(props) => (props.borderBottom ? '0' : '2.4rem')};
  }
`;

export const Icon = styled.div`
  margin-right: 0.8rem;
`;
