import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Loader = styled.div`
  border: ${({ borderWidth = '4px' }) => borderWidth} solid
    ${(props) => props.theme.config.colors.gray_100};
  border-top: ${({ borderWidth = '4px' }) => borderWidth} solid
    ${(props) => props.theme.config.colors.primary};
  border-radius: 50%;
  width: ${({ width = '60px' }) => width};
  height: ${({ width = '60px' }) => width};
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export const Text = styled.span`
  ${({ text }) =>
    text &&
    css`
      margin-left: ${(props) => props.theme.config.spacing.spacing_2};
    `}
`;
