import styled from 'styled-components';

import { Card } from 'modules/Core/Common';

export const Container = styled(Card)`
  display: flex;
  gap: 2.4rem;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.6rem;

  p {
    ${(props) => props.theme.config.typograph.default};
    color: ${(props) => props.theme.config.colors.text_base};

    strong {
      text-transform: capitalize;
    }
  }
`;
