export const ACTIVE_USERS = 'ACTIVE_USERS';
export const REGISTERED_USERS = 'REGISTERED_USERS';
export const EPROCTORING_EVENTS_ANALYTICS_BASE_TESTS =
  'EPROCTORING_EVENTS_ANALYTICS_BASE_TESTS';
export const EPROCTORING_EVENTS_ANALYTICS_CUSTOM_TESTS =
  'EPROCTORING_EVENTS_ANALYTICS_CUSTOM_TESTS';
export const EPROCTORING_EVENTS_ANALYTICS_PLUGIN =
  'EPROCTORING_EVENTS_ANALYTICS_PLUGIN';
export const EPROCTORING_SESSIONS_COUNT = 'EPROCTORING_SESSIONS_COUNT';
export const COMPLETION_RATE_BASE_TESTS = 'COMPLETION_RATE_BASE_TESTS';
export const COMPLETION_RATE_CUSTOM_TESTS = 'COMPLETION_RATE_CUSTOM_TESTS';
export const COMPLETION_RATE_PLUGIN = 'COMPLETION_RATE_PLUGIN';
