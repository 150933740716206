import styled, { css } from 'styled-components';
import { device } from 'modules/Utils';

export const Container = styled.div`
  width: 35rem;
  padding: 3.2rem;

  @media ${device.mobile} {
    width: auto;
  }
`;

export const Header = styled.div`
  color: ${(props) => props.theme.config.colors.text_base};

  h2 {
    ${(props) => props.theme.config.typograph.quartenary};
    font-size: 2rem;
    margin-top: 3.2rem;
  }

  p {
    ${(props) => props.theme.config.typograph.default};
    margin-top: 0.8rem;
  }

  @media ${device.mobile} {
    button {
      display: block;
      margin-left: auto;
    }
  }
`;

export const ActiveWidgets = styled.div`
  margin-top: 3.2rem;
  padding-bottom: 3.2rem;
  border-bottom: 1px solid ${(props) => props.theme.config.colors.gray_200};

  h4 {
    ${(props) => props.theme.config.typograph.mico};
    color: ${(props) => props.theme.config.colors.gray_400};
    margin-bottom: 1.6rem;
  }
`;

export const EmptyWidgets = styled.div`
  text-align: center;

  h6 {
    ${(props) => props.theme.config.typograph.default};
    color: ${(props) => props.theme.config.colors.text_base};
    font-size: 1.6rem;
    font-weight: bold;
    margin-top: 1.6rem;
    margin-bottom: 0.4rem;
  }

  p {
    ${(props) => props.theme.config.typograph.mico};
    color: ${(props) => props.theme.config.colors.text_base};
  }
`;

export const MoreWidgets = styled.div`
  margin-top: 3.2rem;

  h4 {
    ${(props) => props.theme.config.typograph.mico};
    color: ${(props) => props.theme.config.colors.gray_400};
    margin-bottom: 1.6rem;
  }
`;

export const WidgetsList = styled.ul``;

export const Widget = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.config.colors.light};
  padding: 0.8rem 1.6rem;
  border: 1px solid ${(props) => props.theme.config.colors.gray_200};
  border-radius: 0.6rem;
  ${(props) => props.theme.config.typograph.default};
  color: ${(props) => props.theme.config.colors.primary};
  font-weight: bold;
  transition: box-shadow 0.2s;
  cursor: pointer;

  & + li {
    margin-top: 0.8rem;
  }

  &:hover {
    box-shadow: 0 0.2rem 0.4rem rgba(49, 49, 49, 0.15);

    svg {
      path {
        fill: ${(props) => props.theme.config.colors.primary};
      }
    }
  }
`;

export const WidgetIcon = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 0.8rem;
  }

  span {
    display: block;
    margin-left: 0.8rem;
    ${(props) => props.theme.config.typograph.mico};
    color: ${(props) => props.theme.config.colors.gray};
  }
`;

export const ScrollableContainer = styled.div`
  overflow-y: auto;
  max-height: 66.7rem;

  ${(props) =>
    props.isDragging &&
    css`
      overflow: unset;
    `};

  scrollbar-color: ${(props) => props.theme.config.colors.gray_200};

  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.config.colors.gray_100};
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.config.colors.gray_300};
    border-radius: 0.8rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.config.colors.gray};
  }
`;
