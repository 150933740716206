import React, { createContext, useContext, useMemo, createRef } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

import { format } from 'modules/Utils/Date';
import {
  Tooltip,
  Skeleton,
  CustomSelect,
  Button,
  Icon,
  Switch,
  Loader,
} from 'modules/Core/Common';
import { UserAvatar } from 'modules/Users/Common';
import { useAuth } from 'modules/Auth/Hooks';
import { UserAvatarInfo } from 'modules/Users/Common';
import * as S from './GeneralHeader.styles';
import i18n from 'i18next';

const GeneralHeaderContext = createContext();

const useGeneralHeaderContext = () => {
  const context = useContext(GeneralHeaderContext);
  if (!context) {
    throw new Error(i18n.t('errors.error-general-header-context'));
  }
  return context;
};

const getTrackingInfo = (tracking) => {
  if (!tracking) return null;
  const { city, country } = tracking;
  return `${city}, ${country}`;
};

const LoadingContentComponent = () => (
  <>
    <S.LoadingContainer>
      <Skeleton type="string" />
      <Skeleton type="string" />
    </S.LoadingContainer>
    <S.LoadingContainer>
      <Skeleton type="string" />
      <Skeleton type="string" />
    </S.LoadingContainer>
    <S.LoadingContainer>
      <Skeleton type="string" />
      <Skeleton type="string" />
    </S.LoadingContainer>
  </>
);

const TestName = () => {
  const { testName } = useGeneralHeaderContext();

  return (
    <div>
      <h6>{i18n.t('commons.general-header.test-type')}</h6>
      <Tooltip
        placement="bottom"
        overlay={testName}
        overlayInnerStyle={{
          backgroundColor: '#0075EA',
          borderRadius: '4px',
          padding: '16px',
          fontSize: '16px',
        }}
      >
        <span>{testName}</span>
      </Tooltip>
    </div>
  );
};

const Evaluators = () => {
  const { evaluators } = useGeneralHeaderContext();

  const renderEvaluators = (evaluators) => {
    if (Array.isArray(evaluators)) {
      const cardRef = createRef(null);
      return evaluators?.map((evaluator) => (
        <UserAvatar
          key={evaluator?.id}
          id={evaluator?.id}
          name={evaluator?.name}
          email={evaluator?.email}
          avatar={evaluator?.avatar?.thumb?.url || null}
          cardRef={cardRef}
        >
          <UserAvatar.Photo />
          <UserAvatar.Card />
        </UserAvatar>
      ));
    }

    const cardRef = createRef(null);
    return (
      <UserAvatar
        key={evaluators?.id}
        id={evaluators?.id}
        name={evaluators?.name}
        email={evaluators?.email}
        avatar={evaluators?.avatar?.thumb?.url || null}
        cardRef={cardRef}
      >
        <UserAvatar.Photo />
        <UserAvatar.Card />
      </UserAvatar>
    );
  };

  return (
    <div>
      <h6>{i18n.t('commons.general-header.evaluator')}</h6>
      <S.Evaluators>{renderEvaluators(evaluators)}</S.Evaluators>
    </div>
  );
};

const DataComponent = ({ name, value }) => (
  <div>
    <h6>{name}</h6>
    <span>{value}</span>
  </div>
);

DataComponent.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
};

const Content = () => {
  const {
    id,
    thumb,
    changeCategory,
    certificatePdf,
    openCertificate,
    openTheaterView,
    name,
    email,
    tracking,
    createdAt,
    isEllt,
    studentId,
    testName,
    evaluators,
    loading,
    currentCategory,
    onClose,
    onSwitchChange,
    isSwitchActive,
    isSwitchBlocked,
    switchLabel,
    isSwitchLoading,
  } = useGeneralHeaderContext();
  const theme = useTheme();
  const { userDateFormat } = useAuth();

  return (
    <S.ContentContainer>
      <S.User>
        <UserAvatarInfo
          size="48px"
          loading={loading}
          avatar={thumb}
          name={name}
          email={email}
          location={getTrackingInfo(tracking)}
        />
      </S.User>
      <S.Log>
        {loading ? (
          <S.Loading>
            <LoadingContentComponent />
          </S.Loading>
        ) : (
          <>
            <S.DataContainer>
              <DataComponent name="ID" value={id} />
              {createdAt && (
                <DataComponent
                  name={i18n.t('common-words.date')}
                  value={format(createdAt, userDateFormat, 'parse')}
                />
              )}
              {isEllt && (
                <DataComponent
                  name={i18n.t('commons.general-header.ellt-number')}
                  value={studentId}
                />
              )}
              {testName && <TestName />}
              {evaluators && <Evaluators />}
            </S.DataContainer>
            <S.AnswerActions>
              {changeCategory && <Category />}
              {openCertificate && (
                <Tooltip
                  placement="bottom"
                  overlay={i18n.t(
                    `commons.general-header.${
                      !certificatePdf ? 'no' : 'open'
                    }-certificate`
                  )}
                >
                  <div>
                    <Button
                      disabled={!certificatePdf}
                      medium
                      transparent
                      onClick={openCertificate}
                    >
                      <Icon
                        name="certificate"
                        color={
                          !certificatePdf
                            ? theme.config.colors.light
                            : theme.config.colors.primary
                        }
                        fontSize="13px"
                      />
                      Certificate
                    </Button>
                  </div>
                </Tooltip>
              )}
              {openTheaterView && (
                <Button
                  medium
                  transparent
                  className="last-button"
                  onClick={openTheaterView}
                >
                  <Icon
                    name="expand-open"
                    color={theme.config.colors.primary}
                    fontSize="12px"
                  />
                  Theater View
                </Button>
              )}
            </S.AnswerActions>
            {currentCategory && <CurrentCategory />}
            {onClose && <Close />}
            {onSwitchChange && (
              <S.BooleanContainer>
                {switchLabel && <h5>{switchLabel}</h5>}
                {isSwitchLoading ? (
                  <Loader width="3.2rem" style={{ justifyContent: 'center' }} />
                ) : (
                  <Switch
                    active={isSwitchActive}
                    disabled={isSwitchBlocked}
                    onChange={({ target }) => onSwitchChange(target.checked)}
                  />
                )}
              </S.BooleanContainer>
            )}
          </>
        )}
      </S.Log>
    </S.ContentContainer>
  );
};

const Category = () => {
  const { loading, changeCategory, categoryData, currentData } =
    useGeneralHeaderContext();

  return (
    <div className="w-style">
      <S.CategoryText>
        {i18n.t('commons.general-header.select-category')}
      </S.CategoryText>

      <S.SelectContainer>
        <CustomSelect
          secondary
          selectedValue={currentData.category}
          data={categoryData}
          title="Loading..."
          loading={loading}
          onToggleClick={changeCategory}
        />
      </S.SelectContainer>
    </div>
  );
};

const CurrentCategory = () => {
  const { currentCategory } = useGeneralHeaderContext();

  const formatIconName = (name) => {
    if (name === 'writing') return 'edit';
    return name;
  };

  return (
    <S.CategoryContainer className="w-style">
      <S.CategoryText>
        {i18n.t('commons.general-header.category')}
      </S.CategoryText>
      <S.CategoryIcon>
        <Icon name={formatIconName(currentCategory.toLowerCase())} />
      </S.CategoryIcon>
      <S.CategoryName>{currentCategory}</S.CategoryName>
    </S.CategoryContainer>
  );
};

const Close = () => {
  const { onClose } = useGeneralHeaderContext();

  return (
    <S.CloseContainer>
      <S.Close>
        <Button small transparent onClick={onClose}>
          {i18n.t('commons.buttons.close-button')}
          <Icon fontSize="12px" name="close" />
        </Button>
      </S.Close>
    </S.CloseContainer>
  );
};

const GeneralHeader = ({
  id,
  children,
  thumb,
  name,
  email,
  tracking,
  createdAt,
  testName,
  evaluators,
  isEllt,
  studentId,
  loading,
  changeCategory,
  certificatePdf,
  openCertificate,
  openTheaterView,
  categoryData,
  currentData,
  currentCategory,
  onClose,
  onSwitchChange,
  isSwitchActive,
  isSwitchBlocked,
  switchLabel,
  isSwitchLoading,
}) => {
  const values = useMemo(
    () => ({
      id,
      thumb,
      name,
      email,
      tracking,
      createdAt,
      testName,
      evaluators,
      isEllt,
      studentId,
      loading,
      changeCategory,
      certificatePdf,
      openCertificate,
      openTheaterView,
      categoryData,
      currentData,
      currentCategory,
      onClose,
      onSwitchChange,
      isSwitchActive,
      isSwitchBlocked,
      switchLabel,
      isSwitchLoading,
    }),
    [
      id,
      thumb,
      name,
      email,
      tracking,
      createdAt,
      testName,
      evaluators,
      isEllt,
      studentId,
      loading,
      changeCategory,
      certificatePdf,
      openCertificate,
      openTheaterView,
      categoryData,
      currentData,
      currentCategory,
      onClose,
      onSwitchChange,
      isSwitchActive,
      isSwitchBlocked,
      switchLabel,
      isSwitchLoading,
    ]
  );

  return (
    <GeneralHeaderContext.Provider value={values}>
      <S.Container>{children}</S.Container>
    </GeneralHeaderContext.Provider>
  );
};

GeneralHeader.Content = Content;

GeneralHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  loading: PropTypes.bool,
  id: PropTypes.any,
  thumb: PropTypes.any,
  name: PropTypes.string,
  email: PropTypes.string,
  tracking: PropTypes.object,
  createdAt: PropTypes.string,
  testName: PropTypes.string,
  evaluators: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isEllt: PropTypes.bool,
  studentId: PropTypes.number,
  changeCategory: PropTypes.func,
  certificatePdf: PropTypes.func,
  openCertificate: PropTypes.func,
  openTheaterView: PropTypes.func,
  categoryData: PropTypes.any,
  currentData: PropTypes.any,
  currentCategory: PropTypes.string,
  onClose: PropTypes.func,
  onSwitchChange: PropTypes.func,
  switchLabel: PropTypes.string,
  isSwitchActive: PropTypes.bool,
  isSwitchBlocked: PropTypes.bool,
  isSwitchLoading: PropTypes.bool,
};

export { GeneralHeader };
