import { lazy } from 'react';
import { menuBuilder } from 'services/Routes.service';
import { UsersIcon } from '@edusynch/edusynch-svg-icons';

const StudentList = lazy(() =>
  import('modules/Users/Students/StudentList/StudentList.container')
);
const TeachersList = lazy(() =>
  import('modules/Users/Teachers/TeachersList/TeachersList.container')
);
const SuperAdminList = lazy(() =>
  import('modules/Users/SuperAdmin/SuperAdminList/SuperAdminList.container')
);
const GroupAdminList = lazy(() =>
  import('modules/Users/GroupAdmin/GroupAdminList/GroupAdminList.container')
);
const EvaluatorsList = lazy(() =>
  import('modules/Users/Evaluators/EvaluatorsList/EvaluatorsList.container')
);
const UnitAdminList = lazy(() =>
  import('modules/Users/UnitAdmin/UnitAdminList/UnitAdminList.container')
);
const SchoolAdminList = lazy(() =>
  import('modules/Users/SchoolAdmin/SchoolAdminList/SchoolAdminList.container')
);
const CreatorsList = lazy(() =>
  import('modules/Users/Creators/CreatorList/CreatorList.container')
);

const UsersMenu = menuBuilder([
  {
    Icon: UsersIcon,
    name: 'Users',
  },
]).addSubmenu([
  {
    type: '',
    name: 'Super Admins',
    path: '/users/edusynch/super-admins',
    component: SuperAdminList,
    ability: 'users_super_admin',
  },
  {
    type: '',
    name: 'Group Admins',
    path: '/users/edusynch/group-admins',
    component: GroupAdminList,
    ability: 'users_group_admin',
  },
  {
    type: '',
    name: 'Unit Admins',
    path: '/users/edusynch/unit-admins',
    component: UnitAdminList,
    ability: 'users_unit_admin',
  },
  {
    type: '',
    name: 'School Admins',
    path: '/users/edusynch/school-admins',
    component: SchoolAdminList,
    ability: 'users_school_admin',
  },
  {
    type: '',
    name: 'Teachers',
    path: '/users/edusynch/teachers',
    component: TeachersList,
    ability: 'users_teachers',
  },
  {
    type: '',
    name: 'Students',
    path: '/users/edusynch/students',
    component: StudentList,
    ability: 'students',
  },
  {
    type: '',
    name: 'Evaluators',
    path: '/users/edusynch/evaluators',
    component: EvaluatorsList,
    ability: 'evaluators',
  },
  {
    type: '',
    name: 'License Admins',
    path: '/users/edusynch/teachers-1',
    hidden: true,
    component: EvaluatorsList,
    ability: 'users_license_admin',
  },
  {
    type: '',
    name: 'Saas Admins',
    path: '/users/edusynch/teachers-1',
    component: EvaluatorsList,
    ability: 'users_saas_admin',
    hidden: true,
  },
  {
    type: '',
    name: 'Test Creators',
    path: '/users/edusynch/teachers-1',
    component: EvaluatorsList,
    ability: 'test_creators',
    hidden: true,
  },
  {
    type: '',
    name: 'Super Admins',
    path: '/users/edusynch/admins',
    component: EvaluatorsList,
    ability: 'levely_super_admin',
    hidden: true,
  },
  {
    type: '',
    name: 'Evaluators',
    path: '/users/edusynch/admins',
    component: EvaluatorsList,
    ability: 'levely_evaluators',
    hidden: true,
  },
  {
    type: '',
    name: 'Enterprise Admins',
    path: '/users/edusynch/admins',
    component: EvaluatorsList,
    ability: 'levely_enterprise_admin',
    hidden: true,
  },
  {
    type: '',
    name: 'Creators',
    path: '/users/edusynch/creators',
    component: CreatorsList,
    ability: 'users_creator',
  },
]);

export default UsersMenu;
