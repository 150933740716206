import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Loading } from 'modules/Core/Common';
import { PrivateRoute } from 'modules/Core/Routes/PrivateRoute';
import { AuthRoute } from 'modules/Core/Routes/AuthRoute';

const Routes = ({ outcome, user, whitelabel }) => (
  <Router>
    <Suspense fallback={<Loading ousideContainer={true} />}>
      {user ? (
        <PrivateRoute outcome={outcome} user={user} whitelabel={whitelabel} />
      ) : (
        <AuthRoute />
      )}
    </Suspense>
  </Router>
);

Routes.defaultProps = {
  user: null,
};

Routes.propTypes = {
  user: PropTypes.object,
  outcome: PropTypes.any,
  whitelabel: PropTypes.object,
};

export default Routes;
