import styled, { css } from 'styled-components';
import { ZIndexes } from 'modules/Utils/ZIndexes';

export const Container = styled.div``;

export const ButtonContainer = styled.div`
  display: inline-block;
  position: relative;
  z-index: 1;

  ${(props) =>
    props.block &&
    css`
      width: 100%;
    `};

  ${(props) =>
    props.quaternary &&
    css`
      z-index: 4;
      width: 300px;
    `};
`;

export const Button = styled.button`
  ${(props) => props.theme.config.typograph.xsmall};
  color: ${(props) => props.theme.config.colors.primary};
  cursor: pointer;
  border-radius: 4px;
  padding: 0.4rem 1.6rem;
  background: ${(props) => props.theme.config.colors.primary_200};
  border: none;
  outline: none;
  display: block;
  font-weight: bold;
  min-height: 3.2rem;
  min-width: 12.6rem;
  text-align: left;
  max-width: 12rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:disabled {
    cursor: not-allowed;
  }

  i,
  svg,
  span {
    vertical-align: middle;
  }

  svg {
    display: inline-block;
    opacity: 0.5;
    width: 1.4rem;
    height: 1.4rem;

    path {
      fill: ${(props) => props.theme.config.colors.primary};
    }
  }

  ${(props) =>
    props.secondary &&
    css`
      background: ${(props) => props.theme.config.colors.primary};
      color: ${(props) => props.theme.config.colors.light};
      border-radius: 3.2rem;
      max-width: 100%;
      font-weight: 400;
      min-height: 4rem;
      cursor: pointer;
      text-transform: capitalize;

      svg {
        opacity: 1;
        width: 1.6rem;
        margin-left: ${(props) => props.theme.config.spacing.spacing_4};

        &:not(.edu-icon-change, .edu-icon-arrow-down) {
          margin-left: 0;
        }

        path {
          fill: ${(props) => props.theme.config.colors.light};
          margin-right: 0;
        }
      }
    `};

  ${(props) =>
    props.tertiary &&
    css`
      background: ${(props) => props.theme.config.colors.light};
      color: ${(props) => props.theme.config.colors.text_base};
      border-radius: 0.6rem;
      max-width: 100%;
      font-weight: 400;
      min-height: 4rem;
      cursor: pointer;
      text-transform: capitalize;

      svg {
        opacity: 1;
        width: 1.6rem;
        margin-left: ${(props) => props.theme.config.spacing.spacing_4};

        &:not(.ArrowDownIcon, .ChangeIcon) {
          margin-right: ${(props) => props.theme.config.spacing.spacing_3};
          margin-left: 0;
        }

        path {
          fill: ${(props) => props.theme.config.colors.text_base};
          margin-right: 0;
        }
      }
    `};

  ${(props) =>
    props.quaternary &&
    css`
      background: ${(props) => props.theme.config.colors.light};
      color: ${(props) => props.theme.config.colors.text_base};
      border-radius: 5rem;
      max-width: none;
      min-width: 100%;
      font-weight: 400;
      height: 4rem;
      cursor: pointer;
      text-transform: capitalize;
      display: flex;
      align-items: center;

      svg {
        opacity: 1;
        width: 1.6rem;
        margin-inline-start: auto;

        path {
          fill: ${(props) => props.theme.config.colors.gray_300};
          background: none;
        }

        &:not(.ArrowDownIcon) {
          margin-right: ${(props) => props.theme.config.spacing.spacing_3};
          margin-left: 0;
          border: none;

          path {
            fill: ${(props) => props.theme.config.colors.light};
            background: ${(props) => props.theme.config.colors.primary};
          }
        }
      }
    `};

  ${(props) =>
    props.block &&
    css`
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    `};

  ${(props) =>
    props.large &&
    css`
      height: 4.8rem;
    `};
`;

export const Item = styled.li`
  ${(props) => props.theme.config.typograph.default};
  color: ${(props) => props.theme.config.colors.text_base};
  user-select: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  padding: 1.5rem 0 1.5rem 1.8rem;

  &:hover {
    background-color: rgba(93, 102, 112, 0.05);
  }

  & + li {
    border-top: 1px solid ${(props) => props.theme.config.colors.gray_200};
  }
`;

export const DropdownContainer = styled.div`
  background: ${(props) => props.theme.config.colors.light};
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.15);
  position: absolute;
  border-radius: 0.4rem;
  width: 25.5rem;
  top: 5rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 0.5rem 0 0;

  // arrow-up
  &::before {
    content: '';
    display: block;
    width: 1rem;
    height: 1rem;
    background: ${(props) => props.theme.config.colors.light};
    position: absolute;
    right: 50%;
    transform: translateX(-50%);
    top: -0.4rem;
    transition: background-color 0.2s ease;
    transform: rotate(45deg);

    ${(props) =>
      props.right &&
      css`
        right: 2.5rem;
      `}

    ${(props) =>
      props.left &&
      css`
        calc(100% - 30px)
      `}
  }

  ${(props) =>
    props.secondary &&
    css`
      padding: 0;
    `};

  ${(props) =>
    props.quaternary &&
    css`
      width: 100%;
    `};

  ${(props) =>
    props.block &&
    css`
      width: 100%;
      margin-top: ${(props) => props.theme.config.spacing.spacing_2};
    `};
`;

export const Dropdown = styled.ul`
  z-index: ${ZIndexes.overlay + 1};
  max-height: 20rem;
  overflow-y: scroll;
  padding-right: 0.8rem;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.config.colors.gray_400};
    border-radius: 2rem;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.config.colors.gray_400};
  }

  ${(props) =>
    props.secondary &&
    css`
      overflow-y: auto;
      padding: 0;
    `};

  ${(props) =>
    props.quaternary &&
    css`
      overflow-y: auto;
      padding: 0;
    `};
`;
