import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { InfoIcon, Avatar } from 'modules/Core/Common';
import { useMediaQuery } from 'modules/Core/Hooks';
import { prettyUserType } from 'modules/Users/Permissions/UserType';
import { HeaderMenu } from './HeaderMenu';
import { HeaderDropdown } from './HeaderDropdown';
import { WhitelabelGreet } from './WhitelabelGreet';
import { useLayout } from '../Hooks/useLayout';
import * as S from './Header.style';

const Header = ({
  opened,
  onMenuClick,
  onLogoutClick,
  hasSubmenu,
  toggleShortcutMenu,
  user,
  whitelabelName,
  openedResponsive,
  toggleResponsiveSidebarOpen,
  toggleResponsiveSidebarClose,
  ...props
}) => {
  const isMobile = useMediaQuery('(max-width: 767.98px)');
  const { pathname } = useLocation();
  const { topMargin } = useLayout();

  const isSubmissionView = useMemo(() => {
    return pathname?.includes('submissions') && pathname?.includes('answers');
  }, [pathname]);

  return (
    <S.Header
      noShadow={isSubmissionView}
      style={{ top: `${topMargin || 0}px` }}
    >
      <S.MenuContainer opened={opened}>
        {isMobile ? (
          <HeaderMenu
            onClick={
              openedResponsive
                ? toggleResponsiveSidebarClose
                : toggleResponsiveSidebarOpen
            }
          />
        ) : (
          <HeaderMenu
            onClick={hasSubmenu ? onMenuClick : toggleShortcutMenu}
            opened={opened}
          />
        )}
      </S.MenuContainer>

      <S.WhitelabelContainer>
        <WhitelabelGreet whitelabelName={whitelabelName} />
      </S.WhitelabelContainer>

      <S.RightContainer>
        <InfoIcon
          thin
          xsmall
          xxsmall
          title={!isMobile && prettyUserType(user.user_type)}
          before={
            <Avatar
              src={user?.avatar?.thumb?.url}
              name={user.name}
              data-cy="headerbar-avatar"
            />
          }
          data-test="dash-student-section"
        >
          {!isMobile && user.name}
        </InfoIcon>

        <HeaderDropdown logout={onLogoutClick} {...props} />
      </S.RightContainer>
    </S.Header>
  );
};

Header.defaultProps = {
  opened: false,
};

Header.propTypes = {
  opened: PropTypes.bool,
  onMenuClick: PropTypes.func,
  onLogoutClick: PropTypes.func,
  user: PropTypes.object,
  hasSubmenu: PropTypes.bool,
  toggleShortcutMenu: PropTypes.func,
  whitelabelName: PropTypes.string,
  openedResponsive: PropTypes.bool,
  toggleResponsiveSidebarOpen: PropTypes.func,
  toggleResponsiveSidebarClose: PropTypes.func,
};

export default React.memo(Header);
