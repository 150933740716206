import React from 'react';
import PropTypes from 'prop-types';
import Slider from '@mui/material/Slider';

import * as S from './MUISlider.styles';

export const MUISlider = ({ value, min = 0, max, onChange }) => {
  return (
    <S.Container>
      <Slider
        value={parseFloat(value)}
        min={parseFloat(min)}
        max={parseFloat(max)}
        onChange={(event, newValue) => onChange(newValue)}
      />
    </S.Container>
  );
};

MUISlider.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
};
