import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: block;
  position: relative;
`;

export const SearchToggl = styled.div`
  position: relative;

  svg {
    position: absolute;
    z-index: 1;
    top: 12px;
    right: 16px;
  }

  input {
    width: 100%;
    border-color: ${(props) =>
      props.borders ? props.theme.config.colors.gray_400 : 'transparent'};
  }
`;

export const SelectedOpt = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  background-color: ${(props) =>
    props.borders
      ? props.theme.config.colors.light
      : props.theme.config.colors.primary_200};
  border: 1px solid
    ${(props) =>
      props.borders
        ? props.theme.config.colors.gray_400
        : props.theme.config.colors.primary_200};
  border-radius: 4px;
  overflow: hidden;
  margin: 4px 0 !important;
`;

export const Avatar = styled.div`
  display: flex;
  align-items: center;
`;

export const AvatarImage = styled.div`
  width: 24px;
  height: 24px;
  border: 1px solid ${(props) => props.theme.config.colors.gray_200};
  border-radius: 50%;
  margin-right: 16px;
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const AvatarInitials = styled.div`
  ${(props) => props.theme.config.typograph.xsmall};
  font-size: 1.1rem;
  line-height: 1rem;
  width: 24px;
  height: 24px;
  border: 2px solid ${(props) => props.theme.config.colors.primary};
  border-radius: 50%;
  margin-right: 16px;
  color: ${(props) => props.theme.config.colors.secondary};
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) => {
    if (props.isAll)
      return css`
        visibility: hidden;
      `;
  }}
`;

export const AvatarInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const AvatarLabel = styled.div`
  color: ${(props) => props.theme.config.colors.text_base};
  font-weight: 700;
  line-height: 15px;
  max-width: ${(props) => (props.fullWidth ? '269px' : '145px')};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0 !important;
`;

export const AdditionalInfo = styled.div`
  background-color: ${(props) => props.theme.config.colors.primary_100};
  color: ${(props) => props.theme.config.colors.primary};
  font-size: 1.2rem;
  padding: 0.4rem;
  border-radius: 0.4rem;
  max-width: 124px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0 !important;
`;

export const RemoveSelectedOpt = styled.div`
  margin: 0 0 0 4px !important;
  padding: 5px 2px;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
`;

export const SearchBox = styled.div`
  background-color: ${(props) => props.theme.config.colors.light};
  border-radius: 6px;
  border: 1px solid
    ${(props) =>
      props.borders
        ? props.theme.config.colors.gray_400
        : props.theme.config.colors.light};
  padding-top: 6px;
  padding-right: 6px;
  padding-bottom: 6px;
  overflow: hidden;

  position: absolute;
  width: 100%;
  z-index: 2;
  top: 53px;
  margin-top: 0 !important;

  display: ${(props) => (props.open ? 'block' : 'none')};
  box-shadow: 2px 2px 25px 4px rgb(0, 0, 0, 0.05);
`;

export const OptionsBox = styled.div`
  max-height: 184px;
  padding-right: 5px;
  overflow: auto;

  scrollbar-color: #e1e0e7 !important; // Firefox
  scrollbar-width: thin !important; // Firefox

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.config.colors.light};
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.config.colors.gray_300};
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.config.colors.gray_300};
  }
`;

export const Option = styled.div`
  padding: 15px;
  margin: 0 !important;
  background-color: ${(props) => props.theme.config.colors.light};
  color: ${(props) => props.theme.config.colors.text_base};
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.config.colors.gray_300};
  }
`;

export const Empty = styled.div`
  text-align: center;
  padding: 2.4rem;

  svg {
    margin-bottom: 15px;
    width: 56px;
    height: 56px;
    block-size: auto;
  }

  span {
    display: block;
  }
`;

export const EmptyTitle = styled.span`
  ${(props) => props.theme.config.typograph.default};
  font-size: 24px;
  font-weight: bold;
  color: ${(props) => props.theme.config.colors.secondary};
  margin-bottom: 15px;
`;

export const EmptyDescription = styled.span`
  ${(props) => props.theme.config.typograph.xsmall};
  color: ${(props) => props.theme.config.colors.text_base};
`;
