import styled from 'styled-components';

export const Container = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;

  svg {
    opacity: 0.5;

    path {
      fill: ${(props) => props.theme.config.colors.text_base};
    }
  }
`;

export const Confirm = styled.div`
  width: 24rem;
  padding: 2.4rem;
  text-align: center;

  h4 {
    color: ${(props) => props.theme.config.colors.primary};
    ${(props) => props.theme.config.typograph.default};
    font-weight: bold;
    margin-top: 0.4rem;
  }

  p {
    color: ${(props) => props.theme.config.colors.text_base};
    ${(props) => props.theme.config.typograph.mico};
    margin-top: 0.8rem;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${(props) => props.theme.config.colors.gray_300};
  margin-top: 2.4rem;
  padding-top: 1.6rem;

  button {
    min-width: 7.2rem;
  }
`;
