import { useState, useEffect } from 'react';

const MOBILE_SIZE = '(max-width: 767.98px)';
const TABLET_SIZE = '(max-width: 1023.98px)';
const DESKTOP_SIZE = '(min-width: 1024px)';

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => {
      setMatches(media.matches);
    };

    media.addListener(listener);

    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
};

export { useMediaQuery, MOBILE_SIZE, TABLET_SIZE, DESKTOP_SIZE };
