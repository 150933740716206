import { useEffect, useState } from 'react';

export const useLayout = () => {
  const [topMargin, setTopMargin] = useState(0);

  useEffect(() => {
    const updateTop = () => {
      const topBar = document.querySelector('.maintenance-topbar');

      if (topBar) {
        setTopMargin(topBar.clientHeight);
      } else {
        setTopMargin(0);
      }
    };

    updateTop();

    const observer = new MutationObserver(() => {
      updateTop();
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    window.addEventListener('resize', updateTop);

    return () => {
      window.removeEventListener('resize', updateTop);
      observer.disconnect();
    };
  }, []);

  return {
    topMargin,
  };
};
