const NOT_AVAILABLE_MARKS = ['Pending', 'Evaluated'];

export const getMark = (mark) => {
  if (!mark) return 'N/A';

  const slicedMark = mark?.split(':')?.slice(1, 2)?.join()?.trim();

  if (NOT_AVAILABLE_MARKS.includes(slicedMark)) return 'N/A';

  return slicedMark;
};
