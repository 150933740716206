import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import * as S from './TextEditor.styles';

var toolbarOptions = [
  [{ header: [1, 2, 3, false] }],
  ['bold', 'italic', 'underline'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ align: [] }],
];

export const TextEditor = ({ ...props }) => {
  return (
    <S.Container>
      <ReactQuill
        theme="snow"
        modules={{ toolbar: toolbarOptions }}
        {...props}
      />
    </S.Container>
  );
};
