import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Selectors, Actions, Async } from 'modules/Timezones/TimezonesSlice';

export const useTimezones = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(Selectors.fetchListData);

  useEffect(() => {
    if (!loading && !data?.length) fetchTimezones(); // only fetch if it is empty
  }, []);

  useEffect(() => {
    return () => dispatch(Actions.cleanState());
  }, [location.pathname]);

  const fetchTimezones = useCallback(
    () => dispatch(Async.fetchTimezonesList()),
    []
  );

  return { data, loading };
};
