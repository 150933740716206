import { createSlice } from '@reduxjs/toolkit';
import { defaultRequest } from 'modules/Utils/DefaultRequest';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import { getISOStringFromStringDate } from 'modules/Utils';
import {
  getUTCDefaultStartDate,
  getUTCDefaultEndDate,
} from 'modules/Utils/Date';

import { WHITELABELS_BY_USER_URL } from 'modules/Api/Routes';

let cancelToken;

const initialState = {
  loading: false,
  error: null,
  exportFilter: {
    startDate: '',
    endDate: '',
    extension: 'csv',
    link: '',
    loading: false,
  },
  data: {
    start_date: '',
    end_date: '',
    page: 1,
    perPage: 10,
    search: '',
    type: '',
    request_type: '',
    whitelabel_id: '',
    external_application_id: '',
    whitelabels: {
      loading: false,
      data: null,
    },
    external_applications: {
      loading: false,
      data: null,
    },
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
};

const apiLogSlice = createSlice({
  name: 'apiLog',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    requestWhitelabels: (state) => {
      state.data.whitelabels.loading = true;
    },
    requestExternalApps: (state) => {
      state.data.external_applications.loading = true;
    },
    requestExportLinks: (state) => {
      state.exportFilter.link = '';
      state.exportFilter.loading = true;
    },
    requestLogs: (state) => {
      state.loading = true;
      state.error = null;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    changeExportFilter: (state, action) => {
      state.exportFilter = {
        ...state.exportFilter,
        [action.payload?.param]: action.payload?.value,
      };
    },
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    /**
     * receive a success logs list response
     */
    receiveWhitelabelsList: (state, action) => {
      state.data.whitelabels.loading = false;
      state.data.whitelabels.data = action.payload.content;
    },
    receiveExternalAppsList: (state, action) => {
      state.data.external_applications.loading = false;
      state.data.external_applications.data = action.payload.content;
    },
    receiveLogsList: (state, action) => {
      state.loading = false;
      state.data.total = action.payload.total_items;

      const newLogs =
        action.payload?.request_logs || action.payload?.response_logs;

      if (state.data.page === 1 || !state.data.logs?.length) {
        state.data.logs = newLogs;
      } else {
        state.data.logs = [...state.data.logs, ...newLogs];
      }
    },
    receiveExportLinks: (state, action) => {
      state.exportFilter.loading = false;
      state.exportFilter.link =
        action?.payload?.[`link_download_${state.exportFilter.extension}`];
    },
    clearLogsList: (state) => {
      state.loading = false;
      state.data = {
        page: 1,
        perPage: 10,
        search: '',
        type: 'CMS',
        request_type: 'Request',
        whitelabel_id: '',
        external_application_id: '',
        start_date: '',
        end_date: '',
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
    },
    /**
     * receive an error response
     */
    receiveWhitelabelsError: (state, action) => {
      state.data.whitelabels.loading = false;
      state.error = action.payload;
    },
    receiveExternalAppsError: (state, action) => {
      state.data.external_applications.loading = false;
      state.error = action.payload;
    },
    receiveLogsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    receiveExportLinksError: (state, action) => {
      state.exportFilter.loading = false;
      state.error = action.payload;
    },
    changeLogsPage: (state, action) => {
      state.data.page = action.payload;
    },
    changeLogsFilterItem: (state, action) => {
      state.data[action?.payload?.filterName] = action?.payload.id || '';
    },
    changeLogsFilters: (state, action) => {
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.search = action?.payload.search || '';
      state.data.type = action?.payload.type || '';
      state.data.request_type = action?.payload.request_type || '';
      state.data.whitelabel_id = action?.payload.whitelabel_id
        ? parseInt(action?.payload.whitelabel_id)
        : '';
      state.data.external_application_id = action?.payload
        .external_application_id
        ? parseInt(action?.payload.external_application_id)
        : '';
      state.data.start_date =
        action?.payload.start_date || getUTCDefaultStartDate();
      state.data.end_date = action?.payload.end_date || getUTCDefaultEndDate();
      state.exportFilter.startDate =
        action.payload?.start_date || getUTCDefaultStartDate();
      state.exportFilter.endDate =
        action.payload?.end_date || getUTCDefaultEndDate();
    },
    clearLogsFilters: (state) => {
      state.data.search = '';
      state.data.page = 1;
      state.data.type = 'CMS';
      state.data.request_type = 'Request';
      state.data.whitelabel_id = '';
      state.data.external_application_id = '';
      state.data.start_date = getUTCDefaultStartDate();
      state.data.end_date = getUTCDefaultEndDate();
      state.exportFilter.startDate = '';
      state.exportFilter.endDate = '';
    },
  },
});

const Actions = apiLogSlice.actions;

const Selectors = {
  fetchListData: (state) => state.apiLog,
  logsLoading: ({ apiLog: { loading } }) => ({ loading }),
};

const Async = {
  fetchWhitelabelsList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      WHITELABELS_BY_USER_URL,
      'GET',
      Actions.requestWhitelabels,
      Actions.receiveWhitelabelsList,
      Actions.receiveWhitelabelsError
    ),
  fetchExternalAppsList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      '/cms/v1/external_applications/get_external_applications',
      'GET',
      Actions.requestExternalApps,
      Actions.receiveExternalAppsList,
      Actions.receiveExternalAppsError
    ),
  fetchLogsList: () => async (dispatch, getState) => {
    const {
      apiLog: {
        data: {
          page,
          perPage,
          search,
          type,
          request_type,
          whitelabel_id,
          external_application_id,
          start_date,
          end_date,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestLogs());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: '/cms/v1/logs/logs',
        params: {
          page,
          paginates_per: perPage,
          search,
          sort: sortType,
          sort_by: sortBy,
          type,
          request_type,
          whitelabel_id,
          external_application_id,
          start_date: getISOStringFromStringDate(start_date),
          end_date: getISOStringFromStringDate(end_date),
        },
      });

      action = Actions.receiveLogsList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveLogsError(e.message);
      }
    }

    action && dispatch(action);
  },

  fetchExportSubmit:
    ({ onSuccess, onError }) =>
    async (dispatch, getState) => {
      const {
        apiLog: {
          data: {
            search,
            type,
            request_type,
            whitelabel_id,
            external_application_id,
          },
          exportFilter: { startDate, endDate, extension },
        },
      } = getState();
      let action;

      dispatch(Actions.requestExportLinks());

      const data = {
        file_type: extension,
        start_date: getISOStringFromStringDate(startDate),
        end_date: getISOStringFromStringDate(endDate),
        search,
        type,
        request_type,
        whitelabel_id,
        external_application_id,
      };

      try {
        const response = await request({
          method: 'POST',
          url: `/cms/v1/logs/export_list`,
          data,
        });

        action = Actions.receiveExportLinks(response.data.content);
        onSuccess(response.data.content);
      } catch (e) {
        action = Actions.receiveExportLinksError(e.message);
        onError();
      }

      dispatch(action);
    },
};

const { reducer } = apiLogSlice;

export { reducer, Actions, Async, Selectors };
