import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 3.5rem;
  ${(props) => props.theme.config.spacing.spacing_4};
  width: 100%;
`;

export const THeadeContainer = styled.table`
  width: -webkit-fill-available;
  margin: 0 2.2rem 0.4rem 1.7rem;
  position: unset;
`;

export const THead = styled.thead`
  color: ${(props) => props.theme.config.colors.text_base};
`;

export const Th = styled.th`
  font-weight: 700;
  min-width: 7rem;
  text-align: start;

  :first-child {
    max-width: 3rem;
  }
  :first-child + th {
    max-width: 3rem;
  }
  :first-child + th + th {
    max-width: 3rem;
  }

  :first-child + th + th + th {
    max-width: 3rem;
  }

  :last-child {
    min-width: 7.5rem;
  }
`;

export const TableContainer = styled.div`
  padding: 0 ${(props) => props.theme.config.spacing.spacing_3};
  position: relative;
  border: 1px solid ${(props) => props.theme.config.colors.gray_300};
  border-radius: 6px;
  height: 21.8rem;
  width: 100%;
  ${(props) => props.hasScroll && `overflow-y: scroll`};

  &::-webkit-scrollbar {
    background: ${(props) => props.theme.config.colors.primary_100};
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.config.colors.primary};
    border-radius: 20px;
    width: 5px;
    height: 5px;
  }
`;

export const Table = styled.table`
  ${(props) => props.theme.config.typograph.small};
  color: ${(props) => props.theme.config.colors.text_base};
  width: 100%;
`;

export const Tr = styled.tr`
  position: relative;
  border-bottom: 1px solid ${(props) => props.theme.config.colors.gray_300};
  height: 4.8rem;
`;

export const Td = styled.td`
  min-width: 7rem;
  padding: ${(props) => (props.isEdit ? '1.6rem 1rem 1.6rem 0' : '0')};

  :first-child {
    max-width: 3rem;
  }
  :first-child + td {
    max-width: 3rem;
  }
  :first-child + td + td {
    max-width: 3rem;
  }

  :first-child + td + td + td {
    max-width: 3rem;
  }

  :last-child {
    height: 4.8rem;
    min-width: 7.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 0;
  }

  .MuiOutlinedInput-input {
    font-size: 1.4rem !important;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  margin: ${(props) => (props.isEdit ? 'auto 0' : '0')};
  padding: ${(props) => (props.isEdit ? '0.8rem 0' : '0')};
`;

export const ActionButton = styled.a`
  cursor: pointer;

  svg {
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50px;
    padding: 1.2rem !important;
    overflow: visible;

    &:hover {
      background: ${(props) => props.theme.config.colors.primary_200};

      path {
        fill: ${(props) => props.theme.config.colors.primary};
      }
    }
  }

  :first-child {
    margin-right: 0.6rem;
  }

  svg path {
    fill: ${(props) => props.theme.config.colors.gray_400};
  }
`;

export const IntervalContainer = styled.div`
  display: grid;
  grid-gap: 1.5rem;
`;

export const EmphyData = styled.div`
  position: relative;
  width: 100%;
  height: 21.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 4.8rem;
    height: 4.8rem;
    margin-bottom: 1.9rem;
  }

  h2 {
    ${(props) => props.theme.config.typograph.default};
    color: ${(props) => props.theme.config.colors.text_base};
    font-weight: 700;
    margin-bottom: 2px;
  }

  span {
    ${(props) => props.theme.config.typograph.mico};
    color: ${(props) => props.theme.config.colors.gray};
    font-weight: 700;
  }
`;
