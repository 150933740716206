import { lazy } from 'react';
import { menuBuilder } from 'services/Routes.service';
import { SoftwaresIcon } from '@edusynch/edusynch-svg-icons';

const ExternalSoftwaresList = lazy(() =>
  import(
    'modules/Softwares/ExternalSoftwares/ExternalSoftwaresList/ExternalSoftwaresList.container'
  )
);

const VersionsList = lazy(() =>
  import('modules/Softwares/Versions/VersionsList/VersionsList.container')
);

const IntegrationsList = lazy(() =>
  import(
    'modules/Softwares/Integrations/IntegrationsList/IntegrationsList.container'
  )
);

const APIUsersList = lazy(() =>
  import('modules/Softwares/APIUsers/APIUsersList/APIUsersList.container')
);

const SoftwaresMenu = menuBuilder([
  {
    Icon: SoftwaresIcon,
    name: 'Softwares',
  },
]).addSubmenu([
  {
    type: '',
    name: 'External Softwares',
    path: '/external_softwares',
    exact: true,
    component: ExternalSoftwaresList,
    ability: 'softwares',
  },
  {
    type: '',
    name: 'Versions',
    path: '/versions',
    exact: true,
    component: VersionsList,
    ability: 'softwares',
  },
  {
    type: '',
    name: 'Integrations',
    path: '/integrations',
    exact: true,
    component: IntegrationsList,
    ability: 'softwares',
  },
  {
    type: '',
    name: 'API Users',
    path: '/api_users',
    exact: true,
    component: APIUsersList,
    ability: 'softwares',
  },
]);

export default SoftwaresMenu;
