import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as S from './SidebarGeneralProfile.styles';
import { useHistory } from 'react-router-dom';
import { useTimezones } from 'modules/Timezones/Hooks';
import { useLanguages } from 'modules/Languages/Hooks';
import i18n from 'i18next';

import {
  Row,
  Col,
  Label,
  FormGroup,
  Button,
  InputControl,
  SelectAutocomplete,
  SelectTimezone,
} from 'modules/Core/Common';

import ProfileSchema from './ProfileSchema';

const SidebarGeneralProfile = ({ user, onSave, loading }) => {
  const timezones = useTimezones();
  const languages = useLanguages();
  const { goBack } = useHistory();

  return (
    <>
      <Formik
        initialValues={{
          name: user?.name,
          email: user?.email,
          language: user?.language,
          timezone: user?.timezone,
          time_format: user?.time_format,
          date_format: user?.date_format,
          intervals: user?.scheduler?.items,
        }}
        validationSchema={ProfileSchema}
        onSubmit={onSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <S.Form onSubmit={handleSubmit} noValidate>
            <Row>
              <Col xs={12}>
                <FormGroup>
                  <Label htmlFor="name">
                    {i18n.t(
                      'modules.settings-profile.forms.general-inputs.name'
                    )}
                  </Label>

                  <InputControl
                    inputType="name"
                    inputID="name"
                    inputName="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.name}
                    value={values.name}
                    touched={touched.name}
                    required
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="email">
                    {i18n.t(
                      'modules.settings-profile.forms.general-inputs.email'
                    )}
                  </Label>

                  <InputControl
                    inputType="email"
                    inputID="email"
                    inputName="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.email}
                    value={values.email}
                    touched={touched.email}
                    required
                  />
                </FormGroup>
              </Col>

              <Col xs={12} md={6}>
                <FormGroup>
                  <SelectTimezone
                    borders
                    id="timezone_search_id"
                    loading={timezones?.loading}
                    searchPlaceholder="Search timezone"
                    options={timezones?.data}
                    value={[values.timezone]}
                    onChangeSelect={(id) =>
                      handleChange({
                        target: {
                          name: 'timezone',
                          value: id,
                        },
                      })
                    }
                  />
                </FormGroup>
              </Col>

              <Col xs={12} md={6}>
                <FormGroup>
                  <Label htmlFor="time_format">
                    {i18n.t(
                      'modules.settings-profile.forms.general-inputs.time-format'
                    )}
                  </Label>

                  <SelectAutocomplete
                    borders
                    id="time_format_search_id"
                    placeholder="Select a format"
                    searchPlaceholder="Search format"
                    options={[
                      { id: '12', label: '12h' },
                      { id: '24', label: '24h' },
                    ]}
                    value={values.time_format ? [values.time_format] : []}
                    onChangeSelect={(id) => {
                      handleChange({
                        target: {
                          name: 'time_format',
                          value: id,
                        },
                      });
                    }}
                  />
                </FormGroup>
              </Col>

              <Col xs={12} md={6}>
                <FormGroup>
                  <Label htmlFor="date_format">
                    {i18n.t(
                      'modules.settings-profile.forms.general-inputs.date-format'
                    )}
                  </Label>

                  <SelectAutocomplete
                    borders
                    hideSearch
                    id="date_format_search_id"
                    placeholder="Select a format"
                    options={[
                      { id: 'dd/mm/yyyy', label: 'dd/mm/yyyy' },
                      { id: 'dd/mm/yy', label: 'dd/mm/yy' },
                      { id: 'mm/dd/yy', label: 'mm/dd/yy' },
                      { id: 'yy/mm/dd', label: 'yy/mm/dd' },
                    ]}
                    value={values.date_format ? [values.date_format] : []}
                    onChangeSelect={(id) => {
                      handleChange({
                        target: {
                          name: 'date_format',
                          value: id,
                        },
                      });
                    }}
                  />
                </FormGroup>
              </Col>

              <Col xs={12} md={6}>
                <FormGroup>
                  <Label htmlFor="language">
                    {i18n.t(
                      'modules.settings-profile.forms.general-inputs.language'
                    )}
                  </Label>

                  <SelectAutocomplete
                    borders
                    id="language_search_id"
                    loading={languages?.loading}
                    placeholder="Select a language"
                    searchPlaceholder="Search language"
                    options={languages?.data}
                    value={[values.language]}
                    onChangeSelect={(id) =>
                      handleChange({
                        target: {
                          name: 'language',
                          value: id,
                        },
                      })
                    }
                  />
                </FormGroup>
              </Col>

              <Col xs={12}>
                <S.FooterButtons>
                  <Button
                    className="btn-cancel"
                    medium
                    onClick={() => goBack()}
                    type="button"
                    disabled={loading}
                  >
                    {i18n.t('commons.buttons.cancel-button')}
                  </Button>
                  <Button
                    medium
                    type="submit"
                    hasLoading={loading}
                    loading={loading}
                    disabled={loading}
                  >
                    {i18n.t('commons.buttons.save-settings')}
                  </Button>
                </S.FooterButtons>
              </Col>
            </Row>
          </S.Form>
        )}
      </Formik>
    </>
  );
};

SidebarGeneralProfile.propTypes = {
  user: PropTypes.object,
  onSave: PropTypes.func,
  loading: PropTypes.bool,
};

export default SidebarGeneralProfile;
