import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useIntervalOption, useWidgets } from 'modules/Dashboard/Widgets/Hooks';
import { useRegisteredUsers } from 'modules/Dashboard/Widgets/Users/Hooks';

import { RegisteredUsers } from './RegisteredUsers.component';
import { UsersWidgetPlaceholder } from 'modules/Dashboard/Widgets/Users/Common';
import { WidgetSettings } from 'modules/Dashboard/Widgets/Common';
import { MOCKED_DATA } from 'modules/Dashboard/Widgets/Users/UserWidgets.utils';

export const RegisteredUsersContainer = ({ widget, isMock }) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const { intervalOption, setIntervalOption } = useIntervalOption({
    defaultOption: widget?.settings?.default_option || '7',
  });

  const { fetchRegisteredUsers, registeredUsers } = useRegisteredUsers();
  const { saveWidgetSettings, removeActiveWidget } = useWidgets();

  useEffect(() => {
    if (isMock) return;

    fetchRegisteredUsers(intervalOption);
  }, []);

  const handleChangeIntervalOption = (option) => {
    if (isMock) return;

    setIntervalOption(option);
    fetchRegisteredUsers(option);
  };

  if (registeredUsers?.loading) {
    return <UsersWidgetPlaceholder widget={widget} />;
  }

  if (isSettingsOpen) {
    return (
      <WidgetSettings
        widget={widget}
        onSave={saveWidgetSettings}
        onClose={() => setIsSettingsOpen(false)}
        onRemoveWidget={removeActiveWidget}
      />
    );
  }

  return (
    <RegisteredUsers
      widget={widget}
      data={isMock ? MOCKED_DATA : registeredUsers?.data}
      intervalOption={intervalOption}
      onChangeIntervalOption={handleChangeIntervalOption}
      onOpenSettings={() => setIsSettingsOpen(true)}
      isMock={isMock}
    />
  );
};

RegisteredUsersContainer.propTypes = {
  widget: PropTypes.object,
  isMock: PropTypes.bool,
};
