import { createSlice } from '@reduxjs/toolkit';
import { request } from 'modules/Api/HttpClient';
import {
  CUSTOM_TESTS_CATEGORIES_URL,
  ANSWER_PARTIAL_TEST_SHOW_URL,
  CUSTOM_TEST_GRADES_URL,
  ANSWER_PARTIAL_TEST_RANGE_URL,
  ANSWER_PARTIAL_TEST_UPDATE_TO_ANSWER_URL,
} from 'modules/Api/Routes';
import { Toast } from 'modules/Core/Common';
import { defaultErrorToast } from 'modules/Utils';
import { formDataFromObj } from 'modules/Api/RequestData';
import { isGroupOxford } from 'modules/Utils/InstitutionsGroups';

const startDate = new Date(new Date().setMonth(new Date().getMonth() - 1))
  .toISOString()
  .substring(0, 10);
const endDate = new Date().toISOString().substring(0, 10);

const initialState = {
  loading: false,
  error: '',
  data: {
    categories: [],
  },
  currentSelected: {
    page: 1,
    perPage: 10,
    category: 0,
    categoryTag: '',
    answers: [],
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
  answerSheetView: {
    data: null,
    loading: false,
  },
  setToggleAll: false,
  editAnswer: {
    loading: false,
    error: '',
    currentAnswerId: null,
    currentMark: '',
    currentStatus: '',
  },
  availableMarks: {
    loading: false,
    error: '',
    data: [],
  },
  exportFilter: {
    startDate: startDate,
    endDate: endDate,
    extension: 'csv',
    category: '',
    link: '',
  },
};

const AnswerSheetSlice = createSlice({
  name: 'answerSheet',
  initialState,
  reducers: {
    cleanState: () => ({ ...initialState }),
    changePerPage: (state, action) => {
      state.currentSelected.perPage = action.payload;
      state.currentSelected.page = 1;
    },
    changeExportFilter: (state, action) => {
      state.exportFilter = {
        ...state.exportFilter,
        [action.payload?.param]: action.payload?.value,
      };
    },
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.currentSelected.sort.sortType =
        state.currentSelected.sort.sortBy === newSort &&
        state.currentSelected.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.currentSelected.sort.sortBy = action.payload;
    },
    requestAnswerSheet: (state) => {
      state.loading = true;
    },
    requestAnswerSheetView: (state) => {
      state.answerSheetView.loading = true;
      state.answerSheetView.data = null;
    },
    receiveAnswerSheetView: (state, action) => {
      state.answerSheetView.loading = false;
      state.answerSheetView.data = action.payload;
    },
    clearAnswerSheetView: (state) => {
      state.answerSheetView.data = null;
    },
    clearEditAnswer: (state) => {
      state.editAnswer = {
        loading: false,
        error: '',
        currentAnswerId: null,
        currentMark: '',
        currentStatus: '',
      };
      state.availableMarks = {
        loading: false,
        error: '',
        data: [],
      };
    },
    receiveAnswerSheet: (state, action) => {
      state.data = {
        ...state.data,
        categories: action.payload,
      };
    },
    receiveAnswerSheetList: (state, action) => {
      state.setToggleAll = false;
      state.loading = false;
      state.currentSelected = {
        ...state.currentSelected,
        answers: action.payload.data.answers,
        grades_average_per_category:
          action.payload.data?.grades_average_per_category,
        grades_sum_per_category: action.payload.data?.grades_sum_per_category,
        exercise: action.payload.data.exercise,
        overall_marks_by_evaluators:
          action.payload.data?.overall_marks_by_evaluators,
        total: action.payload.data?.total_items,
        export: action.payload.data?.export,
      };
    },
    updateToggleAll: (state) => {
      state.setToggleAll = !state.setToggleAll;
    },
    changeCategory: (state, action) => {
      state.currentSelected.category = action.payload.category;
      state.currentSelected.categoryTag = action.payload.categoryTag;
    },
    changeCategoryData: (state, action) => {
      state.currentSelected.categoryData = action.payload;
    },
    receiveAnswerSheetError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    receiveAnswerSheetViewError: (state, action) => {
      state.error = action.payload;
      state.answerSheetView.loading = false;
    },
    requestUpdateCurrentAnswerSheet: (state) => {
      state.editAnswer.loading = true;
      state.editAnswer.error = '';
    },
    receiveUpdateCurrentAnswerSheetSuccess: (state) => {
      state.editAnswer = {
        loading: false,
        error: '',
        currentAnswerId: null,
        currentMark: '',
        currentStatus: '',
      };
      state.availableMarks = {
        loading: false,
        error: '',
        data: [],
      };
    },
    receiveUpdateCurrentAnswerSheetError: (state, action) => {
      state.editAnswer.loading = false;
      state.editAnswer.error = action.payload;
    },
    requestAvailableMarksEditAnswerSheet: (state) => {
      state.availableMarks.loading = true;
      state.availableMarks.error = '';
    },
    receiveAvailableMarksEditAnswerSheetSuccess: (state, action) => {
      state.availableMarks = {
        loading: false,
        error: '',
        data: action.payload,
      };
    },
    receiveAvailableMarksEditAnswerSheetError: (state, action) => {
      state.editAnswer.availableMarks.loading = false;
      state.editAnswer.availableMarks.error = action.payload;
    },
    changeAnswerSheetPage: (state, action) => {
      state.currentSelected.page = action.payload;
    },
    changeCurrentAnswerId: (state, action) => {
      state.editAnswer.currentAnswerId = action.payload;
    },
    changeCurrentAnswerMark: (state, action) => {
      state.editAnswer.currentMark = action.payload || '';
    },
    changeCurrentAnswerStatus: (state, action) => {
      state.editAnswer.currentStatus = action.payload || '';
    },
    requestExportLinks: (state) => {
      state.exportFilter.link = '';
      state.loading = true;
    },
    receiveExportLinks: (state, action) => {
      state.loading = false;

      const category = action?.payload.link_answer_sheets?.find((item) => {
        const paramsString =
          item?.[`link_${state.exportFilter.extension}`]?.split('&');

        const category = paramsString
          ?.find((item) => item?.includes('custom_test_category_id'))
          ?.split('=');

        return category?.[1] === state.exportFilter.category;
      });

      state.exportFilter.link =
        category?.[`link_${state.exportFilter.extension}`];
    },
    receiveExportLinksError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

const Actions = AnswerSheetSlice.actions;

const Selectors = {
  fetchListData: (state) => state.answerSheet,
};

const Async = {
  fetchAnswerSheet: (id, groupId) => async (dispatch) => {
    let action;
    dispatch(Actions.requestAnswerSheet());

    const params = {};

    if (isGroupOxford(groupId)) {
      params.overall_scoring_id = id;
    } else {
      params.exercise_id = id;
    }

    try {
      const response = await request({
        method: 'GET',
        url: CUSTOM_TESTS_CATEGORIES_URL,
        params,
      });

      action = Actions.receiveAnswerSheet(response.data);
    } catch (e) {
      action = Actions.receiveAnswerSheetError(e.message);
    }

    dispatch(action);
  },

  fetchAnswerSheetView: (id) => async (dispatch) => {
    let action;
    dispatch(Actions.requestAnswerSheetView());

    try {
      const response = await request({
        method: 'GET',
        url: `${ANSWER_PARTIAL_TEST_SHOW_URL}/${id}`,
      });

      action = Actions.receiveAnswerSheetView(response.data.content);
    } catch (e) {
      action = Actions.receiveAnswerSheetViewError(e.message);
    }

    dispatch(action);
  },

  fetchAnswerSheetList:
    (id, category, groupId) => async (dispatch, getState) => {
      const {
        answerSheet: {
          currentSelected: {
            page,
            perPage,
            sort: { sortType, sortBy },
          },
        },
      } = getState();

      let action;

      dispatch(Actions.requestAnswerSheet());

      const params = {
        page,
        paginates_per: perPage,
        sort: sortType,
        sort_by: sortBy,
      };

      if (isGroupOxford(groupId)) {
        params.overall_scoring_id = id;
      } else {
        params.exercise_id = id;
      }

      try {
        const response = await request({
          method: 'GET',
          url: `${CUSTOM_TEST_GRADES_URL}/${category}`,
          params,
        });

        action = Actions.receiveAnswerSheetList(response.data.content);
      } catch (e) {
        action = Actions.receiveAnswerSheetError(e.message);
      }

      dispatch(action);
    },

  fetchAvailableMarksEditAnswerSheet: (answerId) => async (dispatch) => {
    let action;

    dispatch(Actions.requestAvailableMarksEditAnswerSheet());

    try {
      const response = await request({
        method: 'GET',
        url: `${ANSWER_PARTIAL_TEST_RANGE_URL}/${answerId}`,
      });

      action = Actions.receiveAvailableMarksEditAnswerSheetSuccess(
        response.data
      );
    } catch (e) {
      action = Actions.receiveAvailableMarksEditAnswerSheetError(e.message);
    }

    dispatch(action);
  },

  updateCurrentAnswerSheet:
    ({ onSuccess, onError }) =>
    async (dispatch, getState) => {
      const {
        answerSheet: { editAnswer },
      } = getState();

      let action;

      dispatch(Actions.requestUpdateCurrentAnswerSheet());

      try {
        const data = formDataFromObj({
          marks: Number(editAnswer.currentMark),
          status: editAnswer.currentStatus,
        });

        const response = await request({
          method: 'PUT',
          url: `${ANSWER_PARTIAL_TEST_UPDATE_TO_ANSWER_URL}/${editAnswer.currentAnswerId}`,
          data,
        });

        action = Actions.receiveUpdateCurrentAnswerSheetSuccess();
        Toast('Updated successfully!', 'success');
        if (onSuccess) onSuccess(response.data.content);
      } catch (error) {
        action = Actions.receiveUpdateCurrentAnswerSheetError();
        defaultErrorToast('Sorry, an error occurred during get antifraud log!')(
          error
        );
        if (onError) onError(error);
      }

      dispatch(action);
    },
};

const reducer = AnswerSheetSlice.reducer;

export { reducer, Actions, Async, Selectors };
