import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  SETTINGS_URL,
  SETTINGS_UPDATE_URL,
  CUSTOM_TEST_BY_WHITELABEL_URL,
  SETTINGS_DELETE_IMAGE_URL,
} from 'modules/Api/Routes';
import {
  SETTINGS_INITIAL_STATE,
  createSettingsDataRequestModel,
  AI_EVALUATOR_AVATAR,
} from 'modules/Settings/SettingsUtils';

let cancelToken;

const initialState = {
  loading: false,
  updateLoading: false,
  error: null,
  data: SETTINGS_INITIAL_STATE,
  customTests: {
    loading: false,
    data: [],
  },
};

const EDUSYNCH_WHITELABEL_ID = 100;

const SettingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    changeSettingsField: (state, action) => {
      state.data[action.payload.key] = action.payload.value;
    },
    requestSettings: (state) => {
      state.loading = true;
      state.error = null;
    },
    requestCustomTests: (state) => {
      state.customTests.loading = true;
    },
    receiveSettingsData: (state, action) => {
      const data = action.payload;

      state.loading = false;
      state.data = {
        ...state.data,
        ...data,
        logo_certificate: data?.logo_certificate?.url || null,
        logo_signature: data?.logo_signature?.url || null,
        ai_evaluator_avatar: data?.ai_evaluator_avatar?.url || null,
        page_setup: {
          steps: data.page_setup?.steps || [],
        },
      };
    },
    receiveCustomTestsList: (state, action) => {
      state.customTests = {
        ...state.customTests,
        loading: false,
        data: action.payload.content,
      };
    },
    receiveCustomTestsError: (state, action) => {
      state.customTests = {
        ...state.customTests,
        loading: false,
      };
      state.error = action.payload;
    },
    receiveSettingsDataError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    requestUpdateSettings: (state) => {
      state.updateLoading = true;
    },
    receiveUpdateSettings: (state, action) => {
      const data = action.payload;

      state.updateLoading = false;
      state.loading = false;
      state.data = {
        ...state.data,
        ...data,
        logo_certificate: data?.logo_certificate?.url || null,
        logo_signature: data?.logo_signature?.url || null,
        ai_evaluator_avatar: data?.ai_evaluator_avatar?.url || null,
      };
    },
    receiveUpdateSettingsError: (state, action) => {
      state.updateLoading = false;
      state.error = action.payload;
    },
    receiveRemoveAvatar: (state) => {
      state.data.ai_evaluator_avatar = null;
    },
  },
});

const Actions = SettingsSlice.actions;

const Selectors = {
  fetchListData: (state) => state.settings,
  settingsLoading: ({ settings: { loading } }) => ({ loading }),
};

const Async = {
  fetchSettings: () => async (dispatch) => {
    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestSettings());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: SETTINGS_URL,
      });

      action = Actions.receiveSettingsData(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveSettingsDataError(e.message);
      }
    }

    action && dispatch(action);
  },

  updateSettings:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      let action;
      dispatch(Actions.requestUpdateSettings());

      const formData = createSettingsDataRequestModel(data);

      try {
        const response = await request({
          method: 'PUT',
          url: SETTINGS_UPDATE_URL,
          data: formData,
        });

        action = Actions.receiveUpdateSettings(response.data.content);
        onSuccess();
      } catch (e) {
        onError(e);
        action = Actions.receiveUpdateSettingsError(e.message);
      }

      action && dispatch(action);
    },

  deleteSettingsImage:
    ({ id, type, onSuccess, onError }) =>
    async () => {
      try {
        const response = await request({
          method: 'PUT',
          url: SETTINGS_DELETE_IMAGE_URL,
          data: {
            id,
            type,
          },
        });
        if (onSuccess) onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  removeAvatar: ({ id, onSuccess, onError }) =>
    Async.deleteSettingsImage({
      id,
      type: AI_EVALUATOR_AVATAR,
      onSuccess,
      onError,
    }),

  fetchCustomTestsList: () => async (dispatch) => {
    let action;

    dispatch(Actions.requestCustomTests());

    try {
      const response = await request({
        method: 'GET',
        url: CUSTOM_TEST_BY_WHITELABEL_URL,
        params: {
          whitelabel_id: EDUSYNCH_WHITELABEL_ID,
        },
      });

      action = Actions.receiveCustomTestsList(response.data);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveCustomTestsError(e.message);
      }
    }

    action && dispatch(action);
  },
};

const { reducer } = SettingsSlice;

export { reducer, Actions, Async, Selectors };
