import React from 'react';
import PropTypes from 'prop-types';
import * as S from './BasicLink.styles';

const BasicLink = ({ children, onClick, ...props }) => (
  <S.Anchor
    onClick={(evt) => {
      evt.preventDefault();
      onClick();
    }}
    {...props}
  >
    {children}
  </S.Anchor>
);

BasicLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  onClick: PropTypes.func.isRequired,
};

export default BasicLink;
