import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'modules/Core/Common';

import * as S from './TestsTooltip.styles';

export const TestsTooltip = ({ isToggled, tests = [] }) => {
  const tooltipText = useMemo(() => {
    return tests.map((test) => (
      <S.Text key={test}>
        <p>{test}</p>
      </S.Text>
    ));
  }, [tests?.length]);

  const renderLabel = useMemo(() => {
    if (!tests?.length) return null;

    if (tests?.length === 1) {
      return <S.TooltipItem>{tests[0]}</S.TooltipItem>;
    }

    return (
      <S.TooltipItem isMultipleTests={true}>
        {tests?.length} TESTS
      </S.TooltipItem>
    );
  }, [tests?.length]);

  return (
    <Tooltip
      disabled={tests?.length <= 1 || isToggled}
      overlay={tooltipText}
      placement="bottom"
      overlayInnerStyle={{
        backgroundColor: '#0075EA',
        borderRadius: '4px',
        padding: '16px',
      }}
    >
      {renderLabel}
    </Tooltip>
  );
};

TestsTooltip.propTypes = {
  isToggled: PropTypes.bool,
  tests: PropTypes.array,
};
