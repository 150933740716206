import styled, { css } from 'styled-components';
import { ZIndexes } from 'modules/Utils/ZIndexes';

export const Container = styled.div``;

export const ButtonContainer = styled.div`
  display: inline-block;
  z-index: ${ZIndexes.overlay};

  ${(props) =>
    props.isShowDropdown &&
    css`
      position: relative;
    `}

  ${(props) =>
    props.block &&
    css`
      width: 100%;
    `}
`;

export const Button = styled.button`
  ${(props) => props.theme.config.typograph.xsmall};
  color: ${(props) => props.theme.config.colors.text_base};
  background: ${(props) => props.theme.config.colors.light};
  padding: 0.4rem 1.6rem;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);
  border-radius: 32px;
  border: none;
  outline: none;
  min-height: 3.2rem;
  min-width: 8rem;
  text-align: left;
  max-width: 12rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: ${(props) => props.theme.config.spacing.spacing_2};
  margin-right: ${(props) => props.theme.config.spacing.spacing_2};

  &:disabled {
    cursor: not-allowed;
  }

  i,
  svg,
  span {
    vertical-align: middle;
  }

  svg {
    display: inline-block;
    width: 1.4rem;

    path {
      fill: ${(props) => props.theme.config.colors.gray_300};
      margin-left: ${(props) => props.theme.config.spacing.spacing_3};
    }
  }
`;

export const Item = styled.li`
  ${(props) => props.theme.config.typograph.xsmall};
  color: ${(props) => props.theme.config.colors.text_base};
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  padding: 1.5rem 1.2rem 1.5rem 1.6rem;

  svg {
    width: 1.6rem;
    height: 1.6rem;

    &::before {
      fill: ${(props) => props.theme.config.colors.green};
    }
  }

  &:hover {
    background-color: rgba(93, 102, 112, 0.05);
  }

  & + li {
    border-top: 1px solid ${(props) => props.theme.config.colors.gray_200};
  }
`;

export const DropdownContainer = styled.div`
  background: ${(props) => props.theme.config.colors.light};
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.15);
  position: absolute;
  border-radius: 0.4rem;
  width: 11rem;
  bottom: 5rem;
  left: 50%;
  transform: translateX(-50%);

  // arrow-up
  &::before {
    content: '';
    display: block;
    width: 1rem;
    height: 1rem;
    background: ${(props) => props.theme.config.colors.light};
    position: absolute;
    right: 50%;
    transform: translateX(-50%);
    bottom: -0.4rem;
    transition: background-color 0.2s ease;
    transform: rotate(45deg);
    z-index: -1;
  }

  ${(props) =>
    props.secondary &&
    css`
      padding: 0;
    `};

  ${(props) =>
    props.block &&
    css`
      width: 100%;
      margin-top: ${(props) => props.theme.config.spacing.spacing_2};
    `};
`;

export const Dropdown = styled.ul`
  z-index: ${ZIndexes.overlay + 1};

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.config.colors.gray_400};
    border-radius: 2rem;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.config.colors.gray_400};
  }

  ${(props) =>
    props.secondary &&
    css`
      overflow-y: auto;
      padding: 0;
    `};
`;
