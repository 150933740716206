import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  FEATURE_FLAGS_URL,
  FEATURE_FLAG_BY_ID_URL,
  FEATURE_FLAGS_CREATE_URL,
  FEATURE_FLAGS_UPDATE_URL,
  FEATURE_FLAGS_DELETE_URL,
} from 'modules/Api/Routes';
import { formDataFromObj } from 'modules/Api/RequestData';
import { defaultRequest } from 'modules/Utils/DefaultRequest';

let cancelToken;

const initialState = {
  loading: false,
  error: null,
  data: {
    page: 1,
    perPage: 10,
    search: '',
    sort: {
      sortType: null,
      sortBy: null,
    },
    feature_type: null,
    status: 'ALL',
  },
};

const featureFlagsSlice = createSlice({
  name: 'features',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    requestFeatureFlags: (state) => {
      state.loading = true;
      state.error = null;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    /**
     * receive a success featureFlags list response
     */
    receiveFeatureFlagsList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        features: action.payload.features,
        total: action.payload.total_items,
      };
    },
    clearFeatureFlagsList: (state) => {
      state.loading = false;
      state.data = {
        page: 1,
        perPage: 10,
        search: '',
        sort: {
          sortType: null,
          sortBy: null,
        },
        feature_type: null,
        status: 'ALL',
      };
    },
    /**
     * receive an error response
     */
    receiveFeatureFlagsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changeFeatureFlagsPage: (state, action) => {
      state.data.page = action.payload;
    },
    changeFeatureFlagsSearch: (state, action) => {
      state.data.search = action.payload.search;
      state.data.page = 1;
      state.data.feature_type = action.payload.feature_type;
      state.data.status = action.payload.status;
    },
    updateFeatureFlagsOnList: (state, action) => {
      const index = state.data.features.findIndex(
        (student) => student.id === action.payload.id
      );
      const updated = { ...state.data.features[index], ...action.payload };
      if (index !== -1) state.data.features.splice(index, 1, updated);
    },
    changeFeatureFlagsFilters: (state, action) => {
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
      state.data.search = action?.payload.search || '';
      state.data.feature_type = action?.payload?.feature_type || null;
      state.data.status = action?.payload?.status || 'ALL';
    },
    clearFeatureFlagsFilters: (state) => {
      state.data.search = '';
      state.data.page = 1;
      state.data.perPage = 10;
      state.data.sort.sortType = null;
      state.data.sort.sortBy = null;
      state.data.feature_type = null;
      state.data.status = 'ALL';
    },
  },
});

const Actions = featureFlagsSlice.actions;

const Selectors = {
  fetchListData: (state) => state.features,
  featureLoading: ({ features: { loading } }) => ({ loading }),
};

const Async = {
  fetchFeatureFlagsList: () => async (dispatch, getState) => {
    const {
      features: {
        data: {
          page,
          perPage,
          search,
          sort: { sortType, sortBy },
          feature_type,
          status,
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestFeatureFlags());

    const statusValue =
      status === undefined || status === null || status === 'ALL'
        ? null
        : status === 'ACTIVE';

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: FEATURE_FLAGS_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          sort: sortType,
          sort_by: sortBy,
          feature_type: feature_type?.toUpperCase(),
          status: statusValue,
        },
      });

      action = Actions.receiveFeatureFlagsList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveFeatureFlagsError(e.message);
      }
    }

    action && dispatch(action);
  },

  createFeatureFlag:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestFeatureFlags());
      try {
        const featuresData = formDataFromObj(data);

        const response = await request({
          method: 'POST',
          url: FEATURE_FLAGS_CREATE_URL,
          data: featuresData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveFeatureFlagsError());
        onError(e);
      }
    },

  getFeatureFlagsById:
    ({ id, onSuccess, onError }) =>
    async () => {
      try {
        const response = await request({
          method: 'GET',
          url: `${FEATURE_FLAG_BY_ID_URL}?id=${id}`,
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  deleteFeatureFlag:
    ({ id, onSuccess, onError }) =>
    async () => {
      try {
        const response = await request({
          method: 'DELETE',
          url: `${FEATURE_FLAGS_DELETE_URL}?id=${id}`,
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  updateFeatureFlag:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestFeatureFlags());
      try {
        const featuresData = formDataFromObj(data);

        const response = await request({
          method: 'PUT',
          url: FEATURE_FLAGS_UPDATE_URL,
          data: featuresData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveFeatureFlagsError());
        onError(e);
      }
    },
  updateActive:
    ({ id, active, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestFeatureFlags());
      try {
        const featuresData = formDataFromObj({ id, status: active });

        const response = await request({
          method: 'PUT',
          url: FEATURE_FLAGS_UPDATE_URL,
          data: featuresData,
        });

        dispatch(Actions.updateFeatureFlagsOnList({ id, status: active }));
        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveFeatureFlagsError());
        onError(e);
      }
    },

  fetchGenericList: (feature_type) => async (dispatch) => {
    if (feature_type) {
      defaultRequest(
        dispatch,
        feature_type,
        'GET',
        Actions.requestGeneric,
        Actions.receiveGenericList,
        Actions.receiveGenericError
      );
    }
  },
};

const { reducer } = featureFlagsSlice;

export { reducer, Actions, Async, Selectors };
