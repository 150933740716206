import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  USER_EVALUATORS_ACTIVATE_URL,
  USER_EVALUATORS_URL,
  USER_EVALUATORS_FOR_SELECTS_URL,
  SETTINGS_REMOVE_AVATAR_URL,
  USER_EVALUATORS_CREATE_URL,
  USER_EVALUATOR_BY_ID_URL,
  USER_EVALUATORS_UPDATE_URL,
  USER_EVALUATORS_DELETE_URL,
  LANGUAGES_URL,
  TIMEZONES_URL,
  USER_EVALUATORS_EVALUATABLES_URL,
  EVALUATORS_SCHEDULERS_IMPORT_URL,
} from 'modules/Api/Routes';
import { formDataFromObj } from 'modules/Api/RequestData';
import { defaultRequest } from 'modules/Utils/DefaultRequest';

let cancelToken;

const initialState = {
  loading: false,
  error: null,
  data: {
    evaluators: [],
    page: 1,
    perPage: 10,
    search: '',
    forceUpdateList: '',
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
  languages: {
    data: [],
    loading: false,
  },
  timezones: {
    data: [],
    loading: false,
  },
  evaluatorType: {
    data: ['Enterprise', 'Group', 'Unit', 'School', 'StudentClass'],
    loading: false,
  },
  evaluatables: {
    data: [],
    loading: false,
  },
  isDeleting: false,
};

const evaluatorsSlice = createSlice({
  name: 'evaluator',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    requestEvaluators: (state) => {
      state.loading = true;
      state.error = null;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    requestLanguages: (state) => {
      state.languages = {
        ...state.languages,
        loading: true,
      };
    },
    requestTimezones: (state) => {
      state.timezones = {
        ...state.timezones,
        loading: true,
      };
    },
    requestEvaluatables: (state) => {
      state.evaluatables = {
        ...state.evaluatables,
        loading: true,
      };
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsDeleting: (state, action) => {
      state.isDeleting = action.payload;
    },
    forceUpdateList: (state, action) => {
      state.data.forceUpdateList = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.lading = false;
    },
    /**
     * receive a success evaluators list response
     */
    receiveEvaluatorsList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        evaluators: action.payload.users,
        total: action.payload.total_items,
      };
    },
    clearEvaluatorsList: (state) => {
      state.loading = false;
      state.data = {
        page: 1,
        perPage: 10,
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
    },
    clearEvaluatorsFilters: (state) => {
      state.data.search = '';
      state.data.page = 1;
      state.data.perPage = 10;
      state.data.sort.sortType = null;
      state.data.sort.sortBy = null;
    },
    /**
     * receive a success language list response
     */
    receiveLanguagesList: (state, action) => {
      state.languages = {
        ...state.languages,
        loading: false,
        data: action.payload.content,
      };
    },
    receiveEvaluatables: (state, action) => {
      const evaluatablesSelectedAType = state.evaluatables.data.find(
        (item) => item?.typeId === action.payload.typeId
      );
      const removedEvaluatables = state.evaluatables.data.filter(
        (item) => item.typeId !== action.payload.typeId
      );

      state.evaluatables = {
        ...state.evaluatables,
        loading: false,
        data: [
          ...(evaluatablesSelectedAType
            ? removedEvaluatables
            : state.evaluatables.data),
          action.payload,
        ],
      };
    },
    clearEvaluatablesState: (state) => {
      state.evaluatables = {
        loading: false,
        data: [],
      };
    },
    /**
     * receive a success language list response
     */
    receiveTimezonesList: (state, action) => {
      state.timezones = {
        ...state.timezones,
        loading: false,
        data: action.payload.content,
      };
    },
    /**
     * receive an error response
     */
    receiveEvaluatorsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    receiveLanguagesError: (state, action) => {
      state.languages = {
        ...state.languages,
        loading: false,
      };
      state.error = action.payload;
    },
    receiveEvaluatablesError: (state, action) => {
      state.evaluatables = {
        ...state.evaluatables,
        loading: false,
      };
      state.error = action.payload;
    },
    receiveTimezonesError: (state, action) => {
      state.timezones = {
        ...state.timezones,
        loading: false,
      };
      state.error = action.payload;
    },
    changeEvaluatorsPage: (state, action) => {
      state.data.page = action.payload;
    },
    changeEvaluatorSearch: (state, action) => {
      state.data.search = action.payload.search;
      state.data.page = 1;
    },
    changeEvaluatorFilters: (state, action) => {
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
      state.data.search = action?.payload.search || '';
    },
  },
});

const Actions = evaluatorsSlice.actions;

const Selectors = {
  fetchListData: (state) => state.evaluator,
  evaluatorLoading: ({ evaluator: { loading } }) => ({ loading }),
};

const Async = {
  setActivated:
    ({
      data: evaluator,
      field,
      additionalField,
      activated,
      onSuccess,
      onError,
    }) =>
    async () => {
      try {
        const data = new FormData();
        const currentField = additionalField || field;
        data.set('id', evaluator.id);
        data.set(currentField, activated);

        const response = await request({
          method: 'PUT',
          url: USER_EVALUATORS_ACTIVATE_URL,
          data,
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  fetchEvaluatorsList:
    ({ paginate }) =>
    async (dispatch, getState) => {
      const {
        evaluator: {
          data: {
            page,
            perPage,
            search,
            sort: { sortType, sortBy },
          },
        },
      } = getState();

      ejectCancelInterceptor();
      cancelToken?.cancel();
      cancelToken = generateCancelToken();

      const paginateParams = {
        page: paginate ? page : null,
        paginates_per: paginate ? perPage : null,
      };

      let action;

      dispatch(Actions.requestEvaluators());

      try {
        const response = await request({
          cancelToken: cancelToken.token,
          method: 'GET',
          url: paginate ? USER_EVALUATORS_URL : USER_EVALUATORS_FOR_SELECTS_URL,
          params: {
            ...paginateParams,
            search,
            sort: sortType,
            sort_by: sortBy,
          },
        });

        action = Actions.receiveEvaluatorsList(response.data.content);
      } catch (e) {
        if (!isCancel(e)) {
          action = Actions.receiveEvaluatorsError(e.message);
        }
      }

      action && dispatch(action);
    },

  removeAvatar:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      try {
        const data = formDataFromObj({ id });

        const response = await request({
          method: 'PUT',
          url: SETTINGS_REMOVE_AVATAR_URL,
          data,
        });
        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  createEvaluator:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestEvaluators());
      try {
        if (!data.avatar) delete data.avatar;

        const formattedIntervals = data?.intervals?.map(
          ({ end_date, end_time, start_date, start_time }) => ({
            end_date,
            end_time,
            start_date,
            start_time,
          })
        );

        const evaluatorData = formDataFromObj({
          ...data,
          intervals: formattedIntervals,
        });
        const response = await request({
          method: 'POST',
          url: USER_EVALUATORS_CREATE_URL,
          data: evaluatorData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveEvaluatorsError());
        onError(e);
      }
    },

  getEvaluatorById:
    ({ id, onSuccess, onError }) =>
    async () => {
      try {
        const response = await request({
          method: 'GET',
          url: `${USER_EVALUATOR_BY_ID_URL}?id=${id}`,
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  deleteEvaluator:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.setIsDeleting(true));

      try {
        const response = await request({
          method: 'DELETE',
          url: `${USER_EVALUATORS_DELETE_URL}?id=${id}`,
        });

        onSuccess(response);
        dispatch(Actions.setIsDeleting(false));
      } catch (e) {
        onError(e);
        dispatch(Actions.setIsDeleting(false));
      }
    },

  updateEvaluator:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestEvaluators());
      try {
        if (!(data.avatar instanceof File)) delete data.avatar;

        const formattedIntervals = data?.intervals
          ?.filter((interval) => interval?.start_date)
          ?.map((interval) => {
            const base = {
              end_date: interval?.end_date,
              end_time: interval?.end_time,
              start_date: interval?.start_date,
              start_time: interval?.start_time,
            };
            interval?.id && (base.id = interval?.id);
            return base;
          });

        const evaluatorData = formDataFromObj({
          ...data,
          intervals: JSON.stringify([
            ...formattedIntervals,
            ...data.removedIntervals,
          ]),
        });
        const response = await request({
          method: 'PUT',
          url: USER_EVALUATORS_UPDATE_URL,
          data: evaluatorData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveEvaluatorsError());
        onError(e);
      }
    },

  fetchLanguagesList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      LANGUAGES_URL,
      'GET',
      Actions.requestLanguages,
      Actions.receiveLanguagesList,
      Actions.receiveLanguagesError
    ),

  fetchTimezonesList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      TIMEZONES_URL,
      'GET',
      Actions.requestTimezones,
      Actions.receiveTimezonesList,
      Actions.receiveTimezonesError
    ),

  fetchEvaluatables: (evaluatableType, typeId) => async (dispatch) => {
    let action;

    dispatch(Actions.requestEvaluatables());

    try {
      const response = await request({
        method: 'GET',
        url: `${USER_EVALUATORS_EVALUATABLES_URL}?evaluatable_type=${evaluatableType}`,
      });

      const data = {
        type: evaluatableType,
        typeId: Number(typeId),
        chooseList: response.data,
      };

      action = Actions.receiveEvaluatables(data);
    } catch (e) {
      action = Actions.receiveEvaluatablesError(e.message);
    }

    dispatch(action);
  },

  importEvaluators:
    ({ file, onSuccess, onError, onUploadProgress }) =>
    async () => {
      const data = new FormData();
      data.set('file', file);

      try {
        const response = await request({
          method: 'POST',
          url: EVALUATORS_SCHEDULERS_IMPORT_URL,
          data,
          onUploadProgress,
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },
};

const { reducer } = evaluatorsSlice;

export { reducer, Actions, Async, Selectors };
