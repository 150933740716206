import styled, { css } from 'styled-components';
import { device } from 'modules/Utils/Devices';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: ${(props) => props.theme.config.colors.background};
  ${(props) =>
    props.imgSrc &&
    css`
      background-image: url(${props.imgSrc});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 65% 0%;
    `};

  @media screen and (max-height: 570px) and (${device.tabletAndMobile}) {
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 50px;
  }

  @media ${device.tabletAndMobile} {
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background-color: ${(props) => props.theme.config.colors.secondary};
      z-index: 1;
      opacity: 0.9;
    }
  }

  @media ${device.mobile} {
    padding-top: 40px;
    justify-content: flex-start;
  }

  @media ${device.mobileHeight} {
    padding-top: 80px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 2rem;
  z-index: 10;

  > form,
  > h1 {
    width: 100%;
    max-width: 37rem;
  }

  @media ${device.mobile} {
    > form,
    > h1 {
      max-width: 31.5rem;
    }
  }

  @media screen and (max-height: 570px) and (min-width: 1024px) {
    overflow-y: scroll;
    padding: 0 0 3rem;
    justify-content: flex-start;
    height: 100%;

    > h1 {
      margin-top: 5rem;
    }
  }
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
`;

export { Container, Content, Img };
