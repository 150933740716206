import { DashboardContainer } from 'modules/Dashboard';
import { menuBuilder } from 'services/Routes.service';
import { DashboardSecondIcon } from '@edusynch/edusynch-svg-icons';

const DashboardMenu = menuBuilder([
  {
    name: 'Dashboard',
    Icon: DashboardSecondIcon,
    component: DashboardContainer,
    exact: true,
    ability: 'dashboard',
  },
]).addSubmenu();

export default DashboardMenu;
