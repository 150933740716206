import React from 'react';
import PropTypes from 'prop-types';
import RCDropdown from 'rc-dropdown';
import './Dropdown.style';
import './styles.scss';

const Dropdown = ({ children, classes, ...props }) => (
  <RCDropdown trigger={['click']} overlayClassName={classes} {...props}>
    {children}
  </RCDropdown>
);

Dropdown.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.string,
};

export default Dropdown;
