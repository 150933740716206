import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  USER_CREATORS_URL,
  USER_CREATOR_BY_ID_URL,
  SETTINGS_REMOVE_AVATAR_URL,
  USER_CREATORS_CREATE_URL,
  USER_CREATORS_UPDATE_URL,
  USER_CREATORS_DELETE_URL,
  LANGUAGES_URL,
  TIMEZONES_URL,
} from 'modules/Api/Routes';
import { formDataFromObj } from 'modules/Api/RequestData';
import { defaultRequest } from 'modules/Utils/DefaultRequest';

let cancelToken;

const initialState = {
  loading: false,
  error: null,
  data: {
    page: 1,
    perPage: 10,
    search: '',
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
  languages: {
    data: [],
    loading: false,
  },
  timezones: {
    data: [],
    loading: false,
  },
  isDeleting: false,
};

const creatorSlice = createSlice({
  name: 'creator',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    requestCreator: (state) => {
      state.loading = true;
      state.error = null;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsDeleting: (state, action) => {
      state.isDeleting = action.payload;
    },
    requestLanguages: (state) => {
      state.languages = {
        ...state.languages,
        loading: true,
      };
    },
    requestTimezones: (state) => {
      state.timezones = {
        ...state.timezones,
        loading: true,
      };
    },
    receiveTimezonesList: (state, action) => {
      state.timezones = {
        ...state.timezones,
        loading: false,
        data: action.payload.content,
      };
    },
    receiveTimezonesError: (state, action) => {
      state.timezones = {
        ...state.timezones,
        loading: false,
      };
      state.error = action.payload;
    },
    receiveLanguagesError: (state, action) => {
      state.languages = {
        ...state.languages,
        loading: false,
      };
      state.error = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.lading = false;
    },
    /**
     * receive a success creator list response
     */
    receiveCreatorList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        creator: action.payload.users,
        total: action.payload.total_items,
      };
    },
    clearCreatorList: (state) => {
      state.loading = false;
      state.data = {
        page: 1,
        perPage: 10,
        search: '',
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
    },
    /**
     * receive a success language list response
     */
    receiveLanguagesList: (state, action) => {
      state.languages = {
        ...state.languages,
        loading: false,
        data: action.payload.content,
      };
    },
    /**
     * receive an error response
     */
    receiveCreatorError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changeCreatorPage: (state, action) => {
      state.data.page = action.payload;
    },
    changeCreatorSearch: (state, action) => {
      state.data.search = action.payload.search;
      state.data.page = 1;
    },
    changeCreatorFilters: (state, action) => {
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
      state.data.search = action?.payload.search || '';
    },
    updateCreatorOnList: (state, action) => {
      const updated = action.payload;
      const index = state.data.students.findIndex(
        (student) => student.id === updated.id
      );
      if (index !== -1) state.data.students.splice(index, 1, updated);
    },
    clearCreatorsFilters: (state) => {
      state.data.search = '';
      state.data.page = 1;
      state.data.perPage = 10;
      state.data.sort.sortType = null;
      state.data.sort.sortBy = null;
    },
  },
});

const Actions = creatorSlice.actions;

const Selectors = {
  fetchListData: (state) => state.creator,
  creatorLoading: ({ creator: { loading } }) => ({ loading }),
};

const Async = {
  setActivated:
    ({
      data: evaluator,
      field,
      additionalField,
      activated,
      onSuccess,
      onError,
    }) =>
    async () => {
      try {
        const data = new FormData();
        const currentField = additionalField || field;
        data.set('id', evaluator.id);
        data.set(currentField, activated);

        const response = await request({
          method: 'PUT',
          url: USER_CREATORS_UPDATE_URL,
          data,
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  fetchCreatorList: () => async (dispatch, getState) => {
    const {
      creator: {
        data: {
          page,
          perPage,
          search,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestCreator());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: USER_CREATORS_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receiveCreatorList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveCreatorError(e.message);
      }
    }

    action && dispatch(action);
  },

  removeAvatar:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      try {
        const data = formDataFromObj({ id });

        const response = await request({
          method: 'PUT',
          url: SETTINGS_REMOVE_AVATAR_URL,
          data,
        });
        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  createCreator:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestCreator());
      try {
        if (!data.avatar) delete data.avatar;
        const creatorData = formDataFromObj(data);

        const response = await request({
          method: 'POST',
          url: USER_CREATORS_CREATE_URL,
          data: creatorData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveCreatorError());
        onError(e);
      }
    },

  getCreatorById:
    ({ id, onSuccess, onError }) =>
    async () => {
      try {
        const response = await request({
          method: 'GET',
          url: `${USER_CREATOR_BY_ID_URL}?id=${id}`,
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  deleteCreator:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.setIsDeleting(true));

      try {
        const response = await request({
          method: 'DELETE',
          url: `${USER_CREATORS_DELETE_URL}?id=${id}`,
        });

        onSuccess(response);
        dispatch(Actions.setIsDeleting(false));
      } catch (e) {
        onError(e);
        dispatch(Actions.setIsDeleting(false));
      }
    },

  updateCreator:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestCreator());
      try {
        if (!(data.avatar instanceof File)) delete data.avatar;
        if (!data.password) delete data.password;
        if (!data.password_confirmation) delete data.password_confirmation;

        const creatorData = formDataFromObj(data);

        const response = await request({
          method: 'PUT',
          url: USER_CREATORS_UPDATE_URL,
          data: creatorData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveCreatorError());
        onError(e);
      }
    },

  fetchLanguagesList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      LANGUAGES_URL,
      'GET',
      Actions.requestLanguages,
      Actions.receiveLanguagesList,
      Actions.receiveLanguagesError
    ),

  fetchTimezonesList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      TIMEZONES_URL,
      'GET',
      Actions.requestTimezones,
      Actions.receiveTimezonesList,
      Actions.receiveTimezonesError
    ),
};

const { reducer } = creatorSlice;

export { reducer, Actions, Async, Selectors };
