import { EVENT_MESSAGES } from 'modules/Antifraud/Utils/AntifraudEvents.utils';
const MAX_LABEL_WORDS = 6;

export const getEProctoringEventMessage = (event) => {
  return EVENT_MESSAGES[event?.category] || '';
};

export const formatChartLabels = (data) => {
  return (
    data?.events
      ?.filter((item) => item.total)
      ?.map((item) => getEProctoringEventMessage(item)) || []
  );
};

export const formatChartValues = (data, viewMode) => {
  if (viewMode === 'percent') return formatChartPercentages(data);

  return (
    data?.events?.filter((item) => item.total)?.map((item) => item.total) || []
  );
};

export const formatChartPercentages = (data) => {
  return (
    data?.events
      ?.filter((item) => item.total)
      ?.map((item) => Number(item.percentage?.replace('%', '') || '')) || []
  );
};

export const formatChartData = (
  formattedChartLabels,
  formattedChartData,
  backgroundColor,
  label
) => {
  return {
    labels: formattedChartLabels,
    datasets: [
      {
        label,
        data: formattedChartData,
        backgroundColor: backgroundColor,
        barThickness: 10,
        borderRadius: 10,
      },
    ],
  };
};

export const formatChartOptions = (viewMode) => {
  return {
    indexAxis: 'y',
    maintainAspectRatio: false,
    scales: {
      x: {
        min: 0,
        max: viewMode === 'percent' ? 100 : undefined,
        ticks: {
          callback(tickValue) {
            const currentLabel = this.getLabelForValue(Number(tickValue));

            return viewMode === 'percent' ? `${currentLabel}%` : currentLabel;
          },
        },
      },
      y: {
        ticks: {
          crossAlign: 'far',
          callback(tickValue) {
            const currentLabel = this.getLabelForValue(Number(tickValue));
            const splittedLabel = currentLabel.split(' ');
            const isLongLabel = splittedLabel?.length > MAX_LABEL_WORDS;

            if (isLongLabel) {
              return splittedLabel.slice(0, MAX_LABEL_WORDS).join(' ') + '...';
            }

            return currentLabel;
          },
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
  };
};

export const MOCKED_DATA = {
  events: [
    {
      category: 'MOVE_FOCUS',
      total: 199,
      percentage: '60%',
    },
    {
      category: 'MULTIPLE_CAMS',
      total: 105,
      percentage: '50%',
    },
    {
      category: 'MULTIPLE_DISPLAYS',
      total: 84,
      percentage: '45%',
    },
    {
      category: 'MULTIPLE_FACES',
      total: 62,
      percentage: '40%',
    },
    {
      category: 'NEW_TAB',
      total: 49,
      percentage: '35%',
    },
    {
      category: 'MULTIPLE_FACES_DETECTED',
      total: 32,
      percentage: '30%',
    },
    {
      category: 'NO_FACE_DETECTED',
      total: 22,
      percentage: '25%',
    },
    {
      category: 'NEW_TAB_WINDOW',
      total: 18,
      percentage: '20%',
    },
    {
      category: 'START_STREAMING',
      total: 11,
      percentage: '5%',
    },
    {
      category: 'STOP_STREAMING',
      total: 3,
      percentage: '2%',
    },
  ],
};
