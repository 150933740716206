import React from 'react';
import { CloseIcon } from '@edusynch/edusynch-svg-icons';
import MaintenanceImage from 'assets/layout/maintenance-warning-icon.svg';
import { useMaintenance } from 'modules/Maintenance/Hooks';

import * as S from './MaintenanceTopBar.styles';

const MaintenanceTopBar = () => {
  const { maintenanceDescription, handleCloseByUser, hideMaintenanceTopBar } =
    useMaintenance();

  if (hideMaintenanceTopBar) return null;

  return (
    <S.Container className="maintenance-topbar">
      <S.Content>
        <S.Message>
          <img src={MaintenanceImage} alt="" />
          <span>{maintenanceDescription}</span>
        </S.Message>
        <S.Close onClick={handleCloseByUser}>
          <CloseIcon type="small" />
        </S.Close>
      </S.Content>
    </S.Container>
  );
};

export default MaintenanceTopBar;
