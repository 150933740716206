import styled from 'styled-components';
import { Row } from 'modules/Core/Common';
import { Card } from 'modules/Core/Common';
import { ZIndexes } from 'modules/Utils/ZIndexes';

const RowMargin = styled(Row)`
  position: relative;
  margin-bottom: ${(props) => props.theme.config.spacing.spacing_4};
`;

const RightCard = styled(Card)`
  min-height: 507px;
  position: relative;
  z-index: ${ZIndexes.fullscreen - 5};
`;

export { RowMargin, RightCard };
