import React from 'react';
import PropTypes from 'prop-types';
import { format, utcToTimezone } from 'modules/Utils/Date';
import { useAuth } from 'modules/Auth/Hooks';
import { ClockIcon } from '@edusynch/edusynch-svg-icons';

import * as S from './DateTime.styles';

export const DateTime = ({ date, dateFormat, hasIcon, onlyDate }) => {
  const { userTimeFormatSeconds, userTimezone, userDateFormat } = useAuth();
  const formatedDate = utcToTimezone(date, userTimezone);
  const dateFormatToUse = dateFormat || userDateFormat;

  if (onlyDate) return format(formatedDate, dateFormatToUse);

  return (
    <>
      {format(formatedDate, dateFormatToUse)}

      <S.HourSpan>
        {hasIcon && <ClockIcon type="small" />}
        {format(formatedDate, userTimeFormatSeconds)}
      </S.HourSpan>
    </>
  );
};

DateTime.propTypes = {
  date: PropTypes.any,
  onlyDate: PropTypes.bool,
  dateFormat: PropTypes.string,
  hasIcon: PropTypes.bool,
};
