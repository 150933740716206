import React from 'react';
import PropTypes from 'prop-types';

import * as S from './LabelWithBackground.styles';

export const LabelWithBackground = ({ label = '', onClick, ...props }) => {
  return (
    <S.Label onClick={onClick} {...props}>
      {label}
    </S.Label>
  );
};

LabelWithBackground.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  onClick: PropTypes.func,
};
