import styled from 'styled-components';

export const ContentFilter = styled.div`
  h6 {
    ${(props) => props.theme.config.typograph.xsmall};
    color: ${(props) => props.theme.config.colors.gray_400};
    margin-bottom: 0.8rem;
  }

  div {
    display: flex;
    align-items: center;

    p {
      ${(props) => props.theme.config.typograph.small};
      color: ${(props) => props.theme.config.colors.gray_400};
      font-weight: bold;
      margin-left: 0.8rem;
    }
  }
`;

export const ContentAction = styled.div`
  span {
    display: none;
  }
`;
