import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { Bar } from 'react-chartjs-2';
import i18n from 'i18next';
import {
  IconButton,
  Box,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';

import { Title } from 'modules/Core/Common';
import {
  WidgetCard,
  WidgetCardHeader,
  WidgetCardSubHeader,
  WidgetCardContent,
  WidgetEmptyData,
  IntervalOptionsMenu,
} from 'modules/Dashboard/Widgets/Common';
import {
  formatChartLabels,
  formatChartValues,
  formatChartData,
  formatChartOptions,
} from 'modules/Dashboard/Widgets/EProctoring/EProctoringWidgets.utils';

import { useMediaQuery, MOBILE_SIZE, TABLET_SIZE } from 'modules/Core/Hooks';
import emptyEventsImg from 'assets/layout/empty-data/widgets-empty-data.svg';
import {
  EProctoringPluginIcon,
  SettingsIcon,
  ChartHorizontalIcon,
  PercentIcon,
} from '@edusynch/edusynch-svg-icons';
import * as S from './EProctoringEventsAnalytics.styles';

const toggleButtonStyles = {
  width: '4.8rem',
  height: '2.4rem',
};

export const EProctoringEventsAnalytics = ({
  widget,
  intervalOption,
  onChangeIntervalOption,
  data,
  onOpenSettings,
  isMock,
}) => {
  const [viewMode, setViewMode] = useState('events');

  const theme = useTheme();
  const isMobile = useMediaQuery(MOBILE_SIZE);
  const isTablet = useMediaQuery(TABLET_SIZE);

  const widgetSize = isTablet ? 'small' : widget?.settings?.size;

  const toggleButtonBackgroundColor = (toggledView) => {
    return toggledView === viewMode ? theme.config.colors.primary[200] : '';
  };

  const toggleButtonColor = (toggledView) => {
    return toggledView === viewMode ? theme.config.colors.primary[500] : 'blue';
  };

  const formattedChartLabels = formatChartLabels(data);
  const formattedChartData = formatChartValues(data, viewMode);
  const backgroundColors =
    formattedChartData?.map((_, index) =>
      index % 2 === 0
        ? theme.config.colors.primary
        : theme.config.colors.secondary
    ) || [];
  const chartData = formatChartData(
    formattedChartLabels,
    formattedChartData,
    backgroundColors,
    i18n.t('modules.dashboard.charts.titles.events')
  );
  const chartHeight =
    formattedChartLabels.length > 7
      ? 300 + (formattedChartLabels.length - 7) * 10
      : '15rem';

  const chartOptions = formatChartOptions(viewMode);

  return (
    <WidgetCard size={widgetSize}>
      <WidgetCardHeader
        size={widgetSize}
        Icon={EProctoringPluginIcon}
        title={widget?.test_type}
        subtitle={widget?.name}
        showSettingsButton={isMock && widgetSize === 'small'}
        onOpenSettings={onOpenSettings}
      >
        <WidgetCardSubHeader size={widgetSize}>
          <S.ContentAction>
            {isMock && widgetSize === 'large' && (
              <IconButton
                aria-label="settings"
                id="settings"
                onClick={onOpenSettings}
              >
                <SettingsIcon />
              </IconButton>
            )}
            {!isMobile && widgetSize === 'large' && (
              <IntervalOptionsMenu
                defaultOption={intervalOption}
                onChangeOption={onChangeIntervalOption}
              />
            )}
          </S.ContentAction>
        </WidgetCardSubHeader>
      </WidgetCardHeader>
      <WidgetCardContent size={widgetSize} borderTop>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          {!isMobile && widgetSize === 'large' ? (
            <Title
              as="h6"
              size="default"
              style={{ color: theme.config.colors.gray }}
            >
              {i18n.t('modules.dashboard.charts.titles.events')}
            </Title>
          ) : (
            <Box>
              <S.Wrapper>
                <IntervalOptionsMenu
                  defaultOption={intervalOption}
                  onChangeOption={onChangeIntervalOption}
                />
              </S.Wrapper>
            </Box>
          )}
          <Box>
            <ToggleButtonGroup
              value={viewMode}
              exclusive
              size="large"
              onChange={(_, newMode) => setViewMode(newMode)}
            >
              <ToggleButton
                value="events"
                aria-label="events"
                size="small"
                sx={{
                  ...toggleButtonStyles,
                  borderTopLeftRadius: '2.4rem',
                  borderBottomLeftRadius: '2.4rem',
                  backgroundColor: toggleButtonBackgroundColor('events'),
                  color: toggleButtonColor('events'),
                  '&:hover': {
                    backgroundColor: theme.config.colors.primary_100,
                  },
                  '&.Mui-selected, &.Mui-selected:hover': {
                    backgroundColor: theme.config.colors.primary_100,
                    'svg path': {
                      fill: theme.config.colors.primary,
                    },
                  },
                }}
              >
                <ChartHorizontalIcon width={12} height={12} />
              </ToggleButton>
              <ToggleButton
                value="percent"
                size="small"
                aria-label="percent"
                sx={{
                  ...toggleButtonStyles,
                  borderTopRightRadius: '2.4rem',
                  borderBottomRightRadius: '2.4rem',
                  backgroundColor: toggleButtonBackgroundColor('percent'),
                  color: toggleButtonColor('percent'),
                  '&:hover': {
                    backgroundColor: theme.config.colors.primary_100,
                  },
                  '&.Mui-selected, &.Mui-selected:hover': {
                    backgroundColor: theme.config.colors.primary_100,
                    'svg path': {
                      fill: theme.config.colors.primary,
                    },
                  },
                }}
              >
                <PercentIcon width={12} height={12} />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Stack>
        {formattedChartLabels.length ? (
          <S.ScrollableBox>
            <Box
              sx={{
                '& canvas': {
                  width: '100%',
                  height: chartHeight,
                },
              }}
            >
              <Bar options={chartOptions} data={chartData} />
            </Box>
          </S.ScrollableBox>
        ) : (
          <WidgetEmptyData
            imageURL={emptyEventsImg}
            title={i18n.t('modules.dashboard.charts.empty-data.no-events-yet')}
            subtitle={i18n.t(
              'modules.dashboard.charts.empty-data.add-information-to-see-results-here'
            )}
          />
        )}
      </WidgetCardContent>
    </WidgetCard>
  );
};

EProctoringEventsAnalytics.propTypes = {
  widget: PropTypes.object,
  data: PropTypes.object,
  intervalOption: PropTypes.string,
  onChangeIntervalOption: PropTypes.func,
  onOpenSettings: PropTypes.func,
  isMock: PropTypes.bool,
};
