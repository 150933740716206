import { createSlice } from '@reduxjs/toolkit';
import { request } from 'modules/Api/HttpClient';
import {
  COUNTRY_PERMISSIONS_URL,
  COUNTRY_PERMISSIONS_COUNTRIES_URL,
  COUNTRY_PERMISSIONS_BY_UNIT_ID_URL,
  COUNTRY_PERMISSIONS_CREATE_URL,
  COUNTRY_PERMISSIONS_UPDATE_URL,
  COUNTRY_PERMISSIONS_DELETE_URL,
} from 'modules/Api/Routes';
import { formDataFromObj } from 'modules/Api/RequestData';
import { Toast } from 'modules/Core/Common';
import { defaultErrorToast } from 'modules/Utils';
import { createCountryPermissionsRequestModel } from 'modules/CountryPermissions/CountryPermissionsUtils';
import i18n from 'i18next';

const initialState = {
  loading: false,
  error: null,
  data: {
    countries: [],
    countryPermissions: [],
    selectedGroup: null,
    selectedUnit: null,
  },
};

const countryPermissionsSlice = createSlice({
  name: 'countryPermissions',
  initialState,
  reducers: {
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    requestCountryPermissions: (state) => {
      state.loading = true;
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSelectedGroup: (state, action) => {
      state.data.selectedGroup = action.payload;
    },
    setSelectedUnit: (state, action) => {
      state.data.selectedUnit = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    /**
     * receive a success unit list response
     */
    receiveCountryPermissionsList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        countryPermissions: action.payload,
        total: action.payload.total_items,
      };
    },
    receiveCountryPermissionsListByUnit: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        countryPermissions: action.payload,
        total: action.payload.length,
      };
    },
    receiveCountries: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        countries: action.payload.map((country) => ({
          id: country,
          name: country,
        })),
      };
    },
    /**
     * receive an error response
     */
    receiveCountryPermissionsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

const Actions = countryPermissionsSlice.actions;

const Selectors = {
  fetchListData: (state) => state.countryPermissions,
  countryPermissionsLoading: ({ countryPermissions: { loading } }) => ({
    loading,
  }),
};

const Async = {
  fetchCountryPermissions: () => async (dispatch) => {
    let action;

    dispatch(Actions.requestCountryPermissions());

    try {
      const response = await request({
        method: 'GET',
        url: COUNTRY_PERMISSIONS_URL,
      });

      action = Actions.receiveCountryPermissionsList(
        response.data.content.country_permissions
      );
    } catch (e) {
      action = Actions.receiveCountryPermissionsError(e.message);
    }

    dispatch(action);
  },

  fetchCountryPermissionsByUnitId:
    ({ unitId }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestCountryPermissions());

      try {
        const response = await request({
          method: 'GET',
          url: COUNTRY_PERMISSIONS_BY_UNIT_ID_URL,
          params: {
            unit_id: unitId,
          },
        });

        action = Actions.receiveCountryPermissionsListByUnit(
          response.data.content
        );
      } catch (e) {
        action = Actions.receiveCountryPermissionsError(e.message);
      }

      dispatch(action);
    },

  fetchCountries: () => async (dispatch) => {
    let action;

    dispatch(Actions.requestCountryPermissions());

    try {
      const response = await request({
        method: 'GET',
        url: COUNTRY_PERMISSIONS_COUNTRIES_URL,
      });

      action = Actions.receiveCountries(response.data.content);
    } catch (e) {
      action = Actions.receiveCountryPermissionsError(e.message);
    }

    dispatch(action);
  },

  createCountryPermission:
    ({ unitId, selectedCountries }) =>
    async (dispatch) => {
      dispatch(Actions.requestCountryPermissions());
      try {
        const requestData = createCountryPermissionsRequestModel({
          unitId,
          countries: selectedCountries,
        });

        const countryPermissionsData = formDataFromObj(requestData);

        const response = await request({
          method: 'POST',
          url: COUNTRY_PERMISSIONS_CREATE_URL,
          data: countryPermissionsData,
        });

        dispatch(Actions.receiveCountryPermissionsList(response.data.content));

        const toastMessage =
          selectedCountries?.length > 0
            ? i18n.t('success-messages.successfully-toast.added-country')
            : i18n.t('success-messages.successfully-toast.removed-country');
        Toast(toastMessage, 'success');
      } catch (e) {
        dispatch(Actions.receiveCountryPermissionsError());
        defaultErrorToast(
          i18n.t(
            'errors.error-sorry-an-error-occurred-during.create-country-permissions'
          )
        )(e);
      }
    },

  updateCountryPermission:
    ({ data }) =>
    async (dispatch) => {
      dispatch(Actions.requestCountryPermissions());
      try {
        const countryPermissionData = formDataFromObj(data);

        await request({
          method: 'PUT',
          url: COUNTRY_PERMISSIONS_UPDATE_URL,
          data: countryPermissionData,
        });

        dispatch(Actions.receiveRequestSuccess());
        Toast(
          i18n.t('success-messages.successfully-toast.added-country'),
          'success'
        );
      } catch (e) {
        dispatch(Actions.receiveCountryPermissionsError());
        defaultErrorToast(
          i18n.t(
            'errors.error-sorry-an-error-occurred-during.update-country-permissions'
          )
        )(e);
      }
    },

  deleteCountryPermission:
    ({ id }) =>
    async (dispatch, getState) => {
      const {
        countryPermissions: {
          data: { selectedUnit },
        },
      } = getState();

      try {
        await request({
          method: 'DELETE',
          url: `${COUNTRY_PERMISSIONS_DELETE_URL}?id=${id}`,
        });

        dispatch(
          Async.fetchCountryPermissionsByUnitId({ unitId: selectedUnit })
        );
        Toast(
          i18n.t('success-messages.successfully-toast.removed-country'),
          'success'
        );
      } catch (e) {
        defaultErrorToast(
          i18n.t(
            'errors.error-sorry-an-error-occurred-during.delete-country-permissions'
          )
        )(e);
      }
    },
};

const reducer = countryPermissionsSlice.reducer;

export { reducer, Actions, Async, Selectors };
