export const createLiveTestsModel = (liveTests = {}) => {
  return {
    id: liveTests?.id || null,
    name: liveTests?.name || '',
    group_id: liveTests?.groupId || null,
    unit_id: liveTests?.unitId || null,
    school_id: liveTests?.schoolId || null,
    instructions: liveTests?.instructions || '',
  };
};

export const createLiveTestsEditModel = (obj = {}) => ({
  id: obj?.id || null,
  name: obj?.name || null,
  groupId: obj?.group?.id || null,
  unitId: obj?.unit?.id || null,
  schoolId: obj?.school?.id || null,
  instructions: obj?.instructions || '',
});

export const createLiveCodesModel = (liveCodes = {}) => ({
  testId: liveCodes?.live_test?.id || null,
  testName: liveCodes?.live_test?.name || null,
  liveCodes: liveCodes?.live_test?.live_test_codes || null,
});
