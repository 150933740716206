import { DashboardContainer } from 'modules/Dashboard';
import { menuBuilder } from 'services/Routes.service';
import { PricingIcon } from '@edusynch/edusynch-svg-icons';

const PricingsMenu = menuBuilder([
  {
    Icon: PricingIcon,
    name: 'Pricings',
    hidden: true,
    disabled: true,
  },
]).addSubmenu([
  {
    name: 'Plans',
    path: '/admins',
    disabled: true,
    component: DashboardContainer,
    ability: 'plans',
  },
  {
    name: 'Discounts',
    path: '/admins',
    disabled: true,
    component: DashboardContainer,
    ability: 'discounts',
  },
  {
    name: 'Purchases',
    path: '/admins',
    disabled: true,
    component: DashboardContainer,
    ability: 'purchases',
  },
]);

export default PricingsMenu;
