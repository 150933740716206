import styled from 'styled-components';
import { device } from 'modules/Utils/Devices';
import { ZIndexes } from 'modules/Utils/ZIndexes';

const Container = styled.div`
  display: flex;
  align-items: center;
  z-index: ${ZIndexes.overlay + 3};
`;

const Box = styled.div`
  margin-left: ${(props) =>
    props.thin
      ? props.theme.config.spacing.spacing_3
      : props.theme.config.spacing.spacing_2};
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ${device.mobile} {
    display: block;
  }
`;

const Text = styled.span`
  ${(props) => props.theme.config.typograph.xsmall}
  color: ${(props) => props.theme.config.colors.text_base};
  line-height: 1.4rem;
`;

export { Container, Box, Text };
