import { request } from 'modules/Api/HttpClient';
import {
  GROUPS_URL,
  UNITS_URL,
  UNITS_BY_GROUP_URL,
  SCHOOLS_URL,
  SCHOOLS_BY_UNIT_URL,
  CLASSES_BY_SCHOOL_URL,
  WHITELABELS_BY_USER_URL,
  WHITELABEL_BY_ID_URL,
  EXTERNAL_APPLICATIONS_URL,
  LICENSE_BY_GROUP_URL,
  LICENSE_BY_UNIT_URL,
  LICENSE_BY_SCHOOL_URL,
  LICENSE_BY_CLASS_URL,
  BASE_TESTS_URL,
  CUSTOM_TESTS_URL,
  CUSTOM_TEST_BY_WHITELABEL_URL,
  USER_EVALUATORS_FOR_SELECTS_URL,
} from 'modules/Api/Routes';

const fetchGroups = () =>
  request({
    method: 'GET',
    url: `${GROUPS_URL}?sort=ASC&sort_by=name`,
  });

const fetchUnits = () =>
  request({
    method: 'GET',
    url: `${UNITS_URL}?sort=ASC&sort_by=name`,
  });

const fetchUnitsByGroup = ({ groupId }) =>
  request({
    method: 'GET',
    url: `${UNITS_BY_GROUP_URL}?group_id=${groupId}`,
  });

const fetchSchools = () =>
  request({
    method: 'GET',
    url: `${SCHOOLS_URL}?sort=ASC&sort_by=name`,
  });

const fetchSchoolsByUnit = ({ unitId }) =>
  request({
    method: 'GET',
    url: `${SCHOOLS_BY_UNIT_URL}?unit_id=${unitId}`,
  });

const fetchClassesBySchool = ({ schoolId }) =>
  request({
    method: 'GET',
    url: `${CLASSES_BY_SCHOOL_URL}?school_id=${schoolId}`,
  });

const fetchWhitelabels = ({ group_id, unit_id, school_id }) =>
  request({
    method: 'GET',
    url: WHITELABELS_BY_USER_URL,
    params: {
      group_id,
      unit_id,
      school_id,
    },
  });

const fetchWhitelabelById = (id) =>
  request({
    method: 'GET',
    url: WHITELABEL_BY_ID_URL,
    params: {
      id,
    },
  });

const fetchExternalApplications = () =>
  request({
    method: 'GET',
    url: EXTERNAL_APPLICATIONS_URL,
  });

// Licenses TODO: Where do I put these?

const fetchLicensesByGroup = ({ groupId }) =>
  request({
    method: 'GET',
    url: `${LICENSE_BY_GROUP_URL}?group_id=${groupId}&only_available=true`,
  });

const fetchLicensesByUnit = ({ unitId }) =>
  request({
    method: 'GET',
    url: `${LICENSE_BY_UNIT_URL}?unit_id=${unitId}&only_available=true`,
  });

const fetchLicensesBySchool = ({ schoolId }) =>
  request({
    method: 'GET',
    url: `${LICENSE_BY_SCHOOL_URL}?school_id=${schoolId}&only_available=true`,
  });

const fetchLicensesByClass = ({ classId }) =>
  request({
    method: 'GET',
    url: `${LICENSE_BY_CLASS_URL}?class_id=${classId}&only_available=true`,
  });

// Tests

const fetchTests = () =>
  request({
    method: 'GET',
    url: BASE_TESTS_URL,
    params: {
      sort: 'ASC',
      sort_by: 'name',
    },
  });

const fetchCustomTests = () =>
  request({
    method: 'GET',
    url: CUSTOM_TESTS_URL,
    params: {
      sort: 'ASC',
      sort_by: 'name',
    },
  });

const fetchCustomTestsByWhitelabel = ({ whitelabelId }) =>
  request({
    method: 'GET',
    url: `${CUSTOM_TEST_BY_WHITELABEL_URL}?whitelabel_id=${whitelabelId}`,
  });

// Evaluators - Refactor later

const fetchEvaluators = () =>
  request({
    method: 'GET',
    url: `${USER_EVALUATORS_FOR_SELECTS_URL}`,
  });

export {
  fetchGroups,
  fetchUnits,
  fetchUnitsByGroup,
  fetchSchools,
  fetchSchoolsByUnit,
  fetchClassesBySchool,
  fetchWhitelabels,
  fetchWhitelabelById,
  fetchExternalApplications,
  fetchLicensesByGroup,
  fetchLicensesByUnit,
  fetchLicensesBySchool,
  fetchLicensesByClass,
  fetchTests,
  fetchCustomTests,
  fetchCustomTestsByWhitelabel,
  fetchEvaluators,
};
