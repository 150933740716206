import styled, { css } from 'styled-components';
import { device } from 'modules/Utils/Devices';
import { ZIndexes } from 'modules/Utils/ZIndexes';

const getPlacement = (props) => {
  switch (props.placement) {
    case 'left':
      return css`
        left: 0;
      `;
    case 'top':
      return css`
        right: 0;
        top: -200px;
      `;
    default:
      return css`
        right: 0;
      `;
  }
};

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: ${ZIndexes.overlay + 3};
  }
`;

export const Button = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  z-index: ${ZIndexes.overlay + 3};
  padding: 1.4rem ${(props) => props.theme.config.spacing.spacing_3};
  border-radius: 32px;
  background: transparent;
  transition: background-color 0.2s;
  ${(props) => props.theme.config.typograph.xsmall};
  outline: none;

  border: 1px solid ${(props) => props.theme.config.colors.primary};
  color: ${(props) => props.theme.config.colors.primary};

  svg {
    &.ArrowDownIcon {
      margin-left: ${(props) => props.theme.config.spacing.spacing_2};

      path {
        fill: ${(props) =>
          props.arrowDownIconColor || props.theme.config.colors.primary};
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    background-color: ${(props) =>
      props.theme.config.colors.primary_opacity_05};
  }

  ${(props) =>
    props.primary &&
    css`
      border: 1px solid transparent;
      color: ${(props) => props.theme.config.colors.light};
      background: ${(props) => props.theme.config.colors.primary};

      svg {
        &.ArrowDownIcon {
          path {
            fill: ${(props) =>
              props.arrowDownIconColor || props.theme.config.colors.light};
          }
        }
      }

      &:hover {
        background-color: ${(props) => props.theme.config.colors.primary_600};
      }
    `}

  ${({ iconOnly }) =>
    iconOnly &&
    css`
      display: flex;
      width: 3rem;
      height: 3rem;
      padding: 0;
      border: none;
      background: transparent;

      &:focus,
      &:active,
      &:hover {
        background: transparent;
      }
    `};
`;

export const Dropdown = styled.div`
  position: absolute;
  width: 24rem;
  top: 54px;
  ${(props) => getPlacement(props)};
  z-index: ${ZIndexes.overlay + 1};
  background: ${(props) => props.theme.config.colors.light};
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: ${ZIndexes.overlay + 5};
  min-height: fit-content;

  ${({ small }) =>
    small
      ? css`
          width: 8rem;
        `
      : ''}

  ul {
    margin: 0;

    li {
      &:first-child:hover {
        &::before {
          content: ${(props) => (props.placement === 'top' ? 'none' : '')};
          display: block;
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 6px solid
            ${(props) => props.theme.config.colors.gray_100};
          position: absolute;
          right: 19px;
          top: -6px;
          transition: background-color 0.2s ease;
          z-index: -1;
          ${({ placement, iconOnly }) => {
            switch (placement) {
              case 'left':
                return css`
                  left: ${iconOnly ? '10px' : '20px'};
                `;
              default:
                return css`
                  right: ${iconOnly ? '10px' : '20px'};
                `;
            }
          }}
        }
      }

      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      &:hover {
        transition: ease-in 0.2s;
        background: ${(props) => props.theme.config.colors.gray_300};
      }
    }
  }

  // arrow-up
  &::before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background: ${(props) => props.theme.config.colors.light};
    position: absolute;
    top: -4px;
    transition: background-color 0.2s ease;
    transform: rotate(45deg);
    z-index: -1;
    ${({ placement, iconOnly }) => {
      switch (placement) {
        case 'left':
          return css`
            left: ${iconOnly ? '10px' : '20px'};
          `;
        case 'top':
          return css`
            top: auto;
            bottom: -6px;
            right: ${iconOnly ? '10px' : '20px'};
          `;
        default:
          return css`
            right: ${iconOnly ? '10px' : '20px'};
          `;
      }
    }}
  }

  @media ${device.tabletAndMobile} {
    top: 70px;
  }
`;

export const DropdownList = styled.ul`
  margin: 6px 0 6px 0;
  max-height: 168px;
  overflow-y: auto;

  scrollbar-color: #e1e0e7 !important;
  scrollbar-width: thin !important;

  ${({ large }) =>
    large
      ? css`
          max-height: 200px;
        `
      : ''}

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background: #e1e0e7;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #e1e0e7;
  }

  li {
    & + li {
      border-top: 1px solid ${(props) => props.theme.config.colors.gray_300};
    }
  }
`;

export const DropdownButton = styled.button`
  width: 100%;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  color: ${(props) => props.color || props.theme.config.colors.text_base};
  padding: ${(props) => props.theme.config.spacing.spacing_3};
  ${(props) => props.theme.config.typograph.xsmall};
  transition: background 0.2s;
  ${(props) => props.spaced && `justify-content: space-between;`}

  &:hover {
    background: ${(props) => props.theme.config.colors.default_2};
  }

  svg {
    margin-right: ${(props) => props.theme.config.spacing.spacing_3};

    path {
      fill: ${(props) => props.theme.config.colors.gray};
    }
  }

  @media ${device.mobile} {
    margin-top: 0;
  }
`;
