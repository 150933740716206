export const getChangeEvaluatorRequestModel = ({
  id,
  evaluatorId,
  scheduleDate,
} = {}) => {
  const data = {};

  id && (data.id = id);
  evaluatorId && (data.evaluator_id = evaluatorId);
  scheduleDate && (data.schedule_date = scheduleDate);

  return data;
};
