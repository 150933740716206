import React from 'react';

import { Icon } from 'modules/Core/Common';
import PropTypes from 'prop-types';

export const TableEmptyData = ({ value, ...props }) => {
  if (value) return value;
  return <Icon name="table-empty-data" color="#E1E0E7" {...props} />;
};

TableEmptyData.propTypes = {
  value: PropTypes.any,
};
