import { useState } from 'react';

export const useIntervalOption = ({ defaultOption = '7' } = {}) => {
  const [intervalOption, setIntervalOption] = useState(defaultOption);

  return {
    intervalOption,
    setIntervalOption,
  };
};
