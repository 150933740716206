import {
  TEST_SUBMISSIONS_NORMALS_URL,
  TEST_SUBMISSIONS_CUSTOM_URL,
  TEST_SUBMISSIONS_INVALIDS_URL,
  TEST_SUBMISSIONS_PRACTICE_URL,
} from 'modules/Api/Routes';

const VALID_TESTS = ['base', 'custom', 'invalid', 'practice'];

const URLS = {
  base: TEST_SUBMISSIONS_NORMALS_URL,
  custom: TEST_SUBMISSIONS_CUSTOM_URL,
  invalid: TEST_SUBMISSIONS_INVALIDS_URL,
  practice: TEST_SUBMISSIONS_PRACTICE_URL,
};

export const getSubmissionsBaseURLByTestType = (testType) => {
  if (!testType || !VALID_TESTS.includes(testType))
    throw new Error('Invalid Test Type');

  return URLS[testType];
};
