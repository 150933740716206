import styled from 'styled-components';
import { Button, Card } from 'modules/Core/Common';

const Container = styled.div`
  margin: ${(props) => props.theme.config.spacing.spacing_4} 0 0;
`;

export const IntervalsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IntervalsCard = styled(Card)`
  padding: 0;
  margin: ${(props) => props.theme.config.spacing.spacing_4} 0;
`;

const IntervalContainer = styled.div`
  display: grid;
  grid-gap: 1.5rem;

  .evaluator-type {
    min-width: 200px;
    margin-right: ${(props) => props.theme.config.spacing.spacing_5};
  }

  .choose-group {
    min-width: 300px;
    margin-right: ${(props) => props.theme.config.spacing.spacing_5};
  }
`;

const IntervalBlock = styled.div`
  display: grid;
  text-align: start;
  grid-gap: 1.5rem;
  grid-template-columns: auto auto 60px;
  padding: 0.8rem 0;

  .MuiOutlinedInput-input {
    font-size: 1.4rem !important;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ActionButton = styled.a`
  cursor: pointer;

  svg {
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50px;
    padding: 1.2rem !important;
    overflow: visible;

    &:hover {
      background: ${(props) => props.theme.config.colors.primary_200};

      path {
        fill: ${(props) => props.theme.config.colors.primary};
      }
    }
  }

  svg path {
    fill: ${(props) => props.theme.config.colors.gray_400};
  }
`;

const StyledButton = styled(Button)`
  ${(props) => props.theme.config.typograph.default};
  display: flex;
  justify-content: flex-start;
  padding: 0 2rem 0 1rem;

  > button {
    padding-left: ${(props) => props.theme.config.spacing.spacing_2};
  }
`;

const EmphyData = styled.div`
  position: relative;
  width: 100%;
  height: 21.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  svg {
    width: 48px;
    height: 48px;
    margin-bottom: 1.9rem;
  }
  h2 {
    ${(props) => props.theme.config.typograph.default};
    color: ${(props) => props.theme.config.colors.text_base};
    font-weight: 400;
  }
  span {
    font-size: 1.2rem;
    font-weight: 400;
  }
`;

export {
  ActionButton,
  ActionsContainer,
  EmphyData,
  StyledButton,
  IntervalBlock,
  IntervalContainer,
  Container,
};
