import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { isFalsy } from 'modules/Utils';
import { useTheme } from 'styled-components';
import i18n from 'i18next';

import { Icon, MUIDatePicker, Button } from 'modules/Core/Common';

import * as S from './SearchBar.styles';

const SearchBar = ({
  hasFilters,
  defaultSearch,
  defaultStartDate,
  defaultEndDate,
  onSaveFilterButtonClick,
  onClearFilterButtonClick,
  customFiltersComponent,
  customFilters,
  ...props
}) => {
  const filtersRef = React.useRef();
  const searchBarRef = React.useRef();

  const [showFilters, setShowFilters] = useState(false);
  const [search, setSearch] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const theme = useTheme();

  useEffect(() => {
    setSearch(defaultSearch || '');
    setStartDate(defaultStartDate || '');
    setEndDate(defaultEndDate || '');
  }, [defaultSearch, defaultStartDate, defaultEndDate]);

  const handleSaveFilter = useCallback(() => {
    if (customFilters) {
      onSaveFilterButtonClick({
        ...customFilters,
        search,
      });
    } else {
      onSaveFilterButtonClick({
        search,
        startDate,
        endDate,
      });
    }

    setShowFilters(false);
  }, [search, startDate, endDate, customFilters]);

  const handleClearFilter = useCallback(() => {
    onClearFilterButtonClick();
    setShowFilters(false);
  }, []);

  const handleClickOutside = useCallback(
    (event) => {
      if (showFilters) {
        if (
          filtersRef.current &&
          !filtersRef.current.contains(event.target) &&
          searchBarRef &&
          !searchBarRef.current.contains(event.target) &&
          !event.target.closest('.MuiPaper-root')
        ) {
          setShowFilters(false);
        }
      }
    },
    [showFilters, filtersRef, searchBarRef]
  );

  const handleSearchKeyUp = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        handleSaveFilter();
      }
    },
    [handleSaveFilter]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <S.Container isFilterOpen={!!showFilters}>
      {showFilters && <div className="overlay" />}
      <S.SearchContainer
        ref={searchBarRef}
        hasFilters={hasFilters}
        showFilters={showFilters}
        isFilterActive={!!startDate || !!endDate || !isFalsy(customFilters)}
      >
        <Icon name="search" onClick={handleSaveFilter} />
        <S.SearchInput
          hasFilters={hasFilters}
          onChange={({ target }) => setSearch(target?.value)}
          onKeyUp={handleSearchKeyUp}
          value={search}
          {...props}
        />
        {hasFilters && (
          <Icon
            id="filters"
            name="custom"
            cursor="pointer"
            onClick={() => setShowFilters(!showFilters)}
          />
        )}
      </S.SearchContainer>
      {showFilters && (
        <S.Filters ref={filtersRef}>
          <p>{i18n.t('common-words.filter')}</p>
          {!customFiltersComponent ? (
            <>
              <MUIDatePicker
                borders
                inputClick
                placeholder={i18n.t('common-words.date')}
                textFieldProps={{
                  placeholder: i18n.t('common-words.date'),
                }}
                date={startDate}
                onChange={(newValue) => setStartDate(newValue)}
              />
              <MUIDatePicker
                borders
                inputClick
                placeholder={i18n.t('common-words.prep-to')}
                textFieldProps={{
                  placeholder: i18n.t('common-words.prep-to'),
                }}
                date={endDate}
                onChange={(newValue) => setEndDate(newValue)}
              />
            </>
          ) : (
            customFiltersComponent
          )}

          <S.Actions className="actions">
            <Button type="button" medium onClick={handleClearFilter}>
              <Icon left name="close" fontSize="1.2rem" />
              {i18n.t('commons.search-bar.clear')}
            </Button>
            <Button type="button" medium onClick={handleSaveFilter}>
              <Icon
                left
                name="filter"
                color={theme.config.colors.light}
                fontSize="1.2rem"
              />
              {i18n.t('commons.search-bar.save-filter')}
            </Button>
          </S.Actions>
        </S.Filters>
      )}
    </S.Container>
  );
};

SearchBar.propTypes = {
  hasFilters: PropTypes.bool,
  defaultSearch: PropTypes.string,
  defaultStartDate: PropTypes.string,
  defaultEndDate: PropTypes.string,
  customFiltersComponent: PropTypes.node,
  customFilters: (props, propName) => {
    if (
      props.customFiltersComponent &&
      (props[propName] === undefined || typeof props[propName] !== 'object')
    ) {
      return new Error(i18n.t('errors.error-custom-filter'));
    }
  },
  onSaveFilterButtonClick: (props, propName) => {
    if (
      props.hasFilters &&
      (props[propName] === undefined || typeof props[propName] !== 'function')
    ) {
      return new Error(i18n.t('errors.error-onsave-filter'));
    }
  },
  onClearFilterButtonClick: (props, propName) => {
    if (
      props.hasFilters &&
      (props[propName] === undefined || typeof props[propName] !== 'function')
    ) {
      return new Error(i18n.t('errors.error-onclear-filter'));
    }
  },
};

export default SearchBar;
