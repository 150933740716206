import { useDispatch, useSelector } from 'react-redux';
import camelCase from 'camelcase';

import { Selectors, Async } from 'modules/Dashboard/Widgets/WidgetsSlice';

export const useEProctoringEventsAnalytics = ({ widgetType }) => {
  const widgetsState = useSelector(Selectors.fetchListData);

  const dispatch = useDispatch();

  const fetchEProctoringEventsAnalytics = (intervalOption = '7') => {
    dispatch(
      Async.fetchEProctoringEventsAnalytics({
        widget_type: widgetType,
        filters: {
          days: +intervalOption,
        },
      })
    );
  };

  return {
    fetchEProctoringEventsAnalytics,
    eproctoringEventsAnalytics: widgetsState[camelCase(widgetType)],
  };
};
