const MAX_LABEL_WORDS = 6;

export const formatChartLabels = (data, testType, selectedTest) => {
  const dataField = testType === 'Plugin' ? 'quiz' : 'test';

  return (
    data?.tests
      ?.filter(
        (item) =>
          item.total &&
          item[dataField]?.includes(selectedTest === 'all' ? '' : selectedTest)
      )
      ?.map((item) => item[dataField])
      ?.sort((a, b) => b.total - a.total) || []
  );
};

export const formatChartValues = (data, viewMode, testType, selectedTest) => {
  if (viewMode === 'percent')
    return formatChartPercentages(data, testType, selectedTest);

  const fieldToCompare = testType === 'Plugin' ? 'quiz' : 'test';

  return (
    data?.tests
      ?.filter(
        (item) =>
          item.total &&
          item[fieldToCompare]?.includes(
            selectedTest === 'all' ? '' : selectedTest
          )
      )
      ?.map((item) => item.total)
      ?.sort((a, b) => b - a) || []
  );
};

export const formatChartPercentages = (data, testType, selectedTest) => {
  const fieldToCompare = testType === 'Plugin' ? 'quiz' : 'test';

  return (
    data?.tests
      ?.filter(
        (item) =>
          item.total &&
          item[fieldToCompare]?.includes(
            selectedTest === 'all' ? '' : selectedTest
          )
      )
      ?.map((item) => Number(item.percentage?.replace('%', '') || ''))
      ?.sort((a, b) => b - a) || []
  );
};

export const formatChartData = (
  formattedChartLabels,
  formattedChartData,
  backgroundColor,
  label
) => {
  return {
    labels: formattedChartLabels,
    datasets: [
      {
        label,
        data: formattedChartData,
        backgroundColor: backgroundColor,
        barThickness: 10,
        borderRadius: 10,
      },
    ],
  };
};

export const formatChartOptions = (viewMode) => {
  return {
    indexAxis: 'y',
    maintainAspectRatio: false,
    scales: {
      x: {
        min: 0,
        max: viewMode === 'percent' ? 100 : undefined,
        ticks: {
          callback(tickValue) {
            const currentLabel = this.getLabelForValue(Number(tickValue));

            return viewMode === 'percent' ? `${currentLabel}%` : currentLabel;
          },
        },
      },
      y: {
        ticks: {
          crossAlign: 'far',
          callback(tickValue) {
            const currentLabel = this.getLabelForValue(Number(tickValue));
            const splittedLabel = currentLabel?.split(' ');
            const isLongLabel = splittedLabel?.length > MAX_LABEL_WORDS;

            if (isLongLabel) {
              return splittedLabel.slice(0, MAX_LABEL_WORDS).join(' ') + '...';
            }

            return currentLabel;
          },
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
  };
};

export const getTestsOptions = (data) => {
  return (
    data?.tests
      ?.filter((item) => item.total)
      ?.map((item) => ({
        id: item.test || item.quiz,
        name: item.test || item.quiz,
      })) || []
  );
};

export const MOCKED_DATA = {
  tests: [
    {
      test: 'TOEFL',
      total: 199,
      percentage: '60%',
    },
    {
      test: 'IELTS Academic',
      total: 105,
      percentage: '50%',
    },
    {
      test: 'TOEIC® Listening & Reading',
      total: 84,
      percentage: '45%',
    },
    {
      test: 'CEFR Level Test',
      total: 62,
      percentage: '40%',
    },
    {
      test: 'Cambridge A2 Flyers Test',
      total: 49,
      percentage: '35%',
    },
    {
      test: 'CEFR Reading & Listening',
      total: 32,
      percentage: '30%',
    },
    {
      test: 'IELTS Full 2',
      total: 22,
      percentage: '25%',
    },
    {
      test: 'Linguaskill General',
      total: 18,
      percentage: '20%',
    },
    {
      test: 'Linguaskill Business',
      total: 11,
      percentage: '5%',
    },
    {
      test: 'Progress Check',
      total: 3,
      percentage: '2%',
    },
  ],
};
