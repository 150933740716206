import React from 'react';
import PropTypes from 'prop-types';
import * as S from './style';

const FormContainer = ({ children }) => (
  <S.FormContainer>{children}</S.FormContainer>
);

FormContainer.propTypes = {
  children: PropTypes.element.isRequired,
};

export default FormContainer;
