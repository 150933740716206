import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Col, Toast } from 'modules/Core/Common';
import * as S from './SettingsProfile.styles';
import { AvatarProfile } from './AvatarProfile';
import { SidebarGeneralProfile } from './SidebarGeneralProfile';
import { SidebarGeneralPassword } from './SidebarGeneralPassword';
import { SidebarGeneralIntervals } from './SidebarGeneralIntervals';
import { ListHeader } from 'modules/Core/Common';
import { ButtonToggleContext } from './AvatarProfile/ButtonToggleContext';
import { defaultErrorToast } from 'modules/Utils';
import { Selectors, Async } from 'modules/Auth/AuthSlice';
import { useURLSearchQuery } from 'modules/Core/Hooks';
import { SettingsIcon } from '@edusynch/edusynch-svg-icons';
import i18n from 'i18next';

const SettingsProfile = () => {
  const isMounted = useRef(false);
  const history = useHistory();
  const button = React.useContext(ButtonToggleContext);

  const dispatch = useDispatch();

  const { user } = useSelector(Selectors.selectAuthData);
  const { loading } = useSelector(Selectors.authLoading);
  const [userTypeEvaluator] = useState(user?.user_type === 'evaluator');

  const { queryObject, changeParams } = useURLSearchQuery();

  const handleChangeTab = (tab) => changeParams({ tab });

  useEffect(() => {
    button.setToggleButton(queryObject?.tab || 'general');
  }, [queryObject?.tab]);

  useEffect(() => {
    if (isMounted.current) history.go(0);
    isMounted.current = true;
  }, [user?.language]);

  const handleUpdateProfile = (data, onSuccess) =>
    dispatch(
      Async.updateProfile({
        data,
        onSuccess: () => {
          if (typeof onSuccess === 'function') onSuccess();
          Toast(
            i18n.t('success-messages.successfully-toast.updated-profile'),
            'success'
          );
        },
        onError: (e) =>
          defaultErrorToast(
            i18n.t('errors.error-sorry-an-error-occurred-during.update-profile')
          )(e),
      })
    );

  const handleRemoveAvatar = (onSuccess) => {
    let action = Async.removeAvatar({
      id: user?.id,
      onSuccess: () => {
        if (typeof onSuccess === 'function') onSuccess();
        Toast(
          i18n.t('success-messages.successfully-toast.removed-avatar'),
          'success'
        );
      },
      onError: (e) =>
        defaultErrorToast(
          i18n.t('errors.error-sorry-an-error-occurred-during.remove-avatar')
        )(e),
    });

    dispatch(action);
  };

  const handleUpdateAvatar = (avatar, onSuccess) => {
    if (!avatar) return;
    handleUpdateProfile({ avatar }, onSuccess);
  };

  const handleGetAvatarBase64 = () => {
    dispatch(
      Async.getAvatarBase64({
        onError: (e) =>
          defaultErrorToast(
            i18n.t('errors.error-sorry-an-error-occurred-during.get-avatar')
          )(e),
      })
    );
  };

  return (
    <Grid fluid>
      <S.RowMargin>
        <Col xs={12}>
          <ListHeader
            title={i18n.t(
              'modules.settings-profile.header.titles.profile-settings'
            )}
            IconName={SettingsIcon}
            actionsType="custom"
          />
        </Col>
        <Col xs={5} style={{ paddingRight: '1.2rem' }}>
          <AvatarProfile
            user={user}
            loading={loading}
            onSaveImage={handleUpdateAvatar}
            onRemoveImage={handleRemoveAvatar}
            customOnClick={(tab) => handleChangeTab(tab)}
            onToggleEdit={handleGetAvatarBase64}
          />
        </Col>

        <Col xs={7} style={{ paddingLeft: '1.2rem' }}>
          <S.RightCard padding="3.2rem">
            {button.toggleButton === 'general' && (
              <SidebarGeneralProfile
                user={user}
                onSave={handleUpdateProfile}
                loading={loading}
              />
            )}
            {button.toggleButton === 'password' && (
              <SidebarGeneralPassword
                user={user}
                onSave={handleUpdateProfile}
                loading={loading}
              />
            )}
            {button.toggleButton === 'intervals' && userTypeEvaluator && (
              <SidebarGeneralIntervals
                user={user}
                onSave={(data) => handleUpdateProfile(data)}
                loading={loading}
              />
            )}
          </S.RightCard>
        </Col>
      </S.RowMargin>
    </Grid>
  );
};

SettingsProfile.propTypes = {
  onSave: PropTypes.func,
  interval: PropTypes.object,
};

export default SettingsProfile;
