import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
  name: 'app',
  initialState: {
    sideBarOpened: false,
    sideBarClicked: false,
    responsiveSidebarOpened: true,
    internetStatus: 'online',
    reconnectIntervalId: null,
  },
  reducers: {
    toggleSidebar: (state) => {
      state.sideBarOpened = !state.sideBarOpened;
    },
    toggleResponsiveSidebarOpen: (state) => {
      state.responsiveSidebarOpened = true;
    },
    toggleResponsiveSidebarClose: (state) => {
      state.responsiveSidebarOpened = false;
    },
    toggleSidebarDeactivated: (state) => {
      state.sideBarOpened = false;
    },
    toggleOpenSidebar: (state) => {
      state.sideBarClicked = true;
    },
    toggleCloseSidebar: (state) => {
      state.sideBarClicked = false;
    },
    setInternetStatus: (state, action) => {
      state.internetStatus = action.payload;
    },
    setReconnectIntervalId: (state, action) => {
      state.reconnectIntervalId = action.payload;
    },
    clearReconnectIntervalId: (state) => {
      window.clearInterval(state.reconnectIntervalId);
      state.reconnectIntervalId = null;
    },
  },
});

const { reducer } = appSlice;

const Actions = appSlice.actions;

const Selectors = {
  whitelabel: (state) => state.app.whitelabel,
  sidebar: (state) => ({
    opened: state.app.sideBarOpened,
    clicked: state.app.sideBarClicked,
    openedResponsive: state.app.responsiveSidebarOpened,
  }),
  internetStatus: (state) => state.app.internetStatus,
};

export { reducer, Actions, Selectors };
