import React from 'react';
import { v4 as uuid } from 'uuid';

import * as S from './Gallery.styles';

const UNREAL_DATA = Array.from(Array(20).keys());

export const GalleryPlaceholder = () => (
  <S.PlaceholderContainer className="image-gallery">
    <S.PlaceholderSlider />
    <S.PlaceholderThumbnailContainer>
      {UNREAL_DATA.map(() => (
        <S.PlaceholderThumbnail key={uuid()} />
      ))}
    </S.PlaceholderThumbnailContainer>
  </S.PlaceholderContainer>
);
