export const formDataFromObj = (obj) => {
  const formData = new FormData();
  const entries = Object.entries(obj);

  for (const [key, value] of entries) {
    if (value === null || value === undefined) {
      continue;
    } else if (
      value instanceof Array ||
      (value instanceof Object && !(value instanceof File))
    ) {
      formData.set(key, JSON.stringify(value));
    } else {
      formData.set(key, value);
    }
  }

  return formData;
};
