import styled from 'styled-components';

const getColorByType = (props) => {
  switch (props.variant) {
    case 'primary':
      return props.theme.config.colors.primary;

    case 'secondary':
      return props.theme.config.colors.secondary;

    case 'tertiary':
      return props.theme.config.colors.tertiary;

    case 'orange':
      return props.theme.config.colors.orange;

    case 'green':
      return props.theme.config.colors.green;

    case 'text':
      return props.theme.config.colors.text_base;

    default:
      return props.theme.config.colors.gray;
  }
};

export const Container = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: ${(props) =>
    props.secondary
      ? props.theme.config.colors.text_base
      : getColorByType(props)};

  &::before {
    content: '';
    display: inline-block;
    border-radius: 50%;
    width: 0.8rem;
    height: 0.8rem;
    margin-right: 0.8rem;
    background: ${(props) => getColorByType(props)};
  }
`;
