import React from 'react';
import PropTypes from 'prop-types';
import * as S from './Title.styles';

const Title = ({ children, ...props }) => {
  if (typeof children === 'string') {
    return (
      <S.Title data-test="component-title-title" {...props}>
        {children}
      </S.Title>
    );
  }

  return children;
};

Title.propTypes = {
  children: PropTypes.node,
};

export default Title;
