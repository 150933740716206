import styled, { css } from 'styled-components';
import { device } from 'modules/Utils/Devices';
import { ZIndexes } from 'modules/Utils/ZIndexes';

const Header = styled.header`
  position: sticky;
  margin: 0;
  display: flex;
  width: 100%;
  min-width: 320px;
  height: 8rem;
  top: 0;
  right: 0;
  left: 0;
  z-index: ${ZIndexes.header + 3};
  background-color: ${(props) => props.theme.config.colors.light};
  box-shadow: 0px 4px 4px
    ${(props) => (!props.noShadow ? 'rgba(0, 0, 0, 0.08)' : 'rgba(0, 0, 0, 0)')};

  @media ${device.mobile} {
    height: 6rem;
    z-index: ${ZIndexes.header + 5};
  }
`;

const MenuContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${(props) => props.theme.config.spacing.layout_6};
  width: 7.2rem;
  border-right: 0.1rem;
  border-right-color: ${(props) => props.theme.config.colors.gray_300};
  border-right-style: solid;
  opacity: 0.25;
  transition: 0.2s ease-in;

  ${({ opened }) =>
    opened &&
    css`
      margin-left: 34rem;
      transition: 0.2s ease-out;

      @media ${device.tablet} {
        margin-left: ${(props) => props.theme.config.spacing.layout_2};
      }
    `}

  @media ${device.mobile} {
    margin-left: 0;
    width: ${(props) => props.theme.config.spacing.spacing_7};

    i.edu-icon-menu {
      ${(props) => props.theme.config.typograph.default};
      position: relative;
      left: -0.3rem;
    }
  }
`;

const WhitelabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${(props) => props.theme.config.spacing.layout_3};

  @media ${device.tablet} {
    margin-left: ${(props) => props.theme.config.spacing.layout_2};
  }

  @media ${device.mobile} {
    margin-left: ${(props) => props.theme.config.spacing.layout_1};
  }

  span {
    color: ${(props) => props.theme.config.colors.text_base};
  }

  strong {
    color: ${(props) => props.theme.config.colors.primary};
    font-weight: 700;
  }
`;

const RightContainer = styled.div`
  margin: 0 1.6rem 0 auto;
  display: flex;
  align-items: center;

  @media ${device.mobile} {
    > div:first-child {
      display: block;
    }
  }
`;

export { Header, MenuContainer, WhitelabelContainer, RightContainer };
