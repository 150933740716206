import React from 'react';
import * as S from './Loading.styles';
import PropTypes from 'prop-types';
import i18n from 'i18next';

const Loading = ({
  background,
  backgroundFull,
  size,
  ousideContainer,
  position,
  fullHeight,
}) => (
  <S.Container
    ousideContainer={ousideContainer}
    position={position}
    fullHeight={fullHeight}
  >
    <S.Loader
      background={background}
      backgroundFull={backgroundFull}
      size={size}
    >
      <S.Circular size={size}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          shapeRendering="geometricPrecision"
          textRendering="geometricPrecision"
          viewBox="0 0 80 80"
        >
          <defs>
            <linearGradient
              id="circular-granident"
              x1="40"
              x2="40"
              y1="4"
              y2="80.5"
              gradientUnits="userSpaceOnUse"
              spreadMethod="pad"
            >
              <stop offset="0%" stopColor="#0075EA"></stop>
              <stop offset="99.99%" stopColor="#EC3237"></stop>
              <stop offset="100%" stopColor="rgba(0,0,0,0)"></stop>
            </linearGradient>
          </defs>
          <g opacity="0.8">
            <path
              fill="none"
              stroke="#E1E0E7"
              strokeLinecap="round"
              strokeWidth="8"
              d="M76 40a36.001 36.001 0 11-72.002 0A36.001 36.001 0 0176 40h0z"
            ></path>
            <g transform="translate(40 40)">
              <path
                fill="none"
                stroke="url(#circular-granident)"
                strokeLinecap="round"
                strokeWidth="8"
                d="M76 40A36 36 0 1140 4"
                transform="translate(-40 -40)"
              ></path>
            </g>
          </g>
        </svg>
      </S.Circular>
      <S.Span size={size}>{i18n.t('common-words.loading')}</S.Span>
    </S.Loader>
  </S.Container>
);

Loading.propTypes = {
  background: PropTypes.bool,
  backgroundFull: PropTypes.bool,
  size: PropTypes.string,
  ousideContainer: PropTypes.bool,
  position: PropTypes.string,
  fullHeight: PropTypes.bool,
};

export default Loading;
