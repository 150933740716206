import { lazy } from 'react';
import { menuBuilder } from 'services/Routes.service';
import { WorldIcon } from '@edusynch/edusynch-svg-icons';

const CountryPermissionsContainer = lazy(() =>
  import('modules/CountryPermissions/CountryPermissions.container')
);

const CountryPermissionsMenu = menuBuilder([
  {
    Icon: WorldIcon,
    name: 'Country Permissions',
    component: CountryPermissionsContainer,
    ability: 'country_permissions',
  },
]).addSubmenu();

export default CountryPermissionsMenu;
