import { getRandomInteger } from './';

const motivationalQuotes = [
  {
    id: 1,
    quote: '“An investment in knowledge pays the best interest.”',
    author: 'Benjamin Franklin',
  },
  {
    id: 2,
    quote:
      '“Education is the passport to the future, for tomorrow belongs to those who prepare for it today.',
    author: 'Malcolm X',
  },
  {
    id: 3,
    quote:
      '“Education is not the filling of a pail, but the lighting of a fire.”',
    author: 'W.B. Yeats',
  },
  {
    id: 4,
    quote: '“Education is not preparation for life; education is life itself.”',
    author: 'John Dewey',
  },
  {
    id: 5,
    quote: '“Perfect is the enemy of good.”',
    author: 'Voltaire',
  },
  {
    id: 6,
    quote: '“I’m still learning.”',
    author: 'Michelangelo',
  },
];

export const getRandomMotivationalQuote = () => {
  const randomInteger = getRandomInteger(0, motivationalQuotes.length);
  return motivationalQuotes[randomInteger];
};
