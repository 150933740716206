import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const ButtonToggleContext = React.createContext();

export const AvatarToggleButton = ({ children }) => {
  const [toggleButton, setToggleButton] = useState(
    'general',
    'password',
    'intervals'
  );
  return (
    <ButtonToggleContext.Provider value={{ toggleButton, setToggleButton }}>
      {children}
    </ButtonToggleContext.Provider>
  );
};

AvatarToggleButton.propTypes = {
  children: PropTypes.any,
};
