import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  PROCTORING_SETTINGS_LIST_URL,
  EXTERNAL_SOFTWARE_URL,
  GROUPS_URL,
  VERSIONS_URL,
  INTEGRATIONS_CREATE_URL,
  INTEGRATIONS_UPDATE_URL,
  INTEGRATION_BY_ID_URL,
  GROUPS_CREATE_URL,
  EXTERNAL_SOFTWARE_CREATE_URL,
  VERSIONS_CREATE_URL,
  WHITELABELS_URL,
  PROCTORING_SETTINGS_CREATE_URL,
} from 'modules/Api/Routes';
import { defaultRequest } from 'modules/Utils/DefaultRequest';
import { createProctoringSettingsRequestModel } from 'modules/ProctoringSettings/ProctoringSettingsUtils';
import { formDataFromObj } from 'modules/Api/RequestData';
import { defaultErrorToast } from 'modules/Utils';
import i18n from 'i18next';

let cancelToken;

const initialState = {
  loading: false,
  error: null,
  data: {
    page: 1,
    perPage: 10,
    search: '',
    softwareId: '',
    groupId: '',
    whitelabelId: '',
    sort: {
      sortType: null,
      sortBy: null,
    },
    proctoringSettings: [],
  },
  origin: {
    selected: {
      id: null,
      type: null,
    },
  },
  editProctoringSettings: {
    loading: false,
    error: null,
    data: null,
  },
  groups: {
    loading: false,
    error: false,
    data: [],
  },
  whitelabels: {
    loading: false,
    error: false,
    data: [],
  },
  softwares: {
    loading: false,
    error: false,
    data: [],
  },
  versions: {
    loading: false,
    error: false,
    data: [],
  },
};

const proctoringSettingsOriginSlice = createSlice({
  name: 'proctoringSettingsOrigin',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    changeOrigin: (state, action) => {
      state.origin.selected.id = action.payload.id;
      state.origin.selected.type = action.payload.type;
    },
    changePage: (state, action) => {
      state.data.page = action.payload;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    requestProctoringSettings: (state) => {
      state.loading = true;
    },
    receiveProctoringSettingsList: (state, action) => {
      const proctoringSettings = action?.payload?.proctoring_settings || [];

      const formattedProctoringSettings =
        proctoringSettings.map((item) => ({
          ...item,
          type: item.whitelabel ? 'whitelabel' : 'external_application',
        })) || [];

      state.loading = false;
      state.data = {
        ...state.data,
        total: action.payload.total_items,
        proctoringSettings: formattedProctoringSettings,
      };
    },
    requestExternalSoftwares: (state) => {
      state.softwares.loading = true;
    },
    requestVersions: (state) => {
      state.versions.loading = true;
    },
    requestGroups: (state) => {
      state.groups.loading = true;
    },
    requestWhitelabels: (state) => {
      state.whitelabels.loading = true;
    },
    requestEditProctoringSettings: (state) => {
      state.editProctoringSettings.loading = true;
    },
    receiveExternalSoftwaresList: (state, action) => {
      state.softwares.loading = false;
      state.softwares.data = action.payload.content.external_softwares;
    },
    receiveVersionsList: (state, action) => {
      state.versions.loading = false;
      state.versions.data = action.payload.content.versions;
    },
    receiveGroupsList: (state, action) => {
      state.groups.loading = false;
      state.groups.data = action.payload.content.groups;
    },
    receiveWhitelabelsList: (state, action) => {
      state.whitelabels.loading = false;
      state.whitelabels.data = action.payload.content.whitelabels;
    },
    receiveEditProctoringSettings: (state, action) => {
      state.editProctoringSettings.loading = false;
      state.editProctoringSettings.data = action.payload;
    },
    receiveProctoringSettingsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    receiveExternalSoftwaresError: (state, action) => {
      state.softwares.loading = false;
      state.softwares.error = action.payload;
    },
    receiveVersionsError: (state, action) => {
      state.versions.loading = false;
      state.versions.error = action.payload;
    },
    receiveGroupsError: (state, action) => {
      state.groups.loading = false;
      state.groups.error = action.payload;
    },
    receiveWhitelabelsError: (state, action) => {
      state.whitelabels.loading = false;
      state.whitelabels.error = action.payload;
    },
    receiveEditProctoringSettingsError: (state, action) => {
      state.editProctoringSettings.loading = false;
      state.editProctoringSettings.error = action.payload;
    },
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    changeProctoringSettingsFilters: (state, action) => {
      state.data.search = action.payload.search || '';
      state.data.softwareId = action.payload.external_software_id || '';
      state.data.groupId = action.payload.group_id || '';
      state.data.whitelabelId = action.payload.whitelabel_id || '';
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
    },
    clearEditProctoringSettings: (state) => {
      state.editProctoringSettings.loading = false;
      state.editProctoringSettings.error = null;
      state.editProctoringSettings.data = null;
    },
    clearVersionsList: (state) => {
      state.versions.loading = false;
      state.versions.error = null;
      state.versions.data = [];
    },
  },
});

const Actions = proctoringSettingsOriginSlice.actions;

const Selectors = {
  fetchListData: (state) => state.proctoringSettingsOrigin,
  proctoringSettingsLoading: ({ proctoringSettingsOrigin: { loading } }) => ({
    loading,
  }),
};

const Async = {
  fetchProctoringSettingsList: () => async (dispatch, getState) => {
    const {
      proctoringSettingsOrigin: {
        data: {
          page,
          perPage,
          search,
          softwareId,
          groupId,
          whitelabelId,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestProctoringSettings());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: PROCTORING_SETTINGS_LIST_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          external_software_id: softwareId,
          group_id: groupId,
          whitelabel_id: whitelabelId,
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receiveProctoringSettingsList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveProctoringSettingsError(e.message);
      }
    }

    action && dispatch(action);
  },

  fetchExternalSoftwaresList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      EXTERNAL_SOFTWARE_URL,
      'GET',
      Actions.requestExternalSoftwares,
      Actions.receiveExternalSoftwaresList,
      Actions.receiveExternalSoftwaresError
    ),

  fetchVersionsByExternalSoftwareId:
    (externalSoftwareId) => async (dispatch) => {
      if (!externalSoftwareId) return;

      return defaultRequest(
        dispatch,
        VERSIONS_URL,
        'GET',
        Actions.requestVersions,
        Actions.receiveVersionsList,
        Actions.receiveVersionsError,
        { external_software_id: externalSoftwareId }
      );
    },

  fetchGroupsList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      GROUPS_URL,
      'GET',
      Actions.requestGroups,
      Actions.receiveGroupsList,
      Actions.receiveGroupsError
    ),
  fetchWhitelabelsList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      WHITELABELS_URL,
      'GET',
      Actions.requestWhitelabels,
      Actions.receiveWhitelabelsList,
      Actions.receiveWhitelabelsError
    ),

  getProctoringSettingsById:
    ({ id }) =>
    async (dispatch) => {
      dispatch(Actions.requestEditProctoringSettings());

      try {
        const response = await request({
          method: 'GET',
          url: INTEGRATION_BY_ID_URL,
          params: {
            id,
          },
        });

        const proctoringSettings = response.data.content;

        dispatch(
          Async.fetchVersionsByExternalSoftwareId(
            proctoringSettings?.external_software?.id
          )
        );
        dispatch(Actions.receiveEditProctoringSettings(proctoringSettings));
      } catch (e) {
        defaultErrorToast(
          i18n.t('errors.error-sorry-an-error-occurred-during.get-integration')
        )(e);
        dispatch(Actions.receiveEditProctoringSettingsError(e));
      }
    },
  saveProctoringSettings:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestProctoringSettings());

      const parseData = createProctoringSettingsRequestModel(data);

      if (!data.id) delete parseData.id;

      if (data?.groupRadio === 'new') {
        try {
          const createGroupResponse = await request({
            method: 'POST',
            url: GROUPS_CREATE_URL,
            data: {
              name: data?.group?.name,
              hide_custom_tests: false,
              hide_analysis: false,
            },
          });

          parseData.group_id = createGroupResponse?.data?.content?.id;
        } catch (e) {
          defaultErrorToast(
            i18n.t('errors.error-sorry-an-error-occurred-during.create-group')
          )(e);
          dispatch(Actions.setLoading(false));
          return;
        }
      }

      if (data?.externalSoftwareRadio === 'new') {
        try {
          const createExternalSoftwareResponse = await request({
            method: 'POST',
            url: EXTERNAL_SOFTWARE_CREATE_URL,
            data: {
              title: data?.externalSoftware?.name,
              description: data?.externalSoftware?.name,
              url: data?.externalSoftware?.url,
            },
          });

          parseData.external_software_id =
            createExternalSoftwareResponse?.data?.content?.id;
        } catch (e) {
          defaultErrorToast(
            i18n.t(
              'errors.error-sorry-an-error-occurred-during.create-external-software'
            )
          )(e);
          dispatch(Actions.setLoading(false));
          return;
        }
      }

      if (data?.versionRadio === 'new') {
        try {
          const createVersionResponse = await request({
            method: 'POST',
            url: VERSIONS_CREATE_URL,
            data: {
              version: data?.version?.version,
              version_type: data?.version?.type,
              external_software_id: parseData.external_software_id,
            },
          });

          parseData.version_id = createVersionResponse?.data?.content?.id;
        } catch (e) {
          defaultErrorToast(
            i18n.t('errors.error-sorry-an-error-occurred-during.create-version')
          )(e);
          dispatch(Actions.setLoading(false));
          return;
        }
      }

      try {
        const integrationData = formDataFromObj(parseData);
        const response = await request({
          method: data?.id ? 'PUT' : 'POST',
          url: data?.id ? INTEGRATIONS_UPDATE_URL : INTEGRATIONS_CREATE_URL,
          data: integrationData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveProctoringSettingsError());
        onError(e);
      }
    },

  saveProctoringSettingsWhitelabel:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestProctoringSettings());

      try {
        const whitelabelData = formDataFromObj(data);

        const response = await request({
          method: 'POST',
          url: PROCTORING_SETTINGS_CREATE_URL,
          data: whitelabelData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveProctoringSettingsError());
        onError(e);
      }
    },
};

const reducer = proctoringSettingsOriginSlice.reducer;

export { reducer, Actions, Async, Selectors };
