import styled from 'styled-components';

const Divisor = styled.div`
  /* TODO: Get from config? */
  display: inline-block;
  background-color: ${(props) => props.theme.config.colors.gray_300};
  opacity: 0.2;
  width: 0.1rem;
  height: 4rem;
`;

export { Divisor };
