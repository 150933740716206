import {
  getUTCLast7Days,
  getUTCLast30Days,
  getUTCLastYear,
} from 'modules/Utils/Date';
import i18n from 'i18next';
import { CalendarIcon } from '@edusynch/edusynch-svg-icons';

export const DATE_FILTER = {
  LAST_7_DAYS: 'last_7_days',
  LAST_30_DAYS: 'last_30_days',
  LAST_YEAR: 'last_year',
  CUSTOM: 'custom',
};

const getType = (title) => i18n.t(`commons.filter.${title.replace(/_/g, '-')}`);

const datesOptions = (title) => ({
  Icon: CalendarIcon,
  type: getType(title),
  items: [
    {
      id: 1,
      name: i18n.t('commons.filter.last-7-days'),
      filterName: 'last_7_days',
      type: title,
    },
    {
      id: 2,
      name: i18n.t('commons.filter.last-30-days'),
      filterName: 'last_30_days',
      type: title,
    },
    {
      id: 3,
      name: i18n.t('commons.filter.last-year'),
      filterName: 'last_year',
      type: title,
    },
    {
      id: 4,
      name: i18n.t('commons.filter.custom'),
      filterName: 'custom',
      type: title,
    },
  ],
});

export const formatOptions = (...options) => {
  if (!options || !options?.length) return [];

  return options
    .filter((option) => !!option.items?.length)
    .map((option, index) => ({
      id: index + 1,
      Icon: option.Icon,
      type: option.type,
      items: option.items,
    }));
};

export const formatToggleOptions = (...options) => {
  if (!options || !options?.length) return [];

  return options.map((option, index) => ({
    id: index + 1,
    name: option.name,
    filterName: option.filterName,
    isActive: option.isActive,
    onChange: option.onChange,
  }));
};

export const formatOptionsWithDates = (
  options = [],
  hasDate,
  hasEvaluateDate,
  hasScheduleDate,
  hasSubmissionDate
) => {
  let optionsToMap = [...options];
  hasDate && (optionsToMap = [...optionsToMap, datesOptions('date_interval')]);
  hasEvaluateDate &&
    (optionsToMap = [...optionsToMap, datesOptions('evaluated_date')]);
  hasScheduleDate &&
    (optionsToMap = [...optionsToMap, datesOptions('scheduled_date')]);
  hasSubmissionDate &&
    (optionsToMap = [...optionsToMap, datesOptions('submissions_date')]);

  return optionsToMap.map((option, index) => ({
    id: index + 1,
    Icon: option.Icon,
    type: option.type,
    items: option.items,
  }));
};

export const formatCurrentFilters = (options, currentFilters) => {
  if (
    !options ||
    !options?.length ||
    !currentFilters ||
    !currentFilters?.length
  ) {
    return [];
  }

  const validFilters = currentFilters.filter((filter) => filter.value);

  if (!validFilters?.length) return [];

  return validFilters.map((filter, index) => {
    const optionItems = options.find((option) => option.type === filter.type);

    const currentFilter = optionItems?.items?.find(
      (item) => item.id == filter.value
    );

    return {
      id: index + 1,
      label: currentFilter?.name || currentFilter?.label,
      type: filter.type,
      value: filter.value,
      filterName: currentFilter?.filterName,
      required: currentFilter?.required,
    };
  });
};

export const getDatesObjectByFilterName = (filterName) => {
  switch (filterName) {
    case DATE_FILTER.LAST_7_DAYS:
      return getUTCLast7Days();
    case DATE_FILTER.LAST_30_DAYS:
      return getUTCLast30Days();
    case DATE_FILTER.LAST_YEAR:
      return getUTCLastYear();
    case DATE_FILTER.CUSTOM:
      return 'custom';
    default:
      return null;
  }
};
