import styled from 'styled-components';

const Label = styled.label`
  ${(props) => props.theme.config.typograph.xsmall};
  padding: 0 0 0.8rem;
  color: ${(props) => props.theme.config.colors.text_base};
  display: inline-block;
`;

export { Label };
