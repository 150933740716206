import React from 'react';
import PropTypes from 'prop-types';
import { Stepper, Step, StepLabel } from '@mui/material';

import { Icon } from 'modules/Core/Common';

import * as S from './MUIStepper.styles';

export const MUIStepper = ({ activeStep, steps }) => {
  return (
    <S.Container>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map(({ label, icon }) => (
          <Step key={label}>
            <StepLabel StepIconComponent={() => <Icon name={icon} />}>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </S.Container>
  );
};

MUIStepper.propTypes = {
  activeStep: PropTypes.number,
  steps: PropTypes.array,
};
