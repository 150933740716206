import styled from 'styled-components';
import { device } from 'modules/Utils';

export const Container = styled.section`
  text-align: center;
`;

export const UserAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${(props) => props.theme.config.spacing.spacing_3};
`;

export const User = styled.span`
  ${(props) => props.theme.config.typograph.quartenary};
  font-weight: 400;
  color: ${(props) => props.theme.config.colors.text_base};

  @media ${device.mobile} {
    ${(props) => props.theme.config.typograph.default};
  }
`;

export const MotivationContainer = styled.div`
  color: ${(props) => props.theme.config.colors.primary};

  p {
    ${(props) => props.theme.config.typograph.quartenary};
    margin: 2.4rem 0 0.8rem;

    @media ${device.mobile} {
      ${(props) => props.theme.config.typograph.default};
      font-weight: bold;
      margin-bottom: 1.6rem;
    }
  }
  span {
    ${(props) => props.theme.config.typograph.default};
  }
`;
