import styled from 'styled-components';

export const Container = styled.div`
  &&& {
    .ql-toolbar {
      padding: 1.6rem 2.4rem;
      border: none;
      border-top-left-radius: 0.8rem;
      border-top-right-radius: 0.8rem;
      background-color: ${(props) => props.theme.config.colors.primary_100};

      .ql-formats {
        .ql-stroke {
          stroke: ${(props) => props.theme.config.colors.text_base};
        }

        &:first-child {
          padding-right: 2.4rem;
          border-right: 1px solid
            ${(props) => props.theme.config.colors.primary_200};
        }
      }
    }

    .ql-container {
      border: none;
      border-bottom-left-radius: 0.8rem;
      border-bottom-right-radius: 0.8rem;
    }

    .ql-editor {
      height: 25rem;
      padding: 1.6rem 2.4rem;
      background-color: ${(props) => props.theme.config.colors.light};
      border-bottom-left-radius: 0.8rem;
      border-bottom-right-radius: 0.8rem;
      color: ${(props) => props.theme.config.colors.text_base};
      ${(props) => props.theme.config.typograph.xsmall};
    }

    .ql-header {
      .ql-picker-label {
        padding: 0;
      }
    }

    .ql-picker {
      ${(props) => props.theme.config.typograph.default};
      color: ${(props) => props.theme.config.colors.text_base};
    }
    .ql-editor.ql-blank::before {
      font-style: normal;
      color: ${(props) => props.theme.config.colors.gray_400};
    }
  }
`;
