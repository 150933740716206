import { createSlice } from '@reduxjs/toolkit';
import { request } from 'modules/Api/HttpClient';
import {
  AUTH_LOGIN_URL,
  AUTH_FORGOT_PASSWORD_URL,
  AUTH_REQUEST_FORGOT_PASSWORD_URL,
  PERMISSIONS_CHECK_URL,
  SETTINGS_PROFILE_UPDATE_URL,
  SETTINGS_REMOVE_AVATAR_URL,
  SETTINGS_AVATAR_BASE64_URL,
} from 'modules/Api/Routes';
import { generatePayload } from 'modules/Api/Payload';
import { formDataFromObj } from 'modules/Api/RequestData';
import {
  persistSession,
  getSession,
  cleanSession,
} from 'modules/Auth/Services';
import {
  isSuperAdmin,
  isGroupAdmin,
  isUnitAdmin,
  isSchoolAdmin,
  isAdmin,
  isEvaluator,
} from 'modules/Users/Permissions/UserType';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    token: null,
    /** handle initial load */
    sessionLoaded: false,
    loading: false,
    forgotStatus: null,
    tokenExpired: false,
    serverError: '',
  },
  reducers: {
    setAuthData: (state, action) => {
      state.sessionLoaded = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.lastPageAccessed = null;
      state.permissions = null;
    },
    updateUserData: (state, action) => {
      state.user = action.payload.user;
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload.content;
      state.loading = false;
    },
    setPermissionsError: (state) => {
      state.loading = false;
    },
    setServerError: (state, action) => {
      state.loading = false;
      state.serverError = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setTokenExpired: (state, action) => {
      state.tokenExpired = action.payload;
    },
    clearTokenExpired: (state) => {
      state.tokenExpired = false;
    },
    setTokenInvalid: (state) => {
      state.user = null;
      state.token = null;
      cleanSession();
    },
    clearServerError: (state) => {
      state.serverError = '';
    },
    setForgotStatus: (state, action) => {
      state.forgotStatus = action.payload;
    },
  },
});

const Actions = authSlice.actions;

const Selectors = {
  selectAuthData: (state) => ({
    user: state.auth.user,
    token: state.auth.token,
  }),

  getPermissions: (state) => state?.auth?.permissions,

  getServerError: ({ auth: { serverError } }) => serverError,

  getExpiredToken: (state) => state?.auth?.tokenExpired,

  sessionLoaded: (state) => state?.auth?.sessionLoaded,

  selectLoading: (state) => state?.auth?.loading,

  isTokenExpired: (state) => state?.auth?.tokenExpired,

  forgotHandling: ({ auth: { loading, forgotStatus } }) => ({
    loading,
    forgotStatus,
  }),

  authLoading: ({ auth: { loading } }) => ({ loading }),

  isSuperAdmin: ({
    auth: {
      user: { user_type },
    },
  }) => isSuperAdmin(user_type),

  isGroupAdmin: ({
    auth: {
      user: { user_type },
    },
  }) => isGroupAdmin(user_type),

  isUnitAdmin: ({
    auth: {
      user: { user_type },
    },
  }) => isUnitAdmin(user_type),

  isSchoolAdmin: ({
    auth: {
      user: { user_type },
    },
  }) => isSchoolAdmin(user_type),

  isAdmin: ({
    auth: {
      user: { user_type },
    },
  }) => isAdmin(user_type),

  isOxfordAdmin: ({
    auth: {
      user: { oxford_unit_admin },
    },
  }) => oxford_unit_admin,

  isEvaluator: ({
    auth: {
      user: { user_type },
    },
  }) => isEvaluator(user_type),

  timezone: ({
    auth: {
      user: { timezone },
    },
  }) => timezone,

  timeFormat: ({
    auth: {
      user: { time_format },
    },
  }) => time_format,

  dateFormat: ({
    auth: {
      user: { date_format },
    },
  }) => date_format,

  dashboardWidgets: ({
    auth: {
      user: { dashboard },
    },
  }) => dashboard,
  canEvaluate: ({
    auth: {
      user: { can_evaluate, user_type },
    },
  }) => isSuperAdmin(user_type) || !!can_evaluate,

  canChangeEvaluators: ({
    auth: {
      user: { can_cancel_interview, user_type },
    },
  }) => isSuperAdmin(user_type) || !!can_cancel_interview,
};

const Async = {
  /**
   * @param {Object} data
   * @param {String} data.email
   * @param {String} data.password
   * @param {Function} onError
   */
  signIn: (data, onError, onSuccess) => async (dispatch) => {
    dispatch(Actions.setLoading(true));

    try {
      const response = await request({
        method: 'POST',
        url: AUTH_LOGIN_URL,
        data: { payload: generatePayload(data) },
      });

      const { user } = response.data.content;
      const { token } = user;

      delete user.token;

      dispatch(Actions.setAuthData({ user, token }));
      dispatch(Actions.clearTokenExpired());
      dispatch(Async.checkPermissions());
      persistSession(user, token);
      onSuccess && onSuccess();
    } catch (e) {
      dispatch(Actions.setLoading(false));
      onError(e);
    }
  },

  /**
   * @param {Object} data
   * @param {String} data.email
   * @param {Function} onError
   */
  forgotPassword: (data, onError) => async (dispatch) => {
    dispatch(Actions.setLoading(true));

    try {
      await request({
        method: 'POST',
        url: AUTH_REQUEST_FORGOT_PASSWORD_URL,
        data,
      });

      dispatch(Actions.setForgotStatus('success'));
    } catch (e) {
      onError(e);
    } finally {
      dispatch(Actions.setLoading(false));
    }
  },

  /**
   * @param {Object} data
   * @param {String} data.token
   * @param {String} data.password
   * @param {Function} onSuccess
   * @param {Function} onError
   */
  newPassword:
    ({ token, password }, onSuccess, onError) =>
    async (dispatch) => {
      dispatch(Actions.setLoading(true));

      try {
        await request({
          method: 'POST',
          url: AUTH_FORGOT_PASSWORD_URL,
          data: { forgot_password_token: token, password },
        });
        onSuccess();
      } catch (e) {
        onError(e);
      } finally {
        dispatch(Actions.setLoading(false));
      }
    },

  checkPermissions: () => async (dispatch) => {
    try {
      const response = await request({
        method: 'GET',
        url: PERMISSIONS_CHECK_URL,
      });
      dispatch(Actions.setPermissions(response.data));
      dispatch(Actions.clearServerError());
    } catch (e) {
      dispatch(Actions.setPermissionsError(e.message));
    }
  },

  logout: () => (dispatch) => {
    cleanSession();
    dispatch(Actions.setAuthData({ user: null, token: null }));
  },

  hydrateSession: () => async (dispatch) => {
    const session = getSession();

    dispatch(
      Actions.setAuthData(
        session || {
          user: null,
          token: null,
        }
      )
    );
  },

  updateProfile:
    ({ data, onSuccess, onError }) =>
    async (dispatch, getState) => {
      dispatch(Actions.setLoading(true));

      const {
        auth: { token },
      } = getState();

      let formattedIntervals = data?.intervals?.map((item) => {
        const interval = {
          start_date: item.start_date,
          end_date: item.end_date,
          start_time: item.start_time,
          end_time: item.end_time,
        };

        item?.id && (interval.id = item?.id);

        return interval;
      });

      if (data?.removedIntervals)
        formattedIntervals = [...formattedIntervals, ...data.removedIntervals];

      const requestData = formDataFromObj({
        ...data,
        intervals: formattedIntervals
          ? JSON.stringify(formattedIntervals)
          : null,
      });

      try {
        const response = await request({
          method: 'PUT',
          url: SETTINGS_PROFILE_UPDATE_URL,
          data: requestData,
        });
        const updatedUser = response?.data?.content;
        dispatch(Actions.updateUserData({ user: updatedUser }));
        persistSession(updatedUser, token);
        onSuccess(response);
      } catch (e) {
        onError(e);
      } finally {
        dispatch(Actions.setLoading(false));
      }
    },

  updateEvaluatorProfile:
    ({ data, onSuccess, onError }) =>
    async (dispatch, getState) => {
      dispatch(Actions.setLoading(true));

      const {
        auth: { token },
      } = getState();

      try {
        if (!(data.avatar instanceof File)) delete data.avatar;

        const formattedIntervals = data?.intervals
          ?.filter((interval) => interval?.start_date)
          ?.map((interval) => {
            const base = {
              end_date: interval?.end_date,
              end_time: interval?.end_time,
              start_date: interval?.start_date,
              start_time: interval?.start_time,
            };
            interval?.id && (base.id = interval?.id);
            return base;
          });

        const evaluatorData = formDataFromObj({
          ...data,
          intervals: JSON.stringify([
            ...formattedIntervals,
            ...data.removedIntervals,
          ]),
        });

        const response = await request({
          method: 'PUT',
          url: SETTINGS_PROFILE_UPDATE_URL,
          data: evaluatorData,
        });
        const updatedUser = response?.data?.content;
        dispatch(Actions.setAuthData({ user: updatedUser, token }));
        persistSession(updatedUser, token);
        onSuccess(response);
      } catch (e) {
        onError(e);
      } finally {
        dispatch(Actions.setLoading(false));
      }
    },

  removeAvatar:
    ({ id, onSuccess, onError }) =>
    async (dispatch, getState) => {
      dispatch(Actions.setLoading(true));

      const {
        auth: { token },
      } = getState();

      try {
        const data = formDataFromObj({ id });

        const response = await request({
          method: 'PUT',
          url: SETTINGS_REMOVE_AVATAR_URL,
          data,
        });
        const updatedUser = response?.data?.content;
        dispatch(Actions.updateUserData({ user: updatedUser }));
        persistSession(updatedUser, token);
        onSuccess(response);
      } catch (e) {
        onError(e);
      } finally {
        dispatch(Actions.setLoading(false));
      }
    },

  getAvatarBase64:
    ({ onError }) =>
    async (dispatch, getState) => {
      dispatch(Actions.setLoading(true));

      const {
        auth: { user },
      } = getState();

      try {
        const response = await request({
          method: 'GET',
          url: SETTINGS_AVATAR_BASE64_URL,
        });
        const avatar = response.data.content.avatar;
        dispatch(
          Actions.updateUserData({ user: { ...user, avatar_base64: avatar } })
        );
        dispatch(Actions.setLoading(false));
      } catch (e) {
        onError && onError(e);
        dispatch(Actions.setLoading(false));
      }
    },
};

const { reducer } = authSlice;

export { reducer, authSlice, Async, Actions, Selectors };
