import styled from 'styled-components';

export const TextArea = styled.textarea`
  width: 100%;
  height: ${(props) => props.heightArea || ''};
  padding: 1rem 1.6rem;
  border: 1px solid transparent;
  border-radius: 0.6rem;
  resize: none;
  outline: none;
  color: ${(props) => props.theme.config.colors.text_base};
  margin-bottom: ${(props) => props.theme.config.spacing.spacing_4};
  transition: border-color 0.2s ease-in;
  ${(props) =>
    props.bordered &&
    `border: 1px solid ${props.theme.config.colors.gray_300}`};

  &::placeholder {
    color: ${(props) => props.theme.config.colors.gray_400};
  }

  &:-ms-input-placeholder {
    color: ${(props) => props.theme.config.colors.gray_400};
  }

  &::-ms-input-placeholder {
    color: ${(props) => props.theme.config.colors.gray_400};
  }

  &:hover,
  &:focus {
    border-color: ${(props) => props.theme.config.colors.primary};
  }

  &:disabled,
  &:read-only {
    background-color: ${(props) => props.theme.config.colors.gray_200};
  }
`;
