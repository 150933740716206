import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'modules/Core/Common';
import * as S from './styles';

const Number = ({ dataCy, ...props }) => (
  <S.Container>
    <Icon name="arrow-down" />
    <S.NumberField min={0} data-cy={dataCy} {...props} />
    <Icon name="arrow-up" />
  </S.Container>
);

Number.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element,
  dataCy: PropTypes.string,
};

export { Number };
