import React from 'react';
import Dashboard from './Dashboard.component';
import PropTypes from 'prop-types';

const DashboardContainer = ({ user }) => {
  const data = {
    motivation: [
      {
        id: 1,
        phrase: '“An investment in knowledge pays the best interest.”',
        author: 'Benjamin Franklin',
      },
      {
        id: 2,
        phrase:
          '“Education is the passport to the future, for tomorrow belongs to those who prepare for it today.”',
        author: 'Malcolm X',
      },
      {
        id: 3,
        phrase:
          '“Education is not the filling of a pail, but the lighting of a fire.”',
        author: 'W.B. Yeats',
      },
      {
        id: 4,
        phrase:
          '“Education is not preparation for life; education is life itself.”',
        author: 'John Dewey',
      },
      {
        id: 5,
        phrase: '“Perfect is the enemy of good.”',
        author: 'Voltaire',
      },
      {
        id: 6,
        phrase: '“I’m still learning.”',
        author: 'Michelangelo',
      },
    ],
  };
  return <Dashboard data={data} user={user} />;
};

DashboardContainer.propTypes = {
  user: PropTypes.object,
  whitelabel: PropTypes.object,
};

export default DashboardContainer;
