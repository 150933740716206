import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { device } from 'modules/Utils/Devices';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent = 'space-between' }) => justifyContent};
  margin-bottom: ${(props) =>
    props.marginSmall
      ? props.theme.config.spacing.spacing_6
      : props.theme.config.spacing.spacing_7};

  @media ${device.mobile} {
    margin-bottom: ${(props) => props.theme.config.spacing.spacing_4};
    flex-wrap: wrap;
  }
`;

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) =>
    props.alignRight ? 'flex-end' : 'space-between'};
  margin-left: ${(props) => props.theme.config.spacing.spacing_6};

  input[type='search'] {
    @media ${device.mobile} {
      width: 100%;
    }
  }

  button {
    @media ${device.tablet} {
      margin-left: ${(props) => props.theme.config.spacing.spacing_3};
    }

    @media ${device.mobile} {
      margin-top: ${(props) => props.theme.config.spacing.spacing_3};
    }
  }

  @media ${device.mobile} {
    margin-top: ${(props) => props.theme.config.spacing.spacing_3};
    margin-left: 0;
    flex-wrap: wrap;
  }
`;

export const Title = styled.h2`
  ${(props) => props.theme.config.typograph.quartenary};
  color: ${(props) => props.theme.config.colors.text_base};
  white-space: nowrap;
`;

export const Subtitle = styled.h3`
  ${(props) => props.theme.config.typograph.xsmall};
  margin-top: ${(props) => props.theme.config.spacing.spacing_1};
  color: ${(props) => props.theme.config.colors.text_base};
  opacity: 0.5;
  text-transform: capitalize;
  white-space: nowrap;
`;

export const SubtitleLink = styled((props) => <Link {...props} />)`
  display: flex;
  ${(props) => props.theme.config.typograph.mico};
  color: ${(props) => props.theme.config.colors.gray};
  white-space: nowrap;
  svg {
    display: inline-block;
    width: 0.75rem !important;
    padding-top: 0.2rem;

    path {
      fill: ${(props) => props.theme.config.colors.gray_400};
    }
  }

  &:hover {
    color: ${(props) => props.theme.config.colors.gray_400};
  }
`;

export const ButtonAddContainer = styled.div`
  button {
    svg {
      display: flex;
      position: relative;
      margin-left: -8px;
      margin-right: ${(props) => props.theme.config.spacing.spacing_1};
    }
  }
`;

export const Span = styled.span`
  display: flex;
  align-items: center;

  svg {
    width: 1.2rem;
    margin-right: ${(props) => props.theme.config.spacing.spacing_2};

    path {
      fill: ${(props) => props.theme.config.colors.light};
    }
  }
`;
