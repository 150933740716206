export const addParamsToURL = (url, params) => {
  if (typeof url !== 'string') {
    throw new Error('url parameter must be a string');
  }
  if (typeof params !== 'object') {
    throw new Error('params parameter must be an object');
  }
  return new URL(updateSearchParams(new URL(url), params)).toString();
};

const updateSearchParams = (urlObj, params) => {
  const searchParams = new URLSearchParams(urlObj.search);
  Object.entries(params).forEach(([key, value]) =>
    searchParams.set(key, value)
  );
  urlObj.search = searchParams.toString();
  return urlObj;
};
