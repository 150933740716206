import React, { Fragment, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { find } from 'lodash';

import { useMediaQuery } from 'modules/Core/Hooks';
import { RoutesShortcuts } from 'modules/Core/Routes/PrivateRoute/config';
import { checkAllAbilitiesToShowMenu } from 'modules/Core/Routes/PrivateRoute/PrivateRoute.utils';
import { ArrowLeftIcon } from '@edusynch/edusynch-svg-icons';
import { useLayout } from '../Hooks/useLayout';
import * as S from './Sidebar.style';
import i18n from 'i18next';

const SidebarSubmenu = ({
  opened,
  clicked,
  currentMenu,
  openSidebarAbsolute,
  toggleCloseSidebar,
  openDashboardShortcut,
  openedResponsive,
  toggleResponsiveSidebarClose,
}) => {
  const sidebarRef = useRef(null);
  const shortcutRef = useRef(null);
  const isDesktop = useMediaQuery('(min-width: 768px)');
  const { topMargin } = useLayout();

  const goToLinkAndCloseMenu = useCallback(() => {
    toggleCloseSidebar();
    toggleResponsiveSidebarClose();
  });

  const formatStringToTranslation = (name) =>
    name?.replaceAll(' ', '-').toLowerCase();

  const Icon = currentMenu?.Icon;

  return (
    <>
      <S.SidebarCSSTransition
        in={(opened || clicked) && !openDashboardShortcut && openedResponsive}
        timeout={200}
        unmountOnExit
        nodeRef={sidebarRef}
        classNames="sidebar-animation"
      >
        <S.Submenu ref={sidebarRef} style={{ top: `${topMargin || 0}px` }}>
          <S.SubmenuTitle>
            {!isDesktop && (
              <S.OpenArrow onClick={toggleCloseSidebar}>
                <ArrowLeftIcon />
              </S.OpenArrow>
            )}
            {Icon && <Icon color="#0075EA" />}
            {currentMenu?.submenu?.name &&
              i18n.t(
                `sidebar.${formatStringToTranslation(
                  currentMenu?.submenu?.name
                )}`
              )}
          </S.SubmenuTitle>

          <S.ItemsList>
            {currentMenu?.submenu?.menus?.map(({ name, routes }) => {
              const hasChildrenPermissions = find(
                routes,
                ({ show_on_submenu, hidden }) => show_on_submenu && !hidden
              );

              return (
                <Fragment key={uuid()}>
                  {!!hasChildrenPermissions && (
                    <S.ItemsListItem>
                      <S.SubmenuItemsTitle>
                        {name &&
                          i18n.t(
                            `sidebar.${formatStringToTranslation(
                              currentMenu?.submenu?.name
                            )}-submenu.${formatStringToTranslation(name)}`
                          )}
                      </S.SubmenuItemsTitle>
                    </S.ItemsListItem>
                  )}

                  {routes
                    .filter((route) => route.show_on_submenu)
                    .filter((route) => !route.hidden)
                    .map(({ name, path, disabled }) => (
                      <S.ItemsListItem key={uuid()}>
                        <S.SubmenuItems>
                          <S.SubmenuLink
                            to={path}
                            disabled={disabled}
                            onClick={isDesktop ? null : goToLinkAndCloseMenu}
                          >
                            {i18n.t(
                              `sidebar.${formatStringToTranslation(
                                currentMenu?.submenu?.name
                              )}-submenu.menu-items.${formatStringToTranslation(
                                name
                              )}`
                            )}
                          </S.SubmenuLink>
                        </S.SubmenuItems>
                      </S.ItemsListItem>
                    ))}
                </Fragment>
              );
            })}
          </S.ItemsList>
        </S.Submenu>
      </S.SidebarCSSTransition>

      <S.SidebarCSSTransition
        in={openDashboardShortcut}
        timeout={200}
        unmountOnExit
        nodeRef={shortcutRef}
        classNames="sidebar-animation"
      >
        <S.Submenu ref={shortcutRef} style={{ top: `${topMargin || 0}px` }}>
          <S.SubmenuTitle>{i18n.t('sidebar.fast-access.title')}</S.SubmenuTitle>

          <S.ItemsList>
            {RoutesShortcuts.filter(({ hidden }) => !hidden)
              .filter(({ abilities }) => checkAllAbilitiesToShowMenu(abilities))
              .map(({ name, Icon, path }) => (
                <S.ShortcutListItem key={uuid()}>
                  {!path ? (
                    <S.ShortcutButton
                      onClick={openSidebarAbsolute}
                      data-name={name}
                    >
                      {Icon && <Icon color="#0075EA" type="small" />}
                      {i18n.t(`sidebar.${formatStringToTranslation(name)}`)}
                    </S.ShortcutButton>
                  ) : (
                    <S.ShortcutLink to={path}>
                      {Icon && <Icon color="#0075EA" type="small" />}
                      {i18n.t(`sidebar.${formatStringToTranslation(name)}`)}
                    </S.ShortcutLink>
                  )}
                </S.ShortcutListItem>
              ))}
          </S.ItemsList>
        </S.Submenu>
      </S.SidebarCSSTransition>
    </>
  );
};

SidebarSubmenu.defaultProps = {
  opened: false,
};

SidebarSubmenu.propTypes = {
  opened: PropTypes.bool,
  clicked: PropTypes.bool,
  currentMenu: PropTypes.any,
  toggleCloseSidebar: PropTypes.func,
  openDashboardShortcut: PropTypes.bool,
  toggleResponsiveSidebarClose: PropTypes.func,
  openSidebarAbsolute: PropTypes.func,
  openedResponsive: PropTypes.bool,
};

export default React.memo(SidebarSubmenu);
