import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import i18n from 'i18next';
import {
  Row,
  Col,
  Input,
  Button,
  ButtonDropdown,
  ConfirmTooltip,
  RangeDatePickerModal,
} from 'modules/Core/Common';
import { Menu } from './Menu';
import { FiltersPlaceholder } from './Filters.placeholder';
import { useAuth } from 'modules/Auth/Hooks';
import { convertUTCDateToUserTimezone } from 'modules/Utils/Date';
import { useMediaQuery } from 'modules/Core/Hooks';
import { isFalsy, format } from 'modules/Utils';
import {
  formatCurrentFilters,
  formatOptionsWithDates,
  getDatesObjectByFilterName,
} from './Filters.utils';
import { Skeleton } from 'modules/Core/Common';
import {
  CloseIcon,
  CustomIcon,
  PlusIcon,
  SearchIcon,
} from '@edusynch/edusynch-svg-icons';

import * as S from './Filters.styles';

export const Filters = ({
  options = [],
  toggleOptions = [],
  defaultSearch,
  defaultFilters,
  defaultDates,
  isDateTime,
  onFilterChange,
  onClearAllFilters,
  onClearFilter,
  onClearDates,
  onFilter,
  loading,
  hasClearDate,
  unlimitedMaxDate = false,
  customMaxDateInDays = null,
  loadingFilters,
  ...props
}) => {
  const { userTimeFormat, userTimezone, userDateFormat } = useAuth();
  const [shouldCloseDropdown, setShouldCloseDropdown] = useState(false);
  const [search, setSearch] = useState('');
  const [initialDates, setInitialDates] = useState({});
  const [dates, setDates] = useState({});

  const [isRangeDatePickerModalOpen, setIsRangeDatePickerModalOpen] =
    useState(false);

  const [
    isRangeEvaluateDatePickerModalOpen,
    setIsRangeEvaluateDatePickerModalOpen,
  ] = useState(false);

  const [
    isRangeScheduleDatePickerModalOpen,
    setIsRangeScheduleDatePickerModalOpen,
  ] = useState(false);

  const [
    isRangeSubmissionDatePickerModalOpen,
    setIsRangeSubmissionDatePickerModalOpen,
  ] = useState(false);

  const theme = useTheme();

  const isMobile = useMediaQuery('(max-width: 767.98px)');

  const resetDates = () => {
    if (Object.keys(initialDates).length !== 0 && defaultDates) {
      setDates(initialDates);
    }
  };

  const handleClearAllFilters = () => {
    resetDates();
    onClearAllFilters();
  };

  useEffect(() => {
    if (Object.keys(initialDates).length === 0 && defaultDates) {
      const initialDates_ = {
        start_date: defaultDates?.start_date,
        end_date: defaultDates?.end_date,
        start_evaluated_date: defaultDates?.start_evaluated_date,
        end_evaluated_date: defaultDates?.end_evaluated_date,
        start_schedule_date: defaultDates?.start_schedule_date,
        end_schedule_date: defaultDates?.end_schedule_date,
        start_submission_date: defaultDates?.start_submission_date,
        end_submission_date: defaultDates?.end_submission_date,
      };
      setInitialDates(initialDates_);
      setDates(initialDates_);
    }
  }, [defaultDates]);

  useEffect(() => {
    setSearch(defaultSearch || '');
  }, [defaultSearch]);

  const hasDate = useMemo(() => {
    return (
      defaultDates && 'start_date' in defaultDates && 'end_date' in defaultDates
    );
  }, [defaultDates?.start_date, defaultDates?.end_date]);

  const hasEvaluateDate = useMemo(() => {
    return (
      defaultDates &&
      'start_evaluated_date' in defaultDates &&
      'end_evaluated_date' in defaultDates
    );
  }, [defaultDates?.start_evaluated_date, defaultDates?.end_evaluated_date]);

  const hasScheduleDate = useMemo(() => {
    return (
      defaultDates &&
      'start_schedule_date' in defaultDates &&
      'end_schedule_date' in defaultDates
    );
  }, [defaultDates?.start_schedule_date, defaultDates?.end_schedule_date]);

  const hasSubmissionDate = useMemo(() => {
    return (
      defaultDates &&
      'start_submission_date' in defaultDates &&
      'end_submission_date' in defaultDates
    );
  }, [defaultDates?.start_submission_date, defaultDates?.end_submission_date]);

  const formattedCurrentFilters = formatCurrentFilters(options, defaultFilters);

  const formattedOptions = defaultDates
    ? formatOptionsWithDates(
        options,
        hasDate,
        hasEvaluateDate,
        hasScheduleDate,
        hasSubmissionDate
      )
    : options;

  const hasSelectedFilters = useMemo(() => {
    return (
      !!defaultSearch || !isFalsy(formattedCurrentFilters) || !isFalsy(dates)
    );
  }, [defaultSearch, formattedCurrentFilters, dates]);

  const formatDateToSubmit = (date) => date?.substring(0, 10);

  const handleSubmitFilters = () => {
    onFilter({
      search: search.trim(),
      start_date: formatDateToSubmit(dates.start_date),
      end_date: formatDateToSubmit(dates.end_date),
      start_evaluated_date: formatDateToSubmit(dates?.start_evaluated_date),
      end_evaluated_date: formatDateToSubmit(dates?.end_evaluated_date),
      start_schedule_date: formatDateToSubmit(dates?.start_schedule_date),
      end_schedule_date: formatDateToSubmit(dates?.end_schedule_date),
      start_submission_date: formatDateToSubmit(dates?.start_submission_date),
      end_submission_date: formatDateToSubmit(dates?.end_submission_date),
    });
  };

  const handleSearchKeyUp = (event) => {
    if (event.key === 'Enter') {
      handleSubmitFilters();
    }
  };

  const handleFilterChange = (filter) => {
    const isDateFilter = filter?.type.includes('date_interval');
    const isEvaluateDateFilter = filter?.type.includes('evaluated_date');
    const isScheduleDateFilter = filter?.type.includes('scheduled_date');
    const isSubmissionDateFilter = filter?.type.includes('submissions-date');

    if (
      !isDateFilter &&
      !isEvaluateDateFilter &&
      !isScheduleDateFilter &&
      !isSubmissionDateFilter
    ) {
      return onFilterChange(filter);
    }

    const { filterName } = filter;

    const datesObject = getDatesObjectByFilterName(filterName);

    if (datesObject !== 'custom') {
      isDateFilter && setDates((prev) => ({ ...prev, ...datesObject }));
      isEvaluateDateFilter &&
        setDates((prev) => ({
          ...prev,
          start_evaluated_date: datesObject?.start_date,
          end_evaluated_date: datesObject?.end_date,
        }));
      isScheduleDateFilter &&
        setDates((prev) => ({
          ...prev,
          start_schedule_date: datesObject?.start_date,
          end_schedule_date: datesObject?.end_date,
        }));
      isSubmissionDateFilter &&
        setDates((prev) => ({
          ...prev,
          start_submission_date: datesObject?.start_date,
          end_submission_date: datesObject?.end_date,
        }));
    } else {
      isDateFilter && setIsRangeDatePickerModalOpen(true);
      isEvaluateDateFilter && setIsRangeEvaluateDatePickerModalOpen(true);
      isScheduleDateFilter && setIsRangeScheduleDatePickerModalOpen(true);
      isSubmissionDateFilter && setIsRangeSubmissionDatePickerModalOpen(true);
    }
  };

  const requiredFiltersNotSelected = defaultFilters
    ?.filter((filter) => filter.required)
    ?.some((filter) => !filter.value);

  const hasTwoOrMoreDateFilters =
    Object?.values(dates)?.filter((date) => Boolean(date)).length > 2;

  return (
    <S.Container>
      <RangeDatePickerModal
        isOpen={isRangeDatePickerModalOpen}
        onClose={() => setIsRangeDatePickerModalOpen(false)}
        startDate={dates?.start_date}
        endDate={dates?.end_date}
        onDateChange={(date) => setDates((prev) => ({ ...prev, ...date }))}
        onSave={() => setIsRangeDatePickerModalOpen(false)}
        isDateTime={isDateTime}
        customTitle={isDateTime && `${i18n.t('commons.filter.is-date-time')}`}
        unlimitedMaxDate={unlimitedMaxDate}
        customMaxDateInDays={customMaxDateInDays}
      />
      <RangeDatePickerModal
        isOpen={isRangeEvaluateDatePickerModalOpen}
        onClose={() => setIsRangeEvaluateDatePickerModalOpen(false)}
        startDate={dates?.start_evaluated_date}
        endDate={dates?.end_evaluated_date}
        onDateChange={(date) => {
          setDates((prev) => ({
            ...prev,
            start_evaluated_date:
              date?.start_date || dates?.start_evaluated_date,
            end_evaluated_date: date?.end_date || dates?.end_evaluated_date,
          }));
        }}
        onSave={() => setIsRangeEvaluateDatePickerModalOpen(false)}
        customTitle={i18n.t('commons.filter.evaluated-date')}
        unlimitedMaxDate={unlimitedMaxDate}
        customMaxDateInDays={customMaxDateInDays}
      />
      <RangeDatePickerModal
        isOpen={isRangeScheduleDatePickerModalOpen}
        onClose={() => setIsRangeScheduleDatePickerModalOpen(false)}
        startDate={dates?.start_schedule_date}
        endDate={dates?.end_schedule_date}
        onDateChange={(date) => {
          setDates((prev) => ({
            ...prev,
            start_schedule_date: date?.start_date || dates?.start_schedule_date,
            end_schedule_date: date?.end_date || dates?.end_schedule_date,
          }));
        }}
        onSave={() => setIsRangeScheduleDatePickerModalOpen(false)}
        customTitle={i18n.t('commons.filter.scheduled-date')}
        unlimitedMaxDate={unlimitedMaxDate}
        customMaxDateInDays={customMaxDateInDays}
      />
      <RangeDatePickerModal
        isOpen={isRangeSubmissionDatePickerModalOpen}
        onClose={() => setIsRangeSubmissionDatePickerModalOpen(false)}
        startDate={dates?.start_submission_date}
        endDate={dates?.end_submission_date}
        onDateChange={(date) => {
          setDates((prev) => ({
            ...prev,
            start_submission_date:
              date?.start_date || dates?.start_submission_date,
            end_submission_date: date?.end_date || dates?.end_submission_date,
          }));
        }}
        onSave={() => setIsRangeSubmissionDatePickerModalOpen(false)}
        customTitle={i18n.t('commons.filter.submissions-date')}
        unlimitedMaxDate={unlimitedMaxDate}
        customMaxDateInDays={customMaxDateInDays}
      />
      <Row
        style={
          hasSelectedFilters ? { marginBottom: '0' } : { marginBottom: '1rem' }
        }
      >
        <Col xs={12}>
          <Row>
            <Col
              xs={12}
              md={4}
              lg={3}
              style={{
                marginBottom: isMobile ? '1.6rem' : '0',
              }}
            >
              <Input
                PrependIcon={SearchIcon}
                rounded
                medium
                style={{
                  width: '100%',
                  border: `1px solid ${theme.config.colors.primary_200}`,
                }}
                dataCy="filter.search-input"
                placeholder={i18n.t('common-words.search')}
                value={search}
                onChange={({ target }) => setSearch(target.value)}
                onKeyUp={handleSearchKeyUp}
                disabled={loadingFilters}
              />
            </Col>
            <Col xs={12} md={8} lg={9}>
              <Row>
                <Col
                  xs={4}
                  md
                  style={{ paddingLeft: isMobile ? '0.8rem' : '0' }}
                >
                  {loadingFilters && (
                    <S.ButtonSkeletonContainer>
                      <Skeleton type="large" style={{ width: '8.4rem' }} />
                    </S.ButtonSkeletonContainer>
                  )}
                  {!loadingFilters && !!formattedOptions.length && (
                    <ButtonDropdown
                      title={i18n.t('common-words.filter')}
                      Icon={CustomIcon}
                      iconColor={theme.config.colors.primary}
                      closeOnClickInside={shouldCloseDropdown}
                      hasArrowDown={false}
                      placement="left"
                      showOverlay={false}
                      dataCy="filter.filter-button"
                      onCloseDropdown={() => setShouldCloseDropdown(false)}
                      style={{ width: '8.8rem' }}
                    >
                      <Menu
                        options={formattedOptions}
                        currentFilters={defaultFilters}
                        onFilterChange={handleFilterChange}
                        toggleOptions={toggleOptions}
                        onSelect={() => setShouldCloseDropdown(true)}
                        onSearchMouseEnter={() => setShouldCloseDropdown(false)}
                        onSearchMouseLeave={() => setShouldCloseDropdown(true)}
                        onHeaderMouseEnter={() => setShouldCloseDropdown(false)}
                        onHeaderMouseLeave={() => setShouldCloseDropdown(true)}
                        {...props}
                      />
                    </ButtonDropdown>
                  )}
                </Col>
                <Col xs={8} md>
                  <Row end="xs" style={{ marginRight: '0' }}>
                    {hasSelectedFilters && (
                      <ConfirmTooltip
                        subtitle={i18n.t(
                          'commons.filter.message-clear-all-filters'
                        )}
                        onConfirm={handleClearAllFilters}
                      >
                        <Button
                          dataCy="filter.clear-button"
                          light
                          medium
                          style={{
                            border: 'none',
                            padding: '0',
                            minWidth: '8.4rem',
                            color: theme.config.colors.gray,
                            backgroundColor: 'transparent',
                          }}
                        >
                          {i18n.t('commons.buttons.clear-all')}
                        </Button>
                      </ConfirmTooltip>
                    )}
                    <Button
                      medium
                      onClick={handleSubmitFilters}
                      dataCy="filter.search-button"
                      style={{
                        minWidth: '9rem',
                      }}
                      disabled={requiredFiltersNotSelected || loadingFilters}
                    >
                      {i18n.t('common-words.search')}
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      {hasSelectedFilters && (
        <>
          <S.Separator />
          <S.SelectedFilters>
            {!!defaultSearch && (
              <S.SearchContainer
                style={{
                  marginBottom:
                    isMobile &&
                    (!isFalsy(formattedCurrentFilters) ||
                      !isFalsy(defaultDates))
                      ? '1.6rem'
                      : '0',
                }}
              >
                <S.Label>{i18n.t('common-words.search')}</S.Label>
                <S.SearchValue data-cy="filter.item">
                  {defaultSearch}
                  <ConfirmTooltip
                    subtitle={i18n.t('commons.filter.message-clear-filters')}
                    onConfirm={() =>
                      onFilter({
                        search: '',
                        start_date: dates.start_date,
                        end_date: dates.end_date,
                        start_evaluated_date: dates.start_evaluated_date,
                        end_evaluated_date: dates.end_evaluated_date,
                        start_schedule_date: dates.start_schedule_date,
                        end_schedule_date: dates.end_schedule_date,
                        start_submission_date: dates.start_submission_date,
                        end_submission_date: dates.end_submission_date,
                      })
                    }
                  >
                    <CloseIcon width={8} color="white" />
                  </ConfirmTooltip>
                </S.SearchValue>
              </S.SearchContainer>
            )}
            {loading ? (
              <FiltersPlaceholder
                style={{
                  marginBottom:
                    isMobile &&
                    (!isFalsy(formattedCurrentFilters) ||
                      !isFalsy(defaultDates))
                      ? '1.6rem'
                      : '0',
                }}
                filtersLength={formattedCurrentFilters?.length}
              />
            ) : (
              <>
                {(!isFalsy(formattedCurrentFilters) || !isFalsy(dates)) && (
                  <S.FiltersContainer
                    style={{
                      marginBottom:
                        isMobile &&
                        (!isFalsy(formattedCurrentFilters) ||
                          !isFalsy(defaultDates))
                          ? '1.6rem'
                          : '0',
                    }}
                  >
                    <Row style={{ margin: '0', flexWrap: 'nowrap' }}>
                      <S.Label style={{ paddingTop: '0.8rem' }}>
                        {i18n.t('common-words.filter')}
                      </S.Label>
                      <S.Filters>
                        {formattedCurrentFilters?.map((filter) => (
                          <S.FilterValue key={filter.id} data-cy="filter.item">
                            <span>{filter.type}:</span> {filter.label}
                            {!filter.required && (
                              <ConfirmTooltip
                                subtitle={i18n.t(
                                  'commons.filter.message-clear-filters'
                                )}
                                onConfirm={() => onClearFilter(filter)}
                              >
                                <CloseIcon width={8} color="white" />
                              </ConfirmTooltip>
                            )}
                          </S.FilterValue>
                        ))}
                        {toggleOptions
                          ?.filter((toggleOption) => toggleOption.isActive)
                          ?.map((filter) => (
                            <S.FilterValue
                              key={filter.id}
                              data-cy="filter.item"
                            >
                              <span>{filter.name}</span>
                              <ConfirmTooltip
                                subtitle={i18n.t(
                                  'commons.filter.message-clear-filters'
                                )}
                                onConfirm={() => onClearFilter(filter)}
                              >
                                <CloseIcon width={8} color="white" />
                              </ConfirmTooltip>
                            </S.FilterValue>
                          ))}
                        {!isFalsy(dates) &&
                          (dates.start_date || dates.end_date) && (
                            <S.FilterValue style={{ cursor: 'pointer' }}>
                              <S.FilterValueContent
                                onClick={() =>
                                  setIsRangeDatePickerModalOpen(true)
                                }
                              >
                                <span>{`${i18n.t('common-words.date')}:`}</span>
                                {dates.start_date &&
                                  format(
                                    convertUTCDateToUserTimezone(
                                      dates.start_date,
                                      userTimezone
                                    ),
                                    `${userDateFormat} ${
                                      !isDateTime ? '' : userTimeFormat
                                    }`
                                  )}
                                &nbsp;
                                <span>
                                  {i18n.t('commons.filter.prep-to')}
                                </span>{' '}
                                {dates.end_date &&
                                  format(
                                    convertUTCDateToUserTimezone(
                                      dates.end_date,
                                      userTimezone
                                    ),
                                    `${userDateFormat} ${
                                      !isDateTime ? '' : userTimeFormat
                                    }`
                                  )}
                              </S.FilterValueContent>
                              {(hasTwoOrMoreDateFilters || hasClearDate) && (
                                <ConfirmTooltip
                                  subtitle={i18n.t(
                                    'commons.filter.message-clear-filters'
                                  )}
                                  onConfirm={() => {
                                    setDates((prev) => ({
                                      ...prev,
                                      start_date: '',
                                      end_date: '',
                                    }));
                                    onClearDates();
                                  }}
                                >
                                  <CloseIcon width={8} color="white" />
                                </ConfirmTooltip>
                              )}
                            </S.FilterValue>
                          )}
                        {!isFalsy(dates) &&
                          (dates?.start_evaluated_date ||
                            dates?.end_evaluated_date) && (
                            <S.FilterValue>
                              <S.FilterValueContent
                                onClick={() =>
                                  setIsRangeEvaluateDatePickerModalOpen(true)
                                }
                              >
                                <span>{`${i18n.t(
                                  'commons.filter.evaluated-date'
                                )}:`}</span>
                                {dates?.start_evaluated_date &&
                                  format(
                                    convertUTCDateToUserTimezone(
                                      dates?.start_evaluated_date,
                                      userTimezone
                                    ),
                                    `${userDateFormat} ${
                                      !isDateTime ? '' : userTimeFormat
                                    }`
                                  )}
                                &nbsp;
                                <span>
                                  {i18n.t('commons.filter.prep-to')}
                                </span>{' '}
                                {dates?.end_evaluated_date &&
                                  format(
                                    convertUTCDateToUserTimezone(
                                      dates?.end_evaluated_date,
                                      userTimezone
                                    ),
                                    `${userDateFormat} ${
                                      !isDateTime ? '' : userTimeFormat
                                    }`
                                  )}
                              </S.FilterValueContent>

                              {hasTwoOrMoreDateFilters && (
                                <ConfirmTooltip
                                  subtitle={i18n.t(
                                    'commons.filter.message-clear-filters'
                                  )}
                                  onConfirm={() =>
                                    setDates((prev) => ({
                                      ...prev,
                                      start_evaluated_date: '',
                                      end_evaluated_date: '',
                                    }))
                                  }
                                >
                                  <CloseIcon width={8} color="white" />
                                </ConfirmTooltip>
                              )}
                            </S.FilterValue>
                          )}
                        {!isFalsy(dates) &&
                          (dates?.start_schedule_date ||
                            dates?.end_schedule_date) && (
                            <S.FilterValue>
                              <S.FilterValueContent
                                onClick={() =>
                                  setIsRangeScheduleDatePickerModalOpen(true)
                                }
                              >
                                <span>{`${i18n.t(
                                  'commons.filter.scheduled-date'
                                )}:`}</span>
                                {dates?.start_schedule_date &&
                                  format(
                                    convertUTCDateToUserTimezone(
                                      dates?.start_schedule_date,
                                      userTimezone
                                    ),
                                    `${userDateFormat} ${
                                      !isDateTime ? '' : userTimeFormat
                                    }`
                                  )}
                                &nbsp;
                                <span>
                                  {i18n.t('commons.filter.prep-to')}
                                </span>{' '}
                                {dates?.end_schedule_date &&
                                  format(
                                    convertUTCDateToUserTimezone(
                                      dates?.end_schedule_date,
                                      userTimezone
                                    ),
                                    `${userDateFormat} ${
                                      !isDateTime ? '' : userTimeFormat
                                    }`
                                  )}
                              </S.FilterValueContent>

                              {hasTwoOrMoreDateFilters && (
                                <ConfirmTooltip
                                  subtitle={i18n.t(
                                    'commons.filter.message-clear-filters'
                                  )}
                                  onConfirm={() =>
                                    setDates((prev) => ({
                                      ...prev,
                                      start_schedule_date: '',
                                      end_schedule_date: '',
                                    }))
                                  }
                                >
                                  <CloseIcon width={8} color="white" />
                                </ConfirmTooltip>
                              )}
                            </S.FilterValue>
                          )}
                        {!isFalsy(dates) &&
                          (dates?.start_submission_date ||
                            dates?.end_submission_date) && (
                            <S.FilterValue>
                              <S.FilterValueContent
                                onClick={() =>
                                  setIsRangeSubmissionDatePickerModalOpen(true)
                                }
                              >
                                <span>{`${i18n.t(
                                  'commons.filter.submissions-date'
                                )}:`}</span>
                                {dates?.start_submission_date &&
                                  format(
                                    convertUTCDateToUserTimezone(
                                      dates?.start_submission_date,
                                      userTimezone
                                    ),
                                    `${userDateFormat} ${
                                      !isDateTime ? '' : userTimeFormat
                                    }`
                                  )}
                                &nbsp;
                                <span>to</span>{' '}
                                {dates?.end_submission_date &&
                                  format(
                                    convertUTCDateToUserTimezone(
                                      dates?.end_submission_date,
                                      userTimezone
                                    ),
                                    `${userDateFormat} ${
                                      !isDateTime ? '' : userTimeFormat
                                    }`
                                  )}
                              </S.FilterValueContent>

                              {hasTwoOrMoreDateFilters && (
                                <ConfirmTooltip
                                  subtitle={i18n.t(
                                    'commons.filter.message-clear-filters'
                                  )}
                                  onConfirm={() =>
                                    setDates((prev) => ({
                                      ...prev,
                                      start_submission_date: '',
                                      end_submission_date: '',
                                    }))
                                  }
                                >
                                  <CloseIcon width={8} color="white" />
                                </ConfirmTooltip>
                              )}
                            </S.FilterValue>
                          )}
                        {formattedOptions?.length > 1 && (
                          <ButtonDropdown
                            Icon={PlusIcon}
                            iconColor={theme.config.colors.gray}
                            iconSize={16}
                            iconOnly
                            closeOnClickInside={shouldCloseDropdown}
                            hasArrowDown={false}
                            placement="left"
                            showOverlay={false}
                            dataCy="filter.filter-icon-button"
                            onCloseDropdown={() =>
                              setShouldCloseDropdown(false)
                            }
                          >
                            <Menu
                              options={formattedOptions}
                              currentFilters={defaultFilters}
                              onFilterChange={handleFilterChange}
                              toggleOptions={toggleOptions}
                              onSelect={() => setShouldCloseDropdown(true)}
                              onSearchMouseEnter={() =>
                                setShouldCloseDropdown(false)
                              }
                              onSearchMouseLeave={() =>
                                setShouldCloseDropdown(true)
                              }
                              onHeaderMouseEnter={() =>
                                setShouldCloseDropdown(false)
                              }
                              onHeaderMouseLeave={() =>
                                setShouldCloseDropdown(true)
                              }
                              {...props}
                            />
                          </ButtonDropdown>
                        )}
                      </S.Filters>
                    </Row>
                  </S.FiltersContainer>
                )}
              </>
            )}
          </S.SelectedFilters>
        </>
      )}
    </S.Container>
  );
};

Filters.propTypes = {
  options: PropTypes.array,
  toggleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      filterName: PropTypes.string,
      name: PropTypes.string,
      isActive: PropTypes.bool,
      onChange: PropTypes.func,
    })
  ),
  defaultSearch: PropTypes.string,
  defaultFilters: PropTypes.array,
  defaultDates: PropTypes.shape({
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    start_evaluated_date: PropTypes.string,
    end_evaluated_date: PropTypes.string,
    start_schedule_date: PropTypes.string,
    end_schedule_date: PropTypes.string,
    start_submission_date: PropTypes.string,
    end_submission_date: PropTypes.string,
  }),
  isDateTime: PropTypes.bool,
  onFilterChange: PropTypes.func,
  onDateFilterChange: PropTypes.func,
  onClearAllFilters: PropTypes.func,
  onClearFilter: PropTypes.func,
  onClearDates: PropTypes.func,
  onFilter: PropTypes.func,
  loading: PropTypes.bool,
  hasClearDate: PropTypes.bool,
  unlimitedMaxDate: PropTypes.bool,
  customMaxDateInDays: PropTypes.number,
  loadingFilters: PropTypes.bool,
};
