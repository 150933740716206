import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const ButtonToggleContext = React.createContext();

export const ContextTab = ({ children }) => {
  const [toggleButtonTab, setToggleButtonTab] = useState(
    'tab1',
    'tab2',
    'tab3'
  );

  const toggleTab = (index) => {
    setToggleButtonTab(index);
  };

  return (
    <ButtonToggleContext.Provider
      value={{ toggleButtonTab, setToggleButtonTab }}
      toggleTab={toggleTab}
    >
      {children}
    </ButtonToggleContext.Provider>
  );
};

ContextTab.propTypes = {
  children: PropTypes.any,
  toggleTab: PropTypes.func,
};
