import React, { useEffect, useState } from 'react';
import { FieldArray } from 'formik';
import PropTypes from 'prop-types';
import { Row, Col, Icon, MUIDateTimePicker } from 'modules/Core/Common';
import * as S from './styles';
import { useTheme } from 'styled-components';
import { ListIntervals } from '../ListIntervals';
import { addMinutesUTC } from 'modules/Utils/Date';
import { getMinAndMaxBasedOnLastInterval } from 'modules/Users/Evaluators/EvaluatorsUtils';
import i18n from 'i18next';

const Intervals = ({ isEdit, values, setFieldValue, user }) => {
  const [removedValues, setRemovedValues] = useState(null);
  const [isAddFlag, setIsAddFlag] = useState({
    isAddFlag: false,
    isEdit: isEdit,
  });
  const theme = useTheme();
  const { minDate } = getMinAndMaxBasedOnLastInterval();
  const { minDate: startDate, minEndDate: endDate } =
    getMinAndMaxBasedOnLastInterval(values?.intervals);

  const emptyObject = {
    start_date: startDate,
    end_date: endDate,
    isAdd: true,
  };

  useEffect(() => {
    if (removedValues) {
      setFieldValue('removedIntervals', [
        ...values.removedIntervals,
        {
          id: removedValues,
          _destroy: true,
        },
      ]);
    }
  }, [removedValues]);

  const getIndex = (interval) =>
    values?.intervals?.findIndex((item) => item === interval);

  const deleteInterval = (values, interval, arrayHelpers) => {
    const itemToRemove = values?.intervals?.find((item) => item === interval);
    const indexToRemove = getIndex(itemToRemove);
    itemToRemove?.id && setRemovedValues(itemToRemove?.id);
    arrayHelpers.remove(indexToRemove);
  };

  const filteredIntervals = values?.intervals?.filter(
    (item) => !item._destroy && item?.isAdd
  );

  return (
    <>
      <FieldArray
        name="intervals"
        render={(arrayHelpers) => (
          <>
            <Row>
              <Col xs={12}>
                <S.IntervalsHeader>
                  <S.StyledButton
                    medium
                    bold
                    onClick={(event) => {
                      event.preventDefault();
                      arrayHelpers.push(emptyObject);
                      setIsAddFlag({ ...isAddFlag, isAddFlag: true });
                    }}
                  >
                    <Icon name="plus" color={theme.config.colors.light} />
                    {i18n.t(
                      'modules.settings-profile.forms.intervals.button-submit'
                    )}
                  </S.StyledButton>
                </S.IntervalsHeader>
              </Col>

              <Col xs={12}>
                {values?.intervals?.length >= 1 && isAddFlag.isAddFlag && (
                  <S.Container isEdit={!!filteredIntervals?.length}>
                    {filteredIntervals.map((interval, index) => (
                      <S.IntervalContainer key={index}>
                        <S.IntervalBlock>
                          <MUIDateTimePicker
                            name={`intervals.${getIndex(interval)}.start_date`}
                            date={interval?.start_date}
                            minutesStep={30}
                            onChange={(date) => {
                              setFieldValue(
                                `intervals.${getIndex(interval)}.start_date`,
                                date
                              );
                              setFieldValue(
                                `intervals.${getIndex(interval)}.end_date`,
                                addMinutesUTC(date, 30)
                              );
                            }}
                            minDate={minDate}
                            inputProps={{ readOnly: true }}
                          />
                          <MUIDateTimePicker
                            name={`intervals.${getIndex(interval)}.end_date`}
                            date={interval?.end_date}
                            minutesStep={30}
                            onChange={(date) => {
                              setFieldValue(
                                `intervals.${getIndex(interval)}.end_date`,
                                date
                              );
                              setFieldValue(
                                `intervals.${getIndex(interval)}.start_date`,
                                addMinutesUTC(date, -30)
                              );
                            }}
                            minDate={minDate}
                            inputProps={{ readOnly: true }}
                          />
                          <S.ActionsContainer>
                            <S.ActionButton
                              onClick={() =>
                                deleteInterval(values, interval, arrayHelpers)
                              }
                            >
                              <Icon name="delete" />
                            </S.ActionButton>
                          </S.ActionsContainer>
                        </S.IntervalBlock>
                      </S.IntervalContainer>
                    ))}
                  </S.Container>
                )}
              </Col>
            </Row>

            {values?.intervals?.length ? (
              <ListIntervals
                arrayHelpers={arrayHelpers}
                values={values}
                setFieldValue={setFieldValue}
                isAddForm={isAddFlag}
                deleteInterval={deleteInterval}
                user={user}
              />
            ) : (
              <S.EmphyData>
                <Icon
                  name="table-empty-data"
                  color={theme.config.colors.gray_300}
                />
                <h2>
                  {i18n.t(
                    'modules.settings-profile.forms.intervals.empty-data.message'
                  )}
                </h2>
                <span>
                  {i18n.t(
                    'modules.settings-profile.forms.intervals.empty-data.warning'
                  )}
                </span>
              </S.EmphyData>
            )}
          </>
        )}
      />
    </>
  );
};

Intervals.propTypes = {
  values: PropTypes.object,
  data: PropTypes.object,
  setFieldValue: PropTypes.func,
  isEdit: PropTypes.bool,
  user: PropTypes.object,
};

export default React.memo(Intervals);
