import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import i18n from 'i18next';

const ChooseOptions = ({ active }) => (
  <S.Option active={active}>
    {active
      ? i18n.t('modules.users.teachers.table.table-buttons.yes')
      : i18n.t('modules.users.teachers.table.table-buttons.no')}
  </S.Option>
);

ChooseOptions.propTypes = {
  active: PropTypes.bool,
};

export default ChooseOptions;
