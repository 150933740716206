import React from 'react';
import PropTypes from 'prop-types';
import * as S from './IconButton.styles';

export const IconButton = ({
  IconName,
  iconColor,
  iconSize,
  variant,
  borderBottom,
  ...props
}) => {
  return (
    <S.Button variant={variant} borderBottom={borderBottom} {...props}>
      <IconName color={iconColor} width={iconSize} height={iconSize} />
    </S.Button>
  );
};

IconButton.propTypes = {
  IconName: PropTypes.func.isRequired,
  variant: PropTypes.string,
  iconColor: PropTypes.string,
  iconSize: PropTypes.string,
  borderBottom: PropTypes.bool,
};
