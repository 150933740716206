import React from 'react';
import PropTypes from 'prop-types';

import { Skeleton } from 'modules/Core/Common';

export const AudioPlaceholder = ({ secondary }) => {
  return (
    <div style={{ maxWidth: '35rem' }}>
      <Skeleton type="audio" secondary={secondary} />
    </div>
  );
};

AudioPlaceholder.propTypes = {
  secondary: PropTypes.bool,
};
