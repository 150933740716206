const createGroupModel = (group = {}) => {
  return {
    id: group.id || null,
    name: group.name || '',
    slug: group.slug || '',
    hideAnalysis: group.hide_analysis || false,
    hideCustomTests: group.hide_custom_tests || false,
    roundedCert: group.rounded_cefr_certificate || false,
    hasSimulations: group.has_simulations || false,
    showCEFRLanguage: group.show_cefr_language || false,
  };
};

const createGroupRequestModel = (group = {}) => {
  return {
    id: group.id,
    name: group.name,
    slug: group.slug,
    hide_analysis: group.hideAnalysis,
    hide_custom_tests: group.hideCustomTests,
    rounded_cefr_certificate: group.roundedCert,
    has_simulations: group.hasSimulations,
    show_cefr_language: group.showCEFRLanguage,
  };
};

export { createGroupModel, createGroupRequestModel };
