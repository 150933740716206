import styled from 'styled-components';
import { device } from 'modules/Utils/Devices';

const Container = styled.div`
  position: fixed;
  z-index: 100;
  mix-blend-mode: normal;
  background: ${(props) => props.theme.config.colors.text_base};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const FormContainer = styled.div`
  display: block;
  position: absolute;
  padding: 4rem 2.4rem 3.2rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  border-radius: 0.6rem;
  max-width: 42rem;
  border-top: 5px solid ${(props) => props.theme.config.colors.secondary};
  background-color: ${(props) => props.theme.config.colors.background};

  @media ${device.mobile} {
    max-width: 27rem;
  }

  @media ${device.tabletAndMobile} {
    & a,
    label {
      color: ${(props) => props.theme.config.colors.text_base};
    }
  }

  .login-button-container {
    margin-top: 1.2rem;

    @media ${device.tabletAndMobile} {
      margin-top: 2.4rem;
    }

    @media ${device.mobile} {
      position: static;
      transform: none;
    }
  }

  .loading-container {
    padding: 72.5px;

    > div {
      position: static;
    }
  }

  .forgot-link {
    color: ${(props) => props.theme.config.colors.text_base};

    @media ${device.tablet} {
      & {
        margin-bottom: 16px;
      }
    }
  }

  button {
    ${(props) => props.theme.config.typograph.xsmall}
    height: 4rem;
    margin-top: 0;
  }
`;

const Title = styled.h1`
  ${(props) => props.theme.config.typograph.quartenary}
  color: ${(props) => props.theme.config.colors.text_base};
  margin: 0 auto ${(props) => props.theme.config.spacing.spacing_2};
  display: block;
  text-align: center;
`;

const Description = styled.h1`
  ${(props) => props.theme.config.typograph.default}
  color: ${(props) => props.theme.config.colors.text_base};
  margin: 0 auto ${(props) => props.theme.config.spacing.spacing_5};
  display: block;
  text-align: center;

  @media ${device.mobile} {
    margin: 0 auto ${(props) => props.theme.config.spacing.spacing_4};
  }
`;

export { Title, Container, Description, FormContainer };
