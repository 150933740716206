import { lazy } from 'react';
import { menuBuilder } from 'services/Routes.service';
import { EProctoringPluginIcon } from '@edusynch/edusynch-svg-icons';

const ProctoringSettingsOrigin = lazy(() =>
  import(
    'modules/ProctoringSettings/ProctoringSettingsOrigin/ProctoringSettingsOrigin.container'
  )
);
const ProctoringSettingsList = lazy(() =>
  import(
    'modules/ProctoringSettings/ProctoringSettingsList/ProctoringSettingsList.container'
  )
);
const ProctoringSettingsEdit = lazy(() =>
  import(
    'modules/ProctoringSettings/ProctoringSettingsEdit/ProctoringSettingsEdit.container'
  )
);

const ProctoringSettingsMenu = menuBuilder([
  {
    Icon: EProctoringPluginIcon,
    name: 'Proctoring Settings',
    component: ProctoringSettingsOrigin,
    ability: 'proctoring_settings',
    path: '/proctoring-settings',
    exact: true,
  },
  {
    Icon: EProctoringPluginIcon,
    name: 'Proctoring Settings Edit',
    component: ProctoringSettingsEdit,
    ability: 'proctoring_settings',
    path: '/proctoring-settings/edit',
    exact: true,
    hidden: true,
  },
  {
    name: 'Exams Settings',
    component: ProctoringSettingsList,
    ability: 'proctoring_settings',
    path: '/proctoring-settings/exams-settings',
    exact: true,
    hidden: true,
  },
]).addSubmenu();

export default ProctoringSettingsMenu;
