import { lazy } from 'react';
import { menuBuilder } from 'services/Routes.service';
import { ResultsIcon } from '@edusynch/edusynch-svg-icons';

const StudentPracticeResultsListContainer = lazy(() =>
  import(
    'modules/Practice/PracticeResults/StudentPracticeResultsList/StudentPracticeResultsList.container'
  )
);
const StudentHistoryContainer = lazy(() =>
  import(
    'modules/Practice/PracticeResults/StudentHistory/StudentHistory.container'
  )
);
const StudentDetailsContainer = lazy(() =>
  import(
    'modules/Practice/PracticeResults/StudentDetails/StudentDetails.container'
  )
);
const GroupPracticeResultsListContainer = lazy(() =>
  import(
    'modules/Practice/PracticeResults/GroupPracticeResultsList/GroupPracticeResultsList.container'
  )
);
const UnitPracticeResultsListContainer = lazy(() =>
  import(
    'modules/Practice/PracticeResults/UnitPracticeResultsList/UnitPracticeResultsList.container'
  )
);
const SchoolPracticeResultsListContainer = lazy(() =>
  import(
    'modules/Practice/PracticeResults/SchoolPracticeResultsList/SchoolPracticeResultsList.container'
  )
);
const ClassPracticeResultsListContainer = lazy(() =>
  import(
    'modules/Practice/PracticeResults/ClassPracticeResultsList/ClassPracticeResultsList.container'
  )
);

const GroupBaseTestsResultsListContainer = lazy(() =>
  import(
    'modules/Simulation/SimulationResults/SimulationBaseTests/GroupSimulationResultsList/GroupSimulationResultsList.container'
  )
);
const UnitBaseTestsResultsListContainer = lazy(() =>
  import(
    'modules/Simulation/SimulationResults/SimulationBaseTests/UnitSimulationResultsList/UnitSimulationResultsList.container'
  )
);
const SchoolBaseTestsResultsListContainer = lazy(() =>
  import(
    'modules/Simulation/SimulationResults/SimulationBaseTests/SchoolSimulationResultsList/SchoolSimulationResultsList.container'
  )
);
const ClassBaseTestsResultsListContainer = lazy(() =>
  import(
    'modules/Simulation/SimulationResults/SimulationBaseTests/ClassSimulationResultsList/ClassSimulationResultsList.container'
  )
);

const GroupCustomTestsResultsListContainer = lazy(() =>
  import(
    'modules/Simulation/SimulationResults/SimulationCustomTests/GroupSimulationResultsList/GroupSimulationResultsList.container'
  )
);
const UnitCustomTestsResultsListContainer = lazy(() =>
  import(
    'modules/Simulation/SimulationResults/SimulationCustomTests/UnitSimulationResultsList/UnitSimulationResultsList.container'
  )
);
const SchoolCustomTestsResultsListContainer = lazy(() =>
  import(
    'modules/Simulation/SimulationResults/SimulationCustomTests/SchoolSimulationResultsList/SchoolSimulationResultsList.container'
  )
);
const ClassCustomTestsResultsListContainer = lazy(() =>
  import(
    'modules/Simulation/SimulationResults/SimulationCustomTests/ClassSimulationResultsList/ClassSimulationResultsList.container'
  )
);
const ReportContainer = lazy(() =>
  import('modules/Simulation/SimulationResults/Report/Report.container')
);

const AnswerSheetContainer = lazy(() =>
  import('modules/Simulation/AnswerSheet/AnswerSheet.container')
);

const ResultsMenu = menuBuilder([
  {
    Icon: ResultsIcon,
    name: 'Results',
  },
]).addSubmenu([
  {
    type: 'Practice',
    name: 'Student',
    path: '/student-practice-results/:id/history/:exerciseId',
    component: StudentHistoryContainer,
    hidden: true,
    ability: 'results_practice_student',
  },
  {
    type: 'Practice',
    name: 'Student',
    path: '/student-practice-results/:id',
    component: StudentDetailsContainer,
    hidden: true,
    ability: 'results_practice_student',
  },
  {
    type: 'Practice',
    name: 'Group',
    path: '/group-practice-results',
    component: GroupPracticeResultsListContainer,
    ability: 'results_practice_group',
  },
  {
    type: 'Practice',
    name: 'Unit',
    path: '/unit-practice-results',
    component: UnitPracticeResultsListContainer,
    ability: 'results_practice_unit',
  },
  {
    type: 'Practice',
    name: 'School',
    path: '/school-practice-results',
    component: SchoolPracticeResultsListContainer,
    ability: 'results_practice_school',
  },
  {
    type: 'Practice',
    name: 'Class',
    path: '/class-practice-results',
    component: ClassPracticeResultsListContainer,
    ability: 'results_practice_class',
  },
  {
    type: 'Practice',
    name: 'Student',
    path: '/student-practice-results',
    component: StudentPracticeResultsListContainer,
    ability: 'results_practice_student',
  },
  {
    type: 'Base',
    name: 'Group',
    exact: true,
    path: '/results/base/group',
    component: GroupBaseTestsResultsListContainer,
    ability: 'results_test_group',
  },
  {
    type: 'Base',
    name: 'Group',
    exact: true,
    path: '/results/base/group/report/:testTag/:exerciseId/:studentId',
    component: ReportContainer,
    ability: 'results_test_group',
    hidden: true,
  },
  {
    type: 'Base',
    name: 'Unit',
    exact: true,
    path: '/results/base/unit',
    component: UnitBaseTestsResultsListContainer,
    ability: 'results_test_unit',
  },
  {
    type: 'Base',
    name: 'Unit',
    exact: true,
    path: '/results/base/unit/report/:testTag/:exerciseId/:studentId',
    component: ReportContainer,
    ability: 'results_test_unit',
    hidden: true,
  },
  {
    type: 'Base',
    name: 'School',
    exact: true,
    path: '/results/base/school',
    component: SchoolBaseTestsResultsListContainer,
    ability: 'results_test_school',
  },
  {
    type: 'Base',
    name: 'School',
    exact: true,
    path: '/results/base/school/report/:testTag/:exerciseId/:studentId',
    component: ReportContainer,
    ability: 'results_test_school',
    hidden: true,
  },
  {
    type: 'Base',
    name: 'Class',
    exact: true,
    path: '/results/base/class',
    component: ClassBaseTestsResultsListContainer,
    ability: 'results_test_class',
  },
  {
    type: 'Base',
    name: 'Class',
    exact: true,
    path: '/results/base/class/report/:testTag/:exerciseId/:studentId',
    component: ReportContainer,
    ability: 'results_test_class',
    hidden: true,
  },
  {
    type: 'Custom',
    name: 'Group',
    exact: true,
    path: '/results/custom/group',
    component: GroupCustomTestsResultsListContainer,
    ability: 'results_custom_group',
  },
  {
    type: 'Custom',
    name: 'Group',
    path: '/results/custom/:type/:id',
    component: AnswerSheetContainer,
    ability: 'results_custom_group',
    hidden: true,
  },
  {
    type: 'Custom',
    name: 'Unit',
    path: '/results/custom/unit',
    component: UnitCustomTestsResultsListContainer,
    ability: 'results_custom_unit',
  },
  {
    type: 'Custom',
    name: 'School',
    path: '/results/custom/school',
    component: SchoolCustomTestsResultsListContainer,
    ability: 'results_custom_school',
  },
  {
    type: 'Custom',
    name: 'Class',
    path: '/results/custom/class',
    component: ClassCustomTestsResultsListContainer,
    ability: 'results_custom_class',
  },
  {
    type: 'Enterprise',
    name: 'Enterprise',
    path: '/enterprise-enterprise-results',
    component: StudentPracticeResultsListContainer,
    hidden: true,
    ability: 'results_enterprise',
  },
  {
    type: 'Enterprise',
    name: 'Division',
    path: '/division-enterprise-results',
    component: StudentPracticeResultsListContainer,
    hidden: true,
    ability: 'results_enterprise_division',
  },
  {
    type: 'Enterprise',
    name: 'Unit',
    path: '/unit-enterprise-results',
    component: StudentPracticeResultsListContainer,
    hidden: true,
    ability: 'results_enterprise_unit',
  },
]);

export default ResultsMenu;
