import { Toast } from 'modules/Core/Common';
import i18n from 'i18next';

export const copyToClipBoard = async (textToCopy) => {
  try {
    await navigator.clipboard.writeText(textToCopy);
    Toast(i18n.t('success-messages.copied'), 'success');
  } catch (error) {
    Toast(
      i18n.t('errors.error-sorry-an-error-occurred-during.copy-clipboard'),
      'error'
    );
  }
};
