import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import { Tooltip, LabelWithBackground, Icon } from 'modules/Core/Common';

export const UTM = ({ utms }) => {
  if (!utms || !utms?.length) {
    return <Icon name="table-empty-data" style={{ margin: 'auto' }} />;
  }

  return (
    <Tooltip
      placement="bottom"
      overlay={utms.map((utm) => (
        <p key={utm?.id}>
          <span>{utm?.utm_type}: </span>
          <span>{utm?.value}</span>
        </p>
      ))}
      secondaryTooltip
      overlayInnerStyle={{
        backgroundColor: '#0075EA',
        borderRadius: '4px',
        padding: '8px 16px',
        fontSize: '1.6rem',
      }}
    >
      <LabelWithBackground
        label={i18n.t('common-words.view-details')}
        style={{ width: 'auto', whiteSpace: 'nowrap' }}
        secondary
      />
    </Tooltip>
  );
};

UTM.propTypes = {
  utms: PropTypes.array,
};
