import PropTypes from 'prop-types';

import { UserAvatar as UsersCommonAvatar } from 'modules/Users/Common';

export function UserAvatar({ user, showName, showCard, size = '24px' }) {
  return (
    <UsersCommonAvatar
      name={user?.name}
      email={user?.email}
      avatar={user?.avatar?.thumb?.url || null}
      size={size}
    >
      <UsersCommonAvatar.Photo />
      {showCard && <UsersCommonAvatar.Card />}
      {showName && <UsersCommonAvatar.Name />}
    </UsersCommonAvatar>
  );
}

UserAvatar.propTypes = {
  user: PropTypes.object,
  showName: PropTypes.bool,
  showCard: PropTypes.bool,
  size: PropTypes.string,
};
