import styled, { css } from 'styled-components';

const Input = styled.input`
  ${(props) => props.theme.config.typograph.default};
  height: ${(props) => props.theme.config.spacing.spacing_7};
  padding: 1rem 1.6rem;
  background-color: ${(props) => props.theme.config.colors.light};
  color: ${(props) => props.theme.config.colors.text_base};
  border: none;
  border-radius: 0.6rem;
  outline: none;
  border: 1px solid transparent;
  transition: 0.2s ease-in;

  &::placeholder {
    appearance: none;
    ${({ theme }) => theme.config.typograph.xsmall};
    color: ${({ theme }) => theme.config.colors.gray_400} !important;
    opacity: 1;
  }

  &:disabled,
  &::read-only {
    background-color: ${(props) => props.theme.config.colors.gray_200};
  }

  &:hover,
  &:focus {
    border-color: ${(props) => props.theme.config.colors.primary};
  }

  &:focus {
    & + span {
      top: -12px;
      font-size: 12px;
    }
  }

  ${(props) =>
    props.errors &&
    props.touched &&
    css`
      border-color: ${(props) => props.theme.config.colors.tertiary} !important;
    `}

  ${(props) =>
    props.success &&
    css`
      border-color: ${(props) => props.theme.config.colors.green} !important;
    `}

  ${(props) =>
    props.border &&
    css`
      border-color: ${(props) => props.theme.config.colors.gray_300} !important;
    `}

  ${(props) =>
    props.AppendIcon &&
    css`
      padding-right: 4.8rem;
    `}

  ${(props) =>
    props.PrependIcon &&
    css`
      padding-left: 4rem;
    `}

  ${(props) =>
    props.rounded &&
    css`
      border-radius: 3.2rem;
    `}

  ${(props) =>
    props.medium &&
    css`
      height: 4rem;
    `}
`;

const Container = styled.div`
  position: relative;

  svg {
    width: 1.6rem;
  }

  svg:not(.CheckIcon) {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1.6rem;
    height: 1.6rem;
    padding: 0 !important;

    &[data-type='append'] {
      right: 1.6rem;
    }
  }

  svg.CheckIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    right: ${(props) => props.theme.config.spacing.spacing_3};

    path {
      fill: ${(props) => props.theme.config.colors.green} !important;
    }
  }

  ${(props) =>
    props.PrependIcon &&
    css`
      svg {
        position: absolute;
        left: 1.6rem;
      }
    `};
`;

export const Label = styled.span`
  position: absolute;
  z-index: 1;
  color: ${({ theme }) => theme.config.colors.gray};
  font-size: 16px;
  line-height: 24px;
  left: 10px;
  top: 12px;
  padding: 0 6px;
  transition: ease-in 0.1s;
  pointer-events: none;

  &::after {
    content: '';
    width: 100%;
    position: absolute;
    height: 3px;
    background: ${({ theme }) => theme.config.colors.light};
    left: 50%;
    top: calc(50% + 1px);
    z-index: -1;
    transform: translate(-50%, -50%);
  }

  ${(props) =>
    props.hasValue &&
    css`
      top: -12px;
      font-size: 12px;
    `}
`;

export { Input, Container };
