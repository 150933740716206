import React from 'react';
import PropTypes from 'prop-types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { WidgetByType } from 'modules/Dashboard/Widgets/Common/WidgetByType';

import * as S from './WidgetsList.styles';

export const SortableWidgetContainerItem = ({
  widget,
  isDragging,
  ...props
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: widget?.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <S.WidgetContainer
      key={widget?.id}
      widgetSize={widget?.settings?.size}
      ref={setNodeRef}
      style={style}
      isDragging={isDragging}
      {...attributes}
      {...listeners}
      {...props}
    >
      <WidgetByType widget={widget} isMock />
    </S.WidgetContainer>
  );
};

SortableWidgetContainerItem.propTypes = {
  widget: PropTypes.object,
  isDragging: PropTypes.any,
};
