import styled, { css } from 'styled-components';

const hasItems = css`
  background: ${(props) => props.theme.config.colors.primary_200};
  border: none;
  color: ${(props) => props.theme.config.colors.primary};
`;

const zeroItems = css`
  background: ${(props) => props.theme.config.colors.light};
  border: 1px solid ${(props) => props.theme.config.colors.gray_200};
  color: ${(props) => props.theme.config.colors.text_base};
`;

const TooltipItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 4px;
  outline: none;
  margin: 0 auto;
  white-space: nowrap;
  min-width: 110px;
  min-height: 37px;
  ${(props) => (props.hasItems ? hasItems : zeroItems)}
`;

const Text = styled.div`
  ${(props) => props.theme.config.typograph.xsmall};

  p::selection {
    background: ${(props) => props.theme.config.colors.gray_400};
  }

  p {
    display: flex;
    align-items: center;

    & + p {
      ${(props) => props.hasIcon && 'margin-top: .6rem;'}
    }

    svg {
      width: 1.4rem;
      margin-left: 0.6rem;

      path {
        fill: ${(props) => props.theme.config.colors.light};
      }
    }
  }
`;

export { TooltipItem, Text };
