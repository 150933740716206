import { lazy } from 'react';
import { menuBuilder } from 'services/Routes.service';
import { LicenseIcon } from '@edusynch/edusynch-svg-icons';

const LicensesList = lazy(() =>
  import('modules/Licenses/LicensesList/LicensesList.container')
);

const LicenseHistoryList = lazy(() =>
  import(
    'modules/LicenseHistory/LicenseHistoryList/LicenseHistoryList.container'
  )
);

const LicensesMenu = menuBuilder([
  {
    Icon: LicenseIcon,
    name: 'Licenses',
  },
]).addSubmenu([
  {
    type: '',
    name: 'Licenses',
    path: '/licenses',
    exact: true,
    component: LicensesList,
    ability: 'licenses',
  },
  {
    type: '',
    name: 'License History',
    path: '/license-history',
    exact: true,
    component: LicenseHistoryList,
    ability: 'licenses',
  },
]);

export default LicensesMenu;
