import { useState, useEffect } from 'react';

export const useToggleChild = ({ items, itemKey = 'id' }) => {
  const [toggleId, setToggleId] = useState(null);
  const [currentToggled, setCurrentToggled] = useState({});

  useEffect(() => {
    if (currentToggled && toggleId) updateToggled();
  }, [items]);

  const handleToggle = (id) => {
    if (id === toggleId) {
      setToggleId(null);
      setCurrentToggled({});
    } else {
      const currentSelected = items?.find((item) => item[itemKey] === id);
      setToggleId(id);
      setCurrentToggled(currentSelected);
    }
  };

  const updateToggled = () => {
    const currentSelected = items?.find((item) => item[itemKey] === toggleId);
    setCurrentToggled(currentSelected);
  };

  return {
    toggleId,
    currentToggled,
    handleToggle,
  };
};
