import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Icon, Skeleton } from 'modules/Core/Common';
import * as S from './UserAvatarInfo.styles';

const UserAvatarInfo = ({ avatar, loading, size, name, email, location }) => {
  if (loading) {
    return (
      <S.Container>
        <Skeleton type="round" />
        <S.InfoContainer>
          <Skeleton type="string" />
          <Skeleton type="string" />
          <Skeleton type="string" />
        </S.InfoContainer>
      </S.Container>
    );
  }

  return (
    <S.Container>
      <Avatar src={avatar} size={size} name={name} />
      <S.InfoContainer>
        <S.Span>{name}</S.Span>
        <S.Span>
          <Icon name="email" /> {email}
        </S.Span>
        {location && (
          <S.Span>
            <Icon name="world" /> {location}
          </S.Span>
        )}
      </S.InfoContainer>
    </S.Container>
  );
};

UserAvatarInfo.propTypes = {
  avatar: PropTypes.string,
  size: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  location: PropTypes.string,
  loading: PropTypes.bool,
};

export default UserAvatarInfo;
