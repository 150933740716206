import styled from 'styled-components';

export const HourSpan = styled.span`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.config.colors.gray_400};
  font-size: 12px;
  line-height: 14px;
  margin-top: ${(props) => props.theme.config.spacing.spacing_1};

  svg {
    padding-left: 0 !important;
  }
`;
