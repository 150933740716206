import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.config.colors.gray_200};
  padding: 1.6rem 2.4rem;

  h6 {
    ${(props) => props.theme.config.typograph.mico};
    color: ${(props) => props.theme.config.colors.gray};
    margin-bottom: 0.4rem;
  }

  p {
    ${(props) => props.theme.config.typograph.default};
    font-weight: bold;
    color: ${(props) => props.theme.config.colors.text_base};
  }
`;

export const Content = styled.div`
  padding: 1.6rem 2.4rem;
  min-height: 26rem;
`;

export const Footer = styled.div`
  border-top: 1px solid ${(props) => props.theme.config.colors.gray_200};
  padding: 1.6rem 2.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DangerZone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 36.5rem;
  text-align: center;
  position: relative;

  h2 {
    margin-bottom: 0.8rem;
    ${(props) => props.theme.config.typograph.quartenary};
    font-size: 2rem;
    color: ${(props) => props.theme.config.colors.text_base};
  }

  p {
    max-width: 23.2rem;
    ${(props) => props.theme.config.typograph.xsmall};
    margin-bottom: 4rem;
    color: ${(props) => props.theme.config.colors.gray};
  }
`;

export const CloseButton = styled.div`
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: 1.7rem;
  right: 2.4rem;
`;
