import React from 'react';
import PropTypes from 'prop-types';

import * as S from './TextArea.styles';

const TextArea = ({ dataCy, bordered, heightArea, ...props }) => {
  return (
    <S.TextArea
      data-cy={dataCy}
      heightArea={heightArea}
      bordered={bordered}
      {...props}
    />
  );
};

TextArea.propTypes = {
  dataCy: PropTypes.string,
  bordered: PropTypes.bool,
  heightArea: PropTypes.string,
};

export default TextArea;
