import { lazy } from 'react';
import { menuBuilder } from 'services/Routes.service';
import { OverallIcon } from '@edusynch/edusynch-svg-icons';

const OverallScoring = lazy(() =>
  import(
    'modules/OverallScoring/OverallScoringList/OverallScoringList.container'
  )
);
const OverallScoringEdit = lazy(() =>
  import('modules/OverallScoring/OverallScoringEdit')
);

const OverallMenu = menuBuilder([
  {
    Icon: OverallIcon,
    name: 'Overall Scoring',
    component: OverallScoring,
    exact: true,
    path: '/overall-scoring',
    ability: 'overall_scorings',
  },
  {
    type: 'overall',
    name: 'Overall Scoring',
    component: OverallScoringEdit,
    path: '/overall-scoring/:id',
    hidden: true,
    ability: 'overall_scorings',
  },
]).addSubmenu();

export default OverallMenu;
