import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.config.colors.gray_200};
  padding: 1.6rem 2.4rem;

  h6 {
    ${(props) => props.theme.config.typograph.mico};
    color: ${(props) => props.theme.config.colors.gray};
    margin-bottom: 0.4rem;
  }

  p {
    ${(props) => props.theme.config.typograph.default};
    font-weight: bold;
    color: ${(props) => props.theme.config.colors.text_base};
  }
`;

export const Content = styled.div`
  padding: 1.6rem 2.4rem;
  min-height: 26rem;
`;

export const UsersList = styled.div`
  max-height: 16rem;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    background: ${(props) => props.theme.config.colors.gray_100};
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.config.colors.gray_300};
    border-radius: 8px;
    width: 5px;
  }
`;

export const User = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > button.user {
    all: unset;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    flex: 1;
    cursor: pointer;

    img,
    div {
      width: 2.4rem;
      height: 2.4rem;
      min-width: 2.4rem;
    }

    span {
      ${(props) => props.theme.config.typograph.default};
      color: ${(props) => props.theme.config.colors.text_base};
    }
  }

  & + div {
    margin-top: 1.6rem;
  }

  &.active {
    background-color: ${(props) => props.theme.config.colors.primary_100};
    padding: 0.8rem;
    border-radius: 4px;
  }
`;

export const Footer = styled.div`
  border-top: 1px solid ${(props) => props.theme.config.colors.gray_200};
  padding: 1.6rem 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CloseButton = styled.div`
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: 1.7rem;
  right: 2.4rem;
`;

export const Empty = styled.div`
  ${(props) => props.theme.config.typograph.xsmall};
  color: ${(props) => props.theme.config.colors.gray_400};
  height: 16rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const EmptyMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;

  strong {
    ${(props) => props.theme.config.typograph.default};
    color: ${(props) => props.theme.config.colors.secondary};
    font-weight: bold;
  }

  span {
    ${(props) => props.theme.config.typograph.xsmall};
    color: ${(props) => props.theme.config.colors.gray_400};
  }
`;

export const LoadingContainer = styled.div`
  max-height: 16rem;
  overflow: hidden;

  .skeleton-container {
    span {
      height: 2.4rem;
    }

    & + .skeleton-container {
      margin-top: 1.6rem;
    }
  }
`;
