import React from 'react';
import PropTypes from 'prop-types';
import * as S from './FormGroup.styles';

const FormGroup = ({ children, ...props }) => (
  <S.FormGroup {...props}>{children}</S.FormGroup>
);

FormGroup.propTypes = {
  children: PropTypes.node,
};

export default FormGroup;
