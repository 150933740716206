import styled, { css } from 'styled-components';
import { device } from 'modules/Utils/Devices';
import { ZIndexes } from 'modules/Utils/ZIndexes';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: ${ZIndexes.overlay};
  }

  @media ${device.mobile} {
    width: 100%;
  }
`;

export const SearchContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  z-index: ${ZIndexes.overlay + 3};
  max-width: 100%;

  .edu-icon-search {
    cursor: pointer;
    padding: 2rem 1.2rem;
    transition: 0.2s ease-in;

    &:hover {
      &::before {
        color: ${(props) => props.theme.config.colors.gray_400};
      }
    }
  }

  svg {
    width: 1.4rem;

    path {
      fill: ${(props) => props.theme.config.colors.gray_400};
    }

    &:first-child {
      heihgt: 24px;
      position: absolute;
      right: ${(props) => (props.hasFilters ? '24px' : '16px')};
      z-index: ${ZIndexes.overlay + 1};
      ${({ hasFilters }) =>
        hasFilters &&
        css`
          border-left: 1px solid
            ${(props) => props.theme.config.colors.gray_200};
          padding: 0 0 0 1.8rem;
        `}
    }

    &#filters {
      position: absolute;
      right: 5.8rem;

      &:before {
        ${({ showFilters, isFilterActive }) =>
          (showFilters || isFilterActive) &&
          css`
            color: ${(props) => props.theme.config.colors.primary};
          `};
      }

      @media ${device.mobile} {
        right: 64px;
      }
    }
  }

  @media ${device.mobile} {
    width: 100%;
  }

  &.filters-dropdown {
    font-size: 4rem;
  }
`;
export const SearchInput = styled.input.attrs((props) => ({
  type: props.type || 'text',
}))`
  width: ${(props) =>
    props.hasFilters ? 'calc(370px - 104px)' : 'calc(370px - 48px)'};
  height: 40px;
  outline: none;
  border: none;
  border-radius: 42px;
  padding: ${(props) => (props.hasFilters ? '0 88px 0 16px' : '0 48px 0 16px')};
  font-size: 1.4rem;
  color: ${(props) => props.theme.config.colors.text_base};
`;
export const Filters = styled.div`
  position: absolute;
  top: 40px;
  right: 48px;
  z-index: ${ZIndexes.overlay + 4};
  background: ${(props) => props.theme.config.colors.light};
  width: 292px;
  padding: 16px;
  margin-top: ${(props) => props.theme.config.spacing.spacing_2};
  border-radius: 4px;
  align-self: center;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);

  p {
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: ${(props) => props.theme.config.spacing.spacing_2};
    color: ${(props) => props.theme.config.colors.text_base};
  }

  div + div {
    &:not(.actions) {
      margin-top: ${(props) => props.theme.config.spacing.spacing_3};
    }
  }

  // arrow-up
  &::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: ${(props) => props.theme.config.colors.light};
    position: absolute;
    right: 20px;
    top: -4px;
    transition: background-color 0.2s ease;
    transform: rotate(45deg);
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2.4rem;

  button {
    padding: 0;
    margin-top: 0;

    &:first-child {
      min-width: 85px;
      width: 85px;
      background: transparent;
      border: 1px solid ${(props) => props.theme.config.colors.gray_400};
      color: ${(props) => props.theme.config.colors.text_base};

      svg {
        path {
          fill: ${(props) => props.theme.config.colors.gray_400};
        }
      }
    }

    &:last-child {
      width: 120px;
    }
  }
`;
