import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import { useLocation } from 'react-router-dom';

import { Sidebar } from '../Sidebar';
import { Header } from '../Header';

const getMenuObjectByChildPath = (subMenus, location) => {
  const menuObject = find(subMenus, {
    submenu: {
      menus: [
        {
          routes: [
            {
              path: location,
            },
          ],
        },
      ],
    },
  });

  return menuObject;
};

const AppCommon = ({
  routes,
  opened,
  clicked,
  toggleOpenSidebar,
  toggleCloseSidebar,
  onMenuClick,
  onLogoutClick,
  user,
  whitelabelName,
  openedResponsive,
  toggleSidebarDeactivated,
  toggleResponsiveSidebarOpen,
  toggleResponsiveSidebarClose,
  ...props
}) => {
  const location = useLocation();
  const [hasSubmenu, setHasSubmenu] = useState(false);
  const [currentMenu, setCurrentMenu] = useState('');
  const [activeMenuName, setActiveMenuName] = useState('');
  const [openDashboardShortcut, setOpenDashboardShortcut] = useState(false);

  useEffect(
    () => {
      const menuObject = getMenuObjectByChildPath(routes, location.pathname);

      toggleCloseSidebar();

      if (menuObject) {
        setActiveMenuName(menuObject.name);
        setHasSubmenu(true);
        setOpenDashboardShortcut(false);
      } else {
        setActiveMenuName('');
        setHasSubmenu(false);
      }
    },
    location.pathname,
    clicked
  );

  const openSidebarAbsolute = useCallback(
    (event) => {
      const { name: dataName } = event.target.dataset;
      const menu = routes.find(({ name }) => name === dataName);
      setOpenDashboardShortcut(false);
      setCurrentMenu(menu);
      toggleOpenSidebar();
    },
    [routes]
  );

  const toggleFixedSidebarOnClick = useCallback(() => {
    const { pathname } = location;
    const subMenus = routes.filter(({ submenu }) => submenu);
    const menuObject = getMenuObjectByChildPath(subMenus, pathname);

    if (hasSubmenu && !openDashboardShortcut && clicked) {
      onMenuClick();
      setCurrentMenu('');
      setOpenDashboardShortcut(false);
    }

    if (menuObject) {
      onMenuClick();
      setCurrentMenu(menuObject);
    } else {
      setCurrentMenu('');
      toggleSidebarDeactivated();
      setOpenDashboardShortcut(false);
    }
  }, [location.pathname, hasSubmenu, clicked]);

  const toggleShortcutMenu = useCallback(() => {
    onMenuClick();

    if (opened && !openDashboardShortcut) {
      toggleCloseSidebar();
      setOpenDashboardShortcut(false);
    } else {
      setOpenDashboardShortcut(!openDashboardShortcut);
    }
  }, [opened, openDashboardShortcut]);

  const closeSidebarMouseLeave = useCallback(() => {
    const { pathname } = location;
    const subMenus = routes.filter(({ submenu }) => submenu);
    const menuObject = getMenuObjectByChildPath(subMenus, pathname);

    if (hasSubmenu) {
      setCurrentMenu(menuObject);
      toggleCloseSidebar();
    } else {
      toggleCloseSidebar();
    }

    if (!hasSubmenu && opened) setOpenDashboardShortcut(true);
  }, [hasSubmenu, opened]);

  return (
    <>
      <Sidebar
        routes={routes}
        opened={opened}
        clicked={clicked}
        setOpenDashboardShortcut={setOpenDashboardShortcut}
        openSidebarAbsolute={openSidebarAbsolute}
        currentMenu={currentMenu}
        openedResponsive={openedResponsive}
        activeMenuName={activeMenuName}
        toggleCloseSidebar={toggleCloseSidebar}
        toggleSidebarDeactivated={toggleSidebarDeactivated}
        openDashboardShortcut={openDashboardShortcut}
        closeSidebarMouseLeave={closeSidebarMouseLeave}
        toggleResponsiveSidebarOpen={toggleResponsiveSidebarOpen}
        toggleResponsiveSidebarClose={toggleResponsiveSidebarClose}
      />

      <Header
        opened={opened}
        user={user}
        hasSubmenu={hasSubmenu}
        onLogoutClick={onLogoutClick}
        whitelabelName={whitelabelName}
        openedResponsive={openedResponsive}
        toggleShortcutMenu={toggleShortcutMenu}
        onMenuClick={toggleFixedSidebarOnClick}
        toggleResponsiveSidebarOpen={toggleResponsiveSidebarOpen}
        toggleResponsiveSidebarClose={toggleResponsiveSidebarClose}
        {...props}
      />
    </>
  );
};

AppCommon.propTypes = {
  routes: PropTypes.array,
  user: PropTypes.object,
  opened: PropTypes.bool,
  clicked: PropTypes.bool,
  toggleOpenSidebar: PropTypes.func,
  toggleCloseSidebar: PropTypes.func,
  onMenuClick: PropTypes.func,
  onLogoutClick: PropTypes.func,
  whitelabelName: PropTypes.string,
  openedResponsive: PropTypes.bool,
  toggleResponsiveSidebarOpen: PropTypes.func,
  toggleResponsiveSidebarClose: PropTypes.func,
  toggleSidebarDeactivated: PropTypes.func,
};

export default AppCommon;
