import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useIntervalOption, useWidgets } from 'modules/Dashboard/Widgets/Hooks';
import { useEProctoringSessionsCount } from 'modules/Dashboard/Widgets/EProctoring/Hooks';

import { SessionsCount } from './SessionsCount.component';
import { UsersWidgetPlaceholder } from 'modules/Dashboard/Widgets/Users/Common';
import { WidgetSettings } from 'modules/Dashboard/Widgets/Common';
import { WidgetFilters } from 'modules/Dashboard/Widgets/EProctoring/SessionsCount/WidgetFilters';
import { MOCKED_DATA } from 'modules/Dashboard/Widgets/EProctoring/SessionsCount/SessionsCount.utils';
import { useNonInitialEffect } from 'modules/Core/Hooks';

export const SessionsCountContainer = ({ widget, isMock }) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [previousUserFilter, setPreviousUserFilter] = useState(null);
  const [searchUser, setSearchUser] = useState('');

  const { intervalOption, setIntervalOption } = useIntervalOption({
    defaultOption: widget?.settings?.default_option || '7',
  });

  const {
    fetchEProctoringSessionsCount,
    eproctoringSessionsCount,
    fetchStudentsList,
    users,
  } = useEProctoringSessionsCount({
    widgetType: widget?.type,
  });
  const { saveWidgetSettings, removeActiveWidget } = useWidgets();

  const formattedUsers = useMemo(() => {
    if (previousUserFilter) {
      const isPreviousUserFilterInNewUsersList = users?.data.find(
        (user) => +user.id === previousUserFilter.id
      );

      return isPreviousUserFilterInNewUsersList
        ? users?.data
        : [...users.data, previousUserFilter];
    } else {
      return users?.data;
    }
  }, [users?.data]);

  useEffect(() => {
    if (isMock) return;

    fetchEProctoringSessionsCount(intervalOption, selectedUserId);
  }, []);

  useNonInitialEffect(() => {
    if (isMock || users.loading || !searchUser) return;

    fetchStudentsList(searchUser);
  }, [searchUser]);

  const handleChangeIntervalOption = (option) => {
    if (isMock) return;

    setIntervalOption(option);
    fetchEProctoringSessionsCount(option);
  };

  const handleSearchUsers = (value) => {
    if (isMock) return;

    if (selectedUserId) {
      const previousUser = users?.data?.find(
        (user) => +user.id === +selectedUserId
      );

      setPreviousUserFilter(previousUser);
    }

    setSearchUser(value);
  };

  const handleSaveFilter = (userId) => {
    if (isMock) return;

    setSelectedUserId(userId);
    fetchEProctoringSessionsCount(intervalOption, userId);
  };

  if (eproctoringSessionsCount?.loading) {
    return <UsersWidgetPlaceholder widget={widget} />;
  }

  if (isSettingsOpen) {
    return (
      <WidgetSettings
        widget={widget}
        onSave={saveWidgetSettings}
        onClose={() => setIsSettingsOpen(false)}
        onRemoveWidget={removeActiveWidget}
      />
    );
  }

  if (isFiltersOpen) {
    return (
      <WidgetFilters
        widget={widget}
        onSave={({ userId }) => handleSaveFilter(userId)}
        defaultUserId={selectedUserId}
        defaultSearch={searchUser}
        isLoading={users?.loading}
        users={formattedUsers}
        onClose={() => setIsFiltersOpen(false)}
        onSearchChange={handleSearchUsers}
      />
    );
  }

  return (
    <SessionsCount
      widget={widget}
      data={isMock ? MOCKED_DATA : eproctoringSessionsCount?.data}
      intervalOption={intervalOption}
      onChangeIntervalOption={handleChangeIntervalOption}
      onOpenSettings={() => setIsSettingsOpen(true)}
      onOpenFilter={() => setIsFiltersOpen(true)}
      hasFiltersActive={!!selectedUserId}
      isMock={isMock}
    />
  );
};

SessionsCountContainer.propTypes = {
  widget: PropTypes.object,
  isMock: PropTypes.bool,
};
