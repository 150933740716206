import React from 'react';
import PropTypes from 'prop-types';

import { Skeleton } from 'modules/Core/Common';

import * as S from './Filters.styles';

export const FiltersPlaceholder = ({ filtersLength = 1 }) => {
  return (
    <S.SkeletonContainer>
      <Skeleton
        type="string"
        style={{ width: '5.6rem', marginRight: '1.6rem' }}
      />
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.8rem' }}>
        {[...Array(filtersLength + 1).keys()].map((n) => (
          <Skeleton key={n} type="large" style={{ width: '11rem' }} />
        ))}
      </div>
    </S.SkeletonContainer>
  );
};

FiltersPlaceholder.propTypes = {
  filtersLength: PropTypes.number,
};
