import React from 'react';
import PropTypes from 'prop-types';
import * as S from './IconRoundBox.styles';

const IconRoundBox = ({ children, ...props }) => (
  <S.IconRoundBox {...props}>{children}</S.IconRoundBox>
);

IconRoundBox.propTypes = {
  children: PropTypes.element,
};

export default IconRoundBox;
