import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Selectors, Actions, Async } from 'modules/Languages/LanguagesSlice';

export const useLanguages = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(Selectors.fetchListData);

  useEffect(() => {
    if (!loading) fetchLanguages();
  }, []);

  useEffect(() => {
    return () => dispatch(Actions.cleanState());
  }, [location.pathname]);

  const fetchLanguages = useCallback(
    () => dispatch(Async.fetchLanguagesList()),
    []
  );

  return { data, loading };
};
