import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  WHITELABELS_URL,
  WHITELABEL_CREATE_URL,
  WHITELABEL_UPDATE_URL,
  WHITELABEL_DELETE_URL,
  WHITELABEL_DELETE_IMAGE_URL,
  WHITELABELS_INSTITUTIONS_URL,
  WHITELABEL_BY_ID_URL,
  WHITELABEL_GENERATE_API_KEY_URL,
  CUSTOM_TEST_BY_WHITELABEL_URL,
} from 'modules/Api/Routes';
import { defaultRequest } from 'modules/Utils/DefaultRequest';
import { getISOStringFromStringDate } from 'modules/Utils';
import { WHITELABEL_INITIAL_STATE } from 'modules/Whitelabel/Utils/Whitelabel.utils';

let cancelToken;

const initialState = {
  loading: false,
  error: null,
  data: {
    page: 1,
    perPage: 10,
    search: '',
    typeWhitelabel: null,
    institution: null,
    startDate: null,
    endDate: null,
    sort: {
      sortType: 'DESC',
      sortBy: 'created_at',
    },
  },
  institutions: {
    data: [],
    loading: false,
  },
  customTests: {
    data: [],
    loading: false,
  },
  editWhitelabel: {
    data: WHITELABEL_INITIAL_STATE,
    images: null,
    loading: false,
  },
};

const whitelabelSlice = createSlice({
  name: 'whitelabels',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    requestWhitelabel: (state) => {
      state.loading = true;
      state.error = null;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    changeWhitelabelField: (state, action) => {
      state.editWhitelabel.data[action.payload.key] = action.payload.value;
    },
    changeWhitelabelThemeField: (state, action) => {
      state.editWhitelabel.data.whitelabel_theme[action.payload.key] =
        action.payload.value;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    /**
     * receive a success whitelabel list response
     */
    receiveWhitelabeslList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        whitelabels: action.payload.whitelabels,
        total: action.payload.total_items,
      };
    },
    requestInstitutions: (state) => {
      state.institutions = {
        ...state.institutions,
        loading: true,
      };
    },
    receiveInstitutionsList: (state, action) => {
      state.institutions = {
        ...state.institutions,
        loading: false,
        data: action.payload.content,
      };
    },
    receiveInstitutionsError: (state, action) => {
      state.institutions = {
        ...state.institutions,
        loading: false,
      };
      state.error = action.payload;
    },
    requestCustomTests: (state) => {
      state.customTests = {
        ...state.customTests,
        loading: true,
      };
    },
    receiveCustomTestsList: (state, action) => {
      state.customTests = {
        ...state.customTests,
        loading: false,
        data: action.payload.content,
      };
    },
    receiveCustomTestsError: (state, action) => {
      state.customTests = {
        ...state.customTests,
        loading: false,
      };
      state.error = action.payload;
    },
    clearWhitelabeslList: (state) => {
      state.loading = false;
      state.data = {
        page: 1,
        perPage: 10,
        search: '',
        sort: {
          sortType: 'DESC',
          sortBy: 'created_at',
        },
      };
    },
    clearEditWhitelabel: (state) => {
      state.editWhitelabel.data = WHITELABEL_INITIAL_STATE;
    },
    requestEditWhitelabel: (state) => {
      state.editWhitelabel.loading = true;
    },
    receiveEditWhitelabel: (state, action) => {
      if (action.payload?.content) {
        Object.keys(WHITELABEL_INITIAL_STATE).forEach((key) => {
          switch (key) {
            case 'institution_id':
              state.editWhitelabel.data[key] =
                action.payload?.content?.institution?.id;
              break;
            case 'logo':
            case 'logo_certificate':
            case 'logo_signature':
            case 'open_graph_image':
            case 'favicon':
            case 'authentication_image_desktop':
            case 'authentication_image_iphone':
            case 'authentication_image_tablet':
            case 'ai_evaluator_avatar':
              state.editWhitelabel.data[key] =
                action.payload?.content?.[key]?.url;
              break;
            case 'whitelabel_theme':
              state.editWhitelabel.data[key] =
                action.payload?.content?.whitelabel_theme?.brand_colors ||
                action.payload?.content?.whitelabel_themes?.brand_colors ||
                WHITELABEL_INITIAL_STATE.whitelabel_theme;
              break;
            case 'page_setup':
              state.editWhitelabel.data[key] = {
                steps: action.payload?.content?.[key]?.steps || [],
              };
              break;
            default:
              state.editWhitelabel.data[key] = action.payload?.content?.[key];
              break;
          }
        });
      }
      state.editWhitelabel.loading = false;
    },
    receiveEditWhitelabelError: (state, action) => {
      state.editWhitelabel.loading = false;
      state.error = action.payload;
    },
    requestCreateWhitelabel: (state) => {
      state.editWhitelabel.loading = true;
    },
    receiveCreateWhitelabelError: (state, action) => {
      state.editWhitelabel.loading = false;
      state.error = action.payload;
    },
    requestUpdateWhitelabel: (state) => {
      state.editWhitelabel.loading = true;
    },
    receiveUpdateWhitelabel: (state, action) => {
      state.editWhitelabel.loading = false;

      state.editWhitelabel.data.ai_evaluator_avatar =
        action.payload?.ai_evaluator_avatar?.url;

      state.editWhitelabel.data.page_setup = action.payload?.page_setup;
    },
    receiveUpdateWhitelabelError: (state, action) => {
      state.editWhitelabel.loading = false;
      state.error = action.payload;
    },
    requestApiKey: (state) => {
      state.editWhitelabel.loading = true;
    },
    receiveApiKey: (state, action) => {
      if (action.payload?.content?.api_key) {
        state.editWhitelabel.data.api_key_auth = action.payload.content.api_key;
      }
      state.editWhitelabel.loading = false;
    },
    receiveApiKeyError: (state, action) => {
      state.editWhitelabel.loading = false;
      state.error = action.payload;
    },
    /**
     * receive an error response
     */
    receiveWhitelabelError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changewhitelabelsPage: (state, action) => {
      state.data.page = action.payload;
    },
    changeWhitelabelSearch: (state, action) => {
      state.data.search = action.payload;
    },
    updateWhitelabelOnList: (state, action) => {
      const updLicense = action.payload;
      const index = state.data.licenses.findIndex(
        (license) => license?.id === updLicense?.id
      );
      if (index !== -1) state.data.licenses.splice(index, 1, updLicense);
    },
    changeWhitelabelsFilters: (state, action) => {
      state.data.search = action.payload.search;
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
      state.data.typeWhitelabel = action.payload?.type_whitelabel || null;
      state.data.institution = action.payload?.institution || null;
      state.data.startDate = action.payload?.start_date || null;
      state.data.endDate = action.payload?.end_date || null;
    },
    clearWhitelabelFilters: (state) => {
      state.data.institution = null;
      state.data.typeWhitelabel = null;
      state.data.startDate = null;
      state.data.endDate = null;
      state.data.search = '';
      state.data.page = 1;
      state.data.perPage = 10;
      state.data.sort.sortType = 'DESC';
      state.data.sort.sortBy = 'created_at';
    },
  },
});

const Actions = whitelabelSlice.actions;

const Selectors = {
  fetchListData: (state) => state.whitelabels,
  WhitelabelLoading: ({ whitelabels: { loading } }) => ({ loading }),
};

const Async = {
  fetchWhitelabelsList: () => async (dispatch, getState) => {
    const {
      whitelabels: {
        data: {
          page,
          perPage,
          search,
          institution,
          typeWhitelabel,
          startDate,
          endDate,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestWhitelabel());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: WHITELABELS_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          institution: institution,
          type: typeWhitelabel?.toUpperCase(),
          start_date: startDate ? getISOStringFromStringDate(startDate) : null,
          end_date: endDate ? getISOStringFromStringDate(endDate) : null,
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receiveWhitelabeslList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveWhitelabelError(e.message);
      }
    }

    action && dispatch(action);
  },

  fetchInstitutionsListForSelect:
    ({ type }) =>
    async (dispatch) =>
      defaultRequest(
        dispatch,
        `${WHITELABELS_INSTITUTIONS_URL}?type=${type}`,
        'GET',
        Actions.requestInstitutions,
        Actions.receiveInstitutionsList,
        Actions.receiveInstitutionsError
      ),

  fetchWhitelabel:
    ({ id, onError }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestEditWhitelabel());

      try {
        const response = await request({
          method: 'GET',
          url: `${WHITELABEL_BY_ID_URL}?id=${id}`,
        });

        action = Actions.receiveEditWhitelabel(response.data);
      } catch (e) {
        onError(e);
        action = Actions.receiveEditWhitelabelError(e.message);
      }

      action && dispatch(action);
    },

  fetchCustomTestsList: () => async (dispatch, getState) => {
    const {
      whitelabels: {
        editWhitelabel: {
          data: { id },
        },
      },
    } = getState();

    let action;

    dispatch(Actions.requestCustomTests());

    try {
      const response = await request({
        method: 'GET',
        url: CUSTOM_TEST_BY_WHITELABEL_URL,
        params: {
          whitelabel_id: id,
        },
      });

      action = Actions.receiveCustomTestsList(response.data);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveCustomTestsError(e.message);
      }
    }

    action && dispatch(action);
  },

  generateApiKey: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      WHITELABEL_GENERATE_API_KEY_URL,
      'GET',
      Actions.requestApiKey,
      Actions.receiveApiKey,
      Actions.receiveApiKeyError
    ),

  deleteWhitelabel:
    ({ id, onSuccess, onError }) =>
    async () => {
      try {
        const response = await request({
          method: 'DELETE',
          url: WHITELABEL_DELETE_URL,
          params: {
            id,
          },
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  deleteWhitelabelImage:
    ({ id, type, onSuccess, onError }) =>
    async () => {
      try {
        const response = await request({
          method: 'PUT',
          url: WHITELABEL_DELETE_IMAGE_URL,
          data: {
            id,
            type,
          },
        });
        if (onSuccess) onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  createWhitelabel:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      let action;
      dispatch(Actions.requestCreateWhitelabel());

      const formData = new FormData();

      const notSendFields = ['pageSetup', 'page_setup', 'onboarding_locked'];

      Object.keys(data).forEach((key) => {
        if (data[key] !== null && !notSendFields.includes(key)) {
          if (['whitelabel_theme', 'whitelabel_custom_tests'].includes(key)) {
            return formData.set(key, JSON.stringify(data[key]));
          }
          formData.set(key, data[key]);
        }
      });

      const pageSetup =
        data?.pageSetup?.steps ||
        data?.pageSetup ||
        data?.page_setup?.steps ||
        data?.page_setup;

      if (pageSetup) {
        formData.set('page_setup', JSON.stringify({ steps: pageSetup }));
      }

      try {
        const response = await request({
          method: 'POST',
          url: WHITELABEL_CREATE_URL,
          data: formData,
        });

        action = Actions.receiveEditWhitelabel(response.data);
        onSuccess();

        const whitelabelId = response.data.content.id;
        window.location.href = `/whitelabel/edit?whitelabel_id=${whitelabelId}`;
      } catch (e) {
        onError(e);
        action = Actions.receiveCreateWhitelabelError(e.message);
      }

      action && dispatch(action);
    },

  updateWhitelabel:
    ({ data, onSuccess, onError }) =>
    async (dispatch, getState) => {
      const {
        whitelabels: {
          editWhitelabel: {
            data: { id },
          },
        },
      } = getState();

      const fileFields = [
        'logo',
        'logo_certificate',
        'logo_signature',
        'open_graph_image',
        'favicon',
        'authentication_image_desktop',
        'authentication_image_iphone',
        'authentication_image_tablet',
        'ai_evaluator_avatar',
      ];

      const notSendFields = ['pageSetup', 'page_setup', 'onboarding_locked'];
      const checkFields = ['ai_evaluator_name', 'ai_evaluator_description'];

      let action;
      dispatch(Actions.requestUpdateWhitelabel());

      const formData = new FormData();

      Object.keys(data).forEach((key) => {
        if (data[key] !== null) {
          const invalidFileFields =
            fileFields.includes(key) &&
            (typeof data[key] == 'string' || typeof data[key] == 'undefined');
          const invalidAiFields = checkFields.includes(key) && !data[key];

          if (
            invalidFileFields ||
            notSendFields.includes(key) ||
            invalidAiFields
          ) {
            return;
          }

          if (['whitelabel_theme', 'whitelabel_custom_tests'].includes(key)) {
            return formData.set(key, JSON.stringify(data[key]));
          }

          formData.set(key, data[key]);
        }
      });

      if (data?.pageSetup) {
        formData.set('page_setup', JSON.stringify({ steps: data.pageSetup }));
      }

      formData.set('id', id);

      try {
        const response = await request({
          method: 'PUT',
          url: WHITELABEL_UPDATE_URL,
          data: formData,
        });

        action = Actions.receiveUpdateWhitelabel(response.data.content);
        onSuccess();
      } catch (e) {
        onError(e);
        action = Actions.receiveUpdateWhitelabelError(e.message);
      }

      action && dispatch(action);
    },

  removeAvatar: ({ id, onSuccess, onError }) =>
    Async.deleteWhitelabelImage({
      id,
      type: 'AI_EVALUATOR',
      onSuccess,
      onError,
    }),
};

const { reducer } = whitelabelSlice;

export { reducer, Actions, Async, Selectors };
