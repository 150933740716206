import React from 'react';
import PropTypes from 'prop-types';

import * as S from './WidgetCardContent.styles';

export const WidgetCardContent = ({
  size = 'small',
  borderTop = false,
  children,
}) => {
  return (
    <S.Content size={size} borderTop={borderTop}>
      {children}
    </S.Content>
  );
};

WidgetCardContent.propTypes = {
  size: PropTypes.oneOf(['small', 'large']),
  borderTop: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
