import styled, { css } from 'styled-components';
import { Link as CustomLink } from 'react-router-dom';

import { paginationButtonDefaultStyles } from '../PaginationNextButton/PaginationNextButton.styles';

const Text = styled.span`
  ${(props) => props.theme.config.typograph.xsmall};
`;

const Link = styled((props) => <CustomLink {...props} />)`
  ${paginationButtonDefaultStyles};
  margin-right: 1.2rem;
  border-right: 1px solid ${(props) => props.theme.config.colors.gray_200};

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      cursor: default;
      opacity: 0.5;
    `};
`;

const Button = styled.button`
  ${paginationButtonDefaultStyles};
  margin-right: 1.2rem;
  border-right: 1px solid ${(props) => props.theme.config.colors.gray_200};

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      cursor: default;
      opacity: 0.4;
    `};
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${(props) => props.theme.config.spacing.spacing_2};
`;

export { Link, Button, Text, IconContainer };
