import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  UNITS_URL,
  UNITS_BY_GROUP_URL,
  SCHOOLS_BY_UNIT_URL,
  UNIT_BY_ID_URL,
  UNITS_CREATE_URL,
  UNITS_UPDATE_URL,
  UNITS_DELETE_URL,
  STUDENTS_BY_UNIT_URL,
} from 'modules/Api/Routes';
import { formDataFromObj } from 'modules/Api/RequestData';
import { createUnitRequestModel } from 'modules/Institutions/Units/UnitUtils';

let cancelToken;

const initialState = {
  loading: false,
  error: null,
  data: {
    units: [],
    page: 1,
    perPage: 10,
    search: '',
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
  unitStudents: {
    unitId: '',
    name: '',
    students: [],
    total: 0,
    page: 1,
    perPage: 10,
    search: '',
    showInfo: false,
    align: 'center',
    loading: false,
  },
  unitSchools: {
    unitId: '',
    name: '',
    schools: [],
    total: 0,
    page: 1,
    perPage: 10,
    search: '',
    showInfo: false,
    align: 'center',
    loading: false,
  },
  isDeleting: false,
};

const unitsSlice = createSlice({
  name: 'unit',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    requestUnit: (state) => {
      state.loading = true;
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsDeleting: (state, action) => {
      state.isDeleting = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    /**
     * receive a success unit list response
     */
    receiveUnitList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        units: action.payload.units,
        total: action.payload.total_items,
      };
    },
    receiveUnitListByGroup: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        units: action.payload,
        total: action.payload.length,
      };
    },
    clearUnitsList: (state) => {
      state.loading = false;
      state.data = {
        page: 1,
        perPage: 10,
        search: '',
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
      state.unitStudents = {
        unitId: '',
        name: '',
        students: [],
        total: 0,
        page: 1,
        perPage: 10,
        search: '',
        showInfo: false,
        align: 'center',
        loading: false,
      };
      state.unitSchools = {
        unitId: '',
        name: '',
        schools: [],
        total: 0,
        page: 1,
        perPage: 10,
        search: '',
        showInfo: false,
        align: 'center',
        loading: false,
      };
    },
    /**
     * receive an error response
     */
    receiveUnitError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changeUnitsPage: (state, action) => {
      state.data.page = action.payload;
    },
    changeUnitsSearch: (state, action) => {
      state.data.search = action.payload.search;
      state.data.page = 1;
    },
    changeUnitFilters: (state, action) => {
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
      state.data.search = action?.payload.search || '';
    },
    updateUnitOnList: (state, action) => {
      const updUnit = action.payload;
      const index = state.data.units.findIndex(
        (unit) => unit.id === updUnit.id
      );
      if (index !== -1) state.data.units.splice(index, 1, updUnit);
    },
    requestUnits: (state) => {
      state.unitStudents.loading = true;
      state.unitStudents.error = null;
    },
    receiveUnitsList: (state, action) => {
      state.unitStudents = {
        ...state.unitStudents,
        unitId: action.payload.id || state.unitStudents.unitId,
        name: action.payload.name || state.unitStudents.name,
        students: action.payload.students,
        total: action.payload.total_items,
        loading: false,
      };
    },
    receiveUnitsError: (state, action) => {
      state.unitStudents.loading = false;
      state.unitStudents.error = action.payload;
    },
    requestSchools: (state) => {
      state.unitSchools.loading = true;
      state.unitSchools.error = null;
    },
    receiveSchoolsList: (state, action) => {
      state.unitSchools = {
        ...state.unitSchools,
        unitId: action.payload?.unitId,
        schools: action.payload?.data?.schools,
        total: action.payload?.data?.total_items,
        loading: false,
      };
    },
    receiveSchoolsError: (state, action) => {
      state.unitSchools.loading = false;
      state.unitSchools.error = action.payload;
    },
    changeStudentsFormSearch: (state, action) => {
      state.unitStudents.search = action.payload.search;
      state.unitStudents.page = 1;
    },
    changeStudentsFormPage: (state, action) => {
      state.unitStudents.page = action.payload;
    },
    changeSchoolsFormSearch: (state, action) => {
      state.unitSchools.search = action.payload.search;
      state.unitSchools.page = 1;
    },
    changeSchoolsFormPage: (state, action) => {
      state.unitSchools.page = action.payload;
    },
    clearStudents: (state) => {
      state.unitStudents.search = '';
      state.unitStudents.page = 1;
    },
    clearSchools: (state) => {
      state.unitSchools.search = '';
      state.unitSchools.page = 1;
    },
  },
});

const Actions = unitsSlice.actions;

const Selectors = {
  fetchListData: (state) => state.unit,
  unitLoading: ({ unit: { loading } }) => ({ loading }),
};

const Async = {
  fetchUnitsList: () => async (dispatch, getState) => {
    const {
      unit: {
        data: {
          page,
          perPage,
          search,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestUnit());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: UNITS_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receiveUnitList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveUnitError(e.message);
      }
    }

    action && dispatch(action);
  },

  fetchUnitsListByGroupId:
    ({ groupId }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestUnit());

      try {
        const response = await request({
          method: 'GET',
          url: `${UNITS_BY_GROUP_URL}?group_id=${groupId}`,
        });

        action = Actions.receiveUnitListByGroup(response.data.content);
      } catch (e) {
        action = Actions.receiveUnitError(e.message);
      }

      dispatch(action);
    },

  fetchUnitStudents:
    ({ id, name } = {}) =>
    async (dispatch, getState) => {
      const {
        unit: {
          unitStudents: { page, perPage, search, unitId, name: unitName },
        },
      } = getState();

      let action;

      dispatch(Actions.requestUnits());

      try {
        const response = await request({
          method: 'GET',
          url: STUDENTS_BY_UNIT_URL,
          params: {
            page,
            paginates_per: perPage,
            unit_id: id || unitId,
            search,
          },
        });

        const data = {
          ...response.data.content,
          id: id || unitId,
          name: name || unitName,
        };

        action = Actions.receiveUnitsList(data);
      } catch (e) {
        action = Actions.receiveUnitsError(e.message);
      }

      dispatch(action);
    },

  fetchUnitSchools:
    ({ id } = {}) =>
    async (dispatch, getState) => {
      const {
        unit: {
          unitSchools: { page, perPage, search, unitId },
        },
      } = getState();

      let action;

      dispatch(Actions.requestSchools());

      try {
        const response = await request({
          method: 'GET',
          url: `${SCHOOLS_BY_UNIT_URL}?unit_id=${id || unitId}`,
          params: {
            page,
            paginates_per: perPage,
            search,
          },
        });

        action = Actions.receiveSchoolsList({
          data: response?.data?.content,
          unitId: id || unitId,
        });
      } catch (e) {
        action = Actions.receiveSchoolsError(e.message);
      }

      dispatch(action);
    },

  getUnitById:
    ({ id, onSuccess, onError }) =>
    async () => {
      try {
        const response = await request({
          method: 'GET',
          url: `${UNIT_BY_ID_URL}?id=${id}`,
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  createUnit:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestUnit());
      try {
        const parsedUnit = createUnitRequestModel(data);
        delete parsedUnit.id;

        const unitData = formDataFromObj(parsedUnit);

        const response = await request({
          method: 'POST',
          url: UNITS_CREATE_URL,
          data: unitData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveUnitError());
        onError(e);
      }
    },

  updateUnit:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestUnit());
      try {
        const parsedUnit = createUnitRequestModel(data);

        const unitData = formDataFromObj(parsedUnit);

        const response = await request({
          method: 'PUT',
          url: UNITS_UPDATE_URL,
          data: unitData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveUnitError());
        onError(e);
      }
    },

  deleteUnit:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.setIsDeleting(true));

      try {
        const response = await request({
          method: 'DELETE',
          url: `${UNITS_DELETE_URL}?id=${id}`,
        });

        onSuccess(response);
        dispatch(Actions.setIsDeleting(false));
      } catch (e) {
        onError(e);
        dispatch(Actions.setIsDeleting(false));
      }
    },
};

const reducer = unitsSlice.reducer;

export { reducer, Actions, Async, Selectors };
