import { createSlice } from '@reduxjs/toolkit';
import { request } from 'modules/Api/HttpClient';
import {
  VERSIONS_URL,
  VERSION_BY_ID_URL,
  VERSIONS_CURRENT_URL,
  VERSIONS_CREATE_URL,
  VERSIONS_UPDATE_URL,
  VERSIONS_DELETE_URL,
} from 'modules/Api/Routes';
import { formDataFromObj } from 'modules/Api/RequestData';
import { createVersionRequestModel } from 'modules/Softwares/Versions/VersionUtils';
import {
  getUTCDefaultStartDate,
  getUTCDefaultEndDate,
  getISOStringFromStringDate,
} from 'modules/Utils/Date';

const initialState = {
  loading: false,
  error: null,
  data: {
    versions: [],
    page: 1,
    perPage: 10,
    search: '',
    softwareId: '',
    softwareName: '',
    versionType: '',
    startDate: getUTCDefaultStartDate(),
    endDate: getUTCDefaultEndDate(),
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
  selectedVersion: {
    data: {},
    loading: false,
    error: null,
  },
  currentVersion: {
    data: {},
    loading: false,
    error: null,
  },
  isDeleting: false,
};

const versionsSlice = createSlice({
  name: 'versions',
  initialState,
  reducers: {
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    requestVersion: (state) => {
      state.loading = true;
      state.error = null;
    },
    requestSelectedVersion: (state, action) => {
      state.selectedVersion.loading = true;
      state.selectedVersion.error = null;
      state.selectedVersion.data = {
        id: action?.payload || null,
      };
    },
    requestCurrentVersion: (state) => {
      state.currentVersion.loading = true;
      state.currentVersion.error = null;
    },
    changeSearch: (state, action) => {
      state.data.search = action.payload;
    },
    changePage: (state, action) => {
      state.data.page = action.payload;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsDeleting: (state, action) => {
      state.isDeleting = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    /**
     * receive a success group list response
     */
    receiveVersionList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        versions: action.payload.versions,
        total: action.payload.total_items,
      };
    },
    receiveSelectedVersion: (state, action) => {
      state.selectedVersion = {
        ...state.selectedVersion,
        data: action.payload,
        loading: false,
        error: null,
      };
    },
    receiveCurrentVersion: (state, action) => {
      state.currentVersion = {
        ...state.currentVersion,
        data: action.payload,
        loading: false,
        error: null,
      };
    },
    clearVersionsList: (state) => {
      state.loading = false;
      state.data = {
        page: 1,
        perPage: 10,
        search: '',
        versions: [],
        softwareId: '',
        softwareName: '',
        versionType: '',
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
    },
    clearSelectedVersion: (state) => {
      state.selectedVersion = {
        ...state.selectedVersion,
        data: {
          external_software_id:
            state.selectedVersion?.data?.external_software_id || null,
        },
        loading: false,
        error: null,
      };
    },
    clearCurrentVersion: (state) => {
      state.currentVersion = {
        data: {},
        loading: false,
        error: null,
      };
    },
    /**
     * receive an error response
     */
    receiveVersionError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    receiveSelectedVersionError: (state, action) => {
      state.selectedVersion.loading = false;
      state.selectedVersion.error = action.payload;
    },
    receiveCurrentVersionError: (state, action) => {
      state.currentVersion.loading = false;
      state.currentVersion.error = action.payload;
    },
    changeVersionsFilters: (state, action) => {
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
      state.data.search = action?.payload.search || '';
      state.data.softwareId = action?.payload.software_id || '';
      state.data.softwareName = action?.payload.software_name || '';
      state.selectedVersion.data.external_software_id =
        action?.payload.software_id || null;
      state.data.versionType = action?.payload.version_type || '';
      state.data.startDate =
        action?.payload.start_date || getUTCDefaultStartDate();
      state.data.endDate = action?.payload.end_date || getUTCDefaultEndDate();
    },
    updateVersionOnList: (state, action) => {
      const updVersion = action.payload;
      const index = state.data.versions.findIndex(
        (version) => version.id === updVersion.id
      );
      if (index >= 0) state.data.versions.splice(index, 1, updVersion);
    },
  },
});

const Actions = versionsSlice.actions;

const Selectors = {
  fetchListData: (state) => state.versions,
  versionLoading: ({ versions: { loading } }) => ({
    loading,
  }),
};

const Async = {
  fetchVersionsList: () => async (dispatch, getState) => {
    const {
      versions: {
        data: {
          page,
          perPage,
          search,
          softwareId,
          versionType,
          startDate,
          endDate,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    if (!softwareId) return;

    let action;

    dispatch(Actions.requestVersion());

    try {
      const response = await request({
        method: 'GET',
        url: VERSIONS_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          external_software_id: softwareId,
          version_type: ['', 'all', null].includes(versionType)
            ? undefined
            : versionType,
          start_date: getISOStringFromStringDate(startDate),
          end_date: getISOStringFromStringDate(endDate),
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receiveVersionList(response.data.content);
    } catch (e) {
      action = Actions.receiveVersionError(e.message);
    }

    dispatch(action);
  },

  fetchVersionsListByExternalSoftwareIdForSelect:
    ({ externalSoftwareId }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestVersion());

      try {
        const response = await request({
          method: 'GET',
          url: VERSIONS_URL,
          params: {
            external_software_id: externalSoftwareId,
          },
        });

        action = Actions.receiveVersionList(response.data.content);
      } catch (e) {
        action = Actions.receiveVersionError(e.message);
      }

      dispatch(action);
    },

  getVersionById:
    ({ id }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestSelectedVersion(id));

      try {
        const response = await request({
          method: 'GET',
          url: `${VERSION_BY_ID_URL}?id=${id}`,
        });

        action = Actions.receiveSelectedVersion(response.data.content);
      } catch (e) {
        action = Actions.receiveSelectedVersionError(e.message);
      }

      dispatch(action);
    },

  getCurrentVersionBySoftwareId:
    ({ softwareId }) =>
    async (dispatch) => {
      let action;

      try {
        const response = await request({
          method: 'GET',
          url: `${VERSIONS_CURRENT_URL}?external_software_id=${softwareId}`,
        });

        action = Actions.receiveCurrentVersion(response.data.content);
      } catch (e) {
        action = Actions.receiveCurrentVersionError(e.message);
      }

      dispatch(action);
    },

  createVersion:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestVersion());
      try {
        const parsedVersion = createVersionRequestModel(data);
        delete parsedVersion.id;

        const versionData = formDataFromObj(parsedVersion);

        const response = await request({
          method: 'POST',
          url: VERSIONS_CREATE_URL,
          data: versionData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveVersionError());
        onError(e);
      }
    },

  updateVersion:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestVersion());
      try {
        const parsedVersion = createVersionRequestModel(data);

        const versionData = formDataFromObj(parsedVersion);

        const response = await request({
          method: 'PUT',
          url: VERSIONS_UPDATE_URL,
          data: versionData,
        });

        const updatedVersion = response.data.content;
        dispatch(Actions.updateVersionOnList(updatedVersion));
        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveVersionError());
        onError(e);
      }
    },

  updateCurrentVersion:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestSelectedVersion(data?.id));

      try {
        const parsedVersion = createVersionRequestModel(data);

        const versionData = formDataFromObj(parsedVersion);

        const response = await request({
          method: 'PUT',
          url: VERSIONS_UPDATE_URL,
          data: versionData,
        });

        const updatedVersion = response.data.content;

        dispatch(Actions.updateVersionOnList(updatedVersion));
        dispatch(Actions.receiveSelectedVersion());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveSelectedVersionError());
        onError(e);
      }
    },

  deleteVersion:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.setIsDeleting(true));

      try {
        const response = await request({
          method: 'DELETE',
          url: `${VERSIONS_DELETE_URL}?id=${id}`,
        });

        onSuccess(response);
        dispatch(Actions.setIsDeleting(false));
      } catch (e) {
        onError(e);
        dispatch(Actions.setIsDeleting(false));
      }
    },
};

const reducer = versionsSlice.reducer;

export { reducer, Actions, Async, Selectors };
