import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  CUSTOM_TESTS_URL,
  CUSTOM_TEST_BY_ID_URL,
  CUSTOM_TESTS_CREATE_URL,
  CUSTOM_TESTS_UPDATE_URL,
  CUSTOM_TESTS_DELETE_URL,
  BASE_TESTS_URL,
  WHITELABELS_URL,
  SETTINGS_REMOVE_AVATAR_URL,
} from 'modules/Api/Routes';
import { formDataFromObj } from 'modules/Api/RequestData';
import { defaultRequest } from 'modules/Utils/DefaultRequest';

let cancelToken;

const initialState = {
  loading: false,
  error: null,
  data: {
    page: 1,
    perPage: 10,
    search: '',
    sort: {
      sortType: 'ASC',
      sortBy: 'name',
    },
  },
  tests: {
    data: [],
    loading: false,
  },
  whitelabels: {
    data: [],
    loading: false,
  },
  isDeleting: false,
};

const testsSlice = createSlice({
  name: 'testsCustom',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    requestTests: (state) => {
      state.loading = true;
      state.error = null;
    },
    requestBaseTests: (state) => {
      state.tests = {
        ...state.tests,
        loading: true,
      };
    },
    requestWhitelabels: (state) => {
      state.whitelabels = {
        ...state.whitelabels,
        loading: true,
      };
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsDeleting: (state, action) => {
      state.isDeleting = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    /**
     * receive a success simulationsBase list response
     */
    receiveTestsList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        testsCustom: action.payload.custom_tests,
        total: action.payload.total_items,
      };
    },
    updateCustomTest: (state, action) => {
      state.loading = false;
      const index = state.data.testsCustom.findIndex(
        (item) => item.id === action.payload.id
      );

      if (typeof index === 'number') {
        state.data.testsCustom[index] = action.payload;
      }
    },
    receiveBaseTestsList: (state, action) => {
      state.tests = {
        ...state.tests,
        data: action.payload.content.tests,
        loading: false,
      };
    },
    receiveWhitelabelList: (state, action) => {
      state.whitelabels = {
        ...state.whitelabels,
        data: action.payload.content.whitelabels,
        loading: false,
      };
    },
    clearTestsList: (state) => {
      state.loading = false;
      state.data = {
        page: 1,
        perPage: 10,
        search: '',
        sort: {
          sortType: 'ASC',
          sortBy: 'name',
        },
      };
    },
    /**
     * receive an error response
     */
    receiveTestsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changeTestsPage: (state, action) => {
      state.data.page = action.payload;
    },
    changeTestsSearch: (state, action) => {
      state.data.search = action.payload.search;
      state.data.page = 1;
    },
    changeTestsFilters: (state, action) => {
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
      state.data.search = action?.payload.search || '';
    },
    clearTestsFilters: (state) => {
      state.data.search = '';
      state.data.page = 1;
      state.data.perPage = 10;
      state.data.sort.sortType = 'ASC';
      state.data.sort.sortBy = 'name';
    },
    updateTestsObjOnList: (state, action) => {
      const updOverallObj = action.payload;
      const index = state.data.tests.findIndex(
        (tests) => tests.id === updOverallObj.id
      );
      if (index !== -1) state.data.tests.splice(index, 1, updOverallObj);
    },
  },
});

const Actions = testsSlice.actions;

const Selectors = {
  fetchListData: (state) => state.testsCustom,
  testsLoading: ({ testsCustom: { loading } }) => ({ loading }),
};

const Async = {
  fetchCustomTestsList: () => async (dispatch, getState) => {
    const {
      testsCustom: {
        data: {
          page,
          perPage,
          search,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestTests());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: CUSTOM_TESTS_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receiveTestsList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveTestsError(e.message);
      }
    }

    action && dispatch(action);
  },

  getTestsCustomById:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestTests());

      try {
        const response = await request({
          method: 'GET',
          url: `${CUSTOM_TEST_BY_ID_URL}?id=${id}`,
        });

        action = Actions.receiveRequestSuccess();
        onSuccess(response);
      } catch (e) {
        action = Actions.setLoading(false);
        onError(e);
      }

      dispatch(action);
    },

  deleteCustomTest:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.setIsDeleting(true));

      try {
        const response = await request({
          method: 'DELETE',
          url: `${CUSTOM_TESTS_DELETE_URL}?id=${id}`,
        });

        onSuccess(response);
        dispatch(Actions.setIsDeleting(false));
      } catch (e) {
        onError(e);
        dispatch(Actions.setIsDeleting(false));
      }
    },

  removeLogo:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      try {
        const data = formDataFromObj({ id });

        const response = await request({
          method: 'PUT',
          url: SETTINGS_REMOVE_AVATAR_URL, //falta adicionar o endpoint correto
          data,
        });
        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  createCustomTest:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestTests());
      try {
        if (!data.logo_test) delete data.logo_test;
        const baseTestData = formDataFromObj(data);

        const response = await request({
          method: 'POST',
          url: CUSTOM_TESTS_CREATE_URL,
          data: baseTestData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveTestsError());
        onError(e);
      }
    },

  updateCustomTest:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestTests());
      try {
        if (!(data.logo_test instanceof File)) delete data.logo_test;
        const baseTestData = formDataFromObj(data);

        const response = await request({
          method: 'PUT',
          url: CUSTOM_TESTS_UPDATE_URL,
          data: baseTestData,
        });

        dispatch(Actions.updateCustomTest(response.data.content));
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveTestsError());
        onError(e);
      }
    },

  fetchTestsList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      `${BASE_TESTS_URL}?sort=ASC&sort_by=name`,
      'GET',
      Actions.requestBaseTests,
      Actions.receiveBaseTestsList,
      Actions.receiveTestsError
    ),

  fetchWhitelabelsList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      WHITELABELS_URL,
      'GET',
      Actions.requestWhitelabels,
      Actions.receiveWhitelabelList,
      Actions.receiveTestsError
    ),
};

const { reducer } = testsSlice;

export { reducer, Actions, Async, Selectors };
