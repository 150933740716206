import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { RCTooltip } from 'modules/Core/Common';
import * as S from './SidebarLink.style';

const SidebarLink = ({ onClick, isDesktop, path, disabled, Icon, name }) => {
  const translatedName = i18n.t(
    `sidebar.${name?.replaceAll(' ', '-').toLowerCase()}`
  );

  return (
    <S.MenuItem
      onClick={onClick}
      to={path}
      disabled={disabled}
      data-tip={translatedName}
    >
      <S.SidebarLink>
        <Icon color="#0075ea" />
        {isDesktop && <RCTooltip offset={{ right: 20 }} />}
      </S.SidebarLink>
      {!isDesktop && translatedName}
    </S.MenuItem>
  );
};

SidebarLink.propTypes = {
  onClick: PropTypes.func,
  Icon: PropTypes.func,
  name: PropTypes.string,
  path: PropTypes.string,
  isDesktop: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default SidebarLink;
