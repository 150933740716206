import styled from 'styled-components';
import { device } from 'modules/Utils';

export const Footer = styled.footer`
  position: absolute;
  text-align: center;
  ${(props) => props.theme.config.typograph.xsmall};
  bottom: ${(props) =>
    props.hasIdentifierEnv ? '7rem' : props.theme.config.spacing.spacing_4};
  color: ${(props) => props.theme.config.colors.text_base};
  left: calc(50% - 94px);
  z-index: 1;

  @media ${device.mobile} {
    left: 50%;
    transform: translate(-50%, 0);
    min-width: 277px;
  }
`;
