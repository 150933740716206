import {
  lockDomainsFromApi,
  formatDomainsToApi,
} from 'modules/Softwares/Integrations/IntegrationUtils';

export const createProctoringSettingsModel = (proctoringSettings = {}) => {
  return {
    id: proctoringSettings?.id || null,
    name: proctoringSettings?.name || '',
    groupRadio: 'existent',
    group: {
      id: proctoringSettings?.group?.id || '',
      name: proctoringSettings?.group?.name || '',
    },
    externalSoftwareRadio: 'existent',
    externalSoftware: {
      id:
        proctoringSettings?.external_software?.id ||
        proctoringSettings?.external_software_id ||
        '',
      name: proctoringSettings?.external_software?.title || '',
      url: proctoringSettings?.external_software?.url || '',
    },
    versionRadio: 'existent',
    version: {
      id:
        proctoringSettings?.version?.id || proctoringSettings?.version_id || '',
      version: proctoringSettings?.version_id
        ? '1'
        : proctoringSettings?.version?.version || '',
      type: proctoringSettings?.version_id
        ? '1'
        : proctoringSettings?.version?.type || '',
    },
    domains: lockDomainsFromApi(
      proctoringSettings?.external_application_domains
    ),
  };
};

export const createProctoringSettingsRequestModel = (
  proctoringSettings = {}
) => {
  return {
    id: proctoringSettings.id,
    name: proctoringSettings.name,
    group_id: proctoringSettings.group.id,
    external_software_id: proctoringSettings.externalSoftware.id,
    version_id: proctoringSettings.version.id,
    domains: formatDomainsToApi(proctoringSettings.domains),
  };
};
