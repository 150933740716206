import { getEnabledTestFieldNameByTestTag } from './index';

export const getEnabledTestsFromStudent = (student = {}, tests = []) => {
  const studentEnabledCustomTests =
    student?.custom_tests
      ?.filter((customTest) => customTest.enable_test)
      .map((customTest) => ({
        ...customTest,
        isCustomTest: true,
      })) || [];

  const studentEnabledBaseTests = tests.filter((test) => {
    const testField = getEnabledTestFieldNameByTestTag(test?.tag, false);

    return !!student?.[testField];
  });

  return [...studentEnabledBaseTests, ...studentEnabledCustomTests];
};
