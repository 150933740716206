import React from 'react';
import PropTypes from 'prop-types';

import { Card } from 'modules/Core/Common';

export const WidgetCard = ({ children }) => {
  return (
    <Card padding="2.4rem" style={{ height: '100%' }}>
      {children}
    </Card>
  );
};

WidgetCard.propTypes = {
  children: PropTypes.node,
};
