import styled from 'styled-components';

const Table = styled.table`
  position: relative;
  z-index: 0;
  border-collapse: separate;
  border-spacing: 0 0.4rem;
  width: 100%;

  thead tr {
    ${(props) => props.theme.config.typograph.xsmall};
    color: ${(props) => props.theme.config.colors.text_base};
    text-align: left;
  }

  thead th:last-child {
    text-align: center;

    div {
      justify-content: center;
    }
  }

  td {
    ${(props) => props.theme.config.typograph.xsmall};
    padding: ${(props) =>
      props.tdSpacing ? props.tdSpacing : '1.4rem 2.4rem'};

    &:first-child {
      font-weight: ${(props) => (props.firstTrIsBold ? '700' : '400')};
    }

    &:last-child {
      text-align: center;
      width: 70px;
    }
  }

  th {
    padding: ${(props) => (props.thSpacing ? props.thSpacing : '1rem 2.4rem')};
  }

  td:first-child {
    width: ${(props) => props.firstItemSize || '28rem'};
    border-top-left-radius: 0.6rem;
    border-bottom-left-radius: 0.6rem;
  }

  td:last-child {
    border-top-right-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
    padding: 1.4rem 1.6rem;

    > div {
      justify-content: flex-end;
    }

    > div {
      padding: 0;
    }
  }
`;

export { Table };
