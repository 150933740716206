import styled, { css } from 'styled-components';
import { device } from 'modules/Utils';

export const Container = styled.div`
  position: relative;

  > button {
    position: absolute;
    top: 0;
    right: 2.4rem;
    z-index: 2;
  }

  .MuiCalendarPicker-root .MuiPickersDay-root {
    border-radius: 50%;

    &.highlight {
      background-color: ${(props) => props.theme.config.colors.primary_200};
    }
  }

  .MuiCalendarPicker-root span.MuiTypography-caption {
    background-color: unset;
  }

  .MuiCalendarPicker-root div[role='presentation'] {
    border: none;
    ${(props) => props.theme.config.typograph.default};
    font-weight: bold;
  }

  .MuiCalendarPicker-viewTransitionContainer {
    scrollbar-color: ${(props) => props.theme.config.colors.gray_200};

    &::-webkit-scrollbar {
      width: 0.4rem;
    }

    &::-webkit-scrollbar-track {
      background: ${(props) => props.theme.config.colors.gray_100};
    }

    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.config.colors.gray_300};
      border-radius: 0.8rem;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${(props) => props.theme.config.colors.gray};
    }
  }

  .MuiPickersToolbar-root {
    display: none !important;
  }

  .MuiTabs-root {
    margin: 0 4.4rem;
  }

  .MuiPickerStaticWrapper-root {
    position: relative;
  }

  .MuiDialogActions-root {
    display: none;
  }

  .MuiTabs-indicator {
    display: none;
  }

  .MuiTabs-root {
    box-shadow: none;
  }

  .MuiTabs-flexContainer {
    display: block;
    text-align: center;

    button {
      padding: 0.8rem;
      min-width: 3.2rem;
      min-height: 3.2rem;
      border-radius: 0.4rem;

      &.Mui-selected {
        background-color: ${(props) => props.theme.config.colors.primary_100};
      }

      & + button {
        margin-left: 2.4rem;
      }
    }
  }
`;

export const Header = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.config.colors.gray_200};
  margin-top: 1.6rem;
  margin-bottom: 3.2rem;

  h2 {
    ${(props) => props.theme.config.typograph.default};
    color: ${(props) => props.theme.config.colors.text_base};
    font-weight: bold;
    margin-bottom: 1.6rem;
    margin-left: 2.4rem;
  }

  @media ${device.mobile} {
    margin-bottom: 1.6rem;
  }
`;

export const DateInputs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2.4rem;

  > span {
    display: inline-block;
    margin: 0 2.4rem;
    color: ${(props) => props.theme.config.colors.gray};

    @media ${device.mobile} {
      display: none;
    }
  }

  input[type='date'],
  input[type='datetime-local'] {
    color: ${(props) => props.theme.config.colors.text_base};

    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }
  }

  svg.CalendarIcon {
    margin-right: 0.8rem;

    path {
      fill: ${(props) => props.theme.config.colors.gray_400};
    }
  }

  .MuiOutlinedInput-input {
    font-size: 1.4rem;
    color: ${(props) => props.theme.config.colors.text_base};
  }

  fieldset {
    border-color: ${(props) => props.theme.config.colors.gray_300} !important;
    border-width: 1px !important;
  }

  .MuiInputAdornment-positionEnd {
    display: none;
  }

  @media ${device.mobile} {
    margin-top: 1.6rem;
    gap: 0.8rem;
    padding: 0 0.8rem;
    flex-wrap: wrap;
    flex-direction: column;
  }
`;

export const Types = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1.6rem;
`;

export const Content = styled.div`
  ${({ isScrollable }) =>
    isScrollable &&
    css`
      max-height: 65vh;
      overflow-y: auto;
      overflow-x: hidden;

      @media ${device.mobile} {
        max-height: 55vh;

        @media (max-height: 550px) {
          max-height: 50vh;
        }
      }
    `};
`;

export const Footer = styled.div`
  border-top: 1px solid ${(props) => props.theme.config.colors.gray_300};
  padding: 2.4rem 0rem;
  display: flex;
  gap: 2.4rem;
  justify-content: center;

  @media ${device.mobile} {
    padding: 1.6rem 0rem;
  }
`;
