import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Selectors } from 'modules/Auth/AuthSlice';
import { convertUTCDateToUserTimezone } from 'modules/Utils/Date';

export const useDate = (rawDate = new Date()) => {
  const { user } = useSelector(Selectors.selectAuthData);
  const [date, setDate] = useState(rawDate);

  useEffect(
    () => setDate(convertUTCDateToUserTimezone(rawDate, user?.timezone)),
    []
  );

  return { date };
};
