import styled from 'styled-components';

export const Container = styled.div`
  width: 500px;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const ModalHeader = styled(Flex)`
  flex-direction: column;
  text-align: center;
  padding: 5rem 5rem 4rem;
  color: ${(props) => props.theme.config.colors.text_base};
`;

export const ModalFooter = styled(Flex)`
  justify-content: center;
  padding: 0 2rem 5rem;

  button {
    margin: 0 1rem;
  }
`;

export const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.config.colors.green_200};
  border-radius: 50%;
  width: 66px;
  height: 66px;
  margin-bottom: 4rem;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 1rem;
`;

export const Description = styled.div`
  ${(props) => props.theme.config.typograph.xsmall};
  line-height: normal;
`;
