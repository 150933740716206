import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Dropdown, Menu } from 'modules/Core/Common';
import { useMediaQuery, MOBILE_SIZE } from 'modules/Core/Hooks';
import {
  LogoutIcon,
  SettingsIcon,
  ArrowDownIcon,
  InstallMobileIcon,
  InstallDesktopIcon,
  DashboardSecondIcon,
} from '@edusynch/edusynch-svg-icons';
import * as S from './HeaderDropdown.style';
import i18n from 'i18next';

const HeaderDropdown = ({ logout, ...props }) => {
  const history = useHistory();
  const handleLogout = () => logout();
  const handleSettings = () => history.push('/settings-profile');
  const isMobile = useMediaQuery(MOBILE_SIZE);
  const DROPDOWN_PREFIX = 'header.dropdown-options';

  const handleOutcome = () => {
    props.outcome.prompt();
  };

  const icon = isMobile ? (
    <InstallMobileIcon
      name="install-mobile"
      color="#0075EA"
      style={{ order: -1 }}
    />
  ) : (
    <InstallDesktopIcon
      name="install-desktop"
      color="#0075EA"
      style={{ order: -1 }}
    />
  );

  return (
    <S.DropdownContainer>
      <Dropdown
        classes="edusynch-dropdown"
        getPopupContainer={(trigger) => trigger.parentNode}
        overlay={
          <Menu
            selectable={false}
            className="header-dropdown__menu"
            data-cy="menu-dropdown"
          >
            <S.MenuItemContainer
              key="1"
              data-test="app-settings"
              itemIcon={<SettingsIcon style={{ order: -1 }} />}
              onClick={handleSettings}
            >
              <span>{i18n.t(`${DROPDOWN_PREFIX}.settings`)}</span>
            </S.MenuItemContainer>
            <S.MenuItemContainer
              key="2"
              data-test="app-widgets"
              itemIcon={<DashboardSecondIcon style={{ order: -1 }} />}
              onClick={() => history.push('/dashboard/widgets')}
            >
              <span>{i18n.t(`${DROPDOWN_PREFIX}.widgets`)}</span>
            </S.MenuItemContainer>
            {props.outcome && (
              <S.MenuItemContainer
                key="3"
                data-test="app-logout"
                itemIcon={icon}
                onClick={handleOutcome}
              >
                <span>{i18n.t(`${DROPDOWN_PREFIX}.install`)}</span>
              </S.MenuItemContainer>
            )}
            <S.MenuItemContainer
              key="4"
              data-test="app-logout"
              itemIcon={<LogoutIcon style={{ order: -1 }} />}
              onClick={handleLogout}
            >
              <span>{i18n.t(`${DROPDOWN_PREFIX}.logout`)}</span>
            </S.MenuItemContainer>
          </Menu>
        }
      >
        <S.IconContainer data-test="dropdown-logout" data-cy="header-dropdown">
          <ArrowDownIcon
            type="small"
            name="arrow-down"
            width={12}
            height={12}
          />
        </S.IconContainer>
      </Dropdown>
    </S.DropdownContainer>
  );
};

HeaderDropdown.propTypes = {
  logout: PropTypes.func,
  outcome: PropTypes.any,
};

export default HeaderDropdown;
