import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: ${(props) => props.theme.config.spacing.layout_6};

  ${(props) => {
    if (props.loading)
      return css`
        width: 100%;
        height: 100%;
        padding-top: 7rem;
        align-items: center;
        justify-content: center;
      `;
  }}
`;

export const AddButton = styled.div`
  display: flex;
  justify-content: flex-start;
  > button {
    padding-left: ${(props) => props.theme.config.spacing.spacing_2};
  }
`;

export const SubmitButton = styled.div`
  position: absolute;
  border-top: 1px solid ${(props) => props.theme.config.colors.gray_200};
  padding-right: ${(props) => props.theme.config.spacing.spacing_5};
  background: ${(props) => props.theme.config.colors.light};
  border-radius: 0 0 6px 6px;
  height: 89px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.4rem;
  left: 0;
  margin: 0;
  float: inline-start;
  color: ${(props) => props.theme.config.colors.text_base};
  min-width: -webkit-fill-available;
  bottom: 0;
`;

export const AddIntervalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${(props) => props.theme.config.spacing.spacing_4}
    ${(props) => props.theme.config.spacing.spacing_4} 0 0;

  div {
    margin-right: ${(props) => props.theme.config.spacing.spacing_1};
  }
`;

export const ButtonDelete = styled.div`
  background: none;
  border: none;
  outline: none;
  padding: 0;
  width: auto;
  cursor: pointer;

  svg {
    width: 1.6rem;
    height: 1.6rem;
  }
`;
