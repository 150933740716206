import { Skeleton } from 'modules/Core/Common';

import { LoadingContainer } from './WidgetFilters.styles';

export function WidgetFiltersLoading() {
  return (
    <LoadingContainer>
      <Skeleton type="string" />
      <Skeleton type="string" />
      <Skeleton type="string" />
      <Skeleton type="string" />
      <Skeleton type="string" />
    </LoadingContainer>
  );
}
