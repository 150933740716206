import { createQueryStringFromObject } from './index';

export const deletePropsFromQueryString = (
  queryString = '',
  paramsToDelete
) => {
  if (!queryString) return queryString;

  const transformedToObject = Object.fromEntries(
    new URLSearchParams(queryString)
  );

  paramsToDelete.forEach((param) => {
    delete transformedToObject[param];
  });

  return createQueryStringFromObject(transformedToObject);
};
