import React from 'react';
import PropTypes from 'prop-types';
import { addDotsToName } from 'modules/Utils';
import { Icon, Tooltip } from 'modules/Core/Common';
import { copyToClipBoard } from 'modules/Utils/CopyToClipboard';
import i18n from 'i18next';
import * as S from './styles';

const UserName = ({ name, email, copyEmail, nameSize }) => {
  return (
    <S.Container>
      <Tooltip overlay={name} disabled={name?.length <= nameSize}>
        <S.Span data-test="avatar-user-name">
          {addDotsToName(name, nameSize)}
        </S.Span>
      </Tooltip>
      {copyEmail && (
        <Tooltip
          placement="bottom"
          overlay={i18n.t('commons.buttons.copy-email')}
        >
          <Icon
            name="email"
            cursor="pointer"
            onClick={() => copyToClipBoard(email)}
          />
        </Tooltip>
      )}
    </S.Container>
  );
};

UserName.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  copyEmail: PropTypes.bool,
  nameSize: PropTypes.any,
};

export { UserName };
