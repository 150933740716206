import styled, { css, keyframes } from 'styled-components';
import { device } from 'modules/Utils/Devices';
import { ZIndexes } from 'modules/Utils/ZIndexes';

const loading = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
`;

const animation = css`
  &::after {
    content: '';
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    z-index: 1;
    transform: translateX(-100%);
    animation: ${loading} 1.2s infinite;
    background: linear-gradient(
      90deg,
      transparent,
      hsla(0, 0%, 100%, 0.4),
      transparent
    );
    z-index: 1;
  }
`;

export const Container = styled.div`
  .image-gallery.fullscreen-modal {
    min-width: 100%;
    z-index: ${ZIndexes.fullscreen};
  }

  .image-gallery-image {
    background: ${(props) => props.theme.config.colors.gray_200};
  }

  .image-gallery-fullscreen-button {
    display: flex;
  }

  .image-gallery-content.fullscreen {
    .image-gallery-image {
      background: ${(props) => props.theme.config.colors.text_base};
    }
  }

  .image-gallery-content:not(.fullscreen) {
    .image-gallery-image {
      height: 33.5rem;
    }

    .image-gallery-thumbnail-image {
      height: 5.2rem;
      object-fit: cover;
      background: ${(props) => props.theme.config.colors.gray_200};
    }
  }

  .image-gallery-right-nav,
  .image-gallery-left-nav {
    padding: 16px 10px;
  }

  .image-gallery-slide .image-gallery-image {
    object-fit: contain;
  }

  /* Full Screen Style */
  .image-gallery-content.fullscreen {
    .image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
      .image-gallery-thumbnails {
        margin: 0 50px 0 40px;
      }
    }

    .image-gallery-slides {
      width: fit-content;
      min-height: 76vh;
      margin: auto;
    }
  }

  .image-gallery-thumbnails {
    margin: 0 35px 0 35px;
  }

  .image-gallery-description {
    background: ${(props) => props.theme.config.colors.primary_opacity_9};
    font-size: 1.2rem;
    left: unset;
    right: 0;
    bottom: 0;
  }

  video {
    object-fit: cover;
  }
`;

export const PlaceholderContainer = styled.div``;

export const PlaceholderSlider = styled.div`
  width: 100%;
  height: 350px;
  background: ${(props) => props.theme.config.colors.gray_200};
  position: relative;
  overflow: hidden;
  ${animation};
`;

export const PlaceholderThumbnailContainer = styled.div`
  display: flex;
  margin-top: ${(props) => props.theme.config.spacing.spacing_2};
  overflow: hidden;
`;

export const PlaceholderThumbnail = styled.div`
  position: relative;
  display: flex;
  width: 90px;
  height: 52px;
  flex-basis: 90px;
  flex-shrink: 0;
  background: ${(props) => props.theme.config.colors.gray_200};
  overflow: hidden;
  ${animation};

  & + div {
    margin-left: ${(props) => props.theme.config.spacing.spacing_2};
  }
`;

export const NoScreenArchive = styled.div`
  width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) =>
    props.emptyDataPadding || props.theme.config.spacing.layout_8};

  @media ${device.tabletAndMobile} {
    width: 100%;
  }
`;

export const ExpandButton = styled.button`
  background: transparent;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  padding: 16px;
  outline: none img {
    transform: scale(1);
    transition: 0.2s ease-in;
  }

  &:hover {
    svgmg {
      transform: scale(1.2);
      transition: 0.2s ease-in;
    }
  }

  &&.active {
    top: 20px;
    right: 10px;
  }
`;

export const NextButton = styled.button`
  z-index: 2;
  background: transparent;
  border: none;
  position: absolute;
  right: 0px;
  top: 370px;
  padding: 16px;
  outline: none img {
    transform: scale(1);
    transition: 0.2s ease-in;
  }

  &:hover {
    svgmg {
      transform: scale(1.2);
      transition: 0.2s ease-in;
    }
  }

  &&.active {
    top: calc(100% + 50px);
    right: 10px;
  }
`;

export const PrevButton = styled.button`
  z-index: 2;
  background: transparent;
  border: none;
  position: absolute;
  top: 370px;
  padding: 16px;
  outline: none img {
    transform: scale(1);
    transition: 0.2s ease-in;
  }
  &:hover {
    svgmg {
      transform: scale(1.2);
      transition: 0.2s ease-in;
    }
  }

  &&.active {
    top: calc(100% + 50px);
  }
`;
