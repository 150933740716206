import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { Stack, Box } from '@mui/material';
import { Title } from 'modules/Core/Common';

export const WidgetEmptyData = ({
  imageURL,
  titleStyle,
  title,
  subtitleStyle,
  subtitle,
}) => {
  const theme = useTheme();

  return (
    <Stack
      height={{ xs: '18rem', sm: '28rem' }}
      alignItems="center"
      justifyContent="center"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box mb={2}>
          <img src={imageURL} alt="Empty data icon" />
        </Box>
        <Title
          as="h5"
          style={
            titleStyle || {
              marginBottom: '0.25rem',
              fontSize: '1.6rem',
              lineHeight: '1.6rem',
              fontWeight: 'bold',
              color: theme.config.colors.gray_400,
            }
          }
        >
          {title}
        </Title>
        <Title
          as="p"
          size="small"
          fontWeight="400"
          style={subtitleStyle || { color: theme.config.colors.gray_400 }}
        >
          {subtitle}
        </Title>
      </Box>
    </Stack>
  );
};

WidgetEmptyData.propTypes = {
  imageURL: PropTypes.string,
  titleStyle: PropTypes.string,
  title: PropTypes.string,
  subtitleStyle: PropTypes.string,
  subtitle: PropTypes.string,
};
