import styled from 'styled-components';

const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 15rem;
  height: 15rem;
  padding: 1rem;
  background: ${(props) => props.theme.config.colors.primary_opacity_05};
  border-radius: 0.6rem;
  overflow: hidden;
  position: relative;
  border: ${(props) =>
    !props.hasImg ? '0.2rem dashed rgba(4, 117, 234, .2);' : 'none'};
`;

const AvatarText = styled.span`
  ${(props) => props.theme.config.typograph.mico};
  display: block;
  text-align: center;
  pointer-events: none;
  color: ${(props) => props.theme.config.colors.primary};
`;

export { Avatar, AvatarText };
