const practiceResultsLinks = [
  {
    name: 'Results',
    url: 'results/group-practice-results',
    icon: 'results',
    ability: 'results_practice_group',
  },
  {
    name: 'Results',
    url: 'results/unit-practice-results',
    icon: 'results',
    ability: 'results_practice_unit',
  },
  {
    name: 'Results',
    url: 'results/school-practice-results',
    icon: 'results',
    ability: 'results_practice_school',
  },
  {
    name: 'Results',
    url: 'results/class-practice-results',
    icon: 'results',
    ability: 'results_practice_class',
  },
  {
    name: 'Results',
    url: 'results/student-practice-results',
    icon: 'results',
    ability: 'results_practice_student',
  },
];

const resultsBaseTestsLinks = [
  {
    name: 'Results',
    url: 'results/base/group',
    icon: 'results',
    ability: 'results_test_group',
  },
  {
    name: 'Results',
    url: 'results/base/unit',
    icon: 'results',
    ability: 'results_test_unit',
  },
  {
    name: 'Results',
    url: 'results/base/school',
    icon: 'results',
    ability: 'results_test_school',
  },
  {
    name: 'Results',
    url: 'results/base/class',
    icon: 'results',
    ability: 'results_test_class',
  },
];

const resultsCustomTestsLinks = [
  {
    name: 'Results',
    url: 'results/custom/group',
    icon: 'results',
    ability: 'results_custom_group',
  },
  {
    name: 'Results',
    url: 'results/custom/unit',
    icon: 'results',
    ability: 'results_custom_unit',
  },
  {
    name: 'Results',
    url: 'results/custom/school',
    icon: 'results',
    ability: 'results_custom_school',
  },
  {
    name: 'Results',
    url: 'results/custom/class',
    icon: 'results',
    ability: 'results_custom_class',
  },
];

const usersLinks = [
  {
    name: 'Users',
    url: 'users/edusynch/students',
    icon: 'users',
    ability: 'students',
  },
  {
    name: 'Evaluators',
    url: 'users/edusynch/evaluators',
    icon: 'users',
    ability: 'evaluators',
  },
  {
    name: 'Teachers',
    url: 'users/edusynch/teachers',
    icon: 'users',
    ability: 'users_teachers',
  },
  {
    name: 'School Admins',
    url: 'users/edusynch/school-admins',
    icon: 'users',
    ability: 'users_school_admin',
  },
  {
    name: 'Unit Admins',
    url: 'users/edusynch/unit-admins',
    icon: 'users',
    ability: 'users_unit_admin',
  },
  {
    name: 'Group Admins',
    url: 'users/edusynch/group-admins',
    icon: 'users',
    ability: 'users_group_admin',
  },
  {
    name: 'Super Admins',
    url: 'users/edusynch/super-admins',
    icon: 'users',
    ability: 'users_super_admin',
  },
];

const insitutionsLinks = [
  {
    name: 'Groups',
    url: 'institutions/groups',
    icon: 'teachers',
    ability: 'groups',
  },
  {
    name: 'Units',
    url: 'institutions/units',
    icon: 'teachers',
    ability: 'unit',
  },
  {
    name: 'Schools',
    url: 'institutions/schools',
    icon: 'teachers',
    ability: 'school',
  },
  {
    name: 'Classes',
    url: 'institutions/classes',
    icon: 'teachers',
    ability: 'classes',
  },
];

const simulationsLinks = [
  {
    name: 'Simulations',
    url: 'base-simulations/base-list',
    icon: 'simulation',
    ability: 'simulations_base_test',
  },
  {
    name: 'Simulations',
    url: 'custom-simulations/custom-list',
    icon: 'simulation',
    ability: 'simulations_custom_test',
  },
];

const submissionsLinks = [
  {
    name: 'Submissions',
    url: 'submissions/base-tests',
    icon: 'submission',
    ability: 'submissions_test_admin_base',
  },
  {
    name: 'Submissions',
    url: 'submissions/custom-tests',
    icon: 'submission',
    ability: 'submissions_test_admin_custom',
  },
  {
    name: 'Submissions',
    url: 'submissions/base-tests',
    icon: 'submission',
    ability: 'submissions_test_evaluator_base',
  },
  {
    name: 'Submissions',
    url: 'submissions/custom-tests',
    icon: 'submission',
    ability: 'submissions_test_evaluator_custom',
  },
];

export const LINKS_1 = [
  {
    name: 'Custom Results',
    url: 'results/custom/group',
    icon: 'results',
    ability: 'results_custom_group',
  },
  ...practiceResultsLinks,
  ...resultsBaseTestsLinks,
  ...resultsCustomTestsLinks,
];

export const LINKS_2 = [
  {
    name: 'Students',
    url: 'users/edusynch/students',
    icon: 'student',
    ability: 'students',
  },
  ...usersLinks,
];

export const LINKS_3 = [
  {
    name: 'Licenses',
    url: 'licenses',
    icon: 'license',
    ability: 'licenses',
  },
  ...insitutionsLinks,
];

export const LINKS_4 = [
  {
    name: 'Overall Scoring',
    url: 'overall-scoring',
    icon: 'overall',
    ability: 'overall_scorings',
  },
  ...submissionsLinks,
  ...simulationsLinks,
];
