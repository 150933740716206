import styled, { css } from 'styled-components';

export const ContentFilter = styled.div`
  h6 {
    ${(props) => props.theme.config.typograph.xsmall};
    color: ${(props) => props.theme.config.colors.gray_400};
    margin-bottom: 0.8rem;
  }

  div {
    display: flex;
    align-items: center;

    p {
      ${(props) => props.theme.config.typograph.small};
      color: ${(props) => props.theme.config.colors.gray};
      font-weight: bold;
      margin-left: 0.8rem;
    }
  }
`;

export const ContentAction = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  h6 {
    ${(props) => props.theme.config.typograph.xsmall};
    color: ${(props) => props.theme.config.colors.gray_400};
  }

  span {
    display: none;
  }
`;

export const Footer = styled.div`
  margin-top: 1.6rem;
  display: flex;
  justify-content: space-between;
`;

export const Period = styled.div`
  display: flex;

  p {
    ${(props) => props.theme.config.typograph.mico};
    color: ${(props) => props.theme.config.colors.gray};

    &:first-child {
      &::before {
        content: '';
        display: inline-block;
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        margin-right: 0.8rem;
        background: ${(props) => props.theme.config.colors.primary};
      }
    }

    &:last-child {
      &::before {
        content: '';
        display: inline-block;
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        margin-right: 0.8rem;
        background: ${(props) => props.theme.config.colors.orange};
      }
    }

    & + p {
      margin-left: 2.4rem;
    }
  }
`;

export const OverallSessions = styled.div`
  ${(props) => props.theme.config.typograph.mico};
  color: ${(props) => props.theme.config.colors.text_base};

  span {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
  }

  strong {
    ${(props) => props.theme.config.typograph.xsmall};
    font-weight: bold;
  }

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
      border-top: 1px solid ${(props) => props.theme.config.colors.gray_300};
      padding-top: 1.6rem;
    `};
`;

export const Legend = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
  margin-top: 0.4rem;

  span {
    ${(props) => props.theme.config.typograph.mico};
    color: ${(props) => props.theme.config.colors.gray};
    display: flex;
    align-items: center;

    &:first-child {
      &::before {
        content: '';
        display: inline-block;
        width: 0.4rem;
        height: 0.4rem;
        margin-right: 0.8rem;
        background: ${(props) => props.theme.config.colors.gray};
      }
    }

    &:last-child {
      &::before {
        content: '';
        display: inline-block;
        width: 0.4rem;
        height: 0.4rem;
        margin-right: 0.8rem;
        background: ${(props) => props.theme.config.colors.primary};
      }
    }
  }
`;
