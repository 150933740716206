import { lazy } from 'react';
import { menuBuilder } from 'services/Routes.service';
import { OutlineTestIcon } from '@edusynch/edusynch-svg-icons';

const LiveInterviewList = lazy(() =>
  import(
    'modules/EdusynchLive/LiveInterview/LiveInterviewList/LiveInterviewList.container'
  )
);

const LiveTestsList = lazy(() =>
  import('modules/EdusynchLive/LiveTests/List/LiveTestsList')
);

const LiveCodesList = lazy(() =>
  import('modules/EdusynchLive/LiveTests/LiveCodes/List')
);

const EdusynchLiveMenu = menuBuilder([
  {
    Icon: OutlineTestIcon,
    name: 'EduSynch Live',
  },
]).addSubmenu([
  {
    type: '',
    name: 'Live Interview',
    path: '/live_interview',
    exact: true,
    component: LiveInterviewList,
    ability: 'live_test_queues',
  },
  {
    type: '',
    name: 'Live Tests',
    path: '/live_tests',
    exact: true,
    component: LiveTestsList,
    ability: 'live_tests',
  },
  {
    type: '',
    name: 'Live Codes',
    path: '/live_tests/:liveTestId',
    exact: true,
    component: LiveCodesList,
    ability: 'live_test_codes',
    hidden: true,
  },
]);

export default EdusynchLiveMenu;
