import React from 'react';
import PropTypes from 'prop-types';
import { MenuIcon } from '@edusynch/edusynch-svg-icons';
import * as S from './HeaderMenu.styles';

const HeaderMenu = ({ onClick }) => (
  <S.Menu onClick={onClick}>
    <MenuIcon color="#7F7F7F" type="medium" />
  </S.Menu>
);

HeaderMenu.defaultProps = {
  opened: false,
};

HeaderMenu.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default HeaderMenu;
