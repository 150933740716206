import styled, { css } from 'styled-components';
import { device } from 'modules/Utils';

export const Container = styled.div`
  display: grid;
  gap: 3.2rem;
  grid-template-columns: repeat(4, minmax(28rem, 1fr));

  @media ${device.desktopMedium} {
    grid-template-columns: repeat(3, minmax(28rem, 1fr));
  }

  @media ${device.desktopSmall} {
    grid-template-columns: repeat(2, minmax(28rem, 1fr));
  }

  @media ${device.tablet} {
    grid-template-columns: repeat(2, minmax(28rem, 1fr));
  }

  @media ${device.mobile} {
    grid-template-columns: 1fr;
  }
`;

export const WidgetContainer = styled.div`
  min-width: 28rem;
  max-width: 100%;
  min-height: 40rem;
  border-radius: 0.8rem;
  cursor: grab;
  grid-column: ${(props) =>
    props.widgetSize === 'large' ? 'span 2' : 'span 1'};

  ${(props) =>
    props.isDragging &&
    css`
      grid-column: span 1;
      opacity: 0.5;
    `};
  @media ${device.tablet} {
    grid-column: span 1;
  }
  @media ${device.mobile} {
    grid-column: span 1;
  }
`;

export const WidgetDropContainer = styled.div`
  min-width: 28rem;
  max-width: 100%;
  border-radius: 0.8rem;
  background: ${(props) => props.theme.config.colors.primary_300};
`;
