import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Selectors } from 'modules/Auth/AuthSlice';
import * as S from './WhitelabelGreet.styles';
import i18n from 'i18next';

const WhitelabelGreet = ({ whitelabelName }) => {
  const session = useSelector(Selectors.selectAuthData);

  return (
    <S.WhitelabelNameContainer>
      <S.Span>{i18n.t('header.whitelabel-greet.title')}</S.Span>
      {session?.user?.group ? (
        <S.Strong> {session?.user?.group?.name}</S.Strong>
      ) : (
        <S.Strong> {whitelabelName}</S.Strong>
      )}
    </S.WhitelabelNameContainer>
  );
};

WhitelabelGreet.propTypes = {
  whitelabelName: PropTypes.string.isRequired,
  group: PropTypes.string,
};

export default WhitelabelGreet;
