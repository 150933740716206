import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  SUBSCRIPTIONS_URL,
  SUBSCRIPTIONS_UPDATE_CANCELED_URL,
  WHITELABELS_URL,
  PURCHASES_EXPORT_URL,
} from 'modules/Api/Routes';
import { formDataFromObj } from 'modules/Api/RequestData';
import { getISOStringFromStringDate } from 'modules/Utils';
import {
  getUTCDefaultStartDate,
  getUTCDefaultEndDate,
} from 'modules/Utils/Date';
import { defaultRequest } from 'modules/Utils/DefaultRequest';
import { STATUS_CANCELED } from 'modules/Subscriptions/SubscriptionsUtils';

const currentStartDate = getUTCDefaultStartDate();
const currentEndDate = getUTCDefaultEndDate();

let cancelToken;

const initialState = {
  loading: false,
  updateLoading: false,
  error: null,
  exportFilter: {
    link: '',
    feedbackType: null,
    whitelabelId: null,
    statusCancel: null,
    startDate: currentStartDate,
    endDate: currentEndDate,
    extension: 'csv',
    loading: false,
  },
  data: {
    page: 1,
    perPage: 10,
    feedbackType: null,
    whitelabelId: null,
    statusCancel: null,
    search: '',
    startDate: currentStartDate,
    endDate: currentEndDate,
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
  whitelabels: {
    loading: false,
    data: [],
  },
};

const cancelFeedbackSlice = createSlice({
  name: 'cancelFeedback',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    setUpdateLoading: (state, action) => {
      state.updateLoading = action.payload;
    },
    changeExportFilter: (state, action) => {
      state.exportFilter = {
        ...state.exportFilter,
        [action.payload?.param]: action.payload?.value,
      };
    },
    requestCancelFeedback: (state) => {
      state.loading = true;
      state.error = null;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    receiveCancelFeedbackList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        subscriptions: action.payload.subscriptions,
        total: action.payload.total_items,
      };
    },
    clearCancelFeedbackList: (state) => {
      state.data = {
        page: 1,
        perPage: 10,
        search: '',
        whitelabelId: null,
        startDate: currentStartDate,
        endDate: currentEndDate,
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
    },
    receiveCancelFeedbackError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changeCancelFeedbackPage: (state, action) => {
      state.data.page = action.payload;
    },
    changeCancelFeedbackFilters: (state, action) => {
      state.data.feedbackType = action.payload.feedback_type || null;
      state.data.whitelabelId = action.payload.whitelabel_id || null;
      state.data.statusCancel = action.payload.status_cancel || null;
      state.data.startDate = action.payload.start_date || currentStartDate;
      state.data.endDate = action.payload.end_date || currentEndDate;
      state.data.search = action.payload.search;
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);

      state.exportFilter.whitelabelId = action.payload.whitelabel_id || null;
      state.exportFilter.startDate =
        action.payload.start_date || currentStartDate;
      state.exportFilter.endDate = action.payload.end_date || currentEndDate;
    },
    clearCancelFeedbackFilters: (state) => {
      state.data.whitelabelId = null;
      state.data.search = '';
      state.data.page = 1;
      state.data.perPage = 10;
      state.data.startDate = currentStartDate;
      state.data.endDate = currentEndDate;
      state.data.sort.sortType = null;
      state.data.sort.sortBy = null;
    },
    requestWhitelabels: (state) => {
      state.whitelabels.loading = true;
    },
    receiveWhitelabelsList: (state, action) => {
      state.whitelabels.loading = false;
      state.whitelabels.data = action.payload.content.whitelabels;
    },
    receiveWhitelabelsError: (state, action) => {
      state.whitelabels.loading = false;
      state.error = action.payload;
    },
    requestUpdateCanceledSubscription: (state) => {
      state.updateLoading = true;
    },
    receiveUpdateCanceledSubscription: (state, action) => {
      state.updateLoading = false;
      const updatedSubscription = action.payload;
      state.data.subscriptions = state.data.subscriptions.map(
        (subscription) => {
          if (subscription.subscription_id === updatedSubscription.id) {
            return {
              ...subscription,
              important: updatedSubscription.important,
              verified: updatedSubscription.verified,
            };
          }

          return subscription;
        }
      );
    },
    requestExportLinks: (state) => {
      state.exportFilter.link = '';
      state.exportFilter.loading = true;
    },
    receiveExportLinks: (state, action) => {
      state.exportFilter.loading = false;
      state.exportFilter.link =
        action?.payload?.[`link_download_${state.exportFilter.extension}`];
    },
    receiveExportLinksError: (state, action) => {
      state.exportFilter.loading = false;
      state.error = action.payload;
    },
  },
});

const Actions = cancelFeedbackSlice.actions;

const Selectors = {
  fetchListData: (state) => state.cancelFeedback,
  cancelFeedbackLoading: ({ cancelFeedback: { loading } }) => ({ loading }),
};

const Async = {
  fetchWhitelabelsList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      WHITELABELS_URL,
      'GET',
      Actions.requestWhitelabels,
      Actions.receiveWhitelabelsList,
      Actions.receiveWhitelabelsError
    ),
  fetchCancelFeedbackList: () => async (dispatch, getState) => {
    const {
      cancelFeedback: {
        data: {
          page,
          perPage,
          search,
          feedbackType,
          whitelabelId,
          statusCancel,
          startDate,
          endDate,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestCancelFeedback());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: SUBSCRIPTIONS_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          feedback_type: feedbackType,
          whitelabel_id: whitelabelId,
          status_cancel: statusCancel,
          status: STATUS_CANCELED.toUpperCase(),
          start_date: getISOStringFromStringDate(startDate || currentStartDate),
          end_date: getISOStringFromStringDate(endDate || currentEndDate),
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receiveCancelFeedbackList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveCancelFeedbackError(e.message);
      }
    }

    action && dispatch(action);
  },

  updateCanceledSubscription:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestUpdateCanceledSubscription());

      const requestData = formDataFromObj(data);

      try {
        const response = await request({
          method: 'PUT',
          url: SUBSCRIPTIONS_UPDATE_CANCELED_URL,
          data: requestData,
        });

        dispatch(Actions.receiveUpdateCanceledSubscription(data));
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.setUpdateLoading(false));
        onError(e);
      }
    },

  fetchExportSubmit:
    ({ onSuccess, onError }) =>
    async (dispatch, getState) => {
      const {
        cancelFeedback: {
          data: { search },
          exportFilter: {
            testId,
            groupId,
            whitelabelId,
            operator,
            packageId,
            startDate,
            endDate,
            extension,
          },
        },
      } = getState();
      let action;

      dispatch(Actions.requestExportLinks());

      const data = {
        file_type: extension,
        group_id: groupId,
        test_id: testId,
        whitelabel_id: whitelabelId,
        operator: operator,
        package_bought_id: packageId,
        start_date: getISOStringFromStringDate(startDate || currentStartDate),
        end_date: getISOStringFromStringDate(endDate || currentEndDate),
        search,
      };

      try {
        const response = await request({
          method: 'POST',
          url: PURCHASES_EXPORT_URL,
          data,
        });

        action = Actions.receiveExportLinks(response.data.content);
        onSuccess(response.data.content);
      } catch (e) {
        action = Actions.receiveExportLinksError(e.message);
        onError();
      }

      dispatch(action);
    },
};

const { reducer } = cancelFeedbackSlice;

export { reducer, Actions, Async, Selectors };
