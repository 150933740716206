import React from 'react';
import PropTypes from 'prop-types';

import * as S from './BulletLabel.styles';

export const BulletLabel = ({ variant, secondary, children }) => {
  return (
    <S.Container variant={variant} secondary={secondary}>
      {children}
    </S.Container>
  );
};

BulletLabel.propTypes = {
  variant: PropTypes.string,
  secondary: PropTypes.bool,
  children: PropTypes.node,
};
