import React, { useEffect, useState, createRef } from 'react';
import PropTypes from 'prop-types';
import * as S from './Avatar.styles';

const getInitialLettersFromName = (name) => {
  if (!name) return;
  const initialLetters = name
    .split(' ')
    .map((names) => names.charAt(0))
    .join('')
    .substr(0, 2);
  return initialLetters;
};

const DEFAULT_BACKEND_AVATAR_URL = 'avatar/thumb_default_avatar.png';

const Avatar = ({
  src,
  size,
  name,
  onClick,
  borderRadius,
  showCursorPointer,
  isSquare,
  ...props
}) => {
  const imageRef = createRef(null);
  const [isImageError, setIsImageError] = useState(false);

  const hasImageProblem =
    !src || isImageError || src?.includes(DEFAULT_BACKEND_AVATAR_URL);

  useEffect(() => {
    const { current } = imageRef;
    current?.addEventListener('error', () => setIsImageError(true));
    return () => current?.removeEventListener('error', () => '');
  }, []);

  return hasImageProblem ? (
    <S.UnloadedImage
      onClick={onClick}
      size={size}
      borderRadius={borderRadius}
      showCursorPointer={showCursorPointer}
      {...props}
    >
      {getInitialLettersFromName(name)}
    </S.UnloadedImage>
  ) : (
    <S.Img
      ref={imageRef}
      src={src}
      size={size}
      onClick={onClick}
      showCursorPointer={showCursorPointer}
      isSquare={isSquare}
      {...props}
    />
  );
};

Avatar.defaultProps = {
  src: null,
};

Avatar.propTypes = {
  src: PropTypes.any,
  size: PropTypes.string,
  name: PropTypes.string,
  borderRadius: PropTypes.string,
  onClick: PropTypes.func,
  showCursorPointer: PropTypes.bool,
  isSquare: PropTypes.bool,
};

export default Avatar;
