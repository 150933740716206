import styled from 'styled-components';

export const Container = styled.div`
  background: ${(props) => props.theme.config.colors.primary_200};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const ErrorContent = styled.div`
  width: 400px;
  text-align: center;

  img {
    width: 219px;
    height: 214px;
    margin-bottom: 40px;
  }

  p {
    ${(props) => props.theme.config.typograph.default};
    color: ${(props) => props.theme.config.colors.text_base};
    width: 311px;
    margin: 0 auto;

    strong {
      color: ${(props) => props.theme.config.colors.primary};
    }
  }
`;

export const LinkStyled = styled.a`
  button {
    margin: ${(props) => props.theme.config.spacing.spacing_4} auto 0;
    height: 40px;
    width: 120px;
    font-size: 14px;
    padding: 0 16px;
  }
`;
