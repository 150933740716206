import { addMinutesUTC, roundTimeUTC } from 'modules/Utils/Date';

const formatDateTimeToForm = (intervals) =>
  intervals && intervals.length ? intervals : [];

const getLastDate = (intervals) =>
  intervals.reduce((acc, interval) => {
    const lastEndDate = new Date(acc.end_date);
    const compareDate = new Date(interval.end_date);
    return lastEndDate < compareDate ? interval : acc;
  });

const getMinAndMaxBasedOnLastInterval = (intervals = []) => {
  if (!intervals.length) {
    return {
      minDate: roundTimeUTC(new Date().toISOString()),
      minEndDate: addMinutesUTC(roundTimeUTC(new Date().toISOString()), 30),
    };
  }
  const lastDate = getLastDate(intervals);
  const minDate = roundTimeUTC(lastDate.end_date);
  const minEndDate = addMinutesUTC(minDate, 30);

  return {
    minDate,
    minEndDate,
  };
};

const createEvaluatorModel = ({ evaluator = {}, user }) => ({
  id: evaluator.id || '',
  name: evaluator.name || '',
  email: evaluator.email || '',
  password: '',
  password_confirmation: '',
  platform: evaluator.platform || '',
  avatar: evaluator.avatar || '',
  language: evaluator.language || '',
  timezone: evaluator.timezone || '',
  zoom_id: evaluator.zoom_id || '',
  priority: evaluator.priority || 0,
  removedIntervals: [],
  intervals: formatDateTimeToForm(evaluator?.scheduler?.items, user),
  evaluator_associations: evaluator.evaluator_associations || [],
  can_evaluate_IELTS_Speaking_only:
    evaluator.can_evaluate_IELTS_Speaking_only || false,
  receive_email_evaluation: evaluator.receive_email_evaluation || false,
  show_scheduler_evaluator: evaluator.show_scheduler_evaluator || false,
  can_see_antifraud: evaluator.can_see_antifraud || false,
  can_see_base_tests: evaluator.can_see_base_tests || false,
  can_see_custom_tests: evaluator.can_see_custom_tests || false,
  can_see_antifraud_base_test: evaluator.can_see_antifraud_base_test || false,
  can_see_antifraud_custom_test:
    evaluator.can_see_antifraud_custom_test || false,
  can_see_antifraud_sessions: evaluator.can_see_antifraud_sessions || false,
  can_see_all_proctoring: evaluator.can_see_all_proctoring || false,
});

export { createEvaluatorModel, getMinAndMaxBasedOnLastInterval };
