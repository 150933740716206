import React, { useState, useCallback, createRef, useEffect } from 'react';
import { useTheme } from 'styled-components';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import { Icon, Tooltip } from 'modules/Core/Common';
import * as S from './Audio.styles';
import { secondsToMinutes } from './Utils';
import i18n from 'i18next';
import { AudioPlaceholder } from './Audio.placeholder';

const Audio = ({ url, secondary, ...props }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const theme = useTheme();

  const playerRef = createRef(null);
  const [audioProps, setAudioProps] = useState({
    url: url,
    playing: false,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playedSeconds: 0,
    playbackRate: 1.0,
    seeking: false,
    loop: false,
  });

  const [handleDotsDownload, setHandleDotsDownload] = useState(false);

  useEffect(() => {
    setAudioProps({ ...audioProps, url });
  }, [url]);

  const handlePlay = useCallback(() => {
    setAudioProps({ ...audioProps, playing: true });
  }, [audioProps]);

  const handlePause = useCallback(() => {
    setAudioProps({ ...audioProps, playing: false });
  }, [audioProps]);

  const changeVolume = useCallback(
    (element) => {
      const value = element.target.value;
      setAudioProps({ ...audioProps, volume: Number(value) });
    },
    [audioProps]
  );

  const handleProgress = useCallback(
    (params) => {
      if (!audioProps.seeking) {
        setAudioProps({
          ...audioProps,
          loaded: params.loaded,
          loadedSeconds: params.loadedSeconds,
          played: params.played,
          playedSeconds: params.playedSeconds,
        });
      }
    },
    [audioProps]
  );

  const handleDuration = useCallback(
    (duration) => {
      setAudioProps({ ...audioProps, duration });
    },
    [audioProps]
  );

  const handleMuteAudio = useCallback(() => {
    setAudioProps({
      ...audioProps,
      volume: audioProps.volume ? 0 : 1,
    });
  }, [audioProps]);

  const handleOnSeekMouseDown = useCallback(() => {
    setAudioProps({ ...audioProps, seeking: true });
  }, [audioProps]);

  const handleOnSeekChange = useCallback(
    (e) => {
      setAudioProps({ ...audioProps, played: parseFloat(e.target.value) });
    },
    [audioProps]
  );

  const handleOnSeekMouseUp = useCallback(
    (e) => {
      setAudioProps({ ...audioProps, seeking: false });
      playerRef.current.seekTo(parseFloat(e.target.value));
    },
    [audioProps]
  );

  const curPercentage = (audioProps.playedSeconds / audioProps.duration) * 100;

  return (
    <div>
      <ReactPlayer
        ref={playerRef}
        className="react-player"
        width="100%"
        height="100%"
        url={audioProps.url}
        playing={audioProps.playing}
        light={audioProps.light}
        loop={audioProps.loop}
        playbackRate={audioProps.playbackRate}
        volume={audioProps.volume}
        muted={audioProps.muted}
        onPlay={handlePlay}
        onPause={handlePause}
        onSeek={(e) => console.warn('onSeek', e)}
        onError={() => {
          setIsLoading(false);
          setIsError(true);
        }}
        onProgress={handleProgress}
        onDuration={handleDuration}
        onReady={() => setIsLoading(false)}
      />

      {isLoading ? (
        <AudioPlaceholder secondary={secondary} />
      ) : (
        <S.AudioContainer {...props} error={isError}>
          <S.ButtonPlayer playing={audioProps.playing} {...props}>
            {audioProps.playing ? (
              <Icon onClick={handlePause} name="pause" />
            ) : (
              <Icon onClick={handlePlay} name="play" />
            )}
          </S.ButtonPlayer>
          <S.Timer>
            <S.CurrentDuration {...props}>
              {secondsToMinutes(audioProps.playedSeconds)} /
            </S.CurrentDuration>
            <S.TotalDuration {...props}>
              {' '}
              {secondsToMinutes(audioProps.duration)}
            </S.TotalDuration>
          </S.Timer>
          <S.SeekBar
            type="range"
            min={0}
            max={1}
            step="any"
            value={audioProps.played}
            onTouchStart={handleOnSeekMouseDown}
            onTouchEnd={handleOnSeekMouseUp}
            onMouseDown={handleOnSeekMouseDown}
            onMouseUp={handleOnSeekMouseUp}
            onChange={handleOnSeekChange}
            style={{
              background: `linear-gradient(to right, ${theme.config.colors.primary} ${curPercentage}%, ${theme.config.colors.primary_100} 0)`,
            }}
            {...props}
          />
          <S.SpaceIconsContainer {...props}>
            <S.Volume>
              <S.ToggleVolume
                type="range"
                min={0}
                max={1}
                step="any"
                {...props}
                onChange={changeVolume}
                value={audioProps.volume}
                style={{
                  background: `linear-gradient(to right, ${
                    theme.config.colors.primary
                  } ${audioProps.volume * 100}%, ${
                    theme.config.colors.gray_300
                  } 0)`,
                }}
              />
              <Icon
                id="mute"
                name="audio"
                fontSize="16px"
                style={{ cursor: 'pointer' }}
                onClick={handleMuteAudio}
              />
            </S.Volume>
          </S.SpaceIconsContainer>
          <S.IconThreeDots {...props}>
            <Icon
              onClick={() => setHandleDotsDownload(!handleDotsDownload)}
              name="more"
            />

            {handleDotsDownload && (
              <div className="AudioDownload">
                <a
                  href={audioProps.url}
                  download
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon name="download" />
                  <span>{i18n.t('common-words.download')}</span>
                </a>
              </div>
            )}
          </S.IconThreeDots>

          {isError && (
            <S.Error>
              <Tooltip
                overlayClassName="tooltip-light"
                placement="bottom"
                overlay={
                  <S.ErrorMessage>
                    An error occurred while loading the audio
                  </S.ErrorMessage>
                }
                overlayInnerStyle={{
                  color: theme.config.colors.tertiary_400,
                  fontSize: '1.4rem',
                  borderRadius: '4px',
                  padding: '0.8rem',
                }}
              >
                <Icon fontSize="1.6rem" name="alert-circle" />
              </Tooltip>
            </S.Error>
          )}
        </S.AudioContainer>
      )}
    </div>
  );
};

Audio.propTypes = {
  url: PropTypes.string.isRequired,
  secondary: PropTypes.bool,
};

export default Audio;
