import styled, { css } from 'styled-components';
import { NavLink as Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { device } from 'modules/Utils/Devices';
import { ZIndexes } from 'modules/Utils/ZIndexes';

const afterProps = `
  content: '';
  position: absolute;
  left: -35px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 5px;
  width: 225px;
  height: 40px;
`;

const SidebarCSSTransition = styled(CSSTransition)`
  display: block;

  &.sidebar-animation-enter {
    transform: translate(-100%);
    transition: 0.2s ease-in;
  }

  &.sidebar-animation-enter-active {
    transform: translate(0);
    transition: 0.2s ease-out;
  }

  &.sidebar-animation-exit {
    transform: translate(0);
    transition: 0.2s ease-out;
  }

  &.sidebar-animation-exit-active {
    transform: translate(-100%);
    transition: 0.2s ease-in;
  }
`;

const SubmenuLink = styled(Link)`
  position: relative;
  white-space: nowrap;

  &::before {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: ${(props) => props.theme.config.colors.gray_400};
    position: absolute;
    top: 8px;
    left: -20px;

    @media ${device.mobile} {
      background: ${(props) => props.theme.config.colors.primary};
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
    `}

  &::after {
    transition: 0.2s ease-out;
    ${afterProps}

    @media ${device.tablet} {
      left: -35px;
      width: 180px;
    }

    @media ${device.mobile} {
      width: calc(100% + 40px);
    }
  }

  &:hover {
    color: ${(props) => props.theme.config.colors.text_base};

    &::before {
      background: ${(props) => props.theme.config.colors.text_base};
      opacity: 0.5;
    }

    &::after {
      background: ${(props) => props.theme.config.colors.primary_opacity_1};

      @media ${device.tablet} {
        left: -35px;
        width: 180px;
      }

      @media ${device.mobile} {
        width: calc(100% + 40px);
      }
    }
  }

  &.active {
    color: ${(props) => props.theme.config.colors.primary};

    &::before {
      background: ${(props) => props.theme.config.colors.primary};
      opacity: 1;
    }

    &::after {
      background: ${(props) => props.theme.config.colors.primary_opacity_1};

      @media ${device.tablet} {
        left: -35px;
        width: 180px;
      }

      @media ${device.mobile} {
        width: calc(100% + 40px);
      }
    }
  }

  @media ${device.mobile} {
    ${(props) => props.theme.config.typograph.default};
    margin-left: ${(props) => props.theme.config.spacing.spacing_3};
    display: block;
    color: ${(props) => props.theme.config.colors.light};

    &::after {
      background: ${(props) => props.theme.config.colors.primary_opacity_1};
      width: calc(100% + 40px);
    }
  }
`;

const Submenu = styled.div`
  width: 26rem;
  padding: 3.5rem 3rem;
  background: ${(props) => props.theme.config.colors.gray_200};
  box-shadow: 0px 0.4rem 2rem rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 0;
  left: 8rem;
  height: 100%;
  z-index: ${ZIndexes.submenu + 1};
  overflow: auto;

  @media ${device.tablet} {
    width: 20rem;
    padding: 3.5rem 2rem;
  }

  @media ${device.mobile} {
    top: auto;
    bottom: 0;
    z-index: ${ZIndexes.submenuMobile};
    height: calc(100% - 60px);
    width: 100%;
    left: 0;
    padding: 0;
    background: ${(props) => props.theme.config.colors.secondary};
  }

  &::-webkit-scrollbar {
    background: transparent;
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.config.colors.secondary_100};
    border-radius: 2rem;
    width: 0.5rem;
  }
`;

const SubmenuTitle = styled.h1`
  ${(props) => props.theme.config.typograph.default};
  position: relative;
  font-weight: bold;
  display: flex;
  align-items: flex-end;
  transition: 0.2s ease-in;
  text-transform: capitalize;
  color: ${(props) => props.theme.config.colors.primary};

  svg {
    margin-right: ${(props) => props.theme.config.spacing.spacing_2};
  }

  @media ${device.mobile} {
    ${(props) => props.theme.config.typograph.gary_300};
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.config.colors.light};
    padding: 28px 15px 28px 70px;
    margin-top: 0;
    background: ${(props) => props.theme.config.colors.primary_700};
    border-bottom: 0.1rem solid ${(props) => props.theme.config.colors.gray_300};
  }
`;

const ItemsList = styled.ul`
  display: block;

  @media ${device.mobile} {
    padding: 0 15px 25px;
  }
`;

const Container = styled.div`
  display: block;
`;

const ItemsListItem = styled.li`
  display: block;

  & + li {
    margin-top: 29px;

    @media ${device.mobile} {
      margin-top: 29px;
    }
  }
`;

const SubmenuItemsTitle = styled.h1`
  ${(props) => props.theme.config.typograph.default};
  font-weight: bold;
  color: ${(props) => props.theme.config.colors.text_base};
  margin: 30px 0 15px;

  @media ${device.mobile} {
    color: ${(props) => props.theme.config.colors.light};
    margin: 25px 0 10px;
  }
`;

const SubmenuItems = styled.span`
  ${(props) => props.theme.config.typograph.xsmall};
  color: ${(props) => props.theme.config.colors.text_base};
  padding-left: 20px;

  @media ${device.mobile} {
    display: block;
  }
`;

const Aside = styled.aside`
  background-color: ${(props) => props.theme.config.colors.secondary};
  position: fixed;
  height: 100%;
  z-index: ${ZIndexes.sidebar + 1};
  width: 8rem;
  min-width: 8rem;
  transition: 0.2s ease-in;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  @media ${device.mobile} {
    height: calc(100% - 60px);
    width: 100%;
    bottom: 0;
    top: auto;
  }
`;

const SidebarList = styled.ul`
  width: 100%;
  margin: 0;
  padding-inline-start: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.mobile} {
    display: flex;
    align-items: flex-start;
    padding: 2.5rem 2.5rem 2.5rem 1.5rem;
  }
`;

const SidebarListItem = styled.li`
  position: relative;
  margin: 0.5rem 0;

  @media ${device.tabletMin} {
    a,
    button {
      &:hover {
        span {
          transform: translate(0, -50%);
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  @media ${device.mobile} {
    width: 100%;
  }
`;

const SpanLinkName = styled.span`
  color: ${(props) => props.theme.config.colors.light};
  display: inline-block;
  margin-left: ${(props) => props.theme.config.spacing.spacing_4};
`;

const SidebarHover = styled.span`
  display: block;
  padding: 1rem 1.5rem;
  position: absolute;
  top: 50%;
  left: 8rem;
  border-radius: ${(props) => props.theme.config.spacing.spacing_2};
  background: ${(props) => props.theme.config.colors.primary};
  box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.05);
  white-space: nowrap;
  pointer-events: none;
  color: ${(props) => props.theme.config.colors.light};
  transition: 0.2s ease-in;
  transform: translate(
    -${(props) => props.theme.config.spacing.spacing_2},
    -50%
  );
  opacity: 0;
  visibility: hidden;

  &::after {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &::after {
    border-color: transparent;
    border-right-color: ${(props) => props.theme.config.colors.primary};
    border-width: 6px;
    margin-top: -${(props) => props.theme.config.spacing.spacing_2};
  }
`;

const ShortcutListItem = styled.li`
  position: relative;

  &:hover,
  &.active {
    a,
    button {
      color: ${(props) => props.theme.config.colors.primary};

      &::before {
        background: ${(props) => props.theme.config.colors.primary};
      }

      &::after {
        content: '';
        position: absolute;
        left: -10px;
        top: 50%;
        transform: translateY(-50%);
        background: ${(props) => props.theme.config.colors.primary_opacity_1};
        border-radius: 5px;
        width: 225px;
        height: 40px;
      }
    }
  }

  &:first-child {
    margin-top: ${(props) => props.theme.config.spacing.spacing_5};
  }

  & + li {
    margin-top: ${(props) => props.theme.config.spacing.spacing_4};
  }
`;

const ShortcutButton = styled.button`
  ${(props) => props.theme.config.typograph.default};
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background: transparent;
  color: ${(props) => props.theme.config.colors.text_base};

  &.active {
    color: ${(props) => props.theme.config.colors.primary};

    &::before {
      background: ${(props) => props.theme.config.colors.primary};
    }

    &::after {
      content: '';
      position: absolute;
      left: -10px;
      top: 50%;
      transform: translateY(-50%);
      background: ${(props) => props.theme.config.colors.primary_opacity_1};
      border-radius: 5px;
      width: 225px;
      height: 40px;
    }
  }

  svg {
    margin-right: ${(props) => props.theme.config.spacing.spacing_2};
  }
`;

const ShortcutLink = styled(Link)`
  ${(props) => props.theme.config.typograph.default};
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background: transparent;
  color: ${(props) => props.theme.config.colors.text_base};

  &.active {
    color: ${(props) => props.theme.config.colors.primary};

    &::before {
      background: ${(props) => props.theme.config.colors.primary};
    }

    &::after {
      content: '';
      position: absolute;
      left: -10px;
      top: 50%;
      transform: translateY(-50%);
      background: ${(props) => props.theme.config.colors.primary_opacity_1};
      border-radius: 5px;
      width: 225px;
      height: 40px;
    }
  }

  svg {
    margin-right: ${(props) => props.theme.config.spacing.spacing_2};
  }
`;

const OpenArrow = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: ${(props) => props.theme.config.spacing.spacing_5};
  height: ${(props) => props.theme.config.spacing.spacing_5};
  left: 1.5rem;
  background: ${(props) => props.theme.config.colors.primary_700};
  mix-blend-mode: normal;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);

  svg {
    width: 1.2rem;
    margin-right: 0;

    path {
      fill: ${(props) => props.theme.config.colors.primary};
    }
  }
`;

const MoreItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 80px;
  z-index: 10;
  height: 45px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s opacity;
  cursor: pointer;

  ${(props) =>
    props.up &&
    css`
      height: 160px;
      flex-direction: column;
      justify-content: space-between;
      top: 0;
      padding-bottom: 30px;
      background: linear-gradient(
        0deg,
        rgba(0, 56, 113, 0) 0.45%,
        #003871 14.77%
      );
    `}

  ${(props) =>
    props.down &&
    css`
      bottom: 0;
      background: linear-gradient(
        180deg,
        rgba(0, 56, 113, 0) 0.45%,
        #003871 34.77%
      );
    `}

  ${(props) =>
    props.up &&
    props.state !== 'top' &&
    css`
      opacity: 1;
      visibility: visible;
    `}

  ${(props) =>
    props.down &&
    props.state !== 'bottom' &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

export {
  MoreItems,
  ShortcutListItem,
  ShortcutButton,
  ShortcutLink,
  Aside,
  SidebarList,
  SidebarListItem,
  SpanLinkName,
  Submenu,
  SubmenuTitle,
  SubmenuItemsTitle,
  SubmenuItems,
  ItemsList,
  ItemsListItem,
  Container,
  SidebarHover,
  SubmenuLink,
  OpenArrow,
  SidebarCSSTransition,
};
