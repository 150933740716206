import React from 'react';
import PropTypes from 'prop-types';
import { RouterLink } from 'modules/Core/Common';
import { useLocation } from 'react-router-dom';
import * as S from './AuthSidebar.style';

const AuthSidebarSmall = ({
  title,
  imageSrcLogo,
  linkDescription,
  email,
  subtitle,
}) => {
  const location = useLocation();

  return (
    <S.Content className={location.pathname.includes('password') ? 'page' : ''}>
      <RouterLink to="/" title={title}>
        <S.Logo src={imageSrcLogo} />
      </RouterLink>

      <S.Description>
        {linkDescription}
        <a target="_blank" rel="noopener noreferrer" href={`mailto:${email}`}>
          <span dangerouslySetInnerHTML={{ __html: subtitle }} />
        </a>
      </S.Description>
    </S.Content>
  );
};

AuthSidebarSmall.propTypes = {
  title: PropTypes.string,
  imageSrcLogo: PropTypes.string,
  linkDescription: PropTypes.string,
  email: PropTypes.string,
  subtitle: PropTypes.string,
};

export default AuthSidebarSmall;
