import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { useMediaQuery } from 'modules/Core/Hooks';
import AuthSidebarSmall from './AuthSidebarSmall.component';
import * as S from './AuthContent.styles';

const AuthContent = ({ children, theme }) => {
  const [imageSrc, setImage] = useState(null);
  const [imageSrcLogo, setImageLogo] = useState(null);
  const smallScreen = useMediaQuery('(max-width: 1023.98px)');

  useEffect(() => {
    const loadImage = async () => {
      const img = await theme.config.images.authSidebar();
      const logo = await theme.config.images.authLogo();
      setImage(img.default, logo.default);
      setImageLogo(logo.default);
    };
    loadImage();
  }, [theme]);

  return (
    <S.Container imgSrc={smallScreen && imageSrc}>
      {smallScreen && (
        <AuthSidebarSmall imageSrcLogo={imageSrcLogo} {...theme.config.info} />
      )}

      <S.Content>{children}</S.Content>
    </S.Container>
  );
};

AuthContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  theme: PropTypes.object,
};

export default withTheme(AuthContent);
