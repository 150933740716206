import React from 'react';
import PropTypes from 'prop-types';
import { Title } from './Title';
import * as S from './InfoIcon.style';

const isRaw = (children) => ['string', 'number'].includes(typeof children);

const InfoIcon = ({ children, title, before, ...props }) => (
  <S.Container>
    {before}

    <S.Box {...props}>
      {title && (
        <Title data-cy="component-info-icon-title" {...props}>
          {title}
        </Title>
      )}

      {isRaw(children) ? (
        <S.Text data-test="component-info-icon-text" {...props}>
          {children}
        </S.Text>
      ) : (
        children
      )}
    </S.Box>
  </S.Container>
);

InfoIcon.defaultProps = {
  subTitle: null,
};

InfoIcon.propTypes = {
  before: PropTypes.element,
  title: PropTypes.node,
  children: PropTypes.node,
};

export default InfoIcon;
