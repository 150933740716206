import styled, { css } from 'styled-components';
import { device } from 'modules/Utils';

export const Container = styled.div`
  display: block;
  position: relative;
  ${({ prependInnerIcon }) =>
    !!prependInnerIcon &&
    css`
      display: flex;
      svg:first-child {
        width: 1.6rem;
        height: 1.6rem;
        position: absolute;
        bottom: 50%;
        transform: translateY(50%);
        left: 1.6rem;
      }
    `};
  svg:not[ArrowDownIcon] {
    background: red;
  }
  div {
    margin-top: 0 !important;
    ${(props) =>
      props.border &&
      css`
        border-color: ${(props) =>
          props.theme.config.colors.gray_300} !important;
      `}
  }
`;

export const NormalDiv = styled.div``;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const CustomToggl = styled.div`
  cursor: pointer;
`;

export const ToogleButton = styled.div`
  ${(props) => props.theme.config.typograph.default};
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0 4rem 0 ${(props) => (props.isMultiple ? '0.6rem' : '1.6rem')};
  width: 100%;
  min-height: ${(props) => (props.filterStyle ? '4rem' : '4.8rem')};
  background: ${(props) => props.theme.config.colors.light};
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    props.borders ? props.theme.config.colors.gray_300 : 'transparent'};
  border-radius: ${(props) => (props.filterStyle ? '50px' : '0.6rem')};
  outline: none;
  color: ${(props) => props.theme.config.colors.gray_400};
  appearance: none;
  transition: 0.2s ease-in;
  ${({ prependInnerIcon }) =>
    !!prependInnerIcon &&
    css`
      text-indent: 2.4rem;
    `};

  .edusynch-icon.small {
    transition: 0.2s ease-in;
    transform: translateY(-50%);

    &.AlertCircleSecondIcon {
      transform: translateY(4px);
    }

    path {
      fill: ${(props) =>
        props.errors
          ? props.theme.config.colors.tertiary
          : props.theme.config.colors.gray_400} !important;
    }
  }

  &:hover,
  &:focus {
    border-color: ${(props) => props.theme.config.colors.primary};

    .edusynch-icon.small {
      path {
        fill: ${(props) => props.theme.config.colors.primary};
      }
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      color: ${(props) => props.theme.config.colors.text_base};
      background: ${(props) => props.theme.config.colors.gray_200};
      border: 1px solid ${(props) => props.theme.config.colors.gray_300};
      pointer-events: none;

      svg {
        display: none;
      }
    `}

  ${(props) =>
    props.errors &&
    css`
      border-color: ${(props) => props.theme.config.colors.tertiary} !important;
    `}
  ${(props) =>
    props.isMultiple &&
    css`
      flex-wrap: wrap;
      min-width: 30rem;
      max-width: 60rem;
      padding: 0.8rem 0.4rem;
      @media ${device.mobile} {
        min-width: auto;
      }
    `};
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0.6rem 0 -0.5rem;
`;

export const Error = styled.div`
  margin-left: -0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
`;

export const Toggl = styled.div`
  position: absolute;
  right: 1rem;
  top: 50%;
  cursor: pointer;
`;

export const Placeholder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.config.colors.gray_400};
  pointer-events: none;
  overflow: hidden;
  padding-left: ${(props) => (props.isMultiple ? '1rem' : '0')};

  svg {
    margin-right: 5px;
  }
`;

export const FloatingPlaceholder = styled.div`
  position: absolute;
  left: 1.6rem;
  top: -0.7rem;
  z-index: 1;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.15s ease-in-out;

  span {
    position: relative;
    font-size: 1.2rem;
    line-height: 14px;
    color: ${(props) => props.theme.config.colors.gray_400};
    z-index: 1;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -0.1rem;
    width: 100%;
    height: 1rem;
    z-index: 0;
    background-color: ${(props) =>
      props?.isDisabled
        ? props.theme.config.colors.gray_200
        : props.theme.config.colors.light};
  }
`;

export const SelectedOpt = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 9px;
  background-color: ${(props) => props.theme.config.colors.primary_100};
  border-radius: 4px;
  overflow: hidden;
  margin: 4px;
  font-size: 12px;
  ${(props) =>
    props.isMultiple &&
    css`
      margin: 0.4rem 0.2rem 0;
    `};
`;

export const HiddenSelectedNumber = styled.div`
  color: ${(props) => props.theme.config.colors.primary};
  margin-left: 8px;
`;

export const SelectedLabel = styled.div`
  color: ${(props) => props.theme.config.colors.primary};
  width: 90px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const SelectedLabelOnTop = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 0 0.9rem;
  background-color: ${(props) => props.theme.config.colors.primary_100};
  border-radius: 4px;
  color: ${(props) => props.theme.config.colors.primary};

  svg {
    margin-right: 0 !important;
  }
`;

export const RemoveSelectedOpt = styled.div`
  margin-left: 4px;
  padding: 5px 2px;
  cursor: pointer;
  transition: 0.2s ease-in;
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${(props) => props.theme.config.colors.gray_100};
  }
`;

export const SearchBox = styled.div`
  background-color: ${(props) => props.theme.config.colors.light};
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    props.borders
      ? props.theme.config.colors.gray_300
      : props.theme.config.colors.light};
  border-radius: 6px;
  overflow: hidden;
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  min-width: ${(props) => (props.width ? 'unset' : 'fit-content')};
  box-shadow: 2px 2px 25px 4px rgb(0, 0, 0, 0.05);
`;

export const SearchHead = styled.div`
  position: relative;
  padding: 15px;

  svg {
    position: absolute;
    z-index: 1;
    top: 28px;
    left: 27px;
    cursor: pointer;
  }

  input {
    width: -webkit-fill-available;
    border: 1px solid ${(props) => props.theme.config.colors.gray_300};
    padding-left: 45px !important;
  }
`;

export const OptionsBox = styled.div`
  max-height: 184px;
  padding-right: 5px;
  ${(props) => props.hideScroll && `overflow: auto`};
  scrollbar-color: ${(props) =>
    props.theme.config.colors.gray_300} !important; // Firefox
  scrollbar-width: thin !important; // Firefox
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.config.colors.gray_100};
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.config.colors.gray_300};
    border-radius: 8px;
    border: 4px solid ${(props) => props.theme.config.colors.gray_100};
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.config.colors.gray_300};
  }
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  cursor: pointer;
  background-color: ${(props) => props.theme.config.colors.light};
  color: ${(props) => props.theme.config.colors.gray_400};
  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.config.colors.gray_300};
  }

  svg {
    margin-right: 10px;
  }

  &:hover div {
    color: ${(props) => props.theme.config.colors.primary};
  }
`;

export const OptionLabelFlex = styled.div`
  display: flex;
  align-items: center;
  width: ${(props) => (props.fullWidth ? '100%' : '194px')};
`;

export const OptionLabel = styled.div`
  color: ${(props) => props.theme.config.colors.gray_400};
  max-width: ${(props) => (props.fullWidth ? '100%' : 'calc(100% - 35px)')};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: color 0.2s ease-in;
`;

export const Empty = styled.div`
  text-align: center;
  padding: 2.4rem;
  svg {
    margin-bottom: 15px;
    width: 56px;
    height: 56px;
    block-size: auto;
  }
  span {
    display: block;
  }
`;

export const EmptyTitle = styled.span`
  ${(props) => props.theme.config.typograph.default};
  font-size: 24px;
  font-weight: bold;
  color: ${(props) => props.theme.config.colors.secondary};
  margin-bottom: 15px;
`;

export const EmptyDescription = styled.span`
  ${(props) => props.theme.config.typograph.xsmall};
  color: ${(props) => props.theme.config.colors.text_base};
`;

export const Group = styled.div`
  padding: 0 0 2.4rem;
  background-color: ${(props) => props.theme.config.colors.light};
`;

export const GroupTitle = styled.div`
  padding: 0rem 1.5rem 0.5rem;
  background-color: ${(props) => props.theme.config.colors.light};
  color: ${(props) => props.theme.config.colors.gray_400} !important;
  font-size: 1.4rem;
`;

export const GroupOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;

  &:hover div {
    color: ${(props) => props.theme.config.colors.primary};
  }
`;

export const GroupOptionLabel = styled.div`
  padding: 0.5rem 1.5rem;
  background-color: ${(props) => props.theme.config.colors.light};
  color: ${(props) => props.theme.config.colors.text_base};
  transition: all 0.15s;
  font-size: 1.6rem;
  max-width: ${(props) => (props.fullWidth ? '100%' : 'calc(100% - 124px)')};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const OptionAdditionalInfo = styled.div`
  background-color: ${(props) => props.theme.config.colors.primary_100};
  color: ${(props) => props.theme.config.colors.primary};
  font-size: 1.2rem;
  padding: 0.4rem;
  border-radius: 0.4rem;
  max-width: 124px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const SkeletonContainer = styled.div`
  span.skeleton-element {
    height: 4.8rem;
    border-radius: 0.6rem;
  }
`;
