const VALID_TESTS = ['base', 'custom', 'invalid', 'practice'];

const parseFilters = ({
  testType,
  esEvaluator,
  evaluatorId,
  statusExercise,
  testId,
  groupId,
  testTag,
}) => {
  const scheduleStatus = ['Scheduled', 'Missed', 'Canceled', 'Done'];

  switch (testType) {
    case 'base':
      return {
        status_exercise:
          statusExercise === 'Pending'
            ? 0
            : statusExercise === 'Completed'
            ? 1
            : '',
        es_evaluator: esEvaluator,
        test_id: testId,
        group_id: groupId,
        evaluator_id: evaluatorId,
      };
    case 'custom':
      return {
        group_id: groupId,
        custom_test_id: testId,
        evaluator_id: evaluatorId,
        schedule_status: scheduleStatus?.includes(statusExercise)
          ? statusExercise?.toUpperCase()
          : null,
        status_exercise:
          statusExercise === 'Pending'
            ? 0
            : statusExercise === 'Completed'
            ? 1
            : statusExercise === 'Skipped'
            ? 2
            : null,
      };
    case 'invalid':
      return {
        group_id: groupId,
        test_tag: testTag,
        es_evaluator: esEvaluator,
        evaluator_id: evaluatorId === 'all' ? null : evaluatorId,
      };
    case 'practice':
      return {
        test_id: testId,
        group_id: groupId,
      };
    default:
      return {};
  }
};

export const getSubmissionsURLFiltersParamsByTestType = ({
  testType,
  esEvaluator,
  evaluatorId,
  statusExercise,
  testId,
  groupId,
  testTag,
}) => {
  if (!testType || !VALID_TESTS.includes(testType))
    throw new Error('Invalid Test Type');

  return parseFilters({
    testType,
    esEvaluator,
    evaluatorId,
    statusExercise,
    testId,
    groupId,
    testTag,
  });
};
