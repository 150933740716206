import React from 'react';
import PropTypes from 'prop-types';

import { Col } from 'modules/Core/Common';

import * as S from './WidgetCardSubHeader.styles';

export const WidgetCardSubHeader = ({ size = 'small', children }) => {
  return (
    <Col xs={12} md={size === 'small' ? 12 : 6}>
      <S.SubHeader>{children}</S.SubHeader>
    </Col>
  );
};

WidgetCardSubHeader.propTypes = {
  size: PropTypes.oneOf(['small', 'large']),
  children: PropTypes.node.isRequired,
};
