import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { useTheme } from 'styled-components';
import { isDevelop, isLocal, currentEnv } from 'modules/Utils/Environment';
import {
  DevelopOutlineIcon,
  StageOutlineIcon,
} from '@edusynch/edusynch-svg-icons';
import * as S from './EnvIdentifier.styles';

const EnvIdentifier = () => {
  const theme = useTheme();

  return (
    <S.Container>
      <S.IdentifierContainer>
        <S.IdentifierText>
          {isLocal || isDevelop ? (
            <DevelopOutlineIcon color={theme.config.colors.light} />
          ) : (
            <StageOutlineIcon color={theme.config.colors.light} />
          )}
          {i18n.t('env-identifier.you-are-in')} <S.Env>{currentEnv}.</S.Env>
        </S.IdentifierText>
      </S.IdentifierContainer>
    </S.Container>
  );
};

EnvIdentifier.defaultProps = {
  opened: false,
};

EnvIdentifier.propTypes = {
  env: PropTypes.string,
};

export default EnvIdentifier;
