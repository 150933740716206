import styled, { css } from 'styled-components';
import { device } from 'modules/Utils';

export const Icon = styled.div`
  margin-right: 0.8rem;
`;

export const Header = styled.div`
  display: flex;

  h6 {
    ${(props) => props.theme.config.typograph.xsmall};
    color: ${(props) => props.theme.config.colors.text_base};

    span {
      color: ${(props) => props.theme.config.colors.gray};
    }
  }

  p {
    ${(props) => props.theme.config.typograph.default};
    font-weight: bold;
    color: ${(props) => props.theme.config.colors.primary};
  }

  @media ${device.desktop} {
    ${({ size }) =>
      size === 'small'
        ? css`
            margin-bottom: 2.4rem;
          `
        : ''};
  }

  @media ${device.mobile} {
    margin-bottom: 2.4rem;
  }
`;

export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContentFilter = styled.div`
  h6 {
    ${(props) => props.theme.config.typograph.xsmall};
    color: ${(props) => props.theme.config.colors.gray_400};
    margin-bottom: 0.8rem;
  }

  div {
    display: flex;
    align-items: center;

    p {
      ${(props) => props.theme.config.typograph.small};
      color: ${(props) => props.theme.config.colors.gray_400};
      font-weight: bold;
      margin-left: 0.8rem;
    }
  }
`;

export const ContentAction = styled.div``;
