import styled from 'styled-components';
import { device } from 'modules/Utils/Devices';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: ${(props) => props.theme.config.colors.light};
  min-height: 10.8rem;
  border-radius: 6px;
  padding: 2.6rem ${(props) => props.theme.config.spacing.spacing_4};
  margin-bottom: ${(props) => props.theme.config.spacing.spacing_6};
`;

export const User = styled.div`
  display: flex;
  flex-wrap: wrap;

  span {
    ${(props) => props.theme.config.typograph.quartenary};
    color: ${(props) => props.theme.config.colors.text_base};
    font-weight: 700;
  }

  @media ${device.tabletAndMobile} {
    flex-basis: 100%;
    margin-bottom: ${(props) => props.theme.config.spacing.spacing_4};
  }
`;

export const Info = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  div {
    padding: 0 ${(props) => props.theme.config.spacing.layout_6};

    & + div {
      border-left: 1px solid ${(props) => props.theme.config.colors.gray_300};
    }

    h6 {
      color: ${(props) => props.theme.config.colors.text_base};
      margin-bottom: ${(props) => props.theme.config.spacing.spacing_1};
    }

    span {
      display: inline-block;
      ${(props) => props.theme.config.typograph.default};
      font-weight: bold;
      color: ${(props) => props.theme.config.colors.text_base};
    }

    svg {
      display: inline-block;
      width: 1.6rem;
      margin-left: ${(props) => props.theme.config.spacing.spacing_1};
      transition: color 0.2s, opacity 0.2s;
      opacity: 0.5;

      path {
        fill: ${(props) => props.theme.config.colors.text_base};
      }

      &:hover path {
        fill: ${(props) => props.theme.config.colors.primary};
        opacity: 1;
      }
    }
  }

  @media ${device.tabletAndMobile} {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    div {
      width: inherit;
      padding: ${(props) => props.theme.config.spacing.layout_1} 0;

      &:last-child {
        padding-bottom: 0;
      }

      & + div {
        border-left: none;
        border-top: 1px solid ${(props) => props.theme.config.colors.gray_300};
      }
    }
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background: ${(props) => props.theme.config.colors.light};
  border-radius: 6px;
  padding: 2.4rem;
  min-height: 110px;
  margin-bottom: ${(props) => props.theme.config.spacing.spacing_6};

  @media ${device.tabletAndMobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const InfoContainer = styled.div`
  width: 60%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 80px;
  margin-left: 80px;

  @media ${device.tabletAndMobile} {
    width: 100%;
    grid-template-columns: 1fr;
    gap: 32px;
  }

  > div {
    span {
      display: block;
      margin-top: 1rem;
    }

    div:first-child {
      max-width: 5.6rem;
    }

    div:last-child {
      max-width: 21rem;
    }
  }
`;

export const UserContainer = styled.div`
  width: 26.5rem;

  span:first-child {
    min-width: 4.8rem;
    height: 4.8rem;
  }

  [type='string'] {
    height: 2.4rem;
    max-width: 200px;
  }

  @media ${device.tabletAndMobile} {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    span:first-child {
      flex: 0.5;
    }

    span:last-child {
      flex: 2;
      margin-bottom: 0;
    }
  }
`;
