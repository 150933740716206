export const createVersionModel = (version = {}) => {
  return {
    id: version.id || null,
    externalSoftwareId: version.external_software_id || '',
    version: version.version || '',
    versionType: version.version_type || '',
    build: version.build || 0,
    description: version.description || '',
    current: !!version.current,
  };
};

export const createVersionRequestModel = (version = {}) => {
  return {
    id: version.id,
    external_software_id:
      version.externalSoftwareId ||
      version.external_software_id ||
      version.external_software.id,
    version: version.version,
    version_type: version.versionType || version.version_type,
    build: version.build,
    description: version.description,
    current: !!version.current,
  };
};

export const VERSIONS_TYPES = [
  { id: 'Major', name: 'Major' },
  { id: 'Minor', name: 'Minor' },
  { id: 'Hotfix', name: 'Hotfix' },
];
