import { lazy } from 'react';
import { menuBuilder } from 'services/Routes.service';
import { PlansIcon } from '@edusynch/edusynch-svg-icons';

const SubscriptionContainer = lazy(() =>
  import('modules/Subscription/SubscriptionList/SubscriptionList.container')
);

const PlansMenu = menuBuilder([
  {
    Icon: PlansIcon,
    name: 'Plans',
  },
]).addSubmenu([
  {
    Icon: PlansIcon,
    name: 'Subscription',
    path: '/plans/subscription',
    exact: true,
    component: SubscriptionContainer,
    ability: 'plans',
  },
]);

export default PlansMenu;
