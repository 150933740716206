import styled, { css } from 'styled-components';

const getVariantStyles = (variant) => {
  switch (variant) {
    case 'primary':
      return css`
        svg path {
          fill: ${(props) => props.theme.config.colors.primary};
        }
      `;
    default:
      return css`
        svg path {
          fill: ${(props) => props.theme.config.colors.gray};
        }
      `;
  }
};

const getBorderBottomByVariant = (variant) => {
  switch (variant) {
    case 'primary':
      return css`
        border-bottom: 2px solid ${(props) => props.theme.config.colors.primary};
        padding-bottom: 0.8rem;
      `;
    default:
      return css`
        border-bottom: 2px solid ${(props) => props.theme.config.colors.gray};
        padding-bottom: 0.8rem;
      `;
  }
};

export const Button = styled.button.attrs({
  type: 'button',
})`
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  ${({ variant }) => getVariantStyles(variant)};
  ${({ variant, borderBottom }) =>
    borderBottom && getBorderBottomByVariant(variant)};

  svg {
    width: 1.6rem;
    height: 1.6rem;
  }
`;
