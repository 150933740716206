import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import { formDataFromObj } from 'modules/Api/RequestData';

let cancelToken;

const initialState = {
  loading: false,
  error: null,
  data: {
    widgets: [],
  },
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    requestDashboardWidgets: (state) => {
      state.loading = true;
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    receiveDashboardWidgetsList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        widgets: action.payload.widgets,
        total: action.payload.total_items,
      };
    },
    clearWidgetsList: (state) => {
      state.loading = false;
      state.data = {
        widgets: [],
      };
    },
    receiveWidgetsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

const Actions = dashboardSlice.actions;

const Selectors = {
  fetchListData: (state) => state.dashboard,
  dashboardLoading: ({ dashboard: { loading } }) => ({ loading }),
};

const Async = {
  fetchDashboardWidgetsList: () => async (dispatch) => {
    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestDashboardWidgets());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: '/cms/v1/dashboard/list',
      });

      action = Actions.receiveDashboardWidgetsList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveWidgetsError(e.message);
      }
    }

    action && dispatch(action);
  },

  saveWidgets:
    ({ data }) =>
    async (dispatch) => {
      dispatch(Actions.requestDashboardWidgets());
      try {
        const widgetsData = formDataFromObj(data);

        await request({
          method: 'POST',
          url: '/cms/v1/dashboard/create',
          data: widgetsData,
        });

        dispatch(Actions.receiveRequestSuccess());
      } catch (e) {
        dispatch(Actions.receiveGroupError());
      }
    },
};

const reducer = dashboardSlice.reducer;

export { reducer, Actions, Async, Selectors };
