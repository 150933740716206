import styled, { css } from 'styled-components';

const Menu = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const openedStyle = css`
  margin-right: auto;
`;

const closedStyle = css`
  margin-left: auto;
`;

const Bar = styled.div`
  height: 0.4rem;
  background-color: gray;
  ${({ middle, opened }) =>
    middle &&
    css`
      width: 80%;
      ${opened ? openedStyle : closedStyle}
    `}
`;

export { Menu, Bar };
