import { useRef, useCallback } from 'react';

export const useInfiniteScroll = ({ loading, hasMore, callback }) => {
  const observer = useRef();

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          callback();
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return {
    lastElementRef,
  };
};
