import { createSlice } from '@reduxjs/toolkit';
import { request } from 'modules/Api/HttpClient';
import { formDataFromObj } from 'modules/Api/RequestData';
import {
  SETTINGS_REMOVE_AVATAR_URL,
  STUDENT_LICENSES_CREATE_URL,
  STUDENT_LICENSES_UPDATE_URL,
  STUDENT_LICENSES_DELETE_URL,
} from 'modules/Api/Routes';
import { createStudentLicenseRequestModel } from 'modules/Users/Students/StudentUtils';

const initialState = {
  loading: false,
  error: null,
};

const studentLicenseSlice = createSlice({
  name: 'studentLicense',
  initialState,
  reducers: {
    cleanState: () => ({ ...initialState }),
    requestStudentLicense: (state) => {
      state.loading = true;
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    receiveRequestError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

const Actions = studentLicenseSlice.actions;

const Selectors = {
  studentLicenseLoading: ({ studentLicense: { loading } }) => ({ loading }),
};

const Async = {
  addStudentLicense:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestStudentLicense());
      try {
        const parsedStudentLicense = createStudentLicenseRequestModel(data);
        delete parsedStudentLicense.id;
        delete parsedStudentLicense.license_id;

        const studentLicenseData = formDataFromObj(parsedStudentLicense);

        const response = await request({
          method: 'POST',
          url: STUDENT_LICENSES_CREATE_URL,
          data: studentLicenseData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveRequestError());
        onError(e);
      }
    },

  removeAvatar:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      try {
        const data = formDataFromObj({ id });

        const response = await request({
          method: 'PUT',
          url: SETTINGS_REMOVE_AVATAR_URL,
          data,
        });
        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  updateStudentLicense:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestStudentLicense());
      try {
        const parsedStudentLicense = createStudentLicenseRequestModel(data);

        const studentLicenseData = formDataFromObj(parsedStudentLicense);

        const response = await request({
          method: 'PUT',
          url: STUDENT_LICENSES_UPDATE_URL,
          data: studentLicenseData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveRequestError());
        onError(e);
      }
    },

  deleteStudentLicense:
    ({ id, onSuccess, onError }) =>
    async () => {
      try {
        const response = await request({
          method: 'DELETE',
          url: `${STUDENT_LICENSES_DELETE_URL}?id=${id}`,
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },
};

const { reducer } = studentLicenseSlice;

export { reducer, Actions, Async, Selectors };
