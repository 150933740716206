import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'modules/Core/Common';

import * as S from './PaginationPrevButton.styles';

const PaginationPrevButton = ({ onClick, disabled }) => {
  return (
    <S.Button
      onClick={onClick}
      data-test="pagination-prev-button"
      disabled={disabled}
    >
      <S.Text>
        <Icon name="arrow-left" />
      </S.Text>
    </S.Button>
  );
};

PaginationPrevButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default PaginationPrevButton;
