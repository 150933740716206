import { format, durationToHuman } from 'modules/Utils/Date';

export const getChartLabelsByWidgetSize = (data, widgetSize) => {
  if (!data || !data?.days?.length) return [];

  if (widgetSize === 'large') return data.days;

  const compareStartDate = formatSmallChartLabelDate(
    data?.compare?.start,
    'dd'
  );
  const compareStartMonth = formatSmallChartLabelDate(
    data?.compare?.start,
    'MMM'
  );
  const compareEndDate = formatSmallChartLabelDate(data?.sessions?.end, 'dd');

  const startDate = formatSmallChartLabelDate(data?.sessions?.start, 'dd');
  const startMonth = formatSmallChartLabelDate(data?.sessions?.start, 'MMM');
  const endDate = formatSmallChartLabelDate(data?.sessions?.end, 'dd');

  return [
    `${compareStartDate} - ${compareEndDate} ${compareStartMonth}`,
    `${startDate} - ${endDate} ${startMonth}`,
  ];
};

export const formatChartLabels = (labels, intervalOption, widgetSize) => {
  if (widgetSize === 'small') return labels;

  return (
    labels?.map((item) =>
      intervalOption === '12'
        ? format(item?.date, 'MMM', 'parse')
        : format(item?.date, 'dd MMM', 'parse')
    ) || []
  );
};

export const formatChartValues = (data) => {
  return data?.days?.map((item) => item.session_count) || [];
};

export const formatTimeSpent = (data) => {
  return data?.days?.map((item) => item.time_spent) || [];
};

export const formatChartCompareValues = (data) => {
  return data?.compare_days?.map((item) => item.session_count) || [];
};

export const formatCompareTimeSpent = (data) => {
  return data?.compare_days?.map((item) => item.time_spent) || [];
};

export const formatChartScaleYStepSize = (formattedChartData) => {
  return formattedChartData?.length
    ? Math.ceil(Math.max(...formattedChartData) / 4)
    : 0;
};

export const formatDate = (date = '') => {
  if (!date) return '';

  const [year, month, day] = date.split('-');

  return format(new Date(year, month - 1, day), 'yyyy/MM/dd');
};

export const formatSmallChartLabelDate = (
  date = '',
  dateFormat = 'yyyy/MM/dd'
) => {
  if (!date) return '';

  const [year, month, day] = date.split('-');

  return format(new Date(year, month - 1, day), dateFormat);
};

export const formatChartData = (
  formattedChartLabels,
  formattedChartData,
  formattedChartCompareData,
  backgroundColor,
  compareBackgroundColor,
  label,
  widgetSize
) => {
  let datasets = [];

  if (widgetSize === 'small') {
    const compareTotal =
      formattedChartCompareData?.reduce((total, item) => (total += item), 0) ||
      0;
    const currentTotal =
      formattedChartData?.reduce((total, item) => (total += item), 0) || 0;
    const formattedSmallWidgetChartData = [compareTotal, currentTotal];

    datasets = [
      {
        label,
        data: formattedSmallWidgetChartData,
        backgroundColor: [compareBackgroundColor, backgroundColor],
        borderColor: [compareBackgroundColor, backgroundColor],
        borderWidth: widgetSize === 'small' ? 0 : 2,
        barThickness: 48,
      },
    ];
  } else {
    datasets = [
      {
        label,
        data: formattedChartData,
        backgroundColor: [backgroundColor],
        height: 10,
        borderColor: [backgroundColor],
        borderWidth: 2,
        cutout: '73%',
        lineTension: 0.5,
      },
      {
        label: `Compare ${label}`,
        data: formattedChartCompareData,
        backgroundColor: [compareBackgroundColor],
        height: 10,
        borderColor: [compareBackgroundColor],
        borderWidth: 2,
        cutout: '73%',
        lineTension: 0.5,
      },
    ];
  }

  return {
    labels: formattedChartLabels,
    datasets,
  };
};

export const formatChartOptions = (
  chartScaleYStepSize,
  timeSpentArray = [],
  compareTimeSpentArray = [],
  sessionsTitle = 'Sessions',
  timeSpentTitle = 'Time Spent',
  widgetSize,
  dataLabelColor = '#FFF'
) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 8,
        right: 8,
        top: 24,
        bottom: 8,
      },
    },
    plugins: {
      datalabels: {
        display: widgetSize === 'large' ? false : true,
        anchor: 'end',
        align: 'top',
        clamp: true,
        color: dataLabelColor,
        font: {
          size: 14,
          weight: 'bold',
        },
      },
      legend: {
        labels: {
          font: {
            size: 14,
            weight: 'bold',
          },
          usePointStyle: true,
          pointStyle: 'circle',
          pointStyleWidth: 10,
          boxWidth: 7,
          boxHeight: 7,
        },
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            return `${sessionsTitle} ${tooltipItem.formattedValue}`;
          },
          afterLabel: (tooltipItem) => {
            const timeSpentLabel =
              tooltipItem?.datasetIndex === 0
                ? timeSpentArray[tooltipItem?.dataIndex]
                : compareTimeSpentArray[tooltipItem?.dataIndex];
            return `${timeSpentTitle} ${durationToHuman(timeSpentLabel || '')}`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: widgetSize === 'small' ? false : true,
        },
      },
      y: {
        grid: {
          drawBorder: widgetSize === 'small' ? false : true,
          display: widgetSize === 'small' ? false : true,
        },
        ticks: {
          display: widgetSize === 'small' ? false : true,
          stepSize: chartScaleYStepSize,
        },
      },
    },
  };
};

export const MOCKED_DATA = {
  sessions: {
    session_count: 16029,
    time_spent: 58,
    start: '2024-03-08',
    end: '2024-03-14',
  },
  compare: {
    session_count: 0,
    time_spent: 0,
    start: '2024-03-01',
    end: '2024-03-07',
  },
  days: [
    {
      session_count: 81,
      date: '2022-11-11T20:33:06.572+00:00',
      time_spent: 50,
    },
    {
      session_count: 50,
      date: '2022-11-12T20:33:06.554+00:00',
      time_spent: 33,
    },
    {
      session_count: 75,
      date: '2022-11-13T20:33:06.545+00:00',
      time_spent: 95,
    },
    {
      session_count: 50,
      date: '2022-11-14T20:33:06.539+00:00',
      time_spent: 90,
    },
    {
      session_count: 30,
      date: '2022-11-15T20:33:06.530+00:00',
      time_spent: 45,
    },
    {
      session_count: 45,
      date: '2022-11-16T20:33:06.511+00:00',
      time_spent: 120,
    },
    {
      session_count: 25,
      date: '2022-11-17T20:33:06.496+00:00',
      time_spent: 60,
    },
  ],
  compare_days: [
    {
      session_count: 18,
      date: '2022-11-11T20:33:06.572+00:00',
      time_spent: 50,
    },
    {
      session_count: 5,
      date: '2022-11-12T20:33:06.554+00:00',
      time_spent: 33,
    },
    {
      session_count: 57,
      date: '2022-11-13T20:33:06.545+00:00',
      time_spent: 95,
    },
    {
      session_count: 15,
      date: '2022-11-14T20:33:06.539+00:00',
      time_spent: 90,
    },
    {
      session_count: 3,
      date: '2022-11-15T20:33:06.530+00:00',
      time_spent: 45,
    },
    {
      session_count: 54,
      date: '2022-11-16T20:33:06.511+00:00',
      time_spent: 120,
    },
    {
      session_count: 52,
      date: '2022-11-17T20:33:06.496+00:00',
      time_spent: 60,
    },
  ],
};
