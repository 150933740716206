import { format } from 'modules/Utils/Date';

export const getChartLabelsByWidgetSize = (data, widgetSize) => {
  if (!data || !data?.days_graph?.length) return [];
  if (widgetSize !== 'small') return data.days_graph;

  const labels = data.days_graph;

  const labelsInHalf = Math.floor(labels.length / 2);
  const lastLabelIndex = labels.length - 1;

  if (labels <= 3) return data.days_graph;

  return data.days_graph.map((label, index) => {
    if (index === 0 || index === labelsInHalf || index === lastLabelIndex) {
      return label;
    }
  });
};

export const formatChartLabels = (labels, intervalOption) => {
  return (
    labels?.map((item) =>
      intervalOption === '12'
        ? format(item?.date, 'MMM', 'parse')
        : format(item?.date, 'dd MMM', 'parse')
    ) || []
  );
};

export const formatChartValues = (data) => {
  return data?.days_graph?.map((item) => item.value) || [];
};

export const formatChartScaleYStepSize = (formattedChartData) => {
  return formattedChartData?.length
    ? Math.ceil(Math.max(...formattedChartData) / 4)
    : 0;
};

export const formatChartData = (
  formattedChartLabels,
  formattedChartData,
  backgroundColor,
  label
) => {
  return {
    labels: formattedChartLabels,
    datasets: [
      {
        label,
        data: formattedChartData,
        backgroundColor: [backgroundColor],
        height: 10,
        borderColor: [backgroundColor],
        borderWidth: 2,
        cutout: '73%',
        lineTension: 0.5,
      },
    ],
  };
};

export const formatChartOptions = (chartScaleYStepSize) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          font: {
            size: 14,
            weight: 'bold',
          },
          usePointStyle: true,
          pointStyle: 'circle',
          pointStyleWidth: 10,
          boxWidth: 7,
          boxHeight: 7,
        },
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      y: {
        min: 0,
        ticks: {
          stepSize: chartScaleYStepSize,
        },
      },
    },
  };
};

export const MOCKED_DATA = {
  total_users: 458362,
  total_by_day: 231,
  days_graph: [
    {
      value: 81,
      date: '2022-11-11T20:33:06.572+00:00',
    },
    {
      value: 50,
      date: '2022-11-12T20:33:06.554+00:00',
    },
    {
      value: 75,
      date: '2022-11-13T20:33:06.545+00:00',
    },
    {
      value: 50,
      date: '2022-11-14T20:33:06.539+00:00',
    },
    {
      value: 30,
      date: '2022-11-15T20:33:06.530+00:00',
    },
    {
      value: 45,
      date: '2022-11-16T20:33:06.511+00:00',
    },
    {
      value: 25,
      date: '2022-11-17T20:33:06.496+00:00',
    },
  ],
};
