import React from 'react';

import { Skeleton } from 'modules/Core/Common';

import * as S from './DashboardWidgets.styles';

export const DashboardWidgetsPlaceholder = () => {
  return (
    <S.Container>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((n) => (
        <React.Fragment key={n}>
          <Skeleton type="full" style={{ height: '18.4rem' }} />
        </React.Fragment>
      ))}
    </S.Container>
  );
};
