import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  SIMULATES_BASE_URL,
  SIMULATES_BY_ID_URL,
  SIMULATES_CHANGE_LIVE_URL,
} from 'modules/Api/Routes';

let cancelToken;

const initialState = {
  loading: false,
  error: null,
  data: {
    page: 1,
    perPage: 10,
    search: '',
    testId: '',
    total: '',
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
};

const SimulationsBaseSlice = createSlice({
  name: 'simulationsBase',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    requestSimulationsBase: (state) => {
      state.loading = true;
      state.error = null;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    /**
     * receive a success simulationsBase list response
     */
    receiveSimulationsBaseList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        simulationsBase: action.payload.simulates,
        total: action.payload.total_items,
      };
    },
    clearSimulationsBaseList: (state) => {
      state.loading = false;
      state.data = {
        page: 1,
        perPage: 10,
        search: '',
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
    },
    /**
     * receive an error response
     */
    receiveSimulationsBaseError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changeSimulationsBasePage: (state, action) => {
      state.data.page = action.payload;
    },
    changeSimulationsBaseSearch: (state, action) => {
      state.data.testId = action.payload.test_id;
      state.data.search = action.payload.search;
      state.data.page = 1;
    },
    changeSimulationsBaseFilters: (state, action) => {
      state.data.testId = action.payload?.test_id || '';
      state.data.search = action.payload?.search;
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
    },
    clearSimulationsBaseFilters: (state) => {
      state.data.search = '';
      state.data.testId = '';
      state.data.page = 1;
      state.data.perPage = 10;
      state.data.sort.sortType = null;
      state.data.sort.sortBy = null;
    },
    updateSimulation: (state, action) => {
      const index = state.data.simulationsBase.findIndex(
        (item) => item.id === action.payload.id
      );

      if (typeof index === 'number') {
        state.data.simulationsBase[index] = action.payload;
      }
    },
  },
});

const Actions = SimulationsBaseSlice.actions;

const Selectors = {
  fetchListData: (state) => state.simulationsBase,
  simulationsBaseLoading: ({ simulationsBase: { loading } }) => ({ loading }),
};

const Async = {
  fetchSimulationsBaseList: () => async (dispatch, getState) => {
    const {
      simulationsBase: {
        data: {
          page,
          perPage,
          search,
          testId,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestSimulationsBase());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: SIMULATES_BASE_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          test_id: testId,
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receiveSimulationsBaseList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveSimulationsBaseError(e.message);
      }
    }

    action && dispatch(action);
  },

  getSimulationsBaseById:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestSimulationsBase());

      try {
        const response = await request({
          method: 'GET',
          url: `${SIMULATES_BY_ID_URL}?id=${id}`,
        });

        action = Actions.receiveRequestSuccess();
        onSuccess(response);
      } catch (e) {
        action = Actions.setLoading(false);
        onError(e);
      }

      dispatch(action);
    },

  setLive:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.setLoading(true));

      try {
        const response = await request({
          method: 'PUT',
          url: SIMULATES_CHANGE_LIVE_URL,
          data,
        });

        dispatch(Actions.updateSimulation(response.data.content));
        onSuccess(response);
      } catch (e) {
        onError(e);
      } finally {
        dispatch(Actions.setLoading(false));
      }
    },
};

const { reducer } = SimulationsBaseSlice;

export { reducer, Actions, Async, Selectors };
