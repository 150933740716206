import React from 'react';
import PropTypes from 'prop-types';
import { TablePagination } from 'modules/Core/Common';
import i18n from 'i18next';
const getColumns = (additional, actionsComponent) => [
  ...additional,

  {
    title: i18n.t('common-words.actions'),
    key: 'actions',
    sort: false,
    render: actionsComponent,
  },
];

const TableContainer = ({
  data,
  actions,
  onNextPage,
  actionsComponent,
  additionalColumns,
  type,
  onToggleClick,
  ...props
}) => (
  <TablePagination
    {...props}
    columns={getColumns(additionalColumns, actionsComponent)}
    data={data[type]}
    additionalData={actions}
    pagination={{
      total: data.total,
      perPage: data.perPage,
      page: data.page,
      onNextPage,
      onToggleClick: onToggleClick,
    }}
  />
);

TableContainer.defaultProps = {
  additionalColumns: [],
};

TableContainer.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  actionsComponent: PropTypes.any,
  type: PropTypes.string,
  onNextPage: PropTypes.func,
  additionalColumns: PropTypes.array,
  actions: PropTypes.object,
  onToggleClick: PropTypes.func,
};

export default TableContainer;
