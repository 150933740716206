import React from 'react';
import PropTypes from 'prop-types';

import { RCTooltip as ReactTooltip } from './RCTooltip.styles';

export const RCTooltip = ({
  placement = 'right',
  effect = 'solid',
  fontColor = '#fff',
  backgroundColor = '#0075EA',
  arrowColor = '#0075EA',
  offset,
  children,
  disabled = false,
  ...props
}) => {
  return disabled ? (
    children
  ) : (
    <ReactTooltip
      place={placement}
      effect={effect}
      fontColor={fontColor}
      backgroundColor={backgroundColor}
      arrowColor={arrowColor}
      className="edusynch-tooltip"
      offset={offset}
      {...props}
    >
      {children}
    </ReactTooltip>
  );
};

RCTooltip.propTypes = {
  placement: PropTypes.string,
  effect: PropTypes.string,
  fontColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  arrowColor: PropTypes.string,
  offset: PropTypes.object,
  disabled: PropTypes.any,
  children: PropTypes.node,
};
