const findSelected = (array, idToFind) =>
  array?.find((item) => item?.id === idToFind);

const removeTags = (array, tagsToRemove) =>
  array?.filter((option) => !tagsToRemove?.includes(option?.tag));

export const formatGeneralOptions = (array, exportFilter) => {
  const removeOnCategories = ['SPEAKING', 'READING_WRITING', 'WRITING'];

  const selectedCategory = findSelected(
    exportFilter?.data?.categories?.data,
    exportFilter?.categoryTag
  );

  const arrayOfTagsToRemove =
    exportFilter.resultsScreen === 'institutions-analytics'
      ? [
          'show_skipped_questions',
          'show_correct_answers',
          'show_wrong_answers',
          'show_perc_correct',
        ]
      : ['show_correct_answers', 'show_wrong_answers', 'show_perc_correct'];

  const tagsToRemove = removeOnCategories?.includes(selectedCategory?.tag)
    ? arrayOfTagsToRemove
    : [];

  exportFilter.resultsScreen !== 'institutions-list' &&
    tagsToRemove.push('show_total_logins');

  if (exportFilter.resultsScreen === 'institutions-list') {
    tagsToRemove.push('show_total_questions');
    tagsToRemove.push('show_avg_response_time');
    tagsToRemove.push('show_category');
  }

  const newArray = removeTags(array, tagsToRemove);

  return newArray;
};

export const shouldRenderOnlyGeneral = (exportFilter) => {
  if (exportFilter.resultsScreen === 'institutions-list') {
    return (
      !shouldRender('TOEFL', 'SPEAKING', exportFilter) &&
      !shouldRender('TOEFL', 'WRITING', exportFilter) &&
      !shouldRender('IELTS', 'WRITING', exportFilter) &&
      !shouldRender('IELTS', 'SPEAKING', exportFilter)
    );
  }

  return false;
};

export const shouldRender = (testTag, categoryTag, exportFilter) => {
  const render = {
    forTest: false,
    forCategory: false,
  };

  const selectedTest = findSelected(
    exportFilter?.data?.tests?.data,
    parseInt(exportFilter?.testId)
  );

  const selectedCategory = findSelected(
    exportFilter?.data?.categories?.data,
    exportFilter?.categoryTag
  );

  if (selectedTest) {
    render.forTest = selectedTest?.tag?.includes(testTag);
  }

  if (selectedCategory) {
    render.forCategory = selectedCategory?.tag?.includes(categoryTag);
  }

  if (!exportFilter?.categoryTag || exportFilter?.categoryTag === 'all') {
    render.forCategory = true;
  }

  return render.forTest && render.forCategory;
};

export const sessionOptions = [
  {
    label: 'Session ID',
    tag: 'show_session_id',
  },
  {
    label: 'Session Start Time',
    tag: 'show_start_time',
  },
  {
    label: 'Session End Time',
    tag: 'show_end_time',
  },
  {
    label: 'Session Score',
    tag: 'show_session_score',
  },
  {
    label: 'Session Log',
    tag: 'show_session_log',
    tooltip: 'Closed Browser, Ended session or Session not saved.',
  },
];

export const generalOptions = [
  {
    label: 'Test Type',
    tag: 'show_test_type',
  },
  {
    label: 'Category',
    tag: 'show_category',
  },
  {
    label: 'Time Spent',
    tag: 'show_time_spent',
  },
  {
    label: 'Time Spent (Answered Questions)',
    tag: 'show_time_spent_question',
  },
  {
    label: 'Login Count',
    tag: 'show_total_logins',
  },
  {
    label: 'Total Questions',
    tag: 'show_total_questions',
  },
  {
    label: 'Questions Viewed',
    tag: 'show_questions_viewed',
  },
  {
    label: 'Average Response Time',
    tag: 'show_avg_response_time',
  },
  {
    label: 'Correct Answers',
    tag: 'show_correct_answers',
  },
  {
    label: 'Wrong Answers',
    tag: 'show_wrong_answers',
  },
  {
    label: 'Skipped Questions',
    tag: 'show_skipped_questions',
  },
  {
    label: '% Correct',
    tag: 'show_perc_correct',
    tooltip: 'Reading and Listening',
  },
];

export const toeflSpeakingOptions = [
  {
    label: 'Independent 1 Questions',
    tag: 'show_speaking_independent1_questions',
  },
  {
    label: 'Integrated 2 Questions',
    tag: 'show_speaking_integrated2_questions',
  },
  {
    label: 'Integrated 3 Questions',
    tag: 'show_speaking_integrated3_questions',
  },
  {
    label: 'Integrated 4 Questions',
    tag: 'show_speaking_integrated4_questions',
  },
];

export const toeflWritingOptions = [
  {
    label: 'Independent Questions',
    tag: 'show_writing_independent_questions',
  },
  {
    label: 'Integrated Questions',
    tag: 'show_writing_integrated_questions',
  },
];

export const ieltsWritingOptions = [
  {
    label: 'Task 1',
    tag: 'show_task1',
  },
  {
    label: 'Task 2',
    tag: 'show_task2',
  },
];

export const ieltsSpeakingOptions = [
  {
    label: 'Part 1',
    tag: 'show_part1',
  },
  {
    label: 'Part 2',
    tag: 'show_part2',
  },
  {
    label: 'Part 3',
    tag: 'show_part3',
  },
];

export const linguaskillWritingOptions = [
  {
    label: 'Part 1',
    tag: 'linguaskill_writing_part1',
  },
  {
    label: 'Part 2',
    tag: 'linguaskill_writing_part2',
  },
];

export const linguaskillSpeakingOptions = [
  {
    label: 'Part 1',
    tag: 'linguaskill_speaking_part1',
  },
  {
    label: 'Part 2',
    tag: 'linguaskill_speaking_part2',
  },
  {
    label: 'Part 3',
    tag: 'linguaskill_speaking_part3',
  },
  {
    label: 'Part 4',
    tag: 'linguaskill_speaking_part4',
  },
  {
    label: 'Part 5',
    tag: 'linguaskill_speaking_part5',
  },
];
