import React from 'react';
import PropTypes from 'prop-types';
import StudentIcon from 'assets/layout/empty-data/student-icon.svg';
import TeacherIcon from 'assets/layout/empty-data/teacher-icon.svg';
import * as S from './ResponseDataHandle.styles';
import i18n from 'i18next';

const getIconByType = (type) => {
  switch (type) {
    case 'student':
      return StudentIcon;
    case 'teacher':
      return TeacherIcon;
    default:
      return StudentIcon;
  }
};

const ResponseDataHandleEmpty = ({ type = 'student', ...props }) => (
  <S.Container {...props}>
    <S.Image src={getIconByType(type)} />
    <S.Title>{i18n.t('commons.tables.empty-data.title')}</S.Title>
    <S.Description>
      {i18n.t('commons.tables.empty-data.subtitle')}
    </S.Description>
  </S.Container>
);

ResponseDataHandleEmpty.propTypes = {
  type: PropTypes.string,
};

export default ResponseDataHandleEmpty;
