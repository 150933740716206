export const checkValueInRangeAndReturnIndex = (listOfValues, value) => {
  if (!listOfValues || !listOfValues.length) return 0;
  const valueInRange = listOfValues.reduce((max, current, index) => {
    const anterior = listOfValues[index - 1] || 0;
    if (value > anterior && value <= current) {
      return current > max ? current : max;
    }
    return max;
  }, 0);
  return listOfValues.findIndex((i) => i === valueInRange);
};
