const TYPES_MAP = {
  super_admin: 'Super Admin',
  group_admin: 'Group Admin',
  school_admin: 'School Admin',
  evaluator: 'Evaluator',
  teacher: 'Teacher',
  Support: 'Support',
  license_admin: 'License Admin',
  enterprise: 'Enterprise Admin',
  unit_admin: 'Unit Admin',
  creator: 'Creator',
};

const prettyUserType = (userType) => TYPES_MAP[userType];

const isSuperAdmin = (userType) => userType.includes('super_admin');

const isGroupAdmin = (userType) => userType.includes('group_admin');

const isUnitAdmin = (userType) => userType.includes('unit_admin');

const isSchoolAdmin = (userType) => userType.includes('school_admin');

const isAdmin = (userType) => userType.includes('admin');

const isEvaluator = (userType) => userType.includes('evaluator');

const isCreator = (userType) => userType.includes('creator');

export {
  prettyUserType,
  isSuperAdmin,
  isGroupAdmin,
  isUnitAdmin,
  isSchoolAdmin,
  isAdmin,
  isEvaluator,
  isCreator,
};
