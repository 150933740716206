import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import {
  Actions as AppActions,
  Selectors as AppSelectors,
} from 'modules/Core/AppSlice';
import { Selectors as AuthSelectors } from 'modules/Auth/AuthSlice';
import { Async as AsyncAuth } from 'modules/Auth/AuthSlice';
import { AppCommon } from './AppCommon';
import { useZendeskChat } from 'modules/Core/Hooks';
import { isProduction } from 'modules/Utils/Environment';
import * as S from './AppLayout.style';

const AppLayout = ({ children, routes, user, whitelabel, ...props }) => {
  const { opened, clicked, openedResponsive } = useSelector(
    AppSelectors.sidebar
  );
  const tokenExpired = useSelector(AuthSelectors.isTokenExpired);
  const dispatch = useDispatch();
  const { initZendeskChat } = useZendeskChat();

  const onLogout = () => dispatch(AsyncAuth.logout());
  const toggleSidebar = () => dispatch(AppActions.toggleSidebar());
  const toggleOpenSidebar = () => dispatch(AppActions.toggleOpenSidebar());
  const toggleCloseSidebar = () => dispatch(AppActions.toggleCloseSidebar());
  const toggleSidebarDeactivated = () =>
    dispatch(AppActions.toggleSidebarDeactivated());
  const toggleResponsiveSidebarOpen = () =>
    dispatch(AppActions.toggleResponsiveSidebarOpen());
  const toggleResponsiveSidebarCloseCallback = () =>
    dispatch(AppActions.toggleResponsiveSidebarClose());

  const toggleResponsiveSidebarClose = useCallback(() => {
    toggleResponsiveSidebarCloseCallback();
    toggleCloseSidebar();
  });

  useEffect(() => {
    if (!tokenExpired) {
      initZendeskChat();
    }
  }, []);

  return (
    <>
      <AppCommon
        routes={routes}
        opened={opened}
        clicked={clicked}
        user={user}
        openedResponsive={openedResponsive}
        whitelabelName={whitelabel.name}
        onLogoutClick={onLogout}
        onMenuClick={toggleSidebar}
        toggleOpenSidebar={toggleOpenSidebar}
        toggleCloseSidebar={toggleCloseSidebar}
        toggleSidebarDeactivated={toggleSidebarDeactivated}
        toggleResponsiveSidebarOpen={toggleResponsiveSidebarOpen}
        toggleResponsiveSidebarClose={toggleResponsiveSidebarClose}
        {...props}
      />

      <S.Content hasIdentifierEnv={!isProduction} opened={opened}>
        {children}
      </S.Content>
    </>
  );
};

AppLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  routes: PropTypes.array,
  user: PropTypes.object,
  whitelabel: PropTypes.object,
};

export default AppLayout;
