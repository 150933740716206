import styled from 'styled-components';
import NumericInput from 'react-numeric-input';

export const NumberField = styled(NumericInput)`
  display: block;
  padding: 0 2rem 0 4rem;
  width: 100%;
  height: 4.8rem;
  border: 1px solid ${(props) =>
    props.theme.config.colors.gray_300}; !important;
  background: ${(props) => props.theme.config.colors.light};
  border-radius: 0.6rem !important;
  color: ${(props) => props.theme.config.colors.text_base};
  font-size: 1.6rem !important;
  font-weight: 700;
  outline: none;
  width: 9.5rem !important;
  padding-left: 1.5rem !important;
  color: ${(props) => props.theme.config.colors.gray_400};

  & + b {
    right: 0 !important;
    top: 0 !important;
    border-top-right-radius: 0.6rem!important;

    &,
    & + b {
      width: ${(props) => props.theme.config.spacing.spacing_5} !important;
      background: ${(props) => props.theme.config.colors.gray_300}; !important;
      border: none !important;
      box-shadow: none !important;
      cursor: pointer !important;

      i {
        display: none;
      }
    }

    & + b {
      right: 0 !important;
      bottom: 0 !important;
      border-top: 1px solid ${(props) =>
        props.theme.config.colors.gray_300}; !important;
      border-bottom-right-radius: 0.6rem!important;
    }
  }

  &:disabled {
    color: ${(props) => props.theme.config.colors.text_base};
    background: ${(props) => props.theme.config.colors.gray_200};
    border: 1px solid ${(props) => props.theme.config.colors.gray_300};
  }
`;

export const Container = styled.div`
  display: block;
  position: relative;
  width: max-content;

  svg {
    &.ArrowDownIcon,
    &.ArrowUpIcon {
      position: absolute;
      right: 6px;
      z-index: 10;
      pointer-events: none;
      width: 1.4rem;
    }

    &.ArrowDownIcon {
      bottom: 0px;
    }

    &.ArrowUpIcon {
      top: 0px;
    }
  }
`;
