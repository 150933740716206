import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import i18n from 'i18next';

import { ListHeader, Button, Icon } from 'modules/Core/Common';
import { useWidgets } from 'modules/Dashboard/Widgets/Hooks';
import { useMediaQuery, MOBILE_SIZE } from 'modules/Core/Hooks';
import { DashboardSecondIcon } from '@edusynch/edusynch-svg-icons';
import * as S from './WidgetsListHeader.styles';

export const WidgetsListHeader = ({
  onAddWidget,
  onSaveDashboard,
  loading,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(MOBILE_SIZE);

  const { state } = useWidgets();

  return (
    <S.Container>
      <ListHeader
        title={i18n.t('modules.widgets.title')}
        subtitle={i18n.t('modules.widgets.subtitle')}
        IconName={DashboardSecondIcon}
      />
      <S.ActionsContainer>
        <Button medium transparent onClick={onAddWidget}>
          <Icon name="plus" color={theme.config.colors.primary} />
          {i18n.t(`modules.widgets.${isMobile ? 'widget' : 'add_widget'}`)}
        </Button>
        {state?.unsavedChanges && (
          <Button
            medium
            hasLoading
            loading={loading}
            onClick={onSaveDashboard}
            disabled={loading}
            data-action="save-dashboard"
          >
            {i18n.t('modules.widgets.save_dashboard')}
          </Button>
        )}
      </S.ActionsContainer>
    </S.Container>
  );
};

WidgetsListHeader.propTypes = {
  onAddWidget: PropTypes.func,
  onSaveDashboard: PropTypes.func,
  loading: PropTypes.bool,
};
