import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'modules/Core/Common';
import { useTheme } from 'styled-components';
import EditInterval from '../ListIntervals/EditInterval';
import { formatDatetUTCToTimezone } from 'modules/Utils/Date';
import { useAuth } from 'modules/Auth/Hooks';

import * as S from './ListIntervals.styles';

const ListIntervals = ({
  values,
  arrayHelpers,
  isAddForm,
  deleteInterval,
  setFieldValue,
  user,
}) => {
  const theme = useTheme();
  const { userDateFormat, userTimeFormat, userTimezone } = useAuth();

  const [openEditInterval, setOpenEditInterval] = useState([]);
  const filteredIntervals = values?.intervals?.filter(
    (item) => !item._destroy && !item?.isAdd
  );

  const getIndex = (interval) =>
    values?.intervals?.findIndex((item) => item === interval);

  return (
    <S.Container>
      <S.THeadeContainer>
        <S.THead>
          <tr>
            <S.Th width="45%">Start Date</S.Th>
            <S.Th width="45%">End Date</S.Th>
            <S.Th width="10%" />
          </tr>
        </S.THead>
      </S.THeadeContainer>
      <S.TableContainer hasScroll={values?.intervals?.length >= 5}>
        {filteredIntervals?.length && isAddForm?.isEdit ? (
          <>
            {filteredIntervals?.map((interval, index) => (
              <S.IntervalContainer key={index}>
                <S.Table>
                  <tbody>
                    {!openEditInterval?.find(
                      (item) => item?.id === interval?.id
                    ) ? (
                      <S.Tr>
                        <S.Td width="45%">
                          {formatDatetUTCToTimezone(
                            interval?.start_date,
                            userDateFormat,
                            userTimeFormat,
                            userTimezone
                          )}
                        </S.Td>
                        <S.Td width="45%">
                          {formatDatetUTCToTimezone(
                            interval?.end_date,
                            userDateFormat,
                            userTimeFormat,
                            userTimezone
                          )}
                        </S.Td>
                        <S.Td width="10%">
                          <S.ActionsContainer>
                            <S.ActionButton
                              onClick={() => {
                                const formated = [
                                  ...openEditInterval,
                                  interval,
                                ];
                                setOpenEditInterval(formated);
                              }}
                            >
                              <Icon name="edit" />
                            </S.ActionButton>
                            <S.ActionButton
                              onClick={() =>
                                deleteInterval(values, interval, arrayHelpers)
                              }
                            >
                              <Icon
                                name="delete"
                                color={theme.config.colors.tertiary_300}
                              />
                            </S.ActionButton>
                          </S.ActionsContainer>
                        </S.Td>
                      </S.Tr>
                    ) : (
                      <EditInterval
                        handleCloseEdit={() => {
                          const base = openEditInterval?.filter(
                            (item) => item?.id !== interval?.id
                          );
                          const toRestore = openEditInterval?.find(
                            (item) => item?.id === interval?.id
                          );

                          setFieldValue(
                            `intervals.${getIndex(interval)}`,
                            toRestore
                          );
                          setOpenEditInterval(base);
                        }}
                        handleDeleteInterval={() =>
                          deleteInterval(values, interval, arrayHelpers)
                        }
                        setFieldValue={setFieldValue}
                        index={getIndex(interval)}
                        interval={interval}
                        user={user}
                      />
                    )}
                  </tbody>
                </S.Table>
              </S.IntervalContainer>
            ))}
          </>
        ) : (
          <S.EmphyData>
            <Icon
              name="table-empty-data"
              color={theme.config.colors.gray_300}
            />
            <h2>No Intervals added yet.</h2>
            <span>Add an Interval here.</span>
          </S.EmphyData>
        )}
      </S.TableContainer>
    </S.Container>
  );
};

ListIntervals.propTypes = {
  values: PropTypes.object.isRequired,
  arrayHelpers: PropTypes.object,
  isAddForm: PropTypes.object,
  deleteInterval: PropTypes.func,
  setFieldValue: PropTypes.func,
  user: PropTypes.object,
};

export default ListIntervals;
