import { useState } from 'react';

export const useToggleProfileDropdown = () => {
  const [toggleProfileDropdownId, setToggleProfileDropdownId] = useState();

  const handleToggleProfileDropdown = (id, isOpen) => {
    if (!isOpen) return setToggleProfileDropdownId();

    const isSameId = +toggleProfileDropdownId === +id;

    if (isSameId) {
      setToggleProfileDropdownId();
    } else {
      setToggleProfileDropdownId(id);
    }
  };

  return {
    toggleProfileDropdownId,
    handleToggleProfileDropdown,
  };
};
