import React from 'react';
import PropTypes from 'prop-types';

import { TableEmptyData } from 'modules/Core/Common';
import * as S from './EmptyData.styles';

export const EmptyData = ({
  iconSize = '5.6rem',
  iconColor = 'gray',
  text = '',
  center,
  textStyle,
  ...props
}) => {
  return (
    <S.Container center={center} iconColor={iconColor} {...props}>
      <TableEmptyData fontSize={iconSize} />
      {text && <p style={textStyle}>{text}</p>}
    </S.Container>
  );
};

EmptyData.propTypes = {
  iconSize: PropTypes.string,
  iconColor: PropTypes.string,
  center: PropTypes.bool,
  text: PropTypes.string,
  textStyle: PropTypes.object,
};
