import { useSelector } from 'react-redux';

import { Selectors } from 'modules/Auth/AuthSlice';
import mockedWidgets from 'modules/Dashboard/Common/mocked-widgets.json';

export const useAuth = () => {
  const authData = useSelector(Selectors.selectAuthData);
  const isSuperAdmin = useSelector(Selectors.isSuperAdmin);
  const isGroupAdmin = useSelector(Selectors.isGroupAdmin);
  const isUnitAdmin = useSelector(Selectors.isUnitAdmin);
  const isSchoolAdmin = useSelector(Selectors.isSchoolAdmin);
  const isAdmin = useSelector(Selectors.isAdmin);
  const isOxfordAdmin = useSelector(Selectors.isOxfordAdmin);
  const isEvaluator = useSelector(Selectors.isEvaluator);
  const userTimezone = useSelector(Selectors.timezone);
  const userDashboardWidgets = useSelector(Selectors.dashboardWidgets);
  const timeFormat = useSelector(Selectors.timeFormat);
  const dateFormat = useSelector(Selectors.dateFormat);

  const isAMPM = timeFormat == 12;
  const userTimeFormat = isAMPM ? 'hh:mm a' : 'HH:mm';
  const userTimeFormatSeconds = isAMPM ? 'hh:mm:ss a' : 'HH:mm:ss';
  const userDateFormat =
    typeof dateFormat === 'string'
      ? dateFormat?.replace('mm', 'MM')
      : 'mm/dd/yy';
  const userWidgets = userDashboardWidgets || mockedWidgets;
  const userHasWhitelabel = !!authData?.user?.group?.whitelabel;
  const canEvaluate = useSelector(Selectors.canEvaluate);
  const canChangeEvaluators = useSelector(Selectors.canChangeEvaluators);

  return {
    authData,
    isSuperAdmin,
    isGroupAdmin,
    isUnitAdmin,
    isSchoolAdmin,
    isAdmin,
    isOxfordAdmin,
    isEvaluator,
    isAMPM,
    userTimezone,
    userTimeFormat,
    userTimeFormatSeconds,
    userDateFormat,
    userWidgets,
    userHasWhitelabel,
    canEvaluate,
    canChangeEvaluators,
  };
};
