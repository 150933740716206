import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

import { Button, IconRoundBox } from 'modules/Core/Common';
import { useToggleDropdown } from 'modules/Core/Hooks';
import { ExclamationIcon } from '@edusynch/edusynch-svg-icons';
import i18n from 'i18next';

import * as S from './ConfirmTooltip.styles';

export const ConfirmTooltip = ({
  title = 'Are you sure?',
  subtitle,
  onConfirm,
  elementRef,
  children,
}) => {
  const theme = useTheme();

  const dropdownRef = useRef();

  const { showDropdown, setShowDropdown } = useToggleDropdown(
    elementRef,
    dropdownRef
  );

  return (
    <S.Container
      data-cy="confirmTooltip.container"
      isFilterOpen={!!showDropdown}
      onClick={() => setShowDropdown(!showDropdown)}
    >
      {children}
      {showDropdown && (
        <S.Dropdown ref={dropdownRef}>
          <S.Confirm>
            <IconRoundBox
              withBg
              bg={theme.config.colors.primary_100}
              style={{ margin: '0 auto' }}
            >
              <ExclamationIcon />
            </IconRoundBox>
            <h4>{title}</h4>
            {subtitle && <p>{subtitle}</p>}
            <S.Actions>
              <Button
                transparent
                medium
                onClick={() => {
                  setShowDropdown(false);
                }}
              >
                {i18n.t('commons.buttons.cancel-button')}
              </Button>
              <Button
                dataCy="confirmTooltip.confirmButton"
                medium
                onClick={() => {
                  onConfirm();
                  setShowDropdown(false);
                }}
              >
                {i18n.t('commons.buttons.confirm-button')}
              </Button>
            </S.Actions>
          </S.Confirm>
        </S.Dropdown>
      )}
    </S.Container>
  );
};

ConfirmTooltip.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  elementRef: PropTypes.any,
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.node,
};
