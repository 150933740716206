import React from 'react';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import {
  Icon,
  InfoIcon,
  IconRoundBox,
  Button,
  SearchBar,
} from 'modules/Core/Common';
import { Can } from 'hoc/Ability';
import { useTheme } from 'styled-components';
import i18n from 'i18next';

import * as S from './ListHeader.styles';

const ListHeader = ({
  title,
  isSearch,
  subtitle,
  IconName,
  actionsType,
  hasFilters,
  onAddButtonClick,
  onSaveFilterButtonClick,
  children,
  containerStyles,
  justifyContent,
  backLink,
  backLinkIcon = false,
  subject,
  IconNameButtonAdd,
  defaultSearch,
  showSearchbar = true,
  customBtnTitle,
  ...props
}) => {
  const theme = useTheme();

  const renderActions = () => {
    if (actionsType === 'default') {
      return (
        <S.ActionsContainer {...props}>
          {showSearchbar && (
            <SearchBar
              type="search"
              placeholder={i18n.t('common-words.search')}
              hasFilters={hasFilters}
              defaultSearch={defaultSearch}
              onSaveFilterButtonClick={onSaveFilterButtonClick}
            />
          )}
          <Can I="create" a={subject}>
            <S.ButtonAddContainer>
              <Button
                medium
                type="button"
                onClick={onAddButtonClick}
                data-cy="list-header-add-button"
              >
                {IconNameButtonAdd && (
                  <IconNameButtonAdd color={theme.config.colors.light} />
                )}
                {isSearch
                  ? i18n.t('common-words.search')
                  : title
                  ? `${i18n.t('common-words.add')} ${pluralize.singular(
                      customBtnTitle || title
                    )}`
                  : `${i18n.t('common-words.add')} `}
              </Button>
            </S.ButtonAddContainer>
          </Can>
        </S.ActionsContainer>
      );
    }
    if (actionsType === 'custom') {
      return <S.ActionsContainer {...props}>{children}</S.ActionsContainer>;
    }
    return null;
  };

  return (
    <S.Container
      style={containerStyles}
      justifyContent={justifyContent}
      {...props}
    >
      {IconName ? (
        <InfoIcon
          thin
          before={
            <IconRoundBox withBg bg={theme.config.colors.primary_100}>
              <IconName color={theme.config.colors.primary} type="medium" />
            </IconRoundBox>
          }
        >
          {backLink ? (
            <S.SubtitleLink data-cy="list-header-subtitle" to={backLink}>
              {backLinkIcon && <Icon name="arrow-left" size="small" />}
              {subtitle}
            </S.SubtitleLink>
          ) : subtitle ? (
            <S.Subtitle data-cy="list-header-subtitle">{subtitle}</S.Subtitle>
          ) : null}
          <S.Title data-cy="list-header-title">{title}</S.Title>
        </InfoIcon>
      ) : (
        <S.Title data-cy="list-header-title">{title}</S.Title>
      )}
      {renderActions()}
    </S.Container>
  );
};

ListHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  IconName: PropTypes.func,
  actionsType: PropTypes.string,
  hasFilters: PropTypes.bool,
  onSearchChange: PropTypes.func,
  onAddButtonClick: PropTypes.func,
  onSaveFilterButtonClick: PropTypes.func,
  children: PropTypes.node,
  containerStyles: PropTypes.object,
  isSearch: PropTypes.bool,
  justifyContent: PropTypes.string,
  backLink: PropTypes.string,
  backLinkIcon: PropTypes.bool,
  subject: PropTypes.string,
  IconNameButtonAdd: PropTypes.func,
  defaultSearch: PropTypes.string,
  showSearchbar: PropTypes.bool,
  customBtnTitle: PropTypes.string,
};

export default ListHeader;
