import styled, { css } from 'styled-components';

const Title = styled.span`
  ${(props) => props.theme.config.typograph.mico};

  color: ${(props) => props.theme.config.colors.gray_800};

  ${(props) =>
    props.xxsmall &&
    css`
      font-size: 0.9rem;
      font-weight: 700;
      text-transform: uppercase;
    `}
`;

export { Title };
