import styled from 'styled-components';
import { device } from 'modules/Utils/Devices';

export const Container = styled.div`
  display: block;
  background: ${(props) => props.theme.config.colors.light};
  border-radius: 0.6rem;
  margin-bottom: ${(props) => props.theme.config.spacing.spacing_5};
`;

export const Loading = styled.div`
  display: flex;
  flex-flow: wrap;

  > div {
    padding: 2rem ${(props) => props.theme.config.spacing.layout_4};
  }
`;

export const Evaluators = styled.div`
  display: flex;
  align-items: center;

  > div {
    margin-right: 1rem;
    padding: 0;

    > div {
      width: 3.2rem;
      padding: 0;
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }
`;

export const AnswerActions = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 2rem 1.6rem;

  button.last-button {
    margin-left: 1.2rem;
  }
`;

export const DataContainer = styled.div`
  display: flex;

  > div {
    padding: 0.4rem 2rem;

    @media ${device.desktopLarge} {
      padding: 0.4rem 4rem;
    }

    & + div {
      border-left: 1px solid #e1e0e7;
    }

    h6 {
      color: ${(props) => props.theme.config.colors.text_base};
      margin-bottom: ${(props) => props.theme.config.spacing.spacing_1};
    }

    span {
      ${(props) => props.theme.config.typograph.default};
      color: ${(props) => props.theme.config.colors.text_base};
      display: inline-block;
      font-weight: bold;
      max-width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    svg {
      margin-left: ${(props) => props.theme.config.spacing.spacing_1};
      display: inline-block;
      width: 1.6rem;
      transition: color 0.2s, opacity 0.2s;
      opacity: 0.5;

      path {
        fill: ${(props) => props.theme.config.colors.text_base};
      }

      &:hover {
        path {
          fill: ${(props) => props.theme.config.colors.secondary};
          opacity: 1;
        }
      }
    }
  }

  @media ${device.desktopMediumMin} {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0 2.4rem 1.6rem;

    div {
      width: inherit;
      padding: ${(props) => props.theme.config.spacing.layout_1} 0;

      &:last-child {
        padding-bottom: 0;
      }

      & + div {
        border-left: none;
        border-top: 1px solid #e1e0e7;
      }
    }
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  height: 10.8rem;
  padding-left: ${(props) => props.theme.config.spacing.spacing_4};

  @media ${device.desktopMediumMin} {
    flex-direction: column;
    height: auto;
    padding-left: 0;
  }
`;

export const LoadingContainer = styled.div`
  div {
    display: block;
  }

  div:first-child {
    span {
      width: 5rem;
    }
  }

  div:last-child {
    span {
      width: 10rem;
      margin-top: ${(props) => props.theme.config.spacing.spacing_2};
    }
  }
`;

export const LoadingCategoryContainer = styled.div`
  display: flex;

  & + div {
    margin-left: 3rem;
  }

  div {
    span {
      margin-left: 1rem;
      width: 12rem;
    }
  }
`;

export const User = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-right: 6rem;
  height: 100%;
  min-width: 333px;

  span {
    color: ${(props) => props.theme.config.colors.text_base};
    ${(props) => props.theme.config.typograph.default};
    font-weight: 700;
  }

  @media ${device.desktopMediumMin} {
    flex-basis: 100%;
    padding: 1.6rem 2.4rem;
  }
`;

export const Log = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  background: ${(props) => props.theme.config.colors.gray_100};
  border-radius: 0 0.6rem 0.6rem 0;
`;

export const CategoryContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CategoryText = styled.span`
  ${(props) => props.theme.config.typograph.mico};
  color: ${(props) => props.theme.config.colors.text_base};
  margin-bottom: ${(props) => props.theme.config.spacing.spacing_1};
  display: block;
  white-space: nowrap;
`;

export const CategoryIcon = styled.div`
  display: block;
  margin-right: 0.6rem;

  svg {
    width: 1.5rem;

    path {
      fill: ${(props) => props.theme.config.colors.primary};
    }
  }
`;

export const CategoryName = styled.span`
  ${(props) => props.theme.config.typograph.default};
  font-weight: 800;
  color: ${(props) => props.theme.config.colors.primary};
  display: block;
  white-space: nowrap;
`;

export const InfoCard = styled.div`
  display: flex;
  align-items: center;

  & + div {
    margin-left: ${(props) => props.theme.config.spacing.spacing_6};
  }
`;

export const Title = styled.div`
  ${(props) => props.theme.config.typograph.default};
  font-weight: 700;
  display: block;
  color: ${(props) => props.theme.config.colors.primary};
`;

export const ValueCard = styled.div`
  display: block;
  margin-left: ${(props) => props.theme.config.spacing.spacing_4};
`;

export const Value = styled.span`
  ${(props) => props.theme.config.typograph.xsmall};
  color: ${(props) => props.theme.config.colors.primary};
  display: inline-block;
  background: ${(props) => props.theme.config.colors.primary_100};
  border-radius: 0.4rem;
  padding: 0.6rem 0.8rem;
  margin-right: ${(props) => props.theme.config.spacing.spacing_2};
`;

export const Subtitle = styled.span`
  ${(props) => props.theme.config.typograph.xsmall};
  color: ${(props) => props.theme.config.colors.text_base};
  text-transform: capitalize;
`;

export const SelectContainer = styled.div`
  margin-right: 1.2rem;
  display: block;
  position: relative;
  z-index: 3;

  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const CloseContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem 1.6rem;
`;

export const Close = styled.div`
  button {
    min-width: unset;
    padding: 1.7rem 1.3rem;
  }

  svg {
    margin-left: 0.7rem;

    path {
      fill: ${(props) => props.theme.config.colors.primary};
    }
  }
`;

export const BooleanContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 2.4rem;

  h5 {
    margin-right: 1.6rem;
    color: ${(props) => props.theme.config.colors.text_base};
    ${(props) => props.theme.config.typograph.default};
  }
`;
