import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InfoIcon, Avatar } from 'modules/Core/Common';
import * as S from './WelcomeMessage.styles';
import { useSelector } from 'react-redux';
import { Selectors } from 'modules/Auth/AuthSlice';
import { getRandomMotivationalQuote } from 'modules/Utils';
import i18n from 'i18next';

const WelcomeMessage = () => {
  const [motivationalQuote, setMotivationalQuote] = useState({});
  const session = useSelector(Selectors.selectAuthData);

  useEffect(() => {
    const motivationalQuote = getRandomMotivationalQuote();
    setMotivationalQuote(motivationalQuote);
  }, []);

  return (
    <S.Container>
      <S.UserAvatar>
        <InfoIcon
          before={
            <Avatar
              xlarge
              src={session?.user?.avatar?.thumb?.url}
              name={session?.user?.name}
            />
          }
        />
      </S.UserAvatar>
      <S.User>
        {i18n.t('modules.dashboard.welcome-message.hey')}{' '}
        <strong>{session?.user.name}</strong>,{' '}
        {i18n.t('modules.dashboard.welcome-message.welcome')}!
      </S.User>
      <S.MotivationContainer>
        <p>{motivationalQuote.quote}</p>
        <span>{motivationalQuote.author}</span>
      </S.MotivationContainer>
    </S.Container>
  );
};

WelcomeMessage.propTypes = {
  user: PropTypes.object,
};

export default WelcomeMessage;
