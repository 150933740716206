import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import i18n from 'i18next';
import { useTheme } from 'styled-components';

import { InputControl, Icon, Tooltip } from 'modules/Core/Common';
import { ReactComponent as EmptyIcon } from 'assets/layout/empty-data/empty-icon.svg';

import * as S from './styles';

const SearchDropdown = ({
  options = [],
  placeholder = i18n.t('common-words.search'),
  className,
  loading,
  onChangeSearch,
  onChangeSelect,
  onChangeUnselect,
  isMultiple,
  borders,
  value = [],
  showAllOption,
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [searchFor, setSearchFor] = useState('');
  const [selectedOptions, setSelectedOptions] = useState(value);
  const [formattedOptions, setFormattedOptions] = useState([]);
  const [optionsToRender, setOptionsToRender] = useState([]);

  const allOption = [
    {
      name: showAllOption?.label || i18n.t('common-words.all'),
      id: showAllOption?.id || '-1',
    },
  ];

  const handleFocus = () => {
    !loading && setOpen(true);
  };

  const handleBlur = () => {
    !loading && setOpen(false);
  };

  const handleChangeSearch = (value) => {
    setSearchFor(value);
    onChangeSearch && onChangeSearch(value);
  };

  const handleSelect = (id) => {
    isMultiple
      ? setSelectedOptions([...selectedOptions, id])
      : setSelectedOptions([id]);
    onChangeSelect(id);
    setOpen(false);
  };

  const handleUnselect = (idToRemove) => {
    const newOptions = selectedOptions.filter((item) => item !== idToRemove);
    setSelectedOptions(newOptions);
    onChangeUnselect && onChangeUnselect(idToRemove);
    setOpen(false);
  };

  const getInitials = (label) => {
    if (!label) return;
    const initials = label
      .split(' ')
      .map((labels) => labels.charAt(0))
      .join('')
      .substr(0, 2);
    return initials;
  };

  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  useEffect(() => {
    if (options?.length) {
      const newOptions = showAllOption ? allOption.concat(options) : options;
      setFormattedOptions(newOptions);
    }
  }, [options]);

  useEffect(() => {
    setOptionsToRender(
      formattedOptions.filter((item) => {
        const label = item.label || item.name;
        const email = item?.email || '';
        return (
          (label.toString().toUpperCase().includes(searchFor.toUpperCase()) ||
            email.toString().toUpperCase().includes(searchFor.toUpperCase())) &&
          !selectedOptions.includes(item.id)
        );
      })
    );
  }, [searchFor, formattedOptions, selectedOptions]);

  const checkSelectedExists = () => {
    let exists = false;
    formattedOptions?.forEach(
      (item) => selectedOptions.includes(item.id) && (exists = true)
    );
    return !exists;
  };

  return (
    <ClickAwayListener onClickAway={handleBlur}>
      <S.Container className={className}>
        {(isMultiple || (!isMultiple && checkSelectedExists())) && (
          <S.SearchToggl borders={borders}>
            <Icon color="#E1E0E7" name="search" />
            <InputControl
              inputType="text"
              inputID="search"
              inputName="search"
              placeholder={
                loading ? `${i18n.t('common-words.loading')}...` : placeholder
              }
              onChange={(e) => handleChangeSearch(e.target.value)}
              onFocus={() => handleFocus()}
            />
          </S.SearchToggl>
        )}

        {!loading &&
          formattedOptions?.map((item, index) =>
            selectedOptions.includes(item.id) ? (
              <S.SelectedOpt key={index} borders={borders}>
                <S.Avatar>
                  {item.image ? (
                    <S.AvatarImage url={item.image} />
                  ) : (
                    <S.AvatarInitials isAll={item?.id === 'all'}>
                      {getInitials(item.label || item.name)}
                    </S.AvatarInitials>
                  )}
                  <S.AvatarLabel title={item.label || item.name}>
                    {item.label || item.name}
                  </S.AvatarLabel>
                </S.Avatar>
                <S.RemoveSelectedOpt onClick={() => handleUnselect(item.id)}>
                  <Icon color="#5d6670" name="trash" fontSize="16px" />
                </S.RemoveSelectedOpt>
              </S.SelectedOpt>
            ) : null
          )}

        {!loading && (
          <S.SearchBox open={open} borders={borders}>
            <S.OptionsBox>
              {!optionsToRender?.length ? (
                <S.Empty>
                  <EmptyIcon />
                  <S.EmptyTitle>
                    {i18n.t('commons.search-dropdown.no-results')}
                  </S.EmptyTitle>
                  <S.EmptyDescription>
                    {i18n.t('commons.search-dropdown.no-matches')}
                  </S.EmptyDescription>
                </S.Empty>
              ) : (
                optionsToRender?.map((item, index) => {
                  const label = item.label || item.name;
                  return (
                    <S.Option key={index} onClick={() => handleSelect(item.id)}>
                      <S.Avatar>
                        {item.image ? (
                          <S.AvatarImage url={item.image} />
                        ) : (
                          <S.AvatarInitials isAll={item?.id === 'all'}>
                            {getInitials(label)}
                          </S.AvatarInitials>
                        )}
                        <S.AvatarInfo>
                          <S.AvatarLabel
                            title={label}
                            fullWidth={!item?.additionalInfo?.length}
                          >
                            {label}
                          </S.AvatarLabel>
                          {!!item?.additionalInfo?.length && (
                            <Tooltip
                              placement="bottom"
                              overlay={
                                <>
                                  {item?.additionalInfo?.map((info, index) => (
                                    <div key={index}>{info}</div>
                                  ))}
                                  <div></div>
                                </>
                              }
                              overlayInnerStyle={{
                                backgroundColor: theme.config.colors.primary,
                                borderRadius: '4px',
                                padding: '8px 16px',
                              }}
                            >
                              <S.AdditionalInfo>
                                {item?.additionalInfo?.length > 1
                                  ? `${item?.additionalInfo?.length} Associations`
                                  : item?.additionalInfo[0]}
                              </S.AdditionalInfo>
                            </Tooltip>
                          )}
                        </S.AvatarInfo>
                      </S.Avatar>
                    </S.Option>
                  );
                })
              )}
            </S.OptionsBox>
          </S.SearchBox>
        )}
      </S.Container>
    </ClickAwayListener>
  );
};

SearchDropdown.propTypes = {
  options: PropTypes.array,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.bool,
  onChangeSearch: PropTypes.func,
  onChangeSelect: PropTypes.func,
  onChangeUnselect: PropTypes.func,
  isMultiple: PropTypes.bool,
  borders: PropTypes.bool,
  value: PropTypes.array,
  showAllOption: PropTypes.any,
};

export default SearchDropdown;
