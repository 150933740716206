export const exerciseStatus = ['Completed', 'Pending'];
export const exerciseStatusCustom = [
  'All Status',
  'Completed',
  'Pending',
  'Skipped',
  'Scheduled',
  'Missed',
  'Canceled',
  'Done',
];
