import { format } from 'modules/Utils/Date';

const createNotificationsModel = (notifications = {}) => {
  return {
    id: notifications.id || '',
    title: notifications.title || '',
    group_id: notifications.group?.id || '',
    url: notifications.url || '',
    button_name: notifications.button_name || '',
    notification_type: notifications.notification_type || '',
    schedule_date: notifications.schedule_date || '',
    schedule_time: notifications.schedule_time || '',
    expiration_date: notifications.expiration_date || '',
    message: notifications.message || '',
    image: notifications.image || '',
    modal: notifications.modal || false,
    reference_topic: notifications.reference_topic || '',
    test_id: notifications.test?.id || '',
    custom_test_id: notifications.custom_test?.id || '',
    school_id: notifications.school?.id || '',
    whitelabel_id: notifications.whitelabel?.id || '',
    notification_links_attributes: notifications.notification_links || [],
  };
};

const parseBeforeSubmit = (notification, userDateFormat) => ({
  ...notification,
  schedule_date: format(
    notification?.schedule_date.toString(),
    userDateFormat,
    true
  ),
  expiration_date: format(
    notification?.expiration_date.toString(),
    userDateFormat,
    true
  ),
});

const parseNotificationTest = (notification = {}) => {
  const testId = notification.test_id || notification.custom_test_id;

  if (testId === 'all') {
    return {
      ...notification,
      test_id: '',
      custom_test_id: '',
    };
  }

  return notification;
};

export { createNotificationsModel, parseNotificationTest, parseBeforeSubmit };
