const parseGrades = (grades) => {
  if (!grades) return null;

  return Object.keys(grades).reduce((acc, next) => {
    const formattedGrade = grades[next]?.grade_mark ?? '0.0';
    return { ...acc, [next]: formattedGrade };
  }, {});
};

const parseCustomGrades = (grades) => {
  if (!grades) return null;

  return Object.keys(grades)?.map((item) => {
    const formattedGrade = grades[item]?.grade_mark ?? '0.0';
    return formattedGrade;
  });
};

const parseCategoryIds = (grades) => {
  if (!grades) return null;

  return Object.keys(grades)?.map(
    (item) => grades[item]?.evaluation_category_item_id
  );
};

const parseStrengthsAndRecommendations = (strengthsAndRecommendations) => {
  if (!strengthsAndRecommendations) return null;

  // TODO: Waiting backend
  return strengthsAndRecommendations;
};

export const getAnswerRequestModel = (answer = {}, isCustom) => {
  if (isCustom) {
    return {
      comments: answer.comments,
      marks: parseCustomGrades(answer.grades),
      evaluation_category_item_ids: parseCategoryIds(answer.grades),
      empty_answers: !!answer.empty_answers,
    };
  }

  return {
    comments: answer.comments,
    marks: answer.marks,
    grades: parseGrades(answer.grades),
    strengths_and_recommendations: parseStrengthsAndRecommendations(
      answer.strengthsAndRecommendations
    ),
    empty_answers: !!answer.empty_answers,
  };
};
