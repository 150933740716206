import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'modules/Core/Common';
import { copyToClipBoard } from 'modules/Utils/CopyToClipboard';
import * as S from './styles';

const CopyWithIcon = ({ Icon, title, text, ...props }) => {
  return (
    <S.Container {...props}>
      <Tooltip overlay={title}>
        <Icon
          {...props}
          cursor="pointer"
          onClick={() => copyToClipBoard(text || title)}
        />
      </Tooltip>
    </S.Container>
  );
};

CopyWithIcon.propTypes = {
  Icon: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string,
};

export { CopyWithIcon };
