import React from 'react';
import PropTypes from 'prop-types';

import { Modal, Icon, Title, Loader } from 'modules/Core/Common';

import * as S from './ConfirmDialog.styles';

export const ConfirmDialog = ({
  isOpen,
  onRequestClose,
  title = 'Are you sure?',
  confirmButtonText = 'Yes',
  cancelButtonText = 'No',
  isConfirmHighlighted = false,
  isCancelHighlighted = false,
  loading,
  onConfirm,
}) => {
  const handleCloseModal = () => {
    if (loading) return;

    onRequestClose();
  };

  return (
    <Modal width="418px" isOpen={isOpen} setIsOpen={handleCloseModal}>
      <S.Container>
        <Title tag="h2" size="quartenary">
          {title}
        </Title>
        <S.Actions>
          {loading ? (
            <Loader />
          ) : (
            <>
              <S.DialogButton
                type="button"
                medium
                highlight={isCancelHighlighted}
                onClick={handleCloseModal}
              >
                {cancelButtonText}
              </S.DialogButton>

              <S.DialogButton
                type="button"
                medium
                highlight={isConfirmHighlighted}
                onClick={() => onConfirm()}
              >
                {confirmButtonText}
              </S.DialogButton>
            </>
          )}
        </S.Actions>
        <S.ButtonClose type="button" onClick={handleCloseModal}>
          <Icon fontSize="16px" name="close" />
        </S.ButtonClose>
      </S.Container>
    </Modal>
  );
};

ConfirmDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  isConfirmHighlighted: PropTypes.bool,
  isCancelHighlighted: PropTypes.bool,
  loading: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
};
