import styled, { css } from 'styled-components';

import { Button } from 'modules/Core/Common';

export const Container = styled.div`
  position: relative;
  max-height: 90vh;
  padding: 6rem 0 0;
  background: ${(props) => props.theme.config.colors.light};
  border-radius: 0.6rem;

  h2 {
    ${(props) => props.theme.config.typograph.tertiary};
    text-align: center;
    margin: 0 auto 2.4rem auto;
  }
`;

export const Message = styled.p`
  ${(props) => props.theme.config.typograph.default};
  font-size: 1.6rem;
  text-align: center;
  color: ${(props) => props.theme.config.colors.text_base};
  margin-bottom: 4.8rem;
  padding: 0 3.2rem;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${(props) => props.theme.config.colors.gray_200};
  padding: 3.2rem 0;
`;

export const DialogButton = styled(Button)`
  background: transparent;
  color: ${(props) => props.theme.config.colors.primary};
  border: 1px solid ${(props) => props.theme.config.colors.primary};
  ${(props) => props.theme.config.typograph.xsmall};
  white-space: nowrap;
  width: 15.6rem;

  &:hover,
  &:focus,
  &:active {
    color: ${(props) => props.theme.config.colors.light};
  }

  & + button {
    margin-left: ${(props) => props.theme.config.spacing.spacing_3};
  }

  ${({ highlight }) =>
    highlight &&
    css`
      background: ${(props) => props.theme.config.colors.primary};
      color: ${(props) => props.theme.config.colors.light};
    `};
`;

export const IconContainer = styled.div`
  width: 8rem;
  height: 8rem;
  margin: 0 auto;
  margin-bottom: 2.4rem;

  svg {
    path {
      fill: ${(props) => props.theme.config.colors.tertiary};
    }
  }
`;
