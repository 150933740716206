import styled, { keyframes, css } from 'styled-components';
import { ZIndexes } from 'modules/Utils/ZIndexes';

const rotate = keyframes`
  100% { transform: rotate(360deg) }
`;

const Container = styled.div`
  z-index: ${ZIndexes.loading};
  position: ${(props) => (props.position ? props.position : 'absolute')};
  margin: 0 auto;
  width: ${(props) =>
    props.size ? parseInt(props.size) * 3 + 'rem' : '23.2rem'};
  height: ${(props) =>
    props.size ? parseInt(props.size) * 3 + 'rem' : '23.2rem'};
  ${(props) =>
    props.ousideContainer &&
    css`
      background-color: ${(props) =>
        props.backgroundFull
          ? props.backgroundFull
          : props.theme.config.colors.light};
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      width: 100%;
    `}
  ${(props) =>
    props.fullHeight &&
    css`
      top: 0;
      bottom: 0;
    `}
`;

const Loader = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) =>
    props.size ? parseInt(props.size) * 3 + 'rem' : '23.2rem'};
  height: ${(props) =>
    props.size ? parseInt(props.size) * 3 + 'rem' : '23.2rem'};
  background: ${(props) => props.theme.config.colors.light};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 6px;

  ${(props) =>
    props.background ||
    css`
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      box-shadow: none;
    `}
`;

const Span = styled.span`
  margin-top: ${(props) =>
    props.size ? parseInt(props.size) / 2 + 'rem' : '2rem'};
  font-family: 'Roboto', sans-serif;
  font-size: ${(props) =>
    props.size ? parseInt(props.size) / 2 + 'rem' : '1.6rem'};
  line-height: 2.4rem;
  text-align: center;
  color: ${(props) => props.theme.config.colors.gray};
`;

const Circular = styled.div`
  animation: ${rotate} 1s linear infinite;
  width: ${(props) => (props.size ? props.size : '7.2rem')};
  height: ${(props) => (props.size ? props.size : '7.2rem')};
`;

export { Container, Loader, Circular, Span };
