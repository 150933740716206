import React from 'react';
import PropTypes from 'prop-types';
import { InputControl } from '../InputControl';

const CodeInput = ({ onChange, ...props }) => {
  const handleInputChange = (e) => {
    const value = e.target.value;
    const cleanedValue = value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    e.target.value = cleanedValue;
    if (onChange) {
      onChange(e);
    }
  };

  return <InputControl onChange={handleInputChange} {...props} />;
};

CodeInput.propTypes = {
  onChange: PropTypes.func,
};

export default CodeInput;
