export const createAIEvaluatorRequest = (settings = {}) => {
  return Object.keys(settings).reduce((acc, field) => {
    const isInvalid =
      !settings[field] || [null, undefined].includes(settings[field]);
    const isInvalidFile =
      field === 'ai_evaluator_avatar' && typeof settings[field] === 'string';
    if (isInvalid || isInvalidFile) {
      return acc;
    }

    acc[field] = settings[field];
    return acc;
  }, {});
};
