import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import { defaultRequest } from 'modules/Utils/DefaultRequest';
import {
  PROCTORING_SETTINGS_BY_WHITELABEL_OR_EXTERNAL_APP_ID_URL,
  WHITELABELS_URL,
  EXTERNAL_APPLICATIONS_URL,
} from 'modules/Api/Routes';

let cancelToken;

const initialState = {
  loading: false,
  error: null,
  origin: {
    selected: {
      id: null,
      type: null,
    },
    whitelabels: {
      loading: false,
      data: [],
    },
    external_applications: {
      loading: false,
      data: [],
    },
  },
  data: {
    page: 1,
    perPage: 10,
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
};

const proctoringSettingsListSlice = createSlice({
  name: 'proctoringSettingsList',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    changePage: (state, action) => {
      state.data.page = action.payload;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    changeOrigin: (state, action) => {
      state.origin.selected.id = action.payload.id;
      state.origin.selected.type = action.payload.type;
      state.data = initialState.data;
    },
    request: (state) => {
      state.loading = true;
      state.error = null;
    },
    receiveList: (state, action) => {
      state.loading = false;

      state.data = {
        ...state.data,
        total: action.payload.total_items,
        proctoringSettingsList: action.payload.proctoring_settings,
      };
    },
    clearList: (state) => {
      state.data = initialState.data;
    },
    receiveError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    requestExternalApps: (state) => {
      state.origin.external_applications.loading = true;
    },
    receiveExternalAppsList: (state, action) => {
      state.origin.external_applications.loading = false;
      state.origin.external_applications.data = action.payload.content;
    },
    receiveExternalAppsError: (state, action) => {
      state.origin.external_applications.loading = false;
      state.error = action.payload;
    },
    requestWhitelabels: (state) => {
      state.origin.whitelabels.loading = true;
    },
    receiveWhitelabelsList: (state, action) => {
      state.origin.whitelabels.loading = false;
      state.origin.whitelabels.data = action.payload.content.whitelabels;
    },
    receiveWhitelabelsError: (state, action) => {
      state.origin.whitelabels.loading = false;
      state.error = action.payload;
    },
    changeFilters: (state, action) => {
      state.origin.selected.id = action?.payload?.origin_id || null;
      state.origin.selected.type = action?.payload?.origin_type || null;
      state.data.page = action?.payload?.page || 1;
      state.data.perPage = action?.payload?.paginates_per || 10;
    },
    clearFilters: (state) => {
      state.origin.selected.id = null;
      state.origin.selected.type = null;
      state.data = initialState.data;
    },
  },
});

const Actions = proctoringSettingsListSlice.actions;

const Selectors = {
  fetchListData: (state) => state.proctoringSettingsList,
  proctoringSettingsListLoading: ({ proctoringSettingsList: { loading } }) => ({
    loading,
  }),
};

const Async = {
  fetchWhitelabelsList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      WHITELABELS_URL,
      'GET',
      Actions.requestWhitelabels,
      Actions.receiveWhitelabelsList,
      Actions.receiveWhitelabelsError
    ),
  fetchExternalAppsList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      EXTERNAL_APPLICATIONS_URL,
      'GET',
      Actions.requestExternalApps,
      Actions.receiveExternalAppsList,
      Actions.receiveExternalAppsError
    ),
  fetchList:
    ({ proctoring_settings_id }) =>
    async (dispatch, getState) => {
      const {
        proctoringSettingsList: {
          origin: {
            selected: { id, type },
          },
          data: {
            page,
            perPage,
            sort: { sortType, sortBy },
          },
        },
      } = getState();

      ejectCancelInterceptor();
      cancelToken?.cancel();
      cancelToken = generateCancelToken();

      let action;

      dispatch(Actions.request());

      try {
        const response = await request({
          cancelToken: cancelToken.token,
          method: 'GET',
          url: PROCTORING_SETTINGS_BY_WHITELABEL_OR_EXTERNAL_APP_ID_URL,
          params: {
            whitelabel_id: type !== 'external_application' ? id : null,
            external_application_id:
              type === 'external_application' ? id : null,
            proctoring_settings_id,
            page,
            paginates_per: perPage,
            sort: sortType?.toLowerCase(),
            sort_by: sortBy?.toLowerCase(),
          },
        });

        action = Actions.receiveList(response.data.content);
      } catch (e) {
        if (!isCancel(e)) {
          action = Actions.receiveError(e.message);
        }
      }

      action && dispatch(action);
    },
};

const { reducer } = proctoringSettingsListSlice;

export { reducer, Actions, Async, Selectors };
