import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

import {
  Modal,
  InfoIcon,
  Icon,
  IconRoundBox,
  Title,
} from 'modules/Core/Common';

import * as S from './UnsavedChangesModal.styles';

export const UnsavedChangesModal = ({
  isOpen,
  onClose,
  title = 'Are you sure?',
  message = 'You did not save your dashboard. If you continue, all changes will be lost.',
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  isConfirmHighlighted = true,
  isCancelHighlighted = false,
  onConfirm,
}) => {
  const theme = useTheme();

  return (
    <Modal width="384px" isOpen={isOpen} setIsOpen={onClose}>
      <S.Container>
        <S.IconContainer>
          <InfoIcon
            style={{ margin: '0 auto' }}
            thin
            before={
              <IconRoundBox xlarge withBg bg={theme.config.colors.tertiary_100}>
                <Icon
                  name="exclamation"
                  color={theme.config.colors.tertiary}
                  fontSize="2.4rem"
                />
              </IconRoundBox>
            }
          />
        </S.IconContainer>
        <Title tag="h2" size="quartenary">
          {title}
        </Title>
        <S.Message>{message}</S.Message>
        <S.Actions>
          <S.DialogButton
            type="button"
            large
            highlight={isCancelHighlighted}
            onClick={onClose}
          >
            {cancelButtonText}
          </S.DialogButton>

          <S.DialogButton
            type="button"
            large
            highlight={isConfirmHighlighted}
            onClick={() => onConfirm()}
          >
            {confirmButtonText}
          </S.DialogButton>
        </S.Actions>
      </S.Container>
    </Modal>
  );
};

UnsavedChangesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  isConfirmHighlighted: PropTypes.bool,
  isCancelHighlighted: PropTypes.bool,
  loading: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
};
