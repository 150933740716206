import styled from 'styled-components';

const Label = styled.label`
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 5.2rem;
  height: 2.6rem;

  & span {
    cursor: pointer;
    transition: 0.4s;
  }

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  & input:checked ~ span#slider {
    background: ${(props) => props.theme.config.colors.green_100};
    border: 1px solid ${(props) => props.theme.config.colors.green_300};
    box-shadow: 0px 4px 4px rgba(27, 209, 113, 0.05);
  }

  & input:checked ~ span:last-child {
    transform: translateX(2.6rem);
  }
`;

const Slider = styled.span`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  /* opacity: ${(props) => (props.checked ? '.15' : '1')}; */
  border: 1px solid ${(props) => props.theme.config.colors.gray_200};
  border-radius: 3.4rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
  background: ${(props) => props.theme.config.colors.light};
`;

const SliderBall = styled.span`
  width: 2rem;
  height: 2rem;
  display: inline-block;
  background: ${(props) =>
    props.checked
      ? props.theme.config.colors.green
      : props.theme.config.colors.gray_300};
  border-radius: 50%;
  position: absolute;
  right: 2.8rem;
`;

export { Label, Slider, SliderBall };
